// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface OnfidocreateCheckRequest
 */
export interface OnfidocreateCheckRequest {
    /**
     * Applicant id generated from onfido
     * @type {string}
     * @memberof OnfidocreateCheckRequest
     */
    applicant_id: string;
    /**
     * @type {Array<string>}
     * @memberof OnfidocreateCheckRequest
     */
    report_names: Array<OnfidocreateCheckRequestReportNamesEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum OnfidocreateCheckRequestReportNamesEnum {
    Document = 'document',
    FacialSimilarityVideo = 'facial_similarity_video'
}

