// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CountryCodeAlpha3PlusKosovo,
    OtherField,
    VerificationSubjectDocument,
} from './';

/**
 * @export
 * @interface VerificationSubject
 */
export interface VerificationSubject {
    /**
     * primary name as in MRZ or if names not provided in document, name and given given name
     * @type {string}
     * @memberof VerificationSubject
     */
    primaryName: string;
    /**
     * @type {string}
     * @memberof VerificationSubject
     */
    secondaryName?: string;
    /**
     * @type {CountryCodeAlpha3PlusKosovo}
     * @memberof VerificationSubject
     */
    issuer: CountryCodeAlpha3PlusKosovo;
    /**
     * @type {CountryCodeAlpha3PlusKosovo}
     * @memberof VerificationSubject
     */
    nationality?: CountryCodeAlpha3PlusKosovo;
    /**
     * the persons gender
     * @type {string}
     * @memberof VerificationSubject
     */
    sex?: VerificationSubjectSexEnum;
    /**
     * relevant for countries that have a unique person number
     * @type {string}
     * @memberof VerificationSubject
     */
    personalNumber?: string;
    /**
     * the documents number
     * @type {string}
     * @memberof VerificationSubject
     */
    documentNumber?: string;
    /**
     * the documents number
     * @type {string}
     * @memberof VerificationSubject
     */
    passportNumber?: string;
    /**
     * birth date as unix timestamp in ms
     * @type {number}
     * @memberof VerificationSubject
     */
    birthDate: number;
    /**
     * expiry date as unix timestamp in ms
     * @type {number}
     * @memberof VerificationSubject
     */
    expiryDate?: number;
    /**
     * date the document was issued
     * @type {number}
     * @memberof VerificationSubject
     */
    issuingDate?: number;
    /**
     * @type {VerificationSubjectDocument}
     * @memberof VerificationSubject
     */
    document?: VerificationSubjectDocument;
    /**
     * raw data in Base64 related to the face of the person, only delivered when querying the api diretly not via notification
     * @type {string}
     * @memberof VerificationSubject
     */
    face?: string;
    /**
     * @type {Array<OtherField>}
     * @memberof VerificationSubject
     */
    otherEditableFields?: Array<OtherField>;
}

/**
 * @export
 * @enum {string}
 */
export enum VerificationSubjectSexEnum {
    M = 'M',
    F = 'F',
    LessThan = '<',
    X = 'X',
   
   
   
}

