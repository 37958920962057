import styled from 'styled-components';

let SForm: any = null;
let SBr: any = null;
let SLabel: any = null;

SForm = styled.form``;
SBr = styled.br``;
SLabel = styled.label``;

export const Form = SForm;
export const Br = SBr;
export const Label = SLabel;
