import React, { ReactElement, SyntheticEvent } from 'react';
import styled, { CSSProperties } from 'styled-components';
import IconPickerItem, { IIconPickerItemProps } from './iconPickerItem';
import {
	OverlayHandler,
	Overlays,
} from '../../../logic/handler/overlayhandler/overlayHandler';
import { IIconPickerOverlayProps } from './iconPickerOverlay';
import { Icons } from '../../../images';
import TouchableOpacity from '../buttons/touchableOpacity';
import { Log, Logs } from '../../../logic/log';
import { Config } from '../../../config';

interface IProps {
	children: Array<ReactElement> | ReactElement;
	containerStyle?: CSSProperties;
	defaultValue?: string | number;
	disabled?: boolean;
	onValueChange?: (value: string | number, index: number) => void;
	selectedValue?: string | number;
	value?: string | number;
	error?: string;
	toolTip?: boolean;
}

interface IState {
	selected?: number;
	showSelectBox?: boolean;
	toolTipOpen: boolean;
}

export default class IconPicker extends React.Component<IProps, IState> {
	private divRef: React.RefObject<HTMLDivElement> | null = null;
	private containerRef: React.RefObject<HTMLDivElement> | null = null;
	constructor(props: IProps) {
		super(props);

		let selected: number | undefined = undefined;
		if (
			props.children != null &&
			(props.selectedValue != null || props.defaultValue != null)
		) {
			const search =
				props.selectedValue != null ? props.selectedValue : props.defaultValue;
			Log.info(Logs.SYSTEM, 'SearchValue: ' + search);
			if (Array.isArray(props.children)) {
				selected = props.children.findIndex((el) => el.props.value === search);
			} else {
				selected = 0;
			}
		}

		this.state = {
			selected: selected,
			toolTipOpen: false,
		};

		this.divRef = React.createRef<HTMLDivElement>();
		this.containerRef = React.createRef<HTMLDivElement>();

		this.selectItem = this.selectItem.bind(this);
		this.toggleSelectBox = this.toggleSelectBox.bind(this);
		this.hideToolTip = this.hideToolTip.bind(this);
		this.showToolTip = this.showToolTip.bind(this);
	}

	static getDerivedStateFromProps(props: IProps, state: IState): IState | null {
		if (props.value != null && props.children != null) {
			let selected: number = 0;
			if (Array.isArray(props.children)) {
				const v: number | undefined = props.children.findIndex(
					(el) => el.props.value === props.value
				);
				if (v != null) {
					selected = v;
				}
			}
			return {
				selected: selected,
				toolTipOpen: state.toolTipOpen,
			};
		}

		return null;
	}

	private selectItem(itemValue: string | number, index: number): void {
		if (
			index < 0 ||
			(!Array.isArray(this.props.children) && index > 0) ||
			(Array.isArray(this.props.children) && index >= this.props.children.length)
		) {
			return;
		}

		if (this.props.onValueChange != null) {
			this.props.onValueChange(itemValue, index);
		}

		this.setState({
			selected: index,
		});
	}

	private toggleSelectBox(event: SyntheticEvent): void {
		event.stopPropagation();
		event.preventDefault();

		if (this.props.disabled === true) {
			return;
		}

		if (this.state.showSelectBox === true) {
			OverlayHandler.closeSpecific(Overlays.iconPicker);
		} else {
			let posX: number = 0;
			let posY: number = 0;
			let width: number = Config.iconPicker.defaultWidth;
			if (this.divRef != null && this.divRef.current != null) {
				const domRect: DOMRect = this.divRef.current.getBoundingClientRect();
				posX = domRect.left;
				posY = domRect.top;
				width = domRect.width;

				const overlayData: IIconPickerOverlayProps = {
					posX: posX + 'px',
					posY: posY + 'px',
					width: width + 'px',
					children: this.props.children,
					onPress: this.selectItem,
					selfClose: () => {},
				};
				OverlayHandler.showOverlay(Overlays.iconPicker, overlayData);
			}
		}
	}

	private showToolTip() {
		if (this.props.error === null || this.props.error === '') {
			return;
		}
		if (this.containerRef != null && this.containerRef.current != null) {
			const box = this.containerRef.current.getBoundingClientRect();
			OverlayHandler.showOverlay(Overlays.toolTip, {
				posX: box.x,
				posY: box.y + box.height + 10,
				message: this.props.error,
			});
		}
		this.setState({ toolTipOpen: true });
	}

	private hideToolTip() {
		if (this.state.toolTipOpen === true) {
			OverlayHandler.closeSpecific(Overlays.toolTip);
		}
	}

	render() {
		let selectedItem: ReactElement = <IconPickerItem value="" />;

		const selected: number | undefined = this.state.selected;
		const children: ReactElement | Array<ReactElement> | undefined = this.props.children;
		if (children != null && selected != null) {
			if (Array.isArray(children)) {
				if (selected >= 0 && selected < children.length) {
					const itemProps: IIconPickerItemProps = {
						onPress: this.selectItem,
						children: children[selected],
						index: selected,
						value: children[selected].props.value,
					};
					selectedItem = React.cloneElement(children[selected], itemProps);
				}
			} else {
				const itemProps: IIconPickerItemProps = {
					onPress: this.selectItem,
					children: children,
					index: selected,
					value: children.props.value,
				};
				selectedItem = React.cloneElement(children, itemProps);
			}
		}
		return (
			<StyledMain
				id="iconpicker"
				error={this.props.error != null && this.props.error !== ''}
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
				ref={this.divRef}>
				<StyledContainer
					style={this.props.containerStyle  as any}
					onMouseLeave={() => this.hideToolTip()}
					onMouseEnter={() => this.showToolTip()}
					error={this.props.error != null && this.props.error !== ''}
					ref={this.containerRef}>
					{selectedItem}
				</StyledContainer>
				{!Array.isArray(this.props.children) ||
				this.props.children.length <= 1 ? null : (
					<TouchableOpacity onClick={this.toggleSelectBox}>
						{Icons.dropDownArrow()}
					</TouchableOpacity>
				)}
			</StyledMain>
		);
	}
}

const StyledContainer = styled.div<{ error?: boolean }>``;
const StyledMain = styled.div<{ error?: boolean }>`
	background-color: ${(props) => (props.error === true ? '#FFD3D3' : 'white')};
`;
/*
svg > path {
        fill: ${props => props.error === true ? 'red' : 'inherit' }
    }
    svg > rect {
        stroke: ${props => props.error === true ? 'red' : 'inherit' }
    }
    */
