import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';

export interface IPermissionPerson {
	given_name: string;
	name: string;
	primary_email_address: string;
	person_id: number;
}

class ITeamMemberDataStore extends EventEmitter {
	teamMembers?: Array<IPermissionPerson> = undefined;

	private static instance: ITeamMemberDataStore | null;
	constructor() {
		super();
		IDispatcher.register(this.registerActions.bind(this));
	}

	static getInstance(): ITeamMemberDataStore {
		if (ITeamMemberDataStore.instance == null) {
			ITeamMemberDataStore.instance = new ITeamMemberDataStore();
		}
		return ITeamMemberDataStore.instance;
	}

	registerActions(action: any) {
		switch (action.actionType) {
			default:
				break;
			case Constants.CLEAR_ALL:
				this.teamMembers = undefined;
				this.emit(CHANGE_EVENT);
				break;
			case Constants.TEAMMEMBER_ADDED:
				this.addTeamMember(action.teamMember);
				this.emit(CHANGE_EVENT);
				break;
			case Constants.TEAMMEMBER_CHANGED:
				this.setTeamMembers(action.teamMembers);
				this.emit(CHANGE_EVENT);
				break;
		}
		return true;
	}

	addTeamMember(teamMember: IPermissionPerson): void {
		if (this.teamMembers == null) {
			this.teamMembers = [];
		}

		this.teamMembers = [teamMember].concat(this.teamMembers);
	}

	setTeamMembers(teamMembers?: Array<IPermissionPerson>) {
		this.teamMembers = teamMembers;
	}

	getTeamMembers(): Array<IPermissionPerson> | undefined {
		return this.teamMembers;
	}
	addChangeListener(callback: any) {
		this.on(CHANGE_EVENT, callback);
	}

	removeChangeListener(callback: any) {
		this.removeListener(CHANGE_EVENT, callback);
	}
}

export const TeamMemberDataStore = ITeamMemberDataStore.getInstance();
