import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { YearComponent } from './yearcomponent';
import { DayComponent } from './daycomponent';
import { UtilityMenu } from './utitlitymenu';
import { MonthComponent } from './monthcomponent';
import { Icons } from '../../images';
import {
	RelativeOverlay,
	IRelativeProps,
	IRelativeState,
} from '../../logic/handler/overlayhandler/globaloverlays/aRelativeOverlay';
import { MessageHandler } from '../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../logic/handler/messagehandler/messageHandlerConfig';
import { FlexBox } from '../../content/auth/auth.css';
import TouchableOpacity from '../atomiccompoents/buttons/touchableOpacity';
import { TimePicker } from '../timepicker/timepicker';

interface IProps extends IRelativeProps {
	id?: string;
	showMenu?: boolean;
	startDate?: Date;
	today?: boolean;
	yesterday?: boolean;
	notification?: (date: Date) => void;
	closeCallback: (event: SyntheticEvent) => void;
	yearComponent?: boolean;
	leftAlign: boolean;
	selfClose: () => void;
	resetCallback: () => void;
	closeOnSelect?: boolean;
	minDate?: Date;
	maxDate?: Date;
	showTimePicker?: boolean
}

interface IState extends IRelativeState {
	showMenu?: boolean;
	currentDate: Date;
	selectedData: Date;
	yearComponent: boolean;
	selectedDay?: number;
	posX: string;
	posY: string;
}

export class DateComponent extends RelativeOverlay<IProps, IState> {
	private initScroll: number | null = null;
	constructor(props: IProps) {
		super(props);
		const initDate = new Date();

		this.state = {
			showMenu: this.props.showMenu != null ? this.props.showMenu : true,
			currentDate: this.props.startDate != null ? this.props.startDate : initDate,
			selectedData: this.props.startDate != null ? this.props.startDate : initDate,
			yearComponent: this.props.yearComponent != null ? this.props.yearComponent : true,
			posX: props.posX,
			posY: props.posY,
		};
		this.setDateFromMonth = this.setDateFromMonth.bind(this);
		this.setDateFromyYear = this.setDateFromyYear.bind(this);
		this.setDate = this.setDate.bind(this);
		this.setToday = this.setToday.bind(this);
		this.setYesterday = this.setYesterday.bind(this);
		this.reset = this.reset.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
	}

	reviveState(): void {}

	componentWillReceiveProps() {}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, true);
	}

	handleScroll(event: any) {
		if (this.initScroll == null) {
			this.initScroll = event.srcElement.scrollTop;
		}
		let currentY: string = this.props.posY;
		currentY = currentY.substring(0, currentY.length - 2);
		let currentX: string = this.props.posX;
		currentX = currentX.substring(0, currentX.length - 2);

		const valY: number = parseInt(currentY);
		const valX: number = parseInt(currentX);
		const scrollTop =
			this.initScroll != null
				? event.srcElement.scrollTop - this.initScroll
				: event.srcElement.scrollTop;
		const scrollLeft = event.srcElement.scrollLeft;
		const newY = valY - scrollTop + 'px';
		const newX = valX - scrollLeft + 'px';
		this.setState({
			posY: newY,
			posX: newX,
		});
	}

	setDateFromyYear(year: number) {
		const date = this.state.currentDate;
		date.setFullYear(year);
		this.setState({
			currentDate: date,
		});
	}

	checkDateRange(date: Date) {
		if (this.props.minDate != null) {
			return this.props.minDate > date;
		}
		if (this.props.maxDate != null) {
			return date > this.props.maxDate;
		}
	}

	setDate(day: number, month: number = 0) {
		const date = new Date(this.state.currentDate);
		date.setMonth(date.getMonth() + month);
		date.setDate(day);
		if (this.checkDateRange(date)) {
			MessageHandler.onError(Reporter['dateselect.range.error']);
			return;
		}
		this.setState({
			currentDate: date,
			selectedData: date,
			selectedDay: day,
		});
		if (this.props.notification != null) {
			this.props.notification(date);
		}
		if (this.props.closeOnSelect !== false && this.props.selfClose != null) {
			this.props.selfClose();
		}
	}

	reset() {
		if (this.props.resetCallback != null) {
			this.props.resetCallback();
		}
	}

	formatDate() {}

	setToday() {
		const today = new Date();
		if (!this.checkDateRange(today)) {
			MessageHandler.onError(Reporter['dateselect.range.error']);
			return;
		}
		this.setState({
			currentDate: today,
			selectedData: today,
			selectedDay: today.getDate(),
		});
		if (this.props.notification != null) {
			this.props.notification(today);
		}
	}

	setYesterday() {
		const yesterday = new Date();
		yesterday.setDate(yesterday.getDate() - 1);
		if (!this.checkDateRange(yesterday)) {
			MessageHandler.onError(Reporter['dateselect.range.error']);
			return;
		}
		this.setState({
			currentDate: yesterday,
			selectedData: yesterday,
			selectedDay: yesterday.getDate(),
		});
		if (this.props.notification != null) {
			this.props.notification(yesterday);
		}
	}

	setDateFromMonth(month: number) {
		const date = this.state.currentDate;
		date.setDate(1);
		date.setMonth(month);
		this.setState({
			currentDate: date,
		});
	}

	defineContent() {
		return (
			<DateView
				id= {this.props.id != null ? this.props.id : "pickerTest" }
				key={this.props.id}
				onClick={(event) => event.stopPropagation()}
				top={this.state.posY}
				left={this.state.posX}
				onKeyDown={(event: React.KeyboardEvent) => {
					if(event.key === 'Escape') {
						this.props.selfClose()
					}
				}}
				tabIndex={1}
				leftAlign={this.props.leftAlign}>
				<TouchableOpacity
					containerStyle={CancelWrapper}
					onClick={() => this.props.selfClose()}>
					{Icons.closingCross('10', '10')}
				</TouchableOpacity>
				{this.state.yearComponent ? (
					<YearComponent
						default={this.state.currentDate.getFullYear()}
						onChange={this.setDateFromyYear}
					/>
				) : null}
				<MonthComponent
					onChange={this.setDateFromMonth}
					default={this.state.currentDate.getMonth()}
				/>
				<DayComponent
					date={this.state.currentDate}
					callback={this.setDate}
					selectedDay={this.state.selectedDay}
					minDate={this.props.minDate}
					maxDate={this.props.maxDate}
				/>
				{this.state.showMenu ? (
					<UtilityMenu
						todayButton={this.props.today}
						setToday={this.setToday}
						yesterdayButton={this.props.yesterday}
						setYesterday={this.setYesterday}
						reset={this.reset}
					/>
				) : null}
				{ this.props.showTimePicker ? 
					<TimePicker 
						date={this.state.currentDate}
						intervall={1}
						callback={(date: Date  |  string | undefined) => {
							if(date != null && date instanceof Date) {
								this.setState({
									currentDate: date
								})
							}
						}}
					/>
				: null }
			</DateView>
		);
	}
}

const DateView = styled(FlexBox)<{ leftAlign?: boolean; top: string; left: string }>`
    z-index: 5000;
    position: absolute;
    box-sizing: border-box;
    top: ${(props) => props.top};
    left: ${(props) => props.left}
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: ${props => props.theme.Box.borderRadius};
    box-shadow: ${props => props.theme.Box.boxShadow};
    padding: 20px;
    width: 250px;
`;

const CancelWrapper: React.CSSProperties = {
	width: '10px',
	height: '10px',
	right: '10px',
	top: '10px',
	position: 'absolute',
};
