import React, { ReactElement, SyntheticEvent } from 'react';
import { FormError } from './formError';
import { StrengthBar } from './strengthBar';
import { translate } from '../../../common/language/translate';
import styled from 'styled-components';
import {
	OverlayHandler,
	Overlays,
} from '../../../logic/handler/overlayhandler/overlayHandler';
import { Config } from '../../../config';

interface IProps {
	id: string;
	type?:
		| 'off'
		| 'text'
		| 'username'
		| 'password'
		| 'email'
		| 'name'
		| 'firstname'
		| 'lastname'
		| 'tel'
		| 'street-address'
		| 'postal-code'
		| 'file'
		| 'money';
	className?: string;
	showStrength?: boolean;
	notification?: (message: any) => void;
	showError?: boolean;
	error?: string;
	directSetError?: boolean;
	label?: string;
	placeHolder?: string;
	disabled?: boolean;
	refresh?: () => void;
	value?: string;
	defaultValue?: string;
	onChange?: (value: string) => void;
	onEnter?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onLeave?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onSubmit?: (event: SyntheticEvent) => void;
	showLabel?: boolean;
	showPlaceholder?: boolean;
	valueFromState?: boolean;
	iconLeft?: JSX.Element;
	iconRight?: JSX.Element;
	containerStyle?: React.CSSProperties;
	labelStyle?: React.CSSProperties;
	input?: React.CSSProperties;
	iconRightStyle?: React.CSSProperties;
	iconLeftStyle?: React.CSSProperties;
	autoComplete?: boolean;
	toolTip?: boolean;
	focusOnRender?: boolean;
	helperText?: string;
	transitionInput?: boolean;
	inline?: boolean;
	reactToArrow?: boolean;
}

interface IState {
	error?: string;
	strength: number;
	className?: string;
	disabled?: boolean;
	value?: any;
	defaultValue?: string;
	isFocused?: boolean;
	label?: string;
	placeHolder?: string;
	toolTipOpen?: boolean;
	hovered?: boolean;
	transitionPlaceHolder?: string;
}

export class Input extends React.Component<IProps, IState> {
	notification: (message: any) => void = () => {};
	valueFromState: boolean = false;
	private parentOnChange: (value: string) => void = () => {};
	private hovered: boolean = false;
	ref = React.createRef<HTMLInputElement>();
	constructor(props: IProps) {
		super(props);

		let currentLabel: string | undefined = props.label;
		if (props.notification != null) {
			this.notification = props.notification.bind(this);
		}
		if (props.onChange != null) {
			this.parentOnChange = props.onChange;
		}

		if (props.label == null) {
			if (props.type === 'email' || props.type === 'username') {
				currentLabel =
					props.label != null && props.label !== ''
						? props.label
						: translate('input.email.label');
			} else if (props.type === 'password') {
				currentLabel = translate('input.password.label');
			} else if (props.type === 'tel') {
				currentLabel = translate('input.phone.label');
			} else if (props.type === 'off') {
				if (props.id === 'firstname') {
					currentLabel = translate('input.firstname.label');
				} else if (props.id === 'lastname') {
					currentLabel = translate('input.lastname.label');
				} else if (props.id === 'address') {
					currentLabel = translate('input.address.label');
				} else if (props.id === 'city') {
					currentLabel = translate('input.city.label');
				} else if (props.id === 'postcode') {
					currentLabel = translate('input.postCode.label');
				} else if (props.id === 'state') {
					currentLabel = translate('input.state.label');
				} else if (props.id === 'gender') {
					currentLabel = translate('input.gender.label');
				} else if (props.id === 'nationality') {
					currentLabel = translate('input.nationality.label');
				} else if (props.id === 'customernumber') {
					currentLabel = translate('input.customerNumber.label');
				} else if (props.id === 'accountnumber') {
					currentLabel = translate('input.accountNumber.label');
				} else if (props.id === 'cardnumber') {
					currentLabel = translate('input.cardNumber.label');
				} else if (props.id === 'pin') {
					currentLabel = translate('input.pin.label');
				} else if (props.id === 'nr') {
					currentLabel = translate('input.nr.label');
				} else if (props.id === 'search') {
					currentLabel = translate('input.search.label');
				}
			}
		} else {
			currentLabel = props.label;
		}
		const currentPlaceHolder =
			props.placeHolder == null ? currentLabel : props.placeHolder;

		if (props.type === 'money' || props.valueFromState === true) {
			this.valueFromState = props.valueFromState === true;
		}

		this.state = {
			error: props.error,
			strength: 0,
			className: props.className,
			defaultValue: props.defaultValue,
			disabled: props.disabled === true,
			label: currentLabel,
			placeHolder: currentPlaceHolder,
			value: props.value,
			isFocused: props.focusOnRender,
		};

		this.onChange = this.onChange.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.showToolTip = this.showToolTip.bind(this);
		this.hideToolTip = this.hideToolTip.bind(this);
	}

	componentDidMount() {
		if (this.state.isFocused && this.ref.current != null) {
			this.ref.current.focus();
		}
	}

	setSelectionRange(start:number, end: number) {
		if(this.ref.current == null) {
			return;
		}
		//@ts-ignore
		if( this.ref.current.createTextRange ) {
			//@ts-ignore
			const selRange = this.current.createTextRange();
			selRange.collapse(true);
			selRange.moveStart('character', start);
			selRange.moveEnd('character', end);
			selRange.select();
			this.ref.current.focus();
		} else if( this.ref.current.setSelectionRange ) {
			this.ref.current.focus();
			this.ref.current.setSelectionRange(start, end);
		} else if( typeof this.ref.current.selectionStart != 'undefined' ) {
				this.ref.current.selectionStart = start;
				this.ref.current.selectionEnd = end;
				this.ref.current.focus();
		}
	}

	componentWillReceiveProps(props: IProps): void {
		if (props != null) {
			if (props.disabled !== this.state.disabled) {
				this.setState({ disabled: props.disabled });
			}
			if (props.defaultValue !== this.state.defaultValue) {
				this.setState({ defaultValue: props.defaultValue });
			}
			if (this.state.error == null || this.props.directSetError === true) {
				this.setState({ error: props.error });
			}
			if (this.valueFromState && props.value !== this.state.value) {
				this.setState({ value: props.value });
			}
			if (
				this.props.showLabel !== false &&
				this.state.label != null &&
				props.label !== this.state.label
			) {
				this.setState({
					label: props.label,
				});
			}
			if (
				this.props.showPlaceholder !== false &&
				this.state.placeHolder != null &&
				props.placeHolder !== this.state.placeHolder
			) {
				this.setState({
					placeHolder: props.transitionInput === true ? ' ' : props.placeHolder,
				});
			}
			if(props.focusOnRender && this.ref.current != null) {
				this.ref.current.focus();
			}
		}
	}

	calculatePasswordStrength(password: string | undefined) {
		if (password == null) {
			return 0;
		}
		const strength = Math.min(1, password.length / 12); // At leat 12 chars needed for a strong password

		let variationMultiplier = 0;
		const lowerCase = /[a-z]+/g.test(password);
		const upperCase = /[A-Z]+/g.test(password);
		const numeric = /[0-9]+/g.test(password);
		const special = /[!@#$%^&*)(+=._-]+/g.test(password);

		if (lowerCase) {
			variationMultiplier += Config.input.variationMultiplierLower;
		}
		if (upperCase) {
			variationMultiplier += Config.input.variationMultiplierUpper;
		}
		if (numeric) {
			variationMultiplier += Config.input.variationMultiplierNumeric;
		}
		if (special) {
			variationMultiplier += Config.input.variationMultiplierspecial;
		}

		return Math.min(1, Math.max(0, variationMultiplier * strength));
	}

	componentDidUpdate() {
		if (this.state.isFocused && this.ref.current != null) {
			this.ref.current.focus();
		}
	}

	onChange(event: any) {
		event.preventDefault();
		event.stopPropagation();

		const value = event.target.value;
		let newValue: string = value;
		let valid = false;
		let errorString: string | undefined = undefined;

		let strength = 0;
		if (this.props.type === 'email') {
			if (Input.validateEmail(value)) {
				valid = true;
				errorString = undefined;
			} else {
				if (value == null || value.length === 0) {
					errorString = translate('input.emailErrorRequired');
				} else {
					errorString = translate('input.emailErrorInvalid');
				}
			}
		} else if (this.props.type === 'password') {
			if (Input.validatePassword(value)) {
				valid = true;
				errorString = undefined;
			} else {
				if (value == null || value.length === 0) {
					errorString = translate('input.passwordErrorRequired');
				} else {
					errorString = translate('input.passwordErrorInvalid');
				}
			}
			strength = this.calculatePasswordStrength(value);
		}

		if (this.valueFromState) {
			if (this.props.type === 'money') {
				if (Input.validateMoney(value)) {
					valid = true;
					newValue = value;
					errorString = undefined;
				} else {
					newValue = this.state.value;
					errorString = translate('input.amountErrorInvalid');
				}
			}
		}

		let className = '';
		if (this.props.showError !== false && errorString != null) {
			className += ' error';
		}
		if (this.props.className != null) {
			className += ' ' + this.props.className;
		}

		const optclassName: { [className: string]: string } = {
			className: className,
		};
		let newState: IState = {
			error: errorString,
			...optclassName,
			strength: strength,
			disabled: this.state.disabled,
		};
		if (this.notification != null) {
			this.notification({
				[this.props.id]: { error: errorString, value: value, valid: valid },
			});
		}

		newState = {
			...newState,
			value: newValue,
		};
		this.setState(newState);
		this.parentOnChange(newValue);
	}

	static validateEmail(email?: string): boolean {
		if (email == null) {
			return false;
		}
		const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z\-\.]{1,25}[.][a-z]{2,5}/g;

		const valid: boolean = pattern.test(email);
		return valid;
	}

	static validatePassword(password?: string): boolean {
		if (password == null) {
			return false;
		}
		const pattern = /.{2,}/g;

		return pattern.test(password);
	}

	static validateMoney(amount?: string): boolean {
		if (amount == null) {
			return false;
		}
		const pattern = /^(\d+[.,]{0,1}\d{0,2})?$/g;
		return pattern.test(amount);
	}

	handleFocus = () => { if(this.state.isFocused === true) { return; } 
						  this.setState({ isFocused: true }) };
	handleBlur = () => { if(this.state.isFocused === false) { return; } 
						    this.setState({ isFocused: false }) };

	handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.nativeEvent.key === 'Enter' && this.props.onSubmit != null) {
            e.stopPropagation();
            e.preventDefault();
            this.props.onSubmit(e as SyntheticEvent);
            this.handleBlur();
            if(this.ref.current != null) {
                this.ref.current.blur();
            }
		 }
	}

	private showToolTip(errorString?: string) {
		const error = errorString != null ? errorString : this.state.error;
		this.hovered = true;
		if (
			error == null ||
			error === '' ||
			this.props.toolTip !== true ||
			this.state.toolTipOpen === true
		) {
			return;
		}
		const el = document.getElementById(this.props.id);
		if (el != null) {
			const box = el.getBoundingClientRect();
			OverlayHandler.showOverlay(Overlays.toolTip, {
				posX: box.x,
				posY: box.y + box.height + 10,
				message: error,
			});
		}
		this.setState({ toolTipOpen: true });
	}

	private hideToolTip() {
		this.hovered = false;
		if (this.state.toolTipOpen === true) {
			OverlayHandler.closeSpecific(Overlays.toolTip);
			this.setState({ toolTipOpen: false });
		}
	}

	private getInputType():
		| 'button'
		| 'checkbox'
		| 'color'
		| 'date'
		| 'datetime-local'
		| 'email'
		| 'file'
		| 'hidden'
		| 'image'
		| 'month'
		| 'number'
		| 'password'
		| 'radio'
		| 'range'
		| 'reset'
		| 'search'
		| 'submit'
		| 'tel'
		| 'text'
		| 'time'
		| 'url'
		| 'week' {
		switch (this.props.type) {
			default:
			case 'off':
			case 'text':
			case 'username':
			case 'name':
			case 'firstname':
			case 'lastname':
			case 'street-address':
			case 'postal-code':
			case 'money':
				return 'text';
			case 'password':
				return 'password';
			case 'email':
				return 'email';
			case 'tel':
				return 'tel';
			case 'file':
				return 'file';
		}
	}

	render() {
		if (
			this.hovered === true &&
			this.state.toolTipOpen !== true &&
			this.props.toolTip === true
		) {
			//this.showToolTip();
		}
		const rawInput: ReactElement = ( //TODO Replace with material.io input
			<StyledTextInput
				style={
					(this.props.input != null && this.props.toolTip !== true) ||
					this.props.error == null ||
					this.props.error === ''
						? this.props.input
						: this.props.toolTip === true &&
						  this.state.error != null &&
						  this.state.error !== ''
						? {
								...this.props.input,
								border: '1px solid grey',
						  }
						: undefined
				}
				id={this.props.id}
				onChange={this.onChange}
				onMouseLeave={() => this.hideToolTip()}
				onMouseEnter={() => {//this.showToolTip()}
								}}
				placeholder=" "
				value={
					this.valueFromState === true
						? this.state.value == null
							? ''
							: this.state.value
						: undefined
				}
				defaultValue={this.state.defaultValue}
				disabled={this.state.disabled === true}
				key={this.props.id + '_input'}
				name={this.props.type}
				onFocus={this.props.onEnter == null ? undefined : this.props.onEnter}
				onBlur={this.props.onLeave == null ? undefined : this.props.onLeave}
				onKeyPress={this.handleKeyDown}
				type={this.getInputType()}
				autoComplete={
					this.props.autoComplete === false || this.props.type === 'password'
						? 'off'
						: 'on'
				}
				ref={this.ref}
			/>
		);
		const input: ReactElement | null = (
			<Container
				style={
					this.props.containerStyle != null ? this.props.containerStyle : undefined
				}>
				{this.props.iconLeft != null ? (
					<IconLeft
						style={
							this.props.iconLeftStyle != null
								? this.props.iconLeftStyle
								: undefined
						}>
						{this.props.iconLeft}
					</IconLeft>
				) : null}
				{rawInput}
				{this.props.iconRight != null ? (
					<IconRight
						style={
							this.props.iconRightStyle != null
								? this.props.iconRightStyle
								: { width: '16px' }
						}>
						{this.props.iconRight}
					</IconRight>
				) : null}
			</Container>
		);
		if (this.props.inline !== true) {
			return (
				<HelpTextWrapper>
					<TransitionWrapper
						disabled={this.props.disabled}
						style={this.props.containerStyle}>
						{rawInput}
						<PlaceHolder style={this.props.input}>
							<TextDivTrans disabled={this.props.disabled}>
								{this.props.label}
							</TextDivTrans>
						</PlaceHolder>
					</TransitionWrapper>
					<Helpertext>
						{this.state.error != null && this.state.error !== ''
							? this.state.error
							: this.props.placeHolder}
					</Helpertext>
					{this.props.type === 'password' ? (
						<StrengthBar
							strength={this.state.strength}
							active={
								this.props.showStrength === true &&
								this.props.type === 'password'
							}
							key={this.props.id + '_strength'}
						/>
					) : null}
				</HelpTextWrapper>
			);
		} else if (this.props.showLabel !== true) {
			return (
				<div className={this.state.className}>
					{input}
					{this.props.toolTip !== true ? (
						<FormError
							error={this.state.error}
							active={this.props.showError}
							key={this.props.id + 'error'}
						/>
					) : null}
					{this.props.type === 'password' ? (
						<StrengthBar
							strength={this.state.strength}
							active={this.props.showStrength === true}
							key={this.props.id + '_strength'}
						/>
					) : null}
				</div>
			);
		} else {
			return (
				<label
					htmlFor={this.props.id}
					style={this.props.labelStyle != null ? this.props.labelStyle : undefined}
					className={this.state.className}>
					{this.state.label}
					{input}
					{this.props.toolTip !== true ? (
						<FormError
							error={this.state.error}
							active={this.props.showError}
							key={this.props.id + 'error'}
						/>
					) : null}
					{this.props.type === 'password' ? (
						<StrengthBar
							strength={this.state.strength}
							active={
								this.props.showStrength === true &&
								this.props.type === 'password'
							}
							key={this.props.id + '_strength'}
						/>
					) : null}
				</label>
			);
		}
	}
}

const IconLeft = styled.div`
	margin-right: -20px;
	width: 20px;
	height: 20px;
`;

const IconRight = styled.div`
	margin-left: -30px;
	width: 20px;
	height: 20px;
`;
const Container = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	width: 360px;
`;

const Helpertext = styled.div`
	font-size: 10px;
	color: ${(props) => props.theme.Button.backgroundColor};
`;

const HelpTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
	width: 100%;
`;
const PlaceHolder = styled.div`
	padding-left: 10px;
	padding-right: 5px;
	pointer-events: none;
	position: absolute;
	left: 0;
	top: 10px;
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	transition: 0.2s;
	opacity: 0.5;
	
`;

const TextDivTrans = styled.span<{ disabled?: boolean }>`
	background-color: rgba(0, 0, 0, 0);
	display: inline;
	padding: 5px;
	border-radius: 5px;
	overflow: hidden;
    white-space:nowrap;
	text-overflow:ellipsis;
	max-width: 150px;
	`;

const TransitionWrapper = styled.div<{ disabled?: boolean }>`
	margin: 10px 0;
	position: relative;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	input:focus + div,
	input:not(:placeholder-shown) + div {
		opacity: 1;
		transform: scale(0.75) translateY(-125%) translateX(-18%);
		padding-left: 25px;
	}
	input:focus + div > span,
	input:not(:placeholder-shown) + div > span {
		background-color: white !important;
	}
	input:focus {
		border: 2px solid ${(props) => props.theme.Button.backgroundColor};
		transition: 0.2s ease-in;
		outline: none;
		height: 42px;
	}
	input {
		height: 40px;
		line-heigth: 20px;
		font-size: 16px;
		padding-left: 12px;
		border: 1px solid ${(props) => props.theme.ButtonDisabled.backgroundColor};
		box-sizing: border-box;
		border-radius: 5px;
		box-shadow: ${(props) => props.theme.Box.boxShadow};
	}
`;

// Not fixable due to line length and styled-components patterns
// eslint-disable-next-line no-unexpected-multiline
const StyledTextInput = styled.input<{
	disabled?: boolean;
	iconLeft?: boolean;
	iconRight?: boolean;
}>`
	flex: 1;
	background-color: ${(props) =>
		props.disabled === true ? props.theme.Input.disabled : props.theme.Input.background};
	padding-left: ${(props) => (props.iconLeft === true ? '25px' : 0)};
	padding-right: ${(props) => (props.iconRight === true ? '25px' : 0)};
	height: inherit;
	filter: none;
	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 100px white inset;
	}
`;
/*
secureTextEntry={this.props.type === 'password'}
                iconleft={this.props.iconLeft != null}
                iconRight={this.props.iconRight != null}
                */
