// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders } from '../runtime';
import {
    StdAuthRequest,
} from '../models';

export interface AccessKeyPostRequest {
    StdAuthRequest?: StdAuthRequest;
}

/**
 * no description
 */
export class MerchantApi extends BaseAPI implements IOperationIdMap {

    /**
     * login using access key
     * login using access key
     */
    accessKeyPost = (requestParameters: AccessKeyPostRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<void>({
            path: '/oauth/access_key',
            method: 'POST',
            headers,
            body: requestParameters.StdAuthRequest,
        });
    };

    /**
     * activate an access key
     * activate an access_key
     */
    accessKeyPut = (): Observable<void> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<void>({
            path: '/oauth/access_key',
            method: 'PUT',
            headers,
        });
    };


    operationToOperationId = {
	    "accessKeyPost": "access_key_post" ,
	    "accessKeyPut": "access_key_put"
    }
}
