//@ts-nocheck
import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Icons } from '../../../images';
import { IRelativeProps, IRelativeState, RelativeOverlay } from '../../../logic/handler/overlayhandler/globaloverlays/aRelativeOverlay';

export enum FileType {
	csv = 'csv', // Colon seperated file
	docx = 'docx', // Word
	xls = 'xlsx', // Excel Stylesheet
	pdf = 'pdf', // PDF
	inApp = 'inApp'
}

interface IProps extends IRelativeProps {
	callback: (filetype: FileType) => void;
	width?: string;
	displayReport?: boolean;
}
//ToDo Use this as ImageChooseroverlay, they are very similar
interface IState extends IRelativeState {}

export default class FileTypeChooserOverlay extends RelativeOverlay<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {};
	}

	reviveState() {}

	private _selectFileType(event: SyntheticEvent, filetype: FileType): void {
		event.preventDefault();
		event.stopPropagation();

		if (this.props.callback != null && typeof this.props.callback === 'function') {
			this.props.callback(filetype);
		}

		if (this.props.selfClose != null && typeof this.props.selfClose === 'function') {
			this.props.selfClose();
		}
	}

	defineContent(): ReactNode {
		return (
			<Container x={this.props.posX} y={this.props.posY}>
				{this.props.displayReport === true ?
				<SelectorB  width={this.props.width}  onClick={(event) => this._selectFileType(event, FileType.inApp)}>
					<div style={{marginTop: '5px', width: '24px', height: '24px', marginLeft: '8px' }}>{Icons.detailOverlay()}</div>
				</SelectorB> : null }
				<SelectorB  width={this.props.width}  onClick={(event) => this._selectFileType(event, FileType.csv)}>
					<div style={{marginTop: '5px', width: '24px', height: '24px', marginLeft: '8px' }}>{Icons.csv()}</div>
				</SelectorB>
				<SelectorC  width={this.props.width}  onClick={(event) => this._selectFileType(event, FileType.xls)}>
					<div style={{marginTop: '5px', width: '24px', height: '24px', marginLeft: '5px' }}>{Icons.excel()}</div>
				</SelectorC>
				<FileSelector  width={this.props.width} onClick={(event) => this._selectFileType(event, FileType.pdf)}>
					<div style={{marginTop: '5px' }}>{Icons.pdf()}</div>
				</FileSelector>
			</Container>
		);
	}
}

const Container = styled.div<{ x: string; y: string }>`
	position: absolute;
	top: ${(props) => props.y + 'px'};
	left: ${(props) => props.x + 'px'};
	background-color: rgba(0,0,0,0);
	display: flex;
	z-index: 5000;
	flex-direction: column;
`;

const FileSelector = styled.div<{ width?: string;}>`
	width:  ${(props) => props.width != null ? props.width : '100%'};
	text-align: center;
	line-height: 40px;
	background-color: #FFFFFF;
	border-radius: 5px;
	border: 1px solid  ${(props) => (props.theme.Button.backgroundColor)};
	height:40px;
	display: flex;
	justify-content: center;
	margin: 2px;
	svg {
		fill: ${(props) => (props.theme.Global.lightFontColor)};
	}
	:hover {
		background-color: rgb(189, 190, 192);
		cursor: pointer;
	}
	-webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 0.5s; /* Firefox < 16 */
	-ms-animation: fadein 0.5s; /* Internet Explorer */
	-o-animation: fadein 0.5s; /* Opera < 12.1 */
	animation: fadein 0.5s;
	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	
	/* Firefox < 16 */
	@-moz-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	
	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	
	/* Internet Explorer */
	@-ms-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	
	/* Opera < 12.1 */
	@-o-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
`;

const SelectorB = styled(FileSelector)`
	-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 2s; /* Firefox < 16 */
	-ms-animation: fadein 2s; /* Internet Explorer */
	-o-animation: fadein 2s; /* Opera < 12.1 */
	animation: fadein 2s;
	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Firefox < 16 */
	@-moz-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Internet Explorer */
	@-ms-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Opera < 12.1 */
	@-o-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
`;

const SelectorC = styled(FileSelector)`
	-webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1.5s; /* Firefox < 16 */
	-ms-animation: fadein 1.5s; /* Internet Explorer */
	-o-animation: fadein 1.5s; /* Opera < 12.1 */
	animation: fadein 1.5s;
	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Firefox < 16 */
	@-moz-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Internet Explorer */
	@-ms-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Opera < 12.1 */
	@-o-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
`;
