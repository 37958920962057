// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    AccessKey,
    AccessKeyWithPassword,
    ActedDocument,
    Address,
    AddressId,
    ClientPendingRequests,
    CompanionCodeDetail,
    CompanionCodeRequest,
    CompanionCodeResponse,
    CompanionCodes,
    CompanionRelationship,
    CompanionRequest,
    CompanionRequestId,
    Companions,
    ContentDocumentType,
    CreateApplicantResponse,
    DocumentAction,
    EmailAddress,
    EmailValidation,
    Face,
    FaceId,
    GenerateSDKTokenResponse,
    GetStreamToken,
    GetStreamUser,
    JointPerson,
    JointPersonCreated,
    NewAddress,
    NewEmailAddress,
    NewEmoneyPerson,
    NewPhone,
    OnfidoGenerateSdkTokenRequest,
    OnfidocreateCheckRequest,
    Passport,
    Password,
    PendingRequestIdentifier,
    PersonDocument,
    PersonDocumentType,
    PersonId,
    PersonKYCStatus,
    PersonUpdateRequest,
    Phone,
    PhoneId,
    PhoneValidation,
    Referrals,
    Settings,
    TransferToken,
    UnreadDocuments,
    VideoIdError,
    VideoIdResponse,
    VideoIdResponseData,
    VideoIdUpdateRequest,
} from '../models';

export interface PersonUnreadDocumentsGetRequest {
    identifier: ContentDocumentType;
    language?: PersonUnreadDocumentsGetLanguageEnum;
}

export interface PersonUnreadDocumentsPutRequest {
    DocumentAction: DocumentAction;
}

export interface SelfAccessKeyDeleteRequest {
    access_key: string;
}

export interface SelfAccessKeyGetRequest {
    show_deactivated?: boolean;
}

export interface SelfAccessKeyPostRequest {
    account_number: string;
    name: string;
    qr: string;
    token: string;
}

export interface SelfAddressDeleteRequest {
    address_id: number;
}

export interface SelfAddressPostRequest {
    NewAddress: NewAddress;
}

export interface SelfAddressPutRequest {
    address_id: number;
    NewAddress: NewAddress;
}

export interface SelfCompanionCodeDeleteRequest {
    companion_code: string;
}

export interface SelfCompanionCodeDetailRequest {
    companion_code: string;
}

export interface SelfCompanionCodeExtendedPostRequest {
    CompanionCodeRequest: CompanionCodeRequest;
}

export interface SelfCompanionDeleteRequest {
    person_id: number;
}

export interface SelfCompanionRequestDeleteRequest {
    companion_request_id: number;
}

export interface SelfCompanionRequestPostRequest {
    companion_code: string;
    relationship: CompanionRelationship;
}

export interface SelfCompanionRequestPutRequest {
    companion_request_id: number;
    relationship?: CompanionRelationship;
}

export interface SelfDocumentDeleteRequest {
    person_document_id: number;
}

export interface SelfDocumentGetRequest {
    person_document_id: number;
    thumbnail?: boolean;
}

export interface SelfDocumentListRequest {
    document_type?: PersonDocumentType;
}

export interface SelfDocumentPostRequest {
    document_type: PersonDocumentType;
    document: Blob;
}

export interface SelfElectronicidVideoidFailedGetRequest {
    session_id: string;
}

export interface SelfElectronicidVideoidRequestPutRequest {
    VideoIdUpdateRequest: VideoIdUpdateRequest;
}

export interface SelfElectronicidVideoidResponsePostRequest {
    video_id_data: VideoIdResponseData;
    document?: Blob;
    document_2?: Blob;
    document_3?: Blob;
    document_4?: Blob;
    document_5?: Blob;
}

export interface SelfEmailAddressGetRequest {
    get_not_verified?: boolean;
}

export interface SelfEmailAddressPostRequest {
    NewEmailAddress: NewEmailAddress;
}

export interface SelfEmailAddressPutRequest {
    EmailValidation: EmailValidation;
}

export interface SelfFaceDeleteRequest {
    face_id: number;
}

export interface SelfFaceGetRequest {
    face_id: number;
}

export interface SelfFacePostRequest {
    face_image: Blob;
}

export interface SelfGetstreamCreateUserPostRequest {
    GetStreamUser: GetStreamUser;
    get_or_create?: boolean;
}

export interface SelfJointPersonCreateRequest {
    JointPerson: JointPerson;
}

export interface SelfOnfidoCreateCheckPostRequest {
    OnfidocreateCheckRequest: OnfidocreateCheckRequest;
}

export interface SelfOnfidoGenerateSdkTokenGetRequest {
    applicant_id: string;
}

export interface SelfOnfidoGenerateSdkTokenPostRequest {
    OnfidoGenerateSdkTokenRequest: OnfidoGenerateSdkTokenRequest;
}

export interface SelfPasswordPutRequest {
    Password: Password;
}

export interface SelfPendingRequestsPutRequest {
    PendingRequestIdentifier: PendingRequestIdentifier;
}

export interface SelfPersonPutRequest {
    PersonUpdateRequest: PersonUpdateRequest;
}

export interface SelfPhoneDeleteRequest {
    phone_number_id: number;
}

export interface SelfPhonePostRequest {
    NewPhone: NewPhone;
}

export interface SelfPhonePutRequest {
    PhoneValidation: PhoneValidation;
}

export interface SelfReferralsGetRequest {
    date_from?: Date;
    date_to?: Date;
}

export interface SelfRegisterCustomerRequest {
    NewEmoneyPerson: NewEmoneyPerson;
}

export interface SelfRegisterStaffRequest {
    NewEmoneyPerson: NewEmoneyPerson;
}

export interface SelfSettingsPutRequest {
    Settings: Settings;
}

/**
 * no description
 */
export class SelfServiceApi extends BaseAPI implements IOperationIdMap {

    /**
     * Get documents the selected person acted on
     * Get acted docs
     */
    personActedDocumentsGet = (): Observable<Array<ActedDocument>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ActedDocument>>({
            path: '/self/acted_documents',
            method: 'GET',
            headers,
        });
    };

    /**
     * Get current settings for selected person
     * Get unread docs
     */
    personUnreadDocumentsGet = (requestParameters: PersonUnreadDocumentsGetRequest): Observable<UnreadDocuments> => {
        throwIfRequired(requestParameters, 'identifier', 'personUnreadDocumentsGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.identifier && { 'identifier': requestParameters.identifier }),
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<UnreadDocuments>({
            path: '/self/unread_documents',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Set current settings for selected person
     * perform action on document for user
     */
    personUnreadDocumentsPut = (requestParameters: PersonUnreadDocumentsPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'DocumentAction', 'personUnreadDocumentsPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/unread_documents',
            method: 'PUT',
            headers,
            body: requestParameters.DocumentAction,
        });
    };

    /**
     * deactivate an access key
     */
    selfAccessKeyDelete = (requestParameters: SelfAccessKeyDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'access_key', 'selfAccessKeyDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.access_key && { 'access_key': requestParameters.access_key }),
        };

        return this.request<void>({
            path: '/self/access_key',
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * get issued access key
     */
    selfAccessKeyGet = (requestParameters: SelfAccessKeyGetRequest): Observable<Array<AccessKey>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.show_deactivated && { 'show_deactivated': requestParameters.show_deactivated }),
        };

        return this.request<Array<AccessKey>>({
            path: '/self/access_key',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * please note that the only time the only time the secret_key is shown is in this response
     * create an access key for an account
     */
    selfAccessKeyPost = (requestParameters: SelfAccessKeyPostRequest): Observable<AccessKeyWithPassword> => {
        throwIfRequired(requestParameters, 'account_number', 'selfAccessKeyPost');
        throwIfRequired(requestParameters, 'name', 'selfAccessKeyPost');
        throwIfRequired(requestParameters, 'qr', 'selfAccessKeyPost');
        throwIfRequired(requestParameters, 'token', 'selfAccessKeyPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.name && { 'name': requestParameters.name }),
            ...(requestParameters.qr && { 'qr': requestParameters.qr }),
            ...(requestParameters.token && { 'token': requestParameters.token }),
        };

        return this.request<AccessKeyWithPassword>({
            path: '/self/access_key/{account_number}'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * limit the validity of the connection person - address
     * delete address
     */
    selfAddressDelete = (requestParameters: SelfAddressDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'address_id', 'selfAddressDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/address/{address_id}'.replace('{address_id}', encodeURI(requestParameters.address_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get all addresses of a person
     * get addresses
     */
    selfAddressGet = (): Observable<Array<Address>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Address>>({
            path: '/self/address',
            method: 'GET',
            headers,
        });
    };

    /**
     * add a new address to an already created person
     * add address to person
     */
    selfAddressPost = (requestParameters: SelfAddressPostRequest): Observable<AddressId> => {
        throwIfRequired(requestParameters, 'NewAddress', 'selfAddressPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<AddressId>({
            path: '/self/address',
            method: 'POST',
            headers,
            body: requestParameters.NewAddress,
        });
    };

    /**
     * change an already existing address of a person
     * change address
     */
    selfAddressPut = (requestParameters: SelfAddressPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'address_id', 'selfAddressPut');
        throwIfRequired(requestParameters, 'NewAddress', 'selfAddressPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/address/{address_id}'.replace('{address_id}', encodeURI(requestParameters.address_id)),
            method: 'PUT',
            headers,
            body: requestParameters.NewAddress,
        });
    };

    /**
     * deactivate an companion code
     * deactivate an companion code
     */
    selfCompanionCodeDelete = (requestParameters: SelfCompanionCodeDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'companion_code', 'selfCompanionCodeDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.companion_code && { 'companion_code': requestParameters.companion_code }),
        };

        return this.request<void>({
            path: '/self/companion_code',
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * validate a companion code and show  details
     * validate a companion code
     */
    selfCompanionCodeDetail = (requestParameters: SelfCompanionCodeDetailRequest): Observable<CompanionCodeDetail> => {
        throwIfRequired(requestParameters, 'companion_code', 'selfCompanionCodeDetail');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.companion_code && { 'companion_code': requestParameters.companion_code }),
        };

        return this.request<CompanionCodeDetail>({
            path: '/self/companion_code_detail',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * create companion codes
     * create a companion code using customers data
     */
    selfCompanionCodeExtendedPost = (requestParameters: SelfCompanionCodeExtendedPostRequest): Observable<CompanionCodeResponse> => {
        throwIfRequired(requestParameters, 'CompanionCodeRequest', 'selfCompanionCodeExtendedPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<CompanionCodeResponse>({
            path: '/self/companion_code_extended',
            method: 'POST',
            headers,
            body: requestParameters.CompanionCodeRequest,
        });
    };

    /**
     * list companion codes issued by the current person
     * list active companion codes
     */
    selfCompanionCodeList = (): Observable<Array<CompanionCodes>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<CompanionCodes>>({
            path: '/self/companion_code',
            method: 'GET',
            headers,
        });
    };

    /**
     * create companion codes
     * create a companion code
     */
    selfCompanionCodePost = (): Observable<CompanionCodeResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<CompanionCodeResponse>({
            path: '/self/companion_code',
            method: 'POST',
            headers,
        });
    };

    /**
     * remove primary-companion relation between current person and the given one
     * remove primary-companion relation
     */
    selfCompanionDelete = (requestParameters: SelfCompanionDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'selfCompanionDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.person_id && { 'person_id': requestParameters.person_id }),
        };

        return this.request<void>({
            path: '/self/companion',
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * list current primary-companion relations the current person has
     * list current primary-companion relations
     */
    selfCompanionList = (): Observable<Array<Companions>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Companions>>({
            path: '/self/companion',
            method: 'GET',
            headers,
        });
    };

    /**
     * depending on request_type cancel/deny the request
     * cancel/deny a companion request
     */
    selfCompanionRequestDelete = (requestParameters: SelfCompanionRequestDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'companion_request_id', 'selfCompanionRequestDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.companion_request_id && { 'companion_request_id': requestParameters.companion_request_id }),
        };

        return this.request<void>({
            path: '/self/companion_request',
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * list companion requests, related to the current person
     * list companion requests
     */
    selfCompanionRequestList = (): Observable<Array<CompanionRequest>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<CompanionRequest>>({
            path: '/self/companion_request',
            method: 'GET',
            headers,
        });
    };

    /**
     * ask another person to agree in a primary - companion relationship. until we are asked to do something else, we assume the current person agrees with that. this request will work if and only if:   * both persons have a client_profile set, either through kyc or initial phone number   * the client profiles differ(one is primary the other is companion)   * the companion code is still valid 
     * create a companion request
     */
    selfCompanionRequestPost = (requestParameters: SelfCompanionRequestPostRequest): Observable<CompanionRequestId> => {
        throwIfRequired(requestParameters, 'companion_code', 'selfCompanionRequestPost');
        throwIfRequired(requestParameters, 'relationship', 'selfCompanionRequestPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.companion_code && { 'companion_code': requestParameters.companion_code }),
            ...(requestParameters.relationship && { 'relationship': requestParameters.relationship }),
        };

        return this.request<CompanionRequestId>({
            path: '/self/companion_request',
            method: 'POST',
            headers,
            query,
        });
    };

    /**
     * accept a pending request as companion or primary, depending on request type. if primary and companion agreed, the connection will be established and visible in the persons data. however this might finally fail as one primary may have up to five companions 
     * accept a companion request
     */
    selfCompanionRequestPut = (requestParameters: SelfCompanionRequestPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'companion_request_id', 'selfCompanionRequestPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.companion_request_id && { 'companion_request_id': requestParameters.companion_request_id }),
            ...(requestParameters.relationship && { 'relationship': requestParameters.relationship }),
        };

        return this.request<void>({
            path: '/self/companion_request',
            method: 'PUT',
            headers,
            query,
        });
    };

    /**
     * delete the document, that was uploaded 
     * delete person document
     */
    selfDocumentDelete = (requestParameters: SelfDocumentDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_document_id', 'selfDocumentDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/document/{person_document_id}'.replace('{person_document_id}', encodeURI(requestParameters.person_document_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get the document, that was uploaded 
     * get person document
     */
    selfDocumentGet = (requestParameters: SelfDocumentGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_document_id', 'selfDocumentGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.thumbnail && { 'thumbnail': requestParameters.thumbnail }),
        };

        return this.request<Blob>({
            path: '/self/document/{person_document_id}'.replace('{person_document_id}', encodeURI(requestParameters.person_document_id)),
            method: 'GET',
            headers,
            query,
            responseType: 'blob'
        });
    };

    /**
     * list all documents a person has stored
     * list current person documents
     */
    selfDocumentList = (requestParameters: SelfDocumentListRequest): Observable<Array<PersonDocument>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.document_type && { 'document_type': requestParameters.document_type }),
        };

        return this.request<Array<PersonDocument>>({
            path: '/self/documents',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * add a new document
     * upload a person document
     */
    selfDocumentPost = (requestParameters: SelfDocumentPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'document_type', 'selfDocumentPost');
        throwIfRequired(requestParameters, 'document', 'selfDocumentPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.document_type !== undefined) {
            formData.append('document_type', requestParameters.document_type as any);
        }

        if (requestParameters.document !== undefined) {
            formData.append('document', requestParameters.document as any);
        }

        return this.request<void>({
            path: '/self/document',
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * get errors as reported by E-ID for a session ID
     * get session error
     */
    selfElectronicidVideoidFailedGet = (requestParameters: SelfElectronicidVideoidFailedGetRequest): Observable<VideoIdError> => {
        throwIfRequired(requestParameters, 'session_id', 'selfElectronicidVideoidFailedGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<VideoIdError>({
            path: '/self/electronic_id/{session_id}/videoid_failed'.replace('{session_id}', encodeURI(requestParameters.session_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * get id and authorization to do an eid video identification
     * requesting a videoid authorization
     */
    selfElectronicidVideoidRequestPost = (): Observable<VideoIdResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<VideoIdResponse>({
            path: '/self/electronic_id/videoid.request',
            method: 'POST',
            headers,
        });
    };

    /**
     * add a video id to an existing videoid.request
     * finish a videoid authorization
     */
    selfElectronicidVideoidRequestPut = (requestParameters: SelfElectronicidVideoidRequestPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'VideoIdUpdateRequest', 'selfElectronicidVideoidRequestPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/electronic_id/videoid.request',
            method: 'PUT',
            headers,
            body: requestParameters.VideoIdUpdateRequest,
        });
    };

    /**
     * add a video id to an existing videoid.request
     * finish a videoid authorization
     */
    selfElectronicidVideoidResponsePost = (requestParameters: SelfElectronicidVideoidResponsePostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'video_id_data', 'selfElectronicidVideoidResponsePost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.video_id_data !== undefined) {
            formData.append('video_id_data', requestParameters.video_id_data as any);
        }

        if (requestParameters.document !== undefined) {
            formData.append('document', requestParameters.document as any);
        }

        if (requestParameters.document_2 !== undefined) {
            formData.append('document_2', requestParameters.document_2 as any);
        }

        if (requestParameters.document_3 !== undefined) {
            formData.append('document_3', requestParameters.document_3 as any);
        }

        if (requestParameters.document_4 !== undefined) {
            formData.append('document_4', requestParameters.document_4 as any);
        }

        if (requestParameters.document_5 !== undefined) {
            formData.append('document_5', requestParameters.document_5 as any);
        }

        return this.request<void>({
            path: '/self/electronic_id/videoid.response',
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * get all emailaddresses of a person
     * get emailaddresses
     */
    selfEmailAddressGet = (requestParameters: SelfEmailAddressGetRequest): Observable<Array<EmailAddress>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.get_not_verified && { 'get_not_verified': requestParameters.get_not_verified }),
        };

        return this.request<Array<EmailAddress>>({
            path: '/self/email_address/',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * change email address of an existing person
     * change email address of person
     */
    selfEmailAddressPost = (requestParameters: SelfEmailAddressPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'NewEmailAddress', 'selfEmailAddressPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/email_address/',
            method: 'POST',
            headers,
            body: requestParameters.NewEmailAddress,
        });
    };

    /**
     * validate an email address using verification code received via email
     */
    selfEmailAddressPut = (requestParameters: SelfEmailAddressPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'EmailValidation', 'selfEmailAddressPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/email_address/',
            method: 'PUT',
            headers,
            body: requestParameters.EmailValidation,
        });
    };

    /**
     * limit the validity of the connection person - face
     * delete a face
     */
    selfFaceDelete = (requestParameters: SelfFaceDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'face_id', 'selfFaceDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.face_id && { 'face_id': requestParameters.face_id }),
        };

        return this.request<void>({
            path: '/self/face',
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * get a specificv face image
     * get a face image
     */
    selfFaceGet = (requestParameters: SelfFaceGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'face_id', 'selfFaceGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.face_id && { 'face_id': requestParameters.face_id }),
        };

        return this.request<Blob>({
            path: '/self/face',
            method: 'GET',
            headers,
            query,
            responseType: 'blob'
        });
    };

    /**
     * get the current faceimage of a person
     * get the current face image
     */
    selfFaceGetCurrent = (): Observable<Blob> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'qr': this.configuration.apiKey('qr') }), // qrAuth authentication
        };

        return this.request<Blob>({
            path: '/self/face/current',
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * get all faceimages of a person
     * get all face images
     */
    selfFaceList = (): Observable<Array<Face>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Face>>({
            path: '/self/faces',
            method: 'GET',
            headers,
        });
    };

    /**
     * add a new face to an already created person
     * add face to person
     */
    selfFacePost = (requestParameters: SelfFacePostRequest): Observable<FaceId> => {
        throwIfRequired(requestParameters, 'face_image', 'selfFacePost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.face_image !== undefined) {
            formData.append('face_image', requestParameters.face_image as any);
        }

        return this.request<FaceId>({
            path: '/self/face',
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * add a new getstreamuser to an already created person.
     * create new jttoken
     */
    selfGetstreamCreateTokenGet = (): Observable<GetStreamToken> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<GetStreamToken>({
            path: '/self/getstream/jwttoken',
            method: 'GET',
            headers,
        });
    };

    /**
     * add a new getstreamuser to an already created person.
     * create new getstreamuser
     */
    selfGetstreamCreateUserPost = (requestParameters: SelfGetstreamCreateUserPostRequest): Observable<object> => {
        throwIfRequired(requestParameters, 'GetStreamUser', 'selfGetstreamCreateUserPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.get_or_create && { 'get_or_create': requestParameters.get_or_create }),
        };

        return this.request<object>({
            path: '/self/getstream/user',
            method: 'POST',
            headers,
            query,
            body: requestParameters.GetStreamUser,
        });
    };

    /**
     * add a joint person, linked to the current one
     * add a joint person, linked to the current one
     */
    selfJointPersonCreate = (requestParameters: SelfJointPersonCreateRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'JointPerson', 'selfJointPersonCreate');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/joint_person',
            method: 'POST',
            headers,
            body: requestParameters.JointPerson,
        });
    };

    /**
     * list all persons with a joint relationship to the curret user
     * list current joint persons
     */
    selfJointPersonList = (): Observable<Array<JointPersonCreated>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<JointPersonCreated>>({
            path: '/self/joint_person',
            method: 'GET',
            headers,
        });
    };

    /**
     * get the latest kyc status of a person
     */
    selfKycStatusGet = (): Observable<PersonKYCStatus> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<PersonKYCStatus>({
            path: '/self/kyc_status',
            method: 'GET',
            headers,
        });
    };

    /**
     * get applicant id for onfido identification
     * requesting to create applicant id
     */
    selfOnfidoCreateApplicantPost = (): Observable<CreateApplicantResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<CreateApplicantResponse>({
            path: '/self/onfido/create_applicant',
            method: 'POST',
            headers,
        });
    };

    /**
     * create check for onfido identification
     * requesting to create check
     */
    selfOnfidoCreateCheckPost = (requestParameters: SelfOnfidoCreateCheckPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'OnfidocreateCheckRequest', 'selfOnfidoCreateCheckPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/onfido/create_check',
            method: 'POST',
            headers,
            body: requestParameters.OnfidocreateCheckRequest,
        });
    };

    /**
     * get generate sdk_token for onfido identification
     * requesting to generate sdk_token
     */
    selfOnfidoGenerateSdkTokenGet = (requestParameters: SelfOnfidoGenerateSdkTokenGetRequest): Observable<GenerateSDKTokenResponse> => {
        throwIfRequired(requestParameters, 'applicant_id', 'selfOnfidoGenerateSdkTokenGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.applicant_id && { 'applicant_id': requestParameters.applicant_id }),
        };

        return this.request<GenerateSDKTokenResponse>({
            path: '/self/onfido/generate_sdk_token',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get generate sdk_token for onfido identification
     * requesting to generate sdk_token
     */
    selfOnfidoGenerateSdkTokenPost = (requestParameters: SelfOnfidoGenerateSdkTokenPostRequest): Observable<GenerateSDKTokenResponse> => {
        throwIfRequired(requestParameters, 'OnfidoGenerateSdkTokenRequest', 'selfOnfidoGenerateSdkTokenPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<GenerateSDKTokenResponse>({
            path: '/self/onfido/generate_sdk_token',
            method: 'POST',
            headers,
            body: requestParameters.OnfidoGenerateSdkTokenRequest,
        });
    };

    /**
     * get all passports of a person
     * get passports
     */
    selfPassportGet = (): Observable<Array<Passport>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Passport>>({
            path: '/self/passport',
            method: 'GET',
            headers,
        });
    };

    /**
     * the application is expected to ask the user for his password and double check itt
     * set new password for current user
     */
    selfPasswordPut = (requestParameters: SelfPasswordPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'Password', 'selfPasswordPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/password',
            method: 'PUT',
            headers,
            body: requestParameters.Password,
        });
    };

    /**
     * get all addresses of a person
     * get addresses
     */
    selfPendingRequestsGet = (): Observable<Array<any>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<any>>({
            path: '/self/pending_requests',
            method: 'GET',
            headers,
        });
    };

    /**
     * change an already existing address of a person
     * set request
     */
    selfPendingRequestsPut = (requestParameters: SelfPendingRequestsPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'PendingRequestIdentifier', 'selfPendingRequestsPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/pending_requests',
            method: 'PUT',
            headers,
            body: requestParameters.PendingRequestIdentifier,
        });
    };

    /**
     * initiate closure of person and account
     * close the person and account
     */
    selfPersonDelete = (): Observable<void> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/person',
            method: 'DELETE',
            headers,
        });
    };

    /**
     * change current persons data
     * change current persons data
     */
    selfPersonPut = (requestParameters: SelfPersonPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'PersonUpdateRequest', 'selfPersonPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/person',
            method: 'PUT',
            headers,
            body: requestParameters.PersonUpdateRequest,
        });
    };

    /**
     * limit the validity of the connection person - phone
     * delete phone
     */
    selfPhoneDelete = (requestParameters: SelfPhoneDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'phone_number_id', 'selfPhoneDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.phone_number_id && { 'phone_number_id': requestParameters.phone_number_id }),
        };

        return this.request<void>({
            path: '/self/phone',
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * get all phone numbers of a person
     * get phone numbers
     */
    selfPhoneList = (): Observable<Array<Phone>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Phone>>({
            path: '/self/phone',
            method: 'GET',
            headers,
        });
    };

    /**
     * add a new phone to an already created person.  please note: only mobile phone number will be accepted and the phone number needs to be verified through the sms code sent to it
     * add phone to person
     */
    selfPhonePost = (requestParameters: SelfPhonePostRequest): Observable<PhoneId> => {
        throwIfRequired(requestParameters, 'NewPhone', 'selfPhonePost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<PhoneId>({
            path: '/self/phone',
            method: 'POST',
            headers,
            body: requestParameters.NewPhone,
        });
    };

    /**
     * validate a phone number using verification code received via sms
     */
    selfPhonePut = (requestParameters: SelfPhonePutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'PhoneValidation', 'selfPhonePut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/phone',
            method: 'PUT',
            headers,
            body: requestParameters.PhoneValidation,
        });
    };

    /**
     * get number of referrals
     */
    selfReferralsGet = (requestParameters: SelfReferralsGetRequest): Observable<Referrals> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<Referrals>({
            path: '/self/referrals',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * register a customer
     */
    selfRegisterCustomer = (requestParameters: SelfRegisterCustomerRequest): Observable<PersonId> => {
        throwIfRequired(requestParameters, 'NewEmoneyPerson', 'selfRegisterCustomer');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<PersonId>({
            path: '/self/merchant/register_customer',
            method: 'POST',
            headers,
            body: requestParameters.NewEmoneyPerson,
        });
    };

    /**
     * register a customer
     */
    selfRegisterStaff = (requestParameters: SelfRegisterStaffRequest): Observable<PersonId> => {
        throwIfRequired(requestParameters, 'NewEmoneyPerson', 'selfRegisterStaff');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<PersonId>({
            path: '/self/merchant/register_staff',
            method: 'POST',
            headers,
            body: requestParameters.NewEmoneyPerson,
        });
    };

    /**
     * Get current settings own person
     * Get settings
     */
    selfSettingsGet = (): Observable<Settings> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Settings>({
            path: '/self/settings',
            method: 'GET',
            headers,
        });
    };

    /**
     * Set current settings own selected person
     * Set language
     */
    selfSettingsPut = (requestParameters: SelfSettingsPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'Settings', 'selfSettingsPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/self/settings',
            method: 'PUT',
            headers,
            body: requestParameters.Settings,
        });
    };

    /**
     * Get current settings for selected person
     * get a transfer token
     */
    selfTokenGet = (): Observable<TransferToken> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<TransferToken>({
            path: '/self/token',
            method: 'GET',
            headers,
        });
    };


    operationToOperationId = {
	    "personActedDocumentsGet": "person_acted_documents_get" ,
	    "personUnreadDocumentsGet": "person_unread_documents_get" ,
	    "personUnreadDocumentsPut": "person_unread_documents_put" ,
	    "selfAccessKeyDelete": "self_access_key_delete" ,
	    "selfAccessKeyGet": "self_access_key_get" ,
	    "selfAccessKeyPost": "self_access_key_post" ,
	    "selfAddressDelete": "self_address_delete" ,
	    "selfAddressGet": "self_address_get" ,
	    "selfAddressPost": "self_address_post" ,
	    "selfAddressPut": "self_address_put" ,
	    "selfCompanionCodeDelete": "self_companion_code_delete" ,
	    "selfCompanionCodeDetail": "self_companion_code_detail" ,
	    "selfCompanionCodeExtendedPost": "self_companion_code_extended_post" ,
	    "selfCompanionCodeList": "self_companion_code_list" ,
	    "selfCompanionCodePost": "self_companion_code_post" ,
	    "selfCompanionDelete": "self_companion_delete" ,
	    "selfCompanionList": "self_companion_list" ,
	    "selfCompanionRequestDelete": "self_companion_request_delete" ,
	    "selfCompanionRequestList": "self_companion_request_list" ,
	    "selfCompanionRequestPost": "self_companion_request_post" ,
	    "selfCompanionRequestPut": "self_companion_request_put" ,
	    "selfDocumentDelete": "self_document_delete" ,
	    "selfDocumentGet": "self_document_get" ,
	    "selfDocumentList": "self_document_list" ,
	    "selfDocumentPost": "self_document_post" ,
	    "selfElectronicidVideoidFailedGet": "self_electronicid_videoid_failed_get" ,
	    "selfElectronicidVideoidRequestPost": "self_electronicid_videoid_request_post" ,
	    "selfElectronicidVideoidRequestPut": "self_electronicid_videoid_request_put" ,
	    "selfElectronicidVideoidResponsePost": "self_electronicid_videoid_response_post" ,
	    "selfEmailAddressGet": "self_email_address_get" ,
	    "selfEmailAddressPost": "self_email_address_post" ,
	    "selfEmailAddressPut": "self_email_address_put" ,
	    "selfFaceDelete": "self_face_delete" ,
	    "selfFaceGet": "self_face_get" ,
	    "selfFaceGetCurrent": "self_face_get_current" ,
	    "selfFaceList": "self_face_list" ,
	    "selfFacePost": "self_face_post" ,
	    "selfGetstreamCreateTokenGet": "self_getstream_create_token_get" ,
	    "selfGetstreamCreateUserPost": "self_getstream_create_user_post" ,
	    "selfJointPersonCreate": "self_joint_person_create" ,
	    "selfJointPersonList": "self_joint_person_list" ,
	    "selfKycStatusGet": "self_kyc_status_get" ,
	    "selfOnfidoCreateApplicantPost": "self_onfido_create_applicant_post" ,
	    "selfOnfidoCreateCheckPost": "self_onfido_create_check_post" ,
	    "selfOnfidoGenerateSdkTokenGet": "self_onfido_generate_sdk_token_get" ,
	    "selfOnfidoGenerateSdkTokenPost": "self_onfido_generate_sdk_token_post" ,
	    "selfPassportGet": "self_passport_get" ,
	    "selfPasswordPut": "self_password_put" ,
	    "selfPendingRequestsGet": "self_pending_requests_get" ,
	    "selfPendingRequestsPut": "self_pending_requests_put" ,
	    "selfPersonDelete": "self_person_delete" ,
	    "selfPersonPut": "self_person_put" ,
	    "selfPhoneDelete": "self_phone_delete" ,
	    "selfPhoneList": "self_phone_list" ,
	    "selfPhonePost": "self_phone_post" ,
	    "selfPhonePut": "self_phone_put" ,
	    "selfReferralsGet": "self_referrals_get" ,
	    "selfRegisterCustomer": "self_register_customer" ,
	    "selfRegisterStaff": "self_register_staff" ,
	    "selfSettingsGet": "self_settings_get" ,
	    "selfSettingsPut": "self_settings_put" ,
	    "selfTokenGet": "self_token_get"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum PersonUnreadDocumentsGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
