//@ts-nocheck
import React, { ReactElement, SyntheticEvent } from 'react';
import AGenericElement, {
	IGenericElementProps,
	IGenericElementState,
	showErrorModeEnum,
} from './AGenericElement';
import { GenericElementType } from './IGenericElement';
import { translate } from '../../common/language/translate';
import { BigInput } from '../../components/atomiccompoents/form/inputs.css';
import { OpenIDProviderMetadataResponseTokenEndpointAuthSigningAlgValuesSupportedEnum } from '../../logic/api/ident';
import { EditableComponentIndexStore } from '../../logic/flux/stores/editableComponentStore';
import { Input } from '../../components/atomiccompoents/form';

interface IProps extends IGenericElementProps {
	maxLength?: number;
	regex?: Array<RegExp>;
	isPassword?: boolean;
	regexMap?: Array<{}>;
	numberValidatorCallback?: (val: string | number | undefined) => boolean;
	value?: any;
    onSubmit?: (event: SyntheticEvent) => void;
	helpText?: string;
	placeHolder?: string;
	active?: boolean;
	activeIndex?: number;
	activeIndexCallback?: (index: number) => void;
}

interface IState extends IGenericElementState {
	value: string | undefined;
	active: boolean;
}

export default class GenericTextInput extends AGenericElement<IProps, IState> {
	private uiid = this.props.id + '' + this.props.index;
	private ref = React.createRef<Input>();
	constructor(props: IProps) {
		super(props);

		this.state = {
			value: '',
			showErrorMode: showErrorModeEnum.whileTyping,
			active: false,
		};
		this.isValid = this.isValid.bind(this);
		this.onChange = this.onChange.bind(this);
		this.receiveActiveIndex = this.receiveActiveIndex.bind(this);
		if (this.props.value != null) {
			this.initPresetField();
		}
    }

    componentDidUpdate() {
      if(this.props.value != null && this.props.value != "") {
            const isValidResponse = this.isValid(this.props.value);
            let newValue = this.props.value;
            if (this.props.type === GenericElementType.combined) {
             newValue = {
                    value: this.props.value,
                    validRegex: isValidResponse['validRegex'],
                };
            }
		    const hasError = isValidResponse['hasError'];
            this.props.notification(
                this.props.requestBodyUuid,
                newValue,
                this.props.index,
                hasError
            )
        }
	}
	
	componentDidMount() {
		EditableComponentIndexStore.addChangeListener(this.receiveActiveIndex)
	}

	componentWillUnmount() {
		EditableComponentIndexStore.setActiveIndex(-1);
		EditableComponentIndexStore.removeChangeListener(this.receiveActiveIndex)
	}

	receiveActiveIndex() {
		const index = EditableComponentIndexStore.getActiveIndex();
		if(index === this.props.activeIndex) {
			if(this.ref.current != null) {
				this.ref.current.handleFocus();
			}
		}
		else {
			if(this.ref.current != null) {
				this.ref.current.handleBlur();
			}
		}
	}

	initPresetField() {       
		const n = {
			[this.uiid]: {
				value: this.props.value,
			},
		};
		this.onChange(n);
	}

	isValid(value: string) {
		let newError: string | undefined = undefined;
		let validRegex;
		if (value != null && value !== '') {
			if (this.props.regex != null && this.props.regex.length > 0) {
				let validRegexFound: boolean = false;
				for (const regex of this.props.regex) {
					if (regex.test(value)) {
						validRegexFound = true;
						validRegex = regex;
						break;
					}
				}
				console.log("regex");
				if (validRegexFound === false) {
					newError = translate('generic.pleaseEnterValid') + ' ' + this.props.title;
				}
			} else if (
				this.props.numberValidatorCallback != null &&
				typeof this.props.numberValidatorCallback === 'function'
			) {
				if (!this.props.numberValidatorCallback(value)) {
					newError = translate('generic.pleaseEnterValid') + ' ' + this.props.title;
				}
			}
		}
		if (value == null || value === '') {
			if (this.props.isRequired) {
				newError = this.props.title + ' ' + translate('generic.isRequired');
			}
		}
        if(this.state.error !== newError) {
            this.setState({
                error: newError,
            });
        }

		let hasError;
		if (newError != null) {
			hasError = true;
		} else {
			hasError = false;
		}
		return { hasError: hasError, validRegex: validRegex };
	}

	onChange(n: any) {
		//TODO: replace 'any'
		let newValue = n[this.uiid].value;
		this.setState({
			value: newValue,
		});
		const isValidResponse = this.isValid(newValue);
		const hasError = isValidResponse['hasError'];
		if (this.props.type === GenericElementType.combined) {
			newValue = {
				value: n[this.uiid].value,
				validRegex: isValidResponse['validRegex'],
			};
		}
		this.props.notification(
			this.props.requestBodyUuid,
			newValue,
			this.props.index,
			hasError
		);
	}

	render() {
		if (
			this.props.isRequired === false &&
			this.state.value === '' &&
			this.state.error != null
		) {
			this.setState({ error: undefined });
		}
		return (
			<React.Fragment>
				<div onClick={() => {
					if(this.props.activeIndexCallback != null && this.props.activeIndex != null) {
						this.props.activeIndexCallback(this.props.activeIndex)
					}
				}}>
					<BigInput
						key={this.props.requestBodyUuid}
						label={
							this.props.placeHolder != null ? 
							      this.props.placeHolder + (this.props.isRequired === true ? ' *' : '') :
							this.props.title != null
								? this.props.title + (this.props.isRequired === true ? ' *' : '')
								: ''
						}
						id={this.uiid}
						placeHolder={
							this.props.helpText != null ? this.props.helpText :
							this.props.title != null
								? "Enter " + this.props.title 
								: ''
						}
						onLeave={
							() => {
								if(this.ref.current != null) {
									this.ref.current.handleBlur();
								}
							}
						}
						ref = {this.ref}
						labelStyle={{ width: '100%', maxWidth: '820px', minWidth: '320px' }}
						showError={true}
						error={this.state.error}
						directSetError={true}
						focusOnRender={this.props.active === true}
						notification={this.onChange}
						defaultValue={this.props.value != null ? this.props.value : undefined}
						onSubmit={this.props.onSubmit}
						toolTip={true}
					/>
				</div>
			</React.Fragment>
		);
	}
}
