import { ITranslation } from './ITranslation';

export const de: ITranslation = {
	accountSelector: {
		lastTransaction: 'Letzte Transaktion am',
		accounts: 'Konten',
	},
	dashboard: {
		last25: {
			last25customers: 'Die letzten 25 Registrierungen',
			last25: '25 neuste',
		}
	},
	administration: {
		teams: {
			nomembers: 'Keine Mitarbeiter gefunden',
			teammembers: 'Mitarbeiter',
		},
		systemstate: {
			userstats: 'Nutzer Statistik',
			transactionstats: 'Transaction Statistics',
			dateFrom: 'Date from',
			dateTo: 'Date to',
			name: 'Name',
			date_create: 'Date',
			unbooked_txn: 'Unbooked Transactions',
			number_of_txn: 'Total Number of Transactions',
			min_timeused: 'Min Time',
			avg_timeused: 'Avg Time',
			med_timeused: 'Med Time',
			p99_timeused: '99% Time',
			max_timeused: 'Max Time',
		},
		third_party_monitoring: {
			header: 'Drittanbieter-Überwachung'
		},
		clearing_files:{
			header: 'BPC-Clearing Dateien',
			selectFile: 'Clearing-Datei auswählen',
			table: {
				original_id: 'Original ID', 
				inst_id: 'Inst-ID',
				file_type: 'Dateityp',
				filename: 'Dateiname',
				datetime_booked: 'Buchgungsdatum',
				start_date: 'Startdatum',
				end_date: 'Enddatum'
			}
		},
		card_status:{
			header: 'BPC-Kartenstatus',
			selectFile: 'Status-Datei auswählen',
			table: {
				datetime_imported: 'Importier Datum'
			},
		},
		configuration: {
			header: 'Konfigurationen',
			memo: 'Gebührenpaket',
			valid_from: 'Gültig ab',
			invalid_from: 'Gültig bis',
			amount: 'Menge',
			enterAmount: 'Menge eingeben',
			event: 'Ereignis',
			delete: 'Deaktivieren',
			events: {
				A: 'Iban erzeugt',
				I: 'Identifiziert',
				Y: '1 Jahr angemeldet',
				V: 'X Empfehlungen abgegeben',
				J: 'Junior account angelegt'
			},
		},
	},
	auth: {
		complete: {
			title: 'Fertig',
			subtitle:
				'Bitte melden Sie sich mit Ihrem Konto an um Ihr Passwort erneut zu ändern.',
		},
		confirmation: {
			title: 'Eine E-Mail wurde an Sie verschickt',
			subtitle:
				'Bitte drücken Sie auf den Aktivierungslink in der E-Mail die Sie erhalten haben.',
		},
		password: {
			title: 'Passwort setzen',
			subtitle: 'Geben Sie das Passwort für Ihr Konto an.',
			link: 'Passwort ändern',
			notMatchingError: 'Die Passwörter stimmen nicht überein.',
			inValidPasswordError: 'Bitte geben Sie ein gültiges Passwort ein',
			input: {
				password: {
					label: 'Neues Passwort',
					placeholder: 'Passwort eingeben',
				},
				password2: {
					label: 'Passwort erneut eingeben',
					placeholder: 'Passwort eingeben',
				},
				current: {
					label: 'Altes Passwort',
					placeholder: 'Altes Passwort eingeben',
				},
			},
		},
		resetPassword: {
			title: 'Passwort zurücksetzen',
			subtitle: 'Setzen Sie das Passwort für Ihr Konto zurück.',
			link: 'Passwort zurücksetzen',
			input: {
				email: {
					label: 'E-Mail',
					placeholder: 'E-Mail',
				},
			},
		},
		signin: {
			title: 'Anmeldung',
			subtitle: 'Bitte melden Sie sich mit Ihrem Konto an.',
			link: 'Klicken Sie hier um sich anzumelden',
			pwUserNeeded: 'Sie müssen das Passwort/den Benutzernamen eingeben',
			userNeeded: 'Sie müssen den Benutzernamen eingeben',
			input: {
				email: {
					label: 'E-Mail',
					placeholder: 'E-Mail eingeben',
				},
				password: {
					label: 'Passwort',
					placeholder: 'Passwort eingeben',
				},
			},
		},
		signup: {
			signup: 'ANMELDEN',
			title: 'Anmelden',
			subtitle: 'Bitte melden Sie sich an.',
			link: 'Hier können Sie sich anmelden',
			input: {
				email: {
					label: 'E-Mail',
					error: 'Bitte gebne Sie Ihre E-Mail ein',
				},
				firstName: {
					label: 'Vorname',
					error: 'Bitte geben Sie Ihren Vorname ein',
				},
				lastName: {
					label: 'Nachname',
					error: 'Bitte geben Sie Ihren Nachnamen ein',
				},
				conditions: {
					label: 'Ich akzeptiere die AGBs',
					error: 'Sie müssen die AGBs akzeptieren',
				},
			},
		},
		faceLogin: {
			title: 'Gesichtserkennung',
			subtitle:
				'Bitte starten Sie die Gesichtserkennung in dem Sie auf den Knopf drücken. Bitte schauen Sie danach in die Kamera und positionieren Sie Ihr Gesicht innerhalb des Rahmens.',
			link: 'Drücken Sie auf diesen Link um die Gesichtserkennung zu starten',
		},
		contactAdmin: {
			title: 'Administrator kontaktieren',
			subtitle: 'Bitte melden Sie sich mit Ihrem persönlichem Konto an.',
			link: 'Administrator kontaktieren',
		},
		processComplete: {
			title: 'Bearbeitung abgeschlossen',
			subtitle:
				'Bitte melden Sie sich mit Ihrem persönlichem Konto an um das Passwort erneut zu ändern.',
			link: '',
		},
		contactComponent: {
			title: 'Kontaktieren Sie uns',
			subtitle: 'Bitte ...',
			message: 'Nachricht',
			requestInquiries: 'Anfragen',
		},
		verify: {
			title: 'Bestätigungscode eingeben',
			subtitle:
				'Wir haben einen Link an Ihre E-Mail gesendet. Bitte geben Sie den erhaltenen Bestätigungscode aus der E-Mail ein.',
			link: 'Bestätigungscode verifizieren',
			input: {
				email: {
					label: 'E-Mail',
					placeholder: 'E-Mail eingeben',
					error: 'Die E-Mail fehlt',
				},
				code: {
					label: 'Bestätigungscode',
					placeholder: 'Bestätigungscode eingeben',
					error: 'Der Bestätigungscode fehlt',
				},
				error: 'Die E-Mail oder der Bestätigungscode fehlt',
			},
		},
		footer: {
			powered: 'entwickelt von',
		},
	},
	backend: {
		"999999": "Der Fehlertext muss noch festgelegt werden.",
		"1000001": "Die Konto ID ist zu lang.",
		"1000101": "Die Entität wurde nicht festgelegt.",
		"1000102": "Das Quellkonto wurde nicht festgelegt.",
		"1000103": "Das Zielkonto wurde nicht festgelegt.",
		"1000104": "Es wurde kein Text festgelegt.",
		"1000105": "Die Entität existiert in der Datenbank nicht.",
		"1000106": "Das Quellkonto existiert nicht.",
		"1000107": "Das Quellkonto befindet sich in einem ungültigen Zustand.",
		"1000108": "Es ist ein Fehler im Zusammenhang mit der ID des Zielkontos aufgetreten.",
		"1000109": "Das Zielkonto ist in einem ungültigen Zustand.",
		"1000110": "Der Betrag wurde nicht festgelegt.",
		"1000111": "Der Betrag ist null.",
		"1000112": "Der Betrag ist negative.",
		"1000113": "Der Betrag ist zu groß.",
		"1000114": "Der Betrag hat zu viele Dezimalstellen.",
		"1000115": "Die Währung wurde nicht festgelegt.",
		"1000116": "Die Währung existiert nicht in der Datenbank.",
		"1000117": "Die Währung wird von dem Quellkonto nicht unterstützt.",
		"1000118": "Die Währung wird von dem Zielkonto nicht unterstützt..",
		"1000119": "Die Quell-ID konnte nicht ermittelt werden.",
		"1000120": "Der Zweck ist ungültig.",
		"1000121": "Die lokale Zeit wurde nicht festgelegt.",
		"1000122": "Der Standort wurde nicht festgeglegt.",
		"1000123": "Die Transaktion ist ein Duplikat.",
		"1000201": "Das angegebene Konto ist ungültig.",
		"1000202": "Die angegebene Tabelle ist unbekannt.",
		"1000203": "Der Generator wurde nicht konfiguriert.",
		"1000301": "Die Transaktion ist nicht ausgeglichen.",
		"1000302": "Die Transaktion hat kein Statement.",
		"1000303": "Das Konto wurde noch nicht eröffnet.",
		"1000304": "account not open",
		"1000305": "number of statements < 2",
		"1000401": "Die Transaktion wurde noch nicht gebucht.",
		"1000501": "Die Transaktion existiert nicht..",
		"1000601": "Der Anbieterinstanz ist unbekannt. .",
		"1000602": "Die Anbietertabelle ist unbekannt.",
		"1000603": "Der Generator ist nicht konfiguriert.",
		"1000701": "Die festgelegte Nationalität ist unbekannt.",
		"1000702": "Der Login existiert bereits.",
		"1000703": "Die E-Mail existiert bereits.",
		"1000704": "Die Person muss mindesten 18 Jahre alt sein.",
		"1000705": "Personen ohne ein Passwort können nur durch Geschäftsleiter hinzugefügt werden.",
		"1000706": "Die externe Personen-ID wurde nicht festgelegt.",
		"1000707": "Der Datenanbieter ist unbekannt. Bitte verwende ausschließlich ndIdent.",
		"1000708": "Der festgelegte Partner ist unbekannt.",
		"1000801": "Es wurde kein Nutzername oder E-Mail festgelegt.",
		"1000802": "Der Nutzer existiert nicht.",
		"1000902": "Es wurden zu viele Dezimalstellen angegeben.",
		"1001000": "Die Konto-ID wurde nicht festgelegt.",
		"1001001": "Der Saldentyp ist unbekannt.",
		"1001002": "Das Datum wurde nicht festgelegt.",
		"1001003": "Der Artikel wurde nicht festgelegt.",
		"1001004": "Das Untersaldo wurde nicht festgelegt.",
		"1001100": "Die Entität ist unbekannt.",
		"1001101": "Das Konto ist unbekannt.",
		"1001102": "Der Saldentyp ist unbekannt.",
		"1001103": "Das Anfangsdatum wurde nicht festgelegt.",
		"1001104": "Das Enddatum wurde nicht festgelegt.",
		"1001200": "Die Entität wurde nicht festgelegt.",
		"1001201": "Die Kontonummer wurde nicht festgelegt.",
		"1001202": "Die Entität ist unbekannt.",
		"1001203": "Die Kontonummer ist unbekannt.",
		"1001300": "Die Entität ist unbekannt.",
		"1001301": "Die Kontonummer ist unbekannt.",
		"1001302": "Der Saldentyp ist unbekannt.",
		"1001303": "Das Anfangsadatum wurde nicht festgelegt.",
		"1001304": "Das Enddatum wurde nicht festgelegt.",
		"1001400": "Die Entität wurde nicht festgelegt.",
		"1001401": "Das Konto wurde nicht festgelegt.",
		"1001402": "Die Entität existiert nicht.",
		"1001403": "Das Konto existiert nicht.",
		"1001500": "Die Entität wurde nicht festgelegt.",
		"1001501": "Die Sprache wurde nicht festgelegt.",
		"1001502": "Die Zeitzone wurde nicht festgelegt.",
		"1001503": "Die Entität ist unbekannt.",
		"1001504": "Die Person ist unbekannt.",
		"1001505": "Die Sprache ist unbekannt.",
		"1001506": "Die Zeitzone ist unbekannt.",
		"1001600": "Der initiale Zustand ist ungültig.",
		"1001601": "Die E-Mail ist auf der Sperrliste. Bitte kontaktiere den Support.",
		"1001602": "Die E-Mail wurde bereits verschickt oder die Transaktion existiert nicht.",
		"1001603": "Die E-Mail wurde bereits verschickt oder die Benachrichtigung existiert nicht.",
		"1001700": "Die Nachrichten-ID ist unbekannt.",
		"1001800": "Die Nationalität ist unbekannt.",
		"1001801": "Die Stadt wurde nicht festgelegt.",
		"1001900": "Die Nationalität ist unbekannt.",
		"1001901": "Die Stadt wurde nicht festgelegt.",
		"1001902": "Es wurden keine Einträge gefunden.",
		"1002000": "Es wurden keine Einträge gefunden.",
		"1002100": "Die Nationalität ist unbekannt.",
		"1002101": "Es wurden keine Einträge gefunden.",
		"1002102": "Person has to be at least 18 years old.",
		"1002300": "Die E-Mail Adresse wurde bereits zugewiesen.",
		"1002400": "Es wurden keine Einträge gefunden.",
		"1002500": "Es wurden keine Einträge gefunden.",
		"1002501": "Die primäre E-Mail Adresse kann nicht entfernt werden.",
		"1002600": "Die Telefonnummer wurde bereits zugewiesen",
		"1002700": "Es wurden keine Einträge gefunden.",
		"1002701": "Es gibt keine primäre Telefonnummer mehr.",
		"1002800": "Es wurden keine Einträge gefunden.",
		"1002801": "Die primäre Telefonnummer kann nur gelöscht werden, wenn sie die letzte Telefonnummer ist.",
		"1002900": "Die E-Mail Adresse wurde nicht festgelegt.",
		"1002901": "Die E-Mail Adresse ist unbekannt.",
		"1003000": "Das Token existiert nicht.",
		"1003100": "Die E-Mail ist auf der Sperrliste. Bitte kontaktiere den Support.",
		"1003101": "Die E-Mail ist auf der Sperrliste. Bitte kontaktiere den Support.",
		"1003200": "Der Memotext konnte nicht erstellt werden. Bitte erhöhe die Länge des Memofeldes.",
		"1003300": "Die Auszahlung wurde nicht authorisiert.",
		"1003301": "Die Auszahlung wurde abgelehnt.",
		"1003302": "Es wurde kein Logineintrag gefunden.",
		"1003303": "Die Person konnte in der Datenbank nicht gefunden werden.",
		"1003304": "Das Konto existiert nicht.",
		"1003305": "Die Währung ist in der Datenbank nicht hinterlegt.",
		"1003306": "Das Teilsaldo existiert nicht.",
		"1003307": "Der Zustand ist unbekannt.",
		"1003308": "Das ATM-Konto ist unbekannt.",
		"1003309": "Der Betrag ist null.",
		"1003310": "Der Betrag ist negativ.",
		"1003311": "Der Betrag ist zu groß.",
		"1003312": "Es wurden zu viele Dezimalstellen angegeben.",
		"1004000": "Die UID ist gesperrt.",
		"1004001": "Die UID ist unbekannt.",
		"1004002": "Das Token wurde bereits verwendet.",
		"1004003": "Das UID-Format ist ungültig.",
		"1004100": "Die Karte ist gesperrt.",
		"1004101": "Die Karte ist unbekannt.",
		"1004102": "Das Token wurde bereits verwendet.",
		"1004200": "Das Guthaben reicht nicht aus.",
		"1004201": "Das Token ist ungültig.",
		"1004202": "Das Token ist ungültig.",
		"1004203": "Die Währung ist ungültig.",
		"1004204": "Es wurden zu viele Dezimalstellen angegeben.",
		"1004205": "Es wurde keine App-Sitzung gefunden.",
		"1004206": "Es wurde keine E-Mail Adresse festgelegt.",
		"1004207": "Es wurde keine Person festgelegt.",
		"1004300": "Das Guthaben reicht nicht aus.",
		"1004301": "Das Token ist ungültig.",
		"1004302": "Die Pin ist ungültig.",
		"1004303": "Die Währung ist ungültig.",
		"1004304": "Es wurden zu viele Dezimalstellen angegeben.",
		"1004305": "Die ATM Auszahlung kann nicht gefunden werden oder die ATM Auszahlung wurde bereits verwendet.",
		"1004306": "Die Beträge stimmen nicht überein.",
		"1004307": "Die Währungen stimmen nicht überein.",
		"1004308": "Die Exponenten der Währung stimmt nicht überein.",
		"1004309": "Die Pin wurde bereits festgelegt.",
		"1004310": "Die Zeit der ATM Authentifizierung wurde bereits festgelegt.",
		"1004311": "Die ATM Auszahlung wird bereits durchgeführt.",
		"1004312": "Das Token wurde bereits verwendet.",
		"1004400": "Das Token ist ungültig.",
		"1004401": "Die ATM Auszahlung kann nicht gefunden werden oder die ATM Auszahlung wurde bereits bestätigt.",
		"1004500": "Das Token wurde bereits verwendet.",
		"1004600": "Die Geräte-ID kann nicht gefunden werden.",
		"1004700": "Das Konto kann nicht gefunden werden.",
		"1004701": "Die Karte kann nicht gefunden werden.",
		"1004702": "Die Karte ist bereits einem anderen Konto zugewiesen worden.",
		"1004800": "Das Konto kann nicht gefunden werden.",
		"1004801": "Die Karte kann nicht gefunden werden.",
		"1004900": "Die ATM Auszahlung kann nicht gefunden werden oder die ATM Auszahlung wurde bereits verwendet.",
		"1005000": "Das Konto konnte nicht gefunden werden.",
		"1005001": "NFC konnte nicht gefunden werden.",
		"1005002": "Der NFC ist bereits einem anderen Konto zugewiesen.",
		"1005100": "Das Konto konnte nicht gefunden werden.",
		"1005101": "NFC konnte nicht gefunden werden.",
		"1005200": "Der Gutschein konnte nicht gefunden werden.",
		"1005300": "Die Personen-ID wurde nicht festgelegt.",
		"1005301": "Die Kontonummer wurde nicht festgelegt.",
		"1005302": "Der Betrag wurde nicht festgelegt.",
		"1005303": "Der Betrag ist null.",
		"1005304": "Der Betrag ist zu klein.",
		"1005305": "Der Betrag ist zu groß.",
		"1005306": "Der Betrag hat zu viele Dezimalstellen.",
		"1005307": "Die Währung wurde nicht festgelegt.",
		"1005308": "Die Gutscheinnummer wurde nicht festgelegt.",
		"1005309": "Die Währung ist unbekannt.",
		"1005310": "Die Kontonummer ist unbekannt.",
		"1005311": "Die Gutscheinnummer ist unbekannt.",
		"1005312": "Die Gutscheinnummer gehört zu keinen gültigem Gutschein.",
		"1005313": "Die Währung des Kontos stimmt nicht mit der angegebenen Währung überein.",
		"1005314": "Die Währung des Gutscheines stimmt nicht mit der angegebenen Währung überein.",
		"1005400": "Der Händler existiert nicht.",
		"1005500": "Die selbe ID wurde bereits mit anderen Daten gespeichert.",
		"1005600": "Die selbe ID wurde bereits mit anderen Daten gespeichert.",
		"1005700": "Das Update existiert nicht.",
		"1005800": "Das Konto existiert nicht.",
		"1005900": "Der Partnername ist unbekannt.",
		"1006000": "Es wurde bereits eine ID für das selbe Geschäftskonto mit anderen Daten empfangen.",
		"1006001": "Die Transaktions-ID konnte nicht gefunden werden.",
		"1006002": "Das Konto kann nicht gefunden werden.",
		"1006003": "Das Konto ist nicht aktiv.",
		"1006004": "Die Währung konnte nicht gefunden werden.",
		"1006005": "Das Teilsaldo konnte nicht gefunden werden.",
		"1006006": "Das ATM Konto konnte nicht gefunden werden.",
		"1006007": "Der Betrag ist null.",
		"1006008": "Der Betrag ist zu klein.",
		"1006009": "Der Betrag ist zu groß.",
		"1006010": "Es wurden zu viele Dezimalstellen angegeben.",
		"1006100": "Das Land konnte nicht gefunden werden.",
		"1006200": "Der Ausweis ist ein Duplikat.",
		"1006300": "Das Bild ist ein Duplikat.",
		"1006400": "Die Person wartet bereits darauf bestätigt zu werden.",
		"1006401": "Die Person konnten nicht gefunden werden.",
		"1006402": "Es wurde kein Profilbild gefunden.",
		"1006403": "Es konnte kein hinterlegtes Bild gefunden werden.",
		"1006404": "Das ausstellende Land konnte nicht gefunden werden.",
		"1006405": "Die Nationalität konnte nicht gefunden werden.",
		"1006406": "Die Telefonnummer wurde bereits zugewiesen.",
		"1006407": "Die E-Mail Adresse wurde bereits zugewiesen.",
		"1006500": "Die Person konnte nicht gefunden werden.",
		"1006501": "Die Person wartet bereits darauf bestätigt zu werden.",
		"1006502": "Das Bild ist zu klein.",
		"1006600": "Die Person konnten nicht gefunden werden.",
		"1006601": "Die Person wartet bereits darauf bestätigt zu werden.",
		"1006602": "Die Nationalität ist unbekannt.",
		"1006700": "Es wurde bereits eine ID für das selbe Konto mit anderen Daten empfangen.",
		"1006701": "Die Transaktions-ID konnte nicht gefunden werden.",
		"1006702": "Das Konto kann nicht gefunden werden.",
		"1006703": "Das Konto ist nicht aktiv.",
		"1006704": "Die Währung konnte nicht gefunden werden.",
		"1006705": "Das Teilsaldo konnte nicht gefunden werden.",
		"1006706": "Das ATM Konto konnte nicht gefunden werden.",
		"1006707": "Der Betrag ist null.",
		"1006708": "Der Betrag ist zu klein.",
		"1006709": "Der Betrag ist zu groß.",
		"1006710": "Es wurden zu viele Dezimalstellen angegeben.",
		"1006711": "Die Kryptowährung ist unbekannt.",
		"1006800": "Es wurde bereits eine ID für das selbe Konto mit anderen Daten empfangen.",
		"1006801": "Die Transaktions-ID konnte nicht gefunden werden.",
		"1006802": "Das Konto kann nicht gefunden werden.",
		"1006803": "Das Konto ist nicht aktiv.",
		"1006804": "Die Währung konnte nicht gefunden werden.",
		"1006805": "Das Teilsaldo konnte nicht gefunden werden.",
		"1006806": "Das ATM Konto konnte nicht gefunden werden.",
		"1006807": "Der Betrag ist null.",
		"1006808": "Der Betrag ist zu klein.",
		"1006809": "Der Betrag ist zu groß.",
		"1006810": "Es wurden zu viele Dezimalstellen angegeben.",
		"1006811": "Die Kryptowährung ist unbekannt.",
		"1006900": "Es wurden zu viele Versuche unternommen.",
		"1006901": "Die E-Mail Adresse wurde bereits registriert. Möchtest du das Passwort zurücksetzen?",
		"1006902": "Der Empfehlungscode konnte nicht gefunden werden.",
		"1006903": "Für die Nutzerklasse konnte kein OIDC Client (Produkt) gefunden werden.",
		"1006904": "only superadmin may create bo-user",
		"1007000": "Das Token konnte nicht gefunden werden.",
		"1007001": "Die E-Mail Adressen stimmen nicht überein.",
		"1007002": "Das Token ist nicht aktiv.",
		"1007003": "Ein unbekannter Fehler ist aufgetreten.",
		"1007004": "Das Token wurde bereits verwendet.",
		"1007100": "Der MIME Typ ist für das gespeicherte Bild unbekannt.",
		"1007101": "Der MIME Typ ist für das gespeicherte Bild des Ausweises unbekannt.",
		"1007102": "Die Person konnten nicht gefunden werden.",
		"1007103": "Der Ausweis ist bereit einer anderen Person zugewiesen worden.",
		"1007104": "Das Auslesen der Ausweise für das ausstellende Land ist nicht erlaubt.",
		"1007200": "Das Bild ist zu klein.",
		"1007201": "Der Bildertyp ist unbekannt.",
		"1007202": "Die Person konnten nicht gefunden werden.",
		"1007300": "Die Person konnten nicht gefunden werden.",
		"1007400": "Die Person konnten nicht gefunden werden.",
		"1007500": "Die Transaktion konnte nicht gefunden werden.",
		"1007501": "Der Transaktionstyp ist nicht konfiguriert.",
		"1007600": "Die E-Mail Adresse ist unbekannt.",
		"1007700": "Das Token konnten nicht gefunden werden.",
		"1007701": "Die E-Mail Adressen stimmen nicht überein.",
		"1007702": "Das Token ist nicht aktiv.",
		"1007703": "Ein unbekannter Fehler ist aufgetreten.",
		"1007704": "Die Person konnten nicht gefunden werden.",
		"1007800": "Die Person konnten nicht gefunden werden.",
		"1007900": "Das Bild ist zu klein.",
		"1007901": "Der Bildertyp ist unbekannt.",
		"1007902": "Die Person konnten nicht gefunden werden.",
		"1007903": "Die n-te Position ist unbekannt.",
		"1007904": "Der n-te Ausdruck ist unbekannt.",
		"1007905": "Auf der n-te Position ist ein Duplikat von einem Fingerabruck.",
		"1008000": "Die selbe ID wurde bereits mit anderen Daten gespeichert.",
		"1008100": "Ein initialer Upload ist nur für Personen mit Zustand 'V' erlaubt.",
		"1008101": "Der Ausweis wurde bereits zugewiesen.",
		"1008102": "Die Adresse wurde bereits zugewiesen.",
		"1008103": "Die Telefonnummer wurde bereits zugewiesen.",
		"1008104": "Die Fingerabruck-ID wurde bereits zugewiesen.",
		"1008105": "Die Face-ID wurde bereits zugewiesen.",
		"1008200": "Der Client konnte nicht gefunden werden.",
		"1008300": "Der Client existiert bereits.",
		"1008400": "Der Verifizierungscode konnte nicht gefunden werden.",
		"1008401": "Die Empfänger stimmen nicht überein.",
		"1008402": "Der Verifizierungscode ist nicht aktiv.",
		"1008403": "Ein unbekannter Fehler ist aufgetreten.",
		"1008404": "Der Verifizierungscode wurde bereits verwendet.",
		"1008500": "Es konnte kein Verifizierungscode gefunden werden.",
		"1008600": "Die Sprache ist unbekannt.",
		"1008700": "Das Konto kann nicht gefunden werden.",
		"1008800": "Das Konto kann nicht gefunden werden.",
		"1008900": "Es wurden keine Suchparameter festgelegt.",
		"1009000": "Das Konto kann nicht gefunden werden.",
		"1009001": "Das Konto befindet sich in einem falschen Zustand.",
		"1009002": "Der Kontostand ist nicht null.",
		"1009100": "Die Person konnten nicht gefunden werden.",
		"1009113": "Das Gläubigerkonto konnte nicht gefunden werden.",
		"1009200": "Der angegebene Betrag hat zu viele Ziffern.",
		"1009201": "Die Währung ist unbekannt.",
		"1009202": "Der Typ des Gläubigerkontos kann nicht ermittelt werden.",
		"1009300": "Das Schuldnerkonto wurde nicht festgelegt.",
		"1009301": "Der Typ des Schuldnerkontos ist unbekannt.",
		"1009302": "Die Währung wurde nicht festgelegt.",
		"1009303": "Das Gläubigerkonto wurde nicht festgelegt.",
		"1009304": "Der Typ des Gläubigerkontos ist unbekannt.",
		"1009305": "Der Gläubigtername wurde nicht festgelegt.",
		"1009306": "Die Transaktion wurde nicht als Einkommen oder Ausgabe festgelegt.",
		"1009307": "Der Kontotyp des Schuldners ist nicht implementiert.",
		"1009308": "Der Kontotyp des Gläubigers ist nicht implementiert.",
		"1009309": "Das Schuldnerkonto konnte nicht gefunden werden.",
		"1009310": "Das Schuldnerkonto ist ein externes Konto.",
		"1009311": "Der Schuldner hat keine IBAN und kann keine SEPA Zahlungen vornehmen.",
		"1009312": "Der Gläubiger hat keine IBAN.",
		"1009314": "Eingehende Zahlungen sind für externe Konten nicht vorgesehen.",
		"1009315": "Das Zwischenkonto konnte nicht gefunden werden.",
		"1009316": "Der Betrag wurde nicht festgelegt.",
		"1009317": "Der Betrag ist kleiner oder gleich null.",
		"1009318": "Die Transactions-ID wurde festgelegt, aber die ID der Geldanfrage wurde nicht festgelegt.",
		"1009319": "Die ID der Geldanfrage wurde festgelegt, aber die Transaktions-ID wurde nicht festgelegt.",
		"1009320": "Der Sender ist zum Anfragen von Geld nicht berechtigt.",
		"1009321": "Die Geldanfrage passt nicht zum Geldfluss.",
		"1009322": "Nur der Empfänger kann eine Geldanfrage akzeptieren.",
		"1009323": "Das Empfängerkonto wurde geändert.",
		"1009324": "Die Währung kann nicht geändert werden.",
		"1009325": "Der Gläubiger kann nicht geändert werden.",
		"1009326": "Der Gläubiger kann nicht geändert werden.",
		"1009327": "Die referenzierte Transaktion ist nicht eindeutig.",
		"1009400": "Die Bearbeitung von ausstehenden Transaktionen ist für die angegebene Quelle nicht umgesetzt.",
		"1009500": "Die ID für den Erstellungprozess der Person wurde nicht festgelegt.",
		"1009501": "Das aktuelle Konto wurde nicht festgelegt.",
		"1009502": "Der Typ des Schuldnerkontos ist unbekannt.",
		"1009503": "Die Währung wurde nicht festgelegt.",
		"1009504": "Das Gläubigerkonto wurde nicht festgelegt.",
		"1009505": "Der Betrag wurde nicht festgelegt.",
		"1009506": "Der Betrag ist kleiner gleich null.",
		"1009507": "Das aktuelle Konto konnte nicht gefunden werden.",
		"1009508": "Das Gläubigerkonto konnte nicht gefunden werden.",
		"1009509": "Nur ein (Kunden-) Bankkonto ist erlaubt.",
		"1009600": "Die Adresse konnte nicht gefunden werden.",
		"1009700": "Die Adresse konnte nicht gefunden werden.",
		"1009701": "Der Adresstyp existiert bereits.",
		"1009702": "address approval only possible with identified person",
		"1009800": "Das Konto kann nicht gefunden werden.",
		"1009900": "Das Konto kann nicht gefunden werden.",
		"1010000": "Es wurden keine Suchparameter festgelegt.",
		"1010100": "Das monatliche Limit für Einnahmen wurde erreicht.",
		"1010101": "Das monatliche Limit für Ausgaben wurde erreicht.",
		"1010102": "Das Guthaben ist unter dem minimalen erlaubten Wert.",
		"1010103": "Das Guthaben ist über dem maximalen erlaubten Wert.",
		"1010104": "Der Betrag wurde nicht festgelegt.",
		"1010105": "Es wurden zu viele Dezimalstellen angegeben given.",
		"1010106": "amount day in exceeded",
		"1010107": "amount day out exceeded",
		"1010108": "amount week in exceeded",
		"1010109": "amount week out exceeded",
		"1010110": "amount year in exceeded",
		"1010111": "amount year out exceeded",
		"1010112": "amount day withdraw exceeded",
		"1010113": "amount week withdraw exceeded",
		"1010114": "amount month withdraw exceeded",
		"1010115": "amount year withdraw exceeded",
		"1010116": "amount day upload exceeded",
		"1010117": "amount week upload exceeded",
		"1010118": "amount month upload exceeded",
		"1010119": "amount year upload exceeded",
		"1010120": "unknown validity",
		"1010200": "Der festgelegte Zeitraum ist ungültig.",
		"1010300": "Das eigene Konto konnte nicht gefunden werden.",
		"1010301": "Die eigene Person konnte nicht gefunden werden.",
		"1010302": "Die eigene E-Mail Adresse konnte nicht gefunden werden.",
		"1010303": "Das Konto der Gegenpartei konnte nicht gefunden werden.",
		"1010304": "Die Kontonummer der Gegenpartei wurde nicht festgelegt.",
		"1010305": "Die Person der Gegenpartei konnte nicht gefunden werden.",
		"1010306": "Die E-Mail Adresse der Gegenpartei konnte nicht gefunden werden.",
		"1010307": "Der Betrag wurde nicht festgelegt.",
		"1010308": "Der Betrag ist negativ.",
		"1010309": "Der Betrag ist null.",
		"1010310": "Anfragen an das selbe Konto werden nicht unterstützt.",
		"1010311": "Anfragen an die selbe Person werden nicht unterstützt.",
		"1010312": "Das Gläubigerkonto konnte nicht gefunden werden.",
		"1010313": "Die IBAN des Gläubigers konnte nicht gefunden werden.",
		"1010400": "Die Geldanfrage konnte nicht gefunden werden.",
		"1010401": "Die Geldanfrage der Gegenpartei konnte nicht gefunden werden.",
		"1010402": "Die Geldanfrage ist nicht im Bearbeitungszustand.",
		"1010403": "Die Geldanfrage der Gegenpartei ist nicht im Bearbeitungszustand.",
		"1010404": "Der Empfänger versucht die Anfrage des Senders upzudaten?",
		"1010405": "Der Sender versucht die Anfrage des Empfängers upzudaten?",
		"1010500": "Die Geldanfrage ist in einem inkorrekten Zustand.",
		"1010600": "Der Benachrichtigungsprozess kann nicht gefunden werden.",
		"1010700": "Die Kombination aus Datenbanktabelle und Prozess ist unbekannt.",
		"1010701": "Der Verifizierungscode ist nicht aktiv anymore.",
		"1010702": "Der Verifizierungscode ist nicht aktiv anymore.",
		"1010800": "Das Ändern des Passwortes wird nicht unterstützt.",
		"1010900": "Die Person konnten nicht gefunden werden.",
		"1010901": "Die E-Mail Adresse konnte nicht gefunden werden.",
		"1011000": "Der neue Guthabenwert ist unter dem erlaubten Minimum.",
		"1011100": "Es konnte kein eindeutiger Code erstellt werden.",
		"1011200": "Die Transaktion konnte nicht gefunden werden.",
		"1011201": "Die zu teilende Betrag ist keine Belastung.",
		"1011202": "Die Konten der Gegenepartei stimmen nicht mit den korrespondierenden Kontorypen überein.",
		"1011203": "Die Konten der Gegenepartei stimmen nicht mit den korrespondierenden Anteilen überein.",
		"1011204": "Der Betrag der Gegenpartei ist negativ.",
		"1011205": "Der Betrag der Gegenpartei ist null.",
		"1011206": "Die Summe des geteilten Betrags übersteigt den urprünglichen Betrag.",
		"1011300": "Die Marke der Karte ist unbekannt.",
		"1011301": "Die Kartennummer ist ungültig.",
		"1011302": "Die Kartennummer wurde bereits einer Person zugewiesen.",
		"1011400": "Die Karte kann nicht gefunden werden.",
		"1011500": "Die Personen-ID wurde nicht festgelegt.",
		"1011501": "Das Konto wurde nicht festgelegt.",
		"1011502": "Das Konto kann nicht gefunden werden.",
		"1011503": "Der Betrag wurde nicht festgelegt.",
		"1011504": "Der Betrag ist kleiner gleich null.",
		"1011505": "Die Währung wurde nicht festgelegt.",
		"1011506": "Die Währung konnte nicht gefunden werden.",
		"1011508": "Das externe Konto wurde nicht festgelegt.",
		"1011509": "Das externe Konto konnte nicht gefunden werden.",
		"1011510": "Das Überweisungkonto konnte nicht gefunden werden.",
		"1011511": "fee account not found",
		"1011512": "net amount below zero",
		"1011513": "fee amount below zero",
		"1011514": "not payed",
		"1011515": "amount instructed does not match amount payed",
		"1011600": "Das Konto kann nicht gefunden werden.",
		"1011601": "Die Karte kann nicht gefunden werden provider.",
		"1011602": "Die Karte kann nicht gefunden werden type.",
		"1011603": "Der Kartentyp wird von dem Anbieter nicht unterstützt.",
		"1011604": "Es existiert bereits eine Vereinbarung mit dem Anbieter.",
		"1011605": "unknown error saving card agreement",
		"1011700": "Das Konto kann nicht gefunden werden.",
		"1011701": "Die Karte kann nicht gefunden werden agreement.",
		"1011702": "Die Kartenvereinbarung ist nicht aktiv.",
		"1011800": "Das Konto kann nicht gefunden werden.",
		"1011801": "Die Kartenvereinbarung konnte nicht gefunden werden.",
		"1011802": "Die Kartenvereinbarung ist nicht aktiv.",
		"1011803": "Die Funktion ist nicht umgesetzt worden.",
		"1011804": "customer not registered at bpc",
		"1011900": "Das Konto kann nicht gefunden werden.",
		"1011901": "Die Karte kann nicht gefunden werden agreement.",
		"1011902": "Die Kartenvereinbarung ist nicht aktiv.",
		"1011903": "Die Kartenvereinbarung ist nicht aktiv.",
		"1012000": "Das Bild konnte nicht gefunden werden.",
		"1012100": "Das Bild konnte nicht gefunden werden.",
		"1012200": "Die Telefonnummer wird bereits verwendet.",
		"1012201": "Die Telefonnummer muss verifiziert werden. Bitte versuche es später erneut.",
		"1012300": "Das Konto kann nicht gefunden werden.",
		"1012301": "Der Prozess ist unbekannt.",
		"1012302": "Der Kanal ist unbekannt.",
		"1012400": "Die Telefonnummer wird bereits verwendet.",
		"1012401": "Die Telefonnummer muss verifiziert werden. Bitte versuche es später erneut.",
		"1012402": "Der Erstellungsprozess für die Person konnte nicht gefunden werden. Er wurde abgeschlossen oder ist abgelaufen.",
		"1012500": "Die E-Mail konnte nicht gefunden werden.",
		"1012600": "Der Anhang konnte nicht mit der Anfrage assoziiert werden.",
		"1012601": "Der Anhang konnte nicht gefunden werden.",
		"1012602": "Der Anhang konnte nicht mit der Anfrage assoziiert werden.",
		"1012603": "Der Anhang konnte nicht mit der Anfrage assoziiert werden.",
		"1012604": "Die Quelle ist nicht konfiguriert.",
		"1012700": "Das Datum fehlt.",
		"1012800": "Der Zugriffsschlüssel ist bereits bekannt.",
		"1012900": "Der Zugriffsschlüssel konnte nicht gefunden werden.",
		"1012901": "Der Zugriffsschlüssel ist inaktiv.",
		"1013000": "Der Zugriffsschlüssel ist bereits aktiv.",
		"1013001": "Der Zugriffsschlüssel konnte nicht gefunden werden or active it ist not active.",
		"1013100": "Der Callback konnte nicht gefunden werden.",
		"1013200": "Der Callback konnte nicht gefunden werden.",
		"1013300": "Dieselbe Transaktion wurde mit einer anderen Callback URL gefunden.",
		"1013400": "Die Person konnten nicht gefunden werden.",
		"1013500": "Der Eintrag konnte nicht gefunden werden.",
		"1013501": "Nur eigene Checkouts können abgebrochen werden.",
		"1013600": "Es konnte kein Dokument gefunden werden.",
		"1013700": "Die Person konnten nicht gefunden werden.",
		"1013800": "Es existiert bereits eine Adresse für diesen Adresstyp.",
		"1013900": "Die E-Mail wird bereits verwendet.",
		"1013901": "Die E-Mail wird verifiziert. Bitte versuche es später erneut",
		"1013902": "Die Person konnten nicht gefunden werden creation process. (gone or finished)",
		"1014000": "Es wurden keine nutzbaren Daten geliefert.",
		"1014001": "Der Begleitcode konnte nicht gefunden werden oder er ist bereits aktiv.",
		"1014100": "Die Person konnten nicht gefunden werden creation process. (gone or finished)",
		"1014200": "Die Person konnten nicht gefunden werden creation process. (gone or finished)",
		"1014300": "Die Person konnten nicht gefunden werden creation process. (gone or finished)",
		"1014400": "Die Person konnten nicht gefunden werden creation process. (gone or finished)",
		"1014500": "Die Person konnten nicht gefunden werden creation process. (gone or finished)",
		"1014600": "Die Person konnten nicht gefunden werden creation process. (gone or finished)",
		"1014700": "Die Person konnten nicht gefunden werden creation process. (gone or finished)",
		"1014800": "Es kann nicht fortgefahren werden!",
		"1014801": "Es wurde kein Partner für den OIDC Client konfiguriert.",
		"1014900": "Das Konto ist unbekannt.",
		"1015000": "Das Konto ist unbekannt.",
		"1015100": "Das Konto ist unbekannt.",
		"1015200": "Die mobile Tag ist unbekannt.",
		"1015201": "Du bist bereits eingelogged.",
		"1015202": "Das Kundenkonto konnte nicht gefunden werden.",
		"1015203": "Die mobile Tag ist unbekannt.",
		"1015300": "Der Checkin bei der Location ist unbekannt.",
		"1015301": "test_id assigned, checkout not possible, please assign result",
		"1015400": "Die mobile Tag ist unbekannt.",
		"1015401": "Die UUID konnte nicht geparsed werden.",
		"1015402": "Du bist bereits eingechecked.",
		"1015403": "Die mobile Tag ist unbekannt.",
		"1015500": "Das Händlerkonto konnte nicht gefunden werden.",
		"1015501": "Cannot find the customer.",
		"1015502": "Du bist bereits eingelogged.",
		"1015503": "Die mobile Tag ist unbekannt.",
		"1015600": "Das Konto ist unbekannt.",
		"1015601": "Die mobile Tag konnte nicht gefunden werden oder ist inaktiv.",
		"1015700": "Das Händlerkonto konnte nicht gefunden werden.",
		"1015701": "Die mobile Tag ist unbekannt.",
		"1015702": "Du bist bereits eingelogged.",
		"1015800": "Der Parameter konnte nicht gefunden werden.",
		"1015900": "Der Parameter konnte nicht gefunden werden.",
		"1016000": "Das Land konnte nicht gefunden werden and the classification ist not possible.",
		"1016001": "market not served, classification not possible",
		"1016100": "Es konnte kein eindeutiger Code erstellt werden.",
		"1016200": "Der Begleitcode ist ungültig oder abgelaufen.",
		"1016201": "Das Profil der aktuellen Person wurde nicht festgelegt.",
		"1016203": "Das Profil der Begleitperson wurde nicht festgelegt.",
		"1016204": "Die Person des Begleitcodes ist keine primäre Person.",
		"1016205": "Die Person des Begleitcodes isr keine Begleitperson.",
		"1016206": "primary person is not identified",
		"1016300": "Die Person steht nicht in Bezug zu der Begleitanfrage oder die Anfrage ist inaktiv.",
		"1016301": "Die primäre Person hat bereits die maximale Anzahl an Begleitpersonen.",
		"1016302": "primary person is not identified",
		"1016400": "Die UUID kann nicht geparsed werden.",
		"1016500": "Der Client ist nicht festgelegt worden.",
		"1016501": "Der Klient ist nicht Teil von invrs, core, ident, ndpaynet oder ndident.",
		"1016502": "Die Personen-ID wurde nicht festgelegt.",
		"1016503": "Die Anfrage-ID wurde nicht festgelegt.",
		"1016504": "Der Release-Typ wurde nicht festgelegt.",
		"1016505": "Der Release-Typ ist keine Transaktion.",
		"1016606": "Die Personen-ID der Releaseanfrage ist nicht festgelegt worden.",
		"1016707": "Die Personen-ID wurde nicht festgelegt.",
		"1016708": "Die Anfrage-ID der Releaseanfrage wurde nicht festgelegt.",
		"1016709": "Der Klient des Release wurde nicht festgelegt.",
		"1016710": "Der Release-Klient ist nicht in invrs, core, ident, ndpaynet oder ndident.",
		"1016711": "Die Releaseaktion wurde nicht festgelegt.",
		"1016712": "Die Releaseaktion ist weder RELEASE, NO_RELEASE, BLOCK noch UNBLOCK.",
		"1016713": "Das Release konnte nicht mithilfe der Anfrage-ID gefunden werden.",
		"1016714": "Die anfragende Person hat keine Rechte um die Anfrage zu ändern.",
		"1016715": "Die Releaseanfrage ist blockiert und muss zunächst freigegeben werden.",
		"1016716": "Das Release wurde bereits veröffentlicht.",
		"1016717": "release/no release can only be given once per request",
		"1016800": "Die Sitzung konnte nicht gefunden werden.",
		"1016900": "account not known",
		"1016901": "account closed",
		"1016902": "limit_value has too many digits",
		"1016903": "limit_value is too large",
		"1016904": "limit_type not found",
		"1016905": "limit_type not configurable",
		"1016906": "no system limit configured",
		"1016907": "limit_value below system limit",
		"1016908": "limit_value exceeds system limit",
		"1017000": "account not known",
		"1017001": "account closed",
		"1017100": "account not found",
		"1017101": "person  not found",
		"1017102": "account already has an iban",
		"1017200": "unkown transaction task action",
		"1017201": "unkown depends transaction task action",
		"1017202": "depends transaction task action does not exist",
		"1017300": "unkown transaction task action",
		"1017301": "unknown transaction task",
		"1017302": "transaction task already done",
		"1017400": "unknown field",
		"1017500": "value does not match pattern for iban",
		"1017501": "wrong iban checksum",
		"1017502": "impossible iban checksum",
		"1017503": "bban part of iban violates national bban pattern",
		"1017600": "card not found",
		"1017601": "unsuccessful response from bpc",
		"1017700": "fee package not found",
		"1017701": "transaction type already assigned to account",
		"1017702": "no packages found",
		"1017703": "Package may not be assigned without valid iban",
		"1017800": "2fa required",
		"1017900": "release not found or already done",
		"1017901": "no 2fa release",
		"1017902": "2fa only for own releases",
		"1017903": "max number of tans exceeded",
		"1017904": "channel not usable",
		"1018000": "tan not found or expired",
		"1018001": "too many retries",
		"1018002": "operation not successfull",
		"1018100": "account not found or inactive",
		"1018101": "product not found or inactive",
		"1018200": "merchant account not found",
		"1018201": "customer account not found",
		"1018202": "at least one item is not configured",
		"1018203": "cannot calculate amount",
		"1018204": "amount negative",
		"1018205": "customer name not set, no order possible",
		"1018206": "customer given_name not set, no order possible",
		"1018207": "customer sex not setno order possible",
		"1018208": "customer birth_date not set, no order possible",
		"1018209": "nationality not set, no order possible",
		"1018210": "ordering of multiple products, currently not possible. please do test first",
		"1018211": "voucher not found (wrong code,expired or already used)",
		"1018212": "voucher not valid for merchant",
		"1018213": "voucher has different currency",
		"1018214": "number of items not allowed",
		"1018300": "no product found for transaction",
		"1018301": "transaction and product given, use only one!",
		"1018302": "product does not exist",
		"1018303": "location does not exist or not active",
		"1018304": "test sent to ecocare, no manual testresult allowed",
		"1018400": "previous location not found",
		"1018500": "unknown action",
		"1018501": "test_id already assigned to different product order",
		"1018502": "product order already has a test_id",
		"1018600": "location not found",
		"1018700": "incomplete personal data, transfer to ecolog not possible",
		"1018800": "logitude not convertable to double",
		"1018801": "latitude not convertable to double",
		"1018900": "not authorized",
		"1019000": "non semantic version number",
		"1019001": "current app version not supported on plattform, please use website",
		"1019100": "error in calendar_items end_time > start_time",
		"1019101": "error in calendar_items overlapping times",
		"1019200": "no pharmacy_id assigned",
		"1019300": "no pharmacy_id assigned",
		"1019400": "voucher code not found, invalid or expired",
		"1019500": "merchant account not found",
		"1019501": "customer account not found",
		"1019502": "account zstripe not found",
		"1019503": "account zecocarekunde not found",
		"1019504": "account ecocare not found",
		"1019505": "amount_fee_stripe negative",
		"1019506": "amount_net negative",
		"1019507": "amount_merchant negative",
		"1019508": "amount_ecocare negative",
		"1019510": "amount_payment negative",
		"1019511": "no successfull payment found",
		"1019512": "amount payed does not match amount ordered",
		"1019513": "product voucher does not exist",
		"1019600": "person_id not set",
		"1019601": "account not set",
		"1019602": "account not found",
		"1019603": "amount not set",
		"1019604": "amount at or below zero",
		"1019605": "currency not set",
		"1019606": "currency not found",
		"1019607": "external account not found",
		"1019700": "account not found",
		"1019701": "account not active",
		"1019702": "amount not set",
		"1019703": "amount below min stripe amount",
		"1019704": "currency not supported",
		"1019705": "email address not found",
		"1019706": "customer person not found",
		"1019800": "unknown payment method",
		"1019900": "too many decimal places",
		"1020000": "no release found for given id",
		"1020100": "must not be null",
		"1020101": "no active person record",
		"2000000": "Die E-Mail Adresse ist ungültig.",
		"2000100": "Die E-Mail Adresse ist ungültig.",
		"2000200": "Die E-Mail Adresse ist ungültig.",
		"2000300": "Die E-Mail Adresse ist ungültig.",
		"2000301": "Das Linkformat ist unbekannt.",
		"2000302": "Ein Fehler ist beim bereitstellen der PDF aufgetreten.",
		"2000400": "Ein Fehler ist beim Einloggen aufgetreten.",
		"2000500": "Die Zeit ist abgelaufen.",
		"2000501": "Eine Pin Authentifizierung wurde angefragt.",
		"2000502": "Das Token ist nicht aktiv.",
		"2000503": "Das Token ist nicht aktiv.",
		"2000504": "Die Authentifizierung wurde abgelehnt.",
		"2000505": "Das Token ist nicht aktiv.",
		"2000506": "Der Appprozess hat keine Antwort zurückgeschickt. Der Prozess wird abgebrochen!",
		"2000600": "Die PIN ist nicht numerisch.",
		"2000605": "Das Jsbm ist nicht erreichbar.",
		"2000606": "Ein unerwarteter Fehler ist aufgetreten.",
		"2000700": "no verification request with given id exists",
		"2000701": "data for this id already stored",
		"2000800": "no table id for given table name found",
		"2000801": "no status change for given table triggered",
		"2000900": "no verification request with given id exists",
		"2001000": "data for this notification_id already stored",
		"2001100": "data for this video_id already stored",
		"2001101": "no person found for video id",
		"2001200": "no bpc product for persons resident country",
		"2001201": "already registered for a different product",
		"2001202": "account not found",
		"2001203": "unknown error saving register customer request",
		"2001300": "person not registered at bpc",
		"2001301": "account not found",
		"2001302": "unknown error saving create virtual card request",
		"2001400": "original transaction not found",
		"2001401": "original transaction failed",
		"2001402": "feature not implemented",
		"2001403": "original transaction already reversed",
		"2001500": "original transaction not found",
		"2001501": "reversal transaction not found",
		"2001502": "reversal and original transaction are not balanced",
		"2001600": "unkown account task action",
		"2001601": "unkown depends account task action",
		"2001602": "depends account task action does not exist",
		"2001700": "unkown account task action",
		"2001701": "unknown account task",
		"2001702": "account task already done",
		"2001800": "feature not implemented",
		"2001900": "feature not implemented",
		"2002000": "card not found",
		"2002001": "card agreement not found",
		"2002100": "unsuccessful response from BPC",
		"2002200": "card not found",
		"2002201": "card agreement not found",
		"2002300": "unsuccessful response from BPC",
		"2002301": "invalid state",
		"2002400": "card not found",
		"2002401": "card agreement not found",
		"2002402": "state not found",
		"2002500": "unsuccessful response from BPC",
		"2002501": "unsuccessful response from BPC",
		"2002600": "unknown error saving register customer response",
		"2002601": "unsuccessful response from BPC",
		"2002700": "unknown error saving create virtual card response",
		"2002701": "unsuccessful response from BPC",
		"2002800": "primary with companions cannot be downgraded to companion",
		"2002900": "card not found",
		"2003000": "unsuccessful response from BPC",
		"2003100": "operation type not supported",
		"2003200": "product order not found",
		"2003201": "test result already entered",
		"2003300": "person_id create not set",
		"2003301": "debitor account not set",
		"2003302": "creditor account not set",
		"2003303": "currency not set",
		"2003304": "amount not set",
		"2003305": "amount at or below zero",
		"2003306": "debitor account not found",
		"2003307": "creditor account not found",
		"2003308": "maximal one customer account allowed",
		"2003309": "one account must be customer account",
		"2003400": "card not found",
		"2003500": "unsuccessful response from BPC",
		"2003600": "state not found",
		"2003601": "account not found",
		"2003700": "unsuccessful response from BPC",
		"2003800": "account not found",
		"2003801": "card agreement not found",
		"2003802": "limit not found",
		"2003900": "unsuccessful response from BPC",
		"2004000": "duplicate transaction",
		"2004001": "duplicate transaction",
		"2004100": "account not found",
		"2004101": "card agreement not found",
		"2004102": "card color not found",
		"2004103": "only apply once per customer",
		"2004104": "secret answer and card color required for physical card",
		"2004200": "account not found",
		"2004201": "card agreement not found",
		"2004202": "card not found",
		"2004300": "as of date lies in the future.",
		"2004400": "invalid or unknown email address",
		"2004401": "invalid or unknown account",
		"2004500": "invalid or unknown voucher code",
		"2004600": "invalid or unknown account",
		"2004700": "invalid voucher (wrong code, expired, or already used)",
		"2004800": "wrong kyc screening status",
		"3000001": "Die Funktion ist nicht umgesetzt worden.",
		"3000002": "Das Konto ist nicht aktiv or connected to the acting person.",
		"3000100": "Eine unbekannte Antwort wurde zurückgeschickt.",
		"3000101": "Bei der Wallet-Server-Anfrage ist ein Ausnahmezustand aufgetreten.",
		"3000102": "Eine unbekannte Antwort wurde zurückgeschickt.",
		"3000103": "Bei der Wallet-Server-Anfrage ist ein Ausnahmezustand aufgetreten.",
		"3000200": "Ein Fehler ist beim Validiren der IDP aufgetreten: ",
		"3000201": "Der Nutzer oder das Passwort sind ungültig.",
		"3000202": "Ein Fehler ist beim Validieren der Anfrage aufgetreten: ",
		"3000203": "Ein Fehler ist beim Aufrufen der E-Mail aufgetreten mit der Fehlermeldung: ",
		"3000204": "Ein Fehler ist beim Validieren der Anfrage aufgetreten: ",
		"3000205": "Die Telefonnummer ist ungültig.",
		"3000206": "Die mobile Telefonnummer ist ungültig.",
		"3000207": "Das Token ist ungültig oder abgelaufen.",
		"3000208": "Das Token ist ungültig oder abgelaufen.",
		"3000209": "Die Telefonnummer ist ungültig.",
		"3000210": "Die mobile Telefonnummer ist ungültig.",
		"3000300": "Beim Validieren des Verificationscodes ist ein Fehler aufgetreten.",
		"3000301": "Du bist nicht authorisiert.",
		"3000302": "Die Person ist nicht mit dem Partner verknüpft und die Autorisation ist fehlgeschlagen.",
		"3000303": "Beim Anfordern der Nutzerdaten ist ein Fehler aufgetreten.",
		"3000304": "Die Person ist nicht mit dem Partner verknüpft und die Autorisation ist fehlgeschlagen.",
		"3000305": "Beim Anfordern der Nutzerdaten ist ein Fehler aufgetreten.",
		"3000306": "current app version not supported on plattform, please use website",
		"3000400": "Der Zugriffsschlüssel konnte nicht gespeichert werden, weil der Versuch zu viele Runden in anspruch genommen hat.",
		"3000401": "wrong password",
		"3000500": "Das Konto ist nicht aktiv or connected to the acting person.",
		"3000600": "Beim Aufrufen des Links ist ein Fehler aufgetreten.",
		"3000601": "Beim Aufrufen des Links ist ein Fehler aufgetreten.",
		"3000700": "Das Zahlenformat ist ungültig.",
		"3000701": "Das Zahlenformat ist ungültig.",
		"3000800": "Das Format des Zeitstempels ist ungültig.",
		"3000900": "Die elektronische ID der resultierenden Struktur-ID ist unbekannt.",
		"3000901": "Das Ergebnis ist kein gültiges JSON-Objekt.",
		"3000902": "Ein Fehler ist beim Anfragen der elektronischen ID aufgetreten.",
		"3000903": "Die elektronische ID ist nicht konfiguriert.",
		"3001001": "account not found",
		"3001002": "error calling bpc",
		"3001003": "unknown request structure",
		"3001004": "unknown error",
		"3001005": "bpc not configured",
		"3001100": "not a mobile number",
		"3001101": "error validating phone number",
		"3001102": "connection_error validating phone number",
		"3001200": "Hubspot Connection refused",
		"3001201": "access token not found",
		"3001202": "base url was not found",
		"3001203": "base url was not found",
		"3001204": "api key was not found",
		"3001205": "no person_id and person_create_id found",
		"3001300": "error_code not numeric",
		"3001301": "error_code not numeric",
		"3001302": "undefined function",
		"3001303": "sql error",
		"3001304": "connection closed",
		"3001400": "unknown xml structure",
		"4000000": "Es konnte keine Verbindung mit dem Core-Server hergestellt werden.",
		"4000001": "Ein unbekannter Fehler ist aufgetreten.",
		"4000002": "Es konnte keine Verbindung mit dem Core-Server hergestellt werden.",
		"4000003": "Ein unbekannter Fehler ist aufgetreten.",
		"4000100": "Es konnte keine Verbindung mit dem Core-Server hergestellt werden.",
		"4000101": "Es konnte keine Verbindung mit dem Core-Server hergestellt werden.",
		"4000102": "Ein unbekannter Fehler ist aufgetreten.",
		"4000103": "Eine unbekannte Antwort wurde zurückgeschickt.",
		"4000104": "Es konnte keine Verbindung mit dem Core-Server hergestellt werden.",
		"4000105": "Eine unbekannte Antwort wurde zurückgeschickt.",
		"4000106": "Es konnte keine Verbindung mit dem Core-Server hergestellt werden.",
		"4000107": "Eine unbekannte Antwort wurde zurückgeschickt.",
		"9999999": "Der Fehlertext muss noch festgelegt werden."
	},
	countrys: {
		ABW : 'ABW',
		AFG : 'AFG',
		AGO : 'AGO',
		AIA : 'AIA',
		ALA : 'ALA',
		ALB : 'ALB',
		AND : 'AND',
		ANT : 'ANT',
		ARE : 'ARE',
		ARG : 'ARG',
		ARM : 'ARM',
		ASM : 'ASM',
		ATA : 'ATA',
		ATF : 'ATF',
		ATG : 'ATG',
		AUS : 'AUS',
		AUT : 'AUT',
		AZE : 'AZE',
		BDI : 'BDI',
		BEL : 'BEL',
		BEN : 'BEN',
		BES : 'BES',
		BFA : 'BFA',
		BGD : 'BGD',
		BGR : 'BGR',
		BHR : 'BHR',
		BHS : 'BHS',
		BIH : 'BIH',
		BLM : 'BLM',
		BLR : 'BLR',
		BLZ : 'BLZ',
		BMU : 'BMU',
		BOL : 'BOL',
		BRA : 'BRA',
		BRB : 'BRB',
		BRN : 'BRN',
		BTN : 'BTN',
		BVT : 'BVT',
		BWA : 'BWA',
		CAF : 'CAF',
		CAN : 'CAN',
		CCK : 'CCK',
		CHE : 'CHE',
		CHL : 'CHL',
		CHN : 'CHN',
		CIV : 'CIV',
		CMR : 'CMR',
		COD : 'COD',
		COG : 'COG',
		COK : 'COK',
		COL : 'COL',
		COM : 'COM',
		CPV : 'CPV',
		CRI : 'CRI',
		CUB : 'CUB',
		CUW : 'CUW',
		CXR : 'CXR',
		CYM : 'CYM',
		CYP : 'CYP',
		CZE : 'CZE',
		DEU : 'DEU',
		DJI : 'DJI',
		DMA : 'DMA',
		DNK : 'DNK',
		DOM : 'DOM',
		DZA : 'DZA',
		ECU : 'ECU',
		EGY : 'EGY',
		ERI : 'ERI',
		ESH : 'ESH',
		ESP : 'ESP',
		EST : 'EST',
		ETH : 'ETH',
		EUE : 'EUE',
		FIN : 'FIN',
		FJI : 'FJI',
		FLK : 'FLK',
		FRA : 'FRA',
		FRO : 'FRO',
		FSM : 'FSM',
		GAB : 'GAB',
		GBD : 'GBD',
		GBN : 'GBN',
		GBO : 'GBO',
		GBP : 'GBP',
		GBR : 'GBR',
		GBS : 'GBS',
		GEO : 'GEO',
		GGY : 'GGY',
		GHA : 'GHA',
		GIB : 'GIB',
		GIN : 'GIN',
		GLP : 'GLP',
		GMB : 'GMB',
		GNB : 'GNB',
		GNQ : 'GNQ',
		GRC : 'GRC',
		GRD : 'GRD',
		GRL : 'GRL',
		GTM : 'GTM',
		GUF : 'GUF',
		GUM : 'GUM',
		GUY : 'GUY',
		HKG : 'HKG',
		HMD : 'HMD',
		HND : 'HND',
		HRV : 'HRV',
		HTI : 'HTI',
		HUN : 'HUN',
		IDN : 'IDN',
		IMN : 'IMN',
		IND : 'IND',
		IOT : 'IOT',
		IRL : 'IRL',
		IRN : 'IRN',
		IRQ : 'IRQ',
		ISL : 'ISL',
		ISR : 'ISR',
		ITA : 'ITA',
		JAM : 'JAM',
		JEY : 'JEY',
		JOR : 'JOR',
		JPN : 'JPN',
		KAZ : 'KAZ',
		KEN : 'KEN',
		KGZ : 'KGZ',
		KHM : 'KHM',
		KIR : 'KIR',
		KNA : 'KNA',
		KOR : 'KOR',
		KWT : 'KWT',
		LAO : 'LAO',
		LBN : 'LBN',
		LBR : 'LBR',
		LBY : 'LBY',
		LCA : 'LCA',
		LIE : 'LIE',
		LKA : 'LKA',
		LSO : 'LSO',
		LTU : 'LTU',
		LUX : 'LUX',
		LVA : 'LVA',
		MAC : 'MAC',
		MAF : 'MAF',
		MAR : 'MAR',
		MCO : 'MCO',
		MDA : 'MDA',
		MDG : 'MDG',
		MDV : 'MDV',
		MEX : 'MEX',
		MHL : 'MHL',
		MKD : 'MKD',
		MLI : 'MLI',
		MLT : 'MLT',
		MMR : 'MMR',
		MNE : 'MNE',
		MNG : 'MNG',
		MNP : 'MNP',
		MOZ : 'MOZ',
		MRT : 'MRT',
		MSR : 'MSR',
		MTQ : 'MTQ',
		MUS : 'MUS',
		MWI : 'MWI',
		MYS : 'MYS',
		MYT : 'MYT',
		NAM : 'NAM',
		NCL : 'NCL',
		NER : 'NER',
		NFK : 'NFK',
		NGA : 'NGA',
		NIC : 'NIC',
		NIU : 'NIU',
		NLD : 'NLD',
		NOR : 'NOR',
		NPL : 'NPL',
		NRU : 'NRU',
		NTZ : 'NTZ',
		NZL : 'NZL',
		OMN : 'OMN',
		PAK : 'PAK',
		PAN : 'PAN',
		PCN : 'PCN',
		PER : 'PER',
		PHL : 'PHL',
		PLW : 'PLW',
		PNG : 'PNG',
		POL : 'POL',
		PRI : 'PRI',
		PRK : 'PRK',
		PRT : 'PRT',
		PRY : 'PRY',
		PSE : 'PSE',
		PYF : 'PYF',
		QAT : 'QAT',
		REU : 'REU',
		RKS : 'RKS',
		ROU : 'ROU',
		RUS : 'RUS',
		RWA : 'RWA',
		SAU : 'SAU',
		SDN : 'SDN',
		SEN : 'SEN',
		SGP : 'SGP',
		SGS : 'SGS',
		SHN : 'SHN',
		SJM : 'SJM',
		SLB : 'SLB',
		SLE : 'SLE',
		SLV : 'SLV',
		SMR : 'SMR',
		SOM : 'SOM',
		SPM : 'SPM',
		SRB : 'SRB',
		SSD : 'SSD',
		STP : 'STP',
		SUR : 'SUR',
		SVK : 'SVK',
		SVN : 'SVN',
		SWE : 'SWE',
		SWZ : 'SWZ',
		SXM : 'SXM',
		SYC : 'SYC',
		SYR : 'SYR',
		TCA : 'TCA',
		TCD : 'TCD',
		TGO : 'TGO',
		THA : 'THA',
		TJK : 'TJK',
		TKL : 'TKL',
		TKM : 'TKM',
		TLS : 'TLS',
		TON : 'TON',
		TTO : 'TTO',
		TUN : 'TUN',
		TUR : 'TUR',
		TUV : 'TUV',
		TWN : 'TWN',
		TZA : 'TZA',
		UGA : 'UGA',
		UKR : 'UKR',
		UMI : 'UMI',
		UNA : 'UNA',
		UNK : 'UNK',
		UNO : 'UNO',
		URY : 'URY',
		USA : 'USA',
		UZB : 'UZB',
		VAT : 'VAT',
		VCT : 'VCT',
		VEN : 'VEN',
		VGB : 'VGB',
		VIR : 'VIR',
		VNM : 'VNM',
		VUT : 'VUT',
		WLF : 'WLF',
		WSM : 'WSM',
		XBA : 'XBA',
		XCC : 'XCC',
		XCE : 'XCE',
		XCO : 'XCO',
		XDC : 'XDC',
		XEC : 'XEC',
		XKX : 'XKX',
		XIM : 'XIM',
		XOM : 'XOM',
		XPO : 'XPO',
		XXA : 'XXA',
		XXB : 'XXB',
		XXC : 'XXC',
		XXX : 'XXX',
		YEM : 'YEM',
		ZAF : 'ZAF',
		ZMB : 'ZMB',
		ZWE : 'ZWE'
	},
	backoffice: {
		accountType: 'Typ des Kontos',
		accountTypeAll: 'Alle',
		searchHelp: {
			title: 'Hilfe - Suchfunktion',
			a: 'Standard: Der eingegebene Text wird im Feld Memo gesucht',
			b:
				'Suche nach Feldern: Trennen Sie Feldnamen und Suchwert duch einen Doppelpunkt, falls nach mehreren Feldern (UND Bedingungen) gesucht werden soll, müssen Sie die Felder/Werte mit einem Komma trennten z.B: "account_number: 1234, memo: abc"',
			c: 'Verfügbare Feldnamen: account_number, iban, memo, name, custom_name',
		},
		results: 'Ergebnisse',
		approvals: {
			title: "Freigaben",
			approve: "Freigeben",
			cancel: "Abbrechen",
			reject: 'Ablehnen',
			status: {
				N: 'Neu',
				W: 'Wartend',
				C: 'Abgebrochen',
				R: 'Freigegeben',
			},
			header: {
				approval: 'Freigeben',
				created: 'Erstellt',
				requestesBy: 'Angefordert von',
				requestedByMail: 'E-Mail Anforderer',
				requieredReleases: 'Freigaben',
				releaseCount: 'Freigaben',
				name: 'Anfordernder Nutzer',
				status: 'Status',
				type: 'Typ',
				lastModified: 'letzte Bearbeitung',
				client: 'Client',
				block_reason: 'Geblockt wegen',
				blocked: 'Blockiert'
			},
			details: {
				name: 'Name',
				city: 'Stadt',
				postcode: 'Postleitzahl',
				street: 'Straße',
				email: 'Email',
				country: 'Land',
				employer_country: 'Arbeitgeber Land',
				employer_city: 'Arbeitgeber Stadt',
				employer_postcode: 'Arbeitgeber Postleitzahl',
				employer_street: 'Arbeitgeber Straße',
				employer_name: 'Arbeitgeber',
				source_of_income: 'Einkommensart',
				incomme: 'Monatliches Einkommen',
				document: 'Dokument',
				address_id:'Adress ID',
				AddressApproved: {
					approved: 'freigegeben'
				},
				account_numbers: 'Verbundene Accounts',
				additional: 'Zusätzliche Daten',
				openPerson: 'Kunden öffnen',
				error: 'Freigabegrund',
				titleTrans: 'Transactions Informationen',
				CustomerCreditTransfer: {
					creditorAccount:  "Sender Account",
					creditorName:     "Sender Name",
					debtorAccount:    "Empfänger Account",
					instructedAmount: {
						amount:           "Betrag",
						currency:         "Währung"
					},
					remittanceInformationUnstructured: "Verwendungszweck",
					save_new_favorite: "Als Favorit speichern",
				},
				ta_id: 'Ta-ID',
				transaction_id: 'Transaktions-ID',
				SepaCreditTransfer: {
					creditorAccount: {
						accountNumber: "Sender Account-Nummer",
						iban: "Sender Iban"
					},
					debtorAccount: {
						accountNumber: "Empfänger Account",
						iban: "Empfänger Iban",
					},
					instructedAmount: {
						amount:           "Betrag",
						currency:         "Währung"
					},
					remittanceInformationUnstructured: "Verwendungszweck",
					creditorName:  "Sender Name"
				},
				NewEmoneyPersonWithUsertype: {
					address: {
						address_type: "Address-Typ",
						building_identifier: "Hausnummer",
						city: "Stadt",
						country: "Land",
						country_subentity: "Bundesland/Staat",
						district_name: "Stadtteil",
						floor_identifier: "Etage",
						post_box: "Postfach",
						post_code: "Postleithal",
						street: "Straße",
						suite_identifier: "Suite-Nummmer"
					  },
					  birth_city: "Gebursort",
					  birth_date: "Geburtsdatum",
					  email_address: "E-Mail Addresse",
					  given_name: "Vorname",
					  language: "Sprache",
					  name: "Name",
					  product: "Produkt",
					  sex: "Geschlecht",
					  user_class: "Nutzerklasse",
				},
				person_id: "Personen ID",
				partner_id: 'Partner ID',
				reason: 'Begründung',
				comment: 'Kommentar',
				PersonUpdateRequest: {
					  birth_city: "Gebursort",
					  birth_date: "Geburtsdatum",
					  email: "E-Mail Addresse",
					  given_name: "Vorname",
					  language: "Sprache",
					  name: "Name",
					  product: "Produkt",
					  sex: "Geschlecht",
					  user_class: "Nutzerklasse",
				},
			},
		},
		search: {
			title: 'Kontensuche',
			searchTrans: 'Transaktionssuche',
			input: {
				account_number: {
					label: 'Kontonummer',
					placeholder: 'Kontonummer eingeben',
				},
				accountType: 'Kontotyp',
				accountTypeAll: 'Alle Kontotypen',
				iban: {
					label: 'IBAN',
					placeholder: 'IBAN eingeben',
				},
				memo: {
					label: 'Memo',
					placeholder: 'Memo eingeben',
				},
				name: {
					label: 'Kontoname',
					placeholder: 'Kontoname eingeben',
				},
				custom_name: {
					label: 'Benutzerdefinierter Kontoname',
					placeholder: 'Benutzerdefinierten Kontonamen eingeben',
				},
				primanota: {
					label: 'Transaction-ID',
					placeholder: 'Transaction-ID eingeben',
				},
			},
		},
	},
	button: {
		addNewCustomer: 'Neuen Kunden anlegen',
		addNewMember: 'Neuen Mitarbeiter hinzufügen',
		cancel: 'Abbrechen',
		changePassword: 'Passwort ändern',
		close: 'Schließen',
		create: 'Anlegen',
		gotoDashboard: 'Zum Dashboard',
		next: 'Weiter',
		ok: 'Ok',
		results: 'Ergebnisse',
		save: 'Speichern',
		search: 'Suchen',
		send: 'Senden',
		sendInformation: 'Informationen senden',
		signin: 'Anmelden',
		today: 'Heute',
		button: 'Erkennung starten',
		valid: 'Valide',
		notValid: 'Nicht valide',
		void: 'Void',
		positive: 'Positiv',
		negative: 'Negativ'
	},
	confirmation: {
		transactionSuccessful: {
			title: 'Die Transaktion war erfolgreich',
			message: 'Der Betrag wurde Erfolgreich an den Empfänger übertragen.',
		},
	},
	contextMenu: {
		copy: "Kopieren",
		paste: "Einfügen",
		edit: "Bearbeiten",
		manageAddress: "Adressverwaltung",
		postingJournal: "Buchungsjournal",
		transactionDetails: "Transaktionsdetails",
		cancel: "Abbrechen",
		revert: "Stornieren",
		openCustomer: "Kunde öffnen",
		moneyStatement: "Kontoauszug",
		manageEmail: "Mailverwaltung",
		openAccount: "Im Backoffice öffnen",
		openLimits: "Limit Prüfungen",
		startQueue: "Starte validierungsprozess",
		refund: 'Erstattung',
		invoice: 'Rechnung anfordern',
		storno: 'Storno Dokument'
	},
	customers: {
		openapprovals: {
			title: 'Offene Adressprüfungen',
			name: 'Name',
			given_name: 'Vorname',
			datetime_due: 'Offen seit',
			email_address: 'Emailadresse',
		},
		juniorAccount: {
			title: 'Juniorkonto',
			turns18: 'Volljährig am'
		},
		addressRequirement: {
			setup: 'Dokument anfragen',
			requirement: 'Dokument fehlerhaft wegen',
		},
		opendocuments: 'Offene Dokumentprüfungen',
		limits: {
			types: {
				5: 'Täglich (Ein)',
				6: 'Wöchenlich (Ein)',
				1: 'Monatlich (Ein)',
				2: 'Monatlich (Aus)',
				4: 'Täglich(Aus)',
				7: 'Wöchentlich(Aus)',
				9: 'Jährlich (Aus)',
				8: 'Jährlich (Ein)',
				10: 'Täglich',
				11: 'Wöchenlich',
				12: 'Monatlich',
				13: 'Jährlich',
				14: 'Täglich',
				15: 'Wöchentlich',
				16: 'Monatlich',
				17: 'Jährlich',
				18: '',
				19: 'Täglich(Checkout Topup)',
				20: 'Monatlich(Checkout Topup)',
				21: 'Jährlich(Checkout Topup)',
				22: 'Tägliche Transaktionen(Checkout Topup)'
			},
			transactionLimit: 'Transaktionslimit eingehend',
			transactionLimitOut: 'Transaktionslimit ausgehend',
			widthdrawLimit: 'Auszahlungslimit',
			uploadLimit: 'Einzahlungslimit',
			widthdrawDescription: 'Maximaler Betrag für Auszahlungen des Nutzers',
			uploadDesciption: 'Maximaler Betrag für Einzahlungen des Nutzers',
			transDescription:'Maximaler Betrag für Transaktionen des Nutzers',
			usage: 'Ausnutzung',
			swap: 'Zwischen relativem und absolutem Wert wechseln',
			applied: 'Prüfungen(C/W/B)*',
			check: 'Limits geprüft',
			write: 'Limits geschrieben',
			balance: 'Balance geprüft',
		},
		products: {
			title: 'Produktdetails',
			productid: 'Produkt-ID',
			types: 'Produkt-Hersteller',
			producer: 'Hersteller',
			stamp: 'Stempel',
			signature: 'Signatur',
			signatureStamp: 'Signatur/Stempel',
			paymentMethods: 'Zahlmethoden',
			paypal: 'Paypal',
			at_merchant: 'Vor Ort',
			stripe: 'Stripe',
			id: 'Produkttyp-ID',
			name: 'Name',
			description: 'Beschreibung',
			claim: 'Forderung',
			default_merchant_provision: 'Händler-Provision',
			price: 'Preis',
			reveive_test: 'Test weiterleiten an',
			noweda: 'Noweda standard',
			typeOfTest: 'Test-Typ',
			fast_track: 'Schnellverfahren',
			image: 'Bild',
			imageSrc: 'Bild-URL',
			vat: 'Mehrwertsteuer',
			package: 'Produkt-Paket',
			group: 'Unique-Gruppe',
			endTime: 'Endzeit',
			voucherAmount: 'Gutscheinbetrag',
			time: {
				hour: 'Stunde',
				minute: 'Minute',
				second: 'Sekunde'
			},
		},
		title: {
			title: 'Anrede',
			mr: 'Herr',
			mrs: 'Frau',
			undefined: 'Unspezifiziert',
			notSet: 'Andrede auswählen',
		},
		sex: {
			mr: "Männlich",
			mrs: "Weiblich",
			undefined: "Unspezifiziert",
			notSet: "",
		},
		emails: {
			primary: 'Primäre Emailadresse',
			secondary: 'Sekundäre Emailadressen',
			primaryMails: 'Primäraddresse',
			secondaryMails: 'Sekundäraddresse ' ,
		},
		feesTab: {
			name: 'Transaktionstyp',
			price: 'Preis',
			used: 'Ausnutzung',
			empty: 'Keine gebuchten Pakete gefunden',
		},
		cardTab: {
			type: 'Kartentyp',
			types: {
				credit_card: 'Kreditkarte',
				debit_card: 'Guthabenkarte',
			},
			state: 'Status',
			states: {
				A: 'Aktiv',
				P: 'Ausstehend',
				D: 'Gelöscht',
				O: 'Bestellt',
				B: 'Geblockt',
			},
			id: 'Agreement-ID',
			provider: 'Anbieter',
			custNumBpc: 'BPC-Kundenummer',
			accNumBpc: 'BPC-Accountnummer',
			bpsState: 'BPC-Status',
			cardId: 'Karten-ID',
			color: 'Farbe',
			expiry: 'Gültig bis',
			name: 'Name',
			customerCard: 'Kartenvereinbarung ',
			barCode: 'Strichcode',
			card:  'Karte',
			bpcCardId: 'BPC Karten-ID',
			virtOrPhys: 'Virtuel/Physisch',
			virtOrPhyType: {
				P: 'Physisch',
				V: 'Virtuel',
			},
		},
		close: {
			reason: 'Begründung',
			ClientRequest: 'Kundenanfrage',
            AMLTechnicalError: 'AML/Technischer Fehler',
            Other3rdPartyRequest: 'Andere Anfrage Dritter',
			ImmediateClosure: 'Sofortschließung',
			additional: {
				label: 'Zusätzliche Begründung',
				placeholder: 'Zusätzliche Begründung eingeben'
			},
			block: {
				label: 'Blockiergrund',
				placeholder: 'Blockiergrund eingeben',
			},
		},
        KYC: "KYC-Daten",
		newImage: 'Neues Bild hochladen: ',
		selectImage: 'Produktbild auswählen',
		product: 'Produkt',
		manageAddress: 'Adressen verwalten',
		accountNumber: 'Kontonummer',
		accountType: 'Account-Typ',
		clientProfile: 'Profil',
		differing_comission: 'Abweichende Provision',
		newAddress: 'Neue Adresse',
		phone: 'Telefonnummer',
		expiryDate: 'Ablaufdatum',
		resetKyc: 'KYC anfordern',
		currentBalance: 'Aktuelles Guthaben',
		availableBalance: 'Verfügbares Guthaben',
		noDocuments: 'Keine Dokumente gefunden',
		validPassport: 'Valides Dokument',
		customersAddress: 'Sekundäre Adressen',
		customerNumber: 'Kundennummer',
		barcode: 'Test Barcode',
		parent_person_id: 'Kundennummer Eltern',
		parent_name: 'Eltern name',
		parent_given_name: 'Eltern vorname',
		user_class: 'Familienbeziehung',
		customersIdent: 'Externe IDs',
		vouchers: 'Gutscheine',
		doc_back: 'Dokument Rückseite', 
		portrait: 'Portrait',
		doc_front: 'Dokument Vorderseite',
		date_of_ident: 'Identifizierungsdatum',
		personal_number: 'Personalnummer',
		issuing_state_code: 'Code des austellenden Staates',
		document_class_code: 'Dokument-typ',
		enteredNationality: 'Erfasse Nationalität',
		correctedNationality: 'Tatsächliche Nationalität',
		user_id: 'Nutzer-ID',
		voucherTypes: {
			onceOnly: 'Einmalig',
			unlimited: 'Unbegrenzt'
		},
		advancedKyc: {
			advancedKyc: 'Erweitertes KYC',
			advancedKycHeader: 'Erweitertes KYC wird überschrieben',
			advancedKycWarning: 'Das senden dieser Daten entfernt den erweiterten KYC-Status der Person, fortfahren?',
			create: 'Anlegen',
			income: {
				EarnedIncome: 'Gehalt/Lohn',
				BusinessIncome: 'Geschäftseinkommen',
				InterestIncome: 'Zinserträge',
				DividendIncome: 'Dividendenerträge',
				RentalIncome: 'Mieteinnahmen',
				CapitalGains: 'Kapitalgewinne',
				Royalties: 'Lizengsgebühren'
			},
			avgIncome: {
				below_1000: 'Unter 1000',
                between_1000_2000: '1000 - 2000',
                between_2000_5000: '2000 - 5000',
                between_5000_10000: '5000 - 10000',
                above_10000: 'Über 10000'
			},
			states: {
				D: 'Hinterlegt',
				A: 'Freigegeben',
				C: 'Abgelehnt',
				P: 'Freigegebeen',
				O: 'Überschrieben' 
			},
			input: {
				document: 'Dokument',
				state: 'Status',
				datetime_create: 'Anlagedatum', 
				datetime_invalidate: 'Invalidierungsdatum',
				employer: {
					label: 'Arbeitgeber',
					placeholder: 'Arbeitgeber eingeben'
				},
				employer_street: {
					label: 'Arbeitgeber Straße',
					placeholder: 'Arbeitgeber Straße eingeben',
				},
				employer_country: {
					label: 'Arbeitgeber Land',
					placeholder: 'Arbeitgeber Land eingeben',
				},
				employer_post_code: {
					label: 'Arbeitgeber Postleitzahl',
					placeholder: 'Arbeitgeber Postleitzahl eingeben',
				},
				employer_city: {
					label: 'Arbeitgeber Stadt',
					placeholder: 'Arbeitgeber Stadt eingeben' 
				},
				source_of_income: {
					label: 'Einkommensart',
					placeholder: 'Einkommensart eingeben'
				},
				monthly_income: {
					label: 'Monatliches Einkommen',
					placeholder: 'Monatliches Einkommen eingeben'
				},
				employer_building_identifier: {
					label: 'Arbeitgeber Hausnummer',
					placeholder: 'Arbeitgeber Hausnummer eingeben',
				},
			},
		},
		noweda: 'Pharmacy-ID',
		pharmadirekt: 'Pharmadirekt-ID',
		accountOpeningDate: 'Kontoeröffnung',
		accountclosingDate: 'Kontoschließung',
		balance: 'Saldo',
		fees: 'Gebühren',
		reset: 'Password setzen',
		birthdate: 'Geburtsdatum',
		blocked: 'Konto blockieren',
		inClosure: 'In Schließung',
		isBlocked: 'Konto Geblockt',
		blockedSince: 'Blockiert seit',
		preferences: 'Präferenzen',
		languageSettings: 'Allgemeine Einstellungen',
		notificationSettings: 'Benachrichtiungseinstellungen',
		activateAll: 'Alle setzen',
		deactiveAll: 'Alles deaktivieren',
		buildingIdentifier: 'Hausnummer',
		identification: 'Persönliche Daten',
		city: 'Stadt',
		cityOfBirth: 'Geburtsort',
		closeAccount: 'Konto schließen',
		closeAccountNo: 'Kontonummer schließen',
		closed: 'Geschlossen',
		closedSince: 'Geschlossen seit',
		currency: 'Währung',
		downloadQR: 'QR-Code runterladen',
		customerDetails: 'Kontakt',
		date: 'Datum',
		documentType: 'Ausweisart',
		documentNumber: 'Ausweisnummer',
		validationDate: 'Validierungsdatum',
		issueingAuth: 'Ausstellendes Land',
		dateOfIdentification: 'Identifizierungsdatum',
		email: 'E-Mail',
		givenName: 'Vorname',
		cards: 'Karten',
		iban: 'IBAN',
		interestRate: 'Zinsrate',
		lastTransaction: 'Letzte Transaktion',
		name: 'Name',
		results: 'Ergebnisse',
		street: 'Straße',
		sure: 'Sind sie sicher?',
		validUntil: 'Gültig bis',
		zip: 'Postleitzahl',
		district_name: 'Bezirk',
		floor_identifier: 'Etage', 
		post_box: 'Postfach',
		suite_identifier: 'Suitennummer', 
		country_subentity: 'Bundesland/Bundesstaat',
		accountActivity: 'Mail Benachrichtigungen',
		language: 'Sprache',
		sendMoney: 'Geld senden',
		receiveMoney: 'Geld empfangen',
		messageFriends: 'Push Benachrichtiungen',
		requestMoney: 'Geld anfordern',
		takeOver: 'Übernehmen',
		splitMoney: 'Betrag splitten',
		details: {
			release: 'Freigaben',
			approval: 'Freigeben',
			connections: 'Verlinkte Verbindungen',
			externalAccounts: {
				externalAccounts: 'externe Accounts',
				expiredDate: 'Karte abgelaufen',
				invalidCardNumber: 'Ungültige Kartennummer',
				invalidCode: 'Ungültige Sicherheitsnummer',
				invalidExpiryDate: 'Ungültiges Ablaufdatum (MM/JJ)',
				missingCardNumber: 'Kartennummer fehlt',
				missingExpiryDate: 'Gültigkeitsdatum fehlt',
				missingName: 'Karteninhaber fehlt',
				unsupportedBrand: 'Nicht unterstützter Kartenhersteller',
				fetchError: 'Fehler beim Suchen der externen Accounts',
				input: {
					cardNumber: {
						label: 'Kreditkartennummer',
						placeholder: 'Kreditkartennummer eingeben',
					},
					amount: {
						label: 'Betrag',
						placeholder: 'Betrag eingeben'
					},
					receiver: {
						label: 'Empfänger',
						placeholder: 'Emfänger eingeben'
					},
					validTo: {
						label: 'Gültig bis',
						placeholder: 'Datum auswählen'
					},
					quantitiy: {
						label: 'Anzahl',
						placeholder: 'Anzahl eingeben'
					},
					testCenter: {
						label: 'Bei Testcenter',
						placeholder: 'Testcenter auswählen'
					},
					type: {
						label: 'Gutscheinart',
						placeholder: 'Gutscheinart auswählen',
					},
					expiry: {
						label: 'Gültig bis',
						placeholder: 'MM/JJ',
					},
					name: {
						label: 'Karteninhaber',
						placeholder: 'Name des Karteninhabers',
					},
					securityCode: {
						label: 'Kartenvalidierungscode',
						placeholder: 'Kartenvalidierungscode eingeben',
					},
					bank: {
						label: 'Bank',
						placeholder: 'Bank eingeben',
					},
					acceptance: {
						label: 'Status',
						placeholder: 'Akzeptanz-Status eingeben',
					},
				},
			},
			companions: {
				C: 'Begleiter',
				P: 'Primär',
				name: 'Name',
				email: 'Email',
				phone: 'Telefon',
				type: 'Verbindungstyp',
				linkedPersons: 'Verbundene Personen',
				relation: 'Beziehung',
				familty_relation: 'Familienbeziehung',
				first_degree_relative: 'Verwandter ersten Grades',
				second_degree_relative: 'Verwandter zweiten Grades',
				third_degree_relative: 'Verwandter dritten Grades',
				fourth_degree_relative: 'Verwandter vierten Grades',
				dependant_relationship: 'Abhängigkeitsverhältnis',
				other_official_relationship: 'Andere offizielle/eingetragene Beziehung',
				other_person: {
					label: 'Email Primary',
					placeholder: 'Email des Primary eingeben'
				},
			},
			merchant: {
				merchant: 'Geschäftskonto',
				merchantStatus: 'Status des Geschäftskontos',
				uploadDocument: 'Dokument hochladen',
				calendarData: 'Kalender',
				openCalendarData: 'Öffnungszeiten',
				upload: 'Bestätigen',
				generateAccessKey: 'Accesskey generieren',
				accessKeyId: 'Accesskey-ID',
				status: 'Status',
				created: 'Erzeugt',
				makeInactiv: 'Deaktvieren',
				deactivated: 'Deaktivert',
				select: 'Datei auswählen..',
				current: 'Aktuelle Dokumente: ',
				profile: 'Logo',
				products: 'Produkte',
				description: 'Beschreibung',
				claim: 'Anspruch',
				add: 'Kalendereintrag hinzufügen',
				image: 'Bild',
				noneTimezone: 'Keine Zeitzone ausgewählt',
				calendar: 'Kalender ID',
				segment: 'Segmente',
				timezone: 'Zeitzone',
				webaddress: 'Webadresse',
				product_types: 'Produkttyp auswählen',
				fiskaly: {
					vat: {
						label: 'VAT-ID',
						placeHolder: 'VAT-ID eingeben',
					},
					econ: {
						label: 'Wirtschaftsnummer',
						placeHolder: 'Wirtschaftsnummer eingeben',
					},
					title: 'Fiskaly Händler Daten',
					orgaId: 'Organisations-ID',
					tseID: 'TSS-ID',
					apiKey: 'Api-Key',
					taxNr: {
						label: 'Steuernummer',
						placeHolder: 'Steuernummer eingeben'
					},
				},
				segments: {
					Restaurant: 'Restaurant',
					Military: 'Militärcamp',
					Canteen: 'Kantine',
					Testcenter: 'Testzentrum',
					Club: 'Klub',
					none: 'Kein Segment ausgewählt',
				},
				calendarItems: {
					0: 'Sonntag',
					1: 'Montag',
					2: 'Dienstag',
					3: 'Mittwoch',
					4: 'Donnerstag',
					5: 'Freitag',
					6: 'Samstag',
					timeslotLength: 'Zeitbereich',
					timeslotCapacity: 'Kapazität',
					exclude: 'Ausgeschlossen',
					start: 'Startzeit',
					end: 'Endzeit',
					excluded: 'Ausgenommen',
					exceptions: 'Sonderöffnungen',
					dateRangeDays: 'Bereich(Tage)'
				},
			},
			Voucher: {
				missing: 'Pflichtfelder sind leer: ',
				sendAt: 'Gesendet an',
				amount: 'Betrag',
				createdAt: 'Erstellt am',
				validTo: 'Gültig bis',
				createdBy: 'Erstellt durch',
				quantity: 'Anzahl',
				testcenter: 'Begrenzt auf',
				product: 'Produkt',
				email: 'Empfänger Email',
				download: 'Download',
				code: 'Code',
				createVoucher: 'Gutschein erstellen',
				boundProduct: 'Für Produkt',
				openVouchers: 'Offene Coupons',
				status: 'Status',
				statusTypes: {
					A: 'Aktiv',
					U: 'Benutzt',
					D: 'Gelöscht'
				},
			},
			KYC: {
				state: {
					C: 'Geschlossen',
					B: 'Geblockt',
					V: 'Verifiziert',
					R: 'Ausstehend',
					I: 'Identifiziert',
				},
				requestKYC: {
					header: 'KYC anfordern',
					message: 'KYC wird angefordert. Sind Sie sicher?',
				},
				password: {
					header: 'Passwort zurücksetzen',
					message: 'Passwort wird zurückgesetzt, sind Sie sicher?'
				},
				systemStates: {
					eid_session_initiated: 'eid session initiiert',
					eid_session_completed: 'eid session abgeschlossen',
					eid_data_delivered   : 'eid daten übergeben',
					eid_response_received: 'eid rückmeldung erhalten',
					assec_data_delivered: 'asseco daten übergeben',
					waiting_for_address_check: 'adressprüfung abwarten',
					address_confirmed: 'addresse bestätigt',
					eid_response_received_with_status_ACCEPTED: 'eid antwort mit status akzeptiert',
                    eid_response_received_with_status_REJECTED: 'eid antwort mit status abgeleht',
					asseco_response_received: 'asseco rückmeldung erhalten',
					kyc_completed: 'kyc abgeschlossen',
					identified : 'identifiziert',
					password_reset_triggered: 'Passwort setzen - Mail versendet',
					password_reset: 'Password gesetzt',
					core_data_changed: 'Stammdaten geändert',
					core_data_added: 'Stammdaten hinzugefügt',
					A: 'KYC bestätigt',
					W: 'KYC-Prozess läuft',
					N: 'KYC benötigt',
					I: 'KYC-Prozess läuft',
					R: 'KYC abgelehent',
				},
				profile: {
    				primary: 'Primär',
    				companion: 'Companion',
				},
				status: 'Status',
				datetimeCreate: 'Anlagedatum',
				pep: 'PEP',
				fatca: 'FATCA',
				us_person: 'US-Bürger',
				own_behalf: 'Im eigenen Namen',
				resident: 'Gebietsansäßig',
				customerState: 'Kundenstatus',
				age_check: 'Alter geprüft',
				systemState: "Systemstatus",
				last_updated: 'Letztes Update am ',
				client: "Client-Status",
				eu_member: 'EU-Mitglied',
				eid: {
					requests: 'EID-Anfragen',
					faileds: 'Fehlgeschlagene VideoIDs',
					webhooks: 'Eid Benachrichtigungen',
					request: {
						url: 'URL',
						datetime_create:  'Anlagedatum',
						session_id:  'Session-ID',
						datetime_video_id:  'VideoID-Datum',
						video_id: 'VideoID'
					},
					failed: {
						datetime_create: 'Anlagedatum',
						session_id: 'Session-ID',
						failure_reason: 'Fehlergrund',
						failure_category: 'Fehlerkategorie'
					},
					webhook: {
						datetime_create: 'Anlagedatum',
						notification_id: 'Benachrichtungs-ID',
						verification_id: 'Verifizierungs-ID',
						video_id: 'Video-ID',
						verification_request_id: 'Verifizierungsanfrage-ID'
					},
				},
				personHistory: {
					title: 'Historie',
					personCore: 'Personenhistorie',
					address: 'Adresshistorie',
					email: 'Emailhistorie',
					phone: 'Telefonhistorie',
					person: {
						name: 'Name', 
						given_name:'Vorname',
						sex: 'Geschlecht',
						birth_date: 'Geburtsdatum', 
						birth_city: 'Geburtsort',
						company_name: 'Firma',
						nationality: 'Nationalität',
						fatca: 'Fatca',
						us_person: 'US-Bürger',
						state: 'Status',
						resident: 'Ansässig',
						is_merchant: 'Merchant', 
						own_behalf: 'Im eigenen Namen',
						pep: 'Pep',
						valid_from: 'Gültig seit',
						invalid_from: 'Gültig bis', 
						person_update: 'Geändert durch'
					},
					addresses: {
						street: 'Straße',
						building_identifier: 'Hausnummer', 
						city: 'Stadt',
						country: 'Land', 
						suite_identifier: 'Suitenummer', 
						floor_identifier: 'Etage',
						post_box: 'Postfach',
						post_code: 'Postleitzahl',
						district_name: 'Stadteil'
					},
					emails: {
						email_address: 'Emailadresse',
						datetime_confirmed: 'Bestätigt am'
					},
					phones: {
						phone_number: 'Telefonnummer'
					}
				},
			},
			settings: {
				fetchError: 'Fehler beim laden der Einstellungen',
			},
		},
		error: {
			link: 'Klicken Sie hier um einen bestehenden Kunden zu editieren',
			subtitle: 'Der Kunde existiert bereits',
			title: 'Fehler',
		},
		newCustomer: {
			input: {
				address: {
					label: 'Straße',
					placeholder: 'Straße eingeben',
				},
				district_name: {
					label: 'Bezirk',
					placeholder: 'Bezirk eingeben',
				},
				floor_identifier: {
					label: 'Etage',
					placeholder: 'Etage eingeben',
				},
				suite_identifier: {
					label: 'Suite-Nummer',
					placeholder: 'Suite-Nummer eingeben',
				},
				country_subentity: {
					label: 'Bundesland/Bundesstaat',
					placeholder: 'Bundesland/Bundesstaaat eingeben',
				},
				post_box: {
					label: 'Postfach',
					placeholder: 'Postfach eingeben',
				},
				number: {
					label: 'Hausnummer',
					placeholder: 'Hausnummer eingeben',
				},
				birthdate: {
					label: 'Geburtsdatum',
					placeholder: 'Geburtsdatum auswählen',
				},
				birthplace: {
					label: 'Geburtsort',
					placeholder: 'Geburtsort eingeben',
				},
				city: {
					label: 'Wohnort',
					placeholder: 'Wohnort eingeben',
				},
				companyname: {
					label: 'Firmenname',
					placeholder: 'Ihr Firmenname',
				},
				merchantDescription: {
					label: 'Beschreibung',					
					placeholder: 'Beschreibung eingeben'
				},
				country: {
					label: 'Land',
					placeholder: 'Land eingeben',
				},
				type: {
					label: 'Addresstyp',
					placeholder: 'Addresstyp eingeben',
				},
				email: {
					label: 'E-Mail',
					placeholder: 'E-Mail eingeben',
				},
				firstname: {
					label: 'Vorname',
					placeholder: 'Vornamen eingeben',
				},
				gender: {
					label: 'Geschlecht',
					placeholder: 'Geschlecht eingeben',
				},
				lastname: {
					label: 'Nachname',
					placeholder: 'Nachnamen eingeben',
				},
				language: {
					label: 'Sprache',
					placeholder: 'Sprache eingeben',
				},
				nationality: {
					label: 'Nationalität',
					placeholder: 'Nationalität eingeben',
				},
				phone: {
					label: 'Telefonnummer',
					placeholder: 'Telefonnummer eingeben',
				},
				postCode: {
					label: 'Postleitzahl',
					placeholder: 'Postleitzahl eingeben',
				},
				state: {
					label: 'Bundesland',
					placeholder: 'Bundesland eingeben',
				},
				latitude: {
					label: 'Breitengrad',
					placeholder: 'Breitengrad eingeben',
				},
				longitude: {
					label: 'Längengrad',
					placeholder: 'Längengrad eingeben'
				},
			},
			title: 'Kunden anlegen',
		}, 
		search: {
			input: {
				accountNumber: {
					label: 'Kontonummer',
					placeholder: 'Kontonummer eingeben',
				},
				address: {
					label: 'Adresse',
					placeholder: 'Adresse eingeben',
				},
				birthdate: {
					label: 'Geburtsdatum',
					placeholder: 'Geburtsdatum auswählen',
				},
				cardNumber: {
					label: 'Kartennummer',
					placeholder: 'Kartennummer eingeben',
				},
				customerNumber: {
					label: 'Kundennummer',
					placeholder: 'Kundennummer eingeben',
				},
				external: {
					label: 'Externe ID',
					placeholder: 'Externe ID eingeben',
				},
				email: {
					label: 'E-Mail',
					placeholder: 'E-Mail eingeben',
				},
				street: {
					label: 'Straße',
					placeholder: 'Straße eingeben',
				},
				buildId: {
					label: 'Hausnummer',
					placeholder: 'Hausnummer eingeben',
				},
				city: {
					label: 'Stadt',
					placeholder: 'Stadt eingeben',
				},
				zip: {
					label: 'Postleitzahl',
					placeholder: 'Postleitzahl eingeben',
				},
				country: {
					label: 'Land',
					placeholder: 'Land eingeben',
				},
				firstname: {
					label: 'Vorname',
					placeholder: 'Vornamen eingeben',
				},
				lastname: {
					label: 'Nachname',
					placeholder: 'Nachnamen eingeben',
				},
				searchID: {
					label: 'Such-ID',
					placeholder: 'Such-ID eingeben',
				},
				personIdType: {
					label: 'ID-Typ',
					placeholder: 'ID-Typ auswählen',
				},
				phone: {
					label: 'Telefonnummer',
					placeholder: 'Telefonnummer eingeben',
				},
				idTypes: {
					person_id: 'Personen ID',
					eid_verification_id: 'EID-Verifizierungs-ID',
					pharmacy_id: 'Pharmacy ID',
					pharmadirekt: 'Pharma-Direkt ID',
					noweda: 'Noweda ID',
					user_id: 'Nutzer-ID',
					onfido_check_id: 'Onfido Check ID'
				},
				showHistory: {
					label: 'Historierte Einträge suchen',
					placeholder: 'Historierte Einträge suchen'
				},
			},
			title: 'Kunden suchen',
		},
	},
	date: {
		jan: {
			short: 'Jan',
			long: 'Januar',
		},
		feb: {
			short: 'Feb',
			long: 'Februar',
		},
		mar: {
			short: 'Mär',
			long: 'März',
		},
		apr: {
			short: 'Apr',
			long: 'April',
		},
		may: {
			short: 'Mai',
			long: 'Mai',
		},
		jun: {
			short: 'Jun',
			long: 'Juni',
		},
		jul: {
			short: 'Jul',
			long: 'Juli',
		},
		aug: {
			short: 'Aug',
			long: 'August',
		},
		sep: {
			short: 'Sep',
			long: 'September',
		},
		oct: {
			short: 'Okt',
			long: 'Oktober',
		},
		nov: {
			short: 'Nov',
			long: 'November',
		},
		dec: {
			short: 'Dez',
			long: 'Dezember',
		},
		mon: {
			short: 'Mo',
			long: 'Montag',
		},
		thu: {
			short: 'Do',
			long: 'Dienstag',
		},
		wed: {
			short: 'Mi',
			long: 'Mittwoch',
		},
		tue: {
			short: 'Di',
			long: 'Donnerstag',
		},
		fri: {
			short: 'Fr',
			long: 'Freitag',
		},
		sat: {
			short: 'Sa',
			long: 'Samstag',
		},
		sun: {
			short: 'So',
			long: 'Sonntag',
		},
	},
	documents: {
		selectDocuments: "Dokumene auswählen",
		selectDocument: "Dokument auswählen",
		uploadDocuments: "Dokumente hochladen",
	},
	error: {
		noUserSelected: 'Sie haben keinen Benutzer ausgewählt',
	},
	generic: {
		chooseReport: 'Bericht auswählen',
		createPosting: 'Vorlage erstellen',
		enter: '{{title}} eingeben',
		isRequired: 'wird benötigt',
		report: 'Bericht',
		downloadReport: 'Bericht herunterladen',
		pleaseEnterValid: 'Bitte geben Sie eine(n) gültige(n) ',
		postingTemplate: 'Vorlage',
		selectPostingTemplate: 'Vorlage auswählen',
		selectReportTemplate: 'Bericht auswählen',
		hideOptionalFields: 'optionale Einträge verbergen',
		showOptionalFields: 'optionale Einträge einblenden',
	},
	logOut: {
		heading: 'Sie werden jetzt ausgeloggt',
		message: 'Inaktivitätstimer ist abgelaufen',
		refresh: 'Die Seite wird nun aktualisiert',
	},
	table: {
		noEntriesFound: 'Keine Einträge gefunden',
	},
	paging: {
		rowsPerPage: 'Zeilen je Seite',
		of: 'von',
		showing: 'Anzeigen',
	},
	input: {
		amountErrorInvalid: 'Bitte geben Sie einen gültigen Betrag ein',
		emailErrorRequired: 'Sie müssen eine E-Mail eingeben',
		emailErrorInvalid: 'Bitte geben Sie eine gültige E-Mail ein',
		passwordErrorRequired: 'Sie müssen das Passwort eingeben',
		passwordErrorInvalid: 'Bitte geben Sie ein gültiges Passwort ein',
		email: {
			label: 'E-Mail',
		},
		password: {
			label: 'Passwort',
		},
		phone: {
			label: 'Telefonnummer',
		},
		firstname: {
			label: 'Vorname',
		},
		lastname: {
			label: 'Nachname',
		},
		address: {
			label: 'Adresse',
		},
		city: {
			label: 'Stadt',
		},
		postCode: {
			label: 'Postleitzahl',
		},
		state: {
			label: 'Land',
		},
		gender: {
			label: 'Geschlecht',
		},
		nationality: {
			label: 'Nationalität',
		},
		customerNumber: {
			label: 'Kundennummer',
		},
		accountNumber: {
			label: 'Kontonummer',
		},
		cardNumber: {
			label: 'Kartennummer',
		},
		pin: {
			label: 'Ihre Pin',
		},
		nr: {
			label: 'Hausnummer',
		},
		search: {
			label: 'Suche',
		},
		passportId: {
			label: 'Ausweisnummer',
		},
	},
	nav: {
		dashboard: 'Dashboard',
		customers: 'Kunden',
		transactions: 'Transaktionen',
		postingTemplates: 'Vorlagen',
		reports: 'Berichte',
		backoffice: 'Backoffice',
		reporting: 'Berichten',
		administration: 'Administration',
		permissions: 'Rechte',
		fees: 'Zinsen',
		teams: 'Teams',
		settings: 'Einstellungen',
		connections: 'Verlinkte Verbindungen',
	},
	overlay: {
		passwordTitle: 'Geben Sie zuerst Ihr aktuelles Passwort ein um es zu ändern.',
		noElements: 'Kein Element gefunden',
		confirmation: 'Bestätigung erforderlich',
	},
	team: {
		newTeamMember: 'Neuer Mitarbeiter',
		roleAssignment: 'Rollenzuweisung',
		required: {
			firstName: 'Bitte geben Sie Ihren Vornamen ein',
			lastName: 'Bitte geben Sie Ihren Nachnamen ein',
			email: 'Bitte geben Sie Ihre E-Mail ein',
		},
		header: {
			personId: 'Personen-ID',
			lastname: 'Nachname',
			firstname: 'Vorname',
			email: 'E-Mail',
		},
		input: {
			email: {
				label: 'E-Mail',
				placeholder: 'E-Mail eingeben',
			},
			firstname: {
				label: 'Vorname',
				placeholder: 'Vornamen eingeben',
			},
			fullname: {
				label: 'Name',
			},
			lastname: {
				label: 'Nachname',
				placeholder: 'Nachnamen eingeben',
			},
			role: {
				label: 'Rolle',
				placeholder: 'Rolle eingeben',
			},
		},
	},
	tooltip: {
		copied: 'Kopiert!',
	},
	transactions: {
		dateFrom: 'Vom',
		dateTo: 'Bis',
		dateRangeEnd: 'Startdatum',
		dateRangeStart: 'Enddatum',
		dateRange: 'Datumsbereich',
		downloadStatement: 'Transaktionsliste herunterladen',
		endBalance: 'Endsaldo',
		noPendingTransactions:
			'Es liegen keine ausstehenden Transaktionen für den gewählten Zeitraum vor',
		noTransactions: 'Es liegen keine Transaktionen für den gewählten Zeitraum vor',
		startBalance: 'Startsaldo',
		transactions: 'Transaktionen',
		pending: 'Ausstehende Transaktionen',
		requests: 'Transaktionsanfragen',
		startBalHelper: 'Saldo am Startdatum',
		endBalHelper: 'Saldo am Enddatum',
		pendingHeader: 'Ausstehend',
		hideReversal: 'Stornos ausblenden',
		hideBlocked: 'Geschlossene anzeigen',
		expandableTitle: 'Transaktionsfilter',
		header: {
			attachment: 'Anhang',
			currency: 'Währung',
			information: 'Überweisungsinfo',
			is_blocked: 'Geschlossen',
			date: 'Datum',
			name: 'Name',
			transactionId: 'Transaktions-ID',
			description: 'Buchungstext',
			bookingDate: 'Buchungsdatum',
			valueDate: 'Valuta-Datum',
			amount: 'Betrag',
			requestMoneyId: 'Geld-ID anfragen',
			statement: 'Schlüssel',
		},
		search: {
			placeholder: 'Transaktionen durchsuchen',
		},
	},
	usermenu: {
		appInformation: 'Informationen',
		customerCare: 'Kundendienst',
		helpCenter: 'Hilfe',
		languages: 'Sprachen',
		logout: 'Abmelden',
		logoutTimer: 'Sie werden in {{minutes}} min ausgeloggt',
		membersince: 'Angemeldet seit dem',
		notifications: 'Benachrichtigungen',
		password: 'Passwort',
		security: 'Sicherheit',
		setLanguage: 'Sprache',
		settings: 'Einstellungen',
		update: 'Aktualisiert',
		version: 'Version',
		language: {
			sq: 'Shqiptar',
			de: 'Deutsch',
			en: 'English',
			nl: 'Niederländisch',
			es: 'Spanisch',
			it: 'Italienisch',
			ja: 'Japanisch',
			sv: 'Schwedisch',
			da: 'Dänisch',
			zh: 'Chinesisch',
			albanian: 'Albanisch',
			english: 'Englisch',
			german: 'Deutsch',
		},
		touchID: 'Touch-ID',
		selectImage: 'Neues Profilbild hochladen',
		showProfile: 'Profil anzeigen',
		upgradeAccount: 'Konto aufwerten',
		manageAccount: 'Konto verwalten',
		closeAccount: {
			title: 'Konto schließen',
			heading: 'Schließen Sie Ihr Konto',
			message:
				'Sind Sie sicher, dass Sie Ihr Konto schließen möchten? Alle gespeicherten Daten werden unwiderruflich gelöscht.',
			button: 'Konto schließen',
		},
	},
	clientStats: {
		registered: 'Registrierte Kunden',
		identified: 'Identifizierte Kunden',
		transacting: 'Aktive Kunden'
	},
	messageMenu: {
		header: 'Nachrichten',
		fetchError: 'Fehler beim Laden der Benachrichtigungen',
	},
	messages: {
		noConfig: {
			error: 'Keine Konfiguration gefunden',
			success: '',
		},
		noBackend: {
			error: 'Das Backend ist nicht erreichbar (Service nicht erreichbar))?',
			success: '',
		},
		loginNoData: {
			error: 'Benutzername/Passwort benötigt',
			success: '',
		},
		transactionGet: {
			error: 'Accountnummer nicht bekannt',
			success: '',
		},
		preferencesSave: {
			error: 'Fehler beim Speichern der Einstellungen',
			success: 'Einstellung erfolgreich gespeichert',
		},
		languageSet: {
			error: 'Fehler beim Ändern der Sprache',
			success: 'Sprache erfolgreich gesetzt',
		},
		addCustomer: {
			error: 'Benutzer hinzufügen fehlgeschlagen',
			success: 'Benutzer erfolgreich hinzugefügt',
		},
		addCustomerExists: {
			error: 'Benutzer existiert bereits',
			success: 'Benutzer erfolgreich hinzugefügt',
		},
		deleteAccount: {
			error: 'Sie haben keine Berechtigung um das Konto zu löschen',
		},
		addTeamMemberRole: {
			error: 'Das Hinzufügen einer Rolle ist nicht möglich',
			success: 'Teammitglied und Rolle erfolgreich hinzugefügt',
		},
		updateTeamMemberRole: {
			error: 'Die Rolle kann nicht aktualisiert werden',
			success: 'Rolle erfolgreich aktualisiert',
		},
		downloadStatements: {
			error: 'Speichern der Umsatzliste fehlgeschlagen',
			success: 'Umsatzliste erfolgreich gespeichert',
		},
		genericRequest: {
			success: 'Die generische Anfrage war erfolgreich',
		},
		getTransactionsParams: {
			error: 'Es fehlen Parameter um accountAccountNumberActivityDetailGet auszuführen',
		},
		languageError: {
			error: 'Die Sprache konnte nicht gesetzt werden',
		},
		login: {
			error: 'Login fehlgeschlagen',
			success: 'Login erfolgreich',
		},
		partnerNotFound: {
			error: 'Partner nicht gefunden',
		},
		passwordWrong: {
			error: 'Das Passwort ist falsch',
			success: 'Passwort erfolgreich geändert',
		},
		postRequestError: {
			error: 'Post Anfrage gescheitert',
		},
		putRequestError: {
			error: 'Put Anfrage gescheitert',
		},
		general: {
			error: 'Ein Fehler ist aufgetreten',
			success: 'Die Anfrage war erfolgreich',
		},
	},
	transfer: {
		balance: 'Guthaben',
		selectAccount: 'Wählen Sie ein Konto aus',
		details: {
			accountFrom: 'Konto von',
			attachments: 'Anhänge',
			datetimeCreate: 'Erstellt am',
			documents: 'Ausweise',
			documentsEid: 'Eid-Dokumente',
			documentsUpload: 'Dokumente hochladen',
			emailAddressIdFrom: 'E-Mail id_from',
			instructedAmount: 'Betrag',
			instructedAmountCurreny: 'Währung',
			emailAddressIdTo: 'Empfänger E-Mail ID',
			inOut: 'Eingang/Ausgang',
			localDatetime: 'Lokale Zeit',
			location: 'Standort',
			personIdFrom: 'Personen-ID von',
			purpose: 'Verwendungszweck',
			refMoneyTransferId: 'Referenz Geldtransfer ID',
			refTaId: 'Referenz Transactions ID',
			referenceId: 'Referenz ID',
			taId: 'Ta-ID',
			text: 'Text',
			title: 'Transaktionsdetails',
			transactionSource: 'Transaktionsquelle',
			cancelTransaction: 'Sind Sie sicher?',
			cancelTransactionHeader: 'Sie stornieren die Transaktion',
			tradeDate: 'Trade-Datum',
			transactionId: 'Transaktions-ID',
		},
		postingJournal: {
			bookingDate: 'Buchungsdatum',
			leftTitle: 'Betrag gesendet',
			source: 'Quelle',
			memo: 'Memo',
			posting: 'Transaktions-ID',
			printPdf: 'Als PDF speichern',
			rightTitle: 'Buchungsjournal',
			valueDate: 'Erzeugungsdatum',
			header: {
				account: 'Konto',
				accountName: 'Kontoname',
				dc: 'Belastung/Gutschrift',
				subbalance: 'Sub-Balance',
				statementId: 'Statement-ID',
				key: 'Schlüssel',
				keyDescription: 'Beschreibung',
				amount: 'Betrag',
				statementText: 'Statement',
			},
		},
	},
	service_center: {
		checkboxes: {
			open_testkit_during_video: 'Testkit während Video geöffnet',
			person_performs_test: 'Person führt Test durch',
			testkit_always_in_video: 'Testkit immer zu sehen',
			video_not_cut: 'Video nicht geschnitten',
			result_readable: 'Resultat lesbar',
			performing_person_and_testkit_match: 'Handelnde Person und Testkit sind korrekt',
			barcode_readable: 'Strichcode lesbar',
		},
		start_date: 'Startdatum',
		end_date: 'Enddatum',
		duration: 'Dauer',
		testkit: 'Testkit',
		testTime: 'Datum und Uhrzeit',
		testtype: 'Testtyp',
		comment: {
			label: 'Kommentar(nur für internen Gebrauch)',
			placeholder: 'Kommentar eingeben'
		},
		overlay: {
			title: 'Testergebnis bestätigen',
			text: 'Diese aktion bestätigt das Testergebnis für diesen Nutzer und kann nicht rückgängig gemacht werden',
		},
		overlayNores: {
			title: 'Validierung bestätigen',
			text: 'Diese aktion bestätigt die Aufnahme für diesen Nutzer und kann nicht rückgängig gemacht werden',
		},
		types: {
			regula_approve_document: 'Dokumentenfreigabe',
			eyeson: 'Videofreigabe',
		},
		createVoucher: 'Voucher anlegen',
		setVoucher: 'Voucherdaten eingeben'
	},
	productMaintenance: {
		title: 'Produktwarting',
		typeOfTest: 'Testtyp',
		category: 'Produktkategorie',
		longDescription: 'Langbeschreibung',
		parent: 'Elternkategorie',
		image: 'Bild',
		mapedTypes: 'Zugehörige Testtypen',
		categoryCode: 'Kategorie-Code',
		productConfig: 'Produktkonfiguration',
		productDelivery: {
			delivery_at_merchant: 'Lieferung beim Händler',
			delivery_at_home: 'Lieferung Zuhause',
			digital_product: 'Digitales Produkt',
		},
		productAppointment: {
			product_appointment: 'Produkttermin',
			merchant_appointment: 'Händlertermin',
			walk_in: 'Vor Ort'
		},
		deliveryType: 'Lieferart',
		appointmentType: 'Terminart'
	},
	onfido: {
		read: 'Gelesen',
		checkResult: 'Ergebnis',
		name: 'Name',
		lastName: 'Nachname',
		datetimeCreate: 'Anlagedatum',
		person_toggle: 'Bearbeitet von',
		showTicked: 'Bearbeitete Fälle anzeigen'
	}
};
