// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AccountOpeningWithIBANListParams
 */
export interface AccountOpeningWithIBANListParams {
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBANListParams
     */
    datetime_create: string;
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBANListParams
     */
    date_from: string;
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBANListParams
     */
    date_to: string;
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBANListParams
     */
    filter_by: AccountOpeningWithIBANListParamsFilterByEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AccountOpeningWithIBANListParamsFilterByEnum {
    AccountCreation = 'date_of_account_creation',
    Identification = 'date_of_identification'
}

