// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ContentDocumentType,
} from './';

/**
 * @export
 * @interface ActedDocument
 */
export interface ActedDocument {
    /**
     * @type {ContentDocumentType}
     * @memberof ActedDocument
     */
    identifier?: ContentDocumentType;
    /**
     * @type {string}
     * @memberof ActedDocument
     */
    file_name?: string;
    /**
     * @type {string}
     * @memberof ActedDocument
     */
    file_type?: string;
    /**
     * @type {string}
     * @memberof ActedDocument
     */
    version?: string;
    /**
     * @type {string}
     * @memberof ActedDocument
     */
    language?: ActedDocumentLanguageEnum;
    /**
     * @type {Array<string>}
     * @memberof ActedDocument
     */
    actions?: Array<ActedDocumentActionsEnum>;
    /**
     * @type {Array<string>}
     * @memberof ActedDocument
     */
    related_versions?: Array<string>;
    /**
     * @type {string}
     * @memberof ActedDocument
     */
    link?: string;
    /**
     * @type {string}
     * @memberof ActedDocument
     */
    last_action?: ActedDocumentLastActionEnum;
    /**
     * @type {Date}
     * @memberof ActedDocument
     */
    datetime_last_action?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum ActedDocumentLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum ActedDocumentActionsEnum {
    R = 'R',
    A = 'A',
    J = 'J'
}
/**
 * @export
 * @enum {string}
 */
export enum ActedDocumentLastActionEnum {
    R = 'R',
    A = 'A',
    J = 'J'
}

