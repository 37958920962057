// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AccountOpeningsPerDayListParams
 */
export interface AccountOpeningsPerDayListParams {
    /**
     * @type {Date}
     * @memberof AccountOpeningsPerDayListParams
     */
    datetime_create: Date;
    /**
     * @type {string}
     * @memberof AccountOpeningsPerDayListParams
     */
    partner: AccountOpeningsPerDayListParamsPartnerEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AccountOpeningsPerDayListParamsPartnerEnum {
    All = 'all',
    NDIDENT = 'NDIDENT',
    NDPAY = 'NDPAY',
    ONEFOR = 'ONEFOR'
}

