// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface MerchantOnsideProductOrderItems
 */
export interface MerchantOnsideProductOrderItems {
    /**
     * @type {number}
     * @memberof MerchantOnsideProductOrderItems
     */
    product_id: number;
    /**
     * @type {number}
     * @memberof MerchantOnsideProductOrderItems
     */
    quantity: number;
    /**
     * @type {Array<string>}
     * @memberof MerchantOnsideProductOrderItems
     */
    languages: Array<MerchantOnsideProductOrderItemsLanguagesEnum>;
    /**
     * DEPRECATED, please switch to test_reason_id
     * @type {string}
     * @memberof MerchantOnsideProductOrderItems
     */
    test_reason?: string;
    /**
     * ID of the test reason for this order
     * @type {number}
     * @memberof MerchantOnsideProductOrderItems
     */
    test_reason_id?: number;
    /**
     * ID of the test reason for this order
     * @type {number}
     * @memberof MerchantOnsideProductOrderItems
     */
    method_taken_id?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum MerchantOnsideProductOrderItemsLanguagesEnum {
    De = 'de',
    El = 'el',
    En = 'en',
    Fr = 'fr',
    Nl = 'nl',
    Ru = 'ru',
    Tr = 'tr',
    Zh = 'zh'
}

