// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Verification,
} from './';

/**
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * unique identifier for the notification
     * @type {string}
     * @memberof Notification
     */
    id: string;
    /**
     * unique identifier of the tenant
     * @type {string}
     * @memberof Notification
     */
    tenantId?: string;
    /**
     * unix timestamp in ms
     * @type {number}
     * @memberof Notification
     */
    date: number;
    /**
     * only known value is Video.Verified
     * @type {string}
     * @memberof Notification
     */
    event: NotificationEventEnum;
    /**
     * @type {Verification}
     * @memberof Notification
     */
    data: Verification;
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationEventEnum {
    VideoVerified = 'Video.Verified'
}

