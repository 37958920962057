// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';

export interface AttachmentSourceIdsGetRequest {
    attachment_id: number;
    source: AttachmentSourceIdsGetSourceEnum;
    ta_id: number;
    id: number;
    token: string;
}

/**
 * no description
 */
export class AttachmentsApi extends BaseAPI implements IOperationIdMap {

    /**
     * get a specific attachment
     * get an attachment
     */
    attachmentSourceIdsGet = (requestParameters: AttachmentSourceIdsGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'attachment_id', 'attachmentSourceIdsGet');
        throwIfRequired(requestParameters, 'source', 'attachmentSourceIdsGet');
        throwIfRequired(requestParameters, 'ta_id', 'attachmentSourceIdsGet');
        throwIfRequired(requestParameters, 'id', 'attachmentSourceIdsGet');
        throwIfRequired(requestParameters, 'token', 'attachmentSourceIdsGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.token && { 'token': requestParameters.token }),
        };

        return this.request<Blob>({
            path: '/attachment/{attachment_id}/{source}/{ta_id}/{id}'.replace('{attachment_id}', encodeURI(requestParameters.attachment_id)).replace('{source}', encodeURI(requestParameters.source)).replace('{ta_id}', encodeURI(requestParameters.ta_id)).replace('{id}', encodeURI(requestParameters.id)),
            method: 'GET',
            headers,
            query,
            responseType: 'blob'
        });
    };


    operationToOperationId = {
	    "attachmentSourceIdsGet": "attachment_source_ids_get"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum AttachmentSourceIdsGetSourceEnum {
    RequestMoney = 'request_money',
    Transaction = 'transaction',
    Email = 'email',
    Location = 'location'
}
