import React from 'react';
import styled from 'styled-components';

import { APages } from '../../../components/nav';

const StyledMain = styled.main<{ smallNav:boolean }>`
	flex-grow: 1;
	overflow: auto;
	height: calc(100vh - 112px);
	width: props.smallNav ? 'calc(100vw - 241px)': 'calc(100vw - 241px)'}; 
	bottom: 48px;
	background-color: ${(props) => props.theme.Global.backgroundColor};
	padding: 28px;
	padding-bottom: 0px;
	box-sizing: border-box;
`;

const StyledContentContainer = styled.div<{ smallNav:boolean }>`
	width: ${props => props.smallNav ? 'calc(100vw - 120px)': 'calc(100vw - 300px)'};
	height: 100%;

	display: flex;
	flex-direction: column;
`;

interface IProps {
	pages: APages;
	smallNav: boolean;
}

interface IState {
	smallNav: boolean;
}

class ContentComponent extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			smallNav: props.smallNav,
		};
	}

	static getDerivedStateFromProps(props: IProps, state: IState): IState | null {
		if (props.smallNav !== state.smallNav) {
			return {
				smallNav: props.smallNav,
			};
		}

		return null;
	}

	render() {
		const currentUrl = window.location.pathname.toLocaleLowerCase();
		return (
			<StyledMain id="mainContent" smallNav= {this.state.smallNav} >
				<StyledContentContainer id="content-container" smallNav= {this.state.smallNav} >
					{this.props.pages.getActiveComponent(currentUrl)}
				</StyledContentContainer>
			</StyledMain>
		);
	}
}

export default ContentComponent;
