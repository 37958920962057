// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    FeeAssignment,
    FeeEntry,
} from '../models';

export interface ConfigurationAutomaticFeeAssignmentDeleteRequest {
    automatic_fee_assignment_id: number;
}

export interface ConfigurationAutomaticFeeAssignmentPutRequest {
    FeeAssignment?: FeeAssignment;
}

/**
 * no description
 */
export class ConfigurationApi extends BaseAPI implements IOperationIdMap {

    /**
     */
    configurationAutomaticFeeAssignmentDelete = (requestParameters: ConfigurationAutomaticFeeAssignmentDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'automatic_fee_assignment_id', 'configurationAutomaticFeeAssignmentDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.automatic_fee_assignment_id && { 'automatic_fee_assignment_id': requestParameters.automatic_fee_assignment_id }),
        };

        return this.request<void>({
            path: '/configuration/automatic_fee_assignment',
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     */
    configurationAutomaticFeeAssignmentGet = (): Observable<Array<FeeAssignment>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<FeeAssignment>>({
            path: '/configuration/automatic_fee_assignment',
            method: 'GET',
            headers,
        });
    };

    /**
     */
    configurationAutomaticFeeAssignmentPut = (requestParameters: ConfigurationAutomaticFeeAssignmentPutRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/configuration/automatic_fee_assignment',
            method: 'PUT',
            headers,
            body: requestParameters.FeeAssignment,
        });
    };

    /**
     */
    configurationFeePackagesGet = (): Observable<Array<FeeEntry>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<FeeEntry>>({
            path: '/configuration/fee_packages',
            method: 'GET',
            headers,
        });
    };


    operationToOperationId = {
	    "configurationAutomaticFeeAssignmentDelete": "configuration_automatic_fee_assignment_delete" ,
	    "configurationAutomaticFeeAssignmentGet": "configuration_automatic_fee_assignment_get" ,
	    "configurationAutomaticFeeAssignmentPut": "configuration_automatic_fee_assignment_put" ,
	    "configurationFeePackagesGet": "configuration_fee_packages_get"
    }
}
