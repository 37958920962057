// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Iso4217CurrencyOUT,
} from './';

/**
 * @export
 * @interface CardAuthorization
 */
export interface CardAuthorization {
    /**
     * name of the customer
     * @type {string}
     * @memberof CardAuthorization
     */
    name?: string;
    /**
     * given name of the customer
     * @type {string}
     * @memberof CardAuthorization
     */
    given_name?: string;
    /**
     * @type {string}
     * @memberof CardAuthorization
     */
    card_number_masked?: string;
    /**
     * @type {Iso4217CurrencyOUT}
     * @memberof CardAuthorization
     */
    currency?: Iso4217CurrencyOUT;
    /**
     * @type {string}
     * @memberof CardAuthorization
     */
    debit_credit?: CardAuthorizationDebitCreditEnum;
    /**
     * @type {number}
     * @memberof CardAuthorization
     */
    amount?: number;
    /**
     * @type {Date}
     * @memberof CardAuthorization
     */
    sysdate?: Date;
    /**
     * @type {string}
     * @memberof CardAuthorization
     */
    card_acceptor_name_and_location?: string;
    /**
     * @type {string}
     * @memberof CardAuthorization
     */
    reference_number?: string;
    /**
     * @type {string}
     * @memberof CardAuthorization
     */
    auth_code?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CardAuthorizationDebitCreditEnum {
    D = 'D',
    C = 'C'
}

