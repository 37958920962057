// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CountryCode,
    ExternalIdentifier,
    Sex,
} from './';

/**
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * @type {number}
     * @memberof Person
     */
    person_id: number;
    /**
     * date and time this person was initially created
     * @type {Date}
     * @memberof Person
     */
    datetime_create: Date;
    /**
     * date and time this entry was last updated
     * @type {Date}
     * @memberof Person
     */
    datetime_update: Date;
    /**
     * the persons state:   * \'C\' - CREATED - the person was created but did not confirm its initial email   * \'V\' - VERIFIED - the person was created and confirmed the initial email   * \'R\' - PENDING - the person successfully applied for upgrade   * \'I\' - IDENTIFIED - the person upgraded to identified 
     * @type {string}
     * @memberof Person
     */
    state: PersonStateEnum;
    /**
     * the persons name
     * @type {string}
     * @memberof Person
     */
    name?: string;
    /**
     * the persons given name
     * @type {string}
     * @memberof Person
     */
    given_name?: string;
    /**
     * the persons birth date
     * @type {Date}
     * @memberof Person
     */
    birth_date?: Date;
    /**
     * City or municipality
     * @type {string}
     * @memberof Person
     */
    birth_city?: string;
    /**
     * @type {boolean}
     * @memberof Person
     */
    pep?: boolean;
    /**
     * @type {boolean}
     * @memberof Person
     */
    fatca?: boolean;
    /**
     * indicates if the pertson is an us person
     * @type {boolean}
     * @memberof Person
     */
    us_person?: boolean;
    /**
     * indicates if the pertson is at least_18 years or older
     * @type {boolean}
     * @memberof Person
     */
    age_check?: boolean;
    /**
     * @type {boolean}
     * @memberof Person
     */
    advanced_kyc?: boolean;
    /**
     * person claims to act on own behalf
     * @type {boolean}
     * @memberof Person
     */
    own_behalf?: boolean;
    /**
     * indicates if the person is non resident
     * @type {boolean}
     * @memberof Person
     */
    resident?: boolean;
    /**
     * @type {Sex}
     * @memberof Person
     */
    sex?: Sex;
    /**
     * @type {CountryCode}
     * @memberof Person
     */
    nationality?: CountryCode;
    /**
     * the email address
     * @type {string}
     * @memberof Person
     */
    primary_email_address: string;
    /**
     * the phone number in format E.164
     * @type {string}
     * @memberof Person
     */
    primary_phone_number?: string;
    /**
     * list of partner_ids the person is connected to
     * @type {Array<number>}
     * @memberof Person
     */
    partner_ids?: Array<number>;
    /**
     * indicates if the person has status merchant
     * @type {boolean}
     * @memberof Person
     */
    is_merchant: boolean;
    /**
     * indicates if the person has status merchant
     * @type {boolean}
     * @memberof Person
     */
    is_testcenter?: boolean;
    /**
     * the persons company
     * @type {string}
     * @memberof Person
     */
    company_name?: string;
    /**
     * @type {string}
     * @memberof Person
     */
    segment?: string;
    /**
     * @type {string}
     * @memberof Person
     */
    merchant_description?: string;
    /**
     * @type {string}
     * @memberof Person
     */
    risk_score?: string;
    /**
     * @type {Array<ExternalIdentifier>}
     * @memberof Person
     */
    external_identifier?: Array<ExternalIdentifier>;
    /**
     * if companion logic is enabled:   * primary   * companion   * joint 
     * @type {string}
     * @memberof Person
     */
    client_profile?: PersonClientProfileEnum;
    /**
     * if companion logic is enabled, this field indicates if the person is an eu member or not. until kyc is available, this can just be guessed by the persons phone number 
     * @type {boolean}
     * @memberof Person
     */
    is_eu_member?: boolean;
    /**
     * @type {boolean}
     * @memberof Person
     */
    need_kyc?: boolean;
    /**
     * @type {boolean}
     * @memberof Person
     */
    in_closure?: boolean;
    /**
     * @type {string}
     * @memberof Person
     */
    user_id?: string;
    /**
     * @type {string}
     * @memberof Person
     */
    user_name?: string;
    /**
     * @type {string}
     * @memberof Person
     */
    campaign_codes?: string;
    /**
     * @type {string}
     * @memberof Person
     */
    family_relation?: string;
    /**
     * @type {boolean}
     * @memberof Person
     */
    is_public?: boolean;

    is_healthpoint?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum PersonStateEnum {
    C = 'C',
    V = 'V',
    R = 'R',
    I = 'I'
}
/**
 * @export
 * @enum {string}
 */
export enum PersonClientProfileEnum {
    Primary = 'primary',
    Companion = 'companion',
    Joint = 'joint'
}

