// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    BpcCardStatusFile,
    BpcClearingFile,
    BpcDbalFile,
} from '../models';

export interface BpcCardStatusFilePostRequest {
    file: Blob;
}

export interface BpcCardStatusFilesListRequest {
    limit: number;
    offset: number;
}

export interface BpcClearingFilePostRequest {
    file: Blob;
}

export interface BpcClearingFilesListRequest {
    limit: number;
    offset: number;
}

export interface BpcDbalFileGetRequest {
    bpc_dbal_file_id: number;
}

export interface BpcDbalFilesListRequest {
    limit: number;
    offset: number;
}

/**
 * no description
 */
export class BpcApi extends BaseAPI implements IOperationIdMap {

    /**
     */
    bpcCardStatusFilePost = (requestParameters: BpcCardStatusFilePostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'file', 'bpcCardStatusFilePost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.file !== undefined) {
            formData.append('file', requestParameters.file as any);
        }

        return this.request<void>({
            path: '/bpc/card_status_file',
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     */
    bpcCardStatusFilesList = (requestParameters: BpcCardStatusFilesListRequest): Observable<Array<BpcCardStatusFile>> => {
        throwIfRequired(requestParameters, 'limit', 'bpcCardStatusFilesList');
        throwIfRequired(requestParameters, 'offset', 'bpcCardStatusFilesList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.limit && { 'limit': requestParameters.limit }),
            offset: requestParameters.offset,
        };

        return this.request<Array<BpcCardStatusFile>>({
            path: '/bpc/card_status_files',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    bpcClearingFilePost = (requestParameters: BpcClearingFilePostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'file', 'bpcClearingFilePost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.file !== undefined) {
            formData.append('file', requestParameters.file as any);
        }

        return this.request<void>({
            path: '/bpc/clearing_file',
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     */
    bpcClearingFilesList = (requestParameters: BpcClearingFilesListRequest): Observable<Array<BpcClearingFile>> => {
        throwIfRequired(requestParameters, 'limit', 'bpcClearingFilesList');
        throwIfRequired(requestParameters, 'offset', 'bpcClearingFilesList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.limit && { 'limit': requestParameters.limit }),
            offset: requestParameters.offset,
        };

        return this.request<Array<BpcClearingFile>>({
            path: '/bpc/clearing_files',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    bpcDbalFileGet = (requestParameters: BpcDbalFileGetRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'bpc_dbal_file_id', 'bpcDbalFileGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.bpc_dbal_file_id && { 'bpc_dbal_file_id': requestParameters.bpc_dbal_file_id }),
        };

        return this.request<void>({
            path: '/bpc/dbal_file',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    bpcDbalFilesList = (requestParameters: BpcDbalFilesListRequest): Observable<Array<BpcDbalFile>> => {
        throwIfRequired(requestParameters, 'limit', 'bpcDbalFilesList');
        throwIfRequired(requestParameters, 'offset', 'bpcDbalFilesList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.limit && { 'limit': requestParameters.limit }),
            offset: requestParameters.offset,
        };

        return this.request<Array<BpcDbalFile>>({
            path: '/bpc/dbal_files',
            method: 'GET',
            headers,
            query,
        });
    };


    operationToOperationId = {
	    "bpcCardStatusFilePost": "bpc_card_status_file_post" ,
	    "bpcCardStatusFilesList": "bpc_card_status_files_list" ,
	    "bpcClearingFilePost": "bpc_clearing_file_post" ,
	    "bpcClearingFilesList": "bpc_clearing_files_list" ,
	    "bpcDbalFileGet": "bpc_dbal_file_get" ,
	    "bpcDbalFilesList": "bpc_dbal_files_list"
    }
}
