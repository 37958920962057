import React from 'react';
import {ContainerText} from '../transfer/transfersComponent.css'
import styled from 'styled-components';
import { api, apis, ApiError, VoidResponse } from '../../../../logic/api';
import {
	IStatusState,
	Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Log, Logs } from '../../../../logic/log';
import { translate } from '../../../../common/language/translate';
import {
	IInitProps,
	IInitState,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import { FlexBox } from '../../../auth/auth.css';
import { evaluateErrorMessage } from '../../../../logic/helper/Common';
import Title from '../../../../components/compositcomponents/title';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';
import { format } from '../../../../logic/helper/format';
import { CheckBox } from '../../../../components/atomiccompoents/form';
import { Table } from '../../../../components/compositcomponents/table/table';
import Switch from '../../../../components/atomiccompoents/switch';
import { KeyField } from '../customers/basicStyledComponents/customerDetails.css';


const StyledViewMain = styled(FlexBox)`
	display: flex;
	flex-direction: column;
`;


interface IOnfidoData {
	onfido_backoffice_notification_id: number,
	datetime_create: Date,
	person_id: number,
	status: "U" | "R",
	check_result: string,
	last_name: string,
	first_name: string
}
interface IProps extends IInitProps {
	notification?: (
		personId: number,
	) => void;
}

interface IState extends IStatusState, IInitState {
	data: Array<IOnfidoData>,
	showTicked?: boolean
}

export class OnfidoComponent extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		const n: IState = {
			data: [],
			showTicked: false
		};

		this.state = n;
		this.converter = this.converter.bind(this);
		this.getData()
	}

	converter = function(
        line: IOnfidoData,
        index: number,
		fields: Array<string>
	): RowType {
		const row: RowType = { cells: [], ref: line };
		if (row.cells == null) {
			return row;
		}
		const id = line.onfido_backoffice_notification_id;
		const keys: Array<string> = Object.keys(line);
		for (let i = 0; i < fields.length; i++) {
			const field: string = fields[i];
			switch (field) {
                case 'datetime_create': {
                    const valueDate: string | undefined = format.date(line[field]);
					row.cells.push({
						value: valueDate == null ? '-' : valueDate,
						display: (
							<ContainerText title={valueDate}>
								{valueDate == null ? '-' : valueDate}
							</ContainerText>
						),
						copyVal: valueDate != null ? valueDate.toString() : '-',
                        methods: { customersCallback: () => { this.props.notification(line.person_id)}},
					});
					break;
				}
				case 'status': {
					const status = line.status;
					row.cells.push({
						value: status,
						display: (
							<ContainerText title={status}>
								<CheckBox 
									id={id + '_box'}
									value={status === 'R'}
									onChange={(value: boolean) => {										
										this.toggleEntry(id, value ? 'R' : 'U', index);
									}}
									disabled={status === 'R'}

								/>
							</ContainerText>
						),
						copyVal: status,
                        methods: { customersCallback: () => { this.props.notification(line.person_id)}},
					});
					break;
				}
			
				default: {
					if (Object.prototype.hasOwnProperty.call(line, field)) {
						row.cells.push({
							value: String(Object.values(line)[keys.indexOf(field)]),
							display: <ContainerText 
										title={String(Object.values(line)[keys.indexOf(field)])}>
											{String(Object.values(line)[keys.indexOf(field)])}
									</ContainerText>,
							methods: { customersCallback: () => { this.props.notification(line.person_id)}},
							copyVal: String(Object.values(line)[keys.indexOf(field)])
							});
					} else {
						row.cells.push({ value: '' });
					}
					break;
				}
			}
		}
		return row;
	};
    
	_onChangeCustomerData() {
		this.setState({
			
		})
    }

	getData() {
		api.asyncRequest<Array<IOnfidoData>>(
			{limit: 1000},
			apis.OnfidoApi,
			'onfidoBackofficeNotificationsGet',
			 ).then((response: Array<IOnfidoData>) => {
				this.setState({
					data: response
				})
			 }).catch((error: ApiError) => {
			 	Log.error(Logs.API, "Error on getting stats", error);
			 });
	}

	toggleEntry(notificationId: number, status: 'U' | 'R', index: number) {
		api.asyncRequest<VoidResponse>(
			{onfido_backoffice_notification_id: notificationId},
			apis.OnfidoApi,
			'onfidoBackofficeNotificationTogglePut',
			 ).then(() => {
				const data = this.state.data;
				data[index].status = status;
				this.setState({
					data: data
				})
				MessageHandler.onSuccess(Reporter['onfido.notification.put']);
			 }).catch((error: ApiError) => {
				MessageHandler.onError(Reporter['onfido.notification.put'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
			 	Log.error(Logs.API, "Error on getting stats", error);
			 });
	}

	render() {
		
		return(
			<StyledViewMain>
				<Title 
					title='Onfido Notifications'
				/>
				<div style={{display: 'flex', marginLeft: '16px', marginTop: '8px'}}>
					<KeyField style={{marginRight: '8px'}}>
						{translate('onfido.showTicked')}
					</KeyField>
					<Switch 
						id='showProcessedSwitch'
						defaultValue={this.state.showTicked}
						key= {translate('onfido.showTicked')}
						onChange={(value: boolean) => this.setState({ showTicked: value})}
					/>
				</div>
				<Table<IOnfidoData>
                            header={[	
								'PersonID',											
                                translate('onfido.name'),
								translate('onfido.lastName'),
								translate('onfido.datetimeCreate'),
                                translate('onfido.checkResult'),
                                translate('onfido.read'),
								translate('onfido.person_toggle')
                                
                            ]}
                            sourceData={this.state.showTicked === true ? 
											this.state.data :
											this.state.data.filter((value: IOnfidoData) => {
												return value.status === 'U';
											})}
                            fields={[												
                                'person_id',
                                'first_name',
                                'last_name',
                                'datetime_create',
                                'check_result',
                                'status',
								'person_toggle'
                            ]}
                            stickyHeader={true}
                            highlightTextOnSearch={true}
                            dataConverter = {this.converter}
                        />
			</StyledViewMain>
		);
	}
}



