import React from 'react';
import { Account, api, apis, ApiError, Ident } from '../../../../logic/api';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Log, Logs } from '../../../../logic/log';
import { Reporter } from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';
import { Container, ContainerText } from '../transfer/transfersComponent.css';
import { translate } from '../../../../common/language/translate';
import { Table } from '../../../../components/compositcomponents/table/table';
import styled from 'styled-components';
import { PagingComponent } from '../../../../components/paging';
import { format } from '../../../../logic/helper/format';
import { CheckBox } from '../../../../components/atomiccompoents/form/checkbox';
import { CustomerDataStore, PermissionStore } from '../../../../logic/flux';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import Title from '../../../../components/compositcomponents/title';
import { Icons } from '../../../../images';
import { IconWrapper } from './basicStyledComponents/customerDetails.css';
import { compareDate, evaluateErrorMessage } from '../../../../logic/helper/Common';
import AccountSelector from '../../../../components/accountSelector';
import ApprovalDetails from '../backoffice/approvalDetails';


const Main = styled.div`
    display: flex,
    flex-direction: column;
    align-items: center;
` ;
const PagingWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
const ButtonWrapper = styled.div`
	margin-top: 32px;
	display: flex;
	width: 100%;
`;
const CheckboxContainer = styled(Container)`
`;
const SplitViewWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;
const SplitView = styled.div`
	width: 50%;
	padding: 16px;
	height: calc(100vh - 250px);
	display: flex;
	flex-direction: column;
`;

const DetailsTitleWrapper = styled.div`
	margin-left: 64px;
	margin-top: -4px;
	width: 100%;
	display: flex;
	align-items: center;
	svg {
		fill: ${props => props.theme.Global.primaryColor};
	}
`;

 interface IProps {
	callback: (path: string) => void;
	isCustomerView?: boolean;
	releaseType?: Ident.ReleaseType;
 }

interface IState {
  approvalDataIdent?: Array<Ident.Release>,
  toApproveIdent: {[key: string]: number},
  selectedApproval?: number,
  selectedPersonId?: number,
  selectedError?: string,
  releaseType: Ident.ReleaseType
}

class CustomerOpenApprovals extends React.Component<IProps, IState> {

	private tableRef: Table<Ident.Release> | null = null;
	converter(
		line: Ident.Release,
		index: number,
		fields: Array<string>
	): RowType {
		const row: RowType = { cells: [], ref: line };
		if (row.cells == null) {
			return row;
		}

		const keys: Array<string> = Object.keys(line);
		const releaseId: number = line.release_requests_id == null ? -1 : line.release_requests_id;
		for (let i = 0; i < fields.length; i++) {
			const field: string = fields[i];
			switch (field) {
				case 'release_requests_id': {
					row.cells.push({
						value: line.release_requests_id,
						display: (
							<CheckboxContainer style={{justifyContent: 'center', width: '100%', display: 'flex'}}>
								<CheckBox 
									id={line.release_requests_id.toString()}
									disabled={!PermissionStore.hasPermission(Account.OperationId.ReleasesPut) || line.release_blocked === true}
									value={ this.state.toApproveIdent[line.release_requests_id.toString()] != null }
									onChange={(value: boolean) => this.setToApprove(line.release_requests_id, value, line.error, line.person_id_cust)}
								/>
 							</CheckboxContainer>
						),
						copyVal: line.release_requests_id.toString(),
						methods: {
							approvalCallback: line.release_blocked === true ? () =>  this.doAction(Ident.ReleaseAction.R,  releaseId) : undefined,
							cancelCallback: line.release_blocked === true ? () => this.doAction(Ident.ReleaseAction.B, releaseId) : undefined,
                        },
					});
					break;
                }
                case 'status': {
                    const status = translate('backoffice.approvals.status.' + line.status, line.status);
					row.cells.push({
						value: status,
						display: (
							<Container onClick={() => { this.setState({
								selectedApproval: releaseId,
								selectedError: line.error,
								selectedPersonId: line.person_id_cust
							})}}>
								<ContainerText title={status}>{status}</ContainerText>
							</Container>
						),
						copyVal: status,
                        methods: {
							approvalCallback: () =>  this.doAction(Ident.ReleaseAction.R, releaseId),
							cancelCallback: () => this.doAction(Ident.ReleaseAction.B, releaseId),
                        },
					});
					break;
				}
				case 'release_blocked': {
					row.cells.push({
						value: line.release_blocked?.toString(),
						display: (
							<Container onClick={() => { this.setState({
								selectedApproval: releaseId,
								selectedError: line.error,
								selectedPersonId: line.person_id_cust
							})}}>
								<ContainerText title={line.release_blocked?.toString()}>{line.release_blocked === true ? Icons.checkBoxIcon() : Icons.cancelCross()}</ContainerText>
							</Container>
						),
						copyVal: line.release_blocked.toString(),
                        methods: {
							approvalCallback: () =>  this.doAction(Ident.ReleaseAction.R, releaseId),
							cancelCallback: () => this.doAction(Ident.ReleaseAction.B, releaseId),
                        },
					});
					break;
				}
				case 'requested_by_person_given_name': {
					const value = line.requested_by_person_given_name + " " + line.requested_by_person_name;
					row.cells.push({
						value: value,
						display: (
							<Container onClick={() => { this.setState({
								selectedApproval: releaseId,
								selectedError: line.error,
								selectedPersonId: line.person_id_cust
							})}}>
								<ContainerText title={value}>{value}</ContainerText>
							</Container>
						),
						copyVal: value,
                        methods: {
							approvalCallback: () =>  this.doAction(Ident.ReleaseAction.R, releaseId),
							cancelCallback: () => this.doAction(Ident.ReleaseAction.B, releaseId),
                        },
					});
					break;
                }
                case 'datetime_create':
				case 'last_modified': {
					const valueDate: string | undefined = format.date(line[field]);
					row.cells.push({
						value: valueDate == null ? '-' : valueDate,
						display: (
							<Container onClick={() => { this.setState({
								selectedApproval: releaseId,
								selectedError: line.error,
								selectedPersonId: line.person_id_cust
							})}}>
								<ContainerText title={valueDate}>
									{valueDate == null ? '-' : valueDate}
								</ContainerText>
							</Container>
						),
						copyVal: valueDate != null ? valueDate.toString() : '-',
                        methods: {
							approvalCallback: () =>  this.doAction(Ident.ReleaseAction.R, releaseId),
							cancelCallback: () => this.doAction(Ident.ReleaseAction.B, releaseId),
                        },
					});
					break;
				}
				default: {
					if (Object.prototype.hasOwnProperty.call(line, field)) {
						row.cells.push({
							value: Object.values(line)[keys.indexOf(field)],
							display: (
								<Container onClick={() => { this.setState({
									selectedApproval: releaseId,
									selectedError: line.error,
									selectedPersonId: line.person_id_cust
								})}}>
									<ContainerText
										title={Object.values(line)[keys.indexOf(field)]}>
										{Object.values(line)[keys.indexOf(field)]}
									</ContainerText>
								</Container>
							),
							copyVal: Object.values(line)[keys.indexOf(field)],
							methods: {
								approvalCallback: () =>  this.doAction(Ident.ReleaseAction.R, releaseId),
								cancelCallback: () => this.doAction(Ident.ReleaseAction.B, releaseId),
							},
						});
					} else {
						row.cells.push({ value: '' });
					}
					break;
				}
			}
		}

		return row;
	};
	private resetIndex = false;

	constructor(props: IProps) {
		super(props);
		this.state = {
			toApproveIdent: {},
			releaseType:  props.releaseType == null ? Ident.ReleaseType.AddressSave : props.releaseType
		};
		this.converter = this.converter.bind(this);
		this.doAction = this.doAction.bind(this);
		this.fetchAllReleases = this.fetchAllReleases.bind(this);
		this.setTableRef = this.setTableRef.bind(this);
		this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
	}

	componentWillUnmount() {
		CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
	}

	componentDidMount() {		
		if(this.props.isCustomerView === true) {
			CustomerDataStore.addChangeListener(this._onChangeCustomerData);
			this._onChangeCustomerData();
		} else{
        	this.fetchAllReleases();
		}
    }

	_onChangeCustomerData() {
    }
    

	doAction(action: Ident.ReleaseAction, releaseId: number) {
		const req: Account.ReleasesPutRequest = {
			ReleasePut: {
				release_action: action,
				release_request_id: releaseId
			}
		}
		api.asyncRequest<any>(
			req,
			apis.ReleaseApiIdent,
			'releasesPut'
		).then((response: Ident.ReleaseTriggered) => {
			if (response != null) {
					if(response.release != null && response.release.status.toString() === 'C') {
						MessageHandler.onSuccess(Reporter['backoffice.relaeses.put.cancel'] );
						this.fetchAllReleases();
					} else if(response.triggered_state === Ident.ReleaseTriggerState.S ) {
						MessageHandler.onSuccess(Reporter['backoffice.relaeses.put'] );
						this.fetchAllReleases();
					}  else if(response.triggered_state === Ident.ReleaseTriggerState.N ) {
						MessageHandler.onSuccess(Reporter['backoffice.relaeses.put.new_release'] );
					} else {
						//@ts-ignore
						MessageHandler.onSuccess(Reporter['backoffice.relaeses.put.trigger'], "Approved but:" +  triggered_event.error_text );
					}
			}
		})
		.catch((error: ApiError) => {
			Log.error(Logs.API, error);
			MessageHandler.onError(Reporter['backoffice.relaeses.put'],evaluateErrorMessage(error, true), evaluateErrorMessage(error, false) );
		});
	}
	
	doActionMult(action: Account.ReleaseAction | Ident.ReleaseAction, releaseId: number) {
		const req: Account.ReleasesPutRequest = {
			ReleasePut: {
				release_action: action,
				release_request_id: releaseId
			}
		}
		return api.asyncRequest<any>(
			req,
			apis.ReleaseApiIdent,
			'releasesPut');
		}

	setToApprove(id: number, set: boolean, error?: string, person_id?: number) {
		const approve = this.state.toApproveIdent;
		if(set) {
			approve[id.toString()] = id;
		} else {
			delete approve[id.toString()];
		}
		this.setState({
			toApproveIdent: approve,
			selectedApproval: set ? id : this.state.selectedApproval,
			selectedError: set && error != null ? error : this.state.selectedError,
			selectedPersonId: person_id
		})
	}
    

	handleAllSelected(status: Ident.ReleaseAction) {
		const toApproveId = this.state.toApproveIdent;
		const keysident = Object.keys(toApproveId);
		if(keysident.length > 0) {
			const promisesId = Object.entries(toApproveId).map(keyValue => this.doActionMult(status, keyValue[1]) );
			Promise.all(promisesId).then(values => {
				let message:string = "backoffice.relaeses.put.ident";
				for(const o of values) {
					if(o.triggered_state === Ident.ReleaseTriggerState.N ) {
						message = "backoffice.relaeses.put.retriggered." + status.toString();
					} else if (o.triggered_state === Ident.ReleaseTriggerState.E ) {
						message = "backoffice.relaeses.put.not_all";
					}
				}
				//@ts-ignore
				MessageHandler.onSuccess(Reporter[message as keyof Reporter]);
			}).catch(error => {
				Log.error(Logs.API, error);
				MessageHandler.onError(Reporter['backoffice.relaeses.put'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
			})

		}
		this.setState({
			toApproveIdent: {}
		}, () => {
			this.fetchAllReleases();
		});
		/*for(const key in keys) {
			this.doAction(Account.ReleaseAction.R, toApprove[keys[key]], keys.length > 1 ? true : true , apis.ReleaseApi);
		}
		for(const key in keysident) {
			this.doAction(Account.ReleaseAction.R, toApproveId[keysident[key]], keysident.length > 1 ? true : true , apis.ReleaseApiIdent);
		}
		)*/
	}

	
	fetchAllReleases() {
		this.fetchIdentReleases()
	}
    
	fetchIdentReleases() {
		const req: Ident.ReleasesGetRequest = {
			release_status: [Ident.ReleaseStatus.W, Ident.ReleaseStatus.N],
			release_type: this.state.releaseType
		}
		api.asyncRequest<any>(
			req,
			apis.ReleaseApiIdent,
			'releasesGet'
		).then((response) => {
			if (response != null) {
				const res = [];
				for(const i in response) {
					res.push({
						source: 'i',
						...response[i]
					})
				}
				this.setState({
					approvalDataIdent: res,
				}, () => {
					this.resetIndex = false;
					if(this.tableRef != null) {
						this.tableRef.resetPaging(0);
					}

				})
			}
		})
		.catch((error: ApiError) => {
			Log.error(Logs.API, error);
			MessageHandler.onError(Reporter['backoffice.relaeses.fetch'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
		});
	}
	
	compareData(a: Ident.Release, b: Ident.Release): number {
		return -compareDate(a.datetime_create, b.datetime_create) 
	}

	private setTableRef(element: Table<Ident.Release>): void {
		this.tableRef = element;
	}

	render() {
		const identData = this.state.approvalDataIdent != null ? this.state.approvalDataIdent : [];
		const srcData = identData.sort(this.compareData);
		return (
			<Main>
				{this.props.isCustomerView === true ?
				<AccountSelector /> : null }
				<SplitViewWrapper>
					<SplitView>	
						<div style={{display: 'flex'}}>
								<IconWrapper
									style={{alignSelf: 'flex-start'}} 
									onClick={() => this.fetchAllReleases()}>
										{Icons.refresh()}
								</IconWrapper>
						<PagingWrapper>
								
							<PagingComponent
								numberOfEntries={srcData.length }
								rowsPerPage={5}
								resetIndex={this.resetIndex}
							/>
						</PagingWrapper>
						</div>
						<div style={{maxHeight: 'calc(100vh - 400px', overflowY: 'auto'}}>
							{srcData == null ?  null : 
								<Table<Ident.Release>
										header={[
											translate('backoffice.approvals.header.approval'),
											translate('backoffice.approvals.header.created'),
											translate('backoffice.approvals.header.status'),
											translate('backoffice.approvals.header.name'),
											translate('backoffice.approvals.header.type'),
											translate('backoffice.approvals.header.blocked'),
											translate('backoffice.approvals.header.block_reason'),
										]}
										sourceData={
											srcData
										}
										fields={[
											'release_requests_id',
											'datetime_create',
											'status',
											'requested_by_person_given_name',
											'type',
											'release_blocked',
											'block_reason'
										]}
										stickyHeader={true}
										dataConverter={this.converter}									
										dynamicPaging={true}
										externalPaging={true}
										ref={this.setTableRef}
								
									/>
							}
						</div>
								
						<ButtonWrapper>
							<ButtonOk 
								disableSpinner={true}
								onClick={(event) => {
									event.stopPropagation();
									this.handleAllSelected(Account.ReleaseAction.R)
								}}
								disabled= {Object.keys(this.state.toApproveIdent).length === 0}
								> 
								 { translate('backoffice.approvals.approve') } 
							</ButtonOk>
							<div style={{width: '32px'}}/>
							<ButtonOk 
								disableSpinner={true}
								onClick={(event) => {
									event.stopPropagation();
									this.handleAllSelected(Account.ReleaseAction.B)
								}}
								disabled= {Object.keys(this.state.toApproveIdent).length === 0}
								> 
								 { translate('backoffice.approvals.reject') } 
							</ButtonOk>
						</ButtonWrapper>
					</SplitView>
					<SplitView>
						<DetailsTitleWrapper>
							{Icons.info() } <Title title={"Details"}/> 
						</DetailsTitleWrapper>
							<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
									<ApprovalDetails 
										callback={this.props.callback}
										relaseRequetsId = {this.state.selectedApproval != null  ? this.state.selectedApproval : -1 }
										error= {this.state.selectedError}
										api={apis.ReleaseApiIdent}
										person_id={this.state.selectedPersonId}
									/> 
							</div>
						
					</SplitView>
				</SplitViewWrapper>
			</Main>
		);
	}
}

export default CustomerOpenApprovals;
