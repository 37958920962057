// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AccessKey
 */
export interface AccessKey {
    /**
     * the access key
     * @type {string}
     * @memberof AccessKey
     */
    access_key?: string;
    /**
     * the person who created the key
     * @type {number}
     * @memberof AccessKey
     */
    person_id_create?: number;
    /**
     * date and time of creation
     * @type {Date}
     * @memberof AccessKey
     */
    datetime_create?: Date;
    /**
     * state:   * \'C\' - Created   * \'A\' - Active   * \'D\' - Deactivated 
     * @type {string}
     * @memberof AccessKey
     */
    state?: AccessKeyStateEnum;
    /**
     * a user given name for the key
     * @type {string}
     * @memberof AccessKey
     */
    name?: string;
    /**
     * date and time of activation
     * @type {Date}
     * @memberof AccessKey
     */
    datetime_activate?: Date;
    /**
     * the person who deactivated the key
     * @type {number}
     * @memberof AccessKey
     */
    person_id_deactivate?: number;
    /**
     * date and time of deactivation
     * @type {Date}
     * @memberof AccessKey
     */
    datetime_deactivate?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum AccessKeyStateEnum {
    C = 'C',
    A = 'A',
    D = 'D'
}

