//@ts-nocheck
import React from 'react';
import { api, apis, ApiError, Ident, Account } from '../../../../../logic/api';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Log, Logs } from '../../../../../logic/log';
import { Reporter } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import { RowType } from '../../../../../components/compositcomponents/table/tableTypes';
import { Container, ContainerText } from '../../transfer/transfersComponent.css';
import { translate } from '../../../../../common/language/translate';
import { Table } from '../../../../../components/compositcomponents/table/table';
import styled from 'styled-components';
import { PagingComponent } from '../../../../../components/paging';
import { format } from '../../../../../logic/helper/format';
import { Icons } from '../../../../../images';
import { compareDate, evaluateErrorMessage } from '../../../../../logic/helper/Common';
import PassportVerification from './passportVerification';
import { IconWrapper } from '../../customers/basicStyledComponents/customerDetails.css';
import { StyledInput } from '../../../../../components/atomiccompoents/form/inputs.css';


const Main = styled.div`
    display: flex,
    flex-direction: column;
    align-items: center;
` ;
const PagingWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
const IconWrapperInTable = styled.div`
	display: flex;
	justify-content: center;
	:hover {
		cursor: pointer;
	}
`;

const SplitView = styled.div`
	width: 95%;
	padding: 16px;
	height: calc(100vh - 250px);
	display: flex;
	flex-direction: column;
`;

 interface IProps {
	releaseTypeDocument?: Ident.ReleaseType;
 }

interface IState {
  approvalDataIdent?: Array<Ident.Release>,
  selectedPersonId?: number,
  selectedQueue?: { type: Ident.ReleaseType;  release_request_id: number }
  releaseTypeDocument: Ident.ReleaseType;
  searchValue?: string;
}


class IDApprovalQueue extends React.Component<IProps, IState> {

	private tableRef: Table<Ident.Release> | null = null;
	converterIdent(
		line: Ident.Release ,
		index: number,
		fields: Array<string>
	): RowType {
		const row: RowType = { cells: [], ref: line };
		if (row.cells == null) {
			return row;
		}

		const keys: Array<string> = Object.keys(line);
		const releaseId: number = line.release_requests_id == null ? -1 : line.release_requests_id;
        const methods = { startQueuecallback: () => { 
                        this.setState({
                            selectedQueue: {
                                release_request_id: releaseId,
                                type: line.type
                            }
                        });
                    }
                };

		for (let i = 0; i < fields.length; i++) {
			const field: string = fields[i];
			switch (field) {
                case 'status': {
                    const status = translate('backoffice.approvals.status.' + line.status, line.status);
					row.cells.push({
						value: status,
						display: (
							<Container onClick={() => { this.setState({
								
							})}}>
								<ContainerText title={status}>{status}</ContainerText>
							</Container>
						),
						copyVal: status,
                        methods: methods
					});
					break;
				}
				case 'requested_by_person_given_name': {
					const value = line.requested_by_person_given_name + " " + line.requested_by_person_name;
					row.cells.push({
						value: value,
						display: (
							<Container onClick={() => { this.setState({
								
							})}}>
								<ContainerText title={value}>{value}</ContainerText>
							</Container>
						),
						copyVal: value,
                        methods: methods
					});
					break;
                }
                case 'datetime_create':
				case 'last_modified': {
					let val: string | undefined = undefined;
					const valueDate: string | undefined = format.datetime(line[field]);
					if(field != null && line != null && line[field]  != null) {
						const date = new Date(line[field] ?? '');
						val = `${date?.getFullYear()}${date?.getMonth()}${date?.getDay()}${date?.getHours()}${date?.getMinutes()}`;
					}
					row.cells.push({
						value: val != null ? val : '',
						display: (
							<Container onClick={() => { this.setState({
								
							})}}>
								<ContainerText title={valueDate}>
									{valueDate == null ? '-' : valueDate}
								</ContainerText>
							</Container>
						),
						copyVal: valueDate != null ? valueDate.toString() : '-',
                        methods: methods
					});
					break;
				}
				case 'source': {
					row.cells.push({
						value: '',
						display: (
							<Container onClick={() => { 
								this.setState({
									selectedQueue: {
										release_request_id: releaseId,
										type: line.type
									}
							})}}>
								<ContainerText title={'start'}>
									<IconWrapperInTable>
										{Icons.pagingArrowRight()}
									</IconWrapperInTable>
								</ContainerText>
							</Container>
						),
						copyVal: '',
                        methods: methods
					});
					break;
				}
				default: {
					if (Object.prototype.hasOwnProperty.call(line, field)) {
						row.cells.push({
							value: Object.values(line)[keys.indexOf(field)],
							display: (
								<Container onClick={() => { this.setState({
								})}}>
									<ContainerText
										title={Object.values(line)[keys.indexOf(field)]}>
										{Object.values(line)[keys.indexOf(field)]}
									</ContainerText>
								</Container>
							),
							copyVal: Object.values(line)[keys.indexOf(field)],
							methods: methods
						});
					} else {
						row.cells.push({ value: '' });
					}
					break;
				}
			}
		}

		return row;
	};

	private resetIndex = false;

	constructor(props: IProps) {
		super(props);
		this.state = {
			releaseTypeDocument:  	props.releaseTypeDocument == null ? Ident.ReleaseType.RegulaApproveDocument : props.releaseTypeDocument
		};
		this.converterIdent = this.converterIdent.bind(this);
		this.setTableRef = this.setTableRef.bind(this);
	}

	componentDidMount() {	
        	this.fetchIdentReleases();
    }
    
    
	fetchIdentReleases() {
		const req: Ident.ReleasesGetRequest = {
			release_status: [Ident.ReleaseStatus.W, Ident.ReleaseStatus.N],
			release_type: this.state.releaseTypeDocument
		}
		api.asyncRequest<any>(
			req,
			apis.ReleaseApiIdent,
			'releasesGet'
		).then((response) => {
			if (response != null) {
				const res = [];
				for(const i in response) {
					res.push({
						source: 'i',
						...response[i]
					})
				}
				this.setState({
					approvalDataIdent: res,
				}, () => {
					this.resetIndex = false;
					if(this.tableRef != null) {
						this.tableRef.resetPaging(0);
					}

				})
			}
		})
		.catch((error: ApiError) => {
			Log.error(Logs.API, error);
			MessageHandler.onError(Reporter['backoffice.relaeses.fetch'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
		});
	}
	
	compareData(a: Ident.Release , b: Ident.Release | Account.Release): number {
		return -compareDate(a.datetime_create, b.datetime_create) 
	}

	private setTableRef(element: Table<Ident.Release>): void {
		this.tableRef = element;
	}

	render() {
		const identData: Array<Ident.Release>  = this.state.approvalDataIdent != null ? this.state.approvalDataIdent : [];
		const srcData = identData.sort(this.compareData);
        if(this.state.selectedQueue != null && this.state.selectedQueue.type === Ident.ReleaseType.RegulaApproveDocument) {
            return (
                <PassportVerification 	release_request_id={this.state.selectedQueue.release_request_id} 
										callback={() => 
											{
												this.setState({ selectedQueue: undefined}, 
												()	=> {
													this.fetchIdentReleases()
												})} }/>
            )
        }
		return (
			<Main>
				<SplitView>	
					<div style={{display: 'flex'}}>
							<IconWrapper
								style={{alignSelf: 'flex-start'}} 
								onClick={() => this.fetchIdentReleases()}>
									{Icons.refresh()}
							</IconWrapper>
					<PagingWrapper>
						<StyledInput 
							id="search"
							placeHolder={translate('transactions.search.placeholder')}
							iconRight={Icons.search()}
							containerStyle={{
								height: '32px',
								marginTop: '8px',
								width: '100%',
								boxShadow: 'none',
								marginRight: '32px',
							}}
							input={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								border: '1px solid #E8ECEF',
								boxShadow: 'none',
								minWidth: '100px',
								maxWidth: '280px',
								height: '32px',
								paddingRight: '5px',
							}}
							inline={true}
							valueFromState={true}
							value={this.state.searchValue}
							notification={ (message: any) => {
								this.setState({
									searchValue: message?.search?.value
								})
							}
							}
						/>
						<PagingComponent
							numberOfEntries={srcData.length }
							rowsPerPage={5}
							resetIndex={this.resetIndex}
						/>
					</PagingWrapper>
					</div>
					<div style={{maxHeight: 'calc(100vh - 400px', overflowY: 'auto'}}>
						{srcData == null ?  null : 
							<Table<Ident.Release>
									header={[
										'ID',
										translate('backoffice.approvals.header.created'),
										'Person ID',
										translate('backoffice.approvals.header.name'),
										translate('backoffice.approvals.header.type'),
										translate('contextMenu.startQueue')
									]}
									sourceData={
										srcData
									}
									fields={[
										'release_requests_id',
										'datetime_create',
										'requested_by_person_id',
										'requested_by_person_given_name',
										'type',
										'source'
									]}
									stickyHeader={true}
									dataConverter={this.converterIdent}									
									dynamicPaging={true}
									externalPaging={true}   
									searchValue={this.state.searchValue}
									ref={this.setTableRef}                         
								/>
						}
						</div>
					</SplitView>
			</Main>
		);
	}
}

export default IDApprovalQueue;
