// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    EyesonRecording,
    EyesonRecordingValidation,
    EyesonRoomCheck,
    EyesonWebhook,
    Link,
    NewEyesonRecordingFromFE,
} from '../models';

export interface EyesonGuestPostRequest {
    ta_id: number;
    transaction_id: number;
    eyeson_create_room_id: number;
}

export interface EyesonGuestRoomGetRequest {
    ta_id: number;
    transaction_id: number;
}

export interface EyesonRecordingGetRequest {
    eyeson_add_recording_id: number;
}

export interface EyesonRecordingPutRequest {
    eyeson_add_recording_id: number;
    EyesonRecordingValidation?: EyesonRecordingValidation;
}

export interface EyesonRecordingsDeleteRequest {
    ta_id: number;
    transaction_id: number;
}

export interface EyesonRecordingsListRequest {
    ta_id: number;
    transaction_id: number;
}

export interface EyesonRecordingsPutRequest {
    ta_id: number;
    transaction_id: number;
}

export interface EyesonRoomsGetRequest {
    release_requests_id: number;
}

export interface EyesonRoomsMerchantPostRequest {
    merchant_account_number: string;
    ta_id: number;
    transaction_id: number;
}

export interface EyesonRoomsPostRequest {
    ta_id: number;
    transaction_id: number;
    test_id?: string;
}

export interface EyesonRoomsPutRequest {
    ta_id: number;
    transaction_id: number;
    NewEyesonRecordingFromFE?: NewEyesonRecordingFromFE;
}

export interface EyesonWebhookPostRequest {
    token: string;
    x_eyeson_signature: string;
    EyesonWebhook?: EyesonWebhook;
}

/**
 * no description
 */
export class EyesonApi extends BaseAPI implements IOperationIdMap {

    /**
     * save guest for video conference
     */
    eyesonGuestPost = (requestParameters: EyesonGuestPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'eyesonGuestPost');
        throwIfRequired(requestParameters, 'transaction_id', 'eyesonGuestPost');
        throwIfRequired(requestParameters, 'eyeson_create_room_id', 'eyesonGuestPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
            ...(requestParameters.eyeson_create_room_id && { 'eyeson_create_room_id': requestParameters.eyeson_create_room_id }),
        };

        return this.request<void>({
            path: '/eyeson/guest',
            method: 'POST',
            headers,
            query,
        });
    };

    /**
     * return details on room as saved in backend after creation of release
     */
    eyesonGuestRoomGet = (requestParameters: EyesonGuestRoomGetRequest): Observable<Array<EyesonRoomCheck>> => {
        throwIfRequired(requestParameters, 'ta_id', 'eyesonGuestRoomGet');
        throwIfRequired(requestParameters, 'transaction_id', 'eyesonGuestRoomGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<Array<EyesonRoomCheck>>({
            path: '/eyeson/guest',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get a recording
     */
    eyesonRecordingGet = (requestParameters: EyesonRecordingGetRequest): Observable<Link> => {
        throwIfRequired(requestParameters, 'eyeson_add_recording_id', 'eyesonRecordingGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Link>({
            path: '/eyeson/recordings/{eyeson_add_recording_id}'.replace('{eyeson_add_recording_id}', encodeURI(requestParameters.eyeson_add_recording_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * approve/reject a recording and save test result
     */
    eyesonRecordingPut = (requestParameters: EyesonRecordingPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'eyeson_add_recording_id', 'eyesonRecordingPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/eyeson/recordings/{eyeson_add_recording_id}'.replace('{eyeson_add_recording_id}', encodeURI(requestParameters.eyeson_add_recording_id)),
            method: 'PUT',
            headers,
            body: requestParameters.EyesonRecordingValidation,
        });
    };

    /**
     * invalidate recordings of active room and deactivate room
     */
    eyesonRecordingsDelete = (requestParameters: EyesonRecordingsDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'eyesonRecordingsDelete');
        throwIfRequired(requestParameters, 'transaction_id', 'eyesonRecordingsDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<void>({
            path: '/eyeson/recordings',
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * return list of all recordings
     */
    eyesonRecordingsList = (requestParameters: EyesonRecordingsListRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'eyesonRecordingsList');
        throwIfRequired(requestParameters, 'transaction_id', 'eyesonRecordingsList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<void>({
            path: '/eyeson/recordings',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * submit recordings of active room to service center
     */
    eyesonRecordingsPut = (requestParameters: EyesonRecordingsPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'eyesonRecordingsPut');
        throwIfRequired(requestParameters, 'transaction_id', 'eyesonRecordingsPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<void>({
            path: '/eyeson/recordings',
            method: 'PUT',
            headers,
            query,
        });
    };

    /**
     * return details on room as saved in backend after creation of release
     */
    eyesonRoomsGet = (requestParameters: EyesonRoomsGetRequest): Observable<Array<EyesonRecording>> => {
        throwIfRequired(requestParameters, 'release_requests_id', 'eyesonRoomsGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.release_requests_id && { 'release_requests_id': requestParameters.release_requests_id }),
        };

        return this.request<Array<EyesonRecording>>({
            path: '/eyeson/rooms',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * open an eyeson room for online tests
     */
    eyesonRoomsMerchantPost = (requestParameters: EyesonRoomsMerchantPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'merchant_account_number', 'eyesonRoomsMerchantPost');
        throwIfRequired(requestParameters, 'ta_id', 'eyesonRoomsMerchantPost');
        throwIfRequired(requestParameters, 'transaction_id', 'eyesonRoomsMerchantPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.merchant_account_number && { 'merchant_account_number': requestParameters.merchant_account_number }),
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<void>({
            path: '/eyeson/rooms_merchant',
            method: 'POST',
            headers,
            query,
        });
    };

    /**
     * open an eyeson room for online tests
     */
    eyesonRoomsPost = (requestParameters: EyesonRoomsPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'eyesonRoomsPost');
        throwIfRequired(requestParameters, 'transaction_id', 'eyesonRoomsPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
            ...(requestParameters.test_id && { 'test_id': requestParameters.test_id }),
        };

        return this.request<void>({
            path: '/eyeson/rooms',
            method: 'POST',
            headers,
            query,
        });
    };

    /**
     * add recording to an open room
     */
    eyesonRoomsPut = (requestParameters: EyesonRoomsPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'eyesonRoomsPut');
        throwIfRequired(requestParameters, 'transaction_id', 'eyesonRoomsPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<void>({
            path: '/eyeson/rooms',
            method: 'PUT',
            headers,
            query,
            body: requestParameters.NewEyesonRecordingFromFE,
        });
    };

    /**
     * webhook for actions happened at eyeson
     */
    eyesonWebhookPost = (requestParameters: EyesonWebhookPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'token', 'eyesonWebhookPost');
        throwIfRequired(requestParameters, 'x_eyeson_signature', 'eyesonWebhookPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.x_eyeson_signature && { 'x-eyeson-signature': String(requestParameters.x_eyeson_signature) }),
        };

        return this.request<void>({
            path: '/eyeson/webhook/{token}'.replace('{token}', encodeURI(requestParameters.token)),
            method: 'POST',
            headers,
            body: requestParameters.EyesonWebhook,
        });
    };


    operationToOperationId = {
	    "eyesonGuestPost": "eyeson_guest_post" ,
	    "eyesonGuestRoomGet": "eyeson_guest_room_get" ,
	    "eyesonRecordingGet": "eyeson_recording_get" ,
	    "eyesonRecordingPut": "eyeson_recording_put" ,
	    "eyesonRecordingsDelete": "eyeson_recordings_delete" ,
	    "eyesonRecordingsList": "eyeson_recordings_list" ,
	    "eyesonRecordingsPut": "eyeson_recordings_put" ,
	    "eyesonRoomsGet": "eyeson_rooms_get" ,
	    "eyesonRoomsMerchantPost": "eyeson_rooms_merchant_post" ,
	    "eyesonRoomsPost": "eyeson_rooms_post" ,
	    "eyesonRoomsPut": "eyeson_rooms_put" ,
	    "eyesonWebhookPost": "eyeson_webhook_post"
    }
}
