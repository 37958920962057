// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ContentDocumentType,
} from './';

/**
 * @export
 * @interface UnreadDocuments
 */
export interface UnreadDocuments {
    /**
     * @type {string}
     * @memberof UnreadDocuments
     */
    file_name?: string;
    /**
     * @type {ContentDocumentType}
     * @memberof UnreadDocuments
     */
    identifier?: ContentDocumentType;
    /**
     * @type {string}
     * @memberof UnreadDocuments
     */
    file_type?: string;
    /**
     * @type {string}
     * @memberof UnreadDocuments
     */
    language?: UnreadDocumentsLanguageEnum;
    /**
     * @type {string}
     * @memberof UnreadDocuments
     */
    version?: string;
    /**
     * @type {string}
     * @memberof UnreadDocuments
     */
    document_link?: string;
    /**
     * @type {Array<string>}
     * @memberof UnreadDocuments
     */
    actions?: Array<UnreadDocumentsActionsEnum>;
    /**
     * @type {Date}
     * @memberof UnreadDocuments
     */
    datetime_read?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum UnreadDocumentsLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum UnreadDocumentsActionsEnum {
    R = 'R',
    A = 'A',
    J = 'J'
}

