//@ts-nocheck
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import {
	StyledLine,
	StyledDivHeader,
	StyledSpanHeaderText,
	StyledSelect,
} from '../basicStyledComponents/customersComponent.css';
import {
	IStatusState,
	Reporter,
	IMessageConfig,
	getFormComponents,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { Ident, api, apis, ApiError } from '../../../../logic/api';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Icons } from '../../../../images';
import { translate } from '../../../../common/language/translate';
import { theme } from '../../../../common/theme';
import {
	IInitProps,
	IInitState,
    InitialDataComponent,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import { InitComponentHandler } from '../../../../logic/handler/initialdatahandler/InitComponentHandler';
import { BigInput } from '../../../../components/atomiccompoents/form/inputs.css';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import { FlexBox } from '../../../auth/auth.css';
import { CheckBox, IOption } from '../../../../components/atomiccompoents/form';
import { Sex } from '../../../../logic/api/ident/models/Sex';
import { UserClass } from '../../../../logic/api/ident/models/UserClass';
import { NewAddress } from '../../../../logic/api/ident/models/NewAddress';
import { CountryCodeAlpha2 } from '../../../../logic/api/ident/models/CountryCodeAlpha2';
import { Log, Logs } from '../../../../logic/log';
import { CustomDatePicker } from '../../../../components/datepicker/tfdatepicker';
import moment from 'moment';
import { ColumnBox, Helpertext } from './basicStyledComponents/customerDetails.css';
import { ClientConfig } from '../../../../common/config';
import { evaluateErrorMessage, getCountryOptions, getTitleOptions } from '../../../../logic/helper/Common';

const StyledMain = styled.div`
    padding: 16px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border: ${(props) => props.theme.Box.border};
	box-sizing: ${(props) => props.theme.Box.boxSizing};
	box-shadow: 4px 0px 4px -4px rgba(0,0,0,0.25);
`;

const InputBox = styled.div`
    display: flex;
    align-items: center;
    height: 99px;
    width: 100%;
    max-width: 378px;
    min-width: 27%;
    margin-left: 32px;
    margin-rigth: 32px;
    select {
        min-width: 100px;
        max-width: 378px;
    }
    div {
        width: 378px;
    }
    @media (max-width: 1660px) {
        max-width: 500px;
        select {
            min-width: 100px;
            max-width: 500px;
        }
        div {
            width: 500px;
        }
    }
    @media (max-width: 1515px) {
        max-width: 800px;
        select {
            min-width: 800px;
			max-width: 800px;
			margin: auto;
        }
        div {
			width: 800px;
		}
		#helperwrapper {
			margin: auto !important;
			margin-top: 12px !important;
		}
    }
`;

const CheckboxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
	width: 100%;
	margin-left: 32px;
    margin-rigth: 32px;
`;

const ColumnBoxAdd = styled(ColumnBox)`
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-right: 32px;
    height: calc(100vh - 200px);
    min-height: initial;
    padding-left: 16px;
    @media (max-width: 1515px) { 
        justify-content: center;
    }
`;
const StyledViewButtons = styled(FlexBox)`
    margin-top: 8px;
    margin-left: 32px;
    height: 150px;
    width: 100%;
    display: flex;
	flex-direction: row;
	justify-content: flex-start;
`;

interface IProps extends IInitProps {
	createCallback?: (personId: number ) => void;
}

interface IState extends IStatusState, IInitState {
	lastName?: string;
	firstName?: string;
	email?: string;
	birth_city?: string;
	birth_date?: Date;
	country?: CountryCodeAlpha2;
	zipcode?: string;
	city?: string;
	street?: string;
	number?: string;
	product?: string;
	keyForLoadingSpinner?: number;
	sex?: Sex;
	productsForCust?: Array<string>;
	language?: Ident.Language;
	isJunior?: boolean;
}

class CustomerAddComponent extends InitialDataComponent <IProps, IState> {
	private ButtonStyle = {
        width: theme.Button.width,
        padding: '16px',
	};
	constructor(props: IProps) {
		super(props);
		this.state = {
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			sex: Sex.M,
			product: 'ndident',
			productsForCust: [],
			country: CountryCodeAlpha2.DE,
			language: Ident.Language.De,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.cancel = this.cancel.bind(this);
		this.handleProductSelect = this.handleProductSelect.bind(this);
		this.handleSexSelect = this.handleSexSelect.bind(this);
		this.handleCountrySelect = this.handleCountrySelect.bind(this);
		this.handleLanguageSelect = this.handleLanguageSelect.bind(this);
		InitComponentHandler.register(this, this.constructor.name);
		this.getCustomerProducts = this.getCustomerProducts.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.getCustomerProducts();
	}

	reviveState(newState: { [key: string]: any }): void {
		this.setState({
			...newState,
		});
	}

	componentDidMount() {
		this.fill(this.constructor.name);
	}

	componentWillUnmount() {
		InitComponentHandler.cleanUp(this.constructor.name);
	}

	getCustomerProducts() {
		const params: Ident.LookupProductUserClassGetRequest = {
			user_class: Ident.UserClass.Customer,
		};
		api.asyncRequest<Array<Ident.ProductUserclass>>(
			params,
			apis.MaintenanceApi,
			'lookupProductUserClassGet'
		)
			.then((response) => {
				if (response.length === 0) {
					const e: ApiError = {
						status: 400,
						statusText: 'No products received',
						response: response,
					};
					Log.error(Logs.API, e.statusText);
					this.setState({ productsForCust: [] });
					return;
				}
				const products = this.state.productsForCust;
				if (products != null) {
					for (const product in response) {
						products.push(response[product].product);
					}
				}
				this.setState({ productsForCust: products });
			})
			.catch(() => {
				this.setState({ productsForCust: [] });
			});
	}

	handleSubmit(event: SyntheticEvent) {
		event.preventDefault();
		const lastName = this.state.lastName;
		const firstName = this.state.firstName;
		const product = this.state.product;
		const address = this.state.street;
		const city = this.state.city;
		const zip = this.state.zipcode;
		const state = this.state.country;
		const buildingID = this.state.number;
		const language = this.state.language;
		const sex = this.state.sex;
		// CUSTOMER DETAILS BOX MIT ADRESS IS PRIMARY TODO
		/**if 'yourInputValidationHere'*/

		if (
			this.state.email != null &&
			this.state.email.trim() !== '' &&
			lastName != null &&
			lastName.trim() !== '' &&
			firstName != null &&
			firstName.trim() !== '' &&
			city != null &&
			city.trim() !== '' &&
			address != null &&
			address.trim() !== '' &&
			state != null &&
			state.trim() !== '' 
		) {
			const email = this.state.email.trim();
			const adressVal: NewAddress = {
				city: city,
				street: address,
				building_identifier: buildingID,
				post_code: zip,
				country: state,
			};
			const params = {
				NewEmoneyPersonWithUsertype: {
					name: lastName != null ? lastName : '',
					given_name: firstName != null ? firstName : '',
					email_address: email,
					user_class: UserClass.Customer,
					address: adressVal,
					sex: sex,
					product: product != null ? product.toString() : '',
					language: language,
					birth_date:
						this.state.birth_date != null
							? moment(this.state.birth_date).format('YYYY-MM-DD')
							: undefined,
					birth_city: this.state.birth_city,
					family_relation: this.state.isJunior === true ? 'junior' : undefined
				},
			};

			api.asyncRequest<Ident.PersonId>(
				params,
				apis.RegistrationApi,
				'personCreateThirdPersonPost'
			)
				.then((response) => {
					this.setState({
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
					if (response != null && response.person_id != null) {
						const config: IMessageConfig = MessageHandler.onSuccess(
							Reporter['person.create.thirdperson.post.request.success']
						);
						if (this.props.createCallback != null) {
							this.props.createCallback(response.person_id);
						}
						this.setState({
							showInlineSuccess:
								config.successMethods != null
									? config.successMethods.inline
									: false,
							errorMessage:
								config.errorMessage != null
									? config.errorMessage
									: 'Sucessfully executed task',
							key:
								config.translationKey != null
									? config.translationKey + '.success'
									: '',
							keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
						});
					}
				})
				.catch((error: ApiError) => {
					const config: IMessageConfig = MessageHandler.onError(
						Reporter['person.create.thirdperson.post.request.error'],
						evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
					);
					this.setState({
						showInlineError: config.errorMethods.inline,
						errorMessage:
							config.errorMessage != null
								? config.errorMessage
								: error.statusText,
						key:
							config.translationKey != null
								? config.translationKey + '.error'
								: '',
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
				});
		} else {
			const config: IMessageConfig = MessageHandler.onError(
				Reporter['person.create.thirdperson.invaliddata']
			);
			this.setState({
				showInlineError: config.errorMethods.inline,
				errorMessage:
					config.errorMessage != null
						? config.errorMessage
						: 'Mandatory fields are empty',
				key: config.translationKey != null ? config.translationKey + '.error' : '',
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			});
		}
	}

	getProductOptions(): Array<IOption> {
		const products = this.state.productsForCust;
		const webTitle = ClientConfig.webTitle; //TODO: Only temporary solution until backend delivers proper names
		if (products == null) {
			return [];
		}
		const options: Array<IOption> = [];
		for (let i = 0; i < products.length; i++) {
			options.push({
				key: 'defaultOptionKey' + products[i],
				name:
					products[i] === 'web'
						? webTitle
						: products[i] === 'ndpaynet'
						? 'ndPAYNET'
						: products[i] === 'ndident'
						? 'ndIDENT'
						: products[i],
				value: products[i],
			});
		}
		return options;
	}

	getndPaynetOptions(): Array<IOption> {
		const options: Array<IOption> = [
			{
				key: 'defaultOptionKey 0-99',
				name: '0 - 99',
				value: 0,
			},
			{
				key: 'defaultOptionKey 100-999',
				name: '100 - 999',
				value: 1,
			},
			{
				key: 'defaultOptionKey 000-9999',
				name: '1000 - 99999',
				value: 2,
			},
		];
		return options;
	}

	getLanguageOptions(): Array<IOption> {
		const options: Array<IOption> = [
			{
				key: 'de',
				name: translate('usermenu.language.german'),
				value: Ident.Language.De,
			},
			{
				key: 'en',
				name: translate('usermenu.language.english'),
				value: Ident.Language.En,
			},
			{
				key: 'sq',
				name: translate('usermenu.language.albanian'),
				value: Ident.Language.Sq,
			},
		];
		return options;
	}

	handleProductSelect(message: any) {
		this.setState({
			product: message.product,
		});
	}

	unsetAutofillForChrome(event: any) {
        if(event.target.autocomplete) {
            event.target.autocomplete = "whatever";
        }
    }

	handleSexSelect(message: any) {
		this.setState({
			sex: message.title,
		});
	}
	handleCountrySelect(message: any) {
		this.setState({
			country: message.country,
		});
	}
	handleLanguageSelect(message: any) {
		this.setState({
			language: message.language,
		});
	}

	cancel(event?: SyntheticEvent) {
		if (event != null) {
			event.preventDefault();
		}
	}

	onInputChange(n: any) {
		if (Object.keys(n).length === 1) {
			const key = Object.keys(n)[0] as keyof IState;
			const value = n[key].value;

			const newState: IState = {
				[key]: value,
			};
			this.setState(newState);
		}
	}

	onDateChange(date: Date | null) {
		this.setState({
			birth_date: date == null ? undefined : date,
		});
	}
	onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
		// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			this.handleSubmit(event);
		}
	};

	render() {
		return (
			<StyledMain
				onClick={(event: { stopPropagation: () => any }) => event.stopPropagation()}>
				<FlexBox>
					<StyledDivHeader>
						{Icons.createCustomer()}
						<StyledSpanHeaderText>
							{translate('customers.newCustomer.title')}
						</StyledSpanHeaderText>
					</StyledDivHeader>

					<StyledLine />
						<ColumnBoxAdd>
                            <InputBox>
                                <StyledSelect
                                    helptext={translate('customers.title.title')}
                                    id="title"
                                    options={getTitleOptions()}
									notification={this.handleSexSelect}
									wrapperStyle={{margin: 'initial'}}
                                />
                            </InputBox>
                            <InputBox>
                                <BigInput
                                    label={translate(
                                        'customers.newCustomer.input.firstname.label'
                                    )}
                                    placeHolder={'*' + translate(
                                        'customers.newCustomer.input.firstname.placeholder'
                                    )}
                                    type="firstname"
                                    id="firstName"
                                    value={this.state.firstName}
                                    valueFromState={true}
                                    notification={(n) => this.onInputChange(n)}
                                    onSubmit={this.handleSubmit}
                                />
                            </InputBox>
                            <InputBox>
                                <BigInput
                                    label={translate('customers.newCustomer.input.lastname.label')}
                                    placeHolder={'*' + translate(
                                        'customers.newCustomer.input.lastname.placeholder'
                                    )}
                                    type="lastname"
                                    id="lastName"
                                    value={this.state.lastName}
                                    valueFromState={true}
                                    notification={(n) => this.onInputChange(n)}
                                    onSubmit={this.handleSubmit}
                                />
                            </InputBox>
                            <InputBox>
                                <BigInput
                                    label={translate('customers.newCustomer.input.email.label')}
                                    placeHolder={'*' + translate(
                                        'customers.newCustomer.input.email.placeholder'
                                    )}
                                    type="email"
                                    id="email"
                                    value={this.state.email}
                                    valueFromState={true}
                                    notification={(n) => this.onInputChange(n)}
                                    onSubmit={this.handleSubmit}
                                />
                            </InputBox>
                            <InputBox>
                                <CustomDatePicker
                                    title={translate(
                                        'customers.search.input.birthdate.placeholder'
                                    )}
                                    selectedValue={this.state.birth_date}
                                    onChange={this.onDateChange}
                                    iconCallback={Icons.calendarExpand}
                                    displayDate={this.state.birth_date != null}
                                    resetCallback={() => this.onDateChange(null)}
                                    isInput={true}
                                    zIndex={5000}
                                    helperText={translate(
                                        'customers.search.input.birthdate.label'
                                    )}
							/>
                            </InputBox>
                            <InputBox>
                                <BigInput
                                    label={translate(
                                        'customers.newCustomer.input.birthplace.label'
                                    )}
                                    placeHolder={translate(
                                        'customers.newCustomer.input.birthplace.placeholder'
                                    )}
                                    type="text"
                                    id="birth_city"
                                    value={this.state.birth_city}
                                    valueFromState={true}
                                    notification={(n) => this.onInputChange(n)}
									onSubmit={this.handleSubmit}
									onEnter={this.unsetAutofillForChrome}
                                />
                            </InputBox>
                            <InputBox>
                                <BigInput
                                    label={translate(
                                        'customers.newCustomer.input.address.label'
                                    )}
                                    placeHolder={'*' + translate(
                                        'customers.newCustomer.input.address.placeholder'
                                    )}
                                    type="street-address"
                                    id="street"
                                    onSubmit={this.handleSubmit}
                                    valueFromState={true}
                                    value={this.state.street}
                                    notification={(n) => this.onInputChange(n)}
                                    />
                            </InputBox>
                            <InputBox>
                                <BigInput
                                    placeHolder={translate(
                                        'customers.newCustomer.input.number.placeholder'
                                    )}
                                    label={translate(
                                        'customers.newCustomer.input.number.label'
                                    )}
                                    type="street-address"
                                    id="number"
                                    onSubmit={this.handleSubmit}
                                    valueFromState={true}
                                    value={this.state.number}
                                    notification={(n) => this.onInputChange(n)}
                                />

                            </InputBox>
                            <InputBox>
                                <BigInput
                                    placeHolder={'*' + translate(
                                        'customers.newCustomer.input.city.placeholder'
                                    )}
                                    label={translate('customers.newCustomer.input.city.label')}
                                    type="street-address"
                                    id="city"
                                    onSubmit={this.handleSubmit}
                                    valueFromState={true}
                                    value={this.state.city}
                                    notification={(n) => this.onInputChange(n)}
                                />
                           </InputBox>
                           <InputBox>
                                <BigInput
                                    placeHolder={translate(
                                        'customers.newCustomer.input.postCode.placeholder'
                                    )}
                                    label={translate(
                                        'customers.newCustomer.input.postCode.label'
                                    )}
                                    type="postal-code"
                                    id="zipcode"
                                    onSubmit={this.handleSubmit}
                                    valueFromState={true}
                                    value={this.state.zipcode}
                                    notification={(n) => this.onInputChange(n)}
                                />
                 
                            </InputBox>
                            <InputBox>
                                <StyledSelect
                                    helptext={'*' + translate(
                                        'customers.newCustomer.input.country.label'
                                    )}
                                    id="country"
                                    options={getCountryOptions()}
                                    notification={this.handleCountrySelect}
									current={this.state.country}
									wrapperStyle={{margin: 'initial'}}
                                />
                            </InputBox>
                            <InputBox>
                                <StyledSelect
                                    helptext={translate(
                                        'customers.newCustomer.input.language.label'
                                    )}
                                    id="language"
                                    options={this.getLanguageOptions()}
                                    notification={this.handleLanguageSelect}
									current={this.state.language}
									wrapperStyle={{margin: 'initial'}}
                                />
                            </InputBox>
                            <InputBox>
                                <StyledSelect
                                    helptext={translate('customers.product')}
                                    id="product"
                                    options={this.getProductOptions()}
                                    notification={this.handleProductSelect}
									current={this.state.product}
									wrapperStyle={{margin: 'initial'}}
                                />
                            </InputBox>
							{ ClientConfig.jnrAccount === true ?
								<CheckboxWrapper>                                
									<CheckBox 
										value={this.state.isJunior}
										onChange={(value:boolean) => {
											this.setState({
												isJunior: value
											})
										}}
										id="junior"
									/>
									<Helpertext style={{marginTop: '8px'}}>
										Junior Account
									</Helpertext>
								</CheckboxWrapper>
							: null }
							{getFormComponents(
								this.state.showInlineError,
								this.state.showInlineSuccess,
								this.state.errorMessage,
								this.state.successMessage,
								this.state.key
							)}
                    <StyledViewButtons>
                        <ButtonOk
                            key={this.state.keyForLoadingSpinner}
                            id="btnAddCustomerOk"
                            onClick={this.handleSubmit}
                            style={this.ButtonStyle}>
                            {translate('button.create')}
                        </ButtonOk>
                    </StyledViewButtons>
						</ColumnBoxAdd>
                    
				</FlexBox>
			</StyledMain>
		);
	}
}

export default CustomerAddComponent;
