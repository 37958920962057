import { permissionConfig } from './permissionConfig';
import { IPage } from '../../components/nav';
import { CustomerDataStore, BackofficeStore } from '../../logic/flux';
import { ClientConfig } from '../../common/config';

export const pageConfig: Array<IPage> = [
    {
        display: false,
        active: false,
        icon: 'navDashboard',
        text: 'nav.dashboard',
        showText: true,
        showIcon: true,
        url: '/dashboard',
        category: 'default',
        permissions: permissionConfig.nav.dashboard,
    },
    {
        display: true,
        active: true,
        showSubmenue: false,
        icon: 'navCustomers',
        text: 'nav.customers',
        showText: true,
        showIcon: true,
        url: '/customers',
        category: 'default',
        permissions: permissionConfig.nav.customers,
        //addButton: false,
        //addComponent: <Overlay key={"overlayComponentForCustomer"} popUpComponent={<CustomerOverlay key={"customerOverlay"} />} />,
        subPages: [
            {
                display: false,
                active: false,
                activeCondition: () => CustomerDataStore.getUser() != null,
                icon: '',
                text: 'nav.transactions',
                showText: true,
                showIcon: true,
                url: '/customers/details',
                category: 'default',
                permissions: permissionConfig.nav.customers.transactions,
            },
            {
                display: false,
                active: false,
                activeCondition: () => CustomerDataStore.getUser() != null,
                icon: 'navTransactions',
                text: 'nav.transactions',
                showText: true,
                showIcon: true,
                url: '/customers/transactions',
                category: 'default',
                permissions: permissionConfig.nav.customers.transactions,
            },
            {
                display: false,
                active: false,
                activeCondition: () => CustomerDataStore.getUser() != null,
                icon: 'navPostingTemplates',
                text: 'nav.postingTemplates',
                showText: true,
                showIcon: true,
                url: '/customers/postingTemplates',
                category: 'default',
                permissions: permissionConfig.nav.customers.postingTemplates,
            },
            {
                display: false,
                active: false,
                activeCondition: () => CustomerDataStore.getUser() != null,
                icon: 'navReports',
                text: 'nav.reports',
                showText: true,
                showIcon: true,
                url: '/customers/reports',
                category: 'default',
                permissions: permissionConfig.nav.customers.reports,
            },
        ],
    },
    {
        display: true,
        active: false,
        icon: 'navBackoffice',
        text: 'nav.backoffice',
        showText: true,
        showIcon: true,
        showSubmenue: false,
        url: '/backoffice',
        category: 'default',
        permissions: permissionConfig.nav.backoffice,
        subPages: [
            {
                display: false,
                active: false,
                activeCondition: () => BackofficeStore.getAccount() != null,
                icon: 'navTransactions',
                text: 'nav.transactions',
                showText: true,
                showIcon: true,
                url: '/backoffice/transactions',
                category: 'default',
                permissions: permissionConfig.nav.backoffice.transactions,
            },
            {
                display: false,
                active: false,
                activeCondition: () => BackofficeStore.getAccount() != null,
                icon: 'navPostingTemplates',
                text: 'nav.postingTemplates',
                showText: true,
                showIcon: true,
                url: '/backoffice/postingTemplates',
                category: 'default',
                permissions: permissionConfig.nav.backoffice.postingTemplates,
            },
            {
                display: false,
                active: false,
                icon: 'navReports',
                text: 'nav.reports',
                showText: true,
                showIcon: true,
                url: '/backoffice/reports',
                category: 'default',
                permissions: permissionConfig.nav.backoffice.reports,
            },
        ],
    },
    {
        display: false,
        active: false,
        icon: 'navReporting',
        text: 'nav.reporting',
        showText: true,
        showIcon: true,
        url: '/reporting',
        category: 'default',
        permissions: permissionConfig.nav.reports,
    },
    {
        display: true,
        active: false,
        icon: 'navAdministration',
        text: 'nav.administration',
        showText: true,
        showIcon: true,
        showSubmenue: false,
        url: '/administration',
        category: 'default',
        permissions: permissionConfig.nav.administration,
        subPages: [
            {
                display: false,
                active: false,
                icon: 'navPermissions',
                text: 'nav.ermissions',
                showText: true,
                showIcon: true,
                url: '/administration/permissions',
                category: 'default',
                permissions: permissionConfig.nav.administration.permissions,
            },
            {
                display: false,
                active: false,
                icon: 'navFees',
                text: 'nav.fees',
                showText: true,
                showIcon: true,
                url: '/administration/fees',
                category: 'default',
                permissions: permissionConfig.nav.administration.fees,
            },
            {
                display: false,
                active: false,
                icon: 'navReports',
                text: 'nav.reports',
                showText: true,
                showIcon: true,
                url: '/administration/reports',
                category: 'default',
                permissions: permissionConfig.nav.administration.reports,
            },
            {
                display: false,
                active: false,
                icon: 'navTeams',
                text: 'nav.teams',
                showText: true,
                showIcon: true,
                url: '/administration/teams',
                category: 'default',
                permissions: permissionConfig.nav.administration.teams,
            },
        ],
    },
    {
        display: true,
        active: false,
        icon: 'navDashboard',
        text: 'Dashboard',
        showText: true,
        showIcon: true,
        showSubmenue: false,
        url: '/dashboard',
        category: 'default',
        permissions: permissionConfig.nav.customers,
        subPages: []
    },
    {
        display: ClientConfig.videoqueue,
        active: false,
        icon: 'navServicecenter',
        text: 'ServiceCenter',
        showText: true,
        showIcon: true,
        url: '/servicecenter',
        category: 'default',
        permissions: permissionConfig.nav.servicecenter,
        subPages :[]
    },
    {
        display: ClientConfig.productMaintenance,
        active: false,
        icon: 'navProductManagement',
        text: 'ProductManagement',
        showText: true,
        showIcon: true,
        url: '/productmanagement',
        category: 'default',
        permissions: permissionConfig.nav.product_management,
        subPages :[]
    },
];
