// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AdyenClientInformation,
    AdyenPaymentMethod,
} from './';

/**
 * result of paymentmethods request
 * @export
 * @interface AdyenPaymentTypes
 */
export interface AdyenPaymentTypes {
    /**
     * @type {string}
     * @memberof AdyenPaymentTypes
     */
    payment_provider?: AdyenPaymentTypesPaymentProviderEnum;
    /**
     * @type {Array<AdyenPaymentMethod>}
     * @memberof AdyenPaymentTypes
     */
    paymentMethods?: Array<AdyenPaymentMethod>;
    /**
     * @type {AdyenClientInformation}
     * @memberof AdyenPaymentTypes
     */
    clientInformation?: AdyenClientInformation;
}

/**
 * @export
 * @enum {string}
 */
export enum AdyenPaymentTypesPaymentProviderEnum {
    Adyen = 'adyen'
}

