import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { IItfAccount } from '../../types';
import { UndoStore } from './undoStore';
interface IPickerDates {
	startDate: Date,
	endDate: Date,
}

class IBackofficeStore extends EventEmitter {
	private account: IItfAccount | undefined = undefined;
	private dates: IPickerDates | null = null;
	private static instance: IBackofficeStore | null;
	constructor() {
		super();
		IDispatcher.register(this.registerActions.bind(this));
	}

	static getInstance(): IBackofficeStore {
		if (IBackofficeStore.instance == null) {
			IBackofficeStore.instance = new IBackofficeStore();
		}
		return IBackofficeStore.instance;
	}

	registerActions(action: any) {
		switch (action.actionType) {
			default:
				break;
			case Constants.CLEAR_ALL:
				this.account = undefined;
				this.emit(CHANGE_EVENT);
				break;
			case Constants.BACKOFFICE_CHANGED:
				this.setAccount(action.account);
				this.emit(CHANGE_EVENT, this.account);
				break;
			case Constants.RESET_BACKOFFICE_DATA:
				this.reset();
				this.emit(CHANGE_EVENT, this.account);
				break;
		}
		return true;
	}

	getAccount(): IItfAccount | undefined {
		return this.account;
	}

	setAccount(account?: IItfAccount): void {
		if(account != null) {
			UndoStore.setLastBackofficeAccount(account);
		}
		this.account = account;
	}

	reset(): void {
		this.account = undefined;
		this.dates = null;
	}

	addChangeListener(callback: any) {
		this.on(CHANGE_EVENT, callback);
	}

	removeChangeListener(callback: any) {
		this.removeListener(CHANGE_EVENT, callback);
	}
	getDates() {
		return this.dates;
	}
	setDates(dates: IPickerDates) {
		this.dates = dates;
	}
}

export const BackofficeStore = IBackofficeStore.getInstance();
