// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AccountProductOrderEyesonRoom
 */
export interface AccountProductOrderEyesonRoom {
    /**
     * @type {Array<string>}
     * @memberof AccountProductOrderEyesonRoom
     */
    allowd_actions?: Array<AccountProductOrderEyesonRoomAllowdActionsEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum AccountProductOrderEyesonRoomAllowdActionsEnum {
    RoomPost = 'eyeson_room_post',
    RecordingsPut = 'eyeson_recordings_put',
    RecordingsDelete = 'eyeson_recordings_delete'
}

