// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, throwIfRequired } from '../runtime';
import {
    OpenIDProviderMetadataResponse,
    TokenResponse,
    UserInfoResponse,
} from '../models';

export interface OauthTokenPostRequest {
    grant_type: OauthTokenPostGrantTypeEnum;
    client_id: string;
    client_secret: string;
    redirect_uri?: string;
    code?: string;
    refresh_token?: string;
}

/**
 * no description
 */
export class OpenIDConnectServersideApi extends BaseAPI implements IOperationIdMap {

    /**
     * Discovery endpoint used by OpenID Connect Relying Party to discover the End-User\'s OpenID Provider and obtain information needed to interact with it, including its OAuth 2.0 endpoint locations.
     * Get OpenID Provider configuration information
     */
    authWellKnownOidc = (): Observable<OpenIDProviderMetadataResponse> => {
        return this.request<OpenIDProviderMetadataResponse>({
            path: '/oauth/.well-known/openid-configuration',
            method: 'GET',
        });
    };

    /**
     * this endpint allows the alb to exchange either   * user acces code for an id token, an access token and a refresh token to call the backend interface for the user  or  * refresh token for  an id token, an access token and a refresh token to call the backend interface for the user
     * used by the alb to exchange the users submitted code or the refresh token against a new set of tokens
     */
    oauthTokenPost = (requestParameters: OauthTokenPostRequest): Observable<TokenResponse> => {
        throwIfRequired(requestParameters, 'grant_type', 'oauthTokenPost');
        throwIfRequired(requestParameters, 'client_id', 'oauthTokenPost');
        throwIfRequired(requestParameters, 'client_secret', 'oauthTokenPost');

        const formData = new FormData();
        if (requestParameters.grant_type !== undefined) {
            formData.append('grant_type', requestParameters.grant_type as any);
        }

        if (requestParameters.client_id !== undefined) {
            formData.append('client_id', requestParameters.client_id as any);
        }

        if (requestParameters.client_secret !== undefined) {
            formData.append('client_secret', requestParameters.client_secret as any);
        }

        if (requestParameters.redirect_uri !== undefined) {
            formData.append('redirect_uri', requestParameters.redirect_uri as any);
        }

        if (requestParameters.code !== undefined) {
            formData.append('code', requestParameters.code as any);
        }

        if (requestParameters.refresh_token !== undefined) {
            formData.append('refresh_token', requestParameters.refresh_token as any);
        }

        return this.request<TokenResponse>({
            path: '/oauth/token',
            method: 'POST',
            body: formData,
        });
    };

    /**
     * used by the alb to get the users data
     */
    oauthUserinfoGet = (): Observable<UserInfoResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<UserInfoResponse>({
            path: '/oauth/userinfo',
            method: 'GET',
            headers,
        });
    };

    /**
     * used by the alb to get the users data
     */
    oauthUserinfoPost = (): Observable<UserInfoResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<UserInfoResponse>({
            path: '/oauth/userinfo',
            method: 'POST',
            headers,
        });
    };


    operationToOperationId = {
	    "authWellKnownOidc": "auth_well_known_oidc" ,
	    "oauthTokenPost": "oauth_token_post" ,
	    "oauthUserinfoGet": "oauth_userinfo_get" ,
	    "oauthUserinfoPost": "oauth_userinfo_post"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum OauthTokenPostGrantTypeEnum {
    AuthorizationCode = 'authorization_code',
    RefreshToken = 'refresh_token'
}
