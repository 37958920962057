import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { UserDataStore } from '../../logic/flux/stores/userdataStore';
import { INotifyAbleUserData } from '../../logic/flux/notifyAbles/notifyAbleUserData';
import { IOverlayNotification } from './IOverlayNotification';
import { IMenuEntry } from './userMenu';
import { translate } from '../../common/language/translate';
import {
	IPropsOver,
	IStateOver,
	AOverlay,
} from '../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import { NotificationStore } from '../../logic/flux/stores/notificationStore';
import { INotifyAbleNotification } from '../../logic/flux/notifyAbles/notifyAbleNotification';
import { Account, api, VoidResponse, apis, ApiError } from '../../logic/api';
import { format } from '../../logic/helper/format';
import { Log, Logs } from '../../logic/log';

const MessageMenuBox = styled.div`
	position: fixed;
	width: 352px;
	top: 64px;
	height: calc(100vh - 112px);
	right: 0px;
	z-index: 170;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
	overflow-x: hidden;
	overflow-y: auto;

	a > svg {
		margin-bottom: -2px;
	}
`;

const Header = styled.span`
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	color: #4f5f8e;
	text-align: center;
	height: 70px;
	padding: 20px;
`;

const MessageHeader = styled.span<{ isRead?: boolean }>`
	font-family: Roboto;
	font-style: normal;
	font-weight: ${(props) => (props.isRead === true ? '500' : '0')};
	font-size: 16px;
	color: #4f5f8e;
	padding-bottom: 10px;
`;

const MessageDate = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #979797;
	text-align: end;
`;

const MessageBody = styled.span`
	font-style: normal;
	color: #979797;
	font-size: 16px;
	line-height: 24px;
`;

const StyledView = styled.div`
	margin-right: 4px;
	border-style: outset;
	background: #fbfbfb;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
	width: 100%;
	height: 103px;
	padding: 10px;
`;

const StyledViewTwoItems = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	& > div {
		@media (min-width: 1211px) {
			width: 50%;
			padding-right: 20px;
		}

		@media (max-width: 1210px) {
			width: 100%;
		}
	}
`;

interface IProps extends IPropsOver {
	toggleComponents?: ReactElement[];
	toggleOverlay?: (overlay: IOverlayNotification) => void;
	menuEntries: Array<IMenuEntry>;
}

interface IState extends IStateOver {
	currentComponent: number;
	menuEntries: Array<IMenuEntry>;
	showComponent?: boolean;
	notifications?: Array<Account.NotificationLine>;
}

export class MessageMenu extends AOverlay<IProps, IState>
	implements INotifyAbleUserData, INotifyAbleNotification {
	constructor(props: IProps) {
		super(props);

		this.state = {
			currentComponent: 0,
			menuEntries: props.menuEntries,
		};

		this.showComponent = this.showComponent.bind(this);
		this.hideComponent = this.hideComponent.bind(this);
		this._onChangeUserData = this._onChangeUserData.bind(this);
		this._onChangeNotifications = this._onChangeNotifications.bind(this);
	}

	_onChangeUserData() {
		const comp = UserDataStore.getMenuComponent();
		this.setState({ currentComponent: comp });
	}

	_onChangeNotifications(): void {
		this.setState({
			notifications: NotificationStore.getNotifications(),
		});
	}

	componentDidMount() {
		UserDataStore.addChangeListener(this._onChangeUserData);
		NotificationStore.addChangeListener(this._onChangeNotifications);
		this._onChangeNotifications();

		// Mark messages read in the database
		this.readMessagesPut();
	}

	componentWillUnmount() {
		UserDataStore.removeChangeListener(this._onChangeUserData);
		NotificationStore.removeChangeListener(this._onChangeNotifications);
	}

	private readMessagesPut(): void {
		const notificationIds: Array<Account.NotificationIdListIds> = NotificationStore.getNotifications()
			.filter((n) => n.read !== true)
			.map((n) => {
				const listId: Account.NotificationIdListIds = {
					channel: n.channel,
					id: n.id,
				};
				return listId;
			});

		if (notificationIds == null || notificationIds.length === 0) {
			return;
		}

		const params: Account.NotificationsPutRequest = {
			NotificationIdList: {
				ids: notificationIds,
			},
		};
		api.asyncRequest<VoidResponse>(params, apis.NotificationApi, 'notificationsPut')
			.then(() => {})
			.catch((error: ApiError) => Log.error(Logs.API, error.statusText));
	}

	private showComponent(): void {
		this.setState({
			showComponent: true,
		});
	}

	private hideComponent(): void {
		this.setState({ showComponent: false });
	}

	reviveState(): void {}

	defineContent(): React.ReactNode {
		const notifications = NotificationStore.getNotifications();
		return (
			<MessageMenuBox onClick={(event) => event.stopPropagation()}>
				<Header>{translate('messageMenu.header')}</Header>
				{this.state.notifications != null &&
					notifications != null &&
					notifications.map((msg, index) => {
						const messageJsx = (
							<StyledView key={'message_' + index}>
								<StyledViewTwoItems>
									<MessageHeader isRead={msg.read}>
										{msg.subject}
									</MessageHeader>
									<MessageDate>
										{format.datetime(msg.datetime_create)}
									</MessageDate>
								</StyledViewTwoItems>
								<MessageBody>{msg.preview}</MessageBody>
							</StyledView>
						);

						NotificationStore.markRead(msg);
						return messageJsx;
					})}
			</MessageMenuBox>
		);
	}
}
