import React, { ReactElement } from 'react';
import {
	TableBox,
	StyledInput,
	StyledHr,
	TransactionTableView,
	PendingTransactionTableView,
	FileChooserStyle,
} from './transfer/transfersComponent.css';
import { theme } from '../../../common/theme';
import {
	IStatusState,
	IMessageConfig,
	Reporter,
	getFormComponents,
} from '../../../logic/handler/messagehandler/messageHandlerConfig';
import { IItfAccount } from '../../../logic/types';
import { Account, Ident, ApiError, api, ReportAcceptHeader, apis } from '../../../logic/api';
import { format } from '../../../logic/helper/format';
import {
	OverlayHandler,
	Overlays,
} from '../../../logic/handler/overlayhandler/overlayHandler';
import { Icons } from '../../../images';
import { Accounts } from '../../../logic/accounts';
import { Log, Logs } from '../../../logic/log';
import { MessageHandler } from '../../../logic/handler/messagehandler/messageHandler';
import { translate } from '../../../common/language/translate';
import {
	CustomerDataStore,
	BackofficeStore,
	INotifyAbleBackofficeData,
	UserDataStore }  from  '../../../logic/flux';
import { Table } from '../../../components/compositcomponents/table/table';
import styled from 'styled-components';
import { InitComponentHandler } from '../../../logic/handler/initialdatahandler/InitComponentHandler';
import {
	InitialDataComponent,
	IInitProps,
	IInitState,
} from '../../../logic/handler/initialdatahandler/initialDataComponent';
import {
	RequestMoneyResponse,
	PaymentsRequestMoneyDeleteRequest,
} from '../../../logic/api/account';
import ActivityIndicator from '../../../components/atomiccompoents/activityIndicator';
import { PagingComponent } from '../../../components/paging';
import TouchableOpacity from '../../../components/atomiccompoents/buttons/touchableOpacity';
import { compareDate, downloadFile, evaluateErrorMessage, revertTransaction } from '../../../logic/helper/Common';
import { FileType } from '../../../components/compositcomponents/popup/fileTypeChooserOverlay';
import { CheckBox } from '../../../components/atomiccompoents/form';
import AccountSelector from '../../../components/accountSelector';
import Title from '../../../components/compositcomponents/title';
import Tabs, { ITabvalues } from '../../../components/atomiccompoents/tabs/tabs';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';
import { Config } from '../../../config';
import { KeyValueBlock, KeyField, ValueField } from './customers/basicStyledComponents/customerDetails.css';
import { DisplayMenuComponent } from '../../../components/compositcomponents/displayMenuComponent';
import { CustomDatePicker } from '../../../components/datepicker';
import { IPagingStore } from '../../../logic/flux/stores/pagingStore';
import { getDispoConverter, getPendingConverter, getTransactionConverter } from './transfer/converter';
import Expandable from '../../../components/compositcomponents/expandable/expandable';

interface IProps extends IInitProps {
	redirectCallback?: (path: string) => void;
}

interface IState extends IStatusState, IInitState {
	accounts?: Array<IItfAccount>;
	header: Array<string>;
	keys: Array<string>;
	startBalance: number;
	endBalance: number;
	startDate: Date;
	endDate: Date;
	searchKey: string;
	selectedAccount?: number;
	response?: Account.Activity;
	pendingTransactions?: Array<RequestMoneyResponse>;
	dispoTransactions?: Account.DispoActivity;
	selectedUser?: Ident.Person;
	loading?: boolean;
	loadingPending?: boolean;
	selectedTab: number;
	dynamicPaging?: boolean;
	refreshCount?: number;
	downloadLoading?: boolean;
	pickerStartOpen?: boolean;
	pickerEndOpen?: boolean;
	index?: number;
	offset?: number;
	hideReversal?: boolean;
	showBlocked?: boolean;
	startBlockedBalance?: number;
	endBlockedBalance?: number;
	tableHeightExpand: boolean; 
}

interface ISearch {
	search: {
		error: string | undefined;
		valid: boolean;
		value: any;
	};
}

interface ITableInformation {
	showPaging: boolean, 
	numberOfEntries: number, 
	resetCallback?: (data: any, updateParams?: { updateCallback: () => void, firstOfPage: number}) => void | undefined,
	type: 'S' | 'T' | 'P'
}

export default class TransfersComponent extends InitialDataComponent<IProps, IState>
	implements INotifyAbleBackofficeData {
	private converter = getTransactionConverter(this);
	private pendingConverter = getPendingConverter(this);
	private dispoConverter = getDispoConverter(this);
	private table: Table<Account.ActivityStatements> | null = null;
	private updated = false;
	private firstPage: number | undefined = undefined;

	private DatePickerBoxStyle: React.CSSProperties = {
		boxShadow: 'none',
		borderRadius: '0px',
		height: '24px',
		border: 'none',
		margin: '0px',
		justifyContent: 'flex-start',
	}; 

	private DatePickerTextStyle: React.CSSProperties = {
		color: '#4a4a4a',
		textAlign: 'left',
		margin: '0px',
		marginRight: '8px',
		fontWeight: 400,
		fontSize: '16px',
	};

	constructor(props: IProps) {
		super(props);
		const preSetDates = this.isCustomerScreen()
			? CustomerDataStore.getDates()
			: BackofficeStore.getDates();
		const toDate = preSetDates != null ? preSetDates.endDate : new Date();
		const fromDate = preSetDates != null ? preSetDates.startDate : new Date();
		
		if (preSetDates == null) {
			fromDate.setMonth(fromDate.getMonth() - 1);
		}
		this.downloadStatement = this.downloadStatement.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this.onSearchKeyChange = this.onSearchKeyChange.bind(this);
		this.setTableRef = this.setTableRef.bind(this);
		this._onChangeUserData = this._onChangeUserData.bind(this);
		this._onChangeBackofficeData = this._onChangeBackofficeData.bind(this);
		this.onTabChanged = this.onTabChanged.bind(this);
		this.updateData = this.updateData.bind(this);
		this.onExpand = this.onExpand.bind(this);
		const account: IItfAccount | undefined = BackofficeStore.getAccount();

		const n: IState = {
			accounts: account == null ? undefined : [account],
			header: [''],
			keys: [''],
			startBalance: 0,
			endBalance: 0,
			startDate: fromDate,
			endDate: toDate,
			searchKey: '',
			selectedTab: 0,
			dynamicPaging: false,
			refreshCount: 0,
			hideReversal: false,
			showBlocked: false,
			tableHeightExpand: true
		};

		this.state = n;
		InitComponentHandler.register(this, this.constructor.name);
	}

	reviveState(newState: IState): void {
		this.setState({
			...newState,
			startDate: 'startDate' in newState ? new Date(newState['startDate']) : new Date(),
			endDate: 'endDate' in newState ? new Date(newState['endDate']) : new Date(),
		});
	}
	componentDidMount(): void {
		UserDataStore.addChangeListener(this._onChangeUserData);
		CustomerDataStore.addChangeListener(this._onChangeUserData);
		this._onChangeUserData();
		BackofficeStore.addChangeListener(this._onChangeBackofficeData);
		this._onChangeBackofficeData();
		this.fill(this.constructor.name);
	}

	componentWillUnmount(): void {
		UserDataStore.removeChangeListener(this._onChangeUserData);
		CustomerDataStore.removeChangeListener(this._onChangeUserData);
		BackofficeStore.removeChangeListener(this._onChangeBackofficeData);
		InitComponentHandler.cleanUp(this.constructor.name);
	}

	componentDidUpdate() {
		this.updated = false;
	}

	cancelTransaction(ta_id: number, transaction_id: number) {
		revertTransaction(ta_id, transaction_id, () => {
			this.updated = true;
			this.updateData({});
		});
	}

	_onChangeBackofficeData(): void {
		const account: IItfAccount | undefined = BackofficeStore.getAccount();

		if (this.isBackofficeScreen()) {
			const dates = BackofficeStore.getDates();
			this.setState({
				selectedUser: undefined,
				accounts: account == null ? undefined : [account],
				selectedAccount: 0,
				startDate: dates != null ? dates.startDate : this.state.startDate,
				endDate: dates != null ? dates.endDate : this.state.endDate,
			});

			this.updateData({
				account: account,
			});
		}
	}

	_onChangeUserData(): void {
		if (this.isCustomerScreen()) {
			const person: Ident.Person | undefined = CustomerDataStore.getUser();
			const dates = CustomerDataStore.getDates();
			const acc = CustomerDataStore.getCurrentAccount();
			this.setState({
				selectedUser: person,
				startDate: dates != null ? dates.startDate : this.state.startDate,
				endDate: dates != null ? dates.endDate : this.state.endDate,
			});

			if (person == null) {
				return;
			}
				Accounts.getAccountsByPersonId(person.person_id).then((response) => {
					if (response.length > 0) {
						let selectedAccount = 0;
						if(acc != null) {
							
							for(const o in response) {
								if(response[o].account_number === acc.account_number) {
									selectedAccount = parseInt(o);
								}
							}
						} else {
							const selectedAccount: number =
							this.state.selectedAccount == null ||
							this.state.selectedAccount < 0 ||
							this.state.selectedAccount >= response.length
								? 0
								: this.state.selectedAccount;
						}
						this.setState({
							accounts: response,
							selectedAccount: selectedAccount,
						});

						this.updateData({
							account: response[selectedAccount],
						});
					}
				});
		}
	}

	private downloadStatement(filetype: FileType): void {
		this.setState({
			downloadLoading: true,
		});
		Log.debug(Logs.COMPONENT, 'Clicked downloadStatement');
		if(filetype === FileType.inApp) {
			OverlayHandler.showOverlay(Overlays.reportOverlay, {data: this.table != null ? this.table.getData() : []});
			this.setState({
				downloadLoading: false,
			});
		return;
	}
		if (this.state.response != null) {
			Log.debug(Logs.COMPONENT, 'Downloading preselected transactions');

			let acceptHeader: ReportAcceptHeader | null = null;
			let fileEnding: string = '';
			switch (filetype) {
				case FileType.csv:
					acceptHeader = 'text/comma-separated-values';
					fileEnding = 'csv';
					break;
				default:
				case FileType.pdf:
					acceptHeader = 'application/pdf';
					fileEnding = 'pdf';
					break;
				case FileType.xls:
					acceptHeader =
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
					fileEnding = 'xlsx';
					break;
			}

			const activity = {
				body: {
					...this.state.response,
					statements: this.table != null ? this.table.getData() : [],
					data: {},
					images: []
				},
				accept: acceptHeader,
			};
			api.reportRequest(activity, 'transactions.xlsx')
				.then((file: Blob) => {
					this.setState({
						downloadLoading: false,
					});
					downloadFile(file, format.getDateString() + '_transactions.' + fileEnding);
				})
				.catch((error: string) => Log.error(Logs.REMOVABLE, error));
		}
	}

	private getCurrentAccount(): IItfAccount | undefined {
		if (
			this.state.accounts == null ||
			this.state.selectedAccount == null ||
			this.state.selectedAccount >= this.state.accounts.length
		) {
			return undefined;
		}

		return this.state.accounts[this.state.selectedAccount];
	}

	private isCustomerScreen(): boolean {
		return window.location.pathname.includes('customers');
	}

	private isBackofficeScreen(): boolean {
		return window.location.pathname.includes('backoffice');
	}

	private resetStartDate() {}
	private resetEndDate() {}
	private onFilter(): void {}

	private updateData(data: { account?: IItfAccount; amount?: number, offset?: number,  date_from?: Date; date_to?: Date }, updateParams?: { updateCallback: () => void, firstOfPage: number}) {
		if (data.account == null) {
			data.account = this.getCurrentAccount();
		}
		if (data.date_from == null) {
			data.date_from = this.state.startDate;
		}
		if (data.date_to == null) {
			data.date_to = this.state.endDate;
		}
		if(data.offset == null) {
			data.offset = 0;
		}
		if(data.amount == null) {
			data.amount = Config.table.transactionLimit;
		}
		const dates = {
			startDate: data.date_from,
			endDate: data.date_to,
		};
		Log.debug(Logs.REMOVABLE, 'Starting', new Date().getTime());
		if (this.isCustomerScreen()) {
			CustomerDataStore.setDates(dates);
		} else {
			BackofficeStore.setDates(dates);
		}
		this.setState({
			startDate: data.date_from,
			endDate: data.date_to,
		});

		if (
			data.account == null ||
			data.account.account_number == null ||
			data.date_from == null ||
			data.date_to == null
		) {
			return;
		}

		this.setState({
			loading: true,
		});
		

		if (!window.location.href.includes('requests') && !window.location.href.includes('pending')) {
			Accounts.getTransactions(data.account.account_number, data.date_from, data.date_to, data.amount, data.offset, this.state.hideReversal)
				.then((response) => {
					if(updateParams != null) {
						updateParams.updateCallback();
						this.firstPage = updateParams.firstOfPage;
					}
					this.setState({
						response: response,
						startBalance: response.start_booked_balance ,
						endBalance: response.end_booked_balance,
						loading: false,
						offset: data.offset
					}, () => { this.firstPage = undefined});
					Log.debug(Logs.REMOVABLE, 'done request', new Date().getTime());
				})
				.catch((error: ApiError) => {
					const config: IMessageConfig = MessageHandler.onError(
						Reporter['accounts.transactions.get.request.error'],
						evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
					);
					this.setState({
						showInlineError: config.errorMethods.inline,
						key: config.translationKey != null ? config.translationKey + '.error' : '',
						errorMessage:
							config.errorMessage != null ? config.errorMessage : error.statusText,
						loading: false,
					});
				});
		} else if (!window.location.href.includes('requests')) {
			Accounts.getDispotransactions(data.account.account_number, data.date_from, data.date_to, data.amount, data.offset, !this.state.showBlocked)
				.then((response) => {
					if(updateParams != null) {
						updateParams.updateCallback();
						this.firstPage = updateParams.firstOfPage;
					}
					this.setState({
						dispoTransactions: response,
						startBlockedBalance: response.start_blocked_balance ,
						endBlockedBalance: response.end_blocked_balance,
						loading: false,
						offset: data.offset
					}, () => { this.firstPage = undefined});
					Log.debug(Logs.REMOVABLE, 'done request', new Date().getTime());
				})
				.catch((error: ApiError) => {
					const config: IMessageConfig = MessageHandler.onError(
						Reporter['accounts.transactions.get.request.error'],
						evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
					);
					this.setState({
						showInlineError: config.errorMethods.inline,
						key: config.translationKey != null ? config.translationKey + '.error' : '',
						errorMessage:
							config.errorMessage != null ? config.errorMessage : error.statusText,
						loading: false,
					});
				});	
		} else {
			if (this.state.selectedUser != null) {
				this.setState(
					{
						loadingPending: true,
					},
					() => {
						if (this.state.selectedUser != null) {
							Accounts.getPendingTransactions(
								this.state.selectedUser.person_id
							).then((response) => {
								//TODO: handle error of getPendingTransactions
								this.setState({
									pendingTransactions:
										response == null || response.length === 0
											? []
											: response.filter(
													(v: Account.RequestMoneyResponse) => {
														return (
															v.state ===
																Account.RequestMoneyState.P &&
															compareDate(
																v.datetime_create,
																this.state.startDate
															) >= 0 &&
															compareDate(
																v.datetime_create,
																this.state.endDate
															) <= 0
														);
													}
											),
									loadingPending: false,
									loading: false
								});
							});
						}
					}
				);
			}
		}
	}
	private revertTransaction(ta_id: number, id: number, text: string) {
		OverlayHandler.showOverlay(Overlays.ConfirmationRequestOverlay, {
			confirm: (callback: (success: boolean, close?: boolean) => void) => {
				const req: PaymentsRequestMoneyDeleteRequest = {
					request_money_id: id,
					ta_id: ta_id,
					cancellation_reason: text,
				};
				api.asyncRequest<void>(
					req,
					apis.CustomerTransactionApi,
					'paymentsRequestMoneyDelete'
				)
					.then(() => {
						MessageHandler.onSuccess(Reporter['customer.transactions.revert']);
					})
					.catch((error: ApiError) => {
						MessageHandler.onError(Reporter['customer.transactions.revert'],
						evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
					});
				this.updated = true;
				this.updateData({});
				callback(false, true);
				return;
			},
			heading: translate('transfer.details.cancelTransactionHeader'),
			message: translate('transfer.details.cancelTransaction'),
		});
	}

	private requestInvoice(ta_id: number, id: number, is_storno?: boolean) {
		const req: Account.PaymentsRequestInvoicePostRequest = {
			is_storno: is_storno,
			PlainTransactionIdentifier: {
				ta_id: ta_id,
				transaction_id: id
			}
		};
		api.asyncRequest<Account.Attachment>(
			req,
			apis.CustomerTransactionApi,
			'paymentsRequestInvoicePost'
		)
			.then((response: Account.Attachment) => {
				if(response.link != null) {
					const anchor = document.body.appendChild(document.createElement('a'));
	  				if ('download' in anchor) {
						anchor.download = response.name;
						anchor.href = response.link
						anchor.click();
					}
					this.updated = true;
					this.updateData({});
				}
				MessageHandler.onSuccess(Reporter['customer.transactions.invoice']);
			})
			.catch((error: ApiError) => {
				MessageHandler.onError(Reporter['customer.transactions.invoice'],
				evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
			});
	}

	private refundProductOrder(ta_id: number, id: number) {
		OverlayHandler.showOverlay(Overlays.ConfirmationRequestOverlay, {
			confirm: (callback: (success: boolean, close?: boolean) => void) => {
				const req: Account.PaymentsOrderProductStornoRequest = {
					PlainTransactionIdentifier: {
						transaction_id: id,
						ta_id: ta_id,
					} 
				};
				api.asyncRequest<void>(
					req,
					apis.CustomerTransactionApi,
					'paymentsOrderProductStorno'
				)
					.then(() => {
						MessageHandler.onSuccess(Reporter['customer.transactions.revert'])
					})
					.catch((error: ApiError) => {
						MessageHandler.onError(Reporter['customer.transactions.revert'],
						evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
					});
				this.updated = true;
				this.updateData({});
				callback(false, true);
				return;
			},
			heading: translate('transfer.details.cancelTransactionHeader'),
			message: translate('transfer.details.cancelTransaction'),
		});
	}

	private onSearchKeyChange(n: ISearch) {
		if (n != null && n.search != null && n.search.value != null) {
			this.setState({
				searchKey: n.search.value,
			});
		}
	}
	private onChangeDates(startDate: Date, endDate: Date) {
		if (this.state.accounts != null && this.state.selectedAccount != null) {
			this.updated = true;
			const not = IPagingStore.getNofitication();
			this.updateData({
				account: this.state.accounts[this.state.selectedAccount],
				date_from: startDate,
				date_to: endDate,
			} , {
				firstOfPage: 1,
				updateCallback: () => {
					if(not != null) {
						not({
							firstOfPage: 1,
							lastOfPage: Config.table.rowserPerPageDefault,
							offset: 0
						})
					}
				}
			});
		}
	}
	private setTableRef(element: Table<Account.ActivityStatements>): void {
		this.table = element;
	}

	onTabChanged(tab: number) {
		this.setState({
			selectedTab: tab,
		});
	}

	public onExpand(): void{
		if (this.state.tableHeightExpand === true){
			this.setState({
				tableHeightExpand: false,
			});
		} else{
			this.setState({
				tableHeightExpand: true,
			});
		}
	}

	getSrcDataInformation() : ITableInformation {
		let out: ITableInformation = {showPaging: false, numberOfEntries: 0, resetCallback: undefined, type: 'T'};
		const current = window.location.href.includes('requests') ? 'P' : window.location.href.includes('pending') ? 'S' : 'T';
		switch(current) {
			case 'P':  out = {
				showPaging: this.state.pendingTransactions != null && this.state.pendingTransactions.length > Config.table.rowserPerPageDefault ?  true : false,
				numberOfEntries: this.state.pendingTransactions != null ? this.state.pendingTransactions.length : 0,
				resetCallback: undefined,
				type: 'P'
				}
				break;
			case 'T':  out = {
				showPaging: this.state.response != null && this.state.response.statements != null && this.state.response.statements.length > Config.table.rowserPerPageDefault ?  true : false,
				numberOfEntries: this.state.response != null && this.state.response.statements != null ? this.state.response.statements.length : 0,
				resetCallback: this.updateData,
				type: 'T'
				}
				break;	
			case 'S':  out = {
				showPaging: this.state.dispoTransactions != null && this.state.dispoTransactions.dispo_statements != null && this.state.dispoTransactions.dispo_statements.length > Config.table.rowserPerPageDefault ?  true : false,
				numberOfEntries: this.state.dispoTransactions != null && this.state.dispoTransactions.dispo_statements != null ? this.state.dispoTransactions.dispo_statements.length : 0,
				resetCallback: this.updateData,
				type: 'S'
				}
				break;		
		}
		return out;
	}

	createTabValues() {
		let pendingTransactions: ReactElement | null = null;
		if (!this.isBackofficeScreen()) {
			pendingTransactions = (
				<ActivityIndicator size="medium" color={theme.ActivityIndicator.color} />
			);
			if (this.state.loadingPending !== true) {
				if (
					this.state.pendingTransactions == null ||
					this.state.pendingTransactions.length === 0
				) {
					pendingTransactions = (
						<span>{translate('transactions.noPendingTransactions')}</span>
					);
				} else {
					pendingTransactions = (
						<Table<RequestMoneyResponse>
							header={[
								translate('transactions.header.name'),
								translate('transactions.header.information'),
								translate('transactions.header.description'),
								translate('transactions.header.date'),
								translate('transactions.header.attachment'),
								translate('transactions.header.amount'),
							]}
							sourceData={
								this.state.pendingTransactions == null
									? []
									: this.state.pendingTransactions
							}
							fields={[
								'counterpartyName',
								'remittanceInformationUnstructured',
								'description',
								'datetime_create',
								'attachment',
								'instructedAmount',
							]}
							dataConverter={this.pendingConverter}
							searchValue={this.state.searchKey}
							stickyHeader={true}
							highlightTextOnSearch={true}
							hidePaging={false}
							externalPaging={true}
							dynamicPaging={this.state.dynamicPaging}
							resize={true}
						/>
					);
				}
			}
		}

		let transactions: ReactElement = <ActivityIndicator size="medium" />;
		if (this.state.loading !== true) {
			if (
				this.state.response == null ||
				this.state.response.statements == null ||
				this.state.response.statements.length === 0
			) {
				transactions = <span>{translate('transactions.noTransactions')}</span>;
			} else {
				transactions = (
					<Table<Account.ActivityStatements>
						header={[
							translate('transactions.header.name'),
							translate('transactions.header.transactionId'),
							translate('transactions.header.description'),
							translate('transactions.header.statement'),
							translate('transactions.header.bookingDate'),
							translate('transactions.header.valueDate'),
							translate('transfer.details.tradeDate'),
							translate('transactions.header.amount'),
						]}
						sourceData={
							this.state.response == null ||
							this.state.response.statements == null
								? []
								: this.state.response.statements
						}
						fields={[
							'participant_email_address',
							'transaction_id',
							'statement_text',
							'txkey_description',
							'booking_datetime',
							'value_date',
							'transaction_datetime',
							'amount',
						]}
						resize={true}
						stickyHeader={true}
						searchValue={this.state.searchKey}
						highlightTextOnSearch={true}
						ref={this.setTableRef}
						onFilter={this.onFilter}
						dataConverter={this.converter}
						externalPaging={true}
						hidePaging={false}
						dynamicPaging={false}
						updateTransactionCallback={this.updateData}
						firstOfPage={this.firstPage}
						rowsPerPageParam={Config.table.rowserPerPageDefault}
					/>
				);
			}
		}
		let dispoTransactions: ReactElement = <ActivityIndicator size="medium" />;
		if (this.state.loading !== true) {
			if (
				this.state.dispoTransactions == null ||
				this.state.dispoTransactions.dispo_statements == null ||
				this.state.dispoTransactions.dispo_statements.length === 0 
			) {
				dispoTransactions = <span>{translate('transactions.noTransactions')}</span>;
			} else {
				dispoTransactions = (
					<Table<Account.DispoActivityDispoStatements>
						header={[
							translate('transactions.header.transactionId'),
							translate('transactions.header.description'),
							translate('transactions.header.statement'),
							translate('transactions.header.bookingDate'),
							translate('transactions.header.valueDate'),
							translate('transfer.details.tradeDate'),
							translate('transactions.header.amount'),
						]}
						sourceData={
							this.state.dispoTransactions == null ||
							this.state.dispoTransactions.dispo_statements == null 
								? []
								: this.state.dispoTransactions.dispo_statements
						}
						fields={[
							'transaction_id',
							'statement_text',
							'txkey_description',
							'booking_datetime',
							'value_date',
							'transaction_datetime',
							'amount',
						]}
						resize={true}
						stickyHeader={true}
						searchValue={this.state.searchKey}
						highlightTextOnSearch={true}
						onFilter={this.onFilter}
						dataConverter={this.dispoConverter}
						externalPaging={true}
						hidePaging={false}
						dynamicPaging={false}
						updateTransactionCallback={this.updateData}
						firstOfPage={this.firstPage}
						rowsPerPageParam={Config.table.rowserPerPageDefault}
					/>
				);
			}
		}
		const Tabvalues: Array<ITabvalues> = [];
		if(!this.isBackofficeScreen()) {
			Tabvalues.push(
				{
					name: 'customertransactions',
					path: '/customers/details/transactions/customertransactions',
					title: translate('transactions.transactions'),
					callback: () => {
						return (
							<TransactionTableView
								isBackofficeScreen={this.isBackofficeScreen() === true} tableHeightExpand={this.state.tableHeightExpand} >
								{transactions}
							</TransactionTableView>
						);
					},
				}
			);
			Tabvalues.push(
				{
					name: 'pending',
					path: '/customers/details/transactions/pending',
					title: translate('transactions.pending'),
					callback: () => {
						return (
							<TransactionTableView
								isBackofficeScreen={this.isBackofficeScreen() === true} tableHeightExpand={this.state.tableHeightExpand} >
								{dispoTransactions}
							</TransactionTableView>
						);
					}
				}
			);
			Tabvalues.push(
				{
					name: 'requests',
					path: '/customers/details/transactions/requests',
					title: translate('transactions.requests'),
					callback: () => {
						return (
							<React.Fragment>
								<div
									style={{
										flexGrow: 1,
										fontFamily: 'Roboto',
										fontSize: 14,
										lineHeight: '21px',
										color: '#DADADA',
									}}></div>

								<PendingTransactionTableView>
									{pendingTransactions}
								</PendingTransactionTableView>
							</React.Fragment>
						);
					}
				}
			);
		} else {
			Tabvalues.push(
				{
					name: 'transactions',
					path: '/backoffice/transactions',
					title: translate('transactions.transactions'),
					callback: () => {
						return (
							<TransactionTableView
								isBackofficeScreen={this.isBackofficeScreen() === true} tableHeightExpand={this.state.tableHeightExpand} >
								{transactions}
							</TransactionTableView>
						);
					},
				},
			)
		}
		return Tabvalues;
	}

	render() {
	
		const conf = this.getSrcDataInformation();
		
		const Tabvalues: Array<ITabvalues> = this.createTabValues();

		return (
			<ComponentWrapper>
				<TableBox>
					<TransactionBlock>
					<Expandable open={true} title={translate('transactions.expandableTitle')} onExpand={this.onExpand}>
						<ComponentWrapper>
							<AccountSelector />
						</ComponentWrapper>
						<HeaderBlock>
								<div
									style={{
										flexGrow: 1,
										justifyContent: 'flex-start',
										maxWidth: '80%',
									}}>
									<Title title={translate('transactions.transactions')} />
								</div>
								
								<IconHolder onClick={() => {this.updated = true;
														   this.updateData({})
														}
														   }>
									{Icons.refresh()}
								</IconHolder>
													   
								<KeyValueBlock
									style={{
										flexDirection: 'row',
										border: 'none',
										justifyContent: 'flex-start',
										alignItems: 'center',
										maxWidth: '600px',
									}}>
									<KeyField style={{ marginRight: '8px', overflow: 'visible' }}>
										{translate('transactions.dateRange')}:
									</KeyField>
									<ValueField>
										<CustomDatePicker
											active={this.state.pickerStartOpen}
											openCallback={() => {
												this.setState({ pickerEndOpen: true });
											}}
											selectedValue={this.state.startDate}
											onChange={(date: Date) =>
												this.onChangeDates(date, this.state.endDate)
											}
											displayDate={true}
											resetCallback={() => {}}
											iconCallback={Icons.calendarExpand}
											boxStyle={this.DatePickerBoxStyle}
											textStyle={this.DatePickerTextStyle}
											maxDate={this.state.endDate}
											number={0}
											toggleHeightOffset={16}
											smallText={true}
										/>
									</ValueField>
									<div style={{ width: '32px', marginLeft: '16px' }}> - </div>
									<ValueField>
										<CustomDatePicker
											active={this.state.pickerEndOpen}
											xOffset={200}
											openCallback={() => {
												this.setState({ pickerStartOpen: true });
											}}
											selectedValue={this.state.endDate}
											onChange={(date: Date) =>
												this.onChangeDates(this.state.startDate, date)
											}
											displayDate={true}
											iconCallback={Icons.calendarExpand}
											resetCallback={() => {}}
											boxStyle={this.DatePickerBoxStyle}
											textStyle={this.DatePickerTextStyle}
											minDate={this.state.startDate}
											number={1}
											toggleHeightOffset={16}
											smallText={true}
										/>
									</ValueField>
									<KeyValueBlock
										style={{
											flexDirection: 'row',
											border: 'none',
											justifyContent: 'flex-start',
											alignItems: 'center',
										}}>
										<KeyField style={{ marginRight: '8px', overflow: 'visible' }}>
											{translate(conf.type === 'S' ?  'transactions.hideBlocked' : 'transactions.hideReversal')}:
										</KeyField>
										<ValueField style={{paddingTop: '16px'}}>
											<CheckBox 
												id="hideReversalCheckbox"
												defaultValue={false}
												value={conf.type === 'S' ? this.state.showBlocked : this.state.hideReversal}
												onChange={(value: boolean) => {
													this.updated = true;
													this.setState({
														hideReversal: conf.type === 'S' ? this.state.hideReversal : value,
														showBlocked:  conf.type === 'S' ? value                   : !this.state.showBlocked
													}, () => { this.updateData({}, 
																			  {
																				firstOfPage: 1,
																				updateCallback: () => {
																					const not = IPagingStore.getNofitication();
																					if(not != null) {
																						not({
																							firstOfPage: 1,
																							lastOfPage: Config.table.rowserPerPageDefault,
																							offset: 0
																						})
																					}
																				}
																			})
																		} ); 
																	} 
												}/>
										</ValueField>
									</KeyValueBlock>
								</KeyValueBlock>
											
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'flex-end',
										paddingRight: '16px',
										marginBottom: '8px',
									}}>
									<StyledInput
										value={this.state.searchKey}
										notification={this.onSearchKeyChange}
										id="search"
										placeHolder={translate('transactions.search.placeholder')}
										iconRight={Icons.search()}
										containerStyle={{
											height: '32px',
											marginTop: '8px',
											width: '100%',
											boxShadow: 'none',
											marginRight: '16pxt',
										}}
										input={InputStyle}
										inline={true}
									/>
								</div>
									
						</HeaderBlock>
					</Expandable>
					{getFormComponents(
						this.state.showInlineError,
						this.state.showInlineSuccess,
						this.state.errorMessage,
						this.state.successMessage,
						this.state.key
					)}
					<StyledHr />
					<Spacer />
					<HeaderBar></HeaderBar>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginTop: '-32px',
						}}>
						<Tabs
							activeTab="transactions"
							innerTab={true}
							basePath={
								this.isBackofficeScreen()
									? '/backoffice/transactions'
									: '/customers/details/transactions/'
							}
							redicrect={
								this.isBackofficeScreen()
									? '/backoffice/transactions'
									: '/customers/details/transactions/customertransactions'
							}
							tabList={Tabvalues}
							onUrlChange={() => this.updateData({}) }
							aditionalComponent={
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'flex-end',
									}}>
									{conf.showPaging ? (
										<PagingWrapper>
											<PagingComponent
												numberOfEntries={
													conf.numberOfEntries
												}
												rowsPerPage={4}
												resetTransactionscallback={conf.resetCallback}
												indexReset={this.updated}
											/>
										</PagingWrapper>
									) : (
										undefined
									)}
									<KeyValueBlock
										style={{
											width: '200px',
											flexDirection: 'row',
											border: 'none',
											justifyContent: 'space-around',
											alignItems: 'center',
										}}>
										<KeyField>
											{translate('transactions.startBalance')}:
										</KeyField>
										<DisplayMenuComponent
											elementKey="start_balance"
											copyValue={
												(conf.type === 'T' || conf.type === 'P') && this.state.startBalance != null
													? format.currency(
															this.state.startBalance
													  )
													: this.state.startBlockedBalance != null ?
													format.currency(this.state.startBlockedBalance) : ""
											}
											methods={{}}>
											<ValueField>
												{format.currency(conf.type === 'T' || conf.type === 'P' ? this.state.startBalance : this.state.startBlockedBalance)}
											</ValueField>
										</DisplayMenuComponent>
									</KeyValueBlock>
								</div>
							}
						/>
					</div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'flex-end',
								marginTop: '12px',
							}}>
							<ButtonOk
								disableSpinner={true}
								style={{
									width: '100px',
									height: '40px',
									padding: '0px',
									marginRight: '-25px',
								}}
								onClick={(event: any) => {
									event.preventDefault();
								}}>
								<TouchableOpacity
									onClick={(event, domRect) => {
										OverlayHandler.showOverlay(Overlays.FileTypeChooser, {
											callback: this.downloadStatement,
											posX: domRect == null ? 0 : domRect.x - 2,
											posY: domRect == null ? 0 : domRect.y - 142,
											width: '100px',
										});
									}}
									containerStyle={FileChooserStyle}>
									{this.state.downloadLoading === true ? (
										<div style={{ marginBottom: '15px' }}>
											<ActivityIndicator animating={true} size="small" />
										</div>
									) : (
										<div style={{ marginBottom: '15px' }}>
											{Icons.downloadIcon()}{' '}
										</div>
									)}
								</TouchableOpacity>
							</ButtonOk>
							<KeyValueBlock
								style={{
									width: '200px',
									marginLeft: '20px',
									marginRight: 0,
									fontSize: '16px',
									flexDirection: 'row',
									border: 'none',
									justifyContent: 'space-around',
									alignItems: 'center',
								}}>
								<KeyField>{translate('transactions.endBalance')}:</KeyField>

								<DisplayMenuComponent
									elementKey="end_balance"
									copyValue={
										(conf.type === 'T' || conf.type === 'P') && this.state.endBalance != null
														? format.currency(
																this.state.endBalance
														  )
														: this.state.endBlockedBalance != null ?
														format.currency(this.state.endBlockedBalance) : ""
												}
									methods={{}}>
									<ValueField>
										{format.currency(conf.type === 'T' || conf.type === 'P' ? this.state.endBalance : this.state.endBlockedBalance)}
									</ValueField>
								</DisplayMenuComponent>
							</KeyValueBlock>
						</div>
					</TransactionBlock>
				</TableBox>
			</ComponentWrapper>
		);
	}
}

const ComponentWrapper = styled.div``;
const HeaderBlock = styled.div`
	height: 42px;
	width: 100%;
	align-items: center;
	display: inline;
`;
const TransactionBlock = styled.div``;
const IconHolder = styled.div`
	height: 30px;
	width: 30px;
	padding-right: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 16px;
	:hover {
		cursor: pointer;
		background-color: ${(props) => props.theme.ButtonCancel.backgroundColor};
	}
`;
const InputStyle: React.CSSProperties = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	border: '1px solid #E8ECEF',
	boxShadow: 'none',
	minWidth: '100px',
	maxWidth: '280px',
	height: '32px',
	paddingRight: '5px',
};

const HeaderBar = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	width: 100%;
	margin-bottom: 0px;
	height: 38px;
`;
const PagingWrapper = styled.div`
	justify-self: flex-end;
	margin: auto;
	margin-right: 12px;
	max-height: 32px;
`;
const Spacer = styled.div`
	height: 12px;
`;
