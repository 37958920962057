// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Iso4217Currency,
} from './';

/**
 * @export
 * @interface ProductVoucher
 */
export interface ProductVoucher {
    /**
     * @type {number}
     * @memberof ProductVoucher
     */
    product_voucher_id?: number;
    /**
     * @type {number}
     * @memberof ProductVoucher
     */
    voucher_group?: number;
    /**
     * @type {object}
     * @memberof ProductVoucher
     */
    voucher_code?: object;
    /**
     * @type {number}
     * @memberof ProductVoucher
     */
    amount: number;
    /**
     * @type {Iso4217Currency}
     * @memberof ProductVoucher
     */
    currency: Iso4217Currency;
    /**
     * @type {string}
     * @memberof ProductVoucher
     */
    state?: ProductVoucherStateEnum;
    /**
     * @type {string}
     * @memberof ProductVoucher
     */
    merchant_account_number?: string;
    /**
     * @type {string}
     * @memberof ProductVoucher
     */
    email_address?: string;
    /**
     * @type {Date}
     * @memberof ProductVoucher
     */
    valid_from: Date;
    /**
     * @type {Date}
     * @memberof ProductVoucher
     */
    valid_until: Date;
    /**
     * @type {number}
     * @memberof ProductVoucher
     */
    quantity?: number;
    /**
     * @type {string}
     * @memberof ProductVoucher
     */
    person_create?: string;
    /**
     * @type {string}
     * @memberof ProductVoucher
     */
    receiver?: string;
    /**
     * @type {string}
     * @memberof ProductVoucher
     */
    testcenter?: string;
    /**
     * @type {number}
     * @memberof ProductVoucher
     */
    product_id?: number;
    /**
     * @type {string}
     * @memberof ProductVoucher
     */
    voucher_type?: ProductVoucherVoucherTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ProductVoucherStateEnum {
    A = 'A',
    U = 'U',
    D = 'D'
}
/**
 * @export
 * @enum {string}
 */
export enum ProductVoucherVoucherTypeEnum {
    OnceOnly = 'onceOnly',
    Unlimited = 'unlimited',
    S = 'S',
    M = 'M'
}

