import React, { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { translate } from '../../../../common/language/translate';
import { DisplayMenuComponent } from '../../../../components/compositcomponents/displayMenuComponent';
import { Table } from '../../../../components/compositcomponents/table/table';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';
import Title from '../../../../components/compositcomponents/title';
import { Icons } from '../../../../images';
import { api, ApiError, apis, Ident } from '../../../../logic/api';
import { Actions, CustomerDataStore, PermissionStore } from '../../../../logic/flux';
import { ThemeStore } from '../../../../logic/flux/stores/themeStore';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { OverlayHandler, Overlays } from '../../../../logic/handler/overlayhandler/overlayHandler';
import { downloadFile, evaluateErrorMessage, loadAndShowImage, updatePerson } from '../../../../logic/helper/Common';
import { format } from '../../../../logic/helper/format';
import { Log, Logs } from '../../../../logic/log';
import { FlexBox } from '../../../auth/auth.css';
import { ContainerText } from '../transfer/transfersComponent.css';
import { ColumnBox, HeaderCell, KeyField, KeyValueBlock, ValueField } from './basicStyledComponents/customerDetails.css';

interface IProps {
}

interface IState {
    data: Array<Ident.AdvancedKYCDataDoc>,
    selectedUser?: Ident.Person,
}

const InnerKeyValueBlock = styled(KeyValueBlock)`
    border-bottom: 1px solid #EEEEEE;
    margin-left: 26px;
    height: 124px;
    max-width: 520px;
`;

const OuterKeyvValueBlock = styled(KeyValueBlock)`
    max-width: 750px;

`;

class CustomerDetailsAdvancedKyc extends React.Component<IProps, IState> {
    private converter = (
		line: Ident.AdvancedKYCDataDoc,
		index: number,
		fields: Array<string>
	): RowType =>  {
		const row: RowType = { cells: [], ref: line };
		if (row.cells == null) {
			return row;
		}
		const keys: Array<string> = Object.keys(line);
		for (let i = 0; i < fields.length; i++) {
			const field: string = fields[i];
			switch (field) {
                case 'advanced_kyc_state': {
                    row.cells.push({
						value: translate('customers.advancedKyc.states.' + line.advanced_kyc_state),
						display: <ContainerText
									title={translate('customers.advancedKyc.states.' + line.advanced_kyc_state)}>
										{translate('customers.advancedKyc.states.' + line.advanced_kyc_state)}
								</ContainerText>,
						methods: {
                            approvalCallback: line.advanced_kyc_state === 'D' && this.state.selectedUser != null && this.state.selectedUser.advanced_kyc !== true  && line.datetime_invalidate == null ? () =>  this.approveOrRejectKyc(Ident.ReleaseAction.R, line.release_requests_id != null ? line.release_requests_id : -1) : undefined,
                        },
						copyVal:  translate('customers.advancedKyc.states.' + line.advanced_kyc_state)
					});
					break;
                }
                case 'datetime_create':
                case 'datetime_invalidate': {
                    if (Object.prototype.hasOwnProperty.call(line, field)) {
						row.cells.push({
							value: String(format.date(Object.values(line)[keys.indexOf(field)])),
							display: <ContainerText 
										title={format.date(Object.values(line)[keys.indexOf(field)])}>
											{format.date(Object.values(line)[keys.indexOf(field)])}
									</ContainerText>,
							methods: {
								approvalCallback: line.advanced_kyc_state === 'D' && this.state.selectedUser != null && this.state.selectedUser.advanced_kyc !== true  && line.datetime_invalidate == null ? () =>  this.approveOrRejectKyc(Ident.ReleaseAction.R, line.release_requests_id != null ? line.release_requests_id : -1) : undefined,
							},
                           copyVal: format.date(Object.values(line)[keys.indexOf(field)])
                        });
					} else {
						row.cells.push({ value: '' });
					}
					break;
                }
                case 'person_document_id': {
                    row.cells.push({
						value: line.person_document_id != null ? line.person_document_id.toString() : '',
						display: <ContainerText>
									<Hover onClick={ () => {
                                        if(line.person_document_id != null && this.state.selectedUser != null) {
                                            loadAndShowImage(this.state.selectedUser.person_id , line.person_document_id)
                                        }
                                    }}>
                                       {Icons.collection()}
                                    </Hover>
                                
								</ContainerText>,
						methods: {
                            approvalCallback: line.advanced_kyc_state === 'D' && this.state.selectedUser != null && this.state.selectedUser.advanced_kyc !== true  && line.datetime_invalidate == null ? () =>  this.approveOrRejectKyc(Ident.ReleaseAction.R, line.release_requests_id != null ? line.release_requests_id : -1) : undefined,
                        },
						copyVal:  translate('customers.advancedKyc.states.' + line.advanced_kyc_state)
					});
					break;
                }
                case 'average_monthly_income': {
						row.cells.push({
							value:   translate('customers.advancedKyc.avgIncome.'  + line.average_monthly_income),
							display: <ContainerText 
										title={translate('customers.advancedKyc.avgIncome.'  + line.average_monthly_income)}>
											{translate('customers.advancedKyc.avgIncome.'  + line.average_monthly_income)}
									</ContainerText>,
							methods: {
								approvalCallback: line.advanced_kyc_state === 'D' && this.state.selectedUser != null && this.state.selectedUser.advanced_kyc !== true  && line.datetime_invalidate == null ? () =>  this.approveOrRejectKyc(Ident.ReleaseAction.R, line.release_requests_id != null ? line.release_requests_id : -1) : undefined,
							},
                           copyVal: translate('customers.advancedKyc.avgIncome.'  + line.average_monthly_income)
                        });
					break;
                }
				default: {
					if (Object.prototype.hasOwnProperty.call(line, field)) {
						row.cells.push({
							value: String(Object.values(line)[keys.indexOf(field)]),
							display: <ContainerText 
										title={String(Object.values(line)[keys.indexOf(field)])}>
											{String(Object.values(line)[keys.indexOf(field)])}
									</ContainerText>,
							methods: {
								approvalCallback: line.advanced_kyc_state === 'D' && this.state.selectedUser != null && this.state.selectedUser.advanced_kyc !== true  && line.datetime_invalidate == null ? () =>  this.approveOrRejectKyc(Ident.ReleaseAction.R, line.release_requests_id != null ? line.release_requests_id : -1) : undefined,
							},
                           copyVal: String(Object.values(line)[keys.indexOf(field)])
                        });
					} else {
						row.cells.push({ value: '' });
					}
					break;
				}
			}
		}
		return row;
	};

	constructor(props: IProps) {
		super(props);

		const n: IState = {
           data: [],

		};

        this.state = n;
        this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
        this.updateData = this.updateData.bind(this);
        this.approveOrRejectKyc = this.approveOrRejectKyc.bind(this);
    }

    _onChangeCustomerData() {
		const account = CustomerDataStore.getCurrentAccount();
        const selectedUser = CustomerDataStore.getUser();
 			this.setState({
                selectedUser: selectedUser,
            });
        if(selectedUser != null) {
            this.updateData(selectedUser.person_id);
        }    
    }
    componentDidMount() {
		CustomerDataStore.addChangeListener(this._onChangeCustomerData);
		this._onChangeCustomerData();
	}

	componentWillUnmount() {
		CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
    }

    
    setUpContent() {
       
    }

    updateData(person_id : number) {
        const req: Ident.PersonRequestAdvancedKycGetRequest = {
            person_id: person_id
        }
        api.asyncRequest<Array<Ident.AdvancedKYCDataDoc>>(
			req,
			apis.MaintenanceApi,
			'personRequestAdvancedKycGet'
		).then( (response: Array<Ident.AdvancedKYCDataDoc>)=> {
            this.setState({
                data: response
            })
        }).catch((error: ApiError) => {
            Log.error(Logs.API, error);
        });
    }


    approveOrRejectKyc(action: Ident.ReleaseAction, release_requests_id: number) {
        const req: Ident.ReleasesPutRequest = {
			ReleasePut: {
				release_action: action,
				release_request_id: release_requests_id
			}
		}
		api.asyncRequest<any>(
			req,
			apis.ReleaseApiIdent,
			'releasesPut'
		).then( ()=> {
            if(this.state.selectedUser != null) {
                this.updateData(this.state.selectedUser.person_id);
                updatePerson(this.state.selectedUser.person_id);
            }
        }).catch((error: ApiError) => {
            MessageHandler.onError(Reporter['person.advanced_kyc.approve.release'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
        });
    }

	render() {
        const data = this.setUpContent();
        return (
            <FlexBox>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Title title={translate('customers.advancedKyc.advancedKyc')} />
                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-start', margin: '32px'}}>
                       <HoverCreate onClick={
                           (event : SyntheticEvent) => {
                               event.stopPropagation();
                               event.preventDefault();
                               if(this.state.selectedUser != null) {
                                OverlayHandler.showOverlay(Overlays.kycDataOverlay, {person: this.state.selectedUser, callback: () => { if (this.state.selectedUser != null) { this.updateData(this.state.selectedUser.person_id) }}})
                               }
                           }
                       }>
                           {translate('customers.advancedKyc.create')}
                       </HoverCreate>
                    </div>
                    <Table 
                        dataConverter={this.converter}
                        fields={
                            ['source_of_incomme',
                             'average_monthly_income',
                             'employer_name', 
                             'employer_city', 
                             'employer_country', 
                             'employer_postcode', 
                             'employer_street', 
                             'employer_building_identifier', 
                             'person_document_id', 
                             'advanced_kyc_state', 
                             'datetime_create',
                             'datetime_invalidate'
                            ] 
                        }
                        header={
                            [
                                translate('customers.advancedKyc.input.source_of_income.label'),
                                translate('customers.advancedKyc.input.monthly_income.label'),
                                translate('customers.advancedKyc.input.employer.label'),
                                translate('customers.advancedKyc.input.employer_city.label'),
                                translate('customers.advancedKyc.input.employer_country.label'),
                                translate('customers.advancedKyc.input.employer_post_code.label'),
                                translate('customers.advancedKyc.input.employer_street.label'),
                                translate('customers.advancedKyc.input.employer_building_identifier.label'),
                                translate('customers.advancedKyc.input.document'),
                                translate('customers.advancedKyc.input.state'),
                                translate('customers.advancedKyc.input.datetime_create'),
                                translate('customers.advancedKyc.input.datetime_invalidate'),
                            ]
                        }
                        sourceData={this.state.data != null ? this.state.data : [] }
                    />
                </div> 
            </FlexBox>)
    }
}

const Main = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-y: auto;
    flex-wrap: nowrap;
    width: 100%;
    max-height: calc(100vh - 150px);
    max-width: 100%;
`;

const HoverCreate = styled.div`
  color: ${(props) => props.theme.Global.keyFontColor};
 :hover{
     cursor: pointer
 }
`;

const IconWrapper = styled.div`
    width: 24px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    svg {
        width: 20px;
        height: 20px;
        fill: ${(props) => props.theme.Global.keyFontColor};
    }
`;


const Hover = styled(IconWrapper) `
    margin-left: 64px;
    :hover {
        cursor: pointer;
    }
`;

export default CustomerDetailsAdvancedKyc;
