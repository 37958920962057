// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface PersonDocumentType
 */
export interface PersonDocumentType {
    /**
     * * \'S\' ignature * stam\'P\' * \'A\'address verification * \'F\'ront of the document * \'B\'ack of the document
     * @type {string}
     * @memberof PersonDocumentType
     */
    document_type: PersonDocumentTypeDocumentTypeEnum;
    /**
     * the junior companion code
     * @type {string}
     * @memberof PersonDocumentType
     */
    companion_code?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PersonDocumentTypeDocumentTypeEnum {
    S = 'S',
    P = 'P',
    A = 'A',
    F = 'F',
    B = 'B'
}

