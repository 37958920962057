import React, { ReactElement } from 'react';
import { AccountParams } from '../../../../logic/api/account';
import { Icons } from '../../../../images';
import { OverlayHandler } from '../../../../logic/handler/overlayhandler/overlayHandler';
import { Accounts } from '../../../../logic/accounts';
import { Log, Logs } from '../../../../logic/log';
import { ApiError } from '../../../../logic/api';
import { Actions } from '../../../../logic/flux';
import { SearchResultItem } from '../../../../components/searchResultItem';
import { SearchResultBox, IResultProps } from '../../../../components/seachResultBox';

interface IProps extends IResultProps {
	data: Array<any>;
}

class BackOfficeResultBox extends SearchResultBox<IProps> {
	createData(): Array<ReactElement> {
		const data: ReactElement[] = [];
		if (this.props.data != null && this.props.data.length > 0) {
			for (const account in this.props.data) {
				data.push(
					<SearchResultItem
						title={this.props.data[account].name}
						subTitle={this.props.data[account].account_number}
						image={Icons.customerResultListTempEntry()}
						onClick={() => this.selectAccount(this.props.data[account])}
					/>
				);
			}
		}
		return data;
	}

	private selectAccount(account: AccountParams): void {
		OverlayHandler.updateApp();
		Accounts.getAccountById(account.account_number)
			.then((response) => {
				Log.debug(Logs.API, 'Found account ' + response.name);
				Actions.backofficeChanged(response);
				// BackofficeStore.setAccount(response);
			})
			.catch((error: ApiError) => {
				Log.error(Logs.API, error);
			});
	}
}

export default BackOfficeResultBox;
