//@ts-nocheck
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { api, apis, Account, Ident, ApiError, ReportAcceptHeader } from '../../../../logic/api';
import {
	IInitProps,
	IInitState,
	InitialDataComponent,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import {
	IStatusState, Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { INotifyAbleCustomerData, CustomerDataStore } from '../../../../logic/flux';
import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import { IItfAccount } from '../../../../logic/types';
import AccountSelector from '../../../../components/accountSelector';
import { BigInput } from '../../../../components/atomiccompoents/form/inputs.css';
import { IOption } from '../../../../components/atomiccompoents/form';
import { StyledLine, StyledSelect } from '../basicStyledComponents/customersComponent.css';
import { Icons } from '../../../../images';
import { CustomDatePicker } from '../../../../components/datepicker';
import { Log, Logs } from '../../../../logic/log';
import { ContainerText } from '../transfer/transfersComponent.css';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import Title from '../../../../components/compositcomponents/title';
import { Iso4217Currency } from '../../../../logic/api/account';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { compareDate, downloadFile, evaluateErrorMessage, firstLettterLowerCase } from '../../../../logic/helper/Common';
import { Table } from '../../../../components/compositcomponents/table/table';
import { format } from '../../../../logic/helper/format';
import { PagingComponent } from '../../../../components/paging';
import { Config } from '../../../../config';

interface IFullVoucher extends Account.ProductVoucher {
    download?: -1
}

interface IVoucherForReport extends Account.ProductVoucher {
    product?: string;
}

interface IProps extends IInitProps {
	isBackoffice: boolean;
}
interface IState extends IStatusState, IInitState {
    currAccount?: IItfAccount;
    person?: Ident.Person;
    vouchers?: Array<IFullVoucher>,
    sendToEmail?: string;
    amount?: string;
    merchants?: Array<Account.MerchantAddress>;
    selectedMerchant?: string;
    validdate?: Date;
    keyForLoadingSpinner?: number;
    quantitiy?: string;
    searchKey?: string;
    selectedType?: Account.NewProductVoucherGroupVoucherTypeEnum;
    offset?: number;
    limit?: number;
    availProducts?: Array<Account.Product>;
    selectedProduct?: number;
}

const TableView = styled.div`
    
`;

const PagingWrapper = styled.div`
	justify-self: flex-end;
	margin: auto;
	margin-right: 12px;
	max-height: 32px;
`;

const StyledViewMain = styled(FlexBox)`
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
    height: 100%;
    width: 100%;
`;
const StyledContentBox = styled(FlexBox)`
	flex-direction: column;
    padding: 0px 32px;
    align-items: center;
`;

const HeaderLine = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const HoverText = styled(ContainerText)`
    :hover {
        cursor: pointer;
    }
    svg {
        fill: black;
    }
`;

const SmallInputWrapper = styled.div`
    max-width: 150px;
`;
const BigInputWrapper = styled.div`
    max-width: 350px;
    margin-right: 16px;
    margin-left: 16px;
`;

const StyledMarginSelect = styled(StyledSelect)`
    max-width: 250px;
    select {
        min-width: 200px;
        max-width: 250px;
        margin-bottom: 8px;
        margin-top: 9px;
    }
`;

export default class CustomerVouchers extends InitialDataComponent<IProps, IState>
	implements INotifyAbleCustomerData {
        private converter = (
            line: IFullVoucher,
            index: number,
            fields: Array<string>
        ): RowType => {
            const row: RowType = { cells: [], ref: line };
            if (row.cells == null) {
                return row;
            }
            const keys: Array<string> = Object.keys(line);
            for (let i = 0; i < fields.length; i++) {
                const field: string = fields[i];
                switch (field) {
                    case 'valid_from': {
                        const val = format.date(Object.values(line)[keys.indexOf(field)]);
                        row.cells.push({
                            value: val != null ? val.toString() : "",
                            display: <ContainerText 
                                        title={val}>
                                            {val}
                                    </ContainerText>,
                            methods: {
                                    },
                            copyVal: val
                            });
                        }
                        break;
                    case 'valid_until': {
                        const val = format.date(Object.values(line)[keys.indexOf(field)]);
                        row.cells.push({
                            value: val != null ? val.toString() : "",
                            display: <ContainerText 
                                        title={val}>
                                            {val}
                                    </ContainerText>,
                            methods: {
                                    },
                            copyVal: val
                            });
                        }
                        break;
                    case 'download': {
                        row.cells.push({
                            value: "",
                            display: <HoverText 
                                        onClick={() => {
                                            if(line.voucher_group == null) {
                                                return;
                                            }
                                            this.downloadVoucherXSL(line.voucher_group);
                                        }}
                                        title={""}>
                                            {Icons.downloadIcon()}
                                    </HoverText>,
                            methods: {
                                    },
                            copyVal: ""
                            });
                        }
                        break;    
                    case 'amount': {
                        const val = line.amount + " " + line.currency;
                        row.cells.push({
                            value: val,
                            display: <ContainerText 
                                        title={val}>
                                            {val}
                                    </ContainerText>,
                            methods: {
                                    },
                            copyVal: val
                            });
                        }
                        break;
                    case 'status': {
                        const val = translate('customers.details.Voucher.statusTypes.' + line.state);
                        row.cells.push({
                            value: val,
                            display: <ContainerText 
                                        title={val}>
                                            {val}
                                    </ContainerText>,
                            methods: {
                                    },
                            copyVal: val
                            });
                        }
                        break;
                    case 'product_id': {
                            const val = line.product_id != null ? this.getProductNameForId(line.product_id) ?? '' : '';
                            row.cells.push({
                                value: val,
                                display: <ContainerText 
                                            title={val}>
                                                {val}
                                        </ContainerText>,
                                methods: {
                                        },
                                copyVal: val
                                });
                            }
                            break;
                    case 'voucher_type': {
                            const val = translate('customers.voucherTypes.' + (line.voucher_type === Account.ProductVoucherVoucherTypeEnum.S ? 'onceOnly' : line.voucher_type === Account.ProductVoucherVoucherTypeEnum.M ? 'unlimited' : line.voucher_type));
                            row.cells.push({
                                value: val,
                                display: <ContainerText 
                                            title={val}>
                                                {val}
                                        </ContainerText>,
                                methods: {
                                        },
                                copyVal: val
                                });
                            }
                        break;    
                    default: {
                        if (Object.prototype.hasOwnProperty.call(line, field)) {
                            row.cells.push({
                                value: String(Object.values(line)[keys.indexOf(field)]),
                                display: <ContainerText 
                                            title={String(Object.values(line)[keys.indexOf(field)])}>
                                                {String(Object.values(line)[keys.indexOf(field)])}
                                        </ContainerText>,
                                methods: {
                                        },
                                copyVal: String(Object.values(line)[keys.indexOf(field)])
                                });
                        } else {
                            row.cells.push({ value: '' });
                        }
                        break;
                    }
                }
            }
            return row;
        };

	constructor(props: IProps) {
		super(props);

		this.state = {
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            selectedType: this.props.isBackoffice ? Account.NewProductVoucherGroupVoucherTypeEnum.OnceOnly : undefined
		};

        this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
        this.getMerchants();
        this.onDateChange = this.onDateChange.bind(this);
        this.handleMerchantSelect = this.handleMerchantSelect.bind(this);
        this.handleTypeSelect = this.handleTypeSelect.bind(this);
        this.getCurrentVouchers = this.getCurrentVouchers.bind(this);
        this.getProductOptions = this.getProductOptions.bind(this);
        this.setProduct = this.setProduct.bind(this);
	}

	_onChangeCustomerData() {
        const tempAccount = CustomerDataStore.getCurrentAccount();
        const person = CustomerDataStore.getUser();
		this.setState({
            currAccount: tempAccount,
            person: person,
            sendToEmail: !this.props.isBackoffice ? person?.primary_email_address : undefined
        }, () => {
            this.loadProducts();
            this.getCurrentVouchers({offset: 0, amount: Config.paging.defaultResults});
        })
    }

    loadProducts() {
		const req = {};
		api.asyncRequest<Array<Account.Product>>(req, apis.ProductApi, 'productsGet')
				.then((response) => {
                    this.setState({
                        availProducts: response
                    })
				})
				.catch((error: ApiError) => {
					Log.debug(Logs.API, error);
				});
	}
    
     getCurrentVouchers(data: any) {
         if(this.props.isBackoffice) {
            api.asyncRequest<Array<Account.ProductVoucher>>(
                {qoffset: data.offset, qlimit: data.amount},
                apis.DefaultApi,
                'productVoucherVoucherGroupGet',
            ).then((response: Array<Account.ProductVoucher>) => {
                let fin = response;
                if(response != null && response.length > 0) {
                    fin = this.addDownload(response).sort(this.order);
                }
                this.setState({
                    vouchers: fin,
                    offset: data.offset,
                    limit: data.amount
                }, () => {
                    this.setState(this.state);
                })
            }).catch((error: ApiError) => {
                Log.error(Logs.API, "Error on getting Vouchers", error);
            });
         } else {
            if(this.state.person == null) {
                return;
            }
            const req : Account.ProductVoucherEmailAddressGetRequest = {
                email_address: this.state.person.primary_email_address
            }
            api.asyncRequest<Array<Account.ProductVoucher>>(
                req,
                apis.DefaultApi,
                'productVoucherEmailAddressGet',
            ).then((response: Array<Account.ProductVoucher>) => {
                let fin = response;
                if(response != null && response.length > 0) {
                    fin = this.addDownload(response).sort(this.order);
                 }
                this.setState({
                    vouchers: fin
                }, () => {
                    this.setState(this.state);
                })
            }).catch((error: ApiError) => {
                Log.error(Logs.API, "Error on getting Vouchers", error);
            });
         }
        
     }

     order(a: IFullVoucher, b: IFullVoucher): -1 | 0 | 1 {
        return compareDate(b.valid_from, a.valid_from);
    }

     setNewVoucher() {
         if(!this.props.isBackoffice) {
            if(this.state.currAccount == null || this.state.amount == null || this.state.validdate == null || this.state.sendToEmail == null ) {
                const missingParams = [];
                if(this.state.amount == null) {
                    missingParams.push( translate('customers.details.externalAccounts.input.amount.label'));
                } 
                if (this.state.validdate == null) {
                    missingParams.push(translate('customers.details.externalAccounts.input.validTo.label' ));
                }  
                if (this.state.sendToEmail == null) {
                    missingParams.push( translate('customers.details.externalAccounts.input.receiver.label'));
                }
                MessageHandler.onError( Reporter['customers.vouchers.missingParams'], null, translate('customers.details.Voucher.missing') + missingParams.join(", "));
                this.setState({
                    keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
                })
                return;
            }
            const req: Account.ProductVoucherPostRequest = {
                NewProductVoucher: {
                    merchant_account_number: this.state.selectedMerchant,
                    amount: this.state.amount.replace(",", "."),
                    currency: this.state.currAccount.currency,
                    receiver: this.state.sendToEmail,
                    valid_until: this.state.validdate,
                    product_id: this.state.selectedProduct
                }
            }
           
            api.asyncRequest<Account.Voucher>(
                req,
                apis.DefaultApi,
                'productVoucherPost',
            ).then((response: Account.Voucher) => {
                MessageHandler.onSuccess(Reporter['customer.voucher.set']);
                this.getCurrentVouchers({offset: this.state.offset, amount: this.state.limit});
            }).catch((error: ApiError) => {
                Log.error(Logs.API, "Error on getting Merchants", error);
                MessageHandler.onError(Reporter['customer.voucher.set'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
            });
        } else {
            if(this.state.amount == null || this.state.validdate == null || this.state.sendToEmail == null || this.state.quantitiy == null ) {
                const missingParams = [];
                if(this.state.amount == null) {
                    missingParams.push(translate('customers.details.externalAccounts.input.amount.label'));
                } else if (this.state.validdate == null) {
                    missingParams.push(translate('customers.details.externalAccounts.input.validTo.label' ));
                } else if (this.state.sendToEmail == null) {
                    missingParams.push(translate('customers.details.externalAccounts.input.receiver.label'));
                } else if (this.state.quantitiy == null) {
                    missingParams.push(translate('customers.details.externalAccounts.input.quantitiy.label'));
                }
                MessageHandler.onError( Reporter['customers.vouchers.missingParams'], null, translate('customers.details.Voucher.missing') + missingParams.join(", "));
                this.setState({
                    keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
                })
                return;
            }
            const req: Account.ProductVoucherVoucherGroupPostRequest = {
                NewProductVoucherGroup: {
                    receiver: this.state.sendToEmail,
                    merchant_account_number: this.state.selectedMerchant,
                    amount: this.state.amount.replace(",", "."),
                    valid_until: this.state.validdate,
                    currency: Iso4217Currency.EUR,
                    quantity: parseInt(this.state.quantitiy),
                    voucher_type: this.state.selectedType,
                    product_id: this.state.selectedProduct
                }
            };
            
            api.asyncRequest<Account.Voucher>(
                req,
                apis.DefaultApi,
                'productVoucherVoucherGroupPost',
            ).then((response: Account.Voucher) => {
                MessageHandler.onSuccess(Reporter['customer.voucher.set']);
                this.getCurrentVouchers({offset: this.state.offset, amount: this.state.limit});
            }).catch((error: ApiError) => {
                Log.error(Logs.API, "Error on getting Merchants", error);
                MessageHandler.onError(Reporter['customer.voucher.set'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
            });
        }
        this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
        })
     }

     getMerchants() {
         api.asyncRequest<Array<Account.MerchantAddress>>(
             {},
             apis.MerchantApi,
             'merchantList',
         ).then((response: Array<Account.MerchantAddress>) => {
             this.setState({
                merchants: response
             })

         }).catch((error: ApiError) => {
            Log.error(Logs.API, "Error on getting Merchants", error)
         });
     }


	reviveState(newState: { [key: string]: any }): void {
	}


	componentDidMount(): void {
		CustomerDataStore.addChangeListener(this._onChangeCustomerData);
		this._onChangeCustomerData();
	}

	componentWillUnmount() {
		CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
	}

    generateData() {
        const out: Array<ReactElement> = [];
    }

    getTestcenterOptions(): Array<IOption> {
        const enumValues: Array<IOption> = [];
        enumValues.push({
            key: 'defaultOptionKey',
            name: 'All',
            value: undefined,
        });
        if(this.state.merchants == null) {
            return enumValues;
        }
		for (const value in this.state.merchants) {
			enumValues.push({
				key: 'defaultOptionKey' + value,
				name: this.state.merchants[value].name,
				value: this.state.merchants[value].account_number,
			});
		}
		return enumValues;
    }

    getProductOptions(): Array<IOption> {
        const enumValues: Array<IOption> = [];
        enumValues.push({
            key: 'defaultOptionKey',
            name: 'All',
            value: undefined,
        });
        if(this.state.availProducts == null) {
            return enumValues;
        }
		for (const value of this.state.availProducts) {
			enumValues.push({
				key: 'defaultOptionKey' + value.product_id,
				name: value.name ?? value.product_id.toString(),
				value: value.product_id,
			});
		}
		return enumValues;
    }

    setProduct(message: any) {
		this.setState({
			selectedProduct: message.product == null || message.product == 'All' ? undefined : parseInt(message.product),
		});
    }

    getVoucherTypeOptions(): Array<IOption> {
        const enumValues: Array<IOption> = [];
		for (const value in Account.NewProductVoucherVoucherTypeEnum) {
			enumValues.push({
				key: 'defaultOptionKey' + value,
				name: translate('customers.voucherTypes.' + firstLettterLowerCase(value)),
				value: value,
			});
		}
		return enumValues;
    }
    
    addDownload(data: Array<any>): Array<any> {
        for(const i in data) {
            data[i].download = 0;
        }
        return data;
    }

	handleMerchantSelect(message: any) {
		this.setState({
			selectedMerchant: message.merchant == null || message.merchant == 'All' ? undefined : message.merchant,
		});
    }

    handleTypeSelect(message: any) {
		this.setState({
			selectedType: firstLettterLowerCase(message.type) as Account.NewProductVoucherGroupVoucherTypeEnum,
            quantitiy: message.type === 'Unlimited' ? "1" : this.state.amount
		});
    }
    
    onDateChange(date: Date | undefined) {
        this.setState({
            validdate: date
        })
    }

    downloadVoucherXSL(voucherGroup: number) {
        const req: Account.ProductVoucherVoucherGroupVoucherGroupGetRequest = {
            voucher_group: voucherGroup
        }
        api.asyncRequest<Array<Account.ProductVoucher>>(
            req,
            apis.DefaultApi,
            'productVoucherVoucherGroupVoucherGroupGet',
        ).then((response: Array<Account.ProductVoucher>) => {
            MessageHandler.onSuccess(Reporter['customers.vouchers.report']);
            const fileEnding: string = 'xlsx';
		    const report = 'product_voucher';
            const data: Array<IVoucherForReport> = response; 
            const name: string | undefined = response[0]?.product_id != null ? this.getProductNameForId(response[0].product_id) : "";
            for(const o of data) {
                o.product = name;
            }
            const params = {
                accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' as ReportAcceptHeader,
                body: {
                    data: data,
                },
            };
            api.reportRequest(params, report + '.xlsx')
                .then((file) => {
                     downloadFile(file, format.getDateString() + '_report.' + fileEnding);
                })
                .catch((error: ApiError) => {
                    MessageHandler.onError(Reporter['customers.vouchers.report'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
                    Log.error(Logs.REMOVABLE, error)
                });

        }).catch((error: ApiError) => {
            MessageHandler.onError(Reporter['customers.vouchers.report'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
           Log.error(Logs.API, "Error on getting Report", error)
        });    
    }

    getProductNameForId(product_id: number): string | undefined {
        if(this.state.availProducts == null ) {
            return undefined
        }
        for(const o of this.state.availProducts) {
            if(o.product_id === product_id) {
                return o.name
            }
        }
    }
    
	render() {
		return (
			<StyledContentBox>
                {!this.props.isBackoffice ? <AccountSelector /> : null }
				<StyledViewMain>
                    <HeaderLine>
                        <BigInputWrapper style={{width: '350px'}}>
                            <BigInput
                                label={this.props.isBackoffice ?
                                    translate('customers.details.externalAccounts.input.receiver.label') :
                                    translate('customers.newCustomer.input.email.label')}
                                placeHolder={this.props.isBackoffice ?
                                    translate('customers.details.externalAccounts.input.receiver.placeholder') :
                                    translate('customers.newCustomer.input.email.placeholder')}
                                type="firstname"
                                id="account"
                                value={!this.props.isBackoffice && this.state.person != null && this.state.sendToEmail == null ? this.state.person.primary_email_address : this.state.sendToEmail}
                                valueFromState={true}
                                input={{maxWidth: '350px'}}
                                notification={(n) => {
                                    this.setState({
                                        sendToEmail: n.account.value 
                                    })
                                }}
                                onSubmit={() => {}}
                            />
                        </BigInputWrapper>
                        <SmallInputWrapper>
                            <BigInput
                                label={translate(
                                    'customers.details.externalAccounts.input.amount.label'
                                )}
                                placeHolder={translate(
                                    'customers.details.externalAccounts.input.amount.placeholder'
                                )}
                                type="firstname"
                                id="amount"
                                input={{maxWidth: '150px'}}
                                value={this.state.amount !=  null ? this.state.amount.toString() : ""}
                                valueFromState={true}
                                notification={(n) => {
                                    this.setState({
                                        amount: n.amount.value 
                                    })
                                }}
                                onSubmit={() => {}}
                            />
                        </SmallInputWrapper>
                        <BigInputWrapper>
                            <StyledMarginSelect
                                helptext={translate(
                                    'customers.details.externalAccounts.input.testCenter.label'
                                )}
                                id="merchant"
                                options={this.getTestcenterOptions()}
                                notification={this.handleMerchantSelect}
                                current={undefined}
                                wrapperStyle={{margin: 'initial'}}
                            />
                        </BigInputWrapper>
                        <BigInputWrapper>
                            <StyledMarginSelect
                                helptext={translate(
                                    'customers.details.Voucher.boundProduct'
                                )}
                                id="product"
                                options={this.getProductOptions()}
                                notification={this.setProduct}
                                current={undefined}
                                wrapperStyle={{margin: 'initial'}}
                            />
                        </BigInputWrapper>
                        {this.props.isBackoffice ? 
                          <BigInputWrapper>
                          <StyledMarginSelect
                              helptext={translate(
                                  'customers.details.externalAccounts.input.type.label'
                              )}
                              id="type"
                              options={this.getVoucherTypeOptions()}
                              notification={this.handleTypeSelect}
                              current={undefined}
                              wrapperStyle={{margin: 'initial'}}
                          />
                      </BigInputWrapper> : null}
                        <BigInputWrapper>
                            <CustomDatePicker
                                title={translate(
                                    'customers.details.externalAccounts.input.validTo.placeholder'
                                )}
                                selectedValue={this.state.validdate}
                                onChange={this.onDateChange}
                                iconCallback={Icons.calendarExpand}
                                displayDate={this.state.validdate != null}
                                resetCallback={() => this.onDateChange(undefined)}
                                isInput={true}
                                zIndex={5000}
                                minDate={new Date()}
                                helperText={translate(
                                    'customers.details.externalAccounts.input.validTo.label'
                                )}/>
                        </BigInputWrapper>
                        {this.props.isBackoffice ? 
                          <SmallInputWrapper>
                          <BigInput
                              label={translate(
                                  'customers.details.externalAccounts.input.quantitiy.label'
                              )}
                              placeHolder={translate(
                                  'customers.details.externalAccounts.input.quantitiy.placeholder'
                              )}
                              type="firstname"
                              id="quantitiy"
                              input={{maxWidth: '100px'}}
                              value={this.state.quantitiy !=  null ? this.state.quantitiy.toString() : ""}
                              valueFromState={true}
                              disabled={this.state.selectedType === Account.NewProductVoucherGroupVoucherTypeEnum.Unlimited}
                              notification={(n) => {
                                  this.setState({
                                      quantitiy: n.quantitiy.value 
                                  })
                              }}
                              onSubmit={() => {}}
                          />
                      </SmallInputWrapper> : null }
                    </HeaderLine>
                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-start'}}>
                        <ButtonOk
                            key={this.state.keyForLoadingSpinner}
                            disabled={this.state.currAccount != null && this.state.currAccount.state === Account.AccountDetailStateEnum.C}
                            id="btnAddCustomerOk"
                            onClick={() => { this.setNewVoucher() } }>
                            {translate('customers.details.Voucher.createVoucher')}
                        </ButtonOk>
                    </div>
                    <StyledLine />
                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-start'}}>
                        <Title 
                            title={translate('customers.details.Voucher.openVouchers')}
                        />
                    </div>
                    <TableView>
                         <BigInputWrapper>
                            <BigInput
                                placeHolder={translate('transactions.search.placeholder')}
                                iconRight={Icons.search()}
                                type="firstname"
                                containerStyle={{
                                    height: '32px',
                                    marginTop: '8px',
                                    width: '100%',
                                    boxShadow: 'none',
                                    marginRight: '16pxt',
                                    }}
                                inline={true}
                                id="search"
                                input={{maxWidth: '350px', boxShadow: 'none', height: '32px'}}
                                value={this.state.searchKey !=  null ? this.state.searchKey : ""}
                                valueFromState={true}
                                notification={(n) => {
                                    this.setState({
                                        searchKey: n.search.value 
                                    })
                                }}
                                onSubmit={() => {}}
                            />
                          </BigInputWrapper>
                          <PagingWrapper>
                            <PagingComponent
                                numberOfEntries={
                                    this.props.isBackoffice ? Config.table.transactionLimit : this.state.vouchers?.length ?? 0
                                }
                                rowsPerPage={Config.table.rowserPerPageDefault}
                                resetTransactionscallback={ this.props.isBackoffice ? this.getCurrentVouchers : undefined}
                            />
                        </PagingWrapper>
                        <Table<IFullVoucher>
                                header={!this.props.isBackoffice ? [												
                                    translate('customers.details.Voucher.sendAt'),
                                    translate('customers.details.Voucher.amount'),
                                    translate('customers.details.Voucher.createdAt'),
                                    translate('customers.details.Voucher.validTo'),
                                    translate('customers.details.Voucher.testcenter'),
                                    translate('customers.details.Voucher.status'),
                                    translate('customers.details.Voucher.code'),
                                    translate('customers.details.Voucher.product'),
                                ] : 
                                [												
                                    translate('customers.details.Voucher.sendAt'),
                                    translate('customers.details.Voucher.email'),
                                    translate('customers.details.Voucher.amount'),
                                    translate('customers.details.Voucher.quantity'),
                                    translate('customers.details.Voucher.createdAt'),
                                    translate('customers.details.Voucher.validTo'),
                                    translate('customers.details.Voucher.testcenter'),
                                    translate('customers.details.Voucher.createdBy'),
                                    translate('customers.details.Voucher.download'),
                                    translate('customers.details.externalAccounts.input.type.label'),
                                    translate('customers.details.Voucher.product'),
                                ]}
                                sourceData={this.state.vouchers != null ? this.state.vouchers : []}
                                fields={!this.props.isBackoffice ? [		 										
                                    'email_address',
                                    'amount',
                                    'valid_from',
                                    'valid_until',
                                    'testcenter',
                                    'status',
                                    'voucher_code',
                                    'product_id'
                                ]: [												
                                    'receiver',
                                    'email_address',
                                    'amount',
                                    'quantity',
                                    'valid_from',
                                    'valid_until',
                                    'testcenter',
                                    'person_create',
                                    'download',
                                    'voucher_type',
                                    'product_id'
                                ]}
                                externalPaging={true}
                                stickyHeader={true}
                                highlightTextOnSearch={true}
                                dataConverter={this.converter}
                                searchValue={this.state.searchKey}
                            />
                    </TableView>

				</StyledViewMain>
			</StyledContentBox>
		);
	}
}
