// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    AccessKey,
    AccessKeyWithPassword,
    Address,
    AddressId,
    AdvancedKYCData,
    AdvancedKYCDataDoc,
    ApprovedPersonVerification,
    Closure,
    CompanionCodes,
    Companions,
    EidRequest,
    EidVideoidFailed,
    EidWebhookNotification,
    EmailAddress,
    Face,
    FaceId,
    Fingerprint,
    FingerprintId,
    IdType,
    KYCStatus,
    MerchantUpgrade,
    NewAddress,
    NewAddressRequirment,
    NewEmailAddress,
    NewFingerprint,
    NewPhone,
    OpenAddressApproval,
    Passport,
    Person,
    PersonDocument,
    PersonFound,
    PersonHistory,
    PersonKYCStatus,
    PersonUpdateRequest,
    PersonVerification,
    Phone,
    PhoneId,
    Pin,
    ProductUserclass,
    ReferralLink,
    ReferralProduct,
    ReferralProductId,
    Settings,
    UserClass,
} from '../models';

export interface LookupProductUserClassGetRequest {
    user_class?: UserClass;
}

export interface PersonAddressDeleteRequest {
    person_id: number;
    address_id: number;
}

export interface PersonAddressGetRequest {
    person_id: number;
}

export interface PersonAddressPostRequest {
    person_id: number;
    NewAddress: NewAddress;
}

export interface PersonAddressPutRequest {
    person_id: number;
    address_id: number;
    NewAddress: NewAddress;
}

export interface PersonCompanionCodeListRequest {
    person_id: number;
}

export interface PersonCompanionListRequest {
    person_id: number;
}

export interface PersonCurrentFaceGetRequest {
    person_id: number;
    token: string;
}

export interface PersonDeleteRequest {
    person_id: number;
    reason: PersonDeleteReasonEnum;
    comment?: string;
}

export interface PersonDocumentGetRequest {
    person_id: number;
    document_id: number;
}

export interface PersonDocumentListRequest {
    person_id: number;
}

export interface PersonDocumentPutRequest {
    person_id: number;
    document: Blob;
    document_2?: Blob;
    document_3?: Blob;
    document_4?: Blob;
    document_5?: Blob;
    document_6?: Blob;
    document_7?: Blob;
    document_8?: Blob;
    document_9?: Blob;
    document_10?: Blob;
}

export interface PersonElectronicidPassportPutRequest {
    person_id: number;
    ApprovedPersonVerification: ApprovedPersonVerification;
}

export interface PersonElectronicidRequestListRequest {
    person_id: number;
}

export interface PersonElectronicidVerificationBackGetRequest {
    person_id: number;
    verification_id: string;
}

export interface PersonElectronicidVerificationFaceGetRequest {
    person_id: number;
    verification_id: string;
}

export interface PersonElectronicidVerificationFrontGetRequest {
    person_id: number;
    verification_id: string;
}

export interface PersonElectronicidVerificationGetRequest {
    person_id: number;
}

export interface PersonElectronicidVerificationPutRequest {
    person_id: number;
    electronicid_verification_id: number;
    ApprovedPersonVerification: ApprovedPersonVerification;
}

export interface PersonElectronicidVideoidFailedListRequest {
    person_id: number;
}

export interface PersonElectronicidWebhookNotificationListRequest {
    person_id: number;
}

export interface PersonEmailAddressPostRequest {
    person_id: number;
    NewEmailAddress: NewEmailAddress;
}

export interface PersonEmailAddressPutRequest {
    person_id: number;
    NewEmailAddress: NewEmailAddress;
}

export interface PersonEmailaddressDeleteRequest {
    person_id: number;
    email_address_id: number;
}

export interface PersonEmailaddressGetRequest {
    person_id: number;
    get_not_verified?: boolean;
}

export interface PersonFaceDeleteRequest {
    person_id: number;
    face_id: number;
}

export interface PersonFaceGetRequest {
    person_id: number;
    face_id: number;
}

export interface PersonFaceListRequest {
    person_id: number;
}

export interface PersonFacePostRequest {
    person_id: number;
    file: Blob;
}

export interface PersonFingerprintDeleteRequest {
    person_id: number;
    fingerprint_id: number;
}

export interface PersonFingerprintListRequest {
    person_id: number;
}

export interface PersonFingerprintPostRequest {
    person_id: number;
    NewFingerprint: NewFingerprint;
}

export interface PersonHistoryGetRequest {
    person_id: number;
    date_from?: Date;
    date_to?: Date;
}

export interface PersonJuniorDocumentListRequest {
    person_id: number;
    companion_code: string;
}

export interface PersonKycHistoryGetRequest {
    person_id: number;
}

export interface PersonKycStatusGetRequest {
    person_id: number;
}

export interface PersonMerchantDocumentGetRequest {
    person_id: number;
    person_document_id: number;
}

export interface PersonMerchantDocumentListRequest {
    person_id: number;
}

export interface PersonMerchantPutRequest {
    person_id: number;
    merchant_upgrade?: MerchantUpgrade;
    document?: Blob;
    document_2?: Blob;
    document_3?: Blob;
    document_4?: Blob;
    document_5?: Blob;
    document_6?: Blob;
    document_7?: Blob;
    document_8?: Blob;
    document_9?: Blob;
    document_10?: Blob;
}

export interface PersonPassportDocumentPutRequest {
    person_id: number;
    front?: Blob;
    back?: Blob;
    stored?: Blob;
}

export interface PersonPassportGetRequest {
    person_id: number;
}

export interface PersonPassportPassportBackGetRequest {
    person_id: number;
    passport_id: number;
}

export interface PersonPassportPassportFrontGetRequest {
    person_id: number;
    passport_id: number;
}

export interface PersonPassportStoredImageGetRequest {
    person_id: number;
    passport_id: number;
}

export interface PersonPasswordResetRequest {
    person_id: number;
}

export interface PersonPersonIdAccessKeyDeleteRequest {
    person_id: number;
    access_key: string;
}

export interface PersonPersonIdAccessKeyGetRequest {
    person_id: number;
    show_deactivated?: boolean;
}

export interface PersonPersonIdAccessKeyPostRequest {
    person_id: number;
    account_number: string;
    token: string;
    name: string;
    qr: string;
}

export interface PersonPersonIdAddressRequirementRequest {
    person_id: number;
    NewAddressRequirment: NewAddressRequirment;
}

export interface PersonPersonIdGetRequest {
    person_id: number;
}

export interface PersonPersonIdReferralLinkPostRequest {
    person_id: number;
}

export interface PersonPhoneDeleteRequest {
    person_id: number;
    phone_number_id: number;
}

export interface PersonPhoneListRequest {
    person_id: number;
}

export interface PersonPhonePostRequest {
    person_id: number;
    NewPhone: NewPhone;
}

export interface PersonPinPostRequest {
    person_id: number;
    Pin: Pin;
}

export interface PersonPutRequest {
    person_id: number;
    PersonUpdateRequest: PersonUpdateRequest;
}

export interface PersonReasonForClosureGetRequest {
    person_id: number;
}

export interface PersonRecodeRequest {
    person_id: number;
}

export interface PersonReferralLinkPostRequest {
    ReferralProductId: ReferralProductId;
}

export interface PersonRequestAdvancedKycGetRequest {
    person_id: number;
}

export interface PersonRequestAdvancedKycPostRequest {
    person_id: number;
    advanced_kyc_data?: AdvancedKYCData;
    document?: Blob;
}

export interface PersonRequestKycPutRequest {
    person_id: number;
}

export interface PersonResyncRequest {
    person_id: number;
}

export interface PersonSearchRequest {
    given_name?: string;
    name?: string;
    email_address?: string;
    search_id?: string;
    id_type?: IdType;
    birth_date?: Date;
    street?: string;
    building_identifier?: string;
    city?: string;
    zip?: string;
    phone_number?: string;
    show_history?: boolean;
    limit?: number;
    offset?: number;
    preset?: PersonSearchPresetEnum;
}

export interface PersonSettingsGetRequest {
    person_id: number;
}

export interface PersonSettingsPutRequest {
    person_id: number;
    Settings: Settings;
}

export interface ToBeRemovedPersonDocumentListRequest {
    person_id: number;
    document_type: number;
}

/**
 * no description
 */
export class MaintenanceApi extends BaseAPI implements IOperationIdMap {

    /**
     * lookup types of external ids
     */
    lookupExternalIdTypes = (): Observable<Array<IdType>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<IdType>>({
            path: '/lookup/external_id_types',
            method: 'GET',
            headers,
        });
    };

    /**
     * lookup open address aprovals
     */
    lookupOpenAddressApprovalList = (): Observable<Array<OpenAddressApproval>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<OpenAddressApproval>>({
            path: '/lookup/open_address_approval_list',
            method: 'GET',
            headers,
        });
    };

    /**
     * lookup valid products for a user_class
     */
    lookupProductUserClassGet = (requestParameters: LookupProductUserClassGetRequest): Observable<Array<ProductUserclass>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.user_class && { 'user_class': requestParameters.user_class }),
        };

        return this.request<Array<ProductUserclass>>({
            path: '/lookup/product_user_class',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * limit the validity of the connection person - address
     * delete address
     */
    personAddressDelete = (requestParameters: PersonAddressDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personAddressDelete');
        throwIfRequired(requestParameters, 'address_id', 'personAddressDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/address/{address_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{address_id}', encodeURI(requestParameters.address_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get all addresses of a person
     * get addresses
     */
    personAddressGet = (requestParameters: PersonAddressGetRequest): Observable<Array<Address>> => {
        throwIfRequired(requestParameters, 'person_id', 'personAddressGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Address>>({
            path: '/person/{person_id}/address/'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * add a new address to an already created person * one person has at maximum one living address. * trying to add more than one living address gives an error, address update might be the better choice in those cases 
     * add address to person
     */
    personAddressPost = (requestParameters: PersonAddressPostRequest): Observable<AddressId> => {
        throwIfRequired(requestParameters, 'person_id', 'personAddressPost');
        throwIfRequired(requestParameters, 'NewAddress', 'personAddressPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<AddressId>({
            path: '/person/{person_id}/address/'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            headers,
            body: requestParameters.NewAddress,
        });
    };

    /**
     * change an already existing address of a person
     * change address
     */
    personAddressPut = (requestParameters: PersonAddressPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personAddressPut');
        throwIfRequired(requestParameters, 'address_id', 'personAddressPut');
        throwIfRequired(requestParameters, 'NewAddress', 'personAddressPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/address/{address_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{address_id}', encodeURI(requestParameters.address_id)),
            method: 'PUT',
            headers,
            body: requestParameters.NewAddress,
        });
    };

    /**
     * list companion codes issued by the current person
     * list active companion codes
     */
    personCompanionCodeList = (requestParameters: PersonCompanionCodeListRequest): Observable<Array<CompanionCodes>> => {
        throwIfRequired(requestParameters, 'person_id', 'personCompanionCodeList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<CompanionCodes>>({
            path: '/person/{person_id}/companion_code'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * list current primary-companion relations the current person has
     * list current primary-companion relations
     */
    personCompanionList = (requestParameters: PersonCompanionListRequest): Observable<Array<Companions>> => {
        throwIfRequired(requestParameters, 'person_id', 'personCompanionList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Companions>>({
            path: '/person/{person_id}/companion'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * get a specificv face image
     * get a face image to display it to a third person
     */
    personCurrentFaceGet = (requestParameters: PersonCurrentFaceGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'personCurrentFaceGet');
        throwIfRequired(requestParameters, 'token', 'personCurrentFaceGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.token && { 'token': requestParameters.token }),
        };

        return this.request<Blob>({
            path: '/person/{person_id}/current_face'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
            query,
            responseType: 'blob'
        });
    };

    /**
     * initiate asynchr. deletion of a person
     * delete a person
     */
    personDelete = (requestParameters: PersonDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personDelete');
        throwIfRequired(requestParameters, 'reason', 'personDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.reason && { 'reason': requestParameters.reason }),
            ...(requestParameters.comment && { 'comment': requestParameters.comment }),
        };

        return this.request<void>({
            path: '/person/{person_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * get the document, that was uploaded 
     * get person document
     */
    personDocumentGet = (requestParameters: PersonDocumentGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'personDocumentGet');
        throwIfRequired(requestParameters, 'document_id', 'personDocumentGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Blob>({
            path: '/person/{person_id}/document/{document_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{document_id}', encodeURI(requestParameters.document_id)),
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * list the documents, that were uploaded
     * list all merchant documents
     */
    personDocumentList = (requestParameters: PersonDocumentListRequest): Observable<Array<PersonDocument>> => {
        throwIfRequired(requestParameters, 'person_id', 'personDocumentList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<PersonDocument>>({
            path: '/person/{person_id}/documents'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * upload documents for person
     */
    personDocumentPut = (requestParameters: PersonDocumentPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personDocumentPut');
        throwIfRequired(requestParameters, 'document', 'personDocumentPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.document !== undefined) {
            formData.append('document', requestParameters.document as any);
        }

        if (requestParameters.document_2 !== undefined) {
            formData.append('document_2', requestParameters.document_2 as any);
        }

        if (requestParameters.document_3 !== undefined) {
            formData.append('document_3', requestParameters.document_3 as any);
        }

        if (requestParameters.document_4 !== undefined) {
            formData.append('document_4', requestParameters.document_4 as any);
        }

        if (requestParameters.document_5 !== undefined) {
            formData.append('document_5', requestParameters.document_5 as any);
        }

        if (requestParameters.document_6 !== undefined) {
            formData.append('document_6', requestParameters.document_6 as any);
        }

        if (requestParameters.document_7 !== undefined) {
            formData.append('document_7', requestParameters.document_7 as any);
        }

        if (requestParameters.document_8 !== undefined) {
            formData.append('document_8', requestParameters.document_8 as any);
        }

        if (requestParameters.document_9 !== undefined) {
            formData.append('document_9', requestParameters.document_9 as any);
        }

        if (requestParameters.document_10 !== undefined) {
            formData.append('document_10', requestParameters.document_10 as any);
        }

        return this.request<void>({
            path: '/person/{person_id}/documents'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
            body: formData,
        });
    };

    /**
     * approve the data from verification
     */
    personElectronicidPassportPut = (requestParameters: PersonElectronicidPassportPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personElectronicidPassportPut');
        throwIfRequired(requestParameters, 'ApprovedPersonVerification', 'personElectronicidPassportPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/electronicid/passport'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
            body: requestParameters.ApprovedPersonVerification,
        });
    };

    /**
     * get the eid sessions
     */
    personElectronicidRequestList = (requestParameters: PersonElectronicidRequestListRequest): Observable<Array<EidRequest>> => {
        throwIfRequired(requestParameters, 'person_id', 'personElectronicidRequestList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<EidRequest>>({
            path: '/person/{person_id}/electronicid/request'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * get the documents back picture from the verification
     */
    personElectronicidVerificationBackGet = (requestParameters: PersonElectronicidVerificationBackGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'personElectronicidVerificationBackGet');
        throwIfRequired(requestParameters, 'verification_id', 'personElectronicidVerificationBackGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Blob>({
            path: '/person/{person_id}/electronicid/verifications/{verification_id}/back'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{verification_id}', encodeURI(requestParameters.verification_id)),
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * get the face picture from the verification
     */
    personElectronicidVerificationFaceGet = (requestParameters: PersonElectronicidVerificationFaceGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'personElectronicidVerificationFaceGet');
        throwIfRequired(requestParameters, 'verification_id', 'personElectronicidVerificationFaceGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Blob>({
            path: '/person/{person_id}/electronicid/verifications/{verification_id}/face'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{verification_id}', encodeURI(requestParameters.verification_id)),
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * get the documents front picture from the verification
     */
    personElectronicidVerificationFrontGet = (requestParameters: PersonElectronicidVerificationFrontGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'personElectronicidVerificationFrontGet');
        throwIfRequired(requestParameters, 'verification_id', 'personElectronicidVerificationFrontGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Blob>({
            path: '/person/{person_id}/electronicid/verifications/{verification_id}/front'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{verification_id}', encodeURI(requestParameters.verification_id)),
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * get data from verification
     */
    personElectronicidVerificationGet = (requestParameters: PersonElectronicidVerificationGetRequest): Observable<PersonVerification> => {
        throwIfRequired(requestParameters, 'person_id', 'personElectronicidVerificationGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<PersonVerification>({
            path: '/person/{person_id}/electronicid/verification'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * approve the data from verification
     */
    personElectronicidVerificationPut = (requestParameters: PersonElectronicidVerificationPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personElectronicidVerificationPut');
        throwIfRequired(requestParameters, 'electronicid_verification_id', 'personElectronicidVerificationPut');
        throwIfRequired(requestParameters, 'ApprovedPersonVerification', 'personElectronicidVerificationPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/electronicid/verification/{electronicid_verification_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{electronicid_verification_id}', encodeURI(requestParameters.electronicid_verification_id)),
            method: 'PUT',
            headers,
            body: requestParameters.ApprovedPersonVerification,
        });
    };

    /**
     * get the eid sessions
     */
    personElectronicidVideoidFailedList = (requestParameters: PersonElectronicidVideoidFailedListRequest): Observable<Array<EidVideoidFailed>> => {
        throwIfRequired(requestParameters, 'person_id', 'personElectronicidVideoidFailedList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<EidVideoidFailed>>({
            path: '/person/{person_id}/electronicid/videoid_failed'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * get the eid sessions
     */
    personElectronicidWebhookNotificationList = (requestParameters: PersonElectronicidWebhookNotificationListRequest): Observable<Array<EidWebhookNotification>> => {
        throwIfRequired(requestParameters, 'person_id', 'personElectronicidWebhookNotificationList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<EidWebhookNotification>>({
            path: '/person/{person_id}/electronicid/webhook_notification'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * add a new emailaddress to an already created person.  this email address will receive a confirmation link. using this link one can confirm the email address.
     * add email address to person
     */
    personEmailAddressPost = (requestParameters: PersonEmailAddressPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personEmailAddressPost');
        throwIfRequired(requestParameters, 'NewEmailAddress', 'personEmailAddressPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/email_address/'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            headers,
            body: requestParameters.NewEmailAddress,
        });
    };

    /**
     * change email address of an existing person
     * change email address of person
     */
    personEmailAddressPut = (requestParameters: PersonEmailAddressPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personEmailAddressPut');
        throwIfRequired(requestParameters, 'NewEmailAddress', 'personEmailAddressPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/email_address/'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
            body: requestParameters.NewEmailAddress,
        });
    };

    /**
     * limit the validity of the connection person - emailaddress
     * address_delete
     */
    personEmailaddressDelete = (requestParameters: PersonEmailaddressDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personEmailaddressDelete');
        throwIfRequired(requestParameters, 'email_address_id', 'personEmailaddressDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/email_address/{email_address_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{email_address_id}', encodeURI(requestParameters.email_address_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get all emailaddresses of a person
     * get emailaddresses
     */
    personEmailaddressGet = (requestParameters: PersonEmailaddressGetRequest): Observable<Array<EmailAddress>> => {
        throwIfRequired(requestParameters, 'person_id', 'personEmailaddressGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.get_not_verified && { 'get_not_verified': requestParameters.get_not_verified }),
        };

        return this.request<Array<EmailAddress>>({
            path: '/person/{person_id}/email_address/'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * limit the validity of the connection person - face
     * delete a face
     */
    personFaceDelete = (requestParameters: PersonFaceDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personFaceDelete');
        throwIfRequired(requestParameters, 'face_id', 'personFaceDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/face/{face_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{face_id}', encodeURI(requestParameters.face_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get a specificv face image
     * get a face image
     */
    personFaceGet = (requestParameters: PersonFaceGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'personFaceGet');
        throwIfRequired(requestParameters, 'face_id', 'personFaceGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Blob>({
            path: '/person/{person_id}/face/{face_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{face_id}', encodeURI(requestParameters.face_id)),
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * get the current faceimage of a person
     * get the current face image
     */
    personFaceGetCurrent = (): Observable<Blob> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'qr': this.configuration.apiKey('qr') }), // qrAuth authentication
        };

        return this.request<Blob>({
            path: '/person/face/current',
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * get all faceimages of a person
     * get all face images
     */
    personFaceList = (requestParameters: PersonFaceListRequest): Observable<Array<Face>> => {
        throwIfRequired(requestParameters, 'person_id', 'personFaceList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Face>>({
            path: '/person/{person_id}/face'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * add a new face to an already created person
     * add face to person
     */
    personFacePost = (requestParameters: PersonFacePostRequest): Observable<FaceId> => {
        throwIfRequired(requestParameters, 'person_id', 'personFacePost');
        throwIfRequired(requestParameters, 'file', 'personFacePost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.file !== undefined) {
            formData.append('file', requestParameters.file as any);
        }

        return this.request<FaceId>({
            path: '/person/{person_id}/face'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * limit the validity of the connection person - fingerprint
     * delete a fingerprint
     */
    personFingerprintDelete = (requestParameters: PersonFingerprintDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personFingerprintDelete');
        throwIfRequired(requestParameters, 'fingerprint_id', 'personFingerprintDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/fingerprint/{fingerprint_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{fingerprint_id}', encodeURI(requestParameters.fingerprint_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get all fingerprints of a person
     * get all fingerprints
     */
    personFingerprintList = (requestParameters: PersonFingerprintListRequest): Observable<Array<Fingerprint>> => {
        throwIfRequired(requestParameters, 'person_id', 'personFingerprintList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Fingerprint>>({
            path: '/person/{person_id}/fingerprint'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * add a new fingerprint to an already created person
     * add fingerprint to person
     */
    personFingerprintPost = (requestParameters: PersonFingerprintPostRequest): Observable<FingerprintId> => {
        throwIfRequired(requestParameters, 'person_id', 'personFingerprintPost');
        throwIfRequired(requestParameters, 'NewFingerprint', 'personFingerprintPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'qr': this.configuration.apiKey('qr') }), // qrAuth authentication
        };

        return this.request<FingerprintId>({
            path: '/person/{person_id}/fingerprint'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            headers,
            body: requestParameters.NewFingerprint,
        });
    };

    /**
     * get the persons data using current credentials
     * get person data
     */
    personGet = (): Observable<Person> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'qr': this.configuration.apiKey('qr') }), // qrAuth authentication
        };

        return this.request<Person>({
            path: '/person',
            method: 'GET',
            headers,
        });
    };

    /**
     * get the complete history of a person
     */
    personHistoryGet = (requestParameters: PersonHistoryGetRequest): Observable<PersonHistory> => {
        throwIfRequired(requestParameters, 'person_id', 'personHistoryGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString() }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString() }),
        };

        return this.request<PersonHistory>({
            path: '/person/{person_id}/history'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list the documents, that were uploaded
     * list all merchant documents
     */
    personJuniorDocumentList = (requestParameters: PersonJuniorDocumentListRequest): Observable<Array<PersonDocument>> => {
        throwIfRequired(requestParameters, 'person_id', 'personJuniorDocumentList');
        throwIfRequired(requestParameters, 'companion_code', 'personJuniorDocumentList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.companion_code && { 'companion_code': requestParameters.companion_code }),
        };

        return this.request<Array<PersonDocument>>({
            path: '/person/{person_id}/junior_document/list'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get the complete kyc status history of a person
     */
    personKycHistoryGet = (requestParameters: PersonKycHistoryGetRequest): Observable<Array<KYCStatus>> => {
        throwIfRequired(requestParameters, 'person_id', 'personKycHistoryGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<KYCStatus>>({
            path: '/person/{person_id}/kyc_history'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * get the latest kyc status of a person
     */
    personKycStatusGet = (requestParameters: PersonKycStatusGetRequest): Observable<PersonKYCStatus> => {
        throwIfRequired(requestParameters, 'person_id', 'personKycStatusGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<PersonKYCStatus>({
            path: '/person/{person_id}/kyc_status'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * get the document, that was uploaded when upgrading the person to merchant
     * get merchant document
     */
    personMerchantDocumentGet = (requestParameters: PersonMerchantDocumentGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'personMerchantDocumentGet');
        throwIfRequired(requestParameters, 'person_document_id', 'personMerchantDocumentGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Blob>({
            path: '/person/{person_id}/merchant/document/{person_document_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{person_document_id}', encodeURI(requestParameters.person_document_id)),
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * list the documents, that were uploaded
     * list all merchant documents
     */
    personMerchantDocumentList = (requestParameters: PersonMerchantDocumentListRequest): Observable<Array<PersonDocument>> => {
        throwIfRequired(requestParameters, 'person_id', 'personMerchantDocumentList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<PersonDocument>>({
            path: '/person/{person_id}/merchant/document'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * set merchant status for person and the attached accounts
     * upgrade person to merchant
     */
    personMerchantPut = (requestParameters: PersonMerchantPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personMerchantPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.merchant_upgrade !== undefined) {
            formData.append('merchant_upgrade', requestParameters.merchant_upgrade as any);
        }

        if (requestParameters.document !== undefined) {
            formData.append('document', requestParameters.document as any);
        }

        if (requestParameters.document_2 !== undefined) {
            formData.append('document_2', requestParameters.document_2 as any);
        }

        if (requestParameters.document_3 !== undefined) {
            formData.append('document_3', requestParameters.document_3 as any);
        }

        if (requestParameters.document_4 !== undefined) {
            formData.append('document_4', requestParameters.document_4 as any);
        }

        if (requestParameters.document_5 !== undefined) {
            formData.append('document_5', requestParameters.document_5 as any);
        }

        if (requestParameters.document_6 !== undefined) {
            formData.append('document_6', requestParameters.document_6 as any);
        }

        if (requestParameters.document_7 !== undefined) {
            formData.append('document_7', requestParameters.document_7 as any);
        }

        if (requestParameters.document_8 !== undefined) {
            formData.append('document_8', requestParameters.document_8 as any);
        }

        if (requestParameters.document_9 !== undefined) {
            formData.append('document_9', requestParameters.document_9 as any);
        }

        if (requestParameters.document_10 !== undefined) {
            formData.append('document_10', requestParameters.document_10 as any);
        }

        return this.request<void>({
            path: '/person/{person_id}/merchant'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
            body: formData,
        });
    };

    /**
     * get all passports of a person
     * get passports
     */
    personPassportDocumentPut = (requestParameters: PersonPassportDocumentPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personPassportDocumentPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.front !== undefined) {
            formData.append('front', requestParameters.front as any);
        }

        if (requestParameters.back !== undefined) {
            formData.append('back', requestParameters.back as any);
        }

        if (requestParameters.stored !== undefined) {
            formData.append('stored', requestParameters.stored as any);
        }

        return this.request<void>({
            path: '/person/{person_id}/passport/document'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
            body: formData,
        });
    };

    /**
     * get all passports of a person
     * get passports
     */
    personPassportGet = (requestParameters: PersonPassportGetRequest): Observable<Array<Passport>> => {
        throwIfRequired(requestParameters, 'person_id', 'personPassportGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Passport>>({
            path: '/person/{person_id}/passport'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * get the passports back image
     * get the passports back image
     */
    personPassportPassportBackGet = (requestParameters: PersonPassportPassportBackGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'personPassportPassportBackGet');
        throwIfRequired(requestParameters, 'passport_id', 'personPassportPassportBackGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Blob>({
            path: '/person/{person_id}/passport/{passport_id}/passport_back'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{passport_id}', encodeURI(requestParameters.passport_id)),
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * get the passports front image
     * get the passports front image
     */
    personPassportPassportFrontGet = (requestParameters: PersonPassportPassportFrontGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'personPassportPassportFrontGet');
        throwIfRequired(requestParameters, 'passport_id', 'personPassportPassportFrontGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Blob>({
            path: '/person/{person_id}/passport/{passport_id}/passport_front'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{passport_id}', encodeURI(requestParameters.passport_id)),
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * get a specificv face image
     * get a face image
     */
    personPassportStoredImageGet = (requestParameters: PersonPassportStoredImageGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'personPassportStoredImageGet');
        throwIfRequired(requestParameters, 'passport_id', 'personPassportStoredImageGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Blob>({
            path: '/person/{person_id}/passport/{passport_id}/stored_image'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{passport_id}', encodeURI(requestParameters.passport_id)),
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * resets a persons password
     * reset password
     */
    personPasswordReset = (requestParameters: PersonPasswordResetRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personPasswordReset');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/password_reset'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
        });
    };

    /**
     * deactivate an access key
     */
    personPersonIdAccessKeyDelete = (requestParameters: PersonPersonIdAccessKeyDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personPersonIdAccessKeyDelete');
        throwIfRequired(requestParameters, 'access_key', 'personPersonIdAccessKeyDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.access_key && { 'access_key': requestParameters.access_key }),
        };

        return this.request<void>({
            path: '/person/{person_id}/access_key'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * get issued access key
     */
    personPersonIdAccessKeyGet = (requestParameters: PersonPersonIdAccessKeyGetRequest): Observable<Array<AccessKey>> => {
        throwIfRequired(requestParameters, 'person_id', 'personPersonIdAccessKeyGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.show_deactivated && { 'show_deactivated': requestParameters.show_deactivated }),
        };

        return this.request<Array<AccessKey>>({
            path: '/person/{person_id}/access_key'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * please note that the only time the only time the secret_key is shown is in this response
     * create an access key for an account
     */
    personPersonIdAccessKeyPost = (requestParameters: PersonPersonIdAccessKeyPostRequest): Observable<AccessKeyWithPassword> => {
        throwIfRequired(requestParameters, 'person_id', 'personPersonIdAccessKeyPost');
        throwIfRequired(requestParameters, 'account_number', 'personPersonIdAccessKeyPost');
        throwIfRequired(requestParameters, 'token', 'personPersonIdAccessKeyPost');
        throwIfRequired(requestParameters, 'name', 'personPersonIdAccessKeyPost');
        throwIfRequired(requestParameters, 'qr', 'personPersonIdAccessKeyPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.token && { 'token': requestParameters.token }),
            ...(requestParameters.name && { 'name': requestParameters.name }),
            ...(requestParameters.qr && { 'qr': requestParameters.qr }),
        };

        return this.request<AccessKeyWithPassword>({
            path: '/person/{person_id}/access_key/{account_number}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * add a new address to an already created person
     * add address to person
     */
    personPersonIdAddressRequirement = (requestParameters: PersonPersonIdAddressRequirementRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personPersonIdAddressRequirement');
        throwIfRequired(requestParameters, 'NewAddressRequirment', 'personPersonIdAddressRequirement');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/address_requirement'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            headers,
            body: requestParameters.NewAddressRequirment,
        });
    };

    /**
     * get data of a third person
     * get person data
     */
    personPersonIdGet = (requestParameters: PersonPersonIdGetRequest): Observable<Person> => {
        throwIfRequired(requestParameters, 'person_id', 'personPersonIdGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Person>({
            path: '/person/{person_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * create referral link. if a referral product is given, it will be used as well
     * create referral link
     */
    personPersonIdReferralLinkPost = (requestParameters: PersonPersonIdReferralLinkPostRequest): Observable<ReferralLink> => {
        throwIfRequired(requestParameters, 'person_id', 'personPersonIdReferralLinkPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<ReferralLink>({
            path: '/person/{person_id}/referral_link'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            headers,
        });
    };

    /**
     * limit the validity of the connection person - phone
     * delete phone
     */
    personPhoneDelete = (requestParameters: PersonPhoneDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personPhoneDelete');
        throwIfRequired(requestParameters, 'phone_number_id', 'personPhoneDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/phone/{phone_number_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{phone_number_id}', encodeURI(requestParameters.phone_number_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get all phone numbers of a person
     * get phone numbers
     */
    personPhoneList = (requestParameters: PersonPhoneListRequest): Observable<Array<Phone>> => {
        throwIfRequired(requestParameters, 'person_id', 'personPhoneList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Phone>>({
            path: '/person/{person_id}/phone'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * add a new phone to an already created person. please note: only mobile phone number will be accepted and the phone number needs to be verified through the sms code sent to it
     * add phone to person
     */
    personPhonePost = (requestParameters: PersonPhonePostRequest): Observable<PhoneId> => {
        throwIfRequired(requestParameters, 'person_id', 'personPhonePost');
        throwIfRequired(requestParameters, 'NewPhone', 'personPhonePost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<PhoneId>({
            path: '/person/{person_id}/phone'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            headers,
            body: requestParameters.NewPhone,
        });
    };

    /**
     * updates a persons pin code to a given number
     * set pin
     */
    personPinPost = (requestParameters: PersonPinPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personPinPost');
        throwIfRequired(requestParameters, 'Pin', 'personPinPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/pin'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
            body: requestParameters.Pin,
        });
    };

    /**
     * change other persons data
     * change other persons data
     */
    personPut = (requestParameters: PersonPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personPut');
        throwIfRequired(requestParameters, 'PersonUpdateRequest', 'personPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
            body: requestParameters.PersonUpdateRequest,
        });
    };

    /**
     * Get the reason for account closure
     * Get closure reason
     */
    personReasonForClosureGet = (requestParameters: PersonReasonForClosureGetRequest): Observable<Closure> => {
        throwIfRequired(requestParameters, 'person_id', 'personReasonForClosureGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Closure>({
            path: '/person/{person_id}/reason_for_closure'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * in case a new incompatible abis version has been deployed, the current data can be recoded and sent to abis
     * recode biometric data
     */
    personRecode = (requestParameters: PersonRecodeRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personRecode');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/recode'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            headers,
        });
    };

    /**
     * create referral link. if a referral product is given, it will be used as well
     * create referral link
     */
    personReferralLinkPost = (requestParameters: PersonReferralLinkPostRequest): Observable<ReferralLink> => {
        throwIfRequired(requestParameters, 'ReferralProductId', 'personReferralLinkPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<ReferralLink>({
            path: '/person/referral_link',
            method: 'POST',
            headers,
            body: requestParameters.ReferralProductId,
        });
    };

    /**
     * a list of possible referral products, that my be used to create a referral link, this list may be empty!
     * returns valid referral products for the current person
     */
    personReferralProductsGet = (): Observable<Array<ReferralProduct>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ReferralProduct>>({
            path: '/person/referral_products',
            method: 'GET',
            headers,
        });
    };

    /**
     * get the complete kyc status history of a person
     */
    personRequestAdvancedKycGet = (requestParameters: PersonRequestAdvancedKycGetRequest): Observable<Array<AdvancedKYCDataDoc>> => {
        throwIfRequired(requestParameters, 'person_id', 'personRequestAdvancedKycGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<AdvancedKYCDataDoc>>({
            path: '/person/{person_id}/request_advanced_kyc'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * request new kyc-process from customer
     */
    personRequestAdvancedKycPost = (requestParameters: PersonRequestAdvancedKycPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personRequestAdvancedKycPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.advanced_kyc_data !== undefined) {
            formData.append('advanced_kyc_data', requestParameters.advanced_kyc_data as any);
        }

        if (requestParameters.document !== undefined) {
            formData.append('document', requestParameters.document as any);
        }

        return this.request<void>({
            path: '/person/{person_id}/request_advanced_kyc'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * request new kyc-process from customer
     */
    personRequestKycPut = (requestParameters: PersonRequestKycPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personRequestKycPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/request_kyc'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
        });
    };

    /**
     * in case biometric data gets out of sync, e.g. due to a new empty abis got deployed, biometric data can be sent to abis using this method
     * resync biometric data
     */
    personResync = (requestParameters: PersonResyncRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personResync');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/resync'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            headers,
        });
    };

    /**
     * search for a person, all parameters given will be considered in the search. searches for name or given name use double metaphone to find different spellings of the same word. please note that this is not an exact search, so there might be more results than expected. also we split name and given name by space and minus and index them separate. so if a customer is named Hugo-Egon the following searches will find him, \'hugo\',\'egon\', \'hugo-egon\',\'egon hugo\',...     please note that the result is currently sorted by person_id not by \"match quality\", that may change in the future.
     * search by persons by their attributes.
     */
    personSearch = (requestParameters: any): Observable<Array<PersonFound>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'qr': this.configuration.apiKey('qr') }), // qrAuth authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.given_name && { 'given_name': requestParameters.given_name }),
            ...(requestParameters.name && { 'name': requestParameters.name }),
            ...(requestParameters.email_address && { 'email_address': requestParameters.email_address }),
            ...(requestParameters.search_id && { 'search_id': requestParameters.search_id }),
            ...(requestParameters.id_type && { 'id_type': requestParameters.id_type }),
            ...(requestParameters.birth_date && { 'birth_date': (requestParameters.birth_date as any).toISOString().substring(0, 10) }),
            ...(requestParameters.street && { 'street': requestParameters.street }),
            ...(requestParameters.building_identifier && { 'building_identifier': requestParameters.building_identifier }),
            ...(requestParameters.city && { 'city': requestParameters.city }),
            ...(requestParameters.zip && { 'zip': requestParameters.zip }),
            ...(requestParameters.phone_number && { 'phone_number': requestParameters.phone_number }),
            ...(requestParameters.show_history && { 'show_history': requestParameters.show_history }),
            ...(requestParameters.limit && { 'limit': requestParameters.limit }),
            ...(requestParameters.offset && { 'offset': requestParameters.offset }),
            ...(requestParameters.preset && { 'preset': requestParameters.preset }),
        };

        return this.request<Array<PersonFound>>({
            path: '/persons/search',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Get current settings for selected person
     * Get settings
     */
    personSettingsGet = (requestParameters: PersonSettingsGetRequest): Observable<Settings> => {
        throwIfRequired(requestParameters, 'person_id', 'personSettingsGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Settings>({
            path: '/person/{person_id}/settings'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Set current settings for selected person
     * replace setting
     */
    personSettingsPut = (requestParameters: PersonSettingsPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personSettingsPut');
        throwIfRequired(requestParameters, 'Settings', 'personSettingsPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/{person_id}/settings'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
            body: requestParameters.Settings,
        });
    };

    /**
     * list the documents, that were uploaded
     * list all merchant documents
     */
    toBeRemovedPersonDocumentList = (requestParameters: ToBeRemovedPersonDocumentListRequest): Observable<Array<PersonDocument>> => {
        throwIfRequired(requestParameters, 'person_id', 'toBeRemovedPersonDocumentList');
        throwIfRequired(requestParameters, 'document_type', 'toBeRemovedPersonDocumentList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.document_type && { 'document_type': requestParameters.document_type }),
        };

        return this.request<Array<PersonDocument>>({
            path: '/person/{person_id}/document/list'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
            query,
        });
    };


    operationToOperationId = {
	    "lookupExternalIdTypes": "lookup_external_id_types" ,
	    "lookupOpenAddressApprovalList": "lookup_open_address_approval_list" ,
	    "lookupProductUserClassGet": "lookup_product_user_class_get" ,
	    "personAddressDelete": "person_address_delete" ,
	    "personAddressGet": "person_address_get" ,
	    "personAddressPost": "person_address_post" ,
	    "personAddressPut": "person_address_put" ,
	    "personCompanionCodeList": "person_companion_code_list" ,
	    "personCompanionList": "person_companion_list" ,
	    "personCurrentFaceGet": "person_current_face_get" ,
	    "personDelete": "person_delete" ,
	    "personDocumentGet": "person_document_get" ,
	    "personDocumentList": "person_document_list" ,
	    "personDocumentPut": "person_document_put" ,
	    "personElectronicidPassportPut": "person_electronicid_passport_put" ,
	    "personElectronicidRequestList": "person_electronicid_request_list" ,
	    "personElectronicidVerificationBackGet": "person_electronicid_verification_back_get" ,
	    "personElectronicidVerificationFaceGet": "person_electronicid_verification_face_get" ,
	    "personElectronicidVerificationFrontGet": "person_electronicid_verification_front_get" ,
	    "personElectronicidVerificationGet": "person_electronicid_verification_get" ,
	    "personElectronicidVerificationPut": "person_electronicid_verification_put" ,
	    "personElectronicidVideoidFailedList": "person_electronicid_videoid_failed_list" ,
	    "personElectronicidWebhookNotificationList": "person_electronicid_webhook_notification_list" ,
	    "personEmailAddressPost": "person_email_address_post" ,
	    "personEmailAddressPut": "person_email_address_put" ,
	    "personEmailaddressDelete": "person_emailaddress_delete" ,
	    "personEmailaddressGet": "person_emailaddress_get" ,
	    "personFaceDelete": "person_face_delete" ,
	    "personFaceGet": "person_face_get" ,
	    "personFaceGetCurrent": "person_face_get_current" ,
	    "personFaceList": "person_face_list" ,
	    "personFacePost": "person_face_post" ,
	    "personFingerprintDelete": "person_fingerprint_delete" ,
	    "personFingerprintList": "person_fingerprint_list" ,
	    "personFingerprintPost": "person_fingerprint_post" ,
	    "personGet": "person_get" ,
	    "personHistoryGet": "person_history_get" ,
	    "personJuniorDocumentList": "person_junior_document_list" ,
	    "personKycHistoryGet": "person_kyc_history_get" ,
	    "personKycStatusGet": "person_kyc_status_get" ,
	    "personMerchantDocumentGet": "person_merchant_document_get" ,
	    "personMerchantDocumentList": "person_merchant_document_list" ,
	    "personMerchantPut": "person_merchant_put" ,
	    "personPassportDocumentPut": "person_passport_document_put" ,
	    "personPassportGet": "person_passport_get" ,
	    "personPassportPassportBackGet": "person_passport_passport_back_get" ,
	    "personPassportPassportFrontGet": "person_passport_passport_front_get" ,
	    "personPassportStoredImageGet": "person_passport_stored_image_get" ,
	    "personPasswordReset": "person_password_reset" ,
	    "personPersonIdAccessKeyDelete": "person_person_id_access_key_delete" ,
	    "personPersonIdAccessKeyGet": "person_person_id_access_key_get" ,
	    "personPersonIdAccessKeyPost": "person_person_id_access_key_post" ,
	    "personPersonIdAddressRequirement": "person_person_id_address_requirement" ,
	    "personPersonIdGet": "person_person_id_get" ,
	    "personPersonIdReferralLinkPost": "person_person_id_referral_link_post" ,
	    "personPhoneDelete": "person_phone_delete" ,
	    "personPhoneList": "person_phone_list" ,
	    "personPhonePost": "person_phone_post" ,
	    "personPinPost": "person_pin_post" ,
	    "personPut": "person_put" ,
	    "personReasonForClosureGet": "person_reason_for_closure_get" ,
	    "personRecode": "person_recode" ,
	    "personReferralLinkPost": "person_referral_link_post" ,
	    "personReferralProductsGet": "person_referral_products_get" ,
	    "personRequestAdvancedKycGet": "person_request_advanced_kyc_get" ,
	    "personRequestAdvancedKycPost": "person_request_advanced_kyc_post" ,
	    "personRequestKycPut": "person_request_kyc_put" ,
	    "personResync": "person_resync" ,
	    "personSearch": "person_search" ,
	    "personSettingsGet": "person_settings_get" ,
	    "personSettingsPut": "person_settings_put" ,
	    "toBeRemovedPersonDocumentList": "to_be_removed_person_document_list"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum PersonDeleteReasonEnum {
    ClientRequest = 'ClientRequest',
    AMLTechnicalError = 'AMLTechnicalError',
    Other3rdPartyRequest = 'Other3rdPartyRequest',
    ImmediateClosure = 'ImmediateClosure'
}
/**
 * @export
 * @enum {string}
 */
export enum PersonSearchPresetEnum {
    Standard = 'standard',
    Last25 = 'last25'
}
