// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AccountDetailBlockReasons,
    AccountDetailTxLimits,
    Iso4217Currency,
} from './';

/**
 * @export
 * @interface AccountDetail
 */
export interface AccountDetail {
    /**
     * @type {string}
     * @memberof AccountDetail
     */
    account_number: string;
    /**
     * @type {string}
     * @memberof AccountDetail
     */
    name: string;
    /**
     * @type {Iso4217Currency}
     * @memberof AccountDetail
     */
    currency: Iso4217Currency;
    /**
     * values > * A - Active * B - Blocked * C - Closed
     * @type {string}
     * @memberof AccountDetail
     */
    state: AccountDetailStateEnum;
    /**
     * date and time the last state change happened
     * @type {Date}
     * @memberof AccountDetail
     */
    state_change_date: Date;
    /**
     * the accounts iban, if there is one
     * @type {string}
     * @memberof AccountDetail
     */
    iban?: string;
    /**
     * alphanumeric name of an internal account
     * @type {string}
     * @memberof AccountDetail
     */
    memo?: string;
    /**
     * the applicable interest rates in percent. >as there may be many, one number is not sufficient...
     * @type {number}
     * @memberof AccountDetail
     */
    interest_rate?: number;
    /**
     * account opening date and time
     * @type {Date}
     * @memberof AccountDetail
     */
    opening_date: Date;
    /**
     * account opening date and time
     * @type {Date}
     * @memberof AccountDetail
     */
    last_transaction?: Date;
    /**
     * custom account name set by the end user
     * @type {string}
     * @memberof AccountDetail
     */
    custom_name?: string;
    /**
     * @type {Array<AccountDetailTxLimits>}
     * @memberof AccountDetail
     */
    tx_limits?: Array<AccountDetailTxLimits>;
    /**
     * @type {Array<AccountDetailBlockReasons>}
     * @memberof AccountDetail
     */
    block_reasons?: Array<AccountDetailBlockReasons>;
    account_type?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AccountDetailStateEnum {
    A = 'A',
    B = 'B',
    C = 'C'
}

