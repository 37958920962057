//@ts-nocheck
import React from 'react';
import styled from 'styled-components';
import {
	IInitProps,
	IInitState,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import {
	IStatusState, Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { INotifyAbleCustomerData, CustomerDataStore } from '../../../../logic/flux';
import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Icons } from '../../../../images';
import { OverlayHandler, Overlays } from '../../../../logic/handler/overlayhandler/overlayHandler';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import Title from '../../../../components/compositcomponents/title';
import { StyledDivHeader, StyledLine } from '../basicStyledComponents/customersComponent.css';
import { StyledBox, StyledInputView, StyledSmallInput } from './basicStyledComponents/customerDetails.css';
import { SyntheticEvent } from 'react';
import { IOption } from '../../../../components/atomiccompoents/form/select';


interface IProps extends IInitProps {
    personId?: number;
    callback: (path: string) => void;
}
interface IState extends IStatusState, IInitState {
   primanota: string;
   keyForLoadingSpinner: number;
   searchType: SearchTypeEnum
}

enum SearchTypeEnum  {
    Primanota,
    Barcode
}

const StyledContentBox = styled(FlexBox)`
	flex-direction: column;
	padding: 0px 0px;
`;
export default class CustomerTransactionSearch extends React.Component<IProps, IState>
	implements INotifyAbleCustomerData {
	constructor(props: IProps) {
		super(props);

		this.state = {
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            primanota: "",
            searchType: SearchTypeEnum.Primanota
		};

        this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
	}

	_onChangeCustomerData() {
		const tempUser = CustomerDataStore.getUser();
        const tempAcc = CustomerDataStore.getCurrentAccount();
        if(tempAcc != null) {
            
        }
    }
    
    componentDidMount() {
        CustomerDataStore.addChangeListener(this._onChangeCustomerData);
        this._onChangeCustomerData();
    }

    componentWillUnmount() {
        CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
    }

    onSubmit(event: SyntheticEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
        })
        if(this.state.primanota == null || this.state.primanota === "" || this.state.primanota.indexOf("/") < 0) {
            MessageHandler.onError(Reporter['transaction.search.primanota']);
        } else {
            const split = this.state.primanota.split("/");
            if(split.length !== 2) {
                MessageHandler.onError(Reporter['transaction.search.primanota']);
                return;
            }
            const ta_id = parseInt(split[0]);
            const transId = parseInt(split[1]);
            if(isNaN(ta_id) || isNaN(transId)) {
                MessageHandler.onError(Reporter['transaction.search.primanota']);
                return;
            }
            OverlayHandler.showOverlay(Overlays.postingJournal, {taId: ta_id, transactionId: transId, openedFromSearch: true, callback: this.props.callback, boCallback: this.props.callback});
        }
    }
    getTypeOptions(): Array<IOption> {
		const options: Array<IOption> = [];
        options.push(
            {
                key: 'defaultOptionKey' + 'primanota',
                name: 'Primanota',
                value: SearchTypeEnum.Primanota,
            },
        );
        options.push(
            {
                key: 'defaultOptionKey' + 'barcode',
                name: 'Barcode',
                value: SearchTypeEnum.Barcode,
            },
        )
		return options;
    }
    
    handleTypeSelect(message: any) {
		this.setState({
			searchType: message.search_type,
		});
	}


	render() {
		return (
			<StyledContentBox>
                <StyledBox >
                    <StyledDivHeader>
                        {Icons.searchCustomer()}
                        <Title
                            title={translate('backoffice.search.searchTrans')}
                        />
                    </StyledDivHeader>
                    <StyledLine />
                    <StyledInputView style={{maxWidth: '50%'}}>                   
                            <StyledSmallInput
                                placeHolder={translate(
                                    'backoffice.search.input.primanota.placeholder'
                                )}
                                label={translate('backoffice.search.input.primanota.label')}
                                type="text"
                                id="primanota"
                                onSubmit={this.onSubmit}
                                valueFromState={true}                                
                                value={this.state.primanota != null ? this.state.primanota : ''}
                                onChange={(value: string) => {
                                    this.setState({
                                        primanota: value
                                    })
                                }}
                            />
                    {/*}        <NoMaxWidthSelect
                                helptext={translate('backoffice.search.input.barcode.label')}
                                id="search_type"
                                options={this.getTypeOptions()}
                                notification={this.handleTypeSelect}
                            />*/}
                        </StyledInputView>
                    <div style={{display: 'flex', flexDirection:'row', marginLeft: 'calc(50% - 203px)'}}>
                                <div style={{ display: 'inline-block', position: 'fixed', bottom:'90px'}}>
                                    <ButtonOk
                                        key={this.state.keyForLoadingSpinner}
                                        onClick={this.onSubmit}
                                        id="btnSearchTrans">
                                        {translate('button.search')}
                                    </ButtonOk>
                                </div>
                            </div>
				</StyledBox>
			</StyledContentBox>
		);
	}
}


