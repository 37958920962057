// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface NewAddressRequirment
 */
export interface NewAddressRequirment {
    /**
     * @type {number}
     * @memberof NewAddressRequirment
     */
    address_id?: number;
    /**
     * @type {string}
     * @memberof NewAddressRequirment
     */
    requirement?: NewAddressRequirmentRequirementEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum NewAddressRequirmentRequirementEnum {
    TypeOfDocumentSubmittedDoesNotMeetTheMinimumRequirements = 'The type of document submitted does not meet the minimum requirements',
    DocumentSentHasExpired = 'The document sent has expired',
    ImageOfTheDocumentSentIsTooBlurryNotVisible = 'The image of the document sent is too blurry – not visible',
    DocumentSentDoesNotContainYourName = 'The document sent does not contain your name',
    AddressInTheAttachedDocumentDoesNotConfirmTheAddressYouReported = 'The address in the attached document does not confirm the address you reported',
    DocumentIsNotCompleteImageIsCroppedOrTheIDIsNotScannedBothSides = 'The document is not complete – image is cropped, or the ID is not scanned both sides'
}

