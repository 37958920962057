import React from 'react';
import { DeveloperStore } from '../../../logic/flux/stores/DeveloperStore';
import styled from 'styled-components';
import CopyButton from '../../../components/atomiccompoents/buttons/copyButton';
import { INotifyAbleDeveloperData } from '../../../logic/flux/notifyAbles/notifyAbleDeveloperData';

interface IProps {}

interface IState {
	deviceToken?: string;
}

export default class DeveloperVariables extends React.Component<IProps, IState>
	implements INotifyAbleDeveloperData {
	constructor(props: IProps) {
		super(props);

		this.state = {};
		this._onChangeDeveloperData = this._onChangeDeveloperData.bind(this);
	}

	_onChangeDeveloperData(): void {
		this.setState({
			deviceToken: DeveloperStore.getDeviceToken(),
		});
	}

	componentDidMount(): void {
		DeveloperStore.addChangeListener(this._onChangeDeveloperData);
		this._onChangeDeveloperData();
	}

	componentWillUnmount(): void {
		DeveloperStore.removeChangeListener(this._onChangeDeveloperData);
	}

	render() {
		return (
			<div>
				<h2>Variables</h2>

				<StyledTable>
					<thead>
						<tr>
							<td valign="top">Variable</td>
							<td valign="top">Value</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>FCM device token</td>
							<CopyCell>
								<span>{this.state.deviceToken}</span>
								<CopyButton text={this.state.deviceToken} />
							</CopyCell>
						</tr>
					</tbody>
				</StyledTable>
			</div>
		);
	}
}

const StyledTable = styled.table`
	width: 100%;
	max-width: 100%;
	border: 1px solid black;
	border-collapse: collapse;
	table-layout: fixed;

	td {
		border: 1px solid black;
		padding: 6px;
		vertical-align: top;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

const CopyCell = styled.td`
	& > span {
		display: inline-block;
		width: calc(100% - 18px);
		overflow-x: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;
