// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RegisteredCustomer
 */
export interface RegisteredCustomer {
    /**
     * name of the merchant
     * @type {string}
     * @memberof RegisteredCustomer
     */
    name?: string;
    /**
     * name of the merchant
     * @type {string}
     * @memberof RegisteredCustomer
     */
    given_name?: string;
    /**
     * the email address
     * @type {string}
     * @memberof RegisteredCustomer
     */
    email_address?: string;
    /**
     * timestamp the merchant was created
     * @type {Date}
     * @memberof RegisteredCustomer
     */
    datetime_create?: Date;
    /**
     * values > * A - Active * L - Locked * C - Closed
     * @type {string}
     * @memberof RegisteredCustomer
     */
    state?: RegisteredCustomerStateEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RegisteredCustomerStateEnum {
    A = 'A',
    L = 'L',
    C = 'C'
}

