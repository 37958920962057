// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface PersonDocumentListPersonDocuments
 */
export interface PersonDocumentListPersonDocuments {
    /**
     * @type {number}
     * @memberof PersonDocumentListPersonDocuments
     */
    person_document_id?: number;
    /**
     * @type {Date}
     * @memberof PersonDocumentListPersonDocuments
     */
    datetime_create?: Date;
    /**
     * @type {number}
     * @memberof PersonDocumentListPersonDocuments
     */
    person_id_create?: number;
    /**
     * @type {Date}
     * @memberof PersonDocumentListPersonDocuments
     */
    datetime_delete?: Date;
    /**
     * @type {number}
     * @memberof PersonDocumentListPersonDocuments
     */
    person_id_delete?: number;
    /**
     * @type {string}
     * @memberof PersonDocumentListPersonDocuments
     */
    record_state?: PersonDocumentListPersonDocumentsRecordStateEnum;
    /**
     * @type {string}
     * @memberof PersonDocumentListPersonDocuments
     */
    content_type?: string;
    /**
     * @type {string}
     * @memberof PersonDocumentListPersonDocuments
     */
    filename?: string;
    /**
     * @type {string}
     * @memberof PersonDocumentListPersonDocuments
     */
    document_type?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PersonDocumentListPersonDocumentsRecordStateEnum {
    A = 'A',
    D = 'D'
}

