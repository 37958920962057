import styled from 'styled-components';
import { Box } from '../../../../components/atomiccompoents/boxes/box';
import { BigInput } from '../../../../components/atomiccompoents/form/inputs.css';

export const TableBox = styled(Box)`
	z-index: -1;
	border: none;
	box-shadow: none;
	& > div > div {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}


	& > div > div > div:nth-child(2) {
		font-family: Roboto;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: ${(props) => props.theme.Global.lightFontColor};
		margin-right: 26px;
		white-space: nowrap;

		svg {
			margin-left: 6px;
		}
	}

	& > div > div > div:nth-child(3) {
		input {
			min-width: 10%;
			border-width: 0;
		}
	}
`;
export const Header = styled.div`
	font-family: Roboto;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-transform: uppercase;
	color: ${props => props.theme.Global.keyFontColor};
	flex-grow: 1;
`;
export const StyledInput = styled(BigInput)``;

export const StyledHr = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #e8ecef;
	width: 100%;
`;

export const Container = styled.div`
    display: flex,
    flex-direction: column,
    justify-content: space-evenly,
    text-overflow: ellipsis;
`;

export const ContainerText = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0);
`;
export const ContainerTextNumber = styled(ContainerText)`
	text-align: right;
`;

const TableView = styled.div`
`;

export const TransactionTableView = styled(TableView)<{ isBackofficeScreen: boolean, tableHeightExpand:boolean }>`
	max-height: calc(100vh - 300px);
	overflow: auto;
	height: ${props => props.tableHeightExpand ? 'calc(100vh - 588px)' : 'calc(100vh - 432px)'};
	& > div > div > table {
		table-layout: auto;
		overflow-x: hidden; 
	}
`;

export const PendingTransactionTableView = styled(TableView)`
	max-height: calc(100vh - 700px);
	overflow: auto;
`;

export const PendingTableView = styled.div`
	z-index: -1;

	& > div {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

	& > div > div:nth-child(2) {
		font-family: Roboto;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: ${(props) => props.theme.Global.lightFontColor};
		margin-right: 26px;
		white-space: nowrap;

		svg {
			margin-left: 6px;
		}
	}

	& > div > div:nth-child(3) {
		width: 25%;
		input {
			min-width: 200px;
		}
	}
`;

export const FileChooserStyle: React.CSSProperties = {
	borderRadius: '50%',
	height: '100%',
	width: '100%',
	marginTop: '16px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

