import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { theme } from '../../../../common/theme';
import {
	IStatusState,
	IMessageConfig,
	Reporter,
	getFormComponents,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { IItfAccount } from '../../../../logic/types';
import { Ident, api, apis, ApiError, VoidResponse } from '../../../../logic/api';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { translate } from '../../../../common/language/translate';
import { format } from '../../../../logic/helper/format';
import {
	IInitProps,
	IInitState,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import {
	CustomerDataStore,
	Actions,
	PermissionStore,
} from '../../../../logic/flux';
import { InitComponentHandler } from '../../../../logic/handler/initialdatahandler/InitComponentHandler';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import ButtonCancel from '../../../../components/atomiccompoents/buttons/buttonCancel';
import { EditableWrapper, KeyField } from './basicStyledComponents/customerDetails.css';
import Switch from '../../../../components/atomiccompoents/switch';
import { FlexBox } from '../../../auth/auth.css';
import { compareObject, downloadFile, evaluateErrorMessage, getCountryOptions, updateAccount, updatePerson } from '../../../../logic/helper/Common';
import { Log, Logs } from '../../../../logic/log';
import { ClientConfig } from '../../../../common/config';
import { withPersonAndAccount } from '../../../../components/hocs/withPersonAndAccount';
import Title from '../../../../components/compositcomponents/title';
import EditableComponent from '../../../../components/atomiccompoents/editableComponent/editableComponent';
import KeyDateComponent from '../../../../components/atomiccompoents/keyDateComponent';
import KeySelectComponent from '../../../../components/atomiccompoents/keySelectComponent';
import { CompanionCodeDetail, CountryCode } from '../../../../logic/api/ident';
import Expandable from '../../../../components/compositcomponents/expandable/expandable';
import ImageBox, { IImageBoxElement } from '../../../../components/compositcomponents/imageBox';


const WrapperOuter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
interface IJuniorDetails extends Ident.CompanionCodeDetail {
	turns18?: Date;
	date_of_birth: Date;
}
interface IAccountDetailsProps extends IInitProps {
    person?: Ident.Person;
    currentAccount?: IItfAccount;
}

interface IState extends IStatusState, IInitState {
	persons?: Array<IJuniorDetails>;
	images?:Array<Array<Ident.PersonDocument>>;
}

class IJuniorAccountData extends React.Component<IAccountDetailsProps, IState> {

	constructor(props: IAccountDetailsProps) {
		super(props);

		const n: IState = {
		}
		this.state = n;

		
		this._onChangeCustomerData();
	}

	_onChangeCustomerData() {
		this.getJuniorData();
	}

	getJuniorData() {
        if(this.props.person == null) {
            return;
        }
        const req = {
            person_id: this.props.person.person_id
        }
        api.asyncRequest<any>(
            req,
            apis.MaintenanceApi,
            'personCompanionCodeList')
            .then((response: Array<Ident.CompanionCodes>) => {
				const promisesData = [];
				const promisesImg  = [];
				const persons = [];
				const imgs    = [];
				if(response == null || response.length < 1) {
					return;
				}	
               for(const o of response) {
				 promisesData.push(
					api.asyncRequest<CompanionCodeDetail>(
						{companion_code: o.companion_code},
						apis.OpenIDConnectApi,
						'oauthCompanionCodeDetail')
				 )
				 promisesImg.push(
					api.asyncRequest<Array<Ident.PersonDocument>>(
						{companion_code: o.companion_code, person_id: this.props.person.person_id},
						apis.MaintenanceApi,
						'personJuniorDocumentList')
				 );
				 Promise.allSettled(promisesData).then((res: Array<PromiseSettledResult<Ident.CompanionCodeDetail>>) => {
					
					for(const o of res) {
						if(o.status === 'fulfilled') {
							const fullval = o.value as IJuniorDetails;
							if(fullval.date_of_birth != null) {
								const fullyeardate = new Date(fullval.date_of_birth);
								fullyeardate.setFullYear(fullyeardate.getFullYear() + 18);
								fullval.turns18 = fullyeardate;								
							}
							persons.push(o.value);
						} else {
							persons.push({})
						}
					}
					this.setState({
						persons: persons,
					 })
				 })
				 Promise.allSettled(promisesImg).then((res: Array<PromiseSettledResult<Array<Ident.PersonDocument>>>) => {
					
					for(const o of res) {
						if(o.status === 'fulfilled') {
							imgs.push(o.value);
						}
						else {
							imgs.push({})
						}
					}
					this.setState( {
						images: imgs
					})
				 });
			   }
			}).catch((error: ApiError) => {
				Log.error(Logs.API, error);
			});
	}

	generateImages(index:number) {
		const imgs = this.state.images[index];
		const out: Array<IImageBoxElement> = [];
		if(imgs == null || imgs.length < 1) {
			return out;
		}
		for(const o of imgs) {
			if(o.person_document_id == null) {
				continue;
			}
			out.push(
				{ 	url: 	`/ident/person/${this.props.person.person_id}/document/${o.person_document_id}`,
					title:	o.filename
				}
			)
		}
		return out;
	}



	componentDidUpdate(prevProps: IAccountDetailsProps) {
		if (!compareObject(prevProps, this.props)) {
		  this._onChangeCustomerData();
		}	  
	  }

	  onEdit(index: number, value: string, key?: string) {
		const persons = this.state.persons;
		persons[index][key] = value;
		this.setState({
			persons: persons
		})
	  }

	  renderData() {
		const out = [];
		this.state.persons.forEach((person: IJuniorDetails, index: number) => {
			if(person.person_id == null) {
				return;
			}
			out.push (
				<Expandable title={person.given_name + " " + person.name} open={index === 0}>
					<WrapperOuter>
						<EditableWrapper>
							<EditableComponent
								translationkey='customers.name'
								id="name"
								initText={person.name ?? ''}
								changeCallback={(value: string, key: string) => {
									this.onEdit(index, value, key);
								}}
								enterCallback={(value: string, key: string) => {
									this.onEdit(index, value, key);
								}}
								disabled={true}
								permission={Ident.OperationId.PersonAddressPut}
							/>
						</EditableWrapper>
						<EditableWrapper>
							<EditableComponent
								translationkey='customers.givenName'
								id="give_name"
								initText={person.given_name ?? ''}
								changeCallback={(value: string, key: string) => {
									this.onEdit(index, value, key);
								}}
								enterCallback={(value: string, key: string) => {
									this.onEdit(index, value, key);
								}}
								disabled={true}
								permission={Ident.OperationId.PersonAddressPut}
							/>
						</EditableWrapper>
						<EditableWrapper>
							<KeyDateComponent 
								translationkey="customers.birthdate"
								value={ person?.date_of_birth != null ? new Date(person.date_of_birth) : undefined}
								id="date_of_birth"
								onChange={(key: string, date: Date) => {
									const pers = this.state.persons;
									pers[index].date_of_birth = date;
									this.setState({
										persons: pers
									})
								}}
								disabled={true}
								wrapperStyle={{maxWidth: '240px'}}
								number={0}
							/>
						</EditableWrapper>
						<EditableWrapper>
							<KeyDateComponent 
								translationkey="customers.juniorAccount.turns18"
								value={ person?.turns18 != null ? new Date(person.turns18) : undefined}
								id="date_of_birth"
								onChange={(key: string, date: Date) => {
									const pers = this.state.persons;
									pers[index].turns18 = date;
									this.setState({
										persons: pers
									})
								}}
								disabled={true}
								wrapperStyle={{maxWidth: '240px'}}
								number={0}
							/>
						</EditableWrapper>
						<EditableWrapper>
							<EditableComponent
								translationkey='customers.emailAddress'
								id="email_address"
								initText={person.email_address ?? ''}
								changeCallback={(value: string, key: string) => {
									this.onEdit(index, value, key);
								}}
								enterCallback={(value: string, key: string) => {
									this.onEdit(index, value, key);
								}}
								disabled={true}
								permission={Ident.OperationId.PersonAddressPut}
							/>
					</EditableWrapper>
					<EditableWrapper>
							<EditableComponent
								translationkey='customers.phone'
								id="phone_number"
								initText={person.phone_number ?? ''}
								changeCallback={(value: string, key: string) => {
									this.onEdit(index, value, key);
								}}
								enterCallback={(value: string, key: string) => {
									this.onEdit(index, value, key);
								}}
								disabled={true}
								permission={Ident.OperationId.PersonAddressPut}
							/>
					</EditableWrapper>
					<EditableWrapper>
							<EditableComponent
								translationkey='Relation'
								id="family_relation"
								initText={person.family_relation ?? ''}
								changeCallback={(value: string, key: string) => {
									this.onEdit(index, value, key);
								}}
								enterCallback={(value: string, key: string) => {
									this.onEdit(index, value, key);
								}}
								disabled={true}
								permission={Ident.OperationId.PersonAddressPut}
							/>
					</EditableWrapper>
						<KeyField>
							Documents:
						</KeyField>
						<FlexBox style={{alignItems: 'center', flexDirection: 'row', overflowX: 'auto', border: '1px solid grey', padding: '6px'}}>
								{ this.state.images != null && this.state.images[index] != null ?
									<ImageBox images={this.generateImages(index)}/>  : null
								}
							</FlexBox>
					</WrapperOuter>
				</Expandable>
			)
		})
		return out;
	  }	

	render() {
		return (
			<FlexBox>
				<WrapperOuter>
					<Title 
					 	title={translate('customers.juniorAccount.title')}	
					/>
				</WrapperOuter>
				{this.state.persons != null && this.state.persons.length > 0 ?
				 this.renderData()
				 : 'No data given' }
			</FlexBox>
		);
	}
}

export default withPersonAndAccount(IJuniorAccountData);
