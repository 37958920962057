import { Log, Logs } from '../../log';
import {
	Reporter,
	ReporterConfig,
	IMessageConfig,
} from './messageHandlerConfig';
import React from 'react';
import { BubbleContainer } from '../../../components/bubblecontainer';
import { translate } from '../../../common/language/translate';
import { OverlayHandler, Overlays } from '../overlayhandler/overlayHandler';
import { MessageBubble } from '../../../components/compositcomponents/popup/messagebuble';
import { Actions } from '../../flux';
import { Account } from '../../api';
import { Config } from '../../../config';

class MessageHandlerClass {
	static instance: MessageHandlerClass | null;

	private container: BubbleContainer | undefined;
	private bubblecount = 0;
	static getInstance(): MessageHandlerClass {
		if (MessageHandlerClass.instance == null) {
			MessageHandlerClass.instance = new MessageHandlerClass();
		}
		return MessageHandlerClass.instance;
	}
    //TODO MERGE ALL INTO ONE METHOD
	onError(
        fromComponent: Reporter,
        errorCode?: any,
        object?: any,	
	): IMessageConfig {
		Log.error(Logs.SYSTEM, 'MessageHandler onError');
        const config = ReporterConfig.get(fromComponent);
		if (config != null && config.errorMethods != null) {
			if (config.errorMethods.dialog === true) {
				this.showErrorDialog(
					translate(
						errorCode != null ? 'backend.' + errorCode : config.translationKey != null ? config.translationKey + '.error' : '',
						config.errorMessage != null ? config.errorMessage : object
					)
				);
			}
			if (config.errorMethods.bubble === true) {
				this.showErrorBubble(
					translate(
						errorCode != null ? 'backend.' + errorCode : config.translationKey != null ? config.translationKey + '.error' : '',
						object != null ? object : config.errorMessage != null ? config.errorMessage : Config.defaultErrorMessage
					) + (errorCode != null ? "(" + errorCode + ")" : "")
				);
			}
			if (config.errorMethods.notification === true) {
				this.showNotification(
					translate(
						errorCode != null ? 'backend.' + errorCode : config.translationKey != null ? config.translationKey + '.error' : '',
						config.errorMessage != null ? config.errorMessage : object
					),
					'Error'
				);
			}
			return config;
		}
		return {
			errorMethods: { inline: true },
		};
	}

    onWarning(
        fromComponent: Reporter,
        callback?: () => void,
        object?: any,
    ): IMessageConfig {
        Log.error(Logs.SYSTEM, 'MessageHandler onWarning');
        const config = ReporterConfig.get(fromComponent);
        if (config != null && config.warningMethods != null) {
            if (config.warningMethods.bubble === true) {
                this.showWarningBubble(
                    translate(
                        config.translationKey != null ? config.translationKey + '.error' : '',
                        config.warningMessage != null ? config.warningMessage : object
                    ),
                    callback
                );
            }
            return config;
        }
        return {
            errorMethods: {inline: true},
        };
    }

    showNotification(message: string, title: string) {
        const notification: Account.NotificationLine = {
            id: new Date().getTime() * 1000,
            preview: message,
            subject: title,
            datetime_create: new Date(),
            read: false,
            channel: Account.NotificationChannel.P,
            in_out: Account.NotificationLineInOutEnum.I,
            detail: '',
        };
        Actions.addNotification(notification);
    }

	 showErrorBubble(error: string ) {
        if (this.bubblecount === 0 || !OverlayHandler.hasOverlay(Overlays.messageBubble)) {
			OverlayHandler.showOverlay(Overlays.messageBubble, {
				bubbles: (
					<MessageBubble
						bubblecount={this.bubblecount++}
						message={error}
						warning={true}
					/>
				),
			});
			++this.bubblecount;
		} else {
			const el = React.createElement(MessageBubble, {
				message: error,
				warning: true,
				bubblecount: this.bubblecount,
			});
			Actions.bubbleChanged(el);
		}
	}

    showWarningBubble(message: any, callback?: () => void) {
        if (this.bubblecount === 0 || !OverlayHandler.hasOverlay(Overlays.messageBubble)) {
            OverlayHandler.showOverlay(Overlays.messageBubble, {
                bubbles: (
                    <MessageBubble
                        bubblecount={this.bubblecount++}
                        message={message}
                        warning={true}
                        confirmation={true}
                        callback={callback}
                    />
                ),
            });
            ++this.bubblecount;
        } else {
            const el = React.createElement(MessageBubble, {
                message: message,
                warning: true,
                bubblecount: this.bubblecount,
                confirmation:true,
                callback: callback,
            });
            Actions.bubbleChanged(el);
        }
    }

    showSuccessBubble(success: string) {
        if (this.bubblecount === 0 || !OverlayHandler.hasOverlay(Overlays.messageBubble)) {
            OverlayHandler.showOverlay(Overlays.messageBubble, {
                bubbles: (
                    <MessageBubble
                        bubblecount={this.bubblecount++}
                        message={success}
                        warning={false}
                    />
                ),
            });
            ++this.bubblecount;
        } else {
            const el = React.createElement(MessageBubble, {
                message: success,
                warning: false,
                bubblecount: this.bubblecount,
            });
            Actions.bubbleChanged(el);
        }
    }

	showErrorDialog(error: string) {
		OverlayHandler.showOverlay(Overlays.errorMessage, { error: error });
	}

	showSuccessDialog(message: string) {
		OverlayHandler.showOverlay(Overlays.successMessage, {
			heading: 'Successfully handled Request',
			message: message,
			notification: () => {},
			selfClosed: true,
		});
	}

	onSuccess(fromComponent: Reporter, object?: any): IMessageConfig {
		Log.debug(Logs.SYSTEM, 'MessageHandler onSuccess');
		const config = ReporterConfig.get(fromComponent);
		if (config != null && config.successMethods != null) {
			if (config.successMethods.dialog === true) {
				this.showSuccessDialog(
					translate(
						config.translationKey != null
							? config.translationKey + '.success'
							: '',
                            config.successMessage != null ? config.successMessage : object
					)
				);
			}
			if (config.successMethods.bubble === true) {
				this.showSuccessBubble(
					translate(
						object != null ? object :
						config.translationKey != null
							? config.translationKey + '.success'
							: '',
						config.successMessage != null ? config.successMessage : Config.defaultSuccessMessage
					)
				);
			}
			if (config.successMethods.notification === true) {
				this.showNotification(
					translate(
						config.translationKey != null
							? config.translationKey + '.success'
							: '',
						config.successMessage != null ? config.successMessage : object
					),
					'Success'
				);
			}
			return config;
		}
		return {
			successMethods: { bubble: true },
			errorMethods: { inline: true },
		};
	}
}

export const MessageHandler = MessageHandlerClass.getInstance();
