import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { IItfAccount } from '../../types';

class IAccountStore extends EventEmitter {
	accounts?: { accounts: IItfAccount[]; currentAccount: IItfAccount } = undefined;

	private static instance: IAccountStore | null;

	constructor() {
		super();
		IDispatcher.register(this.registerActions.bind(this));
	}
	static getInstance(): IAccountStore {
		if (IAccountStore.instance == null) {
			IAccountStore.instance = new IAccountStore();
		}

		return IAccountStore.instance;
	}

	registerActions(action: any) {
		switch (action.actionType) {
			default:
				break;
			case Constants.CLEAR_ALL:
				this.accounts = undefined;
				this.emit(CHANGE_EVENT);
				break;
			case Constants.ACCOUNTS_CHANGED:
				this.setAccounts(action.accounts);
				this.emit(CHANGE_EVENT);
				break;
		}
		return true;
	}

	setAccounts(accounts: { accounts: IItfAccount[]; currentAccount: IItfAccount }) {
		this.accounts = accounts;
	}

	getAccounts() {
		return this.accounts;
	}

	addChangeListener(callback: any) {
		this.on(CHANGE_EVENT, callback);
	}

	removeChangeListener(callback: any) {
		this.removeListener(CHANGE_EVENT, callback);
	}
}

export const AccountStore = IAccountStore.getInstance();
