// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders } from '../runtime';
import {
    StripeWebhook,
} from '../models';

export interface StripeWebhookPostRequest {
    StripeWebhook?: StripeWebhook;
}

/**
 * no description
 */
export class StripeApi extends BaseAPI implements IOperationIdMap {

    /**
     */
    stripeWebhookPost = (requestParameters: StripeWebhookPostRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/stripe/webhook',
            method: 'POST',
            headers,
            body: requestParameters.StripeWebhook,
        });
    };


    operationToOperationId = {
	    "stripeWebhookPost": "stripe_webhook_post"
    }
}
