import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';

import { IStatusState, Reporter } from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { Table } from '../../../../components/compositcomponents/table/table';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';


import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import { ContainerText,StyledHr } from '../transfer/transfersComponent.css';
import { Box } from '../../../../components/atomiccompoents/boxes/box';


import { format } from '../../../../logic/helper/format';
import Title from '../../../../components/compositcomponents/title';

import { api, apis, ApiError, Account } from '../../../../logic/api/index';
import { Log, Logs } from '../../../../logic/log';
import { Icons } from '../../../../images';
import { OverlayHandler, Overlays } from '../../../../logic/handler/overlayhandler/overlayHandler';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { evaluateErrorMessage } from '../../../../logic/helper/Common';
import { PagingComponent } from '../../../../components/paging';
import { Config } from '../../../../config';

interface IProps{
	
}

interface IState extends IStatusState{
    toUpload?: Blob;
	fileList?: Array<Account.BpcCardStatusFile>,
	limit?: number,
	offset?: number
}


export default class CardStatus extends React.Component<IProps,IState>{
    private firstPage: number | undefined = undefined;
    private tableRef: Table<Account.BpcCardStatusFile> | null = null;
    converter = (
        line: Account.BpcCardStatusFile,
        index: number,
		fields: Array<string>
	): RowType => {
		const row: RowType = { cells: [], ref: line };
		if (row.cells == null) {
			return row;
		}
		//@ts-ignore
		const keys: Array<string> = Object.keys(line);
		for (let i = 0; i < fields.length; i++) {
			const field: string = fields[i];
			switch (field) {
				case 'datetime_imported':{
					if (Object.prototype.hasOwnProperty.call(line, field)) {
						const val = format.datetime(Object.values(line)[keys.indexOf(field)] as Date);
						row.cells.push({
							value: val != null ?  val.toString() : "",
							display: <ContainerText 
										title={String(val)}>
											{String(val)}
									</ContainerText>,
							methods: {},
							copyVal: String(Number(Object.values(line)[keys.indexOf(field)]).toFixed(3))
							});
					} else {
						row.cells.push({ value: '' });
					}
					break;
				}
				default: {
					if (Object.prototype.hasOwnProperty.call(line, field)) {
						row.cells.push({
							value: String(Object.values(line)[keys.indexOf(field)]),
							display: <ContainerText 
										title={String(Object.values(line)[keys.indexOf(field)])}>
											{String(Object.values(line)[keys.indexOf(field)])}
									</ContainerText>,
							methods: {},
							copyVal: String(Object.values(line)[keys.indexOf(field)])
							});
					} else {
						row.cells.push({ value: '' });
					}
					break;
				}
			}
		}
		return row;
	};
 
    constructor(props: IProps){
        super(props);
		this.state = {
			limit: 100,
			offset: 0,
        };
		this.getFilelist(100, 0);
		this.resetFileList = this.resetFileList.bind(this);
		this.getFilelist = this.getFilelist.bind(this);
		this.uploadFile = this.uploadFile.bind(this);
		
    };

	resetFileList(data: {offset: number, amount: number}, updateParams?: any) {
		this.getFilelist(data.amount, data.offset);
		if(updateParams != null) {
			updateParams.updateCallback();
			this.firstPage = updateParams.firstOfPage;
		}
		this.setState({
			offset: data.offset
		}, () => { this.firstPage = undefined});
	}

	getFilelist(limit: number, offset: number) {
		const req: Account.BpcCardStatusFilesListRequest = {
			limit: limit,
			offset: offset
		};

		api.asyncRequest<Array<Account.BpcCardStatusFile>>(
			req,
			apis.BPCApi,
			'bpcCardStatusFilesList'
		).then((response: Array<Account.BpcCardStatusFile>) => {
			this.setState({
				fileList: response,
				limit: limit,
				offset: offset
			})

		}).catch((error: ApiError) => {
			Log.error(Logs.API, error);
		});
	}

/*	getSingleFile(id: number, fileName: string) {
		const req: Account.BpcDbalFileGetRequest = {
			bpc_dbal_file_id: id
		}

		api.asyncRequest<Blob>(
			req,
			apis.BPCApi,
			'bpcDbalFileGet'
		).then((response: Blob ) => {
			downloadFile(response, fileName);
			MessageHandler.onSuccess(Reporter['admin.clearing_file.get']);

		}).catch((error: ApiError) => {
			MessageHandler.onError(Reporter['admin.clearing_file.get'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false))
		});
	}*/

	uploadFile(file: any) {
		const req: Account.BpcCardStatusFilePostRequest = {
			file: file
		}

		api.asyncRequest<any>(
			req,
			apis.BPCApi,
			'bpcCardStatusFilePost'
		).then((response: any) => {
			MessageHandler.onSuccess(Reporter['admin.clearing_file.post']);
			this.getFilelist(this.state.limit != null ? this.state.limit : 100, this.state.offset != null ? this.state.offset : 0);

		}).catch((error: ApiError) => {
			MessageHandler.onError(Reporter['admin.clearing_file.post'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false))
		});
	}


    render(){
        return 	<FlexBox>
                    <Box>
            		    <div>
					        <Title title={translate('administration.card_status.header')} />
					    </div>
                        <StyledHr />
						<UploadBox>
							<Hover onClick={(event: SyntheticEvent) => {
								event.stopPropagation();
								event.preventDefault();
								OverlayHandler.showOverlay(Overlays.fileChooserOverlay, {
									callback: this.uploadFile,
   									title: translate('administration.card_status.selectFile'),
									fileType: 'all',
								})
							}}>
								{Icons.upload()}
							</Hover>
						</UploadBox>
						<TableBox>
						<PagingWrapper>
							<PagingComponent
								numberOfEntries={
									this.state.fileList != null ? this.state.fileList.length : 0
								}
								rowsPerPage={4}
								resetTransactionscallback={this.resetFileList}
							/>
						</PagingWrapper>
						<Table<Account.BpcCardStatusFile>
                            header={[												
                                translate('administration.clearing_files.table.filename'),
								translate('administration.card_status.table.datetime_imported')
                            ]}
                            sourceData={this.state.fileList != null ? this.state.fileList : []}
                            fields={[												
                                'file_name',
                                'datetime_imported',
                            ]}
							externalPaging={true}
							hidePaging={false}
							dynamicPaging={false}
                            stickyHeader={true}
							firstOfPage={this.firstPage}
							rowsPerPageParam={Config.table.rowserPerPageDefault}
                            highlightTextOnSearch={true}
                            dataConverter = {this.converter}
                            ref={(element) => (this.tableRef = element)}
							resize={true}
                        />
						</TableBox>
                    </Box>
                </FlexBox>
               
    }
    
}

const TableBox = styled.div`
`;
const UploadBox = styled.div`
  	display: flex;
	justify-content: flex-start;
	width: 90%;
`;
const Hover = styled.div`
	:hover {
		cursor: pointer;
	}
	margin-top: 16px;
	margin-left: 32px;
`;
const PagingWrapper = styled.div`
	justify-self: flex-end;
	margin: auto;
	margin-right: 12px;
	max-height: 32px;
`;
