//@ts-nocheck
import React, { SyntheticEvent } from 'react';
import {
	ACenteredOverlay,
	IPropsCenter,
	IStateCenter,
} from '../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import styled from 'styled-components';
import { OverlayHandler, Overlays } from '../../../logic/handler/overlayhandler/overlayHandler';
import { FlexBox } from '../../../content/auth/auth.css';
import Title from '../title';
import { BigInput } from '../../atomiccompoents/form/inputs.css';
import { translate } from '../../../common/language/translate';
import ButtonOk from '../../atomiccompoents/buttons/buttonOk';
import { IOption } from '../../atomiccompoents/form/select';
import { StyledSelect } from '../../../content/dashboard/content/basicStyledComponents/customersComponent.css';

const StyledViewMain = styled(FlexBox)<{height?: string}>`
	margin: auto;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	flex-direction: column;

	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	background-color: white;
	border: 1px solid ${props => props.theme.Button.backgroundColor};
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	width: 500px;
	height: ${props => props.height != null ? props.height : '300px' };
	padding: 16px;
	box-sizing: border-box;
`;

const StyledMarginSelect = styled(StyledSelect)`
    max-width: 450px;
    select {
        min-width: 200px;
        max-width: 450px;
        margin-bottom: 8px;
        margin-top: 9px;
    }
`;


export enum DataTypeEnum {
	Select,
	Input
}
export interface IRequestData {
	translationkeyPL: string;
	translationkeyLA: string;
	value?: string | any;
	type?: DataTypeEnum;
	enumVals?: Array<IOption>;
}

interface IProps extends IPropsCenter{
    callback: (data: {[key: string]: IRequestData}) => void;
	title?: string;
	buttonKey: string;
	data: {[key: string]: IRequestData};
	height?: string
}

interface IState extends IStateCenter {
	data: {[key: string]: IRequestData}
}

export class RequestDataOverlay extends ACenteredOverlay<IProps , IState > {
	
	constructor(props: IProps ) {
		super(props);

		this.state = {
			data: props.data
		};
    }

	reviveState(newState: { [key: string]: any; }): void {
		
	}
    
    
	defineContent() {

		return (
			<StyledViewMain height={this.props.height}>
				<Title title={this.props.title != null ? translate(this.props.title) : "Select Data" } />
					{
						Object.keys(this.state.data).map((key: string) => {
							if(this.state.data[key].type === DataTypeEnum.Input) {
								return(
									<BigInput
										placeHolder={translate(this.state.data[key].translationkeyPL)}
										label={translate(this.state.data[key].translationkeyLA)}
										type="text"
										id={key}
										valueFromState={true}
										value={this.state.data[key].value as string}
										notification={(n: any) =>  {
											const data = this.state.data;
											data[key].value = n[key].value;
											this.setState({
												data: data
											}) 
										}}
								/>
							)
						} else {
							return(
								<StyledMarginSelect
									helptext={translate(this.state.data[key].translationkeyPL)}
									id={key}
									options={this.state.data[key].enumVals}
									notification={(n: any) =>  {
										const data = this.state.data;
										data[key].value = n[key];
										this.setState({
											data: data
										}) 
									}}
									current={this.state.data[key].value}
									wrapperStyle={{margin: 'initial'}}
								/>
							)
						}
					})
				}
             <div style={{display: 'flex', width: '100%', justifyContent: 'flex-start'}}>
				<ButtonOk
					id="btnSendData"
					onClick={() => { this.props.callback(this.state.data);
									 this.props.selfClose() } }>
					{translate(this.props.buttonKey)}
				</ButtonOk>
			</div>	
			</StyledViewMain>
		);
	}

	close() {
		OverlayHandler.closeSpecific(Overlays.fileChooserOverlay);
	}
}
