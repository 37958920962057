import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';

export type themeType = 'default' | 'other';

class IThemeStore extends EventEmitter {
	private static instance: IThemeStore | null;
	private theme: themeType = 'default';

	constructor() {
		super();
		IDispatcher.register(this.registerActions.bind(this));
	}

	static getInstance(): IThemeStore {
		if (IThemeStore.instance == null) {
			IThemeStore.instance = new IThemeStore();
		}

		return IThemeStore.instance;
	}

	registerActions(action: any) {
		switch (action.actionType) {
			default:
				break;
			case Constants.CLEAR_ALL:
				this.theme = 'default';
				this.emit(CHANGE_EVENT);
				break;
			case Constants.THEME_CHANGED:
				this.setTheme(action.theme);
				this.emit(CHANGE_EVENT);
				break;
		}
		return true;
	}

	getTheme(): themeType {
		return this.theme;
	}

	setTheme(theme?: themeType): void {
		if (theme == null) {
			return;
		}

		this.theme = theme;
	}

	addChangeListener(callback: any) {
		this.on(CHANGE_EVENT, callback);
	}

	removeChangeListener(callback: any) {
		this.removeListener(CHANGE_EVENT, callback);
	}
}

export const ThemeStore = IThemeStore.getInstance();
