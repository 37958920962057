// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * indicates the brand of the card * american_express - American Express * discover - Discover * maestro - Maestro * master_card - Master Card * visa - Visa 
 * @export
 * @enum {string}
 */
export enum CardBrand {
    AmericanExpress = 'american_express',
    Discover = 'discover',
    Maestro = 'maestro',
    MasterCard = 'master_card',
    Visa = 'visa'
}

