// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * a calendar item. check start_time < end time
 * @export
 * @interface CalendarItem
 */
export interface CalendarItem {
    /**
     * the weedays 0 = sun, 6 = sat
     * @type {number}
     * @memberof CalendarItem
     */
    weekday_id: CalendarItemWeekdayIdEnum;
    /**
     * @type {string}
     * @memberof CalendarItem
     */
    start_time: string;
    /**
     * @type {string}
     * @memberof CalendarItem
     */
    end_time: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CalendarItemWeekdayIdEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6
}

