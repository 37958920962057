import React, { ReactNode } from 'react';
import styled from 'styled-components';
import {
	IPropsCenter,
	IStateCenter,
	ACenteredOverlay,
} from '../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';

interface IProps extends IPropsCenter {}

interface IState extends IStateCenter {}

const AlertOverlay = styled.div`
	position: absolute;
	top: 64px;
	right: 337px;

	width: 500px;
	height: 764px;

	z-index: 300;

	background-color: #fafafa;
	border-radius: 4px;
`;

export default class AlertMessages extends ACenteredOverlay<IProps, IState> {
	reviveState(): void {}

	defineContent(): ReactNode {
		return <AlertOverlay></AlertOverlay>;
	}
}
