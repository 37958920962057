// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Address_Type:   * \'L\' - Living Address   * \'D\' - Delivery Address 
 * @export
 * @enum {string}
 */
export enum AddressType {
    L = 'L',
    D = 'D'
}

