import styled from 'styled-components';
import {
	OverlayComponent,
	IOverlayComponentProps,
	IOverlayComponentState,
} from '../../../../components/compositcomponents/popup/overlayComponent';
import React, { ReactNode } from 'react';

export interface IPropsCenter extends IOverlayComponentProps {}

export interface IStateCenter extends IOverlayComponentState {}
/*
    abstract class for a centered overlay. 
    By extending this class you need to implement the function defineContent, that returns the overlaycontent you want to be shown
    each function that results from an onClick event, needs to do the event.stopPropagation() call before your logic.
    So you get an overlay, that is centered and will interact according to the defined properties in the options you set up before.
    if you want to have a ClickEvent that is triggered by click anywhere in the overlay(not buttons or links) you need to override the given handleClick() method in your class
*/
export abstract class ACenteredOverlay<
	P extends IPropsCenter,
	S extends IStateCenter
> extends OverlayComponent<P, S> {
	abstract defineContent(): ReactNode;

	isCentered(): boolean {
		return true;
	}

	render(): ReactNode {
		return (
			<CenterOverlay onClick={this.handleClick}>{this.defineContent()}</CenterOverlay>
		);
	}
}

const CenterOverlay = styled.div`
	z-index: 300;
	display: flex;
	justify-content: center;
	align-items: center;

	width: 0;
	height: 0;
`;
