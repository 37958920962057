import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_TRANSACTIONS, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { Activity } from '../../api/account';

class TransactionDataStore extends EventEmitter {
	transactions?: Activity = undefined;
	private static instance: TransactionDataStore | null;

	constructor() {
		super();
		IDispatcher.register(this.registerActions.bind(this));
	}
	static getInstance(): TransactionDataStore {
		if (TransactionDataStore.instance == null) {
			TransactionDataStore.instance = new TransactionDataStore();
		}

		return TransactionDataStore.instance;
	}

	registerActions(action: any) {
		switch (action.actionType) {
			default:
				break;
			case Constants.CLEAR_ALL:
				this.transactions = undefined;
				this.emit(CHANGE_EVENT);
				break;
			case Constants.TRANSACTIONS_CHANGED:
				this.setTransactions(action.transactions);
				this.emit(CHANGE_TRANSACTIONS);
				break;
		}
		return true;
	}

	setTransactions(transactions: Activity) {
		this.transactions = transactions;
	}
	getTransactions() {
		return this.transactions;
	}

	addChangeListener(callback: any) {
		this.on(CHANGE_TRANSACTIONS, callback);
	}

	removeChangeListener(callback: any) {
		this.removeListener(CHANGE_TRANSACTIONS, callback);
	}
}

export const ITransactionDataStore = TransactionDataStore.getInstance();
