// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CompanionRelationship,
} from './';

/**
 * the pending companion requests
 * @export
 * @interface CompanionRequest
 */
export interface CompanionRequest {
    /**
     * @type {number}
     * @memberof CompanionRequest
     */
    companion_request_id: number;
    /**
     * @type {number}
     * @memberof CompanionRequest
     */
    person_id: number;
    /**
     * the referenced companion code
     * @type {string}
     * @memberof CompanionRequest
     */
    companion_code?: string;
    /**
     * the other persons given name
     * @type {string}
     * @memberof CompanionRequest
     */
    given_name: string;
    /**
     * the other persons given name
     * @type {string}
     * @memberof CompanionRequest
     */
    name: string;
    /**
     * * C other person should become companion * P other person should become primary 
     * @type {string}
     * @memberof CompanionRequest
     */
    request_type: CompanionRequestRequestTypeEnum;
    /**
     * date and time the actual request was created (primary or companion used the compantion code)
     * @type {Date}
     * @memberof CompanionRequest
     */
    datetime_create: Date;
    /**
     * date and time the primary accepted the request
     * @type {Date}
     * @memberof CompanionRequest
     */
    datetime_accept_primary?: Date;
    /**
     * date and time the companion accepted the request
     * @type {Date}
     * @memberof CompanionRequest
     */
    datetime_accept_companion?: Date;
    /**
     * the url the users face image if there is one
     * @type {string}
     * @memberof CompanionRequest
     */
    face_url?: string;
    /**
     * @type {CompanionRelationship}
     * @memberof CompanionRequest
     */
    relationship?: CompanionRelationship;
}

/**
 * @export
 * @enum {string}
 */
export enum CompanionRequestRequestTypeEnum {
    C = 'C',
    P = 'P'
}

