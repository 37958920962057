import { Config } from "../../../../config";

export class PagingManager {
    private pages: number;
    private rowsPP: number;
    private offset: number;
    private currentPage: number;
    private amount: number;
    private getTransactionsCallback: (data: any) => void;

    constructor(iGetTransactionsCallback: (data:any) => void) {
        this.rowsPP = Config.table.rowserPerPageDefault;
        this.amount = Config.table.transactionLimit;
        this.pages = this.amount/this.rowsPP;
        this.currentPage = 0;
        this.offset = 0;
        this.getTransactionsCallback = iGetTransactionsCallback;
    }

    setCurrent(index: number, blocked?: boolean) {
        this.currentPage = index;
        let changeValue = 0;
        if(this.currentPage <= Config.table.getNewValuesTrigger && this.offset > 0 ) {
            this.offset = Math.floor(this.offset - (Config.table.rowserPerPageDefault - Config.table.getNewValuesTrigger)/2);
            changeValue = Math.floor((Config.table.rowserPerPageDefault - Config.table.getNewValuesTrigger)/2)
        } else if (this.currentPage >= this.pages - Config.table.getNewValuesTrigger) {
            if(blocked !== true) {
                this.offset = Math.floor(this.offset + this.currentPage/2);
                changeValue = Math.floor(this.currentPage/2)
            }
        } else{
            return { offset: this.offset, changeValue: 0 };
        }
        return { offset: this.offset, changeValue: changeValue };

    }

    reset() {
        this.rowsPP = Config.table.rowserPerPageDefault;
        this.amount = Config.table.transactionLimit;
        this.pages = this.amount/this.rowsPP;
        this.currentPage = 0;
        this.offset = 0;
    }

}