// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ManualIntervention
 */
export interface ManualIntervention {
    /**
     * @type {number}
     * @memberof ManualIntervention
     */
    person_id?: number;
    /**
     * @type {string}
     * @memberof ManualIntervention
     */
    type_of_data?: ManualInterventionTypeOfDataEnum;
    /**
     * @type {string}
     * @memberof ManualIntervention
     */
    initial_value?: string;
    /**
     * @type {string}
     * @memberof ManualIntervention
     */
    corrected_value?: string;
    /**
     * @type {Date}
     * @memberof ManualIntervention
     */
    approval_date?: Date;
    /**
     * @type {string}
     * @memberof ManualIntervention
     */
    eid_agent?: string;
    /**
     * @type {number}
     * @memberof ManualIntervention
     */
    bo_person_id?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ManualInterventionTypeOfDataEnum {
    Surname = 'surname',
    GivenName = 'given name',
    DateOfBirth = 'date of birth',
    Sex = 'sex',
    Nationality = 'nationality',
    IssuingCountry = 'issuing country',
    DocumentNumber = 'document number',
    ExpiryDate = 'expiry date'
}

