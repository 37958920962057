// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * usertype:  * `bo` - backofficeuser  * `customer` - customer 
 * @export
 * @enum {string}
 */
export enum UserClass {
    Bo = 'bo',
    Customer = 'customer'
}

