// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Amount,
    CreditorAccountSelection
} from './';

/**
 * a customer credit transfer.  if a stored favorite was used, put its id into favorite_id  if used as a response to a request-money, add its id\'s to request money, otherwise don\'t send it. Please note that only the receiver of a request_money can use the customer credit transfer to respond to those requests.  BIG FAT WARNING the attributes with name beneficiary are about to be removed, please use the attributes named favorites instead
 * @export
 * @interface NewScheduledTransaction
 */
export interface NewScheduledTransaction {
    /**
     * @type {string}
     * @memberof NewScheduledTransaction
     */
    debitor_account: string;
    /**
     * @type {Amount}
     * @memberof NewScheduledTransaction
     */
    instructed_amount: Amount;
    /**
     * @type {String | creditorAccountSelection}
     * @memberof NewScheduledTransaction
     */
    creditor_account: String | CreditorAccountSelection;
    /**
     * Creditor Name
     * @type {string}
     * @memberof NewScheduledTransaction
     */
    creditor_name: string;
    /**
     * Unstructured remittance information 
     * @type {string}
     * @memberof NewScheduledTransaction
     */
    remittance_information_unstructured?: string;
    /**
     * @type {boolean}
     * @memberof NewScheduledTransaction
     */
    gift_payment?: boolean;
    /**
     * first transaction will happen at that date, by default today
     * @type {Date}
     * @memberof NewScheduledTransaction
     */
    cycle_start?: Date;
    /**
     * last transaction will happen at that date, by default infinity
     * @type {Date}
     * @memberof NewScheduledTransaction
     */
    cycle_end?: Date;
    /**
     * * O -nce  * D -aily  * W -eekly  * M -onthly  * Y -early
     * @type {string}
     * @memberof NewScheduledTransaction
     */
    cycle_type: NewScheduledTransactionCycleTypeEnum;
    /**
     * * B -eginning of ...  * E -nd of ...  * D -ay (e.g. always at the 12th of ...)
     * @type {string}
     * @memberof NewScheduledTransaction
     */
    cycle_reference?: NewScheduledTransactionCycleReferenceEnum;
    /**
     * @type {number}
     * @memberof NewScheduledTransaction
     */
    cycle_day_of_week?: number;
    /**
     * @type {number}
     * @memberof NewScheduledTransaction
     */
    cycle_day_of_month?: number;
    /**
     * external reference as provided by the initiaing application
     * @type {string}
     * @memberof NewScheduledTransaction
     */
    external_reference?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum NewScheduledTransactionCycleTypeEnum {
    O = 'O',
    D = 'D',
    W = 'W',
    M = 'M',
    Y = 'Y',
    P = 'P'
}
/**
 * @export
 * @enum {string}
 */
export enum NewScheduledTransactionCycleReferenceEnum {
    B = 'B',
    E = 'E',
    D = 'D'
}

