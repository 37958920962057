// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders } from '../runtime';
import {
    ClientAmounts,
} from '../models';

/**
 * no description
 */
export class ClientstatsApi extends BaseAPI implements IOperationIdMap {

    /**
     * get the amount of clients for differenct categoeries
     */
    clientstatsRegistrationsGet = (): Observable<ClientAmounts> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<ClientAmounts>({
            path: '/clientstats/registrations',
            method: 'GET',
            headers,
        });
    };


    operationToOperationId = {
	    "clientstatsRegistrationsGet": "clientstats_registrations_get"
    }
}
