import React, { ReactElement } from 'react';
import {  KeyField, Helpertext, ColumnBox, ValueField, EditableWrapper } from '../basicStyledComponents/customerDetails.css';
import { Ident, api, apis, ApiError } from '../../../../../logic/api';
import { translate } from '../../../../../common/language/translate';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import styled from 'styled-components';
import {
	OverlayHandler,
	Overlays,
} from '../../../../../logic/handler/overlayhandler/overlayHandler';
import { FlexBox } from '../../../../auth/auth.css';
import TouchableOpacity from '../../../../../components/atomiccompoents/buttons/touchableOpacity';
import { coalesce, compareDatetime, evaluateErrorMessage } from '../../../../../logic/helper/Common';
import EditableComponent from '../../../../../components/atomiccompoents/editableComponent/editableComponent';
import { IOption } from '../../../../../components/atomiccompoents/form';
import { format } from '../../../../../logic/helper/format';
import ButtonCancel from '../../../../../components/atomiccompoents/buttons/buttonCancel';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import moment from 'moment';
import KeyDateComponent from '../../../../../components/atomiccompoents/keyDateComponent';
import KeySelectComponent from '../../../../../components/atomiccompoents/keySelectComponent';
import ImageBox, { IImageBoxElement } from '../../../../../components/compositcomponents/imageBox';
import { Log, Logs } from '../../../../../logic/log';

interface IProps {
	person_id: number;
	approveCallback: () => void;
	eidData: Ident.PersonVerification;
	state: Ident.PersonStateEnum;
	is18?: boolean
}

interface IState {
    passportFront?: Blob;
	passportStored?: Blob;
	passportBack?: Blob;
	keyForLoadingSpinner?: number;
	ids?: Array<number>;
	expandPassport?: boolean;
	storedImageToUplad?: any;
	passportFrontToUpload?: any;
	eidData: Ident.PersonVerification;
	dataChanged?: boolean;
	passport?: Ident.Passport;
}

const StyledViewMain = styled(FlexBox)`
	flex-direction: column;
	align-items: center;
`;

const StyledIconView = styled(FlexBox)`
	flex-direction: row;
	justify-content: flex-end;
`;


//Unfortunately currently 3 cases are handled in this component, which is very messy, needs a rework
// case ndpay (Clientconfig.editPAssport = true) -> we edit person via personput
// case onefor and wating for approval -> we edit eid-data via eidverificationput
// case onefor and not waitng for approval -> we edit passport via passportput
export default class CustomerDetailsEidVerification extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            expandPassport: true,
			eidData: props.eidData,
		};
		this.setSex = this.setSex.bind(this);
		this.setBirthDate = this.setBirthDate.bind(this);
		this.setStoredImage = this.setStoredImage.bind(this);
		this.setFrontImage = this.setFrontImage.bind(this);
		this.setExpiryDate = this.setExpiryDate.bind(this);
		this.requestKyc = this.requestKyc.bind(this);
		this.changePassportData = this.changePassportData.bind(this);
		this.fetchIdentification();
	}

	static getDerivedStateFromProps(props: IProps, state: IState) {
		return {
			eidData: props.eidData
		}
	}

	componentDidMount(): void {

	}

	componentWillUnmount() {
	
	}

	fetchIdentification(): void {
		let passportId;
		api.asyncRequest<Array<Ident.Passport>>(
			{ person_id: this.props.person_id },
			apis.MaintenanceApi,
			'personPassportGet'
		)
			.then((response) => {
				if (response != null && response.length > 0) {
					response = response.sort(
						(a: Ident.Passport, b: Ident.Passport) =>
							compareDatetime(a.datetime_update, b.datetime_update) * -1
					);

					passportId = response[0].passport_id;
					const pass = response[0];
					api.asyncRequest<Blob>(
						{
							person_id: this.props.person_id,
							passport_id: passportId,
						},
						apis.MaintenanceApi,
						'personPassportPassportFrontGet'
					).then((response) => {
						if(response != null) {
							this.setState({
								passportFront: response,
							});
						}
					}).catch((error: ApiError) => {
						Log.warn(Logs.API, error);
						
					});
					api.asyncRequest<Blob>(
						{
							person_id: this.props.person_id,
							passport_id: passportId,
						},
						apis.MaintenanceApi,
						'personPassportStoredImageGet'
					).then((response) => {
						if(response != null) {
							this.setState({
								passportStored: response,
							});
						}
					}).catch((error: ApiError) => {
						Log.warn(Logs.API, error);
						
					});
					api.asyncRequest<Blob>(
						{
							person_id: this.props.person_id,
							passport_id: passportId,
							},
						apis.MaintenanceApi,
						'personPassportPassportBackGet'
					).then((response) => {
						if(response != null) {
							this.setState({
								passportBack: response,
							});
						}
					}).catch((error: ApiError) => {
						Log.warn(Logs.API, error);
						
					});;
					this.setState({
						passport: response[0]
					})
				}
			})
			.catch((error: ApiError) => {
				Log.error(Logs.API, error);
				MessageHandler.onError(Reporter['customer.details.identification'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
			});
		}
    
	generateImages() : Array<IImageBoxElement> {
        const out: Array<IImageBoxElement> = [];

		if(this.state.passportFront != null && this.state.passportFront.size > 0) {
			out.push(
				{ 	image: 	this.state.passportFront,
					title:	'FaceID: Front'
				}
			)
		}
		if(this.state.passportStored != null && this.state.passportStored.size > 0 ) {
			out.push(
				{ 	image: 	this.state.passportStored,
					title:	'FaceID: Stored'
				}
			)
		}

		if(this.state.passportBack!= null && this.state.passportBack.size > 0) {
			out.push(
				{ 	image: 	this.state.passportBack,
					title:	'FaceID: Back'}
			)
		}
        return out;
	}


	requestKyc() {
		OverlayHandler.showOverlay(Overlays.ConfirmationRequestOverlay, {
			confirm: (callback: (success: boolean, close?: boolean) => void) => {
				api.asyncRequest<any>(
					{ person_id: this.props.person_id },
					apis.MaintenanceApi,
					'personRequestKycPut'
					).then((response: any) => {
						this.setState({
							keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
						});
						if(response.release_request_id != null) {
							MessageHandler.onSuccess(
								Reporter['customer.details.kyc.put.request.approval']
							);
						} else {
							MessageHandler.onSuccess(
								Reporter['customer.details.kyc.put.request']
							);
						}
					}).catch((error: ApiError) => {
						MessageHandler.onError(
							Reporter['customer.details.kyc.put.request'],
							evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
						);
					}
				);
				callback(false, true);
				return;
			},
			heading: translate('customers.details.KYC.requestKYC.header'),
			message: translate('customers.details.KYC.requestKYC.message'),
		});
	}

	savePassportData() {
		if(this.state.eidData.electronicid_verification_id != null ) {
				const passport = !this.state.eidData.waiting_for_approval
				const req: Ident.PersonElectronicidVerificationPutRequest = {
					person_id: this.props.person_id,
					electronicid_verification_id: this.state.eidData.electronicid_verification_id,
					ApprovedPersonVerification: {
						primary_name  : this.state.eidData.primary_name,
						secondary_name: this.state.eidData.secondary_name,
						issuer        : this.state.eidData.issuer,
						nationality   : this.state.eidData.nationality,
						document_number:this.state.eidData.document_number,
						//@ts-ignore
						birth_date    : this.state.eidData.birth_date != null ? moment(this.state.eidData.birth_date).format('YYYY-MM-DD') : undefined,
						//@ts-ignore
						expiry_date   : this.state.eidData.expiry_date != null ? moment(this.state.eidData.expiry_date).format('YYYY-MM-DD') : undefined,
						sex           : this.state.eidData.sex
					}
				}	
				api.asyncRequest<any>(
					req,
					apis.MaintenanceApi,
					'personElectronicidVerificationPut'
				).then((response) => { 
					if(response != null) {
						MessageHandler.onSuccess(Reporter['passport.post.request']);
						
						this.props.approveCallback();
						this.setState({
							keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
						})
					}
				}).catch( (error: ApiError) => {
					if(error.response != null) {
						MessageHandler.onError(Reporter['passport.post.request'], error.response.response.error_code, error.response.response.error_text);
					}
					else {
						MessageHandler.onError(Reporter['passport.post.request']);
					}
					this.setState({
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					})
				});
			}

	}
	
	changePassportData(value: string, key?: string) {
		const eidData = this.state.eidData;
		//@ts-ignore
		eidData[key] = value;
		this.setState({
			eidData: eidData,
			dataChanged: true
		})
}

	setBirthDate(date: Date) {
		const eidData = this.state.eidData;
		eidData.birth_date = date;
		this.setState({
			eidData: eidData,
			dataChanged: true
		})
	}

	setExpiryDate(date: Date) {
		const eidData = this.state.eidData;
		eidData.expiry_date = date;
		this.setState({
			eidData: eidData,
			dataChanged: true
		})
	}

	getSexOptions() {
		const options: Array<IOption> = [ ];
		options.push({
			key: 'defaultOptionKey_not_set',
			name: translate('customers.sex.notSet'),
			value: ""
		});
		options.push({
				key: 'defaultOptionKey mr',
				name: translate('customers.sex.mr'),
				value: Ident.Sex.M,
			});
		options.push({
				key: 'defaultOptionKey mrs',
				name: translate('customers.sex.mrs'),
				value: Ident.Sex.F,
			});
		options.push({
				key: 'defaultOptionKey undefined',
				name: translate('customers.sex.undefined'),
				value: Ident.Sex.X,
			});
		return options;
	}

	setStoredImage(file: any) {
		this.setState({
			storedImageToUplad: file
		})
	}
	setFrontImage(file: any) {
		this.setState({
			passportFrontToUpload: file
		})
	}
	setSex(sex: any) {
		const eidData = this.state.eidData;
		eidData.sex = sex;
		this.setState({
			eidData: eidData,
			dataChanged: true
		})
	}
	render() {

		return (
			<FlexBox>
				<StyledIconView>
					<TouchableOpacity>
						<FlexBox style={{ height: '29px', width: '30px' }} />
					</TouchableOpacity>
				</StyledIconView>
               
				<StyledViewMain>              
					<WrapperInner>
						<ColumnBox style={{maxHeight: 'calc(100vh - 300px', minWidth: '950px'}}>
                       		 <EditableWrapper>							
									<EditableComponent 
										translationkey="input.firstname.label"
										id={"primary_name"}
										inputStyle={{
											textAlign: 'left',
											marginBottom: '0px',
											marginTop: '2px',
										}}
										initText={ coalesce("", this.state.eidData.primary_name) }
										enterCallback={this.changePassportData}
										changeCallback={this.changePassportData}
										disabled={false}
									/>
							</EditableWrapper>
                            <EditableWrapper>							
								<EditableComponent 
										translationkey="input.lastname.label"
										id={"secondary_name" }
										inputStyle={{
											textAlign: 'left',
											marginBottom: '0px',
											marginTop: '2px',
										}}
										initText={  coalesce('', this.state.eidData.secondary_name )}
										changeCallback={this.changePassportData}
										enterCallback={this.changePassportData}
										disabled={false}
									/>
							</EditableWrapper>
							<EditableWrapper>							
									<EditableComponent 
										translationkey="customers.documentNumber"
										id={"document_number" }
										inputStyle={{
											textAlign: 'left',
											marginBottom: '0px',
											marginTop: '2px',
										}}
										initText={coalesce("", this.state.eidData.document_number) }
										changeCallback={this.changePassportData}
										enterCallback={this.changePassportData}
										disabled={false}
									/>
							</EditableWrapper>
							<EditableWrapper>
								<KeyDateComponent 
									translationkey="customers.expiryDate"
									value={this.state.eidData.expiry_date != null ? new Date(this.state.eidData.expiry_date) : undefined}
									id="expiryDate"
									onChange={((key: string, value: Date) => {
										this.setExpiryDate(value);
									})}
									disabled={true}
									number={1}
								/>  
							</EditableWrapper>
							<EditableWrapper>
								<EditableComponent 
										translationkey="customers.advancedKyc.input.state"
										id="status"
										initText={coalesce( "", this.state.eidData.status )}
										changeCallback={this.changePassportData}
										enterCallback={this.changePassportData}
										disabled={true}
										inputStyle={{
											textAlign: 'left',
											marginBottom: '0px',
											marginTop: '2px',
										}}
									/>
							</EditableWrapper>
							<EditableWrapper>
								<EditableComponent 
										translationkey="customers.issueingAuth"
										id={ "issuer"  }
										initText={coalesce("", this.state.eidData.issuer  )}
										changeCallback={this.changePassportData}
										enterCallback={this.changePassportData}
										disabled={false}
										inputStyle={{
											textAlign: 'left',
											marginBottom: '0px',
											marginTop: '2px',
										}}
									/>
							</EditableWrapper>
							<EditableWrapper>
								<EditableComponent 
										translationkey="input.nationality.label"
										id="nationality"
										initText={coalesce( "", this.state.eidData.nationality )}
										changeCallback={this.changePassportData}
										enterCallback={this.changePassportData}
										disabled={false}
										inputStyle={{
											textAlign: 'left',
											marginBottom: '0px',
											marginTop: '2px',
										}}
									/>
							</EditableWrapper>
                            <EditableWrapper>
								<KeySelectComponent 
									translationkey="input.gender.label"
									id="gender"
									selected={ coalesce("", this.state.eidData.sex) }
									options={this.getSexOptions()}
									disabled={false}
									onChange={(value: string, key: string) => {
										this.setSex(value)
									}}
                       			/>
							</EditableWrapper>
							<EditableWrapper>
								<LargeKeyValueBlock>
									<KeyField>Eid-ID</KeyField>
									<ValueField>
										{this.state.eidData != null && this.state.eidData.verification_id != null ? this.state.eidData.verification_id : ''}
									</ValueField>
								</LargeKeyValueBlock>
							</EditableWrapper>
							<EditableWrapper>
								<LargeKeyValueBlock>
									<KeyField>{translate('customers.dateOfIdentification')}</KeyField>
									<ValueField>
										{this.state.eidData != null && this.state.eidData.verification_date != null ? format.date(this.state.eidData.verification_date ) : ''}
									</ValueField>
								</LargeKeyValueBlock>
							</EditableWrapper>
                            <EditableWrapper>
								<KeyDateComponent 
										translationkey="customers.birthdate"
										value={this.state.eidData.birth_date != null ? new Date(this.state.eidData.birth_date) : undefined}
										id="birth_date"
										onChange={((key: string, value: Date) => {
											this.setBirthDate(value);
										})}
										disabled={false}
										number={2}
									/>  
							</EditableWrapper>
							<EditableWrapper>
							<EditableComponent
									translationkey="customers.user_class"
									id="client_profile"
									initText={ coalesce('' , this.state.passport?.family_relation ) }
									inputStyle={{
										textAlign: 'left',
										marginBottom: '0px',
										marginTop: '2px',
									}}
									changeCallback={() => {
										
									}}
									enterCallback={this.changePassportData}
									disabled={true}
								/>
						</EditableWrapper>
						{this.state.passport?.family_relation === 'junior' ?
						<React.Fragment>
							<EditableWrapper>
								<EditableComponent
										translationkey="customers.parent_person_id"
										id="parent_person_id"
										initText={ coalesce('' , this.state.passport?.parent_person_id.toString() ) }
										inputStyle={{
											textAlign: 'left',
											marginBottom: '0px',
											marginTop: '2px',
										}}
										changeCallback={() => {
											
										}}
										enterCallback={this.changePassportData}
										disabled={true}
									/>
							</EditableWrapper>
							<EditableWrapper>
							<EditableComponent
									translationkey="customers.parent_name"
									id="parent_name"
									initText={ coalesce('' , this.state.passport?.parent_name ) }
									inputStyle={{
										textAlign: 'left',
										marginBottom: '0px',
										marginTop: '2px',
									}}
									changeCallback={() => {
										
									}}
									enterCallback={this.changePassportData}
									disabled={true}
								/>
							</EditableWrapper>
							<EditableWrapper>
								<EditableComponent
										translationkey="customers.parent_given_name"
										id="parent_given_name"
										initText={ coalesce('' , this.state.passport?.parent_given_name ) }
										inputStyle={{
											textAlign: 'left',
											marginBottom: '0px',
											marginTop: '2px',
										}}
										changeCallback={() => {
											
										}}
										enterCallback={this.changePassportData}
										disabled={true}
									/>
						</EditableWrapper>
					</React.Fragment>
						: null}
							<EditableWrapper style={{height: '140px'}}>
									<KeyField>
										{translate('transfer.details.documents')}
									</KeyField>
								<ValueField>
									<FlexBox style={{alignItems: 'center', flexDirection: 'row', overflowX: 'auto'}}>
										{ this.state.passportFront != null || this.state.passportBack != null || this.state.passportStored != null 
											? <ImageBox images={this.generateImages()}/>  
											: null }
									</FlexBox>
								</ValueField>
							</EditableWrapper> 
							<EditableWrapper>
									<KeyField>
										
									</KeyField>	
								<ValueField >
									<ButtonOk
									  key={this.state.keyForLoadingSpinner}
									  disabled={this.props.state === Ident.PersonStateEnum.C || this.props.is18 === false }
									  onClick={() => this.requestKyc()}
									  id="btnRequestKyc">
									   {translate('customers.resetKyc')}	
									</ButtonOk>
								</ValueField>
							</EditableWrapper>
						</ColumnBox>
					</WrapperInner>
					  	<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '40%',
						}}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginTop: '65px',
									justifyContent: 'flex-start',
								}}>
								<ButtonCancel
									id="btnEditCustomerDetailsCancel"
									onClick={() => {}}>
									{translate('button.cancel')}
								</ButtonCancel>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginTop: '65px',
									justifyContent: 'flex-start',
								}}>
								<ButtonOk
									key={this.state.keyForLoadingSpinner}
									onClick={() => this.savePassportData()}
									id="btnEditCustomerDetailsOk">
									{translate('customers.details.approval')}
								</ButtonOk>
							</div>
						</div>
				</StyledViewMain>
                <FlexBox style={{ height: '29px', width: '30px' }} />
			</FlexBox>
		);
	}
}

const WrapperInner = styled(FlexBox)`
	width: 100%;
	min-width: 300px;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const LargeKeyValueBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 450px;
    height: 62px;
    border-bottom: 1px solid #DEDEDE;
    padding: ${(props) => props.theme.Table.TBody.Td.padding};
    margin: 8px;
    padding: 8px;
`;