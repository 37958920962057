// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReleaseType {
    CreatePerson = 'create_person',
    CloseAccount = 'close_account',
    ApproveAddress = 'approve_address',
    ApproveAdvancedKyc = 'approve_advanced_kyc',
    ApproveDocument = 'approve_document',
    AddressSave = 'address_save',
    AddressUpdate = 'address_update',
    RequestKyc = 'request_kyc',
    RegulaApproveDocument = 'regula_approve_document'
}

