import React, { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { AGreyOverlay } from './globaloverlays/aGreyOverlay';
import { Log, Logs } from '../../log';
import { MessageHandler } from '../messagehandler/messageHandler';
import { Reporter } from '../messagehandler/messageHandlerConfig';

export enum Overlays {
	alerts = 'alerts',
	notifications = 'notifications',
	transactionDetail = 'transactionDetail',
	postingJournal = 'postingJournal',
	teamMember = 'teamMember',
	userMenu = 'userMenu',
	changePassword = 'changePassword',
	closeAccount = 'closeAccount',
	errorMessage = 'errorMessage',
	successMessage = 'successMessage',
	backofficeSearchHelp = 'backofficeSearchHelp',
	confirmationOverlay = 'confirmationOverlay',
	addCustomer = 'addCustomer',
	ConfirmationRequestOverlay = 'ConfirmationRequestOverlay',
	messageBubble = 'messageBubble',
	roleEdit = 'roleEdit',
	messageMenu = 'messageMenu',
	datePicker = 'datePicker',
	datePickerRangeElement = 'datePickerRangeElement',
	iconPicker = 'iconPicker',
	imagePreview = 'imagePreview',
	developer = 'developer',
	toolTip = 'toolTip',
	FileTypeChooser = 'FileTypeChooser',
	PDFViewer = 'PDFViewer',
	contextMenu = 'contextMenu',
    warningOverlay = 'warningOverlay',
    addressOverlay = 'addressOverlay',
    fileChooserOverlay ='fileChooserOverlay',
	imageSelectOverlay = 'imageSelectOverlay',
	multipleFileChooser = 'multipleFileChooser',
	emailOverlay = 'emailOverlay',
	productOverlay = 'productOverlay',
	reportOverlay = 'reportOverlay',
	selectProductImageOverlay = 'selectProductImageOverlay',
	confirmationRequestOverlayWithReason = 'confirmationRequestOverlayWithReason',
	limitInfoOverlay = 'limitInfoOverlay',
	productTypeOverlay = 'productTypeOverlay',
	kycDataOverlay = 'kycDataOverlay',
	addCalendarItemOverlay = 'addCalendarItemOverlay',
	addCalendarItemExceptionOverlay = 'addCalendarItemExceptionOverlay',
	addCalendarItemExcludeOverlay = 'addCalendarItemExcludeOverlay',
	popupMenuComponent = 'popupMenuComponent',
	requestDataOverlay = 'requestDataOverlay'
}

export interface IOverlayHandlerOptions {
	overlayId: string;
	dupilcate?: boolean;
	modal?: boolean; // default true -> Screen is greyed out
	isClosedOnClickOutside?: boolean; // default true
	closesOpenedOverlays?: boolean; // default true -> false means: Show overlay on top of other overlays,
	component: React.ComponentClass<any, any>;
	isCentered?: boolean;
}
export interface IOverlayOptions {
	[key: string]: IOverlayHandlerOptions;
}
export interface IOverlayData {
	onClose?: () => void;
	[key: string]: any;
}

const CloseExceptions = [
	Overlays.messageBubble.toString()
]

type OverlayType = ReactElement;

class IOverlayHandler {
	private currentOverlays: Array<OverlayType> = [];
	private currentOptions: IOverlayHandlerOptions[] = [];
	private static instance: IOverlayHandler | null = null;
	private appCallback: (current: number) => void = () => {};
	private options: IOverlayOptions = {};
	private updateAppFunc: Function = () => {};

	private constructor() {
		this.closeOverlaysOnClick = this.closeOverlaysOnClick.bind(this);
		this.closeOverlaysOnModal = this.closeOverlaysOnModal.bind(this);
	}

	static getInstance() {
		if (IOverlayHandler.instance == null) {
			IOverlayHandler.instance = new IOverlayHandler();
		}
		return IOverlayHandler.instance;
	}

	setOptions(options: IOverlayOptions, callback: (current: number) => void): void {
		this.options = options;
		this.appCallback = callback;
	}

	setCallback(callback: () => void) {
		this.appCallback = callback;
	}

	setUpdateApp(func: any) {
		this.updateAppFunc = func;
	}

	updateApp() {
		if (this.updateAppFunc !== null) {
			this.updateAppFunc();
		}
	}

	hasOverlay(overlay: Overlays) {
		for(const i of this.currentOptions) {
			if(i.overlayId === overlay.toString()) {
				return true;
			}
		}
		return false;
	}

	getOverlayJson() {
		const overlayJson: [{ key: string; data: {} }?] = [];

		for (const i in this.currentOverlays) {
			overlayJson.push({
				key: this.currentOptions[i].overlayId,
				data: this.currentOverlays[i].props,
			});
		}
		return overlayJson;
	}

	closeSpecific(overlay: string) {
		const newOv: Array<OverlayType> = [];
		const newOpt: Array<IOverlayHandlerOptions> = [];
		let done: boolean = false;
		const closeOverlays: Array<OverlayType> = [];
		for (const i in this.currentOverlays) {
			if (this.currentOptions[i].overlayId !== overlay || done) {
				newOv.push(this.currentOverlays[i]);
				newOpt.push(this.currentOptions[i]);
			} else {
				closeOverlays.push(this.currentOverlays[i]);
				if (this.currentOptions[i].dupilcate === true) {
					done = true;
				}
			}
		}
		this.currentOptions = newOpt;
		this.currentOverlays = newOv;
		this.appCallback(this.currentOverlays.length);

		closeOverlays.forEach((overlay) => {
			if (overlay.props.onClose != null && typeof overlay.props.onClose === 'function') {
				overlay.props.onClose();
			}
		});
	}

	findDuplicate(id: string): boolean {
		for (const i in this.currentOverlays) {
			if (
				this.currentOptions[i].overlayId === id &&
				(this.currentOptions[i].dupilcate == null ||
					this.currentOptions[i].dupilcate === false)
			) {
				this.closeSpecific(id);
				return true;
			}
		}
		return false;
	}

	terminate() {
		this.currentOverlays = [];
		this.currentOptions = [];
		//this.updateApp();
	}

	closeAllOverlays() {
		const newCurrentOverlays =[];
		const newCurrentOptions = [];
		for (const i in this.currentOptions) {
			if(CloseExceptions.indexOf(this.currentOptions[i].overlayId.toString()) > - 1) {
				newCurrentOptions.push(this.currentOptions[i]);
				newCurrentOverlays.push(this.currentOverlays[i]);
			}
		}
		this.currentOverlays = newCurrentOverlays;
		this.currentOptions = newCurrentOptions;
		this.updateApp();
	}

	showOverlay(overlayName: Overlays, data: IOverlayData = {}) {
		if (this.findDuplicate(overlayName.toString())) {
			return;
		}
		const overlay = this.options[overlayName.toString()];
		if (overlay.closesOpenedOverlays) {
			this.closeAllOverlays();
		}

		try {
			const el: ReactElement | undefined = React.createElement(overlay.component, {
				...data,
				selfClose: () => this.closeSpecific(overlay.overlayId),
			});
			if (el == null) {
				MessageHandler.onError(Reporter['overlayhandler.noelement.error']);
				return;
			}
			if (overlay.modal) {
				this.currentOverlays.push(
					<AGreyOverlay
						callback={this.closeOverlaysOnModal}
						onClose={data.onClose}
						centered={overlay.isCentered === true}
						key={overlayName.toString() + this.currentOverlays.length.toString()}>
						{el}
					</AGreyOverlay>
				);
			} else {
				this.currentOverlays.push(el);
			}

			this.currentOptions.push(overlay);
			this.appCallback(this.currentOverlays.length);
		} catch (e) {
			Log.error(Logs.SYSTEM, e);
		}
	}

	closeOverlaysOnModal(event: SyntheticEvent) {
		//array.filter didnt work here for some reason.. to do is do mange this
		event.stopPropagation();
		const newOv: Array<OverlayType> = [];
		const newOpt: Array<IOverlayHandlerOptions> = [];
		for (const i in this.currentOverlays) {
			if (
				!this.currentOptions[i].modal ||
				!this.currentOptions[i].isClosedOnClickOutside
			) {
				newOv.push(this.currentOverlays[i]);
				newOpt.push(this.currentOptions[i]);
			}
		}
		this.currentOptions = newOpt;
		this.currentOverlays = newOv;
		this.appCallback(this.currentOverlays.length);
	}

	closeOverlaysOnClick() {
		if (this.currentOverlays.length === 0) {
			return;
		}
		const newOv = [];
		const newOpt = [];
		for (const i in this.currentOverlays) {
			if (!this.currentOptions[i].isClosedOnClickOutside) {
				newOv.push(this.currentOverlays[i]);
				newOpt.push(this.currentOptions[i]);
			}
		}
		this.currentOptions = newOpt;
		this.currentOverlays = newOv;
		this.appCallback(this.currentOverlays.length);
	}

	getOverlays() {
		return (
			<OverlayView>
				{this.currentOverlays.map((element) => {
					return element;
				})}
			</OverlayView>
		);
	}
}

const OverlayView = styled.div`
	position: absolute;
	z-index: 2500;

	width: 0;
	height: 0;
`;

export const OverlayHandler = IOverlayHandler.getInstance();
