import styled from "styled-components";
import { StyledInput } from "../form/inputs.css";

export const TextView = styled.div<{hasPermission: boolean, hasError?: boolean, isEditbale?: boolean}>`
    text-align: left;
    color: ${(props) => props.isEditbale === false ? '#6A6C6E' : props.theme.Global.darkFontColor};
    line-height: 24px;
    font-size: 14px;
    margin-right: 10px;
    background-color: ${(props) =>
         props.hasPermission === true ? 'white'  : props.theme.Global.backgroundColor };
    height: 24px;
    border:  ${(props) =>
        props.hasError === true ? '1px solid grey' : 'none' };
    width: 100%;
    font-weight: 400;
    font-family: Roboto;
    &:hover {
        font-weight: 500;
        color: ${(props) =>
            props.hasPermission === true ? props.theme.Button.backgroundColor : props.theme.Global.lightFontColor };
    }
`;
//@ts-ignore
export  const InputView = styled(StyledInput)`
    width: 100%;    
    & > div {
            width: 100%;
        }
    & > div > input,
        & > input  {
        height: 20px;
        width: 100%;
        font-size: 14px;
        font-family: Roboto;
        background: rgba(0, 0, 0 ,0);
        box-sizing: border-box;
        box-shadow: none;
        border-radius: 2px;
        line-height: 20px;
        color:  ${props => props.theme.Global.darkFontColor};
        margin-right: 10px;
        text-align: left;
		margin-bottom: 0px;
		margin-top: 2px;
}
    
`;

export const LargeKeyValueBlock = styled.div<{big?: boolean}>`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 450px;
    height: ${props => props.big === true ? '102px' : '62px' };
    border-bottom: 1px solid #DEDEDE;
    padding: ${(props) => props.theme.Table.TBody.Td.padding};
    margin: 8px;
    padding: 8px;
`;