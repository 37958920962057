// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * the impression type   * \'0\' - Live-scan plain   * \'1\' - Live-scan rolled   * \'2\' - Nonlive-scan plain   * \'3\' - Nonlive-scan rolled   * \'4\' - Latent impression    * \'5\' - Latent tracing   * \'6\' - Latent photo    * \'7\' - Latent lift   * \'8\' - Live-scan vertical swipe   * \'10\' - Live-scan palm   * \'11\' - Nonlive-scan palm   * \'12\' - Latent palm impression   * \'13\' - Latent palm tracing   * \'14\' - Latent palm photo   * \'15\' - Latent palm lift   * \'20\' - Live-scan optical contact plain   * \'21\' - Live-scan optical contact rolled   * \'22\' - Live-scan non-optical contact plain   * \'23\' - Live-scan non-optical contact rolled   * \'24\' - Live-scan optical contactless plain   * \'25\' - Live-scan optical contactless  rolled   * \'26\' - Live-scan non-optical contactless plain   * \'27\' - Live-scan non-optical contactless rolled   * \'28\' - Other   * \'29\' - Unknown 
 * @export
 * @enum {string}
 */
export enum NistImpressionType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_15 = 15,
    NUMBER_16 = 16,
    NUMBER_17 = 17,
    NUMBER_18 = 18,
    NUMBER_19 = 19,
    NUMBER_20 = 20,
    NUMBER_21 = 21,
    NUMBER_22 = 22,
    NUMBER_23 = 23,
    NUMBER_24 = 24,
    NUMBER_25 = 25,
    NUMBER_26 = 26,
    NUMBER_27 = 27,
    NUMBER_28 = 28,
    NUMBER_29 = 29
}

