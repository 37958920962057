//@ts-nocheck
import React from 'react';
import styled from 'styled-components';

import { IStatusState } from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { Table } from '../../../../components/compositcomponents/table/table';
import { RowType } from '../../../../components/compositcomponents/table/tableTypes';
import { CustomDatePicker } from '../../../../components/datepicker';
import { KeyValueBlock, KeyField, ValueField } from '../customers/basicStyledComponents/customerDetails.css';
import { DisplayMenuComponent } from '../../../../components/compositcomponents/displayMenuComponent';

import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import { ContainerText,StyledHr } from '../transfer/transfersComponent.css';
import { Box } from '../../../../components/atomiccompoents/boxes/box';


import { format } from '../../../../logic/helper/format';
import Title from '../../../../components/compositcomponents/title';

import { api, apis, ApiError, Account } from '../../../../logic/api/index';
import { Log, Logs } from '../../../../logic/log';



interface IProps{
	startDate?: Date;
    endDate?: Date;
    onChangeDate?: (startDate: Date, endDate: Date) => void;
}

interface IState extends IStatusState{
    startDate?: Date;
	endDate?: Date;
    stats?: Array<Account.BookingOverview>;
}


export default class SystemStateComponent extends React.Component<IProps,IState>{
   
    private tableRef: Table<Account.BookingOverview> | null = null;
     
    constructor(props: IProps){
        super(props);
		const endDate = new Date();
		const startDate = new Date();
		this.state = {
            stats: [],
            startDate: props.startDate != null ? props.startDate : startDate,
			endDate: props.endDate != null ? props.endDate : endDate,
        };
		this.resetEndDate = this.resetEndDate.bind(this);
		this.resetStartDate = this.resetStartDate.bind(this);
		this.onChangeStartDate = this.onChangeStartDate.bind(this);
		this.onChangeEndDate = this.onChangeEndDate.bind(this);
		this.getSystemStats();
    };
    
    onChangeStartDate(date: Date) {
		this.setState({
			startDate: new Date(date),
		}, () => {
			this.getSystemStats()
		});
	}

    onChangeEndDate(date: Date) {
		this.setState({
			endDate: new Date(date),
		},() => {
			this.getSystemStats()
		});
    }
    
    private resetStartDate() {
		this.setState({
			startDate: undefined,
		});
    }
    
	private resetEndDate() {
		this.setState({
			endDate: undefined,
		});
	}

    getSystemStats(): void{
		const request = {
			date_from: this.state.startDate ,
			date_to: this.state.endDate 
	   }
	   api.asyncRequest<Array<Account.BookingOverview>>(
		   request,
            apis.BookingApi,
            'bookingOverviewGet',
            ).then((response: Array<Account.BookingOverview>) => {
            const fin = response;
            if(response != null) {
                this.setState({
                    stats: fin
				});
			}
            }).catch((error: ApiError) => {
            Log.error(Logs.API, "Error on getting stats", error);
            });
    } 

	converter = function(
        line: Account.BookingOverview,
        index: number,
		fields: Array<string>
	): RowType {
		const row: RowType = { cells: [], ref: line };
		if (row.cells == null) {
			return row;
		}
		const keys: Array<string> = Object.keys(line);
		for (let i = 0; i < fields.length; i++) {
			const field: string = fields[i];
			switch (field) {
                case 'date_create': {
                    const valueDate: string | undefined = format.date(line[field]);
					row.cells.push({
						value: valueDate == null ? '-' : valueDate,
						display: (
							<ContainerText title={valueDate}>
								{valueDate == null ? '-' : valueDate}
							</ContainerText>
						),
						copyVal: valueDate != null ? valueDate.toString() : '-',
                        methods: {},
					});
					break;
				}
				case 'avg_timeused':
				case 'med_timeused':
				case 'p99_timeused':
				case 'max_timeused':
				case 'min_timeused': {
					if (Object.prototype.hasOwnProperty.call(line, field)) {
						const val = format.number(Number(Object.values(line)[keys.indexOf(field)]),3);
						row.cells.push({
							value: val,
							display: <ContainerDecimals 
										title={String(val)}>
											{String(val)}
									</ContainerDecimals>,
							methods: {},
							copyVal: String(Number(Object.values(line)[keys.indexOf(field)]).toFixed(3))
							});
					} else {
						row.cells.push({ value: '' });
					}
					break;
				}
				default: {
					if (Object.prototype.hasOwnProperty.call(line, field)) {
						row.cells.push({
							value: String(Object.values(line)[keys.indexOf(field)]),
							display: <ContainerText 
										title={String(Object.values(line)[keys.indexOf(field)])}>
											{String(Object.values(line)[keys.indexOf(field)])}
									</ContainerText>,
							methods: {},
							copyVal: String(Object.values(line)[keys.indexOf(field)])
							});
					} else {
						row.cells.push({ value: '' });
					}
					break;
				}
			}
		}
		return row;
	};

    render(){
        return 	<FlexBox>
                    <Box>
            		    <div>
					        <Title title={translate('administration.systemstate.transactionstats')} />
					    </div>
                        <StyledHr />
				        <Dates>
				        	<KeyValueBlock style={{width: '30%', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
				        		<KeyField>
				        			{translate('administration.systemstate.dateFrom')}:
				        		</KeyField>	
				        		<ValueField >
				        			<DisplayMenuComponent
				        					elementKey="startDate"
				        					copyValue={this.state.startDate != null ? this.state.startDate.toString() : ''}
				        					methods={{
				        					}}
				        					>
				        				<CustomDatePicker 
				        					selectedValue={this.state.startDate}
				        					onChange={this.onChangeStartDate}
				        					displayDate={true}
				        					resetCallback={this.resetStartDate}
				        					boxStyle={{
				        						boxShadow: 'none',
				        						borderRadius: '0px',
				        						height: '24px',
				        						border: 'none',
				        						margin: '0px',
				        						justifyContent: 'flex-start',
				        					}}
				        					textStyle={{
				        						color: '#4a4a4a',
				        						textAlign: 'left',
				        						margin: '0px',
				        						marginLeft: '8px',
				        						marginRight: '8px',
				        						fontWeight: 400,
				        						fontSize: '16px',
				        					}}
				        					maxDate={this.state.endDate}
				        					number={0}
				        					toggleHeightOffset={16}
				        					smallText={true}
				        				/>
				        			</DisplayMenuComponent>
				        		</ValueField>
				        	</KeyValueBlock>
				        	<KeyValueBlock style={{width: '30%', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
				        		<KeyField>
				        			{translate('administration.systemstate.dateTo')}:
				        		</KeyField>	
				        		<ValueField >
				        			<DisplayMenuComponent
				        					elementKey="endDate"
				        					copyValue={this.state.endDate != null ? this.state.endDate.toString() : ''}
				        					methods={{
				        					}}
				        					>
				        				<CustomDatePicker 
				        					selectedValue={this.state.endDate}
				        					onChange={this.onChangeEndDate}
				        					displayDate={true}
				        					resetCallback={this.resetEndDate}
                                        
				        					boxStyle={{
				        						boxShadow: 'none',
				        						borderRadius: '0px',
				        						height: '24px',
				        						border: 'none',
				        						margin: '0px',
				        						justifyContent: 'flex-start',
				        					}}
				        					textStyle={{
				        						marginRight: '0px',
				        						color: '#4a4a4a',
				        						textAlign: 'left',
				        						margin: '0px',
				        						fontWeight: 400,
				        						fontSize: '16px'
				        					}}
				        					minDate={this.state.startDate}
				        					number={1}
				        					toggleHeightOffset={16}
				        					smallText={true}
				        				/>
				        			</DisplayMenuComponent>
				        		</ValueField>
				        	</KeyValueBlock>
				        </Dates>         
                        <Table<Account.BookingOverview>
                            header={[												
                                translate('administration.systemstate.name'),
								translate('administration.systemstate.date_create'),
								translate('administration.systemstate.number_of_txn'),
                                translate('administration.systemstate.unbooked_txn'),
                                translate('administration.systemstate.min_timeused'),
                                translate('administration.systemstate.avg_timeused'),
                                translate('administration.systemstate.med_timeused'),
                                translate('administration.systemstate.p99_timeused'),
                                translate('administration.systemstate.max_timeused'),
                            ]}
                            sourceData={this.state.stats != null ? this.state.stats : []}
                            fields={[												
                                'name',
                                'date_create',
                                'number_of_txn',
                                'unbooked_txn',
                                'min_timeused',
                                'avg_timeused',
                                'med_timeused',
                                'p99_timeused',
                                'max_timeused',
                            ]}
                            stickyHeader={true}
                            highlightTextOnSearch={true}
                            dataConverter = {this.converter}
                            ref={(element) => (this.tableRef = element)}
                        />
                    </Box>
                </FlexBox>
               
    }
    
}

const Part = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 1;
`;

const Dates = styled(Part)`
	width: 100%;
`;

const ContainerDecimals = styled(ContainerText)`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;

