// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * needed parameters to exchange an authorization code for an access_token. as openapi3 does not allow to specify dependencies between parameters and at least the erlang and the typescript code generator have difficulties with \"oneOf\". therefore the required parameters for each grant type are just written down in plaintext
 * @export
 * @interface TokenForm
 */
export interface TokenForm {
    /**
     * the grant type
     * @type {string}
     * @memberof TokenForm
     */
    grant_type: TokenFormGrantTypeEnum;
    /**
     * The OAuth 2.0 client identifier, obtained at registration.
     * @type {string}
     * @memberof TokenForm
     */
    client_id: string;
    /**
     * The OAuth 2.0 client password, obtained at registration.
     * @type {string}
     * @memberof TokenForm
     */
    client_secret: string;
    /**
     * The redirection URI to which the response will be sent. It must exactly match one of the registered redirection URIs for the client. required for grant type authorization_code.
     * @type {string}
     * @memberof TokenForm
     */
    redirect_uri?: string;
    /**
     * the authorization code issued by the idp, required for grant type authorization_code.
     * @type {string}
     * @memberof TokenForm
     */
    code?: string;
    /**
     * the refresh token, required for grant type refresh_token
     * @type {string}
     * @memberof TokenForm
     */
    refresh_token?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum TokenFormGrantTypeEnum {
    AuthorizationCode = 'authorization_code',
    RefreshToken = 'refresh_token'
}

