// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface Reason
 */
export interface Reason {
    /**
     * the specific error that occured
     * @type {string}
     * @memberof Reason
     */
    type: ReasonTypeEnum;
    /**
     * the error message, legth may change, when new messages are defined
     * @type {string}
     * @memberof Reason
     */
    message: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ReasonTypeEnum {
    DocumentAspect = 'Document.Aspect',
    EnvironmentNotAppropriate = 'Environment.NotAppropriate',
    DocumentNotLegible = 'Document.NotLegible',
    PhotoInvalid = 'Photo.Invalid',
    PersonUnderage = 'Person.Underage',
    DocumentDoesNotMatchData = 'Document.DoesNotMatchData',
    DocumentNotSecurityElements = 'Document.NotSecurityElements',
    PhotoDoesNotMatchPerson = 'Photo.DoesNotMatchPerson',
    DocumentExpired = 'Document.Expired',
   
    LivenessNotDetected = 'Liveness.NotDetected',
    UserAborted = 'User.Aborted',
    BandwidthTooLow = 'Bandwidth.TooLow',
    MaxLengthExceeded = 'MaxLength.Exceeded',
   
    CustomQuestion = 'Custom.Question'
}

