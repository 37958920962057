import { responsiveFontSizes } from '@material-ui/core';
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { ClientConfig } from '../../../../../common/config';
import { getCountry } from '../../../../../common/language/getCountry';
import { translate } from '../../../../../common/language/translate';
import ButtonCancel from '../../../../../components/atomiccompoents/buttons/buttonCancel';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import EditableComponent from '../../../../../components/atomiccompoents/editableComponent/editableComponent';
import { IOption } from '../../../../../components/atomiccompoents/form';
import { Image } from '../../../../../components/atomiccompoents/image';
import KeyCheckBoxComponent from '../../../../../components/atomiccompoents/keyCheckboxComponent';
import KeyTextComponent from '../../../../../components/atomiccompoents/keyTextComponent';
import { IRequestData, DataTypeEnum } from '../../../../../components/compositcomponents/popup/requestDataOverlay';
import Title from '../../../../../components/compositcomponents/title';
import { Config } from '../../../../../config';
import { Icons } from '../../../../../images';
import { api, ApiError, apis, Account, Ident } from '../../../../../logic/api';
import { Iso4217Currency } from '../../../../../logic/api/account';
import { Face } from '../../../../../logic/api/ident';
import { UserDataStore } from '../../../../../logic/flux';
import { PermissionStore } from '../../../../../logic/flux/stores/permissionStore';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import { OverlayHandler, Overlays } from '../../../../../logic/handler/overlayhandler/overlayHandler';
import { coalesce, evaluateErrorMessage } from '../../../../../logic/helper/Common';
import { format } from '../../../../../logic/helper/format';
import { Log, Logs } from '../../../../../logic/log';
import { RowBox } from '../../../../auth/auth.css';
import { IconWrapper, ImageCircle, StyledViewButtons } from '../../customers/basicStyledComponents/customerDetails.css';
import { TestResultComponent } from './testresultComponent';



const Main = styled.div`
    display: flex,
    flex-direction: column;
    align-items: center;
` ;

const VideoBox = styled.div`
    margin: 16px;
    width: 70%;
    display: flex;
    flex-direction: column;
`;

const Video = styled.video`
    width: 70%;
    border: 2px solid ${props => props.theme.Global.primaryColor};
`;

const CheckBoxBox = styled.div`
    display: flex;
    flex-direction: column;
    margin:16px;
    align-items: center;
    width: 25%    
`;

const TestKitBox = styled.div` 
    font-size: 24px;
    font-weight: 550;
    text-align: center;
    margin-top: 16px;
`;

const DataBox = styled.div` 
    display: flex;
    flex-direction: row;
    width: 80%;
`;

const ControlBox = styled(DataBox)`
    justify-content: center;
    margin: 8px;
    width: auto;
`;

const SpeedBox = styled.div`
    font-weight: 500;
    font-size: 20px;
    color: ${props => props.theme.Global.primaryColor};
`;

const VideoSelect = styled.div<{isSelected?: boolean}>`
    font-weight: 500;
    padding: 4px;
    border-radius: 5px;
    border: 2px solid ${props => props.theme.Global.primaryColor};
    color: ${props => props.isSelected === true ?  props.theme.Global.primaryColor :  props.theme.Global.darkFontColor};
    margin: auto;
    :hover {
        cursor: pointer;
    }
`;

const ErrorBox = styled.div`
    font-weight: 600;
    max-width: 150px;
    text-wrap: wrap;
    color: #8B0000;
    font-size: 16px;
`;

const VideoContainer = styled(DataBox)`
    margin: 16px;
    justify-content: center;
    width: auto;
`;

const antigens: Array<string> = [
    "Antigen", "Antigen_Citizen", "Antibody_Light", "Antibody", "Antigen_Citizen_HH", "Antigen_Citizen_BG"
];

const checkbox_fields: {[key:string]: Array<string>} = {
    "antigen": [
        'open_testkit_during_video',
        'person_performs_test',
        'testkit_always_in_video',
        'video_not_cut',
        'result_readable',
        'performing_person_and_testkit_match'
        ],
    "pcr": [
        'open_testkit_during_video',
        'person_performs_test',
        'testkit_always_in_video',
        'video_not_cut',
        'barcode_readable',
        'performing_person_and_testkit_match'
        ]
    }

interface IProps {
	callback: () => void;
	release_request_id: number;
 }

interface IState {
    keyForLoadingSpinner: number;
    data?: Account.EyesonRecording;
    person?: Ident.Person;
    checkboxes: {[key: string] : boolean};
    face?: Blob;
    video?: string;
    videos?: Array<Account.EyesonRecording>;
    collectedVideos: {[video_id: number]: string}
    currentVideo?: any;
    loading: boolean;
    videoSpeed: number;
    testKit?: Account.ProductTypeOut;
    done?: boolean;
    error?: string;
    passRequest?: Account.EyesonRecordingPutRequest;
    merchants?: Array<Account.MerchantAddress>;
    voucherReq?: Account.ProductVoucherPostRequest;
    details?: Account.ProductOrderDetails;
    type: 'antigen'  | 'pcr'
}


class VideoVerification extends React.Component<IProps, IState> {
    private videoRef: HTMLVideoElement | null = null;

	constructor(props: IProps) {
		super(props);
		this.state = {
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            checkboxes: {
                open_testkit_during_video: false,
			    person_performs_test: false,
			    testkit_always_in_video: false,
			    video_not_cut: false,
			    result_readable: false,
			    performing_person_and_testkit_match: false,
                barcode_readable: false
            },
            loading: true,
            videoSpeed: 1,
            done: false,
            collectedVideos: {},
            type: 'antigen'
		};
        this.setRef = this.setRef.bind(this);
        this.showVoucherOverlay = this.showVoucherOverlay.bind(this);
        this.setVoucher = this.setVoucher.bind(this);
       
	}

     fetchData() {
		const req: Account.EyesonRoomsGetRequest = {
            release_requests_id: this.props.release_request_id
        }
		api.asyncRequest<Array<Account.EyesonRecording>>(
			req,
			apis.EyesonApi,
            'eyesonRoomsGet')
            .then((response: Array<Account.EyesonRecording>) => {
                if(response.length > 0) {
                    this.setState({
                        data: response[0],
                        videos: response,
                    }, () => {
                        if(response[0].person_id != null) {
                            api.asyncRequest<Ident.Person>(
                                { person_id: response[0].person_id },
                                apis.MaintenanceApi,
                                'personPersonIdGet')
                                .then((responsePerson: Ident.Person) => {
                                    this.setState({
                                        person: responsePerson
                                    })
                                }).catch(( error: ApiError) => {
                                    Log.error(Logs.API, error);
                                });
                            api.asyncRequest<Array<Face>>(
                                { person_id: response[0].person_id},
                                apis.MaintenanceApi,
                                'personFaceList'
                            )
                            .then((responseFace: Array<Face>) => {
                                if (responseFace != null && responseFace.length > 0) {                              
                                        api.asyncRequest<Blob>(
                                            {
                                                person_id: response[0].person_id,
                                                face_id: responseFace[0].face_id,
                                            },
                                            apis.MaintenanceApi,
                                            'personFaceGet'
                                        ).then((newResponse: Blob) => {
                                            this.setState({
                                                face: newResponse,
                                            });
                                        });
                                    }
                            })
                            .catch((error: ApiError) => {
                                Log.error(Logs.API, error);
                                if(error != null) {
                                    MessageHandler.onError(Reporter['customer.details.identification'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
                                }
                            });
                        if(response[0].eyeson_add_recording_id != null) {
                            api.asyncRequest<Account.Link>(
                                { eyeson_add_recording_id: response[0].eyeson_add_recording_id },
                                apis.EyesonApi,
                                'eyesonRecordingGet')
                                .then((response: Account.Link) => {
                                    this.setState({
                                        video: response.link
                                    })
                                }).catch(( error: ApiError) => {
                                    Log.error(Logs.API, error);
                                });
                        }
                        if(response[0].ta_id != null && response[0].transaction_id != null) {
                            api.asyncRequest<Account.ProductTypeOut>(
                                { ta_id: response[0].ta_id, transaction_id : response[0].transaction_id },
                                apis.DefaultApi,
                                'productOrderProductTypeGet')
                                .then((response: Account.ProductTypeOut) => {
                                    this.setState({
                                        testKit: response
                                    })
                                }).catch(( error: ApiError) => {
                                    Log.error(Logs.API, error);
                                });
                            }
                            api.asyncRequest<Account.ProductOrderDetails>(
                                { ta_id: response[0].ta_id, transaction_id: response[0].transaction_id },
                                apis.DefaultApi,
                                'productOrderGet').then((detailsResponse : Account.ProductOrderDetails) => {
                                this.setState({
                                    details: detailsResponse,
                                    type: antigens.indexOf(detailsResponse.type_of_test ?? 'Antigen') < 0 ? 'pcr' : 'antigen' 
                                })
                        }).catch((error) => {
                            Log.error(Logs.API, error);
                            
                        });
                        }
                    })
                }
            }).catch( (error: ApiError) => {
                MessageHandler.onError(Reporter['videoqueue.take.error'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false) );
                this.props.callback()            
            })
	}

    getVideoLink(eyeson_add_recording_id: number, index: number) {
        if(this.state.videos == null) {
            return;
        }
        if(this.state.collectedVideos[eyeson_add_recording_id] != null) {
            this.setState({
                video: this.state.collectedVideos[eyeson_add_recording_id],
                data: this.state.videos[index]
            })
        } else {
            api.asyncRequest<Account.Link>(
                { eyeson_add_recording_id: eyeson_add_recording_id },
                apis.EyesonApi,
                'eyesonRecordingGet')
                .then((response: Account.Link) => {
                    const collectedLinks = this.state.collectedVideos;
                    collectedLinks[eyeson_add_recording_id] = response.link;
                    this.setState({
                        video: response.link,
                        collectedVideos: collectedLinks,
                        data: this.state.videos != null ? this.state.videos[index] : undefined
                    })
                }).catch(( error: ApiError) => {
                    Log.error(Logs.API, error);
                });
        }
    }


    approveVideo(valid: boolean, createVoucher?: boolean) {
        if(this.state.data == null || this.state.data.eyeson_add_recording_id == null) {
            return;
        }
        const req: Account.EyesonRecordingPutRequest = {
            eyeson_add_recording_id: this.state.data?.eyeson_add_recording_id,
            EyesonRecordingValidation: {
                open_testkit_during_video: this.state.checkboxes.open_testkit_during_video == null,
                person_performs_test: this.state.checkboxes.person_performs_test == null,
                testkit_always_in_video: this.state.checkboxes.testkit_always_in_video == null,
                video_not_cut: this.state.checkboxes.video_not_cut == null,
                result_readable: this.state.type === 'antigen' ?  this.state.checkboxes.result_readable == null : true,
                performing_person_and_testkit_match: this.state.checkboxes.performing_person_and_testkit_match == null,
                barcode_readable: this.state.type === 'pcr' ? this.state.checkboxes.barcode_readable == null : true,
                valid: valid,
                test_id: this.state.details?.test_id
            }
        };
        if(this.state.type === 'pcr') {
            OverlayHandler.showOverlay(Overlays.ConfirmationRequestOverlay, {
                confirm: (callback: (success: boolean, close?: boolean) => void) => {
                  
                    api.asyncRequest(
                        req,
                        apis.EyesonApi,
                        'eyesonRecordingPut').
                        then(() => {    
                            MessageHandler.onSuccess(Reporter['videoqueue.approve.result.put']);
                            if(this.state.voucherReq != null && createVoucher === true ) {          
                                api.asyncRequest<Account.Voucher>(
                                    this.state.voucherReq,
                                    apis.DefaultApi,
                                    'productVoucherPost',
                                ).then((response: Account.Voucher) => {
                                    MessageHandler.onSuccess(Reporter['customer.voucher.set']);
                                }).catch((error: ApiError) => {
                                    Log.error(Logs.API, "Error on getting Merchants", error);
                                    MessageHandler.onError(Reporter['customer.voucher.set'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
                                });
                            }
                            this.props.callback();
                            callback(true, true);
                        }).catch(( error: ApiError) => {
                            Log.error(Logs.API, error);
                            MessageHandler.onError(Reporter['videoqueue.approve.result.put'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
                            callback(false, true);
                        });
                },
                onClose: () => {
                    
                },
                heading: translate('service_center.overlayNores.title'),
                message: translate('service_center.overlayNores.text'),
            });
        }
        else if(valid) {
            this.setState({
                done: true,
                passRequest: req
            });
            
        } else {
            api.asyncRequest(
                req,
                apis.EyesonApi,
                'eyesonRecordingPut')
                .then(() => {
                    MessageHandler.onSuccess(Reporter['videoqueue.approve.video.put']);
                    if(this.state.voucherReq != null && createVoucher === true ) {          
                        api.asyncRequest<Account.Voucher>(
                            this.state.voucherReq,
                            apis.DefaultApi,
                            'productVoucherPost',
                        ).then((response: Account.Voucher) => {
                            MessageHandler.onSuccess(Reporter['customer.voucher.set']);
                        }).catch((error: ApiError) => {
                            Log.error(Logs.API, "Error on getting Merchants", error);
                            MessageHandler.onError(Reporter['customer.voucher.set'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
                        });
                    }
                    this.props.callback()
                    //'videoqueue.approve.result.put',
                    }).catch(( error: ApiError) => {
                        MessageHandler.onError(Reporter['videoqueue.approve.video.put'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
                        Log.error(Logs.API, error);
                });
            }
	}

    setVoucher(data: {[key: string]: IRequestData}) {
        const date = new Date();
        date.setDate(date.getDate() + 3);
        const req: Account.ProductVoucherPostRequest = {
            NewProductVoucher: {
                receiver: this.state.person?.primary_email_address,
                merchant_account_number: data.merchantAccount.value != null && data.merchantAccount.value != "" && data.merchantAccount.value != "All" ? data.merchantAccount.value : undefined,
                amount: data.amount.value.replace(",", "."),
                valid_until: date,
                currency: Iso4217Currency.EUR,
                voucher_type: Account.NewProductVoucherVoucherTypeEnum.OnceOnly
            }
        }
        this.setState({
            voucherReq: req
        }, () => {
            this.approveVideo(false, true);
        })
    }

    getMerchants() {
        api.asyncRequest<Array<Account.MerchantAddress>>(
            {},
            apis.MerchantApi,
            'merchantList',
        ).then((response: Array<Account.MerchantAddress>) => {
            this.setState({
               merchants: response
            })

        }).catch((error: ApiError) => {
           Log.error(Logs.API, "Error on getting Merchants", error)
        });
    }

    showVoucherOverlay() {
        const data: {[key: string]: IRequestData} = {};
        data.amount = {
            translationkeyLA: 'customers.details.externalAccounts.input.amount.label',
            translationkeyPL: 'customers.details.externalAccounts.input.amount.placeHolder',
            value: this.state.details?.product_price?.toString(),
            type: DataTypeEnum.Input
        };
        data.merchantAccount = {
            translationkeyLA:  'customers.details.externalAccounts.input.testCenter.label',
            translationkeyPL:  'customers.details.externalAccounts.input.testCenter.placeholder',
            value: this.state.details?.merchant_account_number,
            type: DataTypeEnum.Select,
            enumVals: this.getTestcenterOptions()
        }
        const buttontext = 'customers.details.Voucher.createVoucher';
        OverlayHandler.showOverlay(Overlays.requestDataOverlay, {data: data, buttonKey: buttontext, callback: this.setVoucher, title: 'service_center.setVoucher'})
    }
    
    


    getTestcenterOptions(): Array<IOption> {
        const enumValues: Array<IOption> = [];
        enumValues.push({
            key: 'defaultOptionKey',
            name: 'All',
            value: undefined,
        });
        if(this.state.merchants == null) {
            return enumValues;
        }
		for (const value in this.state.merchants) {
			enumValues.push({
				key: 'defaultOptionKey' + value,
				name: this.state.merchants[value].name,
				value: this.state.merchants[value].account_number,
			});
		}
		return enumValues;
    }

	componentDidMount() {	
       this.fetchData();
       this.getMerchants();
       if(this.videoRef != null && this.videoRef != null) {
            this.videoRef.onerror = () => {
                this.setState({
                    error: `Something went wrong, please make a screenshot of this errormessage. Code: ${this.videoRef?.error?.code}, Message: ${this.videoRef?.error?.message}`
                });
            }
       }
    }
   
    onCheckBoxChange(key: string, value: boolean) {
        const checkbox = this.state.checkboxes;
        if(Object.keys(checkbox).indexOf(key) > -1) {
            delete checkbox[key];
        } else {
            checkbox[key] = false;
        }
        this.setState({
            checkboxes: checkbox
        })
    }

    setFaster() {
        if(this.videoRef != null) {
            const current = this.videoRef;
            const speed = current?.playbackRate;
            if(speed != null && current != null && speed < Config.videoMaxSpeed) {
                current.playbackRate = speed * Config.deltaVideoSpeed;
                this.setState({
                    videoSpeed: current.playbackRate 
                })
            }
        }

    }

    setRef(ref: HTMLVideoElement) {
        this.videoRef = ref;
        if(this.videoRef != null) {
            this.videoRef.onerror = () => {
                this.setState({
                    error: `Code: ${this.videoRef?.error?.code}, Message: ${this.videoRef?.error?.message}`
                });
            }
       }
    }

    setSlower() {
        if(this.videoRef != null) {
            const current = this.videoRef;
            const speed = current?.playbackRate;
            if(speed != null && current != null && speed > 1/Config.deltaVideoSpeed) {
                current.playbackRate = speed / Config.deltaVideoSpeed;
                this.setState({
                    videoSpeed: current.playbackRate 
                })
            }
        }
    }

    copyMessage() {
        const msg = `${this.state.error} VideoLink: ${this.state.video}`;
        navigator.clipboard.writeText(msg);
    }

	render() {
        const duration = this.state.data?.duration;
        let mins = 0;
        let secs = 0;
        if(duration != null) {
            mins = Math.floor(duration / 60);
            secs = duration - (mins * 60);
        }
        if(this.state.done === true && this.state.person != null && this.state.details != null &&
           this.state.data != null  && this.state.passRequest != null ) {
            return <TestResultComponent 
                        person={this.state.person}
                        room={this.state.data}
                        testKit={this.state.testKit}
                        passportImage={this.state.face }
                        videoApproval={this.state.passRequest}
                        details={this.state.details}
                        callback={this.props.callback}/>
        }
		return (
			<Main>
              <Title title="Eyeson Video data" />
              <RowBox> 
                <VideoBox>
                    <DataBox style={{justifyContent: 'center', width: 'auto'}}>
                        <KeyTextComponent
                            key="date" 
                            translationkey={'service_center.start_date'}
                            value={format.datetime(this.state.data?.created_at)}
                        />
                        <KeyTextComponent
                            key="duration" 
                            translationkey={'service_center.duration'}
                            value={coalesce(' - ', mins + " : " +  secs)}
                        />
                        </DataBox>
                    <DataBox style={{justifyContent: 'center', marginTop: '16px'}}>
                        {
                            this.state.videos != null ?
                            this.state.videos.map((video: Account.EyesonRecording, index: number) => {
                                return <VideoSelect
                                            onClick={() => {
                                                this.getVideoLink(video.eyeson_add_recording_id ?? 0, index)
                                            }} 
                                            isSelected={video.eyeson_add_recording_id === this.state.data?.eyeson_add_recording_id}>
                                         {`Video ${index + 1}`}
                                      </VideoSelect>
                            }) : null
                        }
                    </DataBox>
                    <VideoContainer>
                        {
                                this.state.video != null && this.state.error == null ?
                                <Video controls={true}
                                       autoPlay={false}
                                       muted={true}
                                       src={ this.state.video } 
                                       ref={ this.setRef }> { this.state.error} 
                                </Video> 
                                : this.state.error != null ?
                                    <div style={{display: 'flex'}}> 
                                        <ErrorBox>{`Something went wrong, Please copy this msg with the button to the right and contact support. ${this.state.error}`}</ErrorBox>
                                        <IconWrapper onClick={() => this.copyMessage()}>{Icons.copy()}</IconWrapper>
                                    </div> : null
                            }
                    </VideoContainer>
                    <ControlBox>
                            <IconWrapper
                                style={{marginTop: '-12px'}}
                                onClick={() => this.setSlower()}>
                                {Icons.slow()}
                            </IconWrapper>
                            <SpeedBox>
                                {this.state.videoSpeed +  "x"}
                            </SpeedBox>
                            <IconWrapper
                                style={{marginTop: '-12px'}}
                                onClick={() => this.setFaster()}>
                                {Icons.fasten()}
                            </IconWrapper>
                    </ControlBox>
                    <TestKitBox>
                        { this.state.type === 'antigen' ? coalesce('No Manufactor set' , this.state.testKit?.manufacturer_name) + ": " + coalesce('no Testkit set', this.state.testKit?.commercial_name)  : ''}
                    </TestKitBox>
                </VideoBox>
                <CheckBoxBox>
                    {
                        this.state.face != null              ?   
                            <Image image={this.state.face}/> :
                            <ImageCircle>
                                {this.state.person?.given_name != null && this.state.person?.name != null ?  this.state.person?.given_name?.substring(0,1) + this.state.person.name?.substring(0,1) : ''}
                            </ImageCircle>  }
                    {
                        checkbox_fields[this.state.type].map((key: string) => {
                            return(
                                <KeyCheckBoxComponent 
                                    translationkey={`service_center.checkboxes.${key}`}
                                    id={key}
                                    bigCheckbox={true}
                                    onChange={(value: boolean) => this.onCheckBoxChange(key, value)}
                                    value={this.state.checkboxes[key] == null }
                                />
                            )
                        })
                    }
                </CheckBoxBox>
              </RowBox>
              <DataBox style={{justifyContent: 'space-between', width: '90%'}}>
                  <KeyTextComponent 
                    key="name"
                    translationkey={'customers.name'}
                    value={coalesce(' - ', this.state.person?.name)}
                  />
                   <KeyTextComponent
                    key="given_name" 
                    translationkey={'customers.givenName'}
                    value={coalesce(' - ', this.state.person?.given_name)}
                  />
                   <KeyTextComponent 
                    key="personid"
                    translationkey={'customers.customerNumber'}
                    value={coalesce(' - ', this.state.person?.person_id.toString())}
                  />
                  { this.state.type === 'pcr' ? 
                    <EditableComponent
                        translationkey="customers.barcode"
                        id="barcode"
                        initText={ this.state.details?.test_id ?? '' }
                        inputStyle={{
                            textAlign: 'left',
                            marginBottom: '0px',
                            marginTop: '2px',
                        }}
                        wrapperStyle={{maxWidth: '240px'}}
                        changeCallback={(value:string, key?: string) => {
                            const details = this.state.details;
                            if(details != null) {
                                details.test_id = value;
                                this.setState({
                                    details: details,
                                })
                            }
                        }}
                        enterCallback={() => {}}
                        disabled={this.state.details == null}
                    /> : null}
              </DataBox>
              <StyledViewButtons style={{justifyContent: 'center', width: '60%'}}>
                  { UserDataStore.getRoles().indexOf('admin') >= 0 ?
                    <ButtonOk 
                        style={{margin: 'auto'}}
                        disableSpinner={true}
                        onClick={(event: SyntheticEvent) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.showVoucherOverlay();
                            }
                        }
                    > {translate('service_center.createVoucher')}</ButtonOk>
                    : null }
                    <ButtonCancel
                        style={{margin: 'auto'}}
                        id="btnEditCustomerEmailCancel"
                        onClick={() => {
                            this.props.callback()
                        }} >                                
                        {translate('button.cancel')}
                    </ButtonCancel>  
                    <ButtonOk 
                        style={{margin: 'auto'}}
                        onClick={(event: SyntheticEvent) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.approveVideo(false)
                            }
                        }
                    > {translate('button.notValid')}</ButtonOk>
                    <ButtonOk 
                        style={{margin: 'auto'}}
                        key={this.state.keyForLoadingSpinner}
                        onClick={(event: SyntheticEvent) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.approveVideo(true)
                            }
                        }
                        disabled={Object.keys(this.state.checkboxes).length > 1}
                    > {translate('button.valid')}</ButtonOk>
                </StyledViewButtons>
			</Main>
		);
	}
}

export default VideoVerification;
