// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Iso4217Currency,
} from './';

/**
 * @export
 * @interface ScheduledTransactionUpdate
 */
export interface ScheduledTransactionUpdate {
    /**
     * @type {Iso4217Currency}
     * @memberof ScheduledTransactionUpdate
     */
    instructed_amount_currency?: Iso4217Currency;
    /**
     * The amount given with fractional digits, where fractions must be compliant to the currency definition. Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.  **Example:** Valid representations for EUR with up to two decimals are:    * 1056   * 5768.2   * -1.50   * 5877.78 
     * @type {string}
     * @memberof ScheduledTransactionUpdate
     */
    instructed_amount_amount?: string;
    /**
     * @type {string}
     * @memberof ScheduledTransactionUpdate
     */
    counterparty_account?: string;
    /**
     * * A -ccount number  * I -BAN * E -mail address  * P -phone number
     * @type {string}
     * @memberof ScheduledTransactionUpdate
     */
    counterparty_account_type?: ScheduledTransactionUpdateCounterpartyAccountTypeEnum;
    /**
     * @type {string}
     * @memberof ScheduledTransactionUpdate
     */
    counterparty_name?: string;
    /**
     * @type {string}
     * @memberof ScheduledTransactionUpdate
     */
    remittance_information_unstructured?: string;
    /**
     * @type {boolean}
     * @memberof ScheduledTransactionUpdate
     */
    gift_payment?: boolean;
    /**
     * @type {Date}
     * @memberof ScheduledTransactionUpdate
     */
    cycle_start?: Date;
    /**
     * @type {Date}
     * @memberof ScheduledTransactionUpdate
     */
    cycle_end?: Date;
    /**
     * * O -nce  * D -aily  * W -eekly  * M -onthly  * Y -early
     * @type {string}
     * @memberof ScheduledTransactionUpdate
     */
    cycle_type?: ScheduledTransactionUpdateCycleTypeEnum;
    /**
     * * B -eginning of ...  * E -nd of ...  * D -ay (e.g. always at the 12th of ...)
     * @type {string}
     * @memberof ScheduledTransactionUpdate
     */
    cycle_reference?: ScheduledTransactionUpdateCycleReferenceEnum;
    /**
     * @type {number}
     * @memberof ScheduledTransactionUpdate
     */
    cycle_day_of_week?: number;
    /**
     * @type {number}
     * @memberof ScheduledTransactionUpdate
     */
    cycle_day_of_month?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ScheduledTransactionUpdateCounterpartyAccountTypeEnum {
    A = 'A',
    E = 'E',
    I = 'I',
    P = 'P'
}
/**
 * @export
 * @enum {string}
 */
export enum ScheduledTransactionUpdateCycleTypeEnum {
    O = 'O',
    D = 'D',
    W = 'W',
    M = 'M',
    Y = 'Y',
    P = 'P'
}
/**
 * @export
 * @enum {string}
 */
export enum ScheduledTransactionUpdateCycleReferenceEnum {
    B = 'B',
    E = 'E',
    D = 'D'
}

