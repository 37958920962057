import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Log, Logs } from '../../logic/log';

interface IProps {
	id: string;
	defaultValue?: boolean;
	onChange?: (value: boolean) => void;
	disabled?: boolean;
}

interface IState {
	checked?: boolean;
}

export default class Switch extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			checked: props.defaultValue == null ? false : props.defaultValue,
		};

		this._onChange = this._onChange.bind(this);
	}

	componentWillReceiveProps(props: IProps) {
		this.setState({
			checked: props.defaultValue != null ? props.defaultValue : this.state.checked,
		});
	}

	private _onChange(event: SyntheticEvent): void {
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		event.preventDefault();
		if (this.props.disabled === true) {
			return;
		}
		Log.debug(Logs.COMPONENT, 'Triggered switch onChange');
		if (this.props.onChange != null && typeof this.props.onChange === 'function') {
			this.props.onChange(this.state.checked !== true);
		}
		this.setState({
			checked: this.state.checked !== true,
		});
	}

	render() {
		return (
			<StyledLabel id={this.props.id} onClick={this._onChange}>
				<input type="checkbox" checked={this.state.checked === true} onChange={() => {}}/>
				<span />
			</StyledLabel>
		);
	}
}

const StyledLabel = styled.label`
	position: relative;
	display: inline-block;
	width: 38px;
	height: 24px;

	& > input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	/* The slider */
	& > span {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #8d93ab;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 34px;
	}

	& > span:before {
		position: absolute;
		content: '';
		height: 18px;
		width: 18px;
		left: 3px;
		bottom: 3px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 50%;
	}

	input:checked + span {
		background-color: ${(props) => (props.theme.Global.primaryColor)};
	}

	input:focus + span {
		box-shadow: 0 0 1px #2196f3;
	}

	input:checked + span:before {
		-webkit-transform: translateX(15px);
		-ms-transform: translateX(15px);
		transform: translateX(15px);
	}
`;
