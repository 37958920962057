// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Calendar,
    MerchantAddress,
    MerchantProductcalendarAllOf,
    NewAddress,
    OpenCalendar,
    PaymentMethod,
    Product,
    ProductPackage,
} from './';

/**
 * @export
 * @interface MerchantProductcalendar
 */
export interface MerchantProductcalendar {
    /**
     * the merchants name
     * @type {string}
     * @memberof MerchantProductcalendar
     */
    name: string;
    /**
     * the merchants account number
     * @type {string}
     * @memberof MerchantProductcalendar
     */
    account_number: string;
    /**
     * the etermin calendar id
     * @type {number}
     * @memberof MerchantProductcalendar
     */
    calendar_id?: number;
    /**
     * etermin or ndpay
     * @type {string}
     * @memberof MerchantProductcalendar
     */
    calendar_type?: MerchantProductcalendarCalendarTypeEnum;
    /**
     * time zone name see https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     * @type {string}
     * @memberof MerchantProductcalendar
     */
    timezone?: string;
    /**
     * a link to the merchants image
     * @type {string}
     * @memberof MerchantProductcalendar
     */
    image?: string;
    /**
     * @type {NewAddress}
     * @memberof MerchantProductcalendar
     */
    address?: NewAddress;
    /**
     * tthe distance in km in case the request provided lat/long
     * @type {number}
     * @memberof MerchantProductcalendar
     */
    distance_km?: number;
    /**
     * the locations website, if any
     * @type {string}
     * @memberof MerchantProductcalendar
     */
    website?: string;
    /**
     * the merchant identifier
     * @type {string}
     * @memberof MerchantProductcalendar
     */
    identifier?: string;
    /**
     * accepted payment methods
     * @type {Array<PaymentMethod>}
     * @memberof MerchantProductcalendar
     */
    payment_methods?: Array<PaymentMethod>;
    /**
     * pharmacy or testcenter
     * @type {string}
     * @memberof MerchantProductcalendar
     */
    merchant_type?: MerchantProductcalendarMerchantTypeEnum;
    /**
     * @type {Array<Product>}
     * @memberof MerchantProductcalendar
     */
    product_list?: Array<Product>;
    /**
     * @type {Calendar}
     * @memberof MerchantProductcalendar
     */
    calendar?: Calendar;
    /**
     * @type {OpenCalendar}
     * @memberof MerchantProductcalendar
     */
    open_calendar?: OpenCalendar;
    /**
     * @type {Array<ProductPackage>}
     * @memberof MerchantProductcalendar
     */
    package_list?: Array<ProductPackage>;
}

/**
 * @export
 * @enum {string}
 */
export enum MerchantProductcalendarCalendarTypeEnum {
    E = 'E',
    N = 'N'
}
/**
 * @export
 * @enum {string}
 */
export enum MerchantProductcalendarMerchantTypeEnum {
    P = 'P',
    T = 'T'
}

