export enum Logs {
	API = 'API',
	APNS = 'APNS',
	AUTH = 'Auth',
	COMPONENT = 'Component',
	DEV = 'DEV',
	FCM = 'FCM',
	FILE = 'FILE',
	PARSER = 'PARSER',
	PERMISSION = 'Permission',
	SYSTEM = 'System',
	REMOVABLE = 'REMOVABLE',
	OVERLAYS = 'Overlays',
}

export class Log {
	static LEVEL = {
		NONE: 0,
		INFO: 1,
		WARN: 2,
		ERROR: 4,
		DEBUG: 8,
		TRACE: 16,
	};
	private static LOG_LEVEL = Log.LEVEL.DEBUG;

	private static log(
		category: Logs = Logs.SYSTEM,
		level: number,
		msg: string | null | undefined,
		object?: any
	) {
		if (msg == null || level > Log.LOG_LEVEL) {
			return;
		}

		if (level === Log.LEVEL.INFO) {
			if (object != null) {
				console.info('[' + category.toString() + '] ' + msg, object);
			} else {
				console.info('[' + category.toString() + '] ' + msg);
			}
		} else if (level === Log.LEVEL.WARN) {
			if (object != null) {
				console.warn('[' + category.toString() + '] ' + msg, object);
			} else {
				console.warn('[' + category.toString() + '] ' + msg);
			}
		} else if (level === Log.LEVEL.ERROR) {
			if (object != null) {
				console.error('[' + category.toString() + '] ' + msg, object);
			} else {
				console.error('[' + category.toString() + '] ' + msg);
			}
		} else if (level === Log.LEVEL.DEBUG) {
			if (object != null) {
				console.debug('[' + category.toString() + '] ' + msg, object);
			} else {
				console.debug('[' + category.toString() + '] ' + msg);
			}
		}
	}

	static info(category: Logs = Logs.SYSTEM, msg: any, object?: any) {
		if (msg instanceof Object) {
			Log.log(category, Log.LEVEL.INFO, '', msg);
		} else {
			Log.log(category, Log.LEVEL.INFO, msg, object);
		}
	}

	static warn(category: Logs = Logs.SYSTEM, msg: any, object?: any) {
		if (msg instanceof Object) {
			Log.log(category, Log.LEVEL.WARN, '', msg);
		} else {
			Log.log(category, Log.LEVEL.WARN, msg, object);
		}
	}

	static error(category: Logs = Logs.SYSTEM, msg: any, object?: any) {
		if (msg instanceof Object) {
			Log.log(category, Log.LEVEL.ERROR, '', msg);
		} else {
			Log.log(category, Log.LEVEL.ERROR, msg, object);
		}
	}

	static debug(category: Logs = Logs.SYSTEM, msg: any, object?: any) {
		if (msg instanceof Object) {
			Log.log(category, Log.LEVEL.DEBUG, '', msg);
		} else {
			Log.log(category, Log.LEVEL.DEBUG, msg, object);
		}
	}

	static trace(category: Logs = Logs.SYSTEM, msg: any, object?: any) {
		if (msg instanceof Object) {
			Log.log(category, Log.LEVEL.TRACE, '', msg);
		} else {
			Log.log(category, Log.LEVEL.TRACE, msg, object);
		}
	}
}
