// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface Closure
 */
export interface Closure {
    /**
     * @type {string}
     * @memberof Closure
     */
    reason?: ClosureReasonEnum;
    /**
     * @type {string}
     * @memberof Closure
     */
    comment?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ClosureReasonEnum {
    ClientRequest = 'ClientRequest',
    AMLTechnicalError = 'AMLTechnicalError',
    Other3rdPartyRequest = 'Other3rdPartyRequest',
    ImmediateClosure = 'ImmediateClosure'
}

