// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ReplPassport
 */
export interface ReplPassport {
    /**
     * @type {number}
     * @memberof ReplPassport
     */
    person_id: number;
    /**
     * @type {string}
     * @memberof ReplPassport
     */
    name: string;
    /**
     * @type {string}
     * @memberof ReplPassport
     */
    given_name: string;
    /**
     * @type {Date}
     * @memberof ReplPassport
     */
    birth_date: Date;
    /**
     * @type {string}
     * @memberof ReplPassport
     */
    birth_city?: string;
    /**
     * @type {string}
     * @memberof ReplPassport
     */
    passport_number: string;
    /**
     * @type {Date}
     * @memberof ReplPassport
     */
    expiration_date?: Date;
    /**
     * the issuing authority
     * @type {string}
     * @memberof ReplPassport
     */
    issuer?: string;
    /**
     * @type {number}
     * @memberof ReplPassport
     */
    issuing_country_id?: number;
    /**
     * @type {string}
     * @memberof ReplPassport
     */
    sex?: ReplPassportSexEnum;
    /**
     * @type {number}
     * @memberof ReplPassport
     */
    nationality_id?: number;
    /**
     * @type {string}
     * @memberof ReplPassport
     */
    document_type?: ReplPassportDocumentTypeEnum;
    /**
     * @type {string}
     * @memberof ReplPassport
     */
    regula_transaction_id?: string;
    /**
     * @type {Date}
     * @memberof ReplPassport
     */
    regula_transaction_date_time?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum ReplPassportSexEnum {
    F = 'F',
    M = 'M',
    X = 'X'
}
/**
 * @export
 * @enum {string}
 */
export enum ReplPassportDocumentTypeEnum {
    Passport = 'passport',
    IdentityCard = 'identity card',
    DriverLicense = 'driver license'
}

