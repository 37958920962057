// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * the companion code details
 * @export
 * @interface CompanionCodeDetailExt
 */
export interface CompanionCodeDetailExt {
    /**
     * @type {number}
     * @memberof CompanionCodeDetailExt
     */
    person_id: number;
    /**
     * the other persons given name
     * @type {string}
     * @memberof CompanionCodeDetailExt
     */
    given_name: string;
    /**
     * the other persons given name
     * @type {string}
     * @memberof CompanionCodeDetailExt
     */
    name: string;
    /**
     * the email address
     * @type {string}
     * @memberof CompanionCodeDetailExt
     */
    email_address?: string;
    /**
     * a phone number in format e164, if there is one
     * @type {string}
     * @memberof CompanionCodeDetailExt
     */
    phone_number?: string;
    /**
     * Phone Number Type:   * \'M\' - Mobile    * \'F\' - Fixed Network 
     * @type {string}
     * @memberof CompanionCodeDetailExt
     */
    phone_number_type?: CompanionCodeDetailExtPhoneNumberTypeEnum;
    /**
     * the url the users face image if there is one
     * @type {string}
     * @memberof CompanionCodeDetailExt
     */
    face_url?: string;
    /**
     * Famaily relation:   * \'junior\' - For childe    * \'family_member\' - other relatives 
     * @type {string}
     * @memberof CompanionCodeDetailExt
     */
    family_relation?: CompanionCodeDetailExtFamilyRelationEnum;
    /**
     * @type {Date}
     * @memberof CompanionCodeDetailExt
     */
    date_of_birth?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum CompanionCodeDetailExtPhoneNumberTypeEnum {
    M = 'M',
    F = 'F'
}
/**
 * @export
 * @enum {string}
 */
export enum CompanionCodeDetailExtFamilyRelationEnum {
    Junior = 'junior',
    FamilyMember = 'family_member'
}

