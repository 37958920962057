// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    NewAddress,
    Sex,
} from './';

/**
 * @export
 * @interface NewLoginAndAccount
 */
export interface NewLoginAndAccount {
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    email_address?: string;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    email_address_record_state?: NewLoginAndAccountEmailAddressRecordStateEnum;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    password?: string;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    name?: string;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    given_name?: string;
    /**
     * @type {Date}
     * @memberof NewLoginAndAccount
     */
    birth_date?: Date;
    /**
     * @type {Sex}
     * @memberof NewLoginAndAccount
     */
    sex?: Sex;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    nationality?: string;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    state?: NewLoginAndAccountStateEnum;
    /**
     * @type {Array<object>}
     * @memberof NewLoginAndAccount
     */
    phone_numbers?: Array<object>;
    /**
     * @type {NewAddress}
     * @memberof NewLoginAndAccount
     */
    address?: NewAddress;
    /**
     * the pin code to authorize actions on the atm
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    pin?: string;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    dataprovider?: NewLoginAndAccountDataproviderEnum;
    /**
     * @type {number}
     * @memberof NewLoginAndAccount
     */
    external_id?: number;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    user_class?: NewLoginAndAccountUserClassEnum;
    /**
     * @type {number}
     * @memberof NewLoginAndAccount
     */
    person_id_create?: number;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    referral_code?: string;
    /**
     * @type {boolean}
     * @memberof NewLoginAndAccount
     */
    is_merchant?: boolean;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    merchant_description?: string;
    /**
     * @type {number}
     * @memberof NewLoginAndAccount
     */
    segment_id?: number;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    partner: string;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    language: string;
    /**
     * @type {string}
     * @memberof NewLoginAndAccount
     */
    client_profile?: NewLoginAndAccountClientProfileEnum;
    /**
     * @type {boolean}
     * @memberof NewLoginAndAccount
     */
    is_eu_member?: boolean;
    /**
     * @type {number}
     * @memberof NewLoginAndAccount
     */
    person_id_primary?: number;
    /**
     * @type {number}
     * @memberof NewLoginAndAccount
     */
    person_id_referral?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum NewLoginAndAccountEmailAddressRecordStateEnum {
    A = 'A',
    N = 'N'
}
/**
 * @export
 * @enum {string}
 */
export enum NewLoginAndAccountStateEnum {
    R = 'R',
    V = 'V',
    I = 'I',
    C = 'C'
}
/**
 * @export
 * @enum {string}
 */
export enum NewLoginAndAccountDataproviderEnum {
    User = 'user',
    Ndident = 'ndident'
}
/**
 * @export
 * @enum {string}
 */
export enum NewLoginAndAccountUserClassEnum {
    Bo = 'bo',
    Customer = 'customer'
}
/**
 * @export
 * @enum {string}
 */
export enum NewLoginAndAccountClientProfileEnum {
    Primary = 'primary',
    Companion = 'companion',
    Joint = 'joint'
}

