import React from 'react';
import { NavLink } from 'react-router-dom';
import { IPage } from './pages.abstract';
import styled from 'styled-components';
import { PermissionStore, INotifyAblePermissions } from '../../logic/flux';
import { OperationIds } from '../../logic/api';
import { Icons } from '../../images';
import { translate } from '../../common/language/translate';

interface IProps {
	depth: number;
	page: IPage;
	first: boolean;
	last: boolean;
	smallNav?: boolean;
}

interface IState {
	showComponent: boolean;
	userPermissions: Array<OperationIds>;
	smallNav?: boolean;
}

export default class NavbarLink extends React.Component<IProps, IState>
	implements INotifyAblePermissions {
	constructor(props: IProps) {
		super(props);

		this.state = {
			showComponent: false,
			userPermissions: [],
		};

		this._onChangePermissions = this._onChangePermissions.bind(this);
		this.toggleShowComponent = this.toggleShowComponent.bind(this);
	}

	_onChangePermissions() {
		this.setState({
			userPermissions: PermissionStore.getPermissions(),
		});
	}

	componentDidMount(): void {
		PermissionStore.addChangeListener(this._onChangePermissions);
		this._onChangePermissions();
	}

	componentWillUnmount(): void {
		PermissionStore.removeChangeListener(this._onChangePermissions);
	}

	static getDerivedStateFromProps(props: IProps, state: IState): IState | null {
		if (props.smallNav !== state.smallNav) {
			return {
				smallNav: props.smallNav,
				showComponent: state.showComponent,
				userPermissions: state.userPermissions,
			};
		}

		return null;
	}

	get icon(): JSX.Element | null {
		return this.props.page.icon != null && this.props.page.showIcon ? (
			<Image active={this.props.page.active}>
				{Icons.getIcon(this.props.page.icon)}
			</Image>
		) : null;
	}

	get text(): JSX.Element | null {
		return this.props.page.text != null && this.props.page.showText ? (
			<LinkText active={this.props.page.active}>
				{translate(this.props.page.text, this.props.page.text)}
			</LinkText>
		) : null;
	}

	toggleShowComponent() {
		this.setState({
			showComponent: !this.state.showComponent,
		});
	}

	render() {
		if (this.props.page.display !== true) {
			return null;
		}

		if (
			PermissionStore.checkPermissionConfig(
				this.props.page.permissions,
				this.state.userPermissions
			) !== true
		) {
			return null;
		}
		return (
			<StyledLink
				to={this.props.page.url}
				active={this.props.page.active}
				depth={this.props.depth}
				title={translate(this.props.page.text)}
				smallNav={this.state.smallNav}>
				{this.icon}
				{this.state.smallNav === true ? '' : this.text}
			</StyledLink>
		);
	}
}

// TODO: Use props and set active colours
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledLink = styled(({ smallNav, active, depth, ...rest }) => <NavLink {...rest} />)`
	width: 90%;
	margin-left: 5%;
	outline: none;
    height: ${(props) => (props.smallNav === true ? '48px' : '64px')};
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: ${(props) =>
		props.smallNav === true
			? '11px 0px 11px ' + (props.depth * 10 + 17) + 'px'
			: '19px 0px 21px ' + (props.depth * 10 + 24) + 'px'};

    border-style: solid;
    border-width: 0px 0px 3px 0px;
    border-color: ${(props) => (props.active === true ? props.theme.Global.primaryColor : '#fff')};
    color: ${(props) => (props.smallNav === true ? 'rgba(0, 0, 0, 0.0)' : props.theme.Global.darkFontColor)}
	box-shadow: none;
    text-decoration: none;

    &:hover {
        border-color: ${(props) => (props.active === true ? props.theme.Global.secondaryColor :'#fff' )};
    }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LinkText = styled(({ active, ...rest }) => <span {...rest} />)`
    line-height: 22px;
    font-weight: 400;
    margin-left: 18px;
    letter-spacing: 0.3px;
    color: ${(props) => (props.active === true ? props.theme.Global.primaryColor : props.theme.Global.darkFontColor)};
    text-transform: uppercase;
	text-decoration: none;
	&:hover {
        color: ${(props) =>props.theme.Global.secondaryColor };
    }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Image = styled(({ active, ...rest }) => <span {...rest} />)`
    width: 24px;
    height: 24px;
    border: none; 
    font-size: 22px;
    color: ${(props) => (props.theme.Global.lightFontColor)};
    stroke: ${(props) => (props.active === true ? props.theme.Global.primaryColor : props.theme.Global.lightFontColor)};
    fill: ${(props) => (props.active === true ? props.theme.Global.primaryColor : props.theme.Global.lightFontColor)};
    #admin {
        stroke: none;
    }

    svg > path,
    svg > * > path {
        stroke: ${(props) => (props.active === true ? props.theme.Global.primaryColor : props.theme.Global.lightFontColor)};
        fill: ${(props) => (props.active === true ? props.theme.Global.primaryColor : props.theme.Global.lightFontColor)};
        #admin {
            stroke: white;
        }
    }  
`;
