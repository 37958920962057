import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';

// import i18n (needs to be bundled ;))
import i18next from './common/language/i18n';
import { I18nextProvider } from 'react-i18next';
import { Log, Logs } from './logic/log';
/*if ('serviceWorker' in navigator && !('safari' in window)) {
	navigator.serviceWorker
		.register('./firebase-messaging-sw.js')
		.then(function(registration) {
			if (messaging != null) {
				messaging.useServiceWorker(registration);
				Log.debug(Logs.FCM, 'Registration successful, scope is:', registration.scope);
			}
		})
		.catch(function(err) {
			Log.error(Logs.FCM, 'Service worker registration failed, error:', err);
		});
}*/

ReactDOM.render(
	<I18nextProvider i18n={i18next}>
		<App />
	</I18nextProvider>,
	document.getElementById('root')
);
