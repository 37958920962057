// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, throwIfRequired, encodeURI } from '../runtime';

export interface WipePersonRequest {
    person_id: number;
}

/**
 * no description
 */
export class DebugApi extends BaseAPI implements IOperationIdMap {

    /**
     * specifically in showcases it can ne necessary to wipe a person without further questions. this can be done using this interface.
     * remove a person via emailaddress, finger, face or qrcode
     */
    wipePerson = (requestParameters: WipePersonRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'wipePerson');

        return this.request<void>({
            path: '/showcase/person/{person_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'DELETE',
        });
    };


    operationToOperationId = {
	    "wipePerson": "wipe_person"
    }
}
