import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { de } from './de';
import { en } from './en';
import { sq } from './sq';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const config = {
	fallbackLng: 'en',
	whitelist: ['en', 'de', 'sq'],
};

i18n
	// load translation using xhr -> see /public/locales
	// learn more: https://github.com/i18next/i18next-xhr-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: config.fallbackLng,
		whitelist: config.whitelist,
		debug: true,
		resources: {
			de: { translation: de },
			en: { translation: en },
			sq: { translation: sq },
		},
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

export default i18n;
