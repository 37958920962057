import { ReactNode } from 'react';
import {
	OverlayComponent,
	IOverlayComponentState,
	IOverlayComponentProps,
} from '../../../../components/compositcomponents/popup/overlayComponent';

export interface IPropsOver extends IOverlayComponentProps {}

export interface IStateOver extends IOverlayComponentState {}

export abstract class AOverlay<
	P extends IPropsOver,
	S extends IStateOver
> extends OverlayComponent<P, S> {
	abstract defineContent(): ReactNode;

	isCentered(): boolean {
		return false;
	}

	render() {
		return this.defineContent();
	}
}
