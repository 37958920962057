// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI, COLLECTION_FORMATS } from '../runtime';
import {
    Release,
    ReleasePut,
    ReleaseStatus,
    ReleaseTriggered,
    ReleaseType,
    ReleasesDetails,
} from '../models';

export interface ReleasesDetailsGetRequest {
    release_requests_id: number;
}

export interface ReleasesGetRequest {
    release_status?: Array<ReleaseStatus>;
    release_type?: ReleaseType;
    qaccount_number?: string;
    qlimit?: number;
    qoffset?: number;
    qshow_2fa?: boolean;
}

export interface ReleasesPutRequest {
    ReleasePut: ReleasePut;
}

/**
 * no description
 */
export class ReleasesApi extends BaseAPI implements IOperationIdMap {

    /**
     */
    releasesDetailsGet = (requestParameters: ReleasesDetailsGetRequest): Observable<ReleasesDetails> => {
        throwIfRequired(requestParameters, 'release_requests_id', 'releasesDetailsGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<ReleasesDetails>({
            path: '/releases/{release_requests_id}/details'.replace('{release_requests_id}', encodeURI(requestParameters.release_requests_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    releasesGet = (requestParameters: ReleasesGetRequest): Observable<Array<Release>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.release_status && { 'release_status': requestParameters.release_status.join(COLLECTION_FORMATS['csv']) }),
            ...(requestParameters.release_type && { 'release_type': requestParameters.release_type }),
            ...(requestParameters.qaccount_number && { 'qaccount_number': requestParameters.qaccount_number }),
            ...(requestParameters.qlimit && { 'qlimit': requestParameters.qlimit }),
            ...(requestParameters.qoffset && { 'qoffset': requestParameters.qoffset }),
            ...(requestParameters.qshow_2fa && { 'qshow_2fa': requestParameters.qshow_2fa }),
        };

        return this.request<Array<Release>>({
            path: '/releases',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Perform action on release
     */
    releasesPut = (requestParameters: ReleasesPutRequest): Observable<ReleaseTriggered> => {
        throwIfRequired(requestParameters, 'ReleasePut', 'releasesPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<ReleaseTriggered>({
            path: '/releases',
            method: 'PUT',
            headers,
            body: requestParameters.ReleasePut,
        });
    };


    operationToOperationId = {
	    "releasesDetailsGet": "releases_details_get" ,
	    "releasesGet": "releases_get" ,
	    "releasesPut": "releases_put"
    }
}
