// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SourceOfIncome {
    EarnedIncome = 'earned_income',
    BusinessIncome = 'business_income',
    InterestIncome = 'interest_income',
    DividendIncome = 'dividend_income',
    RentalIncome = 'rental_income',
    CapitalGains = 'capital_gains',
    Royalties = 'royalties'
}

