// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpQuery, throwIfRequired, encodeURI } from '../runtime';

export interface ServicePersonCurrentFaceGetRequest {
    person_id: number;
    token: string;
}

export interface ServicePersonDocumentGetRequest {
    person_id: number;
    token: string;
    document_type?: ServicePersonDocumentGetDocumentTypeEnum;
    person_document_id?: number;
}

/**
 * no description
 */
export class ServiceApi extends BaseAPI implements IOperationIdMap {

    /**
     * get a specificv face image
     * get a face image to display it to a third person
     */
    servicePersonCurrentFaceGet = (requestParameters: ServicePersonCurrentFaceGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'servicePersonCurrentFaceGet');
        throwIfRequired(requestParameters, 'token', 'servicePersonCurrentFaceGet');

        const query: HttpQuery = {
            ...(requestParameters.token && { 'token': requestParameters.token }),
        };

        return this.request<Blob>({
            path: '/service/person/{person_id}/current_face'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            query,
            responseType: 'blob'
        });
    };

    /**
     * get a specificv face image
     * get a document
     */
    servicePersonDocumentGet = (requestParameters: ServicePersonDocumentGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'servicePersonDocumentGet');
        throwIfRequired(requestParameters, 'token', 'servicePersonDocumentGet');

        const query: HttpQuery = {
            ...(requestParameters.token && { 'token': requestParameters.token }),
            ...(requestParameters.document_type && { 'document_type': requestParameters.document_type }),
            ...(requestParameters.person_document_id && { 'person_document_id': requestParameters.person_document_id }),
        };

        return this.request<Blob>({
            path: '/service/person/{person_id}/document'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            query,
            responseType: 'blob'
        });
    };


    operationToOperationId = {
	    "servicePersonCurrentFaceGet": "service_person_current_face_get" ,
	    "servicePersonDocumentGet": "service_person_document_get"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum ServicePersonDocumentGetDocumentTypeEnum {
    M = 'M',
    A = 'A',
    S = 'S',
    P = 'P'
}
