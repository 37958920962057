// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Sums,
} from './';

/**
 * @export
 * @interface SumsPerMonth
 */
export interface SumsPerMonth {
    /**
     * @type {string}
     * @memberof SumsPerMonth
     */
    partner?: SumsPerMonthPartnerEnum;
    /**
     * @type {string}
     * @memberof SumsPerMonth
     */
    year?: string;
    /**
     * @type {string}
     * @memberof SumsPerMonth
     */
    prev_year?: string;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    jan?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    feb?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    mar?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    apr?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    may?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    jun?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    jul?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    aug?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    sep?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    oct?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    nov?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    dec?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    prev_jan?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    prev_feb?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    prev_mar?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    prev_apr?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    prev_may?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    prev_jun?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    prev_jul?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    prev_aug?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    prev_sep?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    prev_oct?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    prev_nov?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerMonth
     */
    prev_dec?: Sums;
}

/**
 * @export
 * @enum {string}
 */
export enum SumsPerMonthPartnerEnum {
    All = 'all',
    NDIDENT = 'NDIDENT',
    NDPAY = 'NDPAY',
    ONEFOR = 'ONEFOR'
}

