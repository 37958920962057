// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CardAgreementCard,
    CardProvider,
    CardType,
} from './';

/**
 * @export
 * @interface CardAgreement
 */
export interface CardAgreement {
    /**
     * @type {number}
     * @memberof CardAgreement
     */
    card_agreement_id: number;
    /**
     * @type {CardProvider}
     * @memberof CardAgreement
     */
    card_provider: CardProvider;
    /**
     * * P - Pending * A - Active * D - Deleted 
     * @type {string}
     * @memberof CardAgreement
     */
    state: CardAgreementStateEnum;
    /**
     * @type {CardType}
     * @memberof CardAgreement
     */
    card_type: CardType;
    /**
     * @type {string}
     * @memberof CardAgreement
     */
    bpc_customer_number?: string;
    /**
     * @type {string}
     * @memberof CardAgreement
     */
    bpc_account_number?: string;
    /**
     * @type {Array<CardAgreementCard>}
     * @memberof CardAgreement
     */
    card?: Array<CardAgreementCard>;
}

/**
 * @export
 * @enum {string}
 */
export enum CardAgreementStateEnum {
    P = 'P',
    A = 'A',
    D = 'D'
}

