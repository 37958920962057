import React, { ReactElement, SyntheticEvent } from 'react';
import { Account, api, ApiError, apis, VoidResponse } from '../../../../logic/api';
import styled from 'styled-components';
import { Icons } from '../../../../images';
import { translate } from '../../../../common/language/translate';
import { Logs, Log } from '../../../../logic/log';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { FlexBox } from '../../../auth/auth.css';
import Title from '../../../../components/compositcomponents/title';
import { evaluateErrorMessage } from '../../../../logic/helper/Common';
import { IOption } from '../../../../components/atomiccompoents/form';
import Expandable from '../../../../components/compositcomponents/expandable/expandable';
import EditableComponent from '../../../../components/atomiccompoents/editableComponent/editableComponent';
import KeySelectComponent from '../../../../components/atomiccompoents/keySelectComponent';
import { OverlayHandler, Overlays } from '../../../../logic/handler/overlayhandler/overlayHandler';
import KeyCheckBoxComponent from '../../../../components/atomiccompoents/keyCheckboxComponent';
import { KeyField } from './basicStyledComponents/customerDetails.css';


interface IProps {
	
}

interface IState {
	types?: Account.ProductConfigTypes;
	products?: Array<Account.Product>
}

export default class ProductConfigComponent extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			products: [],
			types: {
				product_appointment_types: [],
				product_delivery_types: []
			}
		};

		this.getTypes();
		this.getProducts();
	}

	private getProducts() {
		api.asyncRequest<Array<Account.Product>>(
			{},
			apis.ProductApi,
			'productsGet'
		)
			.then((response: Array<Account.Product>) => {				
				this.setState({
					products: response
				});
			})
			.catch((error: ApiError) => {
				Log.error(Logs.COMPONENT, error);
			});
	}


	private getTypes() {

		api.asyncRequest<Account.ProductConfigTypes>(
			{},
			apis.ProductMaintentanceApi,
			'productMaintenanceProductConfigTypes'
		)
			.then((response: Account.ProductConfigTypes) => {				
				this.setState({
					types: response
				});
			})
			.catch((error: ApiError) => {
				Log.error(Logs.COMPONENT, error);
			});
	}

	private setConfig(req: Account.ProductMaintenanceProductAppointmentPutRequest | Account.ProductMaintenanceProductDeliveryPutRequest, operationID: 'productMaintenanceProductDeliveryPut' | 'productMaintenanceProductAppointmentPut') {
		api.asyncRequest<VoidResponse>(
			req,
			apis.ProductMaintentanceApi,
			operationID
			)
			.then(() => {				
				MessageHandler.onSuccess(Reporter['product.maintenance.config.put']);
				this.getProducts();
			})
			.catch((error: ApiError) => {
				Log.error(Logs.COMPONENT, error);
				MessageHandler.onError(Reporter['product.maintenance.config.put'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
			});
	}

	private deleteConfig(req: Account.ProductMaintenanceProductAppointmentDeleteRequest | Account.ProductMaintenanceProductDeliveryDeleteRequest, operationID: 'productMaintenanceProductDeliveryDelete' | 'productMaintenanceProductAppointmentDelete') {
		api.asyncRequest<VoidResponse>(
			req,
			apis.ProductMaintentanceApi,
			operationID
			)
			.then(() => {				
				MessageHandler.onSuccess(Reporter['product.maintenance.config.delete']);
				this.getProducts();
			})
			.catch((error: ApiError) => {
				Log.error(Logs.COMPONENT, error);
				MessageHandler.onError(Reporter['product.maintenance.config.delete'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
			});
	}


	
	renderBoxes(): Array<ReactElement> {
		const out = [];
		//translate('productMaintenance.productConfig.productDelivery');
		//translate('productMaintenance.productConfig.productAppointment');
		let i = 0;
		for(const o of this.state.products) {
			out.push(
				<div style={{marginBottom: '16px', marginLeft: '32px'}}>
					<Expandable
						title={o.name}>
						<ProductBox>
							<InnerBox>
								<Title
									title={translate('productMaintenance.deliveryType')}
								/>
								{ this.state.types.product_delivery_types.map((value: Account.ProductDeliveryType) => {
									return <KeyCheckBoxComponent 
												translationkey={'productMaintenance.productDelivery.' + value.product_delivery_type_description}
												id={value.product_delivery_type_description}
												required={false}
												onChange={(isSet: boolean) => {
													if (isSet === true) {
														this.setConfig({ProductDeliveryProduct: {
															product_delivery_type_id: value.product_delivery_type_id,
															product_id: o.product_id
														}}, 'productMaintenanceProductDeliveryPut')
													} else {
														this.deleteConfig({ product_delivery_type_id: value.product_delivery_type_id,
																			product_id: o.product_id
														}, 'productMaintenanceProductDeliveryDelete')
													}
												}}
												value={o.product_delivery_types != null && o.product_delivery_types.find((elem: Account.ProductDeliveryType) => {
													return value.product_delivery_type_id === elem.product_delivery_type_id 
												}) != null}
											/>
								})}
							</InnerBox>
							<InnerBox>
								<Title
									title={translate('productMaintenance.appointmentType')}
								/>
								{ this.state.types.product_appointment_types.map((value: Account.ProductAppointmentType) => {
									return <KeyCheckBoxComponent 
												translationkey={'productMaintenance.productAppointment.' + value.product_appointment_type_description}
												id={value.product_appointment_type_description}
												required={false}
												onChange={(isSet: boolean) => {
													if (isSet === true) {
														this.setConfig({ProductAppointmentProduct: {
															product_appointment_type_id: value.product_appointment_type_id,
															product_id: o.product_id
														}}, 'productMaintenanceProductAppointmentPut')
													} else {
														this.deleteConfig({ product_appointment_type_id: value.product_appointment_type_id,
																			product_id: o.product_id
														}, 'productMaintenanceProductAppointmentDelete')
													}
												}}
												value={o.product_appointment_types != null && o.product_appointment_types.find((elem: Account.ProductAppointmentType) => {
													return value.product_appointment_type_id === elem.product_appointment_type_id 
												}) != null}
											/>
								})}
							</InnerBox>
						</ProductBox>
					</Expandable>
				</div>
			)
			++i;
		}
		return out;
	}


	render() {
		return (
			<FlexBox>
				<TitleBox>
					<Title 
						title={translate('productMaintenance.productConfig')}
					/>
				</TitleBox>
				{this.renderBoxes()}
			</FlexBox>
		);
	}
}

const TitleBox = styled.div`
	display: flex;
	margin-top: 32px;
	justify-content: space-between;
	width: 90%;
`;


const ProductBox = styled.div`
	display: flex;
	flex-direction: row;
	margin: 16px;
	width: 90%;
	justify-content: center
`;

const InnerBox = styled.div`
	display: flex;
	flex-direction: column;
	width: 40%;
	margin: 8px;

`;
