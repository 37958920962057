// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AntibodyRange
 */
export interface AntibodyRange {
    /**
     * @type {number}
     * @memberof AntibodyRange
     */
    lower: number;
    /**
     * @type {number}
     * @memberof AntibodyRange
     */
    upper: number;
    /**
     * @type {string}
     * @memberof AntibodyRange
     */
    attribute: AntibodyRangeAttributeEnum;
    /**
     * @type {string}
     * @memberof AntibodyRange
     */
    memo: AntibodyRangeMemoEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AntibodyRangeAttributeEnum {
    Igg = 'igg',
    Igm = 'igm'
}
/**
 * @export
 * @enum {string}
 */
export enum AntibodyRangeMemoEnum {
    Positive = 'positive',
    Negative = 'negative',
    GreyAreaPositive = 'grey area positive'
}

