//@ts-nocheck
import React, { SyntheticEvent } from 'react';
import styled, { withTheme } from 'styled-components';
import { api, apis, ApiError, Ident } from '../../../../../logic/api/index';
import { theme } from '../../../../../common/theme';
import {
	IStatusState,
	Reporter,
	getFormComponents,
} from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import {
	OverlayHandler,
	Overlays,
} from '../../../../../logic/handler/overlayhandler/overlayHandler';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { translate } from '../../../../../common/language/translate';
import {
	IPermissionPerson,
	INotifyAbleTeamMemberData,
	TeamMemberDataStore,
	PermissionStore,
	Actions,
} from '../../../../../logic/flux';
import { Table } from '../../../../../components/compositcomponents/table/table';
import { RowType } from '../../../../../components/compositcomponents/table/tableTypes';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import { Box } from '../../../../../components/atomiccompoents/boxes/box';
import ActivityIndicator from '../../../../../components/atomiccompoents/activityIndicator';
import { FlexBox } from '../../../../auth/auth.css';
import { ContainerText } from '../../transfer/transfersComponent.css';
import Title from '../../../../../components/compositcomponents/title';
import { evaluateErrorMessage } from '../../../../../logic/helper/Common';

interface IProps {
	theme?: any;
}

interface IState extends IStatusState {
	members?: Array<IPermissionPerson>;
	loading?: boolean;
}

const StyledBox = styled(Box)`
	min-width: 650px;
`;

const StyledHr = styled.hr`
	border: 1px solid #e8ecef;
	width: 100%;
`;

const TableView = styled(FlexBox)`

`;

const Wrapper = styled(FlexBox)`
	margin-bottom: 24px;
`;

class TeamMembersComponentClass extends React.Component<IProps, IState>
	implements INotifyAbleTeamMemberData {
	private tableRef: Table<IPermissionPerson> | null = null;
	private OkButtonStyle = {
		width: this.props.theme.Button.width,
	};

	constructor(props: IProps) {
		super(props);

		this.state = {};

		this.onClickAdd = this.onClickAdd.bind(this);
		this._onChangeTeamMemberData = this._onChangeTeamMemberData.bind(this);
		this.getTeamMembers = this.getTeamMembers.bind(this);
	}

	_onChangeTeamMemberData(): void {
		const newTeamMembers = TeamMemberDataStore.getTeamMembers() || [];
		this.setState({
			members: newTeamMembers,
		});
	}

	componentDidMount(): void {
		TeamMemberDataStore.addChangeListener(this._onChangeTeamMemberData);
		this._onChangeTeamMemberData();
		this.getTeamMembers();
	}

	componentWillUnmount(): void {
		TeamMemberDataStore.removeChangeListener(this._onChangeTeamMemberData);
	}

	onClickAdd(event: SyntheticEvent) {
		event.stopPropagation();
		OverlayHandler.showOverlay(Overlays.teamMember, { createCallback: () => this.getTeamMembers() });
	}

	converter = function(
		line: IPermissionPerson,
		index: number,
		fields: Array<string>
	): RowType {
		const row: RowType = { cells: [], ref: line };
		if (row.cells == null) {
			return row;
		}
		const personId: number = line.person_id;
		const permissions = [
			Ident.OperationId.PermissionsPersonsRolesPut,
			Ident.OperationId.PermissionsPersonsRolesDelete,
		];

		const editingRoleParams = {
			personId: personId,
			personName: line.name,
			personEmail: line.primary_email_address,
			personGivenName: line.given_name
		};
		const keys: Array<string> = Object.keys(line);
		for (let i = 0; i < fields.length; i++) {
			const field: string = fields[i];
			switch (field) {
				case 'person_id': { 
					const personId: number = line.person_id;
					row.cells.push({
						value: String(personId),
						display: <ContainerText
									title={String(personId)}>
										{String(personId)}
								</ContainerText>,
						methods: {
							editCallback: (PermissionStore.hasOnePermissionOf(permissions) ? () => {								
										 OverlayHandler.showOverlay(
												Overlays.roleEdit,
												editingRoleParams
										  );
									} : undefined)
								},
						copyVal: String(personId)
					});
					break;
				}
				default: {
					if (Object.prototype.hasOwnProperty.call(line, field)) {
						row.cells.push({
							value: String(Object.values(line)[keys.indexOf(field)]),
							display: <ContainerText 
										title={String(Object.values(line)[keys.indexOf(field)])}>
											{String(Object.values(line)[keys.indexOf(field)])}
									</ContainerText>,
							methods: {
								editCallback: (PermissionStore.hasOnePermissionOf(permissions) ? () => {
									
											 OverlayHandler.showOverlay(
													Overlays.roleEdit,
													editingRoleParams
											  );
										} : undefined)
									},
							copyVal: String(Object.values(line)[keys.indexOf(field)])
							});
					} else {
						row.cells.push({ value: '' });
					}
					break;
				}
			}
		}
		return row;
	};

	getTeamMembers(): void {
		this.setState({
			loading: true,
		});
		api.asyncRequest<Array<IPermissionPerson>>(
			null,
			apis.PermissionsApi,
			'permissionsPersonsGet'
		)
			.then((response) => {
				Actions.teamMembersChanged(response);
				this.setState({
					loading: false,
				});
			})
			.catch((error: ApiError) => {
				const config = MessageHandler.onError(
                    Reporter['teammembers.permissions.persons.get.request'],
					evaluateErrorMessage(error, true), 
					evaluateErrorMessage(error, false)
				);
				this.setState({
					loading: false,
					showInlineError: config.errorMethods.inline,
					errorMessage: config.errorMessage != null ? config.errorMessage : '',
					key: config.translationKey != null ? config.translationKey : '',
				});
			});
	}

	teamMembers(): Array<IPermissionPerson> {
		return this.state.members || [];
	}

	render() {
		return (
			<FlexBox>
				<Wrapper>
					<StyledBox onClick={() => OverlayHandler.closeOverlaysOnClick()}>
						<FlexBox>
							<div>
								<Title title={translate('administration.teams.teammembers')} />
							</div>
							<StyledHr />
							<FlexBox>
								<TableView>
									{this.state.loading === true &&
									this.teamMembers().length === 0 ? (
										<ActivityIndicator
											size="medium"
											color={theme.ActivityIndicator.color}
										/>
									) : this.teamMembers().length === 0 ? (
										<div>
											{translate('administration.teams.nomembers')}
										</div>
									) : (
										<Table<IPermissionPerson>
											header={[												
												translate('team.header.lastname'),
												translate('team.header.firstname'),
												translate('team.header.email'),
												translate('team.header.personId'),
											]}
											sourceData={this.teamMembers()}
											fields={[												
												'name',
												'given_name',
												'primary_email_address',
												'person_id',
											]}
											stickyHeader={true}
											highlightTextOnSearch={true}
											dataConverter={this.converter}
											ref={(element) => (this.tableRef = element)}
										/>
									)}
								</TableView>
							</FlexBox>
							{getFormComponents(
								this.state.showInlineError,
								this.state.showInlineSuccess,
								this.state.errorMessage,
								this.state.successMessage,
								this.state.key
							)}
						</FlexBox>
					</StyledBox>
				</Wrapper>
				<ButtonOk
					disableSpinner={true}
					onClick={this.onClickAdd}
					id="btnAddNewTeamMember"
					style={this.OkButtonStyle}>
					{translate('button.addNewMember')}
				</ButtonOk>
			</FlexBox>
		);
	}
}

const TeamMembersComponent = withTheme(TeamMembersComponentClass);
export default TeamMembersComponent;
