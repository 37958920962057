// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface KYCStatus
 */
export interface KYCStatus {
    /**
     * @type {string}
     * @memberof KYCStatus
     */
    kyc_status: KYCStatusKycStatusEnum;
    /**
     * @type {Date}
     * @memberof KYCStatus
     */
    datetime_create: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum KYCStatusKycStatusEnum {
    KYCNeeded = 'KYC_needed',
    IDAndFaceScanSessionInitiated = 'ID_and_face_scan_session_initiated',
    IDAndFaceScanSessionCompleted = 'ID_and_face_scan_session_completed',
    IDAndFaceScanDataDelivered = 'ID_and_face_scan_data_delivered',
    IDAndFaceScanSessionFailed = 'ID_and_face_scan_session_failed',
    IDAndFaceScanResponseReceived = 'ID_and_face_scan_response_received',
    IDAndFaceScanResponseReceivedWithStatusACCEPTED = 'ID_and_face_scan_response_received_with_status_ACCEPTED',
    IDAndFaceScanResponseReceivedWithStatusREJECTED = 'ID_and_face_scan_response_received_with_status_REJECTED',
    WaitingForAddressCheck = 'waiting_for_address_check',
    AddressConfirmed = 'address_confirmed',
    WaitingForDocumentCheck = 'waiting_for_document_check',
    DocumentConfirmed = 'document_confirmed',
    AssecoDataDelivered = 'Asseco_data_delivered',
    AssecoResponseReceived = 'Asseco_response_received',
    KYCRejected = 'KYC_rejected',
    KYCApproved = 'KYC_approved',
    KYCCompleted = 'KYC_completed',
    Identified = 'identified',
    PasswordResetTriggered = 'password_reset_triggered',
    PasswordReset = 'password_reset',
    CoreDataChanged = 'core_data_changed',
    CoreDataAdded = 'core_data_added',
    AdvancedKYCRequested = 'advanced_KYC_requested',
    AdvancedKYCApproved = 'advanced_KYC_approved',
    AdvancedKYCRejected = 'advanced_KYC_rejected'
}

