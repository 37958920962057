//@ts-nocheck
import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import { Icons } from '../../../images';
import {
	IPropsCenter,
	IStateCenter,
	ACenteredOverlay,
} from '../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import { HideComponent } from '../../hideComponent';
import TouchableOpacity from '../../atomiccompoents/buttons/touchableOpacity';

const StyledDivMain = styled.div`
	margin: auto;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	background-color: white;

	padding: 16px;
	width: 400px;
	height: 430px;

	box-sizing: border-box;
`;

const StyledDivFirstLine = styled.div`
	font-size: 20px;
	line-height: 23px;
	letter-spacing: 0.2px;
	color: ${props => props.theme.Global.darkFontColor};
	text-align: center;
	padding-bottom: 20px;
	margin-top: 100px;
`;

const StyledDivIcon: CSSProperties = {
	display: 'flex',
	justifyContent: 'flex-end',
	padding: '8px',
};

const StyledDivSecondLine = styled.div`
	padding: 10px 15px 20px 15px;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.2px;
	color: ${props => props.theme.Global.darkFontColor};
	text-align: center;
`;

const StyledLine = styled.hr`
	border: 1px solid #f2f4f6;
	margin-top: 16px;
	margin-bottom: 16px;
`;

interface IProps extends IPropsCenter {
	notification: () => void;
	heading: string;
	message: string;
	selfClosed?: boolean;
}

interface IState extends IStateCenter {}

export class ConfirmationMessageOverlay extends ACenteredOverlay<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.close = this.close.bind(this);
	}

	getContent() {
		return (
			<StyledDivMain>
				<TouchableOpacity
					containerStyle={StyledDivIcon}
					onClick={() => {
						this.close();
					}}>
					{Icons.closingCross()}
				</TouchableOpacity>
				<StyledDivFirstLine>{this.props.heading}</StyledDivFirstLine>
				<StyledLine />
				<StyledDivSecondLine>{this.props.message}</StyledDivSecondLine>
				<StyledLine />
			</StyledDivMain>
		);
	}

	close() {
		this.props.selfClose();
	}

	reviveState(): void {}

	defineContent() {
		if (this.props.selfClosed === true) {
			return (
				<HideComponent delay={4} onHide={this.close}>
					{this.getContent()}
				</HideComponent>
			);
		} else {
			return this.getContent();
		}
	}
}
