// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    TransactionTemplate,
} from '../models';

export interface ReportTemplatesAdminGetRequest {
    language?: ReportTemplatesAdminGetLanguageEnum;
}

export interface ReportTemplatesBoGetRequest {
    language?: ReportTemplatesBoGetLanguageEnum;
}

export interface ReportTemplatesCustomerGetRequest {
    language?: ReportTemplatesCustomerGetLanguageEnum;
}

export interface ReportTemplatesGetRequest {
    report_class: ReportTemplatesGetReportClassEnum;
    language?: ReportTemplatesGetLanguageEnum;
}

export interface TransactionTemplatesAdminGetRequest {
    language?: TransactionTemplatesAdminGetLanguageEnum;
}

export interface TransactionTemplatesBoGetRequest {
    language?: TransactionTemplatesBoGetLanguageEnum;
}

export interface TransactionTemplatesCustomerGetRequest {
    language?: TransactionTemplatesCustomerGetLanguageEnum;
}

export interface TransactionTemplatesGetRequest {
    transaction_class?: TransactionTemplatesGetTransactionClassEnum;
    language?: TransactionTemplatesGetLanguageEnum;
}

/**
 * no description
 */
export class TemplatesApi extends BaseAPI implements IOperationIdMap {

    /**
     * get all admin report templates
     */
    reportTemplatesAdminGet = (requestParameters: ReportTemplatesAdminGetRequest): Observable<Array<TransactionTemplate>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<TransactionTemplate>>({
            path: '/report_templates/admin',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get all bo report templates
     */
    reportTemplatesBoGet = (requestParameters: ReportTemplatesBoGetRequest): Observable<Array<TransactionTemplate>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<TransactionTemplate>>({
            path: '/report_templates/bo',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get all customer report templates
     */
    reportTemplatesCustomerGet = (requestParameters: ReportTemplatesCustomerGetRequest): Observable<Array<TransactionTemplate>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<TransactionTemplate>>({
            path: '/report_templates/customer',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * to be removed as it was split into report-admin/bo/customer
     * get all report templates
     */
    reportTemplatesGet = (requestParameters: ReportTemplatesGetRequest): Observable<Array<TransactionTemplate>> => {
        throwIfRequired(requestParameters, 'report_class', 'reportTemplatesGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.report_class && { 'report_class': requestParameters.report_class }),
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<TransactionTemplate>>({
            path: '/report_templates',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get all admin transaction templates
     */
    transactionTemplatesAdminGet = (requestParameters: TransactionTemplatesAdminGetRequest): Observable<Array<TransactionTemplate>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<TransactionTemplate>>({
            path: '/transaction_templates/transaction-admin',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get all bo transaction templates
     */
    transactionTemplatesBoGet = (requestParameters: TransactionTemplatesBoGetRequest): Observable<Array<TransactionTemplate>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<TransactionTemplate>>({
            path: '/transaction_templates/transaction-bo',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get all customer transaction templates
     */
    transactionTemplatesCustomerGet = (requestParameters: TransactionTemplatesCustomerGetRequest): Observable<Array<TransactionTemplate>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<TransactionTemplate>>({
            path: '/transaction_templates/transaction-customer',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * to be removed as it was split into transactions-admin/bo/customer
     * get all transaction templates
     */
    transactionTemplatesGet = (requestParameters: TransactionTemplatesGetRequest): Observable<Array<TransactionTemplate>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.transaction_class && { 'transaction_class': requestParameters.transaction_class }),
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<TransactionTemplate>>({
            path: '/transaction_templates',
            method: 'GET',
            headers,
            query,
        });
    };


    operationToOperationId = {
	    "reportTemplatesAdminGet": "report_templates_admin_get" ,
	    "reportTemplatesBoGet": "report_templates_bo_get" ,
	    "reportTemplatesCustomerGet": "report_templates_customer_get" ,
	    "reportTemplatesGet": "report_templates_get" ,
	    "transactionTemplatesAdminGet": "transaction_templates_admin_get" ,
	    "transactionTemplatesBoGet": "transaction_templates_bo_get" ,
	    "transactionTemplatesCustomerGet": "transaction_templates_customer_get" ,
	    "transactionTemplatesGet": "transaction_templates_get"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum ReportTemplatesAdminGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum ReportTemplatesBoGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum ReportTemplatesCustomerGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum ReportTemplatesGetReportClassEnum {
    Bo = 'report-bo',
    Admin = 'report-admin',
    Customer = 'report-customer'
}
/**
 * @export
 * @enum {string}
 */
export enum ReportTemplatesGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum TransactionTemplatesAdminGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum TransactionTemplatesBoGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum TransactionTemplatesCustomerGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum TransactionTemplatesGetTransactionClassEnum {
    Bo = 'transaction-bo',
    Admin = 'transaction-admin',
    Customer = 'transaction-customer'
}
/**
 * @export
 * @enum {string}
 */
export enum TransactionTemplatesGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
