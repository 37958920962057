// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CompanionRelationship,
    CountryCodeAlpha3PlusKosovo,
} from './';

/**
 * the pending companion requests
 * @export
 * @interface Companions
 */
export interface Companions {
    /**
     * @type {number}
     * @memberof Companions
     */
    person_id: number;
    /**
     * the other persons given name
     * @type {string}
     * @memberof Companions
     */
    given_name: string;
    /**
     * the other persons given name
     * @type {string}
     * @memberof Companions
     */
    name: string;
    /**
     * * C other person should become companion * P other person should become primary 
     * @type {string}
     * @memberof Companions
     */
    relation_type: CompanionsRelationTypeEnum;
    /**
     * the url the users face image if there is one
     * @type {string}
     * @memberof Companions
     */
    face_url?: string;
    /**
     * a phone number in format e164, if there is one
     * @type {string}
     * @memberof Companions
     */
    phone_number?: string;
    /**
     * @type {CompanionRelationship}
     * @memberof Companions
     */
    relationship?: CompanionRelationship;
    /**
     * @type {string}
     * @memberof Companions
     */
    street?: string;
    /**
     * @type {string}
     * @memberof Companions
     */
    building_identifier?: string;
    /**
     * @type {string}
     * @memberof Companions
     */
    city?: string;
    /**
     * @type {string}
     * @memberof Companions
     */
    post_code?: string;
    /**
     * @type {CountryCodeAlpha3PlusKosovo}
     * @memberof Companions
     */
    country?: CountryCodeAlpha3PlusKosovo;
    /**
     * @type {Date}
     * @memberof Companions
     */
    scheduled_disconnect?: Date;
    /**
     * Famaily relation:   * \'junior\' - For childe    * \'family_member\' - other relatives 
     * @type {string}
     * @memberof Companions
     */
    family_relation?: CompanionsFamilyRelationEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CompanionsRelationTypeEnum {
    C = 'C',
    P = 'P'
}
/**
 * @export
 * @enum {string}
 */
export enum CompanionsFamilyRelationEnum {
    Junior = 'junior',
    FamilyMember = 'family_member'
}

