import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';

interface IPaginationData {
	rowsPP: number;
	first: number;
	last: number;
	amount?: number;
	offset?: number;
}

class PagingStore extends EventEmitter {
	private static instance: PagingStore | null;
	private notification: any = null;
	private pages: IPaginationData = { rowsPP: 0, first: 0, last: 0 };
	private receiver: {[id:string]: IPaginationData} = {};
	private blocked: boolean = false;
	constructor() {
		super();
		IDispatcher.register(this.registerActions.bind(this));
	}
	static getInstance(): PagingStore {
		if (PagingStore.instance == null) {
			PagingStore.instance = new PagingStore();
		}

		return PagingStore.instance;
	}

	addChangeListener(callback: any) {
		this.on(CHANGE_EVENT, callback);
	}

	removeChangeListener(callback: any) {
		this.removeListener(CHANGE_EVENT, callback);
	}

	registerActions(action: any) {
		switch (action.actionType) {
			default:
				break;
			case Constants.PAGES:
				if(action.id != null) {
					this.receiver[action.id] = action.paging
				} else{
					this.pages = action.pages;
				}
				this.emit(CHANGE_EVENT);
				break;
			case Constants.BLOCK:
				this.blocked = action.blocked;
				break;
			case Constants.NOTIFICATION_SET:
				this.notification = action.notification;
				break;
		}
		return true;
	}

	getPages(id?: string): IPaginationData {
		if(id != null) {
			return this.receiver[id];
		}
		return this.pages;
	}

	getBlocked(): boolean {
		return this.blocked;
	}

	getNofitication(): any {
		return this.notification;
	}
}

export const IPagingStore = PagingStore.getInstance();
