import React from 'react';
import styled from 'styled-components';

interface IProps {
	/**
	 * Whether to show the indicator (true, the default) or hide it (false).
	 */
	animating?: boolean;

	/**
	 * Whether the indicator should hide when not animating (true by default).
	 */
	hidesWhenStopped?: boolean;
	/**
	 * Size of the indicator.
	 * Small has a height of 20, large has a height of 36.
	 *
	 * enum('small', 'large')
	 */
	size?: 'small' | 'medium' | 'large' | number;
	color?: string;
}

interface IState {}

export default class ActivityIndicator extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<Container>
				<Activity
					size={
						this.props.size == null || this.props.size === 'medium'
							? 70
							: this.props.size === 'small'
							? 20
							: this.props.size === 'large'
							? 120
							: this.props.size
					}
				/>
			</Container>
		);
	}
}

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Activity = styled.div<{ color?: string; size: number }>`
	border: ${(props) => props.size * 0.1333 + 'px'} solid #f3f3f3;
	border-top: ${(props) => props.size * 0.1333 + 'px'} solid
		${(props) => (props.color == null ? props.theme.Global.primaryColor : props.color)};
	border-radius: 50%;
	width: ${(props) => props.size + 'px'};
	height: ${(props) => props.size + 'px'};
	animation: spin 2s linear infinite;
	display: inline;
	flex-grow: 0;
	flex-shrink: 0;

	margin: auto;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
