// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RequestMoneyState,
} from './';

/**
 * a card-takeover request
 * @export
 * @interface CardTakeoverResponse
 */
export interface CardTakeoverResponse {
    /**
     * @type {number}
     * @memberof CardTakeoverResponse
     */
    ta_id: number;
    /**
     * @type {number}
     * @memberof CardTakeoverResponse
     */
    request_money_id: number;
    /**
     * @type {string}
     * @memberof CardTakeoverResponse
     */
    own_account: string;
    /**
     * the creditor account identification, either  * an email address, or  * an accountnumber or  * an iban note * phone number note: we need to find out why what the swagger editor does not like the email regex from https://emailregex.com/ 
     * @type {string}
     * @memberof CardTakeoverResponse
     */
    counterparty_account: string;
    /**
     * Creditor Name
     * @type {string}
     * @memberof CardTakeoverResponse
     */
    counterparty_name: string;
    /**
     * Creditor Name
     * @type {string}
     * @memberof CardTakeoverResponse
     */
    counterparty_given_name: string;
    /**
     * @type {number}
     * @memberof CardTakeoverResponse
     */
    counterparty_person_id: number;
    /**
     * @type {string}
     * @memberof CardTakeoverResponse
     */
    counterparty_email_address?: string;
    /**
     * @type {string}
     * @memberof CardTakeoverResponse
     */
    counterparty_phone_number?: string;
    /**
     * Unstructured remittance information 
     * @type {string}
     * @memberof CardTakeoverResponse
     */
    remittance_information_unstructured?: string;
    /**
     * shows when this request was created
     * @type {Date}
     * @memberof CardTakeoverResponse
     */
    datetime_create: Date;
    /**
     * indicates if a request money was received(I) or sent (O)
     * @type {string}
     * @memberof CardTakeoverResponse
     */
    in_out: CardTakeoverResponseInOutEnum;
    /**
     * @type {RequestMoneyState}
     * @memberof CardTakeoverResponse
     */
    state: RequestMoneyState;
    /**
     * shows when this request was updated either withdrawn, denied, cancelled or accepted
     * @type {Date}
     * @memberof CardTakeoverResponse
     */
    datetime_update?: Date;
    /**
     * external reference as provided by the initiaing application
     * @type {string}
     * @memberof CardTakeoverResponse
     */
    external_reference?: string;
    /**
     * @type {string}
     * @memberof CardTakeoverResponse
     */
    face_image_link?: string;
    /**
     * indicates if card take-over request or card take-over offer
     * @type {string}
     * @memberof CardTakeoverResponse
     */
    offer_or_request: CardTakeoverResponseOfferOrRequestEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CardTakeoverResponseInOutEnum {
    I = 'I',
    O = 'O'
}
/**
 * @export
 * @enum {string}
 */
export enum CardTakeoverResponseOfferOrRequestEnum {
    R = 'R',
    O = 'O'
}

