import { IAuthConfig } from './authcomponents/IAuthConfig';
import { Ident } from '../../logic/api';
import { Config } from '../../config';

export const indexOfDefault = Config.auth.defaultPageIndex;

export const authConfig: Array<IAuthConfig> = [
	{
		title: 'auth.signin.title',
		subTitle: 'auth.signin.subtitle',
		enable: true,
		debugTitle: 'sign in',
		authConnection: Ident.AuthConnection.LoginUserPassword,
		uri: '/login',
		linkText: 'auth.signin.link',
		id: 'signin',
	},
	{
		title: 'auth.faceLogin.title',
		subTitle: 'auth.faceLogin.subtitle',
		footer: ['Need help?', 'Contact the admin'],
		enable: false,
		debugTitle: 'face recognition',
		authConnection: Ident.AuthConnection.FaceLogin,
		uri: '/faceLogin',
		linkText: 'auth.faceLogin.link',
		id: 'faceLogin',
	},
	{
		title: 'auth.contactAdmin.title',
		subTitle: 'auth.contactAdmin.subtitle',
		enable: true,
		debugTitle: 'contact admin',
		authConnection: Ident.AuthConnection.ContactAdmin,
		uri: '/contact',
		linkText: 'auth.contactAdmin.link',
		id: 'contactAdmin',
	},
	{
		title: 'auth.resetPassword.title',
		subTitle: 'auth.resetPassword.subtitle',
		enable: true,
		debugTitle: 'reset password',
		authConnection: Ident.AuthConnection.ResetPassword,
		uri: '/resetPassword',
		linkText: 'auth.resetPassword.link',
		id: 'resetPassword',
	},
	{
		title: 'auth.password.title',
		subTitle: 'auth.password.subtitle',
		enable: true,
		debugTitle: 'set password',
		authConnection: Ident.AuthConnection.SetPassword,
		uri: '/password',
		linkText: 'auth.password.link',
		id: 'password',
	},
	{
		title: 'auth.verify.title',
		subTitle: 'auth.verify.subtitle',
		enable: true,
		debugTitle: 'enter code',
		authConnection: Ident.AuthConnection.Verify,
		uri: '/verify',
		linkText: 'auth.verify.link',
		id: 'verify',
	},
	{
		title: 'auth.processComplete.title',
		subTitle: 'auth.processComplete.subtitle',
		enable: true,
		debugTitle: 'process complete',
		authConnection: null, //TODO
		uri: '', //TODO
		linkText: 'auth.processComplete.link',
		id: 'processComplete',
	},
	{
		title: 'auth.confirmation.title',
		subTitle: 'auth.confirmation.subtitle',
		enable: true,
		debugTitle: 'got mail',
		authConnection: null,
		uri: '',
		linkText: '',
		id: 'gotMail',
	},
];
