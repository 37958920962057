// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationId {
    PersonGet = 'person_get',
    PersonPut = 'person_put',
    PersonCreateThirdPersonPost = 'person_create_third_person_post',
    PersonConnectPost = 'person_connect_post',
    OauthSignupPost = 'oauth_signup_post',
    OauthSignupAddressPost = 'oauth_signup_address_post',
    OauthSignupEmailPost = 'oauth_signup_email_post',
    OauthSignupPhonePost = 'oauth_signup_phone_post',
    OauthSignupUserdataPost = 'oauth_signup_userdata_post',
    OauthSignupCancelPhonePost = 'oauth_signup_cancel_phone_post',
    PersonSearch = 'person_search',
    PersonPersonIdGet = 'person_person_id_get',
    PersonDelete = 'person_delete',
    SelfPasswordPut = 'self_password_put',
    SelfPersonPut = 'self_person_put',
    SelfPersonDelete = 'self_person_delete',
    SelfSettingsGet = 'self_settings_get',
    SelfSettingsPut = 'self_settings_put',
    SelfPassportGet = 'self_passport_get',
    SelfFaceList = 'self_face_list',
    SelfFaceGet = 'self_face_get',
    SelfFacePost = 'self_face_post',
    SelfFaceDelete = 'self_face_delete',
    SelfFaceGetCurrent = 'self_face_get_current',
    SelfPhoneList = 'self_phone_list',
    PersonPersonIdAddressRequirement = 'person_person_id_address_requirement',
    SelfPendingRequestsGet = 'self_pending_requests_get',
    SelfPendingRequestsPut = 'self_pending_requests_put',
    SelfPhonePost = 'self_phone_post',
    SelfPhonePut = 'self_phone_put',
    SelfGetstreamCreateUserPost = 'self_getstream_create_user_post',
    SelfGetstreamCreateTokenGet = 'self_getstream_create_token_get',
    SelfPhoneDelete = 'self_phone_delete',
    SelfAddressGet = 'self_address_get',
    SelfAddressPost = 'self_address_post',
    SelfAddressPut = 'self_address_put',
    SelfAddressDelete = 'self_address_delete',
    SelfRegisterCustomer = 'self_register_customer',
    PersonJuniorDocumentList = 'person_junior_document_list',
    SelfRegisterStaff = 'self_register_staff',
    SelfCompanionCodeList = 'self_companion_code_list',
    SelfCompanionCodePost = 'self_companion_code_post',
    SelfCompanionCodeDelete = 'self_companion_code_delete',
    SelfCompanionCodeExtendedPost = 'self_companion_code_extended_post',
    SelfCompanionCodeDetail = 'self_companion_code_detail',
    SelfCompanionRequestList = 'self_companion_request_list',
    SelfCompanionRequestPost = 'self_companion_request_post',
    SelfCompanionRequestPut = 'self_companion_request_put',
    SelfCompanionRequestDelete = 'self_companion_request_delete',
    SelfCompanionList = 'self_companion_list',
    SelfCompanionDelete = 'self_companion_delete',
    SelfJointPersonList = 'self_joint_person_list',
    SelfJointPersonCreate = 'self_joint_person_create',
    SelfDocumentList = 'self_document_list',
    SelfDocumentGet = 'self_document_get',
    SelfDocumentDelete = 'self_document_delete',
    SelfDocumentPost = 'self_document_post',
    SelfAccessKeyGet = 'self_access_key_get',
    SelfAccessKeyPost = 'self_access_key_post',
    SelfAccessKeyDelete = 'self_access_key_delete',
    SelfReferralsGet = 'self_referrals_get',
    SelfElectronicidVideoidRequestPost = 'self_electronicid_videoid_request_post',
    SelfElectronicidVideoidRequestPut = 'self_electronicid_videoid_request_put',
    SelfElectronicidVideoidResponsePost = 'self_electronicid_videoid_response_post',
    SelfElectronicidVideoidFailedGet = 'self_electronicid_videoid_failed_get',
    SelfKycStatusGet = 'self_kyc_status_get',
    SelfEmailAddressGet = 'self_email_address_get',
    SelfEmailAddressPost = 'self_email_address_post',
    SelfEmailAddressPut = 'self_email_address_put',
    SelfTokenGet = 'self_token_get',
    PersonReasonForClosureGet = 'person_reason_for_closure_get',
    PersonSettingsGet = 'person_settings_get',
    PersonSettingsPut = 'person_settings_put',
    PersonMerchantPut = 'person_merchant_put',
    PersonMerchantDocumentList = 'person_merchant_document_list',
    PersonMerchantDocumentGet = 'person_merchant_document_get',
    PersonDocumentPut = 'person_document_put',
    ToBeRemovedPersonDocumentList = 'to_be_removed_person_document_list',
    PersonDocumentList = 'person_document_list',
    PersonDocumentGet = 'person_document_get',
    PersonPassportGet = 'person_passport_get',
    PersonPassportDocumentPut = 'person_passport_document_put',
    PersonPassportStoredImageGet = 'person_passport_stored_image_get',
    PersonPassportPassportFrontGet = 'person_passport_passport_front_get',
    PersonPassportPassportBackGet = 'person_passport_passport_back_get',
    PersonAddressGet = 'person_address_get',
    PersonAddressPost = 'person_address_post',
    PersonAddressPut = 'person_address_put',
    PersonAddressDelete = 'person_address_delete',
    PersonEmailaddressGet = 'person_emailaddress_get',
    PersonEmailAddressPost = 'person_email_address_post',
    PersonEmailAddressPut = 'person_email_address_put',
    PersonEmailaddressDelete = 'person_emailaddress_delete',
    PersonEmailaddressActivate = 'person_emailaddress_activate',
    PersonPinPost = 'person_pin_post',
    PersonPasswordReset = 'person_password_reset',
    PersonCompanionList = 'person_companion_list',
    PersonPhoneList = 'person_phone_list',
    PersonPhonePost = 'person_phone_post',
    PersonPhoneDelete = 'person_phone_delete',
    PersonFingerprintList = 'person_fingerprint_list',
    PersonFingerprintPost = 'person_fingerprint_post',
    PersonFingerprintDelete = 'person_fingerprint_delete',
    PersonFaceList = 'person_face_list',
    PersonFacePost = 'person_face_post',
    PersonFaceGet = 'person_face_get',
    PersonFaceDelete = 'person_face_delete',
    PersonCurrentFaceGet = 'person_current_face_get',
    PersonFaceGetCurrent = 'person_face_get_current',
    PersonPersonIdReferralLinkPost = 'person_person_id_referral_link_post',
    PersonReferralProductsGet = 'person_referral_products_get',
    PersonCompanionCodeList = 'person_companion_code_list',
    PersonReferralLinkPost = 'person_referral_link_post',
    PersonResync = 'person_resync',
    PersonRecode = 'person_recode',
    PersonPersonIdAccessKeyGet = 'person_person_id_access_key_get',
    PersonPersonIdAccessKeyPost = 'person_person_id_access_key_post',
    PersonPersonIdAccessKeyDelete = 'person_person_id_access_key_delete',
    AccessKeyPut = 'access_key_put',
    UnregisteredMobileTagPut = 'unregistered_mobile_tag_put',
    MerchantUnregisteredCheckin = 'merchant_unregistered_checkin',
    LookupProductUserClassGet = 'lookup_product_user_class_get',
    LookupExternalIdTypes = 'lookup_external_id_types',
    LookupOpenAddressApprovalList = 'lookup_open_address_approval_list',
    PartnerGet = 'partner_get',
    PersonPartnerTransfer = 'person_partner_transfer',
    PersonPartnerTransferDelete = 'person_partner_transfer_delete',
    PersonPartnerCloseAccountPost = 'person_partner_close_account_post',
    PersonsPersonIdAccountTaskPost = 'persons_person_id_account_task_post',
    PersonElectronicidVerificationGet = 'person_electronicid_verification_get',
    PersonElectronicidVerificationPut = 'person_electronicid_verification_put',
    PersonElectronicidVerificationFaceGet = 'person_electronicid_verification_face_get',
    PersonElectronicidVerificationFrontGet = 'person_electronicid_verification_front_get',
    PersonElectronicidVerificationBackGet = 'person_electronicid_verification_back_get',
    PersonElectronicidRequestList = 'person_electronicid_request_list',
    PersonElectronicidVideoidFailedList = 'person_electronicid_videoid_failed_list',
    PersonElectronicidWebhookNotificationList = 'person_electronicid_webhook_notification_list',
    PersonElectronicidPassportPut = 'person_electronicid_passport_put',
    AuthAuthorizeGet = 'auth_authorize_get',
    OauthLoginGet = 'oauth_login_get',
    OauthLoginPost = 'oauth_login_post',
    OauthLoginPhonePost = 'oauth_login_phone_post',
    OauthLoginTokenPost = 'oauth_login_token_post',
    OauthResetpassword = 'oauth_resetpassword',
    OauthVerifyPost = 'oauth_verify_post',
    OauthSetPasswordPost = 'oauth_set_password_post',
    OauthChoosePersonPost = 'oauth_choose_person_post',
    OauthNologinGet = 'oauth_nologin_get',
    OauthLogoutGet = 'oauth_logout_get',
    OauthLogoutPost = 'oauth_logout_post',
    OauthCompanionCodeDetail = 'oauth_companion_code_detail',
    AuthWellKnownOidc = 'auth_well_known_oidc',
    OauthTokenPost = 'oauth_token_post',
    OauthUserinfoGet = 'oauth_userinfo_get',
    OauthUserinfoPost = 'oauth_userinfo_post',
    PermissionsRolesGet = 'permissions_roles_get',
    PermissionsPersonsGet = 'permissions_persons_get',
    PermissionsRolesPersonsGet = 'permissions_roles_persons_get',
    PermissionsPersonsRolesGet = 'permissions_persons_roles_get',
    PermissionsPersonsRolesPut = 'permissions_persons_roles_put',
    PermissionsPersonsRolesDelete = 'permissions_persons_roles_delete',
    PharmacyPut = 'pharmacy_put',
    ReleasesGet = 'releases_get',
    ReleasesPut = 'releases_put',
    ReleasesDetailsGet = 'releases_details_get',
    ReportTemplatesGet = 'report_templates_get',
    ReportTemplatesAdminGet = 'report_templates_admin_get',
    ReportTemplatesBoGet = 'report_templates_bo_get',
    ReportTemplatesCustomerGet = 'report_templates_customer_get',
    Rpt008CustomerProfile = 'rpt008_customer_profile',
    Rpt017ManualInterventionList = 'rpt017_manual_intervention_list',
    Rpt028ReferralCodeUsageList = 'rpt028_referral_code_usage_list',
    EidWebhookNotificationPost = 'eid_webhook_notification_post',
    EidVideoidFailedPost = 'eid_videoid_failed_post',
    PersonKycStatusGet = 'person_kyc_status_get',
    PersonKycHistoryGet = 'person_kyc_history_get',
    PersonRequestKycPut = 'person_request_kyc_put',
    PersonRequestAdvancedKycPost = 'person_request_advanced_kyc_post',
    PersonRequestAdvancedKycGet = 'person_request_advanced_kyc_get',
    PersonHistoryGet = 'person_history_get',
    PersonActedDocumentsGet = 'person_acted_documents_get',
    PersonUnreadDocumentsPut = 'person_unread_documents_put',
    PersonUnreadDocumentsGet = 'person_unread_documents_get',
    Rpt026OnboardingStatsGet = 'rpt026_onboarding_stats_get',
    Rpt029ClientOnboardList = 'rpt029_client_onboard_list',
    CloudinarySearchPost = 'cloudinary_search_post',
    RegulaforensicsApiProcessPost = 'regulaforensics_api_process_post',
    RegulaforensicsApiProcessGet = 'regulaforensics_api_process_get',
    RegulaforensicsApiProcessPut = 'regulaforensics_api_process_put',
    RegulaforensicsApiMatchPost = 'regulaforensics_api_match_post',
    RegulaforensicsDocumentAndFaceMatchPost = 'regulaforensics_document_and_face_match_post',
    RegulaforensicsPermissionPost = 'regulaforensics_permission_post',
    SelfOnfidoCreateApplicantPost = 'self_onfido_create_applicant_post',
    SelfOnfidoGenerateSdkTokenGet = 'self_onfido_generate_sdk_token_get',
    SelfOnfidoGenerateSdkTokenPost = 'self_onfido_generate_sdk_token_post',
    SelfOnfidoCreateCheckPost = 'self_onfido_create_check_post',
    OnfidoBackofficeNotificationTogglePut = 'onfido_backoffice_notification_toggle_put',
    OnfidoBackofficeNotificationsGet = 'onfido_backoffice_notifications_get'
}

