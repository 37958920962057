// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    EyesonBaseWebhook,
    EyesonRoom,
    EyesonRoomWebhookAllOf,
    EyesonRoomWebhookAllOf1,
} from './';

/**
 * @export
 * @interface EyesonRoomWebhook
 */
export interface EyesonRoomWebhook {
    /**
     * @type {number}
     * @memberof EyesonRoomWebhook
     */
    timestamp: number;
    /**
     * @type {string}
     * @memberof EyesonRoomWebhook
     */
    type: EyesonRoomWebhookTypeEnum;
    /**
     * @type {EyesonRoom}
     * @memberof EyesonRoomWebhook
     */
    room: EyesonRoom;
}

/**
 * @export
 * @enum {string}
 */
export enum EyesonRoomWebhookTypeEnum {
    RoomUpdate = 'room_update'
}

