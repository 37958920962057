import React, { ReactElement, SyntheticEvent } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate } from '../../common/language/translate';
import { Ident } from '../../logic/api';
import { IAuthConfig } from './authcomponents/IAuthConfig';
import { Log, Logs } from '../../logic/log';

export interface IAuthScreenProps extends RouteComponentProps {
	idpState?: string;
	children?: Array<ReactElement> | ReactElement;
	connection?: Array<Ident.AuthConnection>;
	authConfig?: Array<IAuthConfig>;
	currentAuthConfig?: IAuthConfig;
}

export interface IAuthScreenState {
	redirectTo?: string;
	apiError?: string;
	showInlineErrors?: boolean;
}

export default abstract class AuthScreen<
	P extends IAuthScreenProps,
	S extends IAuthScreenState
> extends React.Component<P, S> {
	abstract onSubmit(event: SyntheticEvent | React.KeyboardEvent<HTMLFormElement>): void; //TODO: remove SyntheticEvent here when all auth components use the new button
	idpState: string;
	authConfig: Array<IAuthConfig>;
	connection: Array<Ident.AuthConnection>;

	constructor(props: P) {
		super(props);

		this.authConfig = props.authConfig == null ? [] : props.authConfig;
		this.connection = props.connection == null ? [] : props.connection;

		if (
			props.location != null &&
			props.location.state != null 
		) {
			const state = props.location.state as any || {};
			if('idpState' in state && state['idpState'] != null) {
				Log.debug(Logs.AUTH, 'Using idpstate from props.location');
				this.idpState = state['idpState'];
			} else {
				this.idpState = '';
			}
		} else if (props.idpState != null) {
			this.idpState = props.idpState;
		} else {
			Log.error(Logs.AUTH, 'idpState not set, the next request will propably not work');
			this.idpState = '';
		}
	}

	protected getAuthConfigForConnection(
		connection: Ident.AuthConnection
	): IAuthConfig | undefined {
		return this.authConfig.find((value) => {
			return value.authConnection === connection;
		});
	}

	protected getSubtitle(): string | string[] {
		if (this.props.currentAuthConfig == null) {
			return '';
		}

		return this.props.currentAuthConfig.subTitle;
	}

	protected getTitle(): string {
		if (this.props.currentAuthConfig == null) {
			return '';
		}

		return this.props.currentAuthConfig.title;
	}

	protected getAuthConfigById(id: string): IAuthConfig | undefined {
		return this.authConfig.find((value) => {
			return value.id === id;
		});
	}

	getLinkForConnection(connection: Ident.AuthConnection): ReactElement | null {
		if (this.connection.includes(connection)) {
			const authConfig: IAuthConfig | undefined = this.getAuthConfigForConnection(
				connection
			);
			if (authConfig == null) {
				Log.debug(Logs.AUTH, 'No authConfig found for connection: ' + connection);
				return null;
			}

			if (authConfig.linkText == null || authConfig.linkText.trim() === '') {
				return null;
			}

			const linkText = translate(authConfig.linkText);

			return (
				<Link id={authConfig.id} to={authConfig.uri}>
					{linkText}
				</Link>
			);
		}

		return null;
	}
}
