// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CountryCodeAlpha2,
    NewPhone,
} from './';

/**
 * personal data of the companion, to be shown during onboarding. please note at email or phone must be given, in addition to the names.
 * @export
 * @interface CompanionCodeRequest
 */
export interface CompanionCodeRequest {
    /**
     * the persons given name
     * @type {string}
     * @memberof CompanionCodeRequest
     */
    given_name: string;
    /**
     * the persons given name
     * @type {string}
     * @memberof CompanionCodeRequest
     */
    name: string;
    /**
     * the email address
     * @type {string}
     * @memberof CompanionCodeRequest
     */
    email_address?: string;
    /**
     * @type {NewPhone}
     * @memberof CompanionCodeRequest
     */
    phone?: NewPhone;
    /**
     * @type {CountryCodeAlpha2}
     * @memberof CompanionCodeRequest
     */
    country?: CountryCodeAlpha2;
    /**
     * Famaily relation:   * \'junior\' - For childe    * \'family_member\' - other relatives 
     * @type {string}
     * @memberof CompanionCodeRequest
     */
    family_relation?: CompanionCodeRequestFamilyRelationEnum;
    /**
     * @type {Date}
     * @memberof CompanionCodeRequest
     */
    date_of_birth?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum CompanionCodeRequestFamilyRelationEnum {
    Junior = 'junior',
    FamilyMember = 'family_member'
}

