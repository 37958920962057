// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface PersonKYCStatus
 */
export interface PersonKYCStatus {
    /**
     * @type {string}
     * @memberof PersonKYCStatus
     */
    kyc_status: PersonKYCStatusKycStatusEnum;
    /**
     * @type {string}
     * @memberof PersonKYCStatus
     */
    address_status: PersonKYCStatusAddressStatusEnum;
    /**
     * @type {Date}
     * @memberof PersonKYCStatus
     */
    datetime_create: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum PersonKYCStatusKycStatusEnum {
    N = 'N',
    I = 'I',
    S = 'S',
    W = 'W',
    A = 'A',
    R = 'R',
    D = 'D'
}
/**
 * @export
 * @enum {string}
 */
export enum PersonKYCStatusAddressStatusEnum {
    Needed = 'needed',
    SubmittedPendingApproval = 'submitted_pending_approval',
    Approved = 'approved',
    Reinitiated = 'reinitiated',
    Rejected = 'rejected'
}

