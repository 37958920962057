// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    MoorwandCancelRequest,
    MoorwandCreateIban,
    MoorwandCreateIbanFailed,
    MoorwandIbanInboundSettled,
    MoorwandIbanSettled,
    MoorwandVariousWebhook,
} from './';

/**
 * @export
 * @interface MoorwandWebhook
 */
export interface MoorwandWebhook {
    /**
     * @type {number}
     * @memberof MoorwandWebhook
     */
    iban_request_id: number;
    /**
     * - 1 - confirmed - 2 - pending - 3 - declined by admin - 4 - failed - 5 - done - 6 - Request sent and waiting for result
     * @type {number}
     * @memberof MoorwandWebhook
     */
    iban_request_status: MoorwandWebhookIbanRequestStatusEnum;
    /**
     * @type {number}
     * @memberof MoorwandWebhook
     */
    account_id: number;
    /**
     * @type {string}
     * @memberof MoorwandWebhook
     */
    currency_code?: string;
    /**
     * @type {number}
     * @memberof MoorwandWebhook
     */
    iban_id?: number;
    /**
     * Bank account address - IBAN number
     * @type {object}
     * @memberof MoorwandWebhook
     */
    iban: object;
    /**
     * Bank account address - account name
     * @type {string}
     * @memberof MoorwandWebhook
     */
    account_name?: string;
    /**
     * Bank account address - account number
     * @type {string}
     * @memberof MoorwandWebhook
     */
    account_number: string;
    /**
     * Bank account address - sort code. needs to be nullable
     * @type {object}
     * @memberof MoorwandWebhook
     */
    sort_code?: object;
    /**
     * Bank account address - BIC code.
     * @type {string}
     * @memberof MoorwandWebhook
     */
    bic?: string;
    /**
     * Bank account address inbound status
     * @type {number}
     * @memberof MoorwandWebhook
     */
    inbound_enabled?: number;
    /**
     * Bank account address outbound status
     * @type {number}
     * @memberof MoorwandWebhook
     */
    outbound_enabled?: number;
    /**
     * See IBAN bank providers
     * @type {number}
     * @memberof MoorwandWebhook
     */
    iban_bank_provider_id?: number;
    /**
     * needs to be nullable
     * @type {object}
     * @memberof MoorwandWebhook
     */
    request_reference?: object;
    /**
     * @type {string}
     * @memberof MoorwandWebhook
     */
    key: string;
    /**
     * @type {number}
     * @memberof MoorwandWebhook
     */
    ts: number;
    /**
     * @type {string}
     * @memberof MoorwandWebhook
     */
    sign: string;
    /**
     * @type {number}
     * @memberof MoorwandWebhook
     */
    transaction_id: number;
    /**
     * @type {number}
     * @memberof MoorwandWebhook
     */
    sender_account_id?: number;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    sender_iban?: object;
    /**
     * @type {string}
     * @memberof MoorwandWebhook
     */
    sender_account_name?: string;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    sender_account_number?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    receiver_iban?: object;
    /**
     * @type {string}
     * @memberof MoorwandWebhook
     */
    receiver_account_name?: string;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    receiver_account_number?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    receiver_country_iso?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    receiver_sort_code?: object;
    /**
     * @type {string}
     * @memberof MoorwandWebhook
     */
    amount?: string;
    /**
     * @type {string}
     * @memberof MoorwandWebhook
     */
    currency?: string;
    /**
     * @type {number}
     * @memberof MoorwandWebhook
     */
    transaction_status: number;
    /**
     * @type {string}
     * @memberof MoorwandWebhook
     */
    notes?: string;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    additional_note?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    reason?: object;
    /**
     * @type {string}
     * @memberof MoorwandWebhook
     */
    reason_code?: string;
    /**
     * @type {string}
     * @memberof MoorwandWebhook
     */
    reason_description?: string;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    transaction_identifier?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    end_to_end_identifier?: object;
    /**
     * @type {string}
     * @memberof MoorwandWebhook
     */
    created_at?: string;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    transaction_scheme?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    transaction_type?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    returned_transaction_type?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    return_request_reference?: object;
    /**
     * @type {number}
     * @memberof MoorwandWebhook
     */
    returned_transaction_id?: number;
    /**
     * needs to be nullable
     * @type {object}
     * @memberof MoorwandWebhook
     */
    sender_sort_code?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    sender_country_iso?: object;
    /**
     * @type {number}
     * @memberof MoorwandWebhook
     */
    receiver_account_id?: number;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    sender_originator_id?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    sender_originator_name?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    sender_originator_service_user_number?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    receiver_address?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    sender_address?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    transaction_details?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    purpose_code?: object;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    purpose_description?: object;
    /**
     * needs to be nullable
     * @type {object}
     * @memberof MoorwandWebhook
     */
    reference?: object;
    /**
     * @type {number}
     * @memberof MoorwandWebhook
     */
    id?: number;
    /**
     * @type {number}
     * @memberof MoorwandWebhook
     */
    status?: number;
    /**
     * @type {string}
     * @memberof MoorwandWebhook
     */
    will_finish_at?: string;
    /**
     * @type {object}
     * @memberof MoorwandWebhook
     */
    type?: object;
}

/**
 * @export
 * @enum {string}
 */
export enum MoorwandWebhookIbanRequestStatusEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6
}

