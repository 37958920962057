// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CardState
 */
export interface CardState {
    /**
     * @type {string}
     * @memberof CardState
     */
    state: CardStateStateEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CardStateStateEnum {
    ValidCard = 'Valid card',
    CallIssuer = 'Call issuer',
    WarmCard = 'Warm card',
    DoNotHonor = 'Do not honor',
    HonorWithId = 'Honor with id',
    NotPermitted = 'Not permitted',
    LostCardCapture = 'Lost card, capture',
    StolenCardCapture = 'Stolen card, capture',
    CallSecurityCapture = 'Call security, capture',
    InvalidCardCapture = 'Invalid card, capture',
    PickUpCardSpecialCondition = 'Pick up card, special condition',
    CallAcquirerSecurity = 'Call acquirer security',
    CardIsNotActivated = 'Card is not activated',
    PINAttemptsExceeded = 'PIN attempts exceeded',
    ForcedPINChange = 'Forced PIN change',
    CreditDebts = 'Credit debts',
    PINActivation = 'PIN activation',
    InstantCardPersonificationWaiting = 'Instant card personification waiting',
    FraudPrevention = 'Fraud prevention',
    TemporaryBlockedByClient = 'Temporary blocked by client',
    PermanentBlockedByClient = 'Permanent blocked by client'
}

