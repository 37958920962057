// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    NewProductCategory,
    ProductAppointmentType,
    ProductCategoryWTypes,
    ProductDeliveryType,
    ProductTypeOfTest,
} from '../models';

export interface ProductConfigTypes {
    product_appointment_types: Array<ProductAppointmentType>;
    product_delivery_types: Array<ProductDeliveryType>;
}

export interface ProductMaintenanceProductCategoriesDeleteRequest {
    product_category_id: number;
}

export interface ProductMaintenanceMerchantProductCategoriesDeleteRequest {
    product_category_id: number;
    merchant_account_number: string;
}

export interface ProductMaintenanceProductCategoriesPostRequest {
    new_product_category: NewProductCategory;
    product_category_image?: Blob;
}

export interface ProductMaintenanceMerchantProductCategoriesPostRequest {
    new_product_category: NewProductCategory;
    product_category_image?: Blob;
    merchant_account_number: string;
}

export interface ProductMaintenanceProductCategoryImageRequest {
    product_category_image_id: number;
}

export interface ProductAppointmentProduct {
    product_id:	number;
    product_appointment_type_id: number;
};

export interface ProductDeliveryProduct {
    product_id:	number;
    product_delivery_type_id: number;
};

export interface ProductMaintenanceProductAppointmentPutRequest {
    ProductAppointmentProduct: ProductAppointmentProduct;
}

export interface ProductMaintenanceProductAppointmentDeleteRequest {
    product_id:	number;
    product_appointment_type_id: number;
}


export interface ProductMaintenanceProductDeliveryPutRequest {
    ProductDeliveryProduct: ProductDeliveryProduct;
}

export interface ProductMaintenanceProductDeliveryDeleteRequest {
    product_id:	number;
    product_delivery_type_id: number;
}


/**
 * no description
 */
export class ProductMaintenanceApi extends BaseAPI implements IOperationIdMap {

    /**
     */
    productMaintenanceProductCategories = (): Observable<Array<ProductCategoryWTypes>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ProductCategoryWTypes>>({
            path: '/product_maintenance/product_categories',
            method: 'GET',
            headers,
        });
    };

    productMaintenanceMerchantProductCategories = (requestParameters: {merchant_account_number: string, show_default?: string}): Observable<Array<ProductCategoryWTypes>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };
        const query: HttpQuery = {
            ...(requestParameters.show_default && { 'show_default': requestParameters.show_default })
        };
        return this.request<Array<ProductCategoryWTypes>>({
            path: '/product_maintenance/{merchant_account_number}/product_categories'.replace('{merchant_account_number}', encodeURI(requestParameters.merchant_account_number)),
            method: 'GET',
            headers,
            query
        });
    };

    productMaintenanceProductConfigTypes = (): Observable<ProductConfigTypes> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<ProductConfigTypes>({
            path: '/product_maintenance/product_config_types',
            method: 'GET',
            headers,
        });
    };

    productMaintenanceProductDeliveryPut = (requestParameters: ProductMaintenanceProductDeliveryPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ProductDeliveryProduct', 'productMaintenanceProductDeliveryPut');
        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };
        console.log(requestParameters);
        return this.request<void>({
            path: '/product_maintenance/product_delivery',
            method: 'PUT',
            headers,
            body: requestParameters.ProductDeliveryProduct
        });
    };

    productMaintenanceProductDeliveryDelete = (requestParameters: ProductMaintenanceProductDeliveryDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'product_id', 'productMaintenanceProductDeliveryDelete');
        throwIfRequired(requestParameters, 'product_delivery_type_id', 'productMaintenanceProductDeliveryDelete');
        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };
        const query: HttpQuery = {
            ...(requestParameters.product_id && { 'product_id': requestParameters.product_id }),
            ...(requestParameters.product_delivery_type_id && { 'product_delivery_type_id': requestParameters.product_delivery_type_id }),
        };
        
        return this.request<void>({
            path: '/product_maintenance/product_delivery',
            method: 'DELETE',
            headers,
            query
        });
    };
    
    productMaintenanceProductAppointmentPut = (requestParameters: ProductMaintenanceProductAppointmentPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ProductAppointmentProduct', 'productMaintenanceProductAppointmentPut');
        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };
        
        return this.request<void>({
            path: '/product_maintenance/product_appointment',
            method: 'PUT',
            headers,
            body: requestParameters.ProductAppointmentProduct
        });
    };

    productMaintenanceProductAppointmentDelete = (requestParameters: ProductMaintenanceProductAppointmentDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'product_id', 'productMaintenanceProductAppointmentDelete');
        throwIfRequired(requestParameters, 'product_appointment_type_id', 'productMaintenanceProductAppointmentDelete');
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };
        const query: HttpQuery = {
            ...(requestParameters.product_id && { 'product_id': requestParameters.product_id }),
            ...(requestParameters.product_appointment_type_id && { 'product_appointment_type_id': requestParameters.product_appointment_type_id }),
        };
        
        return this.request<void>({
            path: '/product_maintenance/product_appointment',
            method: 'DELETE',
            headers,
            query
        });
    };

    /**
     */
    productMaintenanceProductCategoriesDelete = (requestParameters: ProductMaintenanceProductCategoriesDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'product_category_id', 'productMaintenanceProductCategoriesDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.product_category_id && { 'product_category_id': requestParameters.product_category_id }),
        };

        return this.request<void>({
            path: '/product_maintenance/product_categories',
            method: 'DELETE',
            headers,
            query,
        });
    };

     /**
     */
      productMaintenanceMerchantProductCategoriesDelete = (requestParameters: ProductMaintenanceMerchantProductCategoriesDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'product_category_id', 'productMaintenanceProductCategoriesDelete');
        throwIfRequired(requestParameters, 'merchant_account_number', 'ProductMaintenanceMerchantProductCategoriesPostRequest');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.product_category_id && { 'product_category_id': requestParameters.product_category_id }),
        };

        return this.request<void>({
            path: '/product_maintenance/{merchant_account_number}/product_categories'.replace('{merchant_account_number}', encodeURI(requestParameters.merchant_account_number)),
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * add or edit a product-category. If product_category_id is set, the categroy will be edited, else added
     */
    productMaintenanceProductCategoriesPost = (requestParameters: ProductMaintenanceProductCategoriesPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'new_product_category', 'productMaintenanceProductCategoriesPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.new_product_category !== undefined) {
            formData.append('new_product_category', requestParameters.new_product_category as any);
        }

        if (requestParameters.product_category_image !== undefined) {
            formData.append('product_category_image', requestParameters.product_category_image as any);
        }

        return this.request<void>({
            path: '/product_maintenance/product_categories',
            method: 'POST',
            headers,
            body: formData,
        });
    };

        /**
     * add or edit a product-category. If product_category_id is set, the categroy will be edited, else added
     */
         productMaintenanceMerchantProductCategoriesPost = (requestParameters: ProductMaintenanceMerchantProductCategoriesPostRequest): Observable<void> => {
            throwIfRequired(requestParameters, 'new_product_category', 'ProductMaintenanceMerchantProductCategoriesPostRequest');
            throwIfRequired(requestParameters, 'merchant_account_number', 'ProductMaintenanceMerchantProductCategoriesPostRequest');
    
            const headers: HttpHeaders = {
                ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
                ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
                ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
            };
    
            const formData = new FormData();
            if (requestParameters.new_product_category !== undefined) {
                formData.append('new_product_category', requestParameters.new_product_category as any);
            }
    
            if (requestParameters.product_category_image !== undefined) {
                formData.append('product_category_image', requestParameters.product_category_image as any);
            }
    
            return this.request<void>({
                path: '/product_maintenance/{merchant_account_number}/product_categories'.replace('{merchant_account_number}', encodeURI(requestParameters.merchant_account_number)),
                method: 'POST',
                headers,
                body: formData,
            });
        };

    /**
     */
    productMaintenanceProductCategoryImage = (requestParameters: ProductMaintenanceProductCategoryImageRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'product_category_image_id', 'productMaintenanceProductCategoryImage');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Blob>({
            path: '/product_maintenance/product_categories_image/{product_category_image_id}'.replace('{product_category_image_id}', encodeURI(requestParameters.product_category_image_id)),
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     */
    productMaintenanceProductTypeOfTestList = (): Observable<Array<ProductTypeOfTest>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ProductTypeOfTest>>({
            path: '/product_maintenance/product_type_of_test_list',
            method: 'GET',
            headers,
        });
    };


    operationToOperationId = {
	    "productMaintenanceProductCategories": "product_maintenance_product_categories" ,
	    "productMaintenanceProductCategoriesDelete": "product_maintenance_product_categories_delete" ,
	    "productMaintenanceProductCategoriesPost": "product_maintenance_product_categories_post" ,
	    "productMaintenanceProductCategoryImage": "product_maintenance_product_category_image" ,
	    "productMaintenanceProductTypeOfTestList": "product_maintenance_product_type_of_test_list"
    }
}
