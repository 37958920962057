import moment from 'moment';
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { translate } from '../../../../../common/language/translate';
import ButtonCancel from '../../../../../components/atomiccompoents/buttons/buttonCancel';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import EditableComponent from '../../../../../components/atomiccompoents/editableComponent/editableComponent';
import { CheckBox, IOption } from '../../../../../components/atomiccompoents/form';
import { Image } from '../../../../../components/atomiccompoents/image';
import KeyCheckBoxComponent from '../../../../../components/atomiccompoents/keyCheckboxComponent';
import KeyDateComponent from '../../../../../components/atomiccompoents/keyDateComponent';
import KeySelectComponent from '../../../../../components/atomiccompoents/keySelectComponent';
import KeyTextComponent from '../../../../../components/atomiccompoents/keyTextComponent';
import { DisplayMenuComponent } from '../../../../../components/compositcomponents/displayMenuComponent';
import { DataTypeEnum, IRequestData } from '../../../../../components/compositcomponents/popup/requestDataOverlay';
import Title from '../../../../../components/compositcomponents/title';
import { Account, api, ApiError, apis, Ident } from '../../../../../logic/api';
import { Iso4217Currency } from '../../../../../logic/api/account';
import { CountryCodeAlpha3MRTD } from '../../../../../logic/api/ident';
import { UserDataStore } from '../../../../../logic/flux';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import { OverlayHandler, Overlays } from '../../../../../logic/handler/overlayhandler/overlayHandler';
import { coalesce, evaluateErrorMessage, getCountryOptions, getCountryOptionsAlph3, valueFromNullableObject } from '../../../../../logic/helper/Common';
import { Log, Logs } from '../../../../../logic/log';
import { RowBox } from '../../../../auth/auth.css';
import { ColumnBox, EditableWrapper, KeyField,  StyledViewButtons, ValueField } from '../../customers/basicStyledComponents/customerDetails.css';



const Main = styled.div`
    display: flex,
    flex-direction: column;
    align-items: center;
` ;

const Label = styled.div`
    margin: auto;
    font-size: 20px;
    text-align: center;
    color: ${props => props.theme.Button.backgroundColor};
`;

interface IProps {
	callback: () => void;
	release_request_id: number;
 }

interface IState {
  data?: Ident.RegulaDocument;
  portrait?: Blob;
  doc_front?: Blob;
  doc_back?: Blob;
  keyForLoadingSpinner: number;
  nationality?: Ident.CountryCodeAlpha3PlusKosovo;
  person?: Ident.Person;
  address?: Ident.Address;
  merchants?: Array<Account.MerchantAddress>;
  voucherReq?: Account.ProductVoucherPostRequest
}

class PassportVerification extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
		};
        this.setEditValue = this.setEditValue.bind(this);
        this.setDate = this.setDate.bind(this);
        this.setVoucher = this.setVoucher.bind(this);
        this.fetchData();
        this.getMerchants(); 
	}

    fetchData() {
		const req: Ident.RegulaforensicsApiProcessGetRequest = {
            release_requests_id: this.props.release_request_id
        }
		api.asyncRequest< Ident.RegulaDocument>(
			req,
			apis.RegForensicsApi,
            'regulaforensicsApiProcessGet')
            .then((response: Ident.RegulaDocument) => {
                this.setState({
                    data: response
                }, () => {
                    if(response.person_id != null) {
                        api.asyncRequest<Ident.Person>(
                            { person_id: response.person_id },
                            apis.MaintenanceApi,
                            'personPersonIdGet')
                            .then((response: Ident.Person) => {
                                this.setState({
                                    person: response
                                })
                            }).catch(( error: ApiError) => {
                                Log.error(Logs.API, error);
                            });

                        api.asyncRequest<Array<Ident.Address>>(
                            { person_id: response.person_id },
                            apis.MaintenanceApi,
                            'personAddressGet').
                            then((addressResponse: Array<Ident.Address>) => {
                                for(const o of addressResponse) {
                                    if(o.address_type === Ident.AddressType.L) {
                                        this.setState({
                                            address: o
                                        })
                                    }
                                }
                            }).catch(( error: ApiError) => {
                                Log.error(Logs.API, error);
                            });
                        }
                    });

            }).catch( (error: ApiError) => {
                MessageHandler.onError(Reporter['videoqueue.take.error'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false) );
                this.props.callback();
            })
	}

    savePassport() {
        if( this.state.data == null                     || 
            this.state.data.person_id == null           ||
            this.state.data.date_of_expiry == null      ||
            this.state.data.regula_document_id == null  || 
            this.state.data.issuing_state_code == null   ) {
            
            return;
        }
        const req: Ident.RegulaforensicsApiProcessPutRequest = {
            person_id: this.state.data.person_id,
            regula_document_id: this.state.data.regula_document_id,
            ApprovedRegulaDocument: {
                //@ts-ignore
                date_of_expiry: moment(this.state.data.date_of_expiry).format('YYYY-MM-DD'),
                document_number: this.state.data.document_number,
                issuing_state_code: this.state.data.issuing_state_code as CountryCodeAlpha3MRTD,
                nationality: this.state.data.nationality,
                place_of_birth: this.state.data.place_of_birth,
                nationality_mrtd: this.state.nationality,
            }
        }
        api.asyncRequest(
			req,
			apis.RegForensicsApi,
			'regulaforensicsApiProcessPut'
		).then( () => {
            MessageHandler.onSuccess(Reporter['videoqueue.approve.put']);
			this.props.callback();
		})
		.catch((error: ApiError) => {
			Log.error(Logs.API, error);
			MessageHandler.onError(Reporter['videoqueue.approve.put'],evaluateErrorMessage(error, true), evaluateErrorMessage(error, false) );
            this.setState({
                keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            })
		});
    }

    setVoucher(data: {[key: string]: IRequestData}) {
        const date = new Date();
        date.setDate(date.getDate() + 3);
        const req: Account.ProductVoucherPostRequest = {
            NewProductVoucher: {
                receiver: this.state.person?.primary_email_address,
                merchant_account_number: data.merchantAccount.value != null && data.merchantAccount.value != "" && data.merchantAccount.value != "All" ? data.merchantAccount.value : undefined,
                amount: data.amount.value.replace(",", "."),
                valid_until: date,
                currency: Iso4217Currency.EUR,
                voucher_type: Account.NewProductVoucherVoucherTypeEnum.OnceOnly
            }
        }
        this.setState({
            voucherReq: req
        }, () => {
            this.approvePassport(Ident.ReleaseAction.B, true);
        })
    }

    getMerchants() {
        api.asyncRequest<Array<Account.MerchantAddress>>(
            {},
            apis.MerchantApi,
            'merchantList',
        ).then((response: Array<Account.MerchantAddress>) => {
            this.setState({
               merchants: response
            })

        }).catch((error: ApiError) => {
           Log.error(Logs.API, "Error on getting Merchants", error)
        });
    }

    showVoucherOverlay() {
        const data: {[key: string]: IRequestData} = {};
        data.amount = {
            translationkeyLA: 'customers.details.externalAccounts.input.amount.label',
            translationkeyPL: 'customers.details.externalAccounts.input.amount.placeHolder',
            value: "",
            type: DataTypeEnum.Input
        };
        data.merchantAccount = {
            translationkeyLA:  'customers.details.externalAccounts.input.testCenter.label',
            translationkeyPL:  'customers.details.externalAccounts.input.testCenter.placeholder',
            value: "",
            type: DataTypeEnum.Select,
            enumVals: this.getTestcenterOptions()
        }
        const buttontext = 'customers.details.Voucher.createVoucher';
        OverlayHandler.showOverlay(Overlays.requestDataOverlay, {data: data, buttonKey: buttontext, callback: this.setVoucher, title: 'service_center.setVoucher'})
    }
    
    


    getTestcenterOptions(): Array<IOption> {
        const enumValues: Array<IOption> = [];
        enumValues.push({
            key: 'defaultOptionKey',
            name: 'All',
            value: undefined,
        });
        if(this.state.merchants == null) {
            return enumValues;
        }
		for (const value in this.state.merchants) {
			enumValues.push({
				key: 'defaultOptionKey' + value,
				name: this.state.merchants[value].name,
				value: this.state.merchants[value].account_number,
			});
		}
		return enumValues;
    }

    approvePassport(action: Ident.ReleaseAction, createVoucher?: boolean) {
		const req: Ident.ReleasesPutRequest = {
			ReleasePut: {
				release_action: action,
				release_request_id: this.props.release_request_id
			}
		}
		api.asyncRequest<any>(
			req,
			apis.ReleaseApiIdent,
			'releasesPut'
		).then(() => {
            MessageHandler.onSuccess(Reporter['videoqueue.approve.put'])
            if(this.state.voucherReq != null && createVoucher === true ) {          
                api.asyncRequest<Account.Voucher>(
                    this.state.voucherReq,
                    apis.DefaultApi,
                    'productVoucherPost',
                ).then((response: Account.Voucher) => {
                    MessageHandler.onSuccess(Reporter['customer.voucher.set']);
                }).catch((error: ApiError) => {
                    Log.error(Logs.API, "Error on getting Merchants", error);
                    MessageHandler.onError(Reporter['customer.voucher.set'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
                });
            }
            this.props.callback();
		})
		.catch((error: ApiError) => {
			Log.error(Logs.API, error);
			MessageHandler.onError(Reporter['backoffice.relaeses.put'],evaluateErrorMessage(error, true), evaluateErrorMessage(error, false) );
            this.setState({
                keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            })
		});
	}

	componentDidMount() {	
       
    }

    setEditValue(value: string, id?: string) {
        if(id != null && this.state.data != null) {
            const data = this.state.data;
            //@ts-ignore
            data[id] = value;
            this.setState({
                data: data
            })
        }
    }

    setDate(key: string, date: Date) {
        const data = this.state.data;
        if(data != null) {
            //@ts-ignore
            data[key] = date;
            this.setState({
                data: data
            })
        }
    }

    isReadyToApprove() : boolean {
        return (
            this.state.data == null                     || 
            this.state.data.person_id == null           ||
            this.state.data.date_of_expiry == null      ||
            this.state.data.issuing_state_code == null  ||
            this.state.nationality ==null               );
    }


	render() {
        console.log(UserDataStore.getRoles());
		return (
			<Main>
              <Title title="Regular Forensics Data" />
              <RowBox style={{marginTop: '32px'}}> 
                <div style={{ width: '32%', minWidth: '480px'}}>
                    <Label>Onboarding</Label>
                </div> 
                <div style={{ width: '32%', minWidth: '480px'}}>
                    <Label>Regular</Label>
                </div> 
                <div style={{ width: '30%', minWidth: '480px'}}>
                    <Label>Passport</Label>
                </div> 
              </RowBox>
              <RowBox> 
                <ColumnBox style={{ width: '32%', maxHeight: '800px', minWidth: '480px'}}>
                    <EditableWrapper style={{width: '250px'}}>
                        <EditableComponent
                            translationkey='customers.name'
                            id="surname"
                            initText={ coalesce('' , this.state.person?.name ) }
                            inputStyle={{
                                textAlign: 'left',
                                marginBottom: '0px',
                                marginTop: '2px',
                            }}
                            wrapperStyle={{maxWidth: '240px'}}
                            changeCallback={() => {
                                
                            }}
                            enterCallback={this.setEditValue}
                            disabled={true}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <EditableComponent
                            translationkey='customers.givenName'
                            id="given_name"
                            initText={ coalesce('' , this.state.person?.given_name ) }
                            inputStyle={{
                                textAlign: 'left',
                                marginBottom: '0px',
                                marginTop: '2px',
                            }}
                            wrapperStyle={{maxWidth: '240px'}}
                            changeCallback={() => {
                                
                            }}
                            enterCallback={this.setEditValue}
                            disabled={true}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <KeyDateComponent 
                            translationkey="customers.birthdate"
                            value={ this.state.person?.birth_date != null ? new Date(this.state.person.birth_date) : undefined}
                            id="date_of_birth"
                            onChange={this.setDate}
                            disabled={true}
                            number={0}
                            wrapperStyle={{maxWidth: '240px'}}
                        />  
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                         <EditableComponent
                            translationkey="customers.newCustomer.input.gender.label"
                            id="sex"
                            initText={ coalesce('' , this.state.person?.sex ) }
                            inputStyle={{
                                textAlign: 'left',
                                marginBottom: '0px',
                                marginTop: '2px',
                            }}
                            wrapperStyle={{maxWidth: '240px'}}
                            changeCallback={() => {
                                
                            }}
                            enterCallback={this.setEditValue}
                            disabled={true}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <EditableComponent
                            translationkey="customers.street"
                            id="street"
                            initText={ coalesce('' , this.state.address?.street ) + " " +  coalesce('' , this.state.address?.building_identifier )}
                            inputStyle={{
                                textAlign: 'left',
                                marginBottom: '0px',
                                marginTop: '2px',
                            }}
                            wrapperStyle={{maxWidth: '240px'}}
                            changeCallback={() => {
                                
                            }}
                            enterCallback={this.setEditValue}
                            disabled={true}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <EditableComponent
                            translationkey="customers.city"
                            id="city"
                            initText={ coalesce('' , this.state.address?.post_code ) + " " +  coalesce('' , this.state.address?.city )}
                            inputStyle={{
                                textAlign: 'left',
                                marginBottom: '0px',
                                marginTop: '2px',
                            }}
                            changeCallback={() => {
                                
                            }}
                            wrapperStyle={{maxWidth: '240px'}}
                            enterCallback={this.setEditValue}
                            disabled={true}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <EditableComponent
                            translationkey="customers.email"
                            id="city"
                            initText={ coalesce('' , this.state.person?.primary_email_address )}
                            inputStyle={{
                                textAlign: 'left',
                                marginBottom: '0px',
                                marginTop: '2px',
                            }}
                            changeCallback={() => {
                                
                            }}
                            wrapperStyle={{maxWidth: '240px'}}
                            enterCallback={this.setEditValue}
                            disabled={true}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <EditableComponent
                            translationkey="customers.phone"
                            id="city"
                            initText={ coalesce('' , this.state.person?.primary_phone_number )}
                            inputStyle={{
                                textAlign: 'left',
                                marginBottom: '0px',
                                marginTop: '2px',
                            }}
                            changeCallback={() => {
                                
                            }}
                            wrapperStyle={{maxWidth: '240px'}}
                            enterCallback={this.setEditValue}
                            disabled={true}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <KeyTextComponent 
                            translationkey={'customers.customerNumber'}
                            value={coalesce(' - ', this.state.person?.person_id.toString())}
                            wrapperStyle={{maxWidth: '240px'}}
                        />
                    </EditableWrapper>
                </ColumnBox>
                <ColumnBox style={{ width: '32%', maxHeight: '800px', minWidth: '480px'}}>
                    <EditableWrapper style={{width: '250px'}}>
                        <EditableComponent
                                translationkey='customers.name'
                                id="surname"
                                initText={ coalesce('' , this.state.data?.name ) }
                                inputStyle={{
                                    textAlign: 'left',
                                    marginBottom: '0px',
                                    marginTop: '2px',
                                }}
                                wrapperStyle={{maxWidth: '240px'}}
                                changeCallback={() => {
                                    
                                }}
                                enterCallback={this.setEditValue}
                                disabled={true}
                            />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <EditableComponent
                            translationkey='customers.givenName'
                            id="given_name"
                            initText={ coalesce('' , this.state.data?.given_name ) }
                            inputStyle={{
                                textAlign: 'left',
                                marginBottom: '0px',
                                marginTop: '2px',
                            }}
                            changeCallback={() => {
                                
                            }}
                            wrapperStyle={{maxWidth: '240px'}}
                            enterCallback={this.setEditValue}
                            disabled={true}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <KeyDateComponent 
                            translationkey="customers.birthdate"
                            value={ this.state.data?.birth_date != null ? new Date(this.state.data.birth_date) : undefined}
                            id="date_of_birth"
                            onChange={this.setDate}
                            disabled={true}
                            wrapperStyle={{maxWidth: '240px'}}
                            number={0}
                        />  
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                         <EditableComponent
                            translationkey="customers.newCustomer.input.gender.label"
                            id="sex"
                            initText={ coalesce('' , this.state.data?.sex ) }
                            inputStyle={{
                                textAlign: 'left',
                                marginBottom: '0px',
                                marginTop: '2px',
                            }}
                            wrapperStyle={{maxWidth: '240px'}}
                            changeCallback={() => {
                                
                            }}
                            enterCallback={this.setEditValue}
                            disabled={true}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <KeySelectComponent 
                            translationkey="customers.correctedNationality"
                            id="nationality_act"
                            wrapperStyle={{maxWidth: '240px'}}
                            selected={ coalesce('' , this.state.nationality) }
                            options={getCountryOptionsAlph3()}
                            disabled={false}
                            onChange={(value: string, key: string) => {
                                this.setState({
                                    nationality: value as Ident.CountryCodeAlpha3PlusKosovo
                                })
                            }}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <EditableComponent
                            translationkey="customers.cityOfBirth"
                            id="place_of_birth"
                            initText={ coalesce('' , this.state.data?.place_of_birth ) }
                            inputStyle={{
                                textAlign: 'left',
                                marginBottom: '0px',
                                marginTop: '2px',
                            }}
                            wrapperStyle={{maxWidth: '240px'}}
                            changeCallback={() => {
                                
                            }}
                            enterCallback={this.setEditValue}
                            disabled={false}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <KeySelectComponent 
                            translationkey="customers.issuing_state_code"
                            id="issuing_state_code"
                            selected={ coalesce('' , this.state.data?.issuing_state_code ) }
                            options={getCountryOptionsAlph3()}
                            disabled={false}
                            onChange={this.setEditValue}
                            wrapperStyle={{maxWidth: '240px'}}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <EditableComponent
                            translationkey="customers.documentNumber"
                            id="document_number"
                            initText={ coalesce('' , this.state.data?.document_number ) }
                            inputStyle={{
                                textAlign: 'left',
                                marginBottom: '0px',
                                marginTop: '2px',
                            }}
                            changeCallback={() => {
                                
                            }}
                            wrapperStyle={{maxWidth: '240px'}}
                            enterCallback={this.setEditValue}
                            disabled={false}
                        />
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <KeyDateComponent 
                            translationkey="customers.date_of_ident"
                            value={this.state.data != null && this.state.data.date_of_identification != null ? new Date(this.state.data.date_of_identification) : undefined}
                            id="date_of_ident"
                            onChange={this.setDate}
                            disabled={true}
                            wrapperStyle={{maxWidth: '240px'}}
                            number={1}
                        />  
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <KeyDateComponent 
                            translationkey="customers.expiryDate"
                            value={this.state.data != null && this.state.data.date_of_expiry != null ? new Date(this.state.data.date_of_expiry) : undefined}
                            id="date_of_expiry"
                            onChange={this.setDate}
                            disabled={false}
                            wrapperStyle={{maxWidth: '240px'}}
                            number={2}
                        />  
                    </EditableWrapper>
                    <EditableWrapper style={{width: '250px'}}>
                        <KeyCheckBoxComponent 
                            translationkey='customers.validPassport'
                            id="validPassport"
                            disabled={true}
                            value={coalesce(false, valueFromNullableObject(['valid_document'], this.state.data) ) }
                            onChange={ () => {}}
                            wrapperStyle={{maxWidth: '240px'}}
                        />
                    </EditableWrapper>
                </ColumnBox>
                <ColumnBox style={{width: '35%', alignItems: 'center', maxHeight: 'calc(100vh-300px', overflowY: 'scroll'}}> 
                    <div style={{marginLeft: '64px'}}> 
                        <EditableWrapper>
                            <EditableComponent
                                translationkey="customers.enteredNationality"
                                id="nationality"
                                initText={ coalesce('' , this.state.data?.nationality ) }
                                inputStyle={{
                                    textAlign: 'left',
                                    marginBottom: '0px',
                                    marginTop: '2px',
                                }}
                                changeCallback={() => {
                                    
                                }}
                                wrapperStyle={{maxWidth: '240px'}}
                                enterCallback={this.setEditValue}
                                disabled={false}
                            />
                     </EditableWrapper>
                    <KeyField style={{marginTop: '16px', marginBottom: '16px'}}>
                        {translate('customers.portrait')}:
                    </KeyField>	
                    <ValueField>
                        <Image alt={ this.state.data?.portrait } />
                    </ValueField>
                    <KeyField style={{marginTop: '16px', marginBottom: '16px'}}>
                        {translate('customers.doc_front')}:
                    </KeyField>	
                    <ValueField>
                        <Image alt={ this.state.data?.document_front } />
                    </ValueField>
                    <KeyField style={{marginTop: '16px', marginBottom: '16px'}}>
                        {translate('customers.doc_back')}:
                    </KeyField>	
                    <ValueField>
                        <Image alt={ this.state.data?.document_back } />
                    </ValueField>
                    <KeyField style={{marginTop: '16px', marginBottom: '16px'}}>
                        {"Selfie"}:
                    </KeyField>	
                    <ValueField>
                        <Image alt={ this.state.data?.selfie } />
                    </ValueField>
                     </div>
                </ColumnBox>
              </RowBox>
              <StyledViewButtons style={{justifyContent: 'center', width: '60%'}}>
                { UserDataStore.getRoles().indexOf('admin') >= 0 ?
                    <ButtonOk 
                        style={{margin: 'auto'}}
                        disableSpinner={true}
                        onClick={(event: SyntheticEvent) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.showVoucherOverlay();
                            }
                        }
                    > {translate('service_center.createVoucher')}</ButtonOk>
                    : null }
                    <ButtonCancel
                        style={{margin: 'auto'}}
                        id="btnEditCustomerEmailCancel"
                        onClick={() => {
                            this.props.callback()
                        }} >                                
                        {translate('button.cancel')}
                    </ButtonCancel>  
                    <ButtonOk 
                        disableSpinner={true}
                        style={{margin: 'auto'}}
                        onClick={(event: SyntheticEvent) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.approvePassport(Ident.ReleaseAction.B)
                            }
                        }
                    > {translate('button.notValid')}</ButtonOk>
                    <ButtonOk 
                        style={{margin: 'auto'}}
                        key={this.state.keyForLoadingSpinner}
                        onClick={(event: SyntheticEvent) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.savePassport()
                            }
                        }
                        disabled={this.isReadyToApprove()}
                    > {translate('button.valid')}</ButtonOk>
                </StyledViewButtons>
			</Main>
		);
	}
}

export default PassportVerification;
