import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import i18next from 'i18next';
import {
	IStatusState,
	Reporter,
	IMessageConfig,
	getFormComponents,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { apis, api, ApiError, Ident } from '../../../../logic/api';
import { Log, Logs } from '../../../../logic/log';
import { OverlayHandler } from '../../../../logic/handler/overlayhandler/overlayHandler';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { translate } from '../../../../common/language/translate';
import { Icons } from '../../../../images';
import TouchableOpacity from '../../../../components/atomiccompoents/buttons/touchableOpacity';
import { evaluateErrorMessage } from '../../../../logic/helper/Common';

interface IProps {
	notification?: () => {};
}

interface IState extends IStatusState {
	selectedLanguage?: string;
}

interface ILang {
	code: string;
	label: string;
}

const StyledDivHeading = styled.span`
	display: flex;
	align-items: center;

	margin-top: 28px;
	margin-bottom: 50px;
	margin-left: 20px;
	margin-right: 20px;

	font-size: 18px;
	line-height: 24px;
	color: #4f5f8e;
	font-style: normal;
	font-weight: normal;

	svg {
		cursor: pointer;
		margin-right: 25px;
	}
`;

const StyledDivLanguageNormal: CSSProperties = {
	cursor: 'pointer',
	height: '30px',

	fontSize: '16px',
	lineHeight: '24px',
	color: '#979797',
	fontStyle: 'normal',
	fontWeight: 'normal',

	marginLeft: '20px',
	marginRight: '20px',
	marginTop: '8px',
	marginBottom: '7px',

	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'row',
};

const StyledDivLanguageSelected: CSSProperties = {
	cursor: 'pointer',
	height: '30px',

	fontSize: '16px',
	lineHeight: '24px',
	color: '#000000',
	fontStyle: 'normal',
	fontWeight: 'normal',

	marginLeft: '20px',
	marginRight: '20px',
	marginTop: '8px',
	marginBottom: '7px',

	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'row',
};

const StyledHr = styled.hr`
	margin-left: 20px;
	margin-right: 20px;
	border: 1px solid #ecebeb;
`;

export class LanguageSelectorComponent extends React.Component<IProps, IState> {
	private availableLanguages: Array<ILang> = [
		{ code: 'de', label: 'german' },
		{ code: 'en', label: 'english' },
		{ code: 'sq', label: 'albanian' },
	];

	constructor(props: IProps) {
		super(props);
		this.state = {};
		this.updateSelected = this.updateSelected.bind(this);
	}

	componentDidMount() {
		const requestParams = {};

		api.asyncRequest(requestParams, apis.SelfServiceApi, 'selfSettingsGet')
			.then((response: any) => {
				if (response != null && response.language != null) {
					this.setState({
						selectedLanguage: response.language,
					});
				}
			})
			.catch((error: ApiError) => {
				Log.error(Logs.API, error);
			});
	}

	updateSelected(code: string) {
		const param: Ident.SelfSettingsPutRequest = {
			Settings: {
				language: code as Ident.Language,
			},
		};

		i18next.changeLanguage(code);
		OverlayHandler.updateApp();

		api.asyncRequest(param, apis.SelfServiceApi, 'selfSettingsPut')
			.then((response) => {
				Log.debug(Logs.API, response);
				MessageHandler.onSuccess(Reporter['selfsettings.language.put.request']);
				this.setState({
					selectedLanguage: code,
				});
			})
			.catch((error: ApiError) => {
				const config: IMessageConfig = MessageHandler.onError(
                    Reporter['selfsettings.language.put.request'],
                    evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
				);
				this.setState({
					showInlineError: config.errorMethods.inline,
					errorMessage:
						config.errorMessage != null ? config.errorMessage : error.statusText,
					key: config.translationKey != null ? config.translationKey + '.error' : '',
				});
			});
	}

	render() {
		return (
			<React.Fragment>
				<StyledDivHeading>
					{Icons.userMenuArrowLeft(this.props.notification)}
					{translate('usermenu.languages')}
				</StyledDivHeading>
				{this.availableLanguages.map((lang: ILang) => {
					return (
						<div>
							{lang.code === this.state.selectedLanguage ? (
								<TouchableOpacity containerStyle={StyledDivLanguageSelected}>
									<span>{translate('usermenu.language.' + lang.label)}</span>
									{Icons.languageTick()}
								</TouchableOpacity>
							) : (
								<TouchableOpacity
									containerStyle={StyledDivLanguageNormal}
									onClick={() => {
										this.updateSelected(lang.code);
									}}>
									<span>{translate('usermenu.language.' + lang.label)}</span>
								</TouchableOpacity>
							)}
							<StyledHr />
						</div>
					);
				})}
				{getFormComponents(
					this.state.showInlineError,
					this.state.showInlineSuccess,
					this.state.errorMessage,
					this.state.successMessage,
					this.state.key
				)}
			</React.Fragment>
		);
	}
}
