import React, { ReactElement, SyntheticEvent } from 'react';
import { Account, api, apis } from '../../../../logic/api';
import styled from 'styled-components';
import { Icons } from '../../../../images';
import { translate } from '../../../../common/language/translate';
import { Logs, Log } from '../../../../logic/log';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { FlexBox } from '../../../auth/auth.css';
import Title from '../../../../components/compositcomponents/title';
import { evaluateErrorMessage } from '../../../../logic/helper/Common';
import { IOption } from '../../../../components/atomiccompoents/form';
import Expandable from '../../../../components/compositcomponents/expandable/expandable';
import EditableComponent from '../../../../components/atomiccompoents/editableComponent/editableComponent';
import KeySelectComponent from '../../../../components/atomiccompoents/keySelectComponent';
import { OverlayHandler, Overlays } from '../../../../logic/handler/overlayhandler/overlayHandler';
import KeyCheckBoxComponent from '../../../../components/atomiccompoents/keyCheckboxComponent';
import { KeyField } from './basicStyledComponents/customerDetails.css';


interface IProps {
	
}

interface IState {
	categories: Array<Account.ProductCategoryWTypes>;
	types: Array<Account.ProductTypeOfTest>;
	parents: {[key: number]: string};
	images: {[key: number]: Blob};
	openedExps: Array<number>;
	keyForLoadingSpinnerSave: number;
	keyForLoadingSpinnerDel: number;
}
const imgUrl =  'https://' + window.location.host + '/core/product_maintenance/product_categories_image/';
export default class ProductMaintenanceComponent extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			categories: [],
			types: [],
			parents: {},
			images:[],
			openedExps: [],
			keyForLoadingSpinnerSave: Math.floor(Math.random() * 10000000),
			keyForLoadingSpinnerDel: Math.floor(Math.random() * 10000000)
		};

		this.getParentOptions = this.getParentOptions.bind(this);
		this.setEditValue = this.setEditValue.bind(this);
		this.setImage = this.setImage.bind(this);
		this.setParent = this.setParent.bind(this);

		this.getCategorys();
		this.getTypes();
	}

	private getCategorys() {

		api.asyncRequest<Array<Account.ProductCategoryWTypes>>(
			{},
			apis.ProductMaintentanceApi,
			'productMaintenanceProductCategories'
		)
			.then((response: Array<Account.ProductCategoryWTypes>) => {
				const parents = {};
				if(response.length > 0) {
					for(const o of response) {
						parents[o.product_category_id] = o.product_category;
					}
					this.setState({
						categories: response,
						parents: parents
					})
				}
			})
			.catch((error) => {
				Log.error(Logs.COMPONENT, error);
			});
	}

	inType(typeOfTestId: number, list: Array<Account.ProductCategoryTypes>): boolean {
		for(const o of list) {
			if(o.product_type_of_test_id === typeOfTestId) {
				return true
			}
		}
		return false;
	}

	private getTypes() {

		api.asyncRequest<Array<Account.ProductTypeOfTest>>(
			{},
			apis.ProductMaintentanceApi,
			'productMaintenanceProductTypeOfTestList'
		)
			.then((response) => {
				this.setState({
					types: response
				})
			})
			.catch((error) => {
				Log.error(Logs.COMPONENT, error);
			});
	}


	private saveCategory(index: number) {
		const types: Array<number> = [];
		for(const o of this.state.categories[index].product_category_type_list) {
			types.push(o.product_type_of_test_id);
		}
		const cat: Account.NewProductCategory = {
			long_description: this.state.categories[index].long_description,
			parent_id: this.state.categories[index].parent_id,
			product_category: this.state.categories[index].product_category,
			product_type_of_test_ids: types,
			product_category_code: this.state.categories[index].product_category_code,
		}
		if(this.state.categories[index].product_category_id != null) {
			cat.product_category_id = this.state.categories[index].product_category_id;
		}
		const newCat = JSON.stringify(cat);
		const req: Account.ProductMaintenanceProductCategoriesPostRequest = {
			//@ts-ignore
			new_product_category: newCat
		}
		
		if(this.state.images[index] != null) {
			req.product_category_image = this.state.images[index];
		}
		api.asyncRequest(
			req,
			apis.ProductMaintentanceApi,
			'productMaintenanceProductCategoriesPost'
		)
			.then(() => {
				MessageHandler.onSuccess(Reporter['productmaintenance.change.category']);
				this.getCategorys();
			})
			.catch((error) => {
				MessageHandler.onError(Reporter['productmaintenance.change.category'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
				Log.error(Logs.COMPONENT, error);
			});
		this.setState({
			keyForLoadingSpinnerSave: Math.floor(Math.random() * 10000000),
		})
	}

	private deleteCategory(index: number) {
		if(this.state.categories[index].product_category_id == null) {
			const cats = this.state.categories;
			cats.splice(index, 1);
			this.setState({
				categories: cats
			})
			return;
		}
		const req: Account.ProductMaintenanceProductCategoriesDeleteRequest = {
			product_category_id: this.state.categories[index].product_category_id
		}
		api.asyncRequest(
			req,
			apis.ProductMaintentanceApi,
			'productMaintenanceProductCategoriesDelete'
		)
			.then(() => {
				MessageHandler.onSuccess(Reporter['productmaintenance.change.category']);
				this.getCategorys();
			})
			.catch((error) => {
				MessageHandler.onError(Reporter['productmaintenance.change.category'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
				Log.error(Logs.COMPONENT, error);
			});
		this.setState({
			keyForLoadingSpinnerDel: Math.floor(Math.random() * 10000000),
		})
	}

	getParentOptions(exceptionID?: number): Array<IOption> {
        const enumValues: Array<IOption> = [];
        enumValues.push({
            key: 'defaultOptionKey',
            name: '-',
            value: undefined,
        });
        const keys = Object.keys(this.state.parents);
		for (const value of keys) {
			if(parseInt(value) !== exceptionID) {
				enumValues.push({
					key: 'defaultOptionKey' + value,
					name: this.state.parents[value],
					value: value,
				});
			}
		}
		return enumValues;
    }

    setParent(value: number | undefined | string, index: number) {
		
		const cats = this.state.categories;
		if(value.toString() === '-' || value == null) {
			cats[index].parent_id = undefined;
		} else {
			cats[index].parent_id = parseInt(value.toString());
		}
		this.setState({
			categories: cats
		});
    }

	setImage(image: Blob, index: number) {
		const imgs = this.state.images;
		imgs[index] = image;
		const cats = this.state.categories;
		cats[index].product_category_image_id = undefined;
		this.setState({
			images: imgs,
			categories: cats
		})
	}

	setEditValue(index: number, field: string, value: string) {
		const cats = this.state.categories;
		cats[index][field] = value;
		this.setState( {
			categories: cats
		})
	}

	renderBoxes(): Array<ReactElement> {
		const out = [];
		const cats = this.state.categories;
		for(const o in this.state.categories) {
			out.push(
				<div style={{margin: '16px'}}>
					<Expandable title={cats[o].product_category} key={cats[o].product_category}
						onExpand={() => {
							const exps = this.state.openedExps
							if(exps.indexOf(parseInt(o)) >= 0) {
								exps.splice(parseInt(o), 1); 
							} else {
								exps.push(parseInt(o));
							}
							this.setState({
								openedExps: exps
							})
						}}
						open={this.state.openedExps.indexOf(parseInt(o) ) >= 0}>
						<div style={{display: 'flex', flexDirection: 'column'}}>
							<CategoryBox>
								<TextEditBox>
									<EditWrapper>
										<EditableComponent
											translationkey="productMaintenance.category"
											id="product_category"
											initText={ cats[o].product_category }
											inputStyle={{
												textAlign: 'left',
												marginBottom: '0px',
												marginTop: '2px',
											}}
											changeCallback={() => {
												
											}}
											wrapperStyle={{maxWidth: '340px'}}
											enterCallback={(value: string, key: string) => {
												this.setEditValue(parseInt(o), key, value);
											}}
											callEnterOnChange={false}
											disabled={false}
										/>
									</EditWrapper>
									<EditWrapper>
										<EditableComponent
											translationkey="productMaintenance.categoryCode"
											id="product_category_code"
											initText={ cats[o].product_category_code }
											inputStyle={{
												textAlign: 'left',
												marginBottom: '0px',
												marginTop: '2px',
											}}
											changeCallback={() => {
												
											}}
											callEnterOnChange={false}
											wrapperStyle={{maxWidth: '340px'}}
											enterCallback={(value: string, key: string) => {
												this.setEditValue(parseInt(o), key, value);
											}}
											disabled={false}
										/>
									</EditWrapper>
									<EditWrapper>
										<EditableComponent
											translationkey="productMaintenance.longDescription"
											id="long_description"
											initText={ cats[o].long_description }
											inputStyle={{
												textAlign: 'left',
												marginBottom: '0px',
												marginTop: '2px',
											}}
											callEnterOnChange={false}
											changeCallback={() => {
												
											}}
											wrapperStyle={{maxWidth: '340px'}}
											enterCallback={(value: string, key: string) => {
												this.setEditValue(parseInt(o), key, value);
											}}
											disabled={false}
										/>
									</EditWrapper>
									<EditWrapper>
										<KeySelectComponent 
											translationkey="productMaintenance.parent"
											id="parent_id"
											wrapperStyle={{maxWidth: '340px'}}
											selected={ cats[o].parent_id ?? undefined }
											options={this.getParentOptions(cats[o].product_category_id)}
											disabled={false}
											onChange={(value: number, key: string) => {
												this.setParent(value, parseInt(o));
											}}
										/>
									</EditWrapper>
									<EditWrapper 
										style={{margin: '8px', padding: '8px', marginLeft: '16px'}}
										onClick={(event: SyntheticEvent) => {
											event.stopPropagation();
											event.preventDefault();
											OverlayHandler.showOverlay(Overlays.fileChooserOverlay, {callback: (file: Blob) => {
												this.setImage(file, parseInt(o));
												}})
										}}>
											<KeyField style={{display: 'flex'}}>
												{translate('productMaintenance.image')}: 
												{this.state.images[o] != null ? <div style={{marginLeft: '16px', marginTop: '8px'}}>{Icons.checkBoxIcon() }</div>: null}
											</KeyField>	
											<ImgWrapper style={{margin: '8px'}}>
												{cats[o].product_category_image_id != null ?
												<img src={imgUrl + cats[o].product_category_image_id} width='340px' />
												: Icons.upload()
												}
											</ImgWrapper>
									</EditWrapper>
								</TextEditBox>
								<CheckBoxBox>
									{this.state.types.map((value: Account.ProductTypeOfTest, index: number) => {
										return (
											<KeyCheckBoxComponent 
												wrapperStyle={{height: '38px', padding: '0'}}
												id={value.type_of_test}
												translationkey={value.type_of_test}
												value={this.inType(value.product_type_of_test_id, cats[o].product_category_type_list ?? [])}	
												onChange={(isIn: boolean) => {
													const cats = this.state.categories;
													if(cats[o].product_category_type_list ==  null) {
														cats[o].product_category_type_list = [];
													}
													if(isIn) {
														cats[o].product_category_type_list.push({
															product_category_id: cats[o].product_category_id,
															product_type_of_test_id: value.product_type_of_test_id,
															type_of_test: value.type_of_test
														})
													} else {
														for(const i in cats[o].product_category_type_list) {
															if(cats[o].product_category_type_list[i].product_type_of_test_id === value.product_type_of_test_id) {
																cats[o].product_category_type_list.splice(parseInt(i), 1);
															}
														}
													}
													this.setState({
														categories: cats
													})
												}}
											/>
										)
									})}
								</CheckBoxBox>
							</CategoryBox>
							<ButtonWrapper>
									<ButtonOk 
										key={this.state.keyForLoadingSpinnerSave}
										onClick={() => {
										this.saveCategory(parseInt(o))
									}}>
										Save
									</ButtonOk>
									<ButtonOk 
										key={this.state.keyForLoadingSpinnerDel}
										onClick={() => {
										this.deleteCategory(parseInt(o))
									}}>
										Delete
									</ButtonOk>
							</ButtonWrapper>
						</div>
					</Expandable>
				</div>
			)
		}
		return out;
	}


	render() {
		return (
			<FlexBox>
				<TitleBox>
					<Title 
						title={translate('productMaintenance.title')}
					/>
					<IconWrapper onClick={() => {
						const newCategory: Account.ProductCategoryWTypes = {
							product_category: 'Category name',
							product_category_type_list: [],
							long_description: "",
							product_category_code: ""
						}
						const cats = this.state.categories;
						cats.push(newCategory);
						const openExp = this.state.openedExps;
						openExp.push(cats.length -1);
						this.setState({
							categories: cats,
							openedExps: openExp
						})
					}}>
						{Icons.addPackage()}
					</IconWrapper>
				</TitleBox>
				{this.renderBoxes()}
			</FlexBox>
		);
	}
}

const TitleBox = styled.div`
	display: flex;
	margin-top: 32px;
	justify-content: space-between;
	width: 90%;
`;
const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: bottom;
	margin-bottom: 15px;
	max-width: 500px;
`;

const CategoryBox = styled.div`
	display: flex;
	flex-direction: row;
	margin: 16px;
	width: 90%;
`;

const TextEditBox = styled.div`
	display: flex;
	flex-direction: column;
	width: 60%;
`;

const CheckBoxBox = styled(TextEditBox)`
	width: 40%;
`;

const EditWrapper = styled.div`
	margin: 8px;
	display: flex;
	flex-direction: column;
`;

const ImgWrapper = styled(EditWrapper)`
	:hover {
		cursor: pointer;
	}
`;

const IconWrapper = styled.div`
    width: 26px;
    height: 24px;
    svg {
        width: 36px;
        height: 24px;
    }
	:hover {
		cursor: pointer;
	}
`;