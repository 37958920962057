// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    PermissionPerson,
    PersonRoleRequest,
    Role,
} from '../models';

export interface PermissionsPersonsRolesDeleteRequest {
    person_id: number;
    role_id: number;
}

export interface PermissionsPersonsRolesGetRequest {
    person_id: number;
}

export interface PermissionsPersonsRolesPutRequest {
    PersonRoleRequest: PersonRoleRequest;
}

export interface PermissionsRolesPersonsGetRequest {
    role_id: number;
}

/**
 * no description
 */
export class PermissionsApi extends BaseAPI implements IOperationIdMap {

    /**
     * returns all known bo persons roles
     */
    permissionsPersonsGet = (): Observable<Array<PermissionPerson>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<PermissionPerson>>({
            path: '/permissions/persons',
            method: 'GET',
            headers,
        });
    };

    /**
     * ends the assignment of a role to a person
     */
    permissionsPersonsRolesDelete = (requestParameters: PermissionsPersonsRolesDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'permissionsPersonsRolesDelete');
        throwIfRequired(requestParameters, 'role_id', 'permissionsPersonsRolesDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.person_id && { 'person_id': requestParameters.person_id }),
            ...(requestParameters.role_id && { 'role_id': requestParameters.role_id }),
        };

        return this.request<void>({
            path: '/permissions/person/roles',
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * returns all roles for a specific person
     */
    permissionsPersonsRolesGet = (requestParameters: PermissionsPersonsRolesGetRequest): Observable<Array<Role>> => {
        throwIfRequired(requestParameters, 'person_id', 'permissionsPersonsRolesGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.person_id && { 'person_id': requestParameters.person_id }),
        };

        return this.request<Array<Role>>({
            path: '/permissions/person/roles',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * CAREFUL! on 2020-01-11, the decission was made to have only one role per person. to enforce that, this interface inactivates all other roles the persion has.
     * assigns a role to a person
     */
    permissionsPersonsRolesPut = (requestParameters: PermissionsPersonsRolesPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'PersonRoleRequest', 'permissionsPersonsRolesPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/permissions/person/roles',
            method: 'PUT',
            headers,
            body: requestParameters.PersonRoleRequest,
        });
    };

    /**
     * returns all known roles
     */
    permissionsRolesGet = (): Observable<Array<Role>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Role>>({
            path: '/permissions/roles',
            method: 'GET',
            headers,
        });
    };

    /**
     * returns all persons with a specific role
     */
    permissionsRolesPersonsGet = (requestParameters: PermissionsRolesPersonsGetRequest): Observable<Array<PermissionPerson>> => {
        throwIfRequired(requestParameters, 'role_id', 'permissionsRolesPersonsGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.role_id && { 'role_id': requestParameters.role_id }),
        };

        return this.request<Array<PermissionPerson>>({
            path: '/permissions/roles/persons',
            method: 'GET',
            headers,
            query,
        });
    };


    operationToOperationId = {
	    "permissionsPersonsGet": "permissions_persons_get" ,
	    "permissionsPersonsRolesDelete": "permissions_persons_roles_delete" ,
	    "permissionsPersonsRolesGet": "permissions_persons_roles_get" ,
	    "permissionsPersonsRolesPut": "permissions_persons_roles_put" ,
	    "permissionsRolesGet": "permissions_roles_get" ,
	    "permissionsRolesPersonsGet": "permissions_roles_persons_get"
    }
}
