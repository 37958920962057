import React from 'react';
import styled from 'styled-components';
import { Input } from '../form/input';
import { InputView, TextView } from './editableComponent.css';

interface IProps {
    translationkey?: string;
    initText?: string;
    changeCallback: (value: string, key?: string) => void;
    enterCallback: (value: string, key?: string) => void;
    id: string;
    disabled?: boolean;
}

interface IState {
    value: string;
    editMode: boolean;
    focused?: boolean;
    error?: string;
}

export default class EditableTableComponent extends React.Component<IProps, IState> {
    inputRef = React.createRef<Input>();
	constructor(props: IProps) {
		super(props);

		this.state = {
            value: props.initText ?? "",
            editMode: false
        };
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }


   
    
    swapEditMode(enter?: boolean) {
        if(this.props.disabled !== true) {
            this.setState({
                editMode: !this.state.editMode,
                value: this.state.value,
            }, () => {
                if(enter === true) {
                    this.props.enterCallback(this.state.value, this.props.id);
                }
            })
        } else {
            this.setState({
                error: 'No editable Field'
            })
        }
    }

    componentDidMount() {
        const node = document.getElementById("editableComp" + this.props.id);
        if( node != null ) {
            node.addEventListener("keyup", (event: any) => {
                if (event.key === "Enter") {
                   this.onEnter();
                }
            });
        }
        if(this.state.editMode === true && this.inputRef.current != null) {
            this.inputRef.current.handleFocus();
        }
        
    }

    componentWillReceiveProps(props: IProps) {
        this.setState({
            value: props.initText
        })
    }


    onChange(message: string) {     
        if(message != null ){
            if(this.props.changeCallback != null) {
                this.props.changeCallback(message, this.props.id);
                this.setState({
                    value: message
                })
            }
           this.props.enterCallback(message, this.props.id);
        }
    }

    onEnter() {
        if(!this.props.disabled) {
            this.swapEditMode(true);
        } else {
            this.setState({
                error: 'No editable Field'
            })
        }
    }

    onBlur(event: React.FocusEvent<HTMLInputElement>) {
        if(event.isPropagationStopped() ) {
            return;
        }
        this.onEnter();     
    }

	render() {
		return (
			<React.Fragment>
                <div onDoubleClick={() => this.swapEditMode()}>
                    {this.state.editMode === true ? 
                            <StyledTableInputView 
                                id={"editableComp" + this.props.id}
                                value={this.state.value}
                                valueFromState={true}
                                type="text"
                                onChange={this.onChange}
                                onSubmit={() => {
                                    this.onEnter()
                                    }
                                }
                                onLeave={(this.onBlur)}
                                ref={this.inputRef}
                                focusOnRender={true}
                                toolTip={true}
                                error={this.state.error}
                                inline={true}
                            /> :
                            <TextView style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', minWidth: '100px'}} 
                                title={this.state.error != null ? this.state.error : this.state.value}
                                hasPermission={this.props.disabled !== true }
                                hasError={this.state.error != null}
                                isEditbale={!this.props.disabled}
                                >
                                    {this.state.value}
                            </TextView>
                }
            </div>
            </React.Fragment>
		);
	}
}

const StyledTableInputView = styled(InputView)`
    margin-top: -2px;
`;