//@ts-nocheck
import React, { ReactElement, SyntheticEvent } from 'react';
import { ADatePicker, IDateProps, IDateState } from './ADatePicker';
import { OverlayHandler, Overlays } from '../../logic/handler/overlayhandler/overlayHandler';
import { format } from '../../logic/helper/format';
import { FlexBox } from '../../content/auth/auth.css';
import styled from 'styled-components';
import TouchableOpacity from '../atomiccompoents/buttons/touchableOpacity';
import { Log, Logs } from '../../logic/log';
import { Config } from '../../config';
import { BigInput } from '../atomiccompoents/form/inputs.css';
import i18next from 'i18next';
import moment from 'moment';
import { EditableComponentIndexStore } from '../../logic/flux/stores/editableComponentStore';
import { Input } from '../atomiccompoents/form';
import { Icons } from '../../images';

interface IProps extends IDateProps {
    title?: string;
    xOffset?: number;
    selectedValue?: Date;
    showYears?: boolean;
    showMonths?: boolean;
    zIndex?: number;
    rangeSelect?: boolean;
    showMenu?: boolean;
    today?: boolean;
	yesterday?: boolean;
	autofillDate?: boolean;
    iconCallback?: () => ReactElement;
    displayDate?: boolean;
    boxStyle?: React.CSSProperties;
    textStyle?: React.CSSProperties;
    resetCallback: () => void;
    minDate?: Date;
    maxDate?: Date;
    number?: number;
    isInput?: boolean;
    label?: string;
    toggleHeightOffset?: number;
    labelStyle?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    smallText?: boolean;
    disabled?: boolean;
    active?: boolean;
	helperText?: string;
    rangePickerOpen?: boolean;
	openCallback?: () => void;
	focusInputOnRender?: boolean;
	activeIndex?: number;
	activeIndexCallback?: (index: number) => void;
	reactToArrow?: boolean;
	inlineTodayButton?: boolean;
	showTimePicker?: boolean;
}

interface IState extends IDateState {
	showDateComponent: boolean;
	value: Date;
	textValue?: string;
	leftAlign: boolean;
	title: string;
	number: number;
	openedByTab?: boolean;
	error?: string;
}

export class CustomDatePicker extends ADatePicker<IProps, IState> {
	private button: TouchableOpacity | null = null;
	private ref = React.createRef<Input>();
	constructor(props: IProps) {
		super(props);
		let txtVal = undefined;
		if (props.selectedValue != null) {
			txtVal = props.showTimePicker ? format.date(props.selectedValue) : format.date(props.selectedValue);
		}
		this.state = {
			showDateComponent: false,
			value: props.selectedValue != null ? props.selectedValue : new Date(),
			textValue: txtVal,
			leftAlign: true,
			title: props.title != null ? props.title : 'Click to Select Date',
			number: props.number != null ? props.number : 0,
			openedByTab: false,
		};
		this.toggleDateComponent = this.toggleDateComponent.bind(this);
		this.receiveActiveIndex = this.receiveActiveIndex.bind(this);
	}
	componentDidMount() {
		EditableComponentIndexStore.addChangeListener(this.receiveActiveIndex);
	}


    componentWillUnmount() {
        EditableComponentIndexStore.setActiveIndex(-1);
        EditableComponentIndexStore.removeChangeListener(this.receiveActiveIndex)
    }

	receiveActiveIndex() {
		const index = EditableComponentIndexStore.getActiveIndex();
		if(index === this.props.activeIndex) {
			if(this.ref.current != null ) {
				this.ref.current.handleFocus();
			}
		}
		else {
			if(this.ref.current != null) {
				this.ref.current.handleBlur();
			}
		}
	}

	componentWillReceiveProps(props: IProps) {
		let txtVal = this.state.textValue;
		if (props.selectedValue != null) {
			txtVal = this.props.showTimePicker ?  format.datetime(props.selectedValue) : format.date(props.selectedValue);
		} else {
			txtVal = undefined;
		}
		if (this.state.showDateComponent !== true) {
			if (props.active === true) {
				this.toggleDateComponent();
				this.setState({
					showDateComponent: true,
					value: props.selectedValue != null ? props.selectedValue : new Date(),
					title: props.title != null ? props.title : 'Click to Select Date',
					textValue: txtVal,
					openedByTab: true,
				});
				return;
			}
		} else if (this.state.openedByTab === true && props.active === false) {
			OverlayHandler.closeSpecific(Overlays.datePicker);
			this.setState({
				showDateComponent: false,
				value: props.selectedValue != null ? props.selectedValue : new Date(),
				textValue: txtVal,
				title: props.title != null ? props.title : 'Click to Select Date',
				openedByTab: false,
			});
			return;
		}
		this.setState({
			showDateComponent: props.active === true ? true : this.state.showDateComponent,
			value: props.selectedValue != null ? props.selectedValue : new Date(),
			textValue: txtVal,
			title: props.title != null ? props.title : 'Click to Select Date',
		});
	}

	toggleDateComponent(event?: SyntheticEvent, labelClick?: boolean) {
		if (this.props.disabled === true) {
			return;
		}
		if (event != null) {
			event.stopPropagation();
			if(this.props.activeIndexCallback != null && this.props.activeIndex != null) {
				this.props.activeIndexCallback(this.props.activeIndex)

			}	
		}
		const element = document.getElementById('toggle' + this.state.number);
		if (element !== null) {
			const rect = element.getBoundingClientRect();
			let x = rect.x;
			let y = rect.y + Math.round(rect.height / 2);
			if (x == null || y == null) {
				x = rect.left;
				y = rect.top + Math.round(rect.height / 2);
			}
			let leftAlign = true;
			if (x + Config.datePicker.datePickerWidth > window.innerWidth) {
				leftAlign = false;
			}
			let topAlign = false;

			let offSet = Config.datePicker.datePickerDefaultMargin + rect.height / 2;
			offSet =
				this.props.toggleHeightOffset != null
					? this.props.toggleHeightOffset + rect.height / 2
					: offSet;
			let noSpace = false;
			if (y + Config.datePicker.datepickerHeight + offSet > window.innerHeight) {
				if (rect.top - Config.datePicker.datepickerTopSpace > 0) {
					y = y - Config.datePicker.datepickerHeight;
					topAlign = true;
				} else {
					noSpace = true;
					y = y - Config.datePicker.datePickerCenterOffset;
					offSet = 0;
					if (
						x + Config.datePicker.toggleWidth + Config.datePicker.datePickerWidth <
						window.innerWidth
					) {
						x = x + Config.datePicker.toggleWidth;
					} else if (x - Config.datePicker.datePickerWidth > 0) {
						x = x - Config.datePicker.datePickerWidth;
					}
				}
			}
			offSet = topAlign ? -1 * offSet : offSet;
            const xOfset = this.props.xOffset != null ? this.props.xOffset : 0;
			y = y + offSet;
			const data = {
				posX:
					!leftAlign && !noSpace
						? Math.round(x + xOfset) - Config.datePicker.moveOffsetX + 'Px'
						: (labelClick === true
								? Math.round(x)
								: Math.round(x + Config.datePicker.moveOffsetY)) + 'px',
				posY: y + 'px',
				startDate: this.state.value,
				yearComponent: this.props.showYears,
				today: this.props.today,
				yesterday: this.props.yesterday,
				notification: this.props.onChange,
				showMenu: this.props.showMenu,
				leftAlign: leftAlign,
				closeCallback: this.toggleDateComponent,
				resetCallback: this.props.resetCallback,
				minDate: this.props.minDate,
				maxDate: this.props.maxDate,
				showTimePicker: this.props.showTimePicker
			};
            OverlayHandler.showOverlay(Overlays.datePicker, data);
		} else {
			Log.error(Logs.OVERLAYS, 'no element found');
		}
	}

	validateInput(leave?: boolean) {
		const lang = i18next.language;
		if (this.state.textValue != null) {
			if(this.state.textValue === "") {
				this.setState({
					textValue: undefined,
				})
				this.props.resetCallback();
				return;
			}
			const val = this.state.textValue;
			let parsedDate = null;
			if (lang === 'de') {
				parsedDate = moment(val, 'DD.MM.YYYY');
			} else if (lang === 'en') {
				parsedDate = moment(val, 'MM/DD/YYYY');
			}
			if (parsedDate != null && parsedDate.isValid()) {
				const newDate = parsedDate.toDate();
				if (this.props.maxDate != null && newDate > this.props.maxDate) {
					this.setState({
						error: 'date is bigger than maxdate',
						textValue: undefined,
						value: new Date(),
					});
					return;
				}
				if (this.props.minDate != null && newDate < this.props.minDate) {
					this.setState({
						error: 'date is smaller than mindate',
						textValue: undefined,
						value: new Date(),
					});
					return;
				}
				newDate.setHours(2);

				//this.setState({ value: newDate });
				this.props.onChange(newDate);
			} else {
				this.setState({
					error: 'no valid date',
					textValue: undefined,
					value: this.props.activeIndex != null ? this.state.value : new Date(),
				});
			}
		}
	}

	calculateNextFittingDay(date: Date, targetDay: number) {
		let i = 0;
		while(date.getDate() != targetDay) {
			if(i > 48) {
				return;
			}
			date.setDate(targetDay);
			++i;
		}
	}


	autofillDate(value: string) {
		if(this.props.autofillDate !== true) {
			this.setState({
				textValue: value,
				error: undefined,
			})
			return;
		}
		const lang = i18next.language;
		const breakSign = lang === 'de' ? '.' : '/';
		const length = value.length;
		const currentDateElements = value.split(breakSign);
		const refDate = new Date();
		const ref = this.ref.current;	
		if(this.ref.current == null) {
			return;
		}
		if(currentDateElements.length === 1) {
			const val = parseInt(currentDateElements[0]);
			if(lang === 'de' || lang === 'sq') {
				if(val === 0 || val > 31) {
					this.setState({
						textValue: value,
						error: undefined,
					});
					return;
				}
				refDate.setDate(val);
				this.calculateNextFittingDay(refDate, val);
			}
			else if(lang === 'en') {
				if(val === 0 || val > 12) {
					this.setState({
						textValue: value,
						error: undefined,
					});
					return;
				}
				refDate.setMonth(val - 1);
			}	
		}
		else if(currentDateElements.length === 2) {
			const val1 = parseInt(currentDateElements[0]);
			const val2 = parseInt(currentDateElements[1]);
			if(lang === 'de' || lang === 'sq') {
				if(val2 === 0 || val2 > 12 ) {
					this.setState({
						textValue: value,
						error: undefined,
					});
					return;
				}
				refDate.setMonth(val2 - 1);
				refDate.setDate(val1);
			}
			else if(lang === 'en') {
				if(val2 === 0  || val2 > 31) {
					this.setState({
						textValue: value,
						error: undefined,
					});
					return;
				}
				refDate.setMonth(val1 - 1);
				refDate.setDate(val2);
				this.calculateNextFittingDay(refDate, val2);
			}	
		} else if(currentDateElements.length === 3) {
			this.setState({
				textValue: value,
				error: undefined,
			})
			return;
		}
		const newVal = format.date(refDate);				
		if(newVal == null) {
			return;	
		}	
		this.setState({
			textValue: newVal,
			error: undefined,
		}, () => {
			if(ref != null) {						
				ref.setSelectionRange(length , newVal != null ? newVal.length : 100);
			}});
	}

    render() {
        if (this.props.isInput !== true) {
            return (
                <Main style={this.props.boxStyle}>
                    <TouchableOpacity
                        id={"toggle" + this.state.number}
                        onClick={event => this.toggleDateComponent(event, false)}
                        containerStyle={this.props.containerStyle != null ? this.props.containerStyle : PickerToggle}
                    >
                        {this.props.displayDate && this.props.selectedValue != null ? (
                            <StyledDateText  hover={true} style={this.props.textStyle}>{this.props.showTimePicker === true ? format.datetime(this.state.value) : format.date(this.state.value)} </StyledDateText>
                        ) : <StyledDateText  hover={true} style={this.props.textStyle}>{this.state.title}</StyledDateText>}
                        {this.props.iconCallback != null ? (
                            <IconWrapper style={{width:'16px', height: '16px'}}>{this.props.iconCallback()}</IconWrapper>
                        ) : null}
                    </TouchableOpacity>
                </Main>
            );
        } else {
            if (this.props.label == null) {
                const inputStyle = this.props.smallText !== true ? {paddingLeft: '45px', width: 'calc( 100% - 50px)'} : {paddingLeft: '45px', fontSize: '14px', color:'rgba(37, 38, 49, 0.5)',  fontWeight: 500,  };
                return (
                    <HelpTextWrapper >
                        <BigInput 
                            id="datePickerInput"
                            valueFromState={true}
                            value={this.state.textValue}
                            label={this.props.helperText}
							onLeave={() => {								
								if(this.ref.current != null) {
									this.ref.current.handleBlur();
								}
								this.validateInput()
							}}
							placeHolder={this.props.title}
							focusOnRender={false}
                            onSubmit={() => this.validateInput()}
							input= {inputStyle}
							ref={this.ref}
                            onChange={(value: string) => this.autofillDate(value) }
							error={this.state.error}
							reactToArrow={this.props.reactToArrow}
                        />
                        <PickerWrapper
                            id={"toggle" + this.state.number}
                            onClick={event => this.toggleDateComponent(event, false)}
                            selected={this.props.selectedValue != null}>
                                {this.props.iconCallback != null ? (
                                    this.props.iconCallback()
                                ) : null}
                        </PickerWrapper>
						{this.props.inlineTodayButton === true ?
						<IconWrapperToday title="Today" onClick={() => {
							this.onChange(new Date());
							}}>{Icons.today()}
						</IconWrapperToday> : null }
                    </HelpTextWrapper>
                )
            }
            else {
                return (
                    <label
                        id={"toggle" + this.state.number}
                        onClick={event => this.toggleDateComponent(event, true)}
                        style={this.props.labelStyle != null ? this.props.labelStyle : LabelToggle}
                    >
                        {this.props.label}
                        <Main>
                            {this.props.displayDate ? (
                                <StyledDateText style={this.props.textStyle}>{this.props.showTimePicker === true ? format.datetime(this.state.value) : format.date(this.state.value)} </StyledDateText>
                            ) : <StyledDateText style={this.props.textStyle}>{this.state.title}</StyledDateText>}
                            {this.props.iconCallback != null ? (
                                this.props.iconCallback()
                            ) : null}
                        </Main>
                    </label>
                )
            }
        }
    }
}

const Main = styled(FlexBox)`
	position: relative;
	box-shadow: ${props => props.theme.Box.boxShadow};
	border-radius: 4px;
	height: 52px;
	box-sizing: border-box;
	border: 1px solid #e8ecef;
	background-color: #ffffff;
	width: 100%;
	flex-direction: row;
	align-items: center;
	margin: 14px 0px;
	margin-top: 13px;
`;
const PickerToggle: React.CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
};
const IconWrapper = styled.div`
    svg {
        height: 16px;
        width: 16px;
    }
`;

const PickerWrapper = styled.div<{selected?: boolean}>`
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 42px;
    z-index: 100;
	margin-left: -100%;
	padding-left: 12px;
`;

const HelpTextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	min-width: 0px;
	position: relative; 
`;

const LabelToggle: React.CSSProperties = {};

const StyledDateText = styled.span<{ hover?: boolean }>`
	margin-left: 22px;
	margin-right: 22px;#
	color: #4a4a4a;
	font-size: 15px;
	box-sizing: border-box;
	:hover {
		font-weight: 500;
		color: '${ props => props.theme.Button.backgroundColor};'
	}
`;
const IconWrapperToday = styled.div`
	:hover {
		cursor: pointer;
	}
	width: 20px;
	z-index: 120;
	position: absolute;
	top: 22px;
	right: 16px;
	display: flex;
	align-items: center;
	svg {
		fill: ${props => props.theme.Global.lightFontColor};
		width: 16px;
		height: 16px;
	}
 `;