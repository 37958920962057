// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CompanionRelationship,
} from './';

/**
 * the companion connection
 * @export
 * @interface CompanionConnection
 */
export interface CompanionConnection {
    /**
     * @type {number}
     * @memberof CompanionConnection
     */
    person_id: number;
    /**
     * @type {string}
     * @memberof CompanionConnection
     */
    other_person_email: string;
    /**
     * @type {CompanionRelationship}
     * @memberof CompanionConnection
     */
    relationship: CompanionRelationship;
    /**
     * Famaily relation:   * \'junior\' - For childe    * \'family_member\' - other relatives 
     * @type {string}
     * @memberof CompanionConnection
     */
    family_relation: CompanionConnectionFamilyRelationEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CompanionConnectionFamilyRelationEnum {
    Junior = 'junior',
    FamilyMember = 'family_member'
}

