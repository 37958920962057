// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    Partner,
    TransferId,
} from '../models';

export interface PartnerGetRequest {
    person_id: number;
}

export interface PersonPartnerTransferRequest {
    person_id: number;
    partner_id: number;
}

export interface PersonPartnerTransferDeleteRequest {
    person_id: number;
    partner_id: number;
    reason?: PersonPartnerTransferDeleteReasonEnum;
    comment?: string;
}

export interface PersonsPersonIdAccountTaskPostRequest {
    person_id: number;
    number_of_days: number;
    account_task_action: PersonsPersonIdAccountTaskPostAccountTaskActionEnum;
    token: string;
}

/**
 * no description
 */
export class PartnerApi extends BaseAPI implements IOperationIdMap {

    /**
     * get a list of all available partners
     * get all partners
     */
    partnerGet = (requestParameters: PartnerGetRequest): Observable<Array<Partner>> => {
        throwIfRequired(requestParameters, 'person_id', 'partnerGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Partner>>({
            path: '/partners/{person_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * transfer user data to a partner.
     * transfer user data
     */
    personPartnerTransfer = (requestParameters: PersonPartnerTransferRequest): Observable<TransferId> => {
        throwIfRequired(requestParameters, 'person_id', 'personPartnerTransfer');
        throwIfRequired(requestParameters, 'partner_id', 'personPartnerTransfer');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<TransferId>({
            path: '/persons/{person_id}/transfer/{partner_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{partner_id}', encodeURI(requestParameters.partner_id)),
            method: 'POST',
            headers,
        });
    };

    /**
     * remove user data from partner
     * undo transfer of user data
     */
    personPartnerTransferDelete = (requestParameters: PersonPartnerTransferDeleteRequest): Observable<TransferId> => {
        throwIfRequired(requestParameters, 'person_id', 'personPartnerTransferDelete');
        throwIfRequired(requestParameters, 'partner_id', 'personPartnerTransferDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.reason && { 'reason': requestParameters.reason }),
            ...(requestParameters.comment && { 'comment': requestParameters.comment }),
        };

        return this.request<TransferId>({
            path: '/persons/{person_id}/transfer/{partner_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{partner_id}', encodeURI(requestParameters.partner_id)),
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * account tasks.
     * transfer an account task.
     */
    personsPersonIdAccountTaskPost = (requestParameters: PersonsPersonIdAccountTaskPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personsPersonIdAccountTaskPost');
        throwIfRequired(requestParameters, 'number_of_days', 'personsPersonIdAccountTaskPost');
        throwIfRequired(requestParameters, 'account_task_action', 'personsPersonIdAccountTaskPost');
        throwIfRequired(requestParameters, 'token', 'personsPersonIdAccountTaskPost');

        const query: HttpQuery = {
            ...(requestParameters.number_of_days && { 'number_of_days': requestParameters.number_of_days }),
            ...(requestParameters.account_task_action && { 'account_task_action': requestParameters.account_task_action }),
            ...(requestParameters.token && { 'token': requestParameters.token }),
        };

        return this.request<void>({
            path: '/persons/{person_id}/account_task'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            query,
        });
    };


    operationToOperationId = {
	    "partnerGet": "partner_get" ,
	    "personPartnerTransfer": "person_partner_transfer" ,
	    "personPartnerTransferDelete": "person_partner_transfer_delete" ,
	    "personsPersonIdAccountTaskPost": "persons_person_id_account_task_post"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum PersonPartnerTransferDeleteReasonEnum {
    ClientRequest = 'ClientRequest',
    AMLTechnicalError = 'AMLTechnicalError',
    Other3rdPartyRequest = 'Other3rdPartyRequest',
    ImmediateClosure = 'ImmediateClosure'
}
/**
 * @export
 * @enum {string}
 */
export enum PersonsPersonIdAccountTaskPostAccountTaskActionEnum {
    PERDEL = 'PERDEL'
}
