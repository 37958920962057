// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Iso4217Currency,
} from './';

/**
 * @export
 * @interface MoneyTransfer
 */
export interface MoneyTransfer {
    /**
     * client generated unique id to prevent duplicate transactions
     * @type {number}
     * @memberof MoneyTransfer
     */
    reference_id: number;
    /**
     * sending account number -> the account the logged in person has control of
     * @type {string}
     * @memberof MoneyTransfer
     */
    account_from: string;
    /**
     * receiving email address
     * @type {string}
     * @memberof MoneyTransfer
     */
    email_address_to: string;
    /**
     * the amount to transfer
     * @type {number}
     * @memberof MoneyTransfer
     */
    amount: number;
    /**
     * @type {Iso4217Currency}
     * @memberof MoneyTransfer
     */
    currency: Iso4217Currency;
    /**
     * a text for this transaction
     * @type {string}
     * @memberof MoneyTransfer
     */
    text?: string;
    /**
     * Purpose of the transaction: * F - Friends and Family  * M - Misc
     * @type {string}
     * @memberof MoneyTransfer
     */
    purpose: MoneyTransferPurposeEnum;
    /**
     * timestamp in local time zone of the client
     * @type {Date}
     * @memberof MoneyTransfer
     */
    local_datetime: Date;
    /**
     * type to be adjusted!!, the location of the client when initiating the transaction
     * @type {string}
     * @memberof MoneyTransfer
     */
    location: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MoneyTransferPurposeEnum {
    F = 'F',
    M = 'M'
}

