// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface OpenIDProviderMetadataResponse
 */
export interface OpenIDProviderMetadataResponse {
    /**
     * REQUIRED. URL using the https scheme with no query or fragment component that the OP asserts as its Issuer Identifier
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    issuer: string;
    /**
     * REQUIRED. URL of the OP\'s OAuth 2.0 Authorization Endpoint
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    authorization_endpoint: string;
    /**
     * URL of the OP\'s OAuth 2.0 Token Endpoint
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    token_endpoint?: string;
    /**
     * RECOMMENDED. URL of the OP\'s UserInfo Endpoint
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    userinfo_endpoint?: string;
    /**
     * REQUIRED. URL of the OP\'s JSON Web Key Set [JWK] document.
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    jwks_uri: string;
    /**
     * RECOMMENDED. URL of the OP\'s Dynamic Client Registration Endpoint
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    registration_endpoint?: string;
    /**
     * RECOMMENDED. JSON array containing a list of the OAuth 2.0 [RFC6749] scope values that this server supports
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    scopes_supported?: Array<OpenIDProviderMetadataResponseScopesSupportedEnum>;
    /**
     * REQUIRED. JSON array containing a list of the OAuth 2.0 response_type values that this OP supports
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    response_types_supported: Array<OpenIDProviderMetadataResponseResponseTypesSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the OAuth 2.0 response_mode values that this OP supports
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    response_modes_supported?: Array<OpenIDProviderMetadataResponseResponseModesSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the OAuth 2.0 Grant Type values that this OP supports
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    grant_types_supported?: Array<OpenIDProviderMetadataResponseGrantTypesSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the Authentication Context Class References that this OP supports
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    acr_values_supported?: Array<OpenIDProviderMetadataResponseAcrValuesSupportedEnum>;
    /**
     * REQUIRED. JSON array containing a list of the Subject Identifier types that this OP supports
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    subject_types_supported: Array<OpenIDProviderMetadataResponseSubjectTypesSupportedEnum>;
    /**
     * REQUIRED. JSON array containing a list of the JWS signing algorithms (alg values) supported by the OP for the ID Token to encode the Claims in a JWT
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    id_token_signing_alg_values_supported: Array<OpenIDProviderMetadataResponseIdTokenSigningAlgValuesSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the JWE encryption algorithms (alg values) supported by the OP for the ID Token to encode the Claims in a JWT
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    id_token_encryption_alg_values_supported?: Array<OpenIDProviderMetadataResponseIdTokenEncryptionAlgValuesSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the JWE encryption algorithms (enc values) supported by the OP for the ID Token to encode the Claims in a JWT
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    id_token_encryption_enc_values_supported?: Array<OpenIDProviderMetadataResponseIdTokenEncryptionEncValuesSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the JWS signing algorithms (alg values) [JWA] supported by the UserInfo Endpoint to encode the Claims in a JWT
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    userinfo_signing_alg_values_supported?: Array<OpenIDProviderMetadataResponseUserinfoSigningAlgValuesSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the JWE encryption algorithms (alg values) [JWA] supported by the UserInfo Endpoint to encode the Claims in a JWT
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    userinfo_encryption_alg_values_supported?: Array<OpenIDProviderMetadataResponseUserinfoEncryptionAlgValuesSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the JWE encryption algorithms (enc values) [JWA] supported by the UserInfo Endpoint to encode the Claims in a JWT
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    userinfo_encryption_enc_values_supported?: Array<OpenIDProviderMetadataResponseUserinfoEncryptionEncValuesSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the JWS signing algorithms (alg values) supported by the OP for Request Objects
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    request_object_signing_alg_values_supported?: Array<OpenIDProviderMetadataResponseRequestObjectSigningAlgValuesSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the JWE encryption algorithms (alg values) supported by the OP for Request Objects
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    request_object_encryption_alg_values_supported?: string;
    /**
     * OPTIONAL. JSON array containing a list of the JWE encryption algorithms (enc values) supported by the OP for Request Objects
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    request_object_encryption_enc_values_supported?: string;
    /**
     * OPTIONAL. JSON array containing a list of Client Authentication methods supported by this Token Endpoint
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    token_endpoint_auth_methods_supported?: Array<OpenIDProviderMetadataResponseTokenEndpointAuthMethodsSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the JWS signing algorithms (alg values) supported by the Token Endpoint for the signature on the JWT
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    token_endpoint_auth_signing_alg_values_supported?: Array<OpenIDProviderMetadataResponseTokenEndpointAuthSigningAlgValuesSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the display parameter values that the OpenID Provider supports
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    display_values_supported?: Array<OpenIDProviderMetadataResponseDisplayValuesSupportedEnum>;
    /**
     * OPTIONAL. JSON array containing a list of the Claim Types that the OpenID Provider supports
     * @type {Array<string>}
     * @memberof OpenIDProviderMetadataResponse
     */
    claim_types_supported?: Array<OpenIDProviderMetadataResponseClaimTypesSupportedEnum>;
    /**
     * RECOMMENDED. JSON array containing a list of the Claim Names of the Claims that the OpenID Provider MAY be able to supply values for
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    claims_supported?: string;
    /**
     * OPTIONAL. URL of a page containing human-readable information that developers might want or need to know when using the OpenID Provider
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    service_documentation?: string;
    /**
     * OPTIONAL. Languages and scripts supported for values in Claims being returned, represented as a JSON array of BCP47 [RFC5646] language tag values
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    claims_locales_supported?: string;
    /**
     * OPTIONAL. Languages and scripts supported for the user interface, represented as a JSON array of BCP47 [RFC5646] language tag values
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    ui_locales_supported?: string;
    /**
     * OPTIONAL. Boolean value specifying whether the OP supports use of the claims parameter, with true indicating support. If omitted, the default value is false
     * @type {boolean}
     * @memberof OpenIDProviderMetadataResponse
     */
    claims_parameter_supported?: boolean;
    /**
     * OPTIONAL. Boolean value specifying whether the OP supports use of the request parameter, with true indicating support. If omitted, the default value is false
     * @type {boolean}
     * @memberof OpenIDProviderMetadataResponse
     */
    request_parameter_supported?: boolean;
    /**
     * OPTIONAL. Boolean value specifying whether the OP supports use of the request_uri parameter, with true indicating support. If omitted, the default value is true
     * @type {boolean}
     * @memberof OpenIDProviderMetadataResponse
     */
    request_uri_parameter_supported?: boolean;
    /**
     * OPTIONAL. Boolean value specifying whether the OP requires any request_uri values used to be pre-registered using the request_uris registration parameter
     * @type {boolean}
     * @memberof OpenIDProviderMetadataResponse
     */
    require_request_uri_registration?: boolean;
    /**
     * OPTIONAL. URL that the OpenID Provider provides to the person registering the Client to read about the OP\'s requirements on how the Relying Party can use the data provided by the OP
     * @type {string}
     * @memberof OpenIDProviderMetadataResponse
     */
    op_policy_uri?: string;
    /**
     * OPTIONAL. URL that the OpenID Provider provides to the person registering the Client to read about OpenID Provider\'s terms of service
     * @type {boolean}
     * @memberof OpenIDProviderMetadataResponse
     */
    op_tos_uri?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseScopesSupportedEnum {
    Openid = 'openid',
    Profile = 'profile',
    Email = 'email',
    Address = 'address',
    Phone = 'phone',
    OfflineAccess = 'offline_access'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseResponseTypesSupportedEnum {
    Code = 'code',
    Token = 'token',
    IdToken = 'id_token',
    IdTokenToken = 'id_token_token',
    CodeIdToken = 'code_id_token',
    CodeIdTokenToken = 'code_id_token_token'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseResponseModesSupportedEnum {
    Query = 'query',
    Fragment = 'fragment',
    FormPost = 'form_post'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseGrantTypesSupportedEnum {
    Implicit = 'implicit',
    AuthorizationCode = 'authorization_code',
    RefreshToken = 'refresh_token',
    Password = 'password',
    ClientCredentials = 'client_credentials',
    UrnietfparamsoauthgrantTypejwtBearer = 'urn:ietf:params:oauth:grant-type:jwt-bearer'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseAcrValuesSupportedEnum {
    _0 = '0'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseSubjectTypesSupportedEnum {
    Public = 'public',
    Pairwise = 'pairwise'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseIdTokenSigningAlgValuesSupportedEnum {
    RS256 = 'RS256',
    RS384 = 'RS384',
    RS512 = 'RS512',
    PS256 = 'PS256',
    PS384 = 'PS384',
    PS512 = 'PS512',
    ES256 = 'ES256',
    ES384 = 'ES384',
    ES512 = 'ES512',
    HS256 = 'HS256',
    HS384 = 'HS384',
    HS512 = 'HS512',
    None = 'none'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseIdTokenEncryptionAlgValuesSupportedEnum {
    RSA15 = 'RSA1_5',
    RSAOAEP = 'RSA-OAEP',
    RSAOAEP256 = 'RSA-OAEP-256',
    ECDHES = 'ECDH-ES',
    ECDHESA128KW = 'ECDH-ES+A128KW',
    ECDHESA192KW = 'ECDH-ES+A192KW',
    ECDHESA256KW = 'ECDH-ES+A256KW',
    Dir = 'dir',
    A128KW = 'A128KW',
    A192KW = 'A192KW',
    A256KW = 'A256KW',
    A128GCMKW = 'A128GCMKW',
    A192GCMKW = 'A192GCMKW',
    A256GCMKW = 'A256GCMKW'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseIdTokenEncryptionEncValuesSupportedEnum {
    A128CBCHS256 = 'A128CBC-HS256',
    A192CBCHS384 = 'A192CBC-HS384',
    A256CBCHS512 = 'A256CBC-HS512',
    A128GCM = 'A128GCM',
    A192GCM = 'A192GCM',
    A256GCM = 'A256GCM'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseUserinfoSigningAlgValuesSupportedEnum {
    RS256 = 'RS256',
    RS384 = 'RS384',
    RS512 = 'RS512',
    PS256 = 'PS256',
    PS384 = 'PS384',
    PS512 = 'PS512',
    ES256 = 'ES256',
    ES384 = 'ES384',
    ES512 = 'ES512',
    HS256 = 'HS256',
    HS384 = 'HS384',
    HS512 = 'HS512'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseUserinfoEncryptionAlgValuesSupportedEnum {
    RSA15 = 'RSA1_5',
    RSAOAEP = 'RSA-OAEP',
    RSAOAEP256 = 'RSA-OAEP-256',
    ECDHES = 'ECDH-ES',
    ECDHESA128KW = 'ECDH-ES+A128KW',
    ECDHESA192KW = 'ECDH-ES+A192KW',
    ECDHESA256KW = 'ECDH-ES+A256KW',
    Dir = 'dir',
    A128KW = 'A128KW',
    A192KW = 'A192KW',
    A256KW = 'A256KW',
    A128GCMKW = 'A128GCMKW',
    A192GCMKW = 'A192GCMKW',
    A256GCMKW = 'A256GCMKW'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseUserinfoEncryptionEncValuesSupportedEnum {
    A128CBCHS256 = 'A128CBC-HS256',
    A192CBCHS384 = 'A192CBC-HS384',
    A256CBCHS512 = 'A256CBC-HS512',
    A128GCM = 'A128GCM',
    A192GCM = 'A192GCM',
    A256GC = 'A256GC'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseRequestObjectSigningAlgValuesSupportedEnum {
    HS256 = 'HS256',
    HS384 = 'HS384',
    HS512 = 'HS512',
    RS256 = 'RS256',
    RS384 = 'RS384',
    RS512 = 'RS512',
    PS256 = 'PS256',
    PS384 = 'PS384',
    PS512 = 'PS512',
    ES256 = 'ES256',
    ES384 = 'ES384',
    ES512 = 'ES512',
    None = 'none'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseTokenEndpointAuthMethodsSupportedEnum {
    ClientSecretPost = 'client_secret_post',
    ClientSecretBasic = 'client_secret_basic',
    ClientSecretJwt = 'client_secret_jwt',
    PrivateKeyJwt = 'private_key_jwt'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseTokenEndpointAuthSigningAlgValuesSupportedEnum {
    RS256 = 'RS256',
    ES256 = 'ES256'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseDisplayValuesSupportedEnum {
    Page = 'page',
    Popup = 'popup',
    Touch = 'touch',
    Wap = 'wap'
}
/**
 * @export
 * @enum {string}
 */
export enum OpenIDProviderMetadataResponseClaimTypesSupportedEnum {
    Normal = 'normal',
    Aggregated = 'aggregated',
    Distributed = 'distributed'
}

