// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface BusinessAccountDetailsParams
 */
export interface BusinessAccountDetailsParams {
    /**
     * @type {string}
     * @memberof BusinessAccountDetailsParams
     */
    segment: BusinessAccountDetailsParamsSegmentEnum;
    /**
     * @type {string}
     * @memberof BusinessAccountDetailsParams
     */
    created_date: string;
}

/**
 * @export
 * @enum {string}
 */
export enum BusinessAccountDetailsParamsSegmentEnum {
    Restaurant = 'Restaurant',
    Club = 'Club',
    EcoCare = 'EcoCare',
    Military = 'Military',
    Canteen = 'Canteen',
    Noweda = 'Noweda',
    All = 'All',
    PharmaDirekt = 'PharmaDirekt',
    Kaufland = 'Kaufland',
    Lidl = 'Lidl',
    Airport = 'Airport',
    CityTestcenter = 'City-Testcenter',
    LifeStyle = 'LifeStyle'
}

