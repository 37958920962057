import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { OverlayHandler, Overlays } from '../../logic/handler/overlayhandler/overlayHandler';

interface IProps {
	image?: Blob
    alt?: string
}

interface IState {
	
}

export class Image extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			
		};
	}

	render() {
        const url = window.location.origin + this.props.alt;
		return (<SmallImg
                    onClick={(event: SyntheticEvent) => {
                        event.stopPropagation();
                        event.preventDefault();
                        OverlayHandler.showOverlay(Overlays.imagePreview, {alt: this.props.alt, image: this.props.image})
                    }}>
                 <SmallimgTag 
                    src={this.props.image != null ? window.URL.createObjectURL(this.props.image) : url} 
                    alt={this.props.alt == null ? 'no image' : this.props.alt}/>
            </SmallImg>)
	}
}

const SmallImg = styled.div`
    height: 180px;
    :hover {
        cursor: pointer;
    }
`;
const SmallimgTag = styled.img`
    max-height: 180px;
    object-fit: cover;
    border: 2px solid ${props => props.theme.secondaryColor}
    border-radius: 5%;
`;