import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.span`
	width: 100%;
	line-height: normal;
	text-align: left;
	font-size: 14px;
	color: #d0021b;
	margin: 5px 16px 16px 16px;
`;

interface IProps {
	error?: string;
	active: boolean | undefined;
	className?: string | undefined;
	directSet?: boolean;
	refresh?: () => void;
	translateID?: string;
}

interface IState {
	active?: boolean;
	error?: string;
}

export class FormError extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			error: props.error,
			active: props.active,
		};
	}

	UNSAFE_componentWillReceiveProps(props: IProps) {
		if (this.state.error !== props.error) {
			this.setState({ error: props.error });
		}
		if (this.state.active !== props.active) {
			this.setState({
				active: props.active,
			});
		}
	}

	render() {
		if (this.state.active !== true || this.state.error == null) {
			return null;
		}
		return <StyledDiv>{this.state.error}</StyledDiv>;
	}
}
