import React from 'react';
import styled from 'styled-components';
import { FlexBox } from '../../content/auth/auth.css';

interface IProps {
	default: number;
	onChange?: (year: number) => void;
}

interface IState {
	value: number;
}

export class YearComponent extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			value: props.default != null ? props.default : new Date().getFullYear(),
		};
	}

	setYear(delta: number) {
		this.setState({
			value: this.state.value + delta,
		});
		if (this.props.onChange != null) {
			this.props.onChange(this.state.value + delta);
		}
	}

	componentWillReceiveProps(props: IProps) {
		this.setState({
			value: props.default,
		});
	}

	render() {
		return (
			<YearComp>
				<LeftIndicator
					onClick={(event) => {
						event.stopPropagation();
						this.setYear(-1);
					}}>
					<HoverText>{'<'}</HoverText>
				</LeftIndicator>
				<InactiveYear
					onClick={(event) => {
						event.stopPropagation();
						this.setYear(-1);
					}}>
					{' '}
					{this.state.value - 1}
				</InactiveYear>
				<ActiveYear> {this.state.value}</ActiveYear>
				<InactiveYear
					onClick={(event) => {
						event.stopPropagation();
						this.setYear(+1);
					}}>
					{' '}
					{this.state.value + 1}
				</InactiveYear>
				<RightIndicator
					onClick={(event) => {
						event.stopPropagation();
						this.setYear(+1);
					}}>
					<HoverText>{'>'}</HoverText>
				</RightIndicator>
			</YearComp>
		);
	}
}

const YearComp = styled(FlexBox)`
	box-sizing: border-box;
	display: inline;
	border-bottom: 1px solid #c0c0c0;
	padding-bottom: 10px;
`;

const ActiveYear = styled.span`
	font-size: 12px;
	color: #464e51;
	font-weight: 700;
	margin-right: 10px;
	&:hover {
		cursor: pointer;
	}
`;

const InactiveYear = styled.span`
	font-size: 12px;
	color: #a9a9a9;
	font-weight: 400;
	margin-right: 10px;
	&:hover {
		cursor: pointer;
	}
`;

const RightIndicator = styled(FlexBox)`
	color: #464e51;
	padding: 5px;
	display: inline;
	margin-right: 10px;
	&:hover {
		cursor: pointer;
	}
`;

const LeftIndicator = styled(RightIndicator)`
	margin-left: 10px;
`;
const HoverText = styled.span`
	&:hover {
		color: white;
		cursor: pointer;
	}
`;
