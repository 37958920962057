// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CustomerTransaction
 */
export interface CustomerTransaction {
    /**
     * @type {string}
     * @memberof CustomerTransaction
     */
    customer_account_number?: string;
    /**
     * @type {string}
     * @memberof CustomerTransaction
     */
    counterpart_account_number?: string;
    /**
     * booking date
     * @type {Date}
     * @memberof CustomerTransaction
     */
    booking_date?: Date;
    /**
     * value date
     * @type {Date}
     * @memberof CustomerTransaction
     */
    value_date?: Date;
    /**
     * timestamp the transaction was inititated
     * @type {Date}
     * @memberof CustomerTransaction
     */
    initiation_datetime?: Date;
    /**
     * timestamp the timestamp the transaction was actually booked
     * @type {Date}
     * @memberof CustomerTransaction
     */
    booking_datetime?: Date;
    /**
     * values > * C - Credit * D - Debit
     * @type {string}
     * @memberof CustomerTransaction
     */
    credit_debit?: CustomerTransactionCreditDebitEnum;
    /**
     * the accounts balance; always positive number; note if credit or debit!
     * @type {number}
     * @memberof CustomerTransaction
     */
    amount?: number;
    /**
     * @type {string}
     * @memberof CustomerTransaction
     */
    currency?: string;
    /**
     * reference text
     * @type {string}
     * @memberof CustomerTransaction
     */
    text?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CustomerTransactionCreditDebitEnum {
    C = 'C',
    D = 'D'
}

