
import React, { ReactElement, SyntheticEvent } from 'react';
import styled, { withTheme } from 'styled-components';
import { Ident, api, apis, ApiError, VoidResponse, Account } from '../../../../logic/api';
import {
	IStatusState,
	IMessageConfig,
	Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Log, Logs } from '../../../../logic/log';
import { translate } from '../../../../common/language/translate';
import {
	IInitProps,
	IInitState,
	InitialDataComponent,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import {
	INotifyAbleCustomerData,
	CustomerDataStore,
	PermissionStore,
	Actions,
} from '../../../../logic/flux';
import { InitComponentHandler } from '../../../../logic/handler/initialdatahandler/InitComponentHandler';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import ButtonCancel from '../../../../components/atomiccompoents/buttons/buttonCancel';
import {
	KeyField,
	ColumnBox,
	ValueField,
	NoMarginSelect,
	AddWrapper,
	DocumentsWrapper,
	LargeKeyValueBlock,
	EditableWrapper,
	ButtonWrapper,
	NoWrapBlock,
	IconValueField,
} from './basicStyledComponents/customerDetails.css';
import { FlexBox } from '../../../auth/auth.css';
import EditableComponent from '../../../../components/atomiccompoents/editableComponent/editableComponent';
import {
	OverlayHandler,
	Overlays,
} from '../../../../logic/handler/overlayhandler/overlayHandler';
import { DisplayMenuComponent } from '../../../../components/compositcomponents/displayMenuComponent';
import { deepCopy, compareObject, evaluateErrorMessage, getFullPerson, getFullAddress, getPhone, getCountryOptions, getTitleOptions, downloadFile, createDynamicLink } from '../../../../logic/helper/Common';
import ImageSelectComponent from '../../../../components/compositcomponents/imageSelectCompoent/imageSelectComponent';
import { Icons } from '../../../../images';
import { CountryCodeAlpha2, ReferralLink } from '../../../../logic/api/ident';
import { IImageData, IImageUploads } from './customerDetailsInterfaces';
import { ClientConfig } from '../../../../common/config';
import { ExternalIdentifier } from '../../../../logic/api/ident/models/ExternalIdentifier';
import Expandable from '../../../../components/compositcomponents/expandable/expandable';
import KeySelectComponent from '../../../../components/atomiccompoents/keySelectComponent';
import KeyTextComponent from '../../../../components/atomiccompoents/keyTextComponent';
import { PersonReferralLinkPutRequest } from '../../../../logic/api/account';
import { IOption } from '../../../../components/atomiccompoents/form';
import { ThemeStore } from '../../../../logic/flux/stores/themeStore';
import KeyCheckBoxComponent from '../../../../components/atomiccompoents/keyCheckboxComponent';
import { ThumbsODownIcon } from 'react-line-awesome';


interface IProps extends IInitProps {
	personId?: number;
	theme?: any;
}
interface IFields {
	address: Ident.Address | undefined,
    personData: {sex?: Ident.Sex, externalIdentifier?: Array<ExternalIdentifier>,  name?: string, given_name?: string, user_id?: string} | undefined,
	phone: string | undefined,
	primary_email_address?: string,
}
interface IState extends IStatusState, IInitState {
	given_name?: string;
	name?: string;
	primary_email_address?: string;
	address?: Ident.Address;
	secondaryAdresses: Array<Ident.Address>;
	personData?: {sex?: Ident.Sex,  externalIdentifier?: Array<ExternalIdentifier>, name?: string, given_name?: string, user_id?: string, junior?: boolean};
	keyForLoadingSpinner?: number;
	personId?: number;
	phone?: string;
	dataChanged?: boolean;
    documents: Array<IImageData>;
	idsOpen?: boolean;
	clientProfile?: string;
	state?: Ident.PersonStateEnum;
	kycStatus?: Ident.PersonKYCStatus;
	qrcode?: Blob;
	addressRequirement?: Ident.NewAddressRequirmentRequirementEnum,
}

const WrapperOuter = styled(FlexBox as any)`
	flex-direction: column;
	align-items: center;
`;
const WrapperInner = styled(FlexBox as any)`
	width: 100%;
	min-width: 1000px;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const HoverField = styled(ValueField)<{hasPermission: boolean}>`
	&:hover {
		font-weight: 500;
		color: ${(props) =>
			props.hasPermission === true ? props.theme.Button.backgroundColor : 'white'};
	}
`;

const EditableComponentInputStyle: React.CSSProperties = {
	textAlign: 'left',
	marginBottom: '0px',
	marginTop: '2px',
};

//TODO: Components needs to be reworked. Way to big. Split it into seperate components. Datahandling is also not that great!
class CustomerDetailsPersonBoxClass extends InitialDataComponent<IProps, IState>
	implements INotifyAbleCustomerData {
	private fields: IFields = {
        address: {address_id: -1, datetime_update: new Date(), country: CountryCodeAlpha2.DE, city: '', address_type: Ident.AddressType.L},
		personData: undefined,
		phone:  undefined,
		primary_email_address: undefined
	};
	private phone: string | undefined = undefined;
	private changeTracker: { [key: string]: boolean } = {
		city: false,
		name: false,
		given_name: false,
		street: false,
		post_code: false,
		birth_date: false,
		birth_city: false,
		building_identifer: false,
		suite_identifier: false,
		floor_identifier: false,
		district_name: false,
		country_subentity: false,
		post_box: false,
		sex: false,
        phone: false,
	};

	ref = React.createRef<HTMLDivElement>();
	private activeIndex = -1;

	constructor(props: IProps) {
		super(props);

		this.state = {
			given_name: undefined,
			name: undefined,
			primary_email_address: undefined,
			personData: {},
			address: {address_id: -1, datetime_update: new Date(), country: CountryCodeAlpha2.DE, city: '', address_type: Ident.AddressType.L},
			phone: undefined,
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			secondaryAdresses: [],
			dataChanged: false,
			documents: [],
			idsOpen: false
		};
		
		this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.personChanged = this.personChanged.bind(this);
		this.updatePerson = this.updatePerson.bind(this);
		this.addressChanged = this.addressChanged.bind(this);
		this.updateAddress = this.updateAddress.bind(this);
		this.getPersonDetails = this.getPersonDetails.bind(this);
		this.savePersonDetails = this.savePersonDetails.bind(this);
		this.setEditValue = this.setEditValue.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.indexCallback = this.indexCallback.bind(this);
		this.setSex = this.setSex.bind(this);
		this.openAddressEdit = this.openAddressEdit.bind(this);
		this.loadDocuments = this.loadDocuments.bind(this);
		this.loadAndShowImage = this.loadAndShowImage.bind(this);
		this.handleCountrySelect = this.handleCountrySelect.bind(this);
		this.setAddressRequirement = this.setAddressRequirement.bind(this);
		this.handleRequirementSelect = this.handleRequirementSelect.bind(this);
	}

	_onChangeCustomerData() {
		const person = CustomerDataStore.getUser();
		if (person != null && window.sessionStorage.getItem(this.constructor.name) == null) {
			this.getPersonDetails(person.person_id);
            this.loadDocuments(person.person_id);
			this.getQrCode(person.person_id);
		} else {
			this.fill(this.constructor.name);
		}
	}

	loadDocuments(personId: number) {
		const params={
			person_id: personId
		}
		api.request(
			params,
			apis.MaintenanceApi,
			'personDocumentList',
			(error: ApiError, response: Array<any>) => {
				if (response != null) {
					const documents: Array<IImageData> = [];
					for(const i in response) {
						documents.push({name: response[i].filename, id: response[i].person_document_id})
					}
					this.setState({
						documents: documents
					})
				}
			}
		);
    }

	getQrCode(personId: number) {
		const params={
			person_id: personId
		}
		api.request(
			params,
			apis.DefaultApi,
			'personReferralQrCodeGet',
			(error: ApiError, response: Blob) => {
				if (response != null) {
					this.setState({
						qrcode: response
					})
				}
			}
		);
    }

	async createQrCode() {
		const person_id = this.state.personId;
		if(person_id == null) {
			return;
		}
		const params={ 
			person_id: person_id
		}
		api.asyncRequest(
			params,
			apis.MaintenanceApi,
			'personPersonIdReferralLinkPost').then( async (response: ReferralLink) => {
				const link = await createDynamicLink(response.referral_link.replace('https://invrs.','https://web.'));
				const req: PersonReferralLinkPutRequest = {
					person_id: person_id,
					referral_link: link
				}
				api.asyncRequest(
					req,
					apis.DefaultApi,
					'personReferralLinkPut').then((response: Blob) => {
						this.setState({
							qrcode: response
						})
		
					}).catch((error: ApiError) => {
						Log.error(Logs.API, error);
					})
			}).catch((error: ApiError) => {
				Log.error(Logs.API, error);
			});
	}

	downloadQR() {
		if(this.state.qrcode != null) {
			downloadFile(this.state.qrcode, "qr_code" );
		}
	}

	reviveState(newState: { [key: string]: any }): void {
		this.setState({
			...newState,
			address:
				'address' in newState
					? newState['address']
					: undefined,
			personData:
				'personData' in newState ? newState['personData'] : undefined,
		});
	}

	componentDidMount(): void {
		if (this.ref.current != null) {
			this.ref.current.focus();
		}
		CustomerDataStore.addChangeListener(this._onChangeCustomerData);
		this._onChangeCustomerData();
	}
	
	componentWillUnmount() {
		CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
	}

	loadAndShowImage(imageId: number, docType: string) {
		if(this.state.personId == null) {
			return;
		}
		if (docType === 'pdf') {
			OverlayHandler.showOverlay(Overlays.PDFViewer, { 
				url: "/ident/person/" + this.state.personId + "/document/" + imageId
			});
			return;
		}
		const params: Ident.PersonDocumentGetRequest = {
			person_id: this.state.personId,
			document_id: imageId,
		}
		api.request(
			params,
			apis.MaintenanceApi,
			'personDocumentGet',
			(error: ApiError, response: Blob) => {
				if (response != null) {
					OverlayHandler.showOverlay(Overlays.imagePreview, {
						image: response,
						alt: 'Image',
					});
				} else {
					MessageHandler.onError(Reporter['image.not.found']);
				}
			}
		);
	}

	setAddressRequirement() {
		if(this.state.personId == null) {
			return;
		}
		const params: Ident.PersonPersonIdAddressRequirementRequest = {
			person_id: this.state.personId,
			NewAddressRequirment: {
				address_id: this.state.address.address_id,
				requirement: this.state.addressRequirement
			}
		}
		api.asyncRequest(
			params,
			apis.MaintenanceApi,
			'personPersonIdAddressRequirement').
			then((response: any) => {
				MessageHandler.onSuccess(Reporter['person.address.requirement.post']);
			}).catch((error: ApiError) => {
				MessageHandler.onError(Reporter['person.address.requirement.post'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false))
			});
	}

	/**
	 * Get the requested person, on success the person details are stored in state.fields
	 * @param personId
	 */
	private getPersonDetails(personId?: number): void {
		if (personId == null) {
			return;
		}
		getFullPerson(personId).then((response: Ident.Person) => {
			this.updatePerson(response, true);
		}).catch((error: ApiError) => {
			Log.error(Logs.API, error);
		});

		getFullAddress(personId).then((response: Array<Ident.Address>) => {
			const addresses: Array<Ident.Address> = [];
			for (const key in response) {
				const currentAddress = response[key];
				if (currentAddress.address_type === Account.AddressType.L) {
					this.updateAddress(currentAddress, true);
				} else {
					addresses.push(currentAddress);
				}
			}
			this.setState({
				secondaryAdresses: addresses,
			});
		}).catch((error: ApiError) => {
			Log.error(Logs.API, error);
		});

		getPhone(personId).then((response: Array<Ident.Phone>) => {
			if (response.length > 0) {
				this.setState({
					phone: response[response.length - 1].phone_number, //TODO CHeck for primary phone number
				});
				this.phone = response[response.length - 1].phone_number;
			}
		}).catch((error: ApiError) => {
			Log.error(Logs.API, error);
		});
		this.loadDocuments(personId);
	}

	uploadImages(documents: Array<any>) {
		if(this.state.personId == null) {
			return;
		}
		const params = {
			person_id: this.state.personId,
			
		};
		for(const i in documents) {
			if(i === "0") {
				//@ts-ignore
				params['document'] = documents[i]
			} else {
				
				const idx:number = parseInt(i) + 1;
				//@ts-ignore
				params['document_' + idx.toString()] = documents[i]
			}
		}
		api.request(
			params,
			apis.MaintenanceApi,
			'personDocumentPut',
			(error: ApiError, response: VoidResponse) => {
				if (response != null && this.state.personId != null) {
					MessageHandler.onSuccess(Reporter['document.upload.error']);
					this.loadDocuments(this.state.personId);
				} else {
					MessageHandler.onError(Reporter['document.upload.error']);
				}
			}
		);
	}

	private personChanged(): boolean {
		if(this.state.personData == null || this.fields.personData == null) {
			return false;
		}
		return !compareObject(this.state.personData, this.fields.personData);
	}

	private phoneChanged(): boolean {
		return this.state.phone !== this.phone && !((this.state.phone === '' || this.state.phone == null) && this.phone == null );
	}

	private updatePerson(person: Ident.Person, init: boolean): void {
		const personData =  {
			sex: person.sex,
			externalIdentifier: person.external_identifier,
			name: person.name,
			given_name: person.given_name,	
			user_id: person.user_id	,
			junior: person.family_relation === 'junior'
		}
		this.setState({
			name: person.name,
			personId: person.person_id,
			given_name: person.given_name,
			personData:  personData,
			primary_email_address: person.primary_email_address,
			state: person.state,
			clientProfile: person.client_profile
		});
		if(init) {
            this.fields.personData = deepCopy(personData);
            this.fields.primary_email_address = person.primary_email_address
		}
	}

	private addressChanged(): boolean {
		if(this.state.address == null || this.fields.address == null) {
			return false;
        }
		return !compareObject(this.fields.address, this.state.address);
	}
	private updateAddress(source: Ident.Address, init?: boolean): void {
		this.setState({
			address: source,
		});
		if(init) {
			this.fields.address = deepCopy(source) as Ident.Address;
		}
	}

    resetPassword() {
		OverlayHandler.showOverlay(Overlays.ConfirmationRequestOverlay, {
			confirm: (callback: (success: boolean, close?: boolean) => void) => {
			if(this.state.personId == null || this.state.state === Ident.PersonStateEnum.C) {
				return;
			}
			const req: Ident.PersonPasswordResetRequest = {
				person_id: this.state.personId
			};
			api.asyncRequest<any>(
				req,
				apis.MaintenanceApi,
				'personPasswordReset')
				.then(() => {
					MessageHandler.onSuccess(Reporter['person.password.reset']);
					this.setState({
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					})

				}).catch( (error: ApiError) => {
					this.setState({
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					})
					MessageHandler.onError(Reporter['person.password.reset'],evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
				})
				callback(false, true);
					return;
				},
				heading: translate('customers.details.KYC.password.header'),
				message: translate('customers.details.KYC.password.message'),
		});
    }

	private changeAddress(personId: number, address: any ) : void {
		const params: Ident.PersonAddressPutRequest = {
			person_id: personId,
			address_id: this.state.address?.address_id ?? 0,
			NewAddress: address,
		};
		api.asyncRequest<VoidResponse>(
			params,
			apis.MaintenanceApi,
			'personAddressPut').then((response: VoidResponse) => {
				this.setState({
					keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
				});
				if (response != null) {
					InitComponentHandler.cleanUp(this.constructor.name);
					this.fields.address = deepCopy(this.state.address != null ? this.state.address : {} ) as Ident.Address;
					const config: IMessageConfig = MessageHandler.onSuccess(
						Reporter['customer.details.adress.put.request.error']
					);
				}
				}).catch((error: ApiError) => {
					const config: IMessageConfig = MessageHandler.onError(
						Reporter['customer.details.adress.put.request.error'],
						evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
					);
			});
	}

	private setAddress(personId: number, address: any ) : void {
		// Post new address
		const params: Ident.PersonAddressPostRequest = {
			person_id: personId,
			NewAddress: address,
		};
		api.asyncRequest<Ident.AddressId>(
			params,
			apis.MaintenanceApi,
			'personAddressPost')
			.then((response: Ident.AddressId) => {
				this.setState({
					keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
				});
				if (response != null) {
					InitComponentHandler.cleanUp(this.constructor.name);
					const newAddress = this.state.address;
					if(newAddress != null) {
						newAddress.address_id = response.address_id;
						this.setState({ address: newAddress });
						this.fields.address = newAddress;
					}
				}
			 }).catch((error: ApiError) =>  {
				const config: IMessageConfig = MessageHandler.onError(
					Reporter['customer.details.adress.post.request.error'],
					evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
				);
			});
	}

	private saveEmailAddres(personId: number, emailAdress: string): void {
		const params: Ident.PersonEmailAddressPostRequest = {
			person_id: personId,
			NewEmailAddress: {
				email_address: emailAdress
			},
		};

		api.request(
			params,
			apis.MaintenanceApi,
			'personEmailAddressPost',
			(error: ApiError, response: Ident.EmailAddressId) => {
				this.setState({
					keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
				});
				if (response != null) {
					InitComponentHandler.cleanUp(this.constructor.name);
					this.fields.primary_email_address = this.state.primary_email_address;
				} else {
					const config: IMessageConfig = MessageHandler.onError(
						Reporter['customer.details.email.post.request.error'],
						error.statusText
					);
					this.setState({
						showInlineError: config.errorMethods.inline,
						errorMessage:
							config.errorMessage != null
								? config.errorMessage
								: error.statusText,
						key:
							config.translationKey != null
								? config.translationKey + '.error'
								: '',
					});
				}
			}
		);
	}

	private setPersonData(personId: number): void {
		const paramsPut: Ident.PersonPutRequest = {
			person_id: personId,
			PersonUpdateRequest: {
				sex: this.state.personData?.sex,
				name: this.state.personData?.name,
				given_name:  this.state.personData?.given_name,
				user_id: this.state.personData?.user_id ,
				family_relation: this.state.personData?.junior === true ? Ident.PersonUpdateRequestFamilyRelationEnum.Junior : Ident.PersonUpdateRequestFamilyRelationEnum.FamilyMember
			},
		};
		api.asyncRequest<VoidResponse>(
			paramsPut,
			apis.MaintenanceApi,
			'personPut'
			).then((response: any) => {
				this.setState({
					keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
				});
				InitComponentHandler.cleanUp(this.constructor.name);
				if(this.fields.personData != null && this.state.personData != null) {
					this.fields.personData.sex = this.state.personData.sex;
				}
				if(response.release_request_id != null) {
					MessageHandler.onSuccess(
						Reporter['customers.person.put.approval']
					);
				} else {
					MessageHandler.onSuccess(
						Reporter['customer.details.data.put.request']
					);
				}
			}).catch((error: ApiError ) => {
				const config: IMessageConfig = MessageHandler.onError(
					Reporter['customer.details.email.post.request.error'],
					evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
				);
			});
	}

	private savePhoneNumber(personId: number, phone: string): void {
		const params: Ident.PersonPhonePostRequest = {
			person_id: personId,
			NewPhone: {
				phone_number: phone,
			},
		};
		api.asyncRequest(
			params,
			apis.MaintenanceApi,
			'personPhonePost').then(() => {
				this.setState({
					keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
				});
				this.fields.phone = this.state.phone;
			}).catch((error: ApiError ) => {
				const config: IMessageConfig = MessageHandler.onError(
					Reporter['customer.details.adress.post.request.error'],
					evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
				);
			});
	}

	private savePersonDetails(personId: number): void {
		// Person details changed?
		if (this.state.dataChanged) {
			// TODO: The user may have multiple email addresses
			// Post new email as primary
			if (this.fields.primary_email_address !== this.state.primary_email_address && this.state.primary_email_address != null) {
				this.saveEmailAddres(personId, this.state.primary_email_address);
			}
			if(this.personChanged() && this.state.personData != null) {
				this.setPersonData(personId);
			}
		}
		// Address changed?
		if (this.addressChanged()) {
			const delAddress = deepCopy(this.state.address != null ? this.state.address : {}) as Ident.Address;
			delete delAddress.address_id;
			delete delAddress.datetime_update;
			const address: Ident.NewAddress = {
				...delAddress as Ident.NewAddress
			};

			// Entry already exists? -> Perform update
			if (this.state.address  != null && this.state.address.address_id != null &&  this.state.address.address_id >= 0) {
				this.changeAddress(personId, delAddress)
			} else {
				this.setAddress(personId, delAddress);
			}
				
		}
		if (this.phoneChanged()) {
			if (this.state.phone == null) {
				return;
			}
			this.savePhoneNumber(personId, this.state.phone);
		}
		this.setState({
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			dataChanged: false,
		});
		this.activeIndex = -1;
		Actions.setEditableComponentActiveIndex(this.activeIndex);
	}
	
	private setSex(key: string, value: string) {
		const personData = this.state.personData;
		if(personData == null) {
			return;
		}
		personData.sex = value as Ident.Sex;
		this.setState({
			personData: personData,
			dataChanged: true,
		});
	}
	private onCancel(event: SyntheticEvent): void {
		event.preventDefault();
		InitComponentHandler.cleanUp(this.constructor.name);
		this.setState({
			primary_email_address: this.fields.primary_email_address,
			address: this.fields.address,
			personData: this.fields.personData,
		});
	}

	private onSubmit(event: SyntheticEvent) {
        event.preventDefault();
        event.stopPropagation();

		if (this.state.personId != null) {
			this.savePersonDetails(this.state.personId);
		} else {
			this.activeIndex = -1;
			Actions.setEditableComponentActiveIndex(this.activeIndex);
		}
	}

	setEditValue(value: string, key?: string) {
		if (key != null) {
			const newState = this.state;
			if(key === 'phone') {
				//@ts-ignore 
				newState.phone = value;
				//@ts-ignore 
				newState.dataChanged = true;
			} else if (key === 'name' || key ==='given_name' || key === 'user_id' ) {
				if(newState.personData == null) {
					return;
				}
				//@ts-ignore 
				newState.personData[key] = value;
				//@ts-ignore 
				newState.dataChanged = true;
			}else {
				if(newState.address == null) {
					return;
				}
				//@ts-ignore
				if(newState.address[key as keyof Ident.Address] === value || 
				(newState.address[key as keyof Ident.Address] === ''  && value === '' ) ||
				(newState.address[key as keyof Ident.Address] == null && value === '')) {
					return;
                }
                //@ts-ignore
                newState.address[key as keyof Ident.Address] = value;
                //@ts-ignore
                newState.dataChanged = true;
				//@ts-ignore
                newState.activeIndex = key === 'street' ? 0 : newState.activeIndex;
                //@ts-ignore
			}
			//@ts-ignore
			this.setState(newState);
		}
	}

	indexCallback(index: number) {
		this.activeIndex = index;
	}

	openAddressEdit() {
		if (this.state.personId != null) {
			OverlayHandler.showOverlay(Overlays.addressOverlay, {
				addresses: this.state.secondaryAdresses,
				personId: this.state.personId,
				state: this.state.state
			});
		}
	}

	handleCountrySelect(message: any) {
		const address = this.state.address;
		if(address == null) {
			return;
		}
		address.country = message.country;
		this.setState({
			address: address,
			dataChanged: true
		});
		this.changeTracker.country = true;
	}

	handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
		if (event.key === 'ArrowDown') {
			event.preventDefault();
			const newActiveIndex = (this.activeIndex + 1) % 12;
			Actions.setEditableComponentActiveIndex(newActiveIndex);
			this.activeIndex = newActiveIndex;
		} else if (event.key === 'ArrowUp') {
			event.preventDefault();
			this.activeIndex =	this.activeIndex <= 0 ? 11 : (this.activeIndex - 1) % 12;
			Actions.setEditableComponentActiveIndex(this.activeIndex);
			
		} else if (event.key === 'Escape') {
			event.preventDefault();
			OverlayHandler.closeSpecific(Overlays.datePicker);
		}
	}

	getAddressRequirementOptions() : Array<IOption>  {
		const options: Array<IOption> = [ ];
		options.push({
			key: 'defaultOptionKey none',
			name: '-',
			value: undefined
		});
		for (const i in Ident.NewAddressRequirmentRequirementEnum) {
			options.push({
				key: 'defaultOptionKey ' + i,
				name: Ident.NewAddressRequirmentRequirementEnum[i],
				value: Ident.NewAddressRequirmentRequirementEnum[i]
			});
		}
		return options;
	}

	handleRequirementSelect(message: any) {
		
		this.setState({
			addressRequirement: message.addressRequirement,
		});
	}


	generateExternalIds(): Array<ReactElement> {
		const out = [];
		if(this.state.personData != null && this.state.personData.externalIdentifier != null) {
			for(const o of this.state.personData.externalIdentifier) {
				if(o.system != null && o.identifier != null) {
					out.push(
						<ValueField style={{marginLeft: '16px'}}>
								<DisplayMenuComponent
									elementKey={o.system}
									methods={{
										openCallback: () => {}
									}}
									copyValue={
										o.identifier.toString()
									}>
									<div style={{display: 'flex' }}>
										<NoWrapBlock>
											{translate('customers.' + o.system) + ": " }
										</NoWrapBlock> { o.identifier} 
									</div>
								</DisplayMenuComponent>
							</ValueField>
					)
				}
			}
		}
		return out;
	}

	render() {
		const permissionget = Ident.OperationId.PersonAddressGet;
		if (!PermissionStore.hasPermission(permissionget)) {
			return null;
		}
		return (
			<FlexBox ref={this.ref} onKeyDown={this.handleKeyDown} tabIndex={0}>
				<WrapperOuter>
					<WrapperInner>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								width: '100%',
							}}>
							<ColumnBox
								style={{
									maxHeight: '82px',
									minHeight: '82px',
									marginTop: '32px',
									marginBottom: '16px',
									marginLeft: '8px'
							}}>										
									<KeySelectComponent 
											options={getTitleOptions(this.state.personData?.sex == null)}
											id="sexSelect"
											translationkey='customers.title.title'
											onChange={this.setSex}
											wrapperStyle={{marginLeft: '32px'}}
											selected={this.state.personData?.sex ?? ''}
											disabled={!PermissionStore.hasPermission(
												Ident.OperationId.PersonAddressPut
											)}
										/>
									<KeyTextComponent 
										translationkey='customers.customerNumber'
										value={this.state.personId?.toString() ?? ''}
									/>	
								<LargeKeyValueBlock style={{ borderBottom: 'none' }}>
									<KeyField></KeyField>
									<ValueField></ValueField>
								</LargeKeyValueBlock>
							</ColumnBox>
							<ColumnBox style={{ maxHeight: 'calc(100vh - 150px', minHeight: '900px' }}>
                            <LargeKeyValueBlock style={{height: this.state.idsOpen && this.state.personData?.externalIdentifier != null ? '140px' : '62px', marginLeft: '24px'}}>
									<KeyField style={{height: '45px', display: 'flex', lineHeight: '32px'}}>
										<Expandable 
											title = {translate('customers.customersIdent')}>
											<div style={{display: 'flex', flexDirection: 'column', marginLeft: '16px' }}>
												{ this.generateExternalIds() }											
											</div>
										</Expandable>
									</KeyField>	
								</LargeKeyValueBlock>
								<EditableWrapper>
                                    <EditableComponent
                                        translationkey='customers.name'
                                        id="name"
                                        initText={this.state.personData != null &&
                                            this.state.personData.name != null
                                                ? this.state.personData.name
                                                : ''
                                        }
                                        inputStyle={ EditableComponentInputStyle }
                                        changeCallback={() => {
                                            this.changeTracker.name = true;
                                            this.setState({ dataChanged: true });
                                        }}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
										index={0}
										disabled={!ClientConfig.name_change}
                                        indexCallback={this.indexCallback}
                                    />
                                </EditableWrapper>
								<EditableWrapper>
                                    <EditableComponent
                                        translationkey='customers.givenName'
                                        id="given_name"
                                        initText={this.state.personData != null &&
                                            this.state.personData.given_name != null
                                                ? this.state.personData.given_name
                                                : ''
                                        }
                                        inputStyle={ EditableComponentInputStyle }
                                        changeCallback={() => {
                                            this.changeTracker.name = true;
                                            this.setState({ dataChanged: true });
                                        }}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
										index={1}
										disabled={!ClientConfig.name_change}
                                        indexCallback={this.indexCallback}
                                    />
                                </EditableWrapper>
								<EditableWrapper>
                                    <EditableComponent
                                        translationkey='customers.user_id'
                                        id="user_id"
                                        initText={this.state.personData?.user_id ?? '' }
                                        inputStyle={ EditableComponentInputStyle }
                                        changeCallback={() => {
                                            this.changeTracker.name = true;
                                            this.setState({ dataChanged: true });
                                        }}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
										index={1}
										disabled={ClientConfig.name_change}
                                        indexCallback={this.indexCallback}
                                    />
                                </EditableWrapper>
								<KeyTextComponent 
										translationkey='customers.clientProfile'
										value={this.state.clientProfile}
									/>	
								<LargeKeyValueBlock onDoubleClick = {
									() => {
										if(this.state.personId != null ) {
											OverlayHandler.showOverlay(Overlays.emailOverlay, { personId: this.state.personId , state: this.state.state})
											}
										}
									}>
									<KeyField>{translate('customers.email')}:</KeyField>
									<HoverField hasPermission={PermissionStore.hasPermission(Ident.OperationId.PersonAddressPut)}>
										<DisplayMenuComponent
											elementKey="email"
											copyValue={
												this.state.primary_email_address ?? '' }
											methods={{
												openCallback: () =>
													this.activeIndex = -1,
												emailCallback: () => {
													if(this.state.personId != null ) {
														OverlayHandler.showOverlay(Overlays.emailOverlay, { personId: this.state.personId, state: this.state.state })
													}
												}
											}}>
											{this.state.primary_email_address}
										</DisplayMenuComponent>
									</HoverField>
								</LargeKeyValueBlock>
                                <EditableWrapper>
                                    <EditableComponent
                                        translationkey='customers.street'
                                        id="street"
                                        initText={this.state.address?.street ?? '' }
                                        inputStyle={ EditableComponentInputStyle }
                                        changeCallback={() => {
                                            this.changeTracker.street = true;
                                            this.setState({ dataChanged: true });
                                        }}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
                                        index={2}
                                        editAddressCallback={() => {
                                            this.openAddressEdit();
                                        }}
                                        indexCallback={this.indexCallback}
                                    />
                                </EditableWrapper>
                                <EditableWrapper>
                                <EditableComponent
                                    translationkey='customers.buildingIdentifier'
                                    id="building_identifier"
                                    initText={
                                        this.state.address?.building_identifier ?? '' }
                                    changeCallback={() => {

                                        this.changeTracker.building_identifer = true;
                                        this.setState({ dataChanged: true });
                                    }}
                                    inputStyle={ EditableComponentInputStyle }
                                    enterCallback={this.setEditValue}
                                    permission={Ident.OperationId.PersonAddressPut}
                                    editAddressCallback={() => {
                                        this.openAddressEdit();
                                    }}
                                    index={3}
                                    indexCallback={this.indexCallback}
                                />
                                </EditableWrapper>
                                <EditableWrapper>
                                <EditableComponent
                                    translationkey='customers.district_name'
                                    id="district_name"
                                    initText={this.state.address?.district_name ?? '' }
                                    changeCallback={() => {
                                        this.changeTracker.district_name = true;
                                        this.setState({ dataChanged: true });
                                    }}
                                    inputStyle={ EditableComponentInputStyle }
                                    enterCallback={this.setEditValue}
                                    permission={Ident.OperationId.PersonAddressPut}
                                    editAddressCallback={() => {
                                        this.openAddressEdit();
                                    }}
                                    index={4}
                                    indexCallback={this.indexCallback}
                                />
                                </EditableWrapper>
                                <EditableWrapper>
                                <EditableComponent
                                    translationkey="customers.floor_identifier"
                                    id="floor_identifier"
                                    initText={this.state.address?.floor_identifier ?? '' }
                                    changeCallback={() => {
                                        this.changeTracker.floor_identifier = true;
                                        this.setState({ dataChanged: true });
                                    }}
                                    inputStyle={ EditableComponentInputStyle }
                                    enterCallback={this.setEditValue}
                                    permission={Ident.OperationId.PersonAddressPut}
                                    editAddressCallback={() => {
                                        this.openAddressEdit();
                                    }}
                                    index={5}
                                    indexCallback={this.indexCallback}
                                />
                                </EditableWrapper>
                                <EditableWrapper>
                                <EditableComponent
                                    translationkey="customers.city"
                                    id="city"
                                    initText={this.state.address?.city ?? '' }
                                    changeCallback={() => {
                                        this.changeTracker.city = true;
                                        this.setState({ dataChanged: true });
                                    }}
                                    inputStyle={ EditableComponentInputStyle }
                                    enterCallback={this.setEditValue}
                                    permission={Ident.OperationId.PersonAddressPut}
                                    editAddressCallback={() => {
                                        this.openAddressEdit();
                                    }}
                                    index={6}
                                    indexCallback={this.indexCallback}
                                />
                                </EditableWrapper>
                                <EditableWrapper>
                                <EditableComponent
                                    translationkey="customers.zip"
                                    id="post_code"
                                    initText={this.state.address?.post_code ?? '' }
                                    changeCallback={() => {
                                        this.changeTracker.post_code = true;
                                        this.setState({ dataChanged: true });
                                    }}
                                    inputStyle={ EditableComponentInputStyle }
                                    enterCallback={this.setEditValue}
                                    permission={Ident.OperationId.PersonAddressPut}
                                    index={7}
                                    indexCallback={this.indexCallback}
                                />
                                </EditableWrapper>
                                <EditableWrapper>
                                <EditableComponent
                                    id="post_box"
                                    translationkey="customers.newCustomer.input.post_box.label"
                                    initText={this.state.address?.post_box ?? '' }
                                    changeCallback={() => {
                                        this.changeTracker.post_box = true;
                                        this.setState({ dataChanged: true });
                                    }}
                                    inputStyle={ EditableComponentInputStyle }
                                    enterCallback={this.setEditValue}
                                    permission={Ident.OperationId.PersonAddressPut}
                                    index={8}
                                    indexCallback={this.indexCallback}
                                />
                                </EditableWrapper>
                                <EditableWrapper>
                                <EditableComponent
                                    translationkey="customers.suite_identifier"
                                    id="suite_identifier"
                                    initText={this.state.address?.suite_identifier ?? '' }
                                    changeCallback={() => {
                                        this.changeTracker.suite_identifier = true;
                                        this.setState({ dataChanged: true });
                                    }}
                                    inputStyle={ EditableComponentInputStyle }
                                    enterCallback={this.setEditValue}
                                    permission={Ident.OperationId.PersonAddressPut}
                                    index={9}
                                    indexCallback={this.indexCallback}
										/>
                                </EditableWrapper>
								<LargeKeyValueBlock>
									<KeyField>
										{translate('customers.newCustomer.input.country.label')}:
									</KeyField>
									<ValueField>
										<NoMarginSelect
											id="country"
											options={getCountryOptions(true)}
											current={this.state.address?.country ?? '' }
											notification={this.handleCountrySelect}
											disabled={false}
										/>
									</ValueField>
								</LargeKeyValueBlock>
                                <EditableWrapper>
                                <EditableComponent
                                    id="country_subentity"
                                    translationkey="customers.country_subentity"
                                    initText={this.state.address?.country_subentity ?? '' }
                                    changeCallback={() => {
                                        this.changeTracker.country_subentity = true;
                                        this.setState({ dataChanged: true });
                                    }}
                                    inputStyle={ EditableComponentInputStyle }
                                    enterCallback={this.setEditValue}
                                    permission={Ident.OperationId.PersonAddressPut}
                                    editAddressCallback={() => {
                                        this.openAddressEdit();
                                    }}
                                    index={10}
                                    indexCallback={this.indexCallback}
                                />
                                </EditableWrapper>
                                <EditableWrapper>
                                <EditableComponent
                                    id="phone"
                                    translationkey="customers.phone"
                                    initText={this.state.phone ?? '' }
                                    changeCallback={() => {
                                        this.changeTracker.phone = true;
                                        this.setState({ dataChanged: true });
                                    }}
                                    enterCallback={this.setEditValue}
                                    inputStyle={ EditableComponentInputStyle }
                                    permission={Ident.OperationId.PersonAddressPut}
                                    index={11}
                                    indexCallback={this.indexCallback}
                                />
                                </EditableWrapper>
								{ClientConfig.address_approval ? 
								<React.Fragment>
									<LargeKeyValueBlock style={{height: '124px'}}>
										<KeyField>
											{translate('customers.addressRequirement.requirement')}:
										</KeyField>
										<ValueField>
											<NoMarginSelect
												id="addressRequirement"
												options={this.getAddressRequirementOptions()}
												current={this.state.addressRequirement }
												notification={this.handleRequirementSelect}
												disabled={false}
												
											/>
											<ButtonOk
												key={this.state.keyForLoadingSpinner}
												id="btnReq"
												disableSpinner={true}
												disabled={this.state.state === Ident.PersonStateEnum.C}
												onClick={() => this.setAddressRequirement()}
												style={{height: '32px'}}>
														{translate('customers.addressRequirement.setup')}
											</ButtonOk>   
										</ValueField>
									</LargeKeyValueBlock>
									<KeyCheckBoxComponent 
										translationkey='Junior Account'
										id='junior'
										value={this.state.personData.junior}
										onChange={(value: boolean) => {
											const data = this.state.personData;
											data.junior = value;
											this.setState({
												personData: data,
												dataChanged: true
											})
										}}
									/>
								</React.Fragment>: null }
								{ this.state.qrcode != null ?
									<LargeKeyValueBlock>
											<KeyField style={{width: '50%'}}>
														{translate('customers.downloadQR')}:
											</KeyField>																	
											<IconValueField  onClick={() => this.downloadQR()}> <div style={{paddingTop: '16px'}}>{Icons.downloadIcon()}</div></IconValueField>
									</LargeKeyValueBlock>
								:    <LargeKeyValueBlock style={{flexDirection: 'row', height: '62px'}}>
										<ButtonOk
											key={this.state.keyForLoadingSpinner}
											id="btnResetPassword"
											disabled={this.state.state === Ident.PersonStateEnum.C}
											onClick={() => this.createQrCode()}
											style={{height: '32px'}}>
														Create QR
										</ButtonOk>   
							</LargeKeyValueBlock>  }
								<LargeKeyValueBlock>
									<KeyField>
										{translate('customers.details.merchant.current')}
									</KeyField>
									<ValueField>
										<DocumentsWrapper>
											<ImageSelectComponent
												images={this.state.documents}
												callback={this.loadAndShowImage}
											/>
											<AddWrapper
												onClick={(event) => {
													event.stopPropagation();
													OverlayHandler.showOverlay(
														Overlays.multipleFileChooser,
														{
															callback: (data: IImageUploads) => {
																this.uploadImages(data.files);
															},
															title: translate('documents.selectDocuments') + ' (10 max)',
															buttonText: translate('documents.uploadDocuments'),
															limit: 10
														}
													);
												}}>
												{Icons.addFoto()}
											</AddWrapper>
										</DocumentsWrapper>
									</ValueField>
								</LargeKeyValueBlock>
                                <LargeKeyValueBlock style={{flexDirection: 'row', height: '62px'}}>
									<ButtonOk
										key={this.state.keyForLoadingSpinner}
										id="btnResetPassword"
										disabled={this.state.state === Ident.PersonStateEnum.C}
										onClick={() => this.resetPassword()}
										style={{height: '32px'}}>
													{translate(
													'customers.reset'
													)} 
                                    </ButtonOk>   
								</LargeKeyValueBlock> 
							</ColumnBox>
						</div>
					</WrapperInner>
					<ButtonWrapper>
						<ButtonCancel
							id="btnEditCustomerDetailsCancel"
							onClick={this.onCancel}>
							{translate('button.cancel')}
						</ButtonCancel>
						<ButtonOk
							key={this.state.keyForLoadingSpinner}
							onClick={this.onSubmit}
							disabled={//!(this.addressChanged() || this.personChanged() || this.phoneChanged())}
										this.state.dataChanged === false || this.state.state === Ident.PersonStateEnum.C}
							id="btnEditCustomerDetailsOk">
							{translate('button.save')}
						</ButtonOk>
					</ButtonWrapper>
				</WrapperOuter>
			</FlexBox>
		);
	}
}

export const CustomerDetailsPersonBox = withTheme(CustomerDetailsPersonBoxClass);
