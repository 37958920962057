// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CheckoutAuthNeededResponse
 */
export interface CheckoutAuthNeededResponse {
    /**
     * @type {Array<string>}
     * @memberof CheckoutAuthNeededResponse
     */
    available_auth?: Array<CheckoutAuthNeededResponseAvailableAuthEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum CheckoutAuthNeededResponseAvailableAuthEnum {
    Qr = 'qr',
    Face = 'face',
    Finger = 'finger',
    Pin = 'pin'
}

