// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Sums,
} from './';

/**
 * @export
 * @interface SumsPerDay
 */
export interface SumsPerDay {
    /**
     * @type {string}
     * @memberof SumsPerDay
     */
    partner?: SumsPerDayPartnerEnum;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_1?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_2?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_3?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_4?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_5?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_6?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_7?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_8?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_9?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_10?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_11?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_12?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_13?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_14?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_15?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_16?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_17?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_18?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_19?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_20?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_21?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_22?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_23?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_24?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_25?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_26?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_27?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_28?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_29?: Sums;
    /**
     * @type {Sums}
     * @memberof SumsPerDay
     */
    day_30?: Sums;
}

/**
 * @export
 * @enum {string}
 */
export enum SumsPerDayPartnerEnum {
    All = 'all',
    NDIDENT = 'NDIDENT',
    NDPAY = 'NDPAY',
    ONEFOR = 'ONEFOR'
}

