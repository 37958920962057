import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { translate } from '../../common/language/translate';
import { KeyField, ValueField } from '../../content/dashboard/content/customers/basicStyledComponents/customerDetails.css';
import { DisplayMenuComponent } from '../compositcomponents/displayMenuComponent';
import { CustomDatePicker } from '../datepicker';

interface IProps {
    translationkey: string;
    value?: Date;
    id: string;
    disabled?: boolean;
    onChange: (key: string, date: Date) => void;
    number: number;
    wrapperStyle?: React.CSSProperties
}

interface IState {
}

export default class KeyDateComponent extends PureComponent<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
        };
    }

	render() {
		return (
			<React.Fragment>
                <LargeKeyValueBlock style={this.props.wrapperStyle}>
                   <KeyField >
                            {translate(
                                this.props.translationkey, this.props.translationkey
                            ) + (this.props.disabled === true ? '' :  ' *')}: 
                    </KeyField>	
                    <ValueField > 
                         <DisplayMenuComponent
                                elementKey={this.props.id}
                                copyValue={this.props.value != null ? this.props.value.toString() : '' }
                                methods={{
                                }}
                                >
                            <CustomDatePicker 
                                number={this.props.number}
                                disabled={this.props.disabled === true }
                                displayDate={true}
                                selectedValue={ this.props.value }
                                title={'not set'}
                                boxStyle={{
                                    boxShadow: 'none',
                                    borderRadius: '0px',
                                    height: '24px',
                                    border: 'none',
                                    margin: '0px',
                                    justifyContent: 'flex-start',
                                }}
                                textStyle={{
                                    marginRight: '0px',
                                    color: '#4a4a4a',
                                    textAlign: 'left',
                                    margin: '0px',
                                    fontWeight: 400,
                                    fontSize: '14px'
                                }}

                                containerStyle={{}}
                                onChange={(date:Date) => this.props.onChange(this.props.id, date)}
                                resetCallback= {() => {}}
                            />
                        </DisplayMenuComponent>
                    </ValueField> 
                </LargeKeyValueBlock> 
            </React.Fragment>
		);
	}
}

const LargeKeyValueBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 450px;
    height: 62px;
    border-bottom: 1px solid #DEDEDE;
    padding: ${(props) => props.theme.Table.TBody.Td.padding};
    margin: 8px;
    padding: 8px;
`;