import React from 'react';
import styled from 'styled-components';
import { translate } from '../../common/language/translate';
import { FlexBox } from '../../content/auth/auth.css';
interface IProps {
	default: number;
	onChange?: (month: number) => void;
}

interface IState {
	index: number;
}

const months = [
	'jan',
	'feb',
	'mar',
	'apr',
	'may',
	'jun',
	'jul',
	'aug',
	'sep',
	'oct',
	'nov',
	'dec',
];

export class MonthComponent extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			index: props.default != null ? props.default : new Date().getMonth(),
		};
	}

	setMonth(delta: number) {
		const newMonth = this.state.index + delta;
		this.setState({
			index: newMonth,
		});
		if (this.props.onChange != null) {
			this.props.onChange(newMonth);
		}
	}

	mod(n: number, m: number): number {
		return ((n % m) + m) % m;
	}

	componentWillReceiveProps(props: IProps) {
		this.setState({
			index: props.default,
		});
	}

	render() {
		const last = months[this.mod(this.state.index - 1, 12)];
		const current = months[this.mod(this.state.index, 12)];
		const next = months[this.mod(this.state.index + 1, 12)];
		return (
			<MonthComp>
				<LeftIndicator
					onClick={(event) => {
						event.stopPropagation();
						this.setMonth(-1);
					}}>
					<HoverText>{'<'}</HoverText>
				</LeftIndicator>
				<InactiveMonth
					onClick={(event) => {
						event.stopPropagation();
						this.setMonth(-1);
					}}>
					{' '}
					{translate('date.' + last + '.short', last)}
				</InactiveMonth>
				<ActiveMonth>{translate('date.' + current + '.short', last)}</ActiveMonth>
				<InactiveMonth
					onClick={(event) => {
						event.stopPropagation();
						this.setMonth(+1);
					}}>
					{' '}
					{translate('date.' + next + '.short', last)}
				</InactiveMonth>
				<RightIndicator
					onClick={(event) => {
						event.stopPropagation();
						this.setMonth(+1);
					}}>
					<HoverText>{'>'}</HoverText>
				</RightIndicator>
			</MonthComp>
		);
	}
}

const MonthComp = styled(FlexBox)`
	box-sizing: border-box;
	display: inline;
	margin-top: 10px;
`;

const ActiveMonth = styled.span`
	font-size: 12px;
	color: #464e51;
	font-weight: 700;
	margin-right: 10px;
`;

const InactiveMonth = styled.span`
	font-size: 12px;
	color: #a9a9a9;
	font-weight: 400;
	margin-right: 10px;
	&:hover {
		cursor: pointer;
	}
`;

const RightIndicator = styled(FlexBox)`
	color: #464e51;
	padding: 5px;
	display: inline;
	margin-right: 10px;
`;

const LeftIndicator = styled(RightIndicator)`
	margin-left: 10px;
`;
const HoverText = styled.span`
	&:hover {
		color: white;
		cursor: pointer;
	}
`;
