// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AuthConnection {
    LoginUserPassword = 'login_user_password',
    LoginPhone = 'login_phone',
    LoginToken = 'login_token',
    Signup = 'signup',
    SignupAddress = 'signup_address',
    SignupEmail = 'signup_email',
    SignupPhone = 'signup_phone',
    SignupUserdata = 'signup_userdata',
    ResetPassword = 'reset_password',
    Verify = 'verify',
    VerifyPhone = 'verify_phone',
    CancelPhone = 'cancel_phone',
    SetPassword = 'set_password',
    ContactAdmin = 'contact_admin',
    FaceLogin = 'face_login',
    ChoosePerson = 'choose_person'
}

