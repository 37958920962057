import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { translate } from '../../../../common/language/translate';
import {
	IStateCenter,
	IPropsCenter,
	ACenteredOverlay,
} from '../../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import { Box } from '../../../../components/atomiccompoents/boxes/box';

interface IProps extends IPropsCenter {}

interface IState extends IStateCenter {}

export default class BackOfficeSearchHelp extends ACenteredOverlay<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {};
	}

	reviveState(): void {}

	defineContent(): ReactNode {
		return (
			<StyledBox>
				<div>{translate('backoffice.searchHelp.title')}</div>
				<ul>
					<li>{translate('backoffice.searchHelp.a')}</li>
					<li>{translate('backoffice.searchHelp.b')}</li>
					<li>{translate('backoffice.searchHelp.c')}</li>
				</ul>
				<ButtonOk>{translate('button.ok')}</ButtonOk>
			</StyledBox>
		);
	}
}

const StyledBox = styled(Box)`
	width: 500px;
	height: 250px;

	& > div {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		text-transform: uppercase;
		color: ${(props) => props.theme.Global.lightFontColor};
	}

	& > button {
		float: right;
	}
`;
