// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Notification,
} from './';

/**
 * @export
 * @interface NotificationGroup
 */
export interface NotificationGroup {
    /**
     * the notification group
     * @type {string}
     * @memberof NotificationGroup
     */
    group: NotificationGroupGroupEnum;
    /**
     * @type {Array<Notification>}
     * @memberof NotificationGroup
     */
    notifications: Array<Notification>;
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationGroupGroupEnum {
    Account = 'account',
    Friends = 'friends',
    Ident = 'ident'
}

