import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { ReactElement } from 'react';

class EditableComponentStore extends EventEmitter {
    currentActiveIndex: number;

	private static instance: EditableComponentStore | null;

	constructor() {
		super();
        IDispatcher.register(this.registerActions.bind(this));
        this.currentActiveIndex = -1;
	}
	static getInstance(): EditableComponentStore {
		if (EditableComponentStore.instance == null) {
			EditableComponentStore.instance = new EditableComponentStore();
		}

		return EditableComponentStore.instance;
	}

	registerActions(action: any) {
		switch (action.actionType) {
			default:
				break;
			case Constants.ACTIVE_INDEX_CHANGED:
				this.setActiveIndex(action.index);
				this.emit(CHANGE_EVENT);
				break;
		}
		return true;
	}

	setActiveIndex(index: number) {
        this.currentActiveIndex = index;
    }

    getActiveIndex(): number {
        return this.currentActiveIndex;
    }
 
	addChangeListener(callback: any) {
		this.on(CHANGE_EVENT, callback);
	}

	removeChangeListener(callback: any) {
		this.removeListener(CHANGE_EVENT, callback);
	}
}

export const EditableComponentIndexStore = EditableComponentStore.getInstance();
