// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ExpectedCustomerListParams
 */
export interface ExpectedCustomerListParams {
    /**
     * @type {string}
     * @memberof ExpectedCustomerListParams
     */
    datetime_create: string;
    /**
     * @type {string}
     * @memberof ExpectedCustomerListParams
     */
    date_from: string;
    /**
     * @type {string}
     * @memberof ExpectedCustomerListParams
     */
    date_to: string;
    /**
     * @type {string}
     * @memberof ExpectedCustomerListParams
     */
    account_number?: string;
    /**
     * @type {string}
     * @memberof ExpectedCustomerListParams
     */
    test_center?: string;
    /**
     * @type {string}
     * @memberof ExpectedCustomerListParams
     */
    order_by?: ExpectedCustomerListParamsOrderByEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ExpectedCustomerListParamsOrderByEnum {
    SysDateTimestamp = 'sys_date_timestamp',
    AppointmentTimestamp = 'appointment_timestamp'
}

