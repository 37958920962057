import React, { SyntheticEvent } from 'react';
import { pageConfig } from './pageConfig';
import HeaderComponent from './header/header';
import ContentComponent from './content/content';
import styled from 'styled-components';
import { OverlayHandler } from '../../logic/handler/overlayhandler/overlayHandler';
import Pages from './pages';
import { UserDataStore } from '../../logic/flux/stores/userdataStore';
import { NavbarComponent, APages, IPage } from '../../components/nav';
import { FooterComponent } from '../../components/footer';

interface IProps {
	onClick: (event: SyntheticEvent) => void;
	
}

interface IState {
	smallNav: boolean;
	roles: Array<string>
}

class DashboardComponent extends React.Component<IProps, IState> {
	private userChecked: boolean;
	pageConfiguration: Array<IPage> = pageConfig;
	constructor(props: IProps) {
		super(props);
		const roles = UserDataStore.getRoles();
		this.state = {
			smallNav: false,
			roles: roles,
		};

		this.userChecked = false;
		this.resize = this.resize.bind(this);
		this.toggleNav = this.toggleNav.bind(this);
		this.receiveUserRoles = this.receiveUserRoles.bind(this);
	}

	componentDidMount(): void {
		this.resize();
		window.addEventListener('resize', this.resize);
		UserDataStore.addChangeListener(this.receiveUserRoles);
	}

	receiveUserRoles() {
		const roles = UserDataStore.getRoles();
		this.setState({
			roles: roles
		})
	}

	private resize() {
		if (window.innerWidth < 987 && this.state.smallNav !== true) {
			this.setState({
				smallNav: true,
			});
		} else if (window.innerWidth >= 987 && this.state.smallNav === true) {
			this.setState({
				smallNav: false,
			});
		}
	}

	private toggleNav(): void {
		this.setState({
			smallNav: !this.state.smallNav,
		});
	}

	UserConfirmed(): boolean {
		const lastUser = window.sessionStorage.getItem('lastUser');
		if (lastUser == null) {
			return false;
		} else {
			if (
				UserDataStore.getUser() != null &&
				UserDataStore.getUser()!!.person_id === parseInt(lastUser)
			) {
				return true;
			} else {
				return false;
			}
		}
	}

	resetSessionStorage() {
		const temp = window.sessionStorage.getItem('user');
		const lastPath = window.sessionStorage.getItem('lastPath');
		const searchPath = window.sessionStorage.getItem('lastSearchPath');
		// window.sessionStorage.clear(); //TODO: This does not work properly...
		if (lastPath != null) {
			window.sessionStorage.setItem('lastPath', lastPath);
		}
		if (searchPath != null) {
			window.sessionStorage.setItem('lastSearchPath', searchPath);
		}
		window.sessionStorage.setItem('user', temp != null ? temp : '');
		this.userChecked = true;
	}

	handleLastSession() {
		this.UserConfirmed();

		if (!this.userChecked) {
			this.resetSessionStorage();
			return;
		}
		const overlays = window.sessionStorage.getItem('overlays');
		if (overlays != null) {
			const overlayJson = JSON.parse(overlays);
			for (const i in overlayJson) {
				OverlayHandler.showOverlay(overlayJson[i].key, overlayJson[i].data);
			}
			window.sessionStorage.removeItem('overlays');
		}
	}

	updatePageConfigWithRoles(): Array<IPage> {
		return this.pageConfiguration;
		/*const roles = this.state.roles;
		const pagCon = this.pageConfiguration;
		for(const o of pagCon) {
			if(o.text === 'nav.customers' && roles.indexOf('support') < 0) {
				o.display = false;
			} else if(o.text === 'nav.backoffice' && roles.indexOf('user') < 0) {
				o.display = false;
			} else if(o.text === 'nav.administration' && roles.indexOf('admin') < 0) {
				o.display = false;
			} else if (o.text=== 'nav.customers' || o.text === 'nav.backoffice' || o.text === 'nav.administration') {
				o.display = true;
			}
		}	
		this.pageConfiguration = pagCon;
		return pagCon;*/
	}

	render() {
		this.handleLastSession();
		const pageConf = pageConfig//this.updatePageConfigWithRoles();
		const pages: APages = new Pages(pageConf, process.env.REACT_APP_ROOT_PATH);
		return (
			<StyledDashboard onClick={this.props.onClick}>
				{OverlayHandler.getOverlays()}
				<div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
					<NavbarComponent pages={pages} smallNav={this.state.smallNav} />
					<div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
						<HeaderComponent
							pages={pages}
							smallNav={this.state.smallNav}
							toggleNav={this.toggleNav}
						/>
						<ContentComponent pages={pages} smallNav={this.state.smallNav} />
						<FooterComponent />
					</div>
				</div>
			</StyledDashboard>
		);
	}
}

const StyledDashboard = styled.div`
	cursor: default;
	flex-direction: column;
	flex-grow: 1;
`;

export default DashboardComponent;
