// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * a referral product
 * @export
 * @interface ReferralProduct
 */
export interface ReferralProduct {
    /**
     * @type {number}
     * @memberof ReferralProduct
     */
    referral_product_id: number;
    /**
     * plaintext description of the referral product
     * @type {string}
     * @memberof ReferralProduct
     */
    description: string;
    /**
     * until management of products has been implemented, including logo upload, we address them via this enum
     * @type {string}
     * @memberof ReferralProduct
     */
    icon: ReferralProductIconEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ReferralProductIconEnum {
    Coffee = 'coffee',
    Money = 'money',
    SaveFee = 'save_fee'
}

