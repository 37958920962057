//@ts-nocheck
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import {UserClass} from '../../../../../logic/api/ident';
import {theme} from '../../../../../common/theme';
import {
	IStatusState,
	IMessageConfig,
	Reporter,
	getFormComponents,
} from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import {
    api, 
    apis, 
    ApiError, 
    Ident
} from '../../../../../logic/api';
import {
    Log, 
    Logs} from '../../../../../logic/log';
import {MessageHandler} from '../../../../../logic/handler/messagehandler/messageHandler';
import {translate} from '../../../../../common/language/translate';
import {Icons} from '../../../../../images';
import {
	IStateOver,
	IPropsOver,
	AOverlay,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import {
	IInitProps,
	IInitState,
} from '../../../../../logic/handler/initialdatahandler/initialDataComponent';
import { InitComponentHandler } from '../../../../../logic/handler/initialdatahandler/InitComponentHandler';
import { BigInput } from '../../../../../components/atomiccompoents/form/inputs.css';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import ButtonCancel from '../../../../../components/atomiccompoents/buttons/buttonCancel';
import Title from '../../../../../components/compositcomponents/title';
import { 
    OverlayHandler, 
    Overlays } from '../../../../../logic/handler/overlayhandler/overlayHandler';
import { evaluateErrorMessage } from '../../../../../logic/helper/Common';

const StyledOverlay = styled.div`
	margin: auto;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 50px;
	padding-top: 20;
	display: flex;
	flex-direction: column;
	width: 640px;
	height: 640px;
	align-items: center;
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	background-color: white;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	overflow-y: auto;
`;

const StyledButtonView = styled.div`
	display: flex;
	flex-direction: row-reverse;

	& > div {
		margin-right: 10px;
	}
`;

const StyledButtonViewCreate = styled(StyledButtonView)`
	margin-top: 120px;
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const Wrapper = styled.div`
	width: 100%;
`;

interface IProps extends IInitProps, IPropsOver {
	createCallback: () => void;
}
interface IState extends IStatusState, IInitState, IStateOver {
    firstName: string;
    lastName: string;
    email: string;
    roleId?: number;
    personId?: number;
    keyForLoadingSpinner?: number;
}

export default class TeamMemberOverlay extends AOverlay<IProps, IState> {
    private ButtonStyle = {
		width: theme.Button.width,
		margin: '16px'
    };
    constructor(props: IProps) {
    	super(props);

    	this.state = {
    		lastName: '',
    		firstName: '',
    		email: '',
    		keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    	};
    	this.onCreate = this.onCreate.bind(this);
    	this.onEmailChanged = this.onEmailChanged.bind(this);
    	this.onFirstNameChanged = this.onFirstNameChanged.bind(this);
    	this.onLastNameChanged = this.onLastNameChanged.bind(this);
    	this.cancel = this.cancel.bind(this);
    	InitComponentHandler.register(this, this.constructor.name);
    }

    stopPropagation(event: SyntheticEvent) {
    	event.stopPropagation();
    }

    componentDidMount() {
    	this.fill(this.constructor.name);
    }

    componentWillUnmount() {
    	InitComponentHandler.cleanUp(this.constructor.name);
    }

    reviveState(newState: IState): void {
    	this.setState(newState);
    }

    cancel(event?: SyntheticEvent) {
    	if (event != null) {
    		event.preventDefault();
    	}
    	this.props.selfClose();
    }

    onCreate(
    	event:
            | SyntheticEvent
    ) {
    	event.preventDefault();
    	if (
    		this.state.firstName !== '' &&
            this.state.lastName !== '' &&
            this.state.email !== ''
    	) {
    		this.addMember(this.state.firstName, this.state.lastName, this.state.email).then(
    			response => {
    				this.setState({
    					keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    				}, () => {
    					if (response != null) {
							//@ts-ignore
							if(response.release_request_id != null) {
								MessageHandler.onSuccess(Reporter['teammember.approval.needed']);
								this.props.selfClose();
							} else {
								const personId = response.person_id;
								const name = this.state.firstName + ' ' + this.state.lastName;
								OverlayHandler.showOverlay(Overlays.roleEdit, {personId: personId, personName: name, createCallback: this.props.createCallback, initPerson: true });
								this.props.selfClose();
							}
    					}
    				} );
    			}
    		);
    	} else {
    		let transactionKey;
    		if (this.state.lastName === '') {
    			transactionKey = 'lastName';
    		} else if (this.state.firstName === '') {
    			transactionKey = 'firstName';
    		} else if (this.state.email === '') {
    			transactionKey = 'email';
    		}
    		const config: IMessageConfig = MessageHandler.onError(
    			Reporter['administrator.teams.add.team.member']
    		);
    		this.setState({
    			showInlineError: config.errorMethods.inline,
    			errorMessage: config.errorMessage != null ? config.errorMessage : '',
    			key:
                    config.translationKey != null
                    	? config.translationKey + '.' + transactionKey
                    	: '',
    			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    		});
    	}
    }

    onEmailChanged(value: string) {
    	this.setState({
    		email: value,
    		showInlineError: false,
    	});
    }

    onLastNameChanged(value: string) {
    	this.setState({
    		lastName: value,
    		showInlineError: false,
    	});
    }

    onFirstNameChanged(value: string) {
    	this.setState({
    		firstName: value,
    		showInlineError: false,
    	});
    }

    onCreateKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    	// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    	if (event.key === 'Enter') {
    		event.preventDefault();
    		event.stopPropagation();
    		this.onCreate(event);
    	}
    };

    getFormContent() {
    	return (
    		<form onSubmit={this.onCreate} onKeyDown={this.onCreateKeyDown}>
				<BigInput
    				label={translate('team.input.firstname.label')}
    				placeHolder={translate('team.input.firstname.placeholder')}
    				type="firstname"
    				id="firstName"
    				onChange={(value: string) => this.onFirstNameChanged(value)}
    				value={this.state.firstName}
    				valueFromState={true}
    				onSubmit={this.onCreate}
    			/>
    			<BigInput
    				label={translate('team.input.lastname.label')}
    				placeHolder={translate('team.input.lastname.placeholder')}
    				type="lastname"
    				id="lastName"
    				onChange={(value: string) => this.onLastNameChanged(value)}
    				value={this.state.lastName}
    				valueFromState={true}
    				onSubmit={this.onCreate}
    			/>
    			<BigInput
    				label={translate('team.input.email.label')}
    				placeHolder={translate('team.input.email.placeholder')}
    				type="email"
    				id="email"
    				onChange={(value: string) => this.onEmailChanged(value)}
    				value={this.state.email}
    				valueFromState={true}
    				onSubmit={this.onCreate}
    			/>

    			{getFormComponents(
    				this.state.showInlineError,
    				this.state.showInlineSuccess,
    				this.state.errorMessage,
    				this.state.successMessage,
    				this.state.key
    			)}

    			<StyledButtonViewCreate>
    				<ButtonOk
    					key={this.state.keyForLoadingSpinner}
    					id="btnAddTeamMemberOk"
    					onClick={this.onCreate}
    					style={this.ButtonStyle}>
    					{translate('button.create')}
    				</ButtonOk>
    				<ButtonCancel
    					id="btnAddTeamMemberCancel"
    					onClick={this.cancel}
    					style={this.ButtonStyle}>
    					{translate('button.cancel')}
    				</ButtonCancel>
    			</StyledButtonViewCreate>
    		</form>
    	);
    }

    private getUserClass(): Promise<string> {
    	return new Promise<string>((resolve, reject) => {
    		const params: Ident.LookupProductUserClassGetRequest = {
    			user_class: Ident.UserClass.Bo,
    		};
    		api.asyncRequest<Array<Ident.ProductUserclass>>(
    			params,
    			apis.MaintenanceApi,
    			'lookupProductUserClassGet'
    		)
    			.then(response => {
    				if (response.length === 0) {
    					const e: ApiError = {
    						status: 400,
    						statusText: 'No products received',
    						response: response,
    					};
    					return reject(e);
    				}
    				return resolve(response[0].product);
    			})
    			.catch((e: ApiError) => {
    				return reject(e);
    			});
    	});
    }

    addMember(firstName: string, lastName: string, email: string): Promise<Ident.PersonId> {
    	return new Promise<Ident.PersonId>((resolve) => {
    		this.getUserClass()
    			.then(product => {
    				const param: Ident.PersonCreateThirdPersonPostRequest = {
    					NewEmoneyPersonWithUsertype: {
    						name: lastName,
    						given_name: firstName,
    						email_address: email,
    						user_class: UserClass.Bo,
                            product: product,
                            birth_city: '',
    						address: {
    							// TODO:
    							city: 'A city',
    							country: Ident.CountryCodeAlpha2.DE,
    							address_type: Ident.AddressType.L,
    						},
    					},
    				};
    				api.asyncRequest<Ident.PersonId>(
    					param,
    					apis.RegistrationApi,
    					'personCreateThirdPersonPost'
    				)
    					.then(
    						(
    							response:
                                    | Ident.PersonId
                                    | PromiseLike<Ident.PersonId>
									| undefined
									| any
    						) => {
    							return resolve(response);
    						}
    					)
    					.catch((error: ApiError) => {
    						const config: IMessageConfig = MessageHandler.onError(
                                Reporter['person.create.third.post.request'],
                                evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
    						);
    						this.setState({
    							showInlineError: config.errorMethods.inline,
    							errorMessage:
                                    config.errorMessage != null
                                    	? config.errorMessage
                                    	: error.statusText,
    							key:
                                    config.translationKey != null
                                    	? config.translationKey + '.error'
                                    	: '',
    							keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    						});
    						Log.debug(Logs.API, error.statusText);
    					});
    			})
    			.catch((error: ApiError) => {
    				const config: IMessageConfig = MessageHandler.onError(
                        Reporter['person.create.third.post.request'],
                        evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
    				);
    				this.setState({
    					showInlineError: config.errorMethods.inline,
    					errorMessage:
                            config.errorMessage != null
                            	? config.errorMessage
                            	: error.statusText,
    					key:
                            config.translationKey != null
                            	? config.translationKey + '.error'
                            	: '',
    					keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    				});
    				Log.debug(Logs.API, error.statusText);
    			});
    	});
    }

    defineContent() {
    	return (
    		<StyledOverlay onClick={event => event.stopPropagation()}>
    			<Wrapper>
    				<Title
    					title={translate('team.newTeamMember')}
    					icon={Icons.createCustomer()}
    				/>
    				{this.getFormContent()}
    			</Wrapper>
    		</StyledOverlay>
    	);
    }
}
