// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Iso4217Currency,
} from './';

/**
 * @export
 * @interface ScheduledTransaction
 */
export interface ScheduledTransaction {
    /**
     * @type {number}
     * @memberof ScheduledTransaction
     */
    ta_id: number;
    /**
     * @type {number}
     * @memberof ScheduledTransaction
     */
    scheduled_transaction_id: number;
    /**
     * @type {Date}
     * @memberof ScheduledTransaction
     */
    valid_from: Date;
    /**
     * @type {Date}
     * @memberof ScheduledTransaction
     */
    invalid_from?: Date;
    /**
     * @type {number}
     * @memberof ScheduledTransaction
     */
    person_id_create: number;
    /**
     * @type {number}
     * @memberof ScheduledTransaction
     */
    person_id_update?: number;
    /**
     * in case of scheduled credit transfer the debitor, in case of money request the creditor
     * @type {number}
     * @memberof ScheduledTransaction
     */
    person_id: number;
    /**
     * in case of scheduled credit transfer the debitor, in case of money request the creditor
     * @type {string}
     * @memberof ScheduledTransaction
     */
    own_account: string;
    /**
     * @type {Iso4217Currency}
     * @memberof ScheduledTransaction
     */
    instructed_amount_currency: Iso4217Currency;
    /**
     * @type {number}
     * @memberof ScheduledTransaction
     */
    instructed_amount_amount: number;
    /**
     * in case of scheduled credit transfer the creditor, in case of money request the debitor
     * @type {number}
     * @memberof ScheduledTransaction
     */
    counterparty_person_id?: number;
    /**
     * in case of scheduled credit transfer the creditor, in case of money request the debitor
     * @type {string}
     * @memberof ScheduledTransaction
     */
    counterparty_account_number?: string;
    /**
     * @type {string}
     * @memberof ScheduledTransaction
     */
    counterparty_account: string;
    /**
     * * A -ccount number  * I -BAN * E -mail address  * P -phone number
     * @type {string}
     * @memberof ScheduledTransaction
     */
    counterparty_account_type: ScheduledTransactionCounterpartyAccountTypeEnum;
    /**
     * @type {string}
     * @memberof ScheduledTransaction
     */
    counterparty_name: string;
    /**
     * @type {string}
     * @memberof ScheduledTransaction
     */
    remittance_information_unstructured?: string;
    /**
     * @type {boolean}
     * @memberof ScheduledTransaction
     */
    gift_payment?: boolean;
    /**
     * * C -redit transfer  * R -equest money
     * @type {string}
     * @memberof ScheduledTransaction
     */
    transaction_type: ScheduledTransactionTransactionTypeEnum;
    /**
     * @type {Date}
     * @memberof ScheduledTransaction
     */
    cycle_start: Date;
    /**
     * @type {Date}
     * @memberof ScheduledTransaction
     */
    cycle_end?: Date;
    /**
     * * O -nce  * D -aily  * W -eekly  * M -onthly  * Y -early
     * @type {string}
     * @memberof ScheduledTransaction
     */
    cycle_type: ScheduledTransactionCycleTypeEnum;
    /**
     * * B -eginning of ...  * E -nd of ...  * D -ay (e.g. always at the 12th of ...)
     * @type {string}
     * @memberof ScheduledTransaction
     */
    cycle_reference: ScheduledTransactionCycleReferenceEnum;
    /**
     * @type {number}
     * @memberof ScheduledTransaction
     */
    cycle_day_of_week?: number;
    /**
     * @type {number}
     * @memberof ScheduledTransaction
     */
    cycle_day_of_month?: number;
    /**
     * external reference as provided by the initiaing application
     * @type {string}
     * @memberof ScheduledTransaction
     */
    external_reference?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ScheduledTransactionCounterpartyAccountTypeEnum {
    A = 'A',
    E = 'E',
    I = 'I',
    P = 'P'
}
/**
 * @export
 * @enum {string}
 */
export enum ScheduledTransactionTransactionTypeEnum {
    C = 'C',
    R = 'R'
}
/**
 * @export
 * @enum {string}
 */
export enum ScheduledTransactionCycleTypeEnum {
    O = 'O',
    D = 'D',
    W = 'W',
    M = 'M',
    Y = 'Y',
    P = 'P'
}
/**
 * @export
 * @enum {string}
 */
export enum ScheduledTransactionCycleReferenceEnum {
    B = 'B',
    E = 'E',
    D = 'D'
}

