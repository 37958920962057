import styled from 'styled-components';

/**
 * optional param: maxHeight
 * sets maxHeight property and scroll behavior
 * example usage:
 * <Box maxHeight={'300px'}>
 * ...
 * </Box>
 */

export const Box = styled.div<{ maxHeight?: string; padding?: number }>`
	padding: ${(props) => (props.padding == null ? '24px' : props.padding + 'px')};
	cursor: default;

	background-color: ${(props) => props.theme.Box.background};
	border: ${(props) => props.theme.Box.border};
	box-sizing: ${(props) => props.theme.Box.boxSizing};
	box-shadow: ${(props) => props.theme.Box.boxShadow};
	border-radius: ${(props) => props.theme.Box.borderRadius};

	max-height: ${(props) => (props.maxHeight != null ? props.maxHeight : undefined)};
	overflow: ${(props) => (props.maxHeight != null ? 'auto' : undefined)};
`;
