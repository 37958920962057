import React, { ReactNode } from 'react';
import NavbarLink from './navbarLink';
import styled from 'styled-components';

import { Icons, Images } from '../../images';
import { APages, IPage } from './pages.abstract';
import History from 'history';
import history from '../../common/history';
import { Actions } from '../../logic/flux';
import { INotifyAbleTheme } from '../../logic/flux/notifyAbles/notifyAbleTheme';
import { ThemeStore, themeType } from '../../logic/flux/stores/themeStore';
import IconPicker from '../atomiccompoents/iconPicker/iconPicker';
import IconPickerItem from '../atomiccompoents/iconPicker/iconPickerItem';
import { DeveloperStore } from '../../logic/flux/stores/DeveloperStore';
import { OverlayHandler, Overlays } from '../../logic/handler/overlayhandler/overlayHandler';
import { Log, Logs } from '../../logic/log';
import { Account, api, ApiError, apis } from '../../logic/api';
import { ClientConfig } from '../../common/config';
import { translate } from '../../common/language/translate';
import { format } from '../../logic/helper/format';

interface IProps {
	pages: APages;
	smallNav: boolean;
}

interface IState {
	activePagename?: string;
	pages: APages;
	currentPath?: string;
	smallNav?: boolean;
	theme?: themeType;
	userImage?: any;
	showSmallDeveloperMenu?: boolean;
	
}

export class NavbarComponent extends React.Component<IProps, IState>
	implements INotifyAbleTheme {
	constructor(props: IProps) {
		super(props);

		this.state = {
			activePagename: this.props.pages.getActivePagename(),
			smallNav: props.smallNav,
			pages: props.pages,
			userImage: undefined,
			showSmallDeveloperMenu: DeveloperStore.isDeveloperModeEnabled() === true,
		};

		this._onChangePath = this._onChangePath.bind(this);
		this._onChangeTheme = this._onChangeTheme.bind(this);
		this._onDrop = this._onDrop.bind(this);
		this.showSmallDeveloperMenu = this.showSmallDeveloperMenu.bind(this);
		this._onCloseDeveloper = this._onCloseDeveloper.bind(this);
	}

	_onChangePath(location: History.Location): void {
		const currentPath: string = location.pathname;
		Log.info(Logs.SYSTEM, 'Location changed: ' + currentPath);
		this.setState({
			currentPath: currentPath,
		});
	}

	_onChangeTheme(): void {
		this.setState({
			theme: ThemeStore.getTheme(),
		});
	}

	componentDidMount(): void {
		ThemeStore.addChangeListener(this._onChangeTheme);
		this._onChangeTheme();
		history.listen((location) => this._onChangePath(location));
	}

	componentWillReceiveProps(props: IProps) {
		this.setState({
			pages: props.pages,
			smallNav: props.smallNav
		})
	}

	componentWillUnmount(): void {
		ThemeStore.removeChangeListener(this._onChangeTheme);
	}

	static getDerivedStateFromProps(props: IProps, state: IState): IState | null {
		if (props.smallNav !== state.smallNav) {
			return {
				smallNav: props.smallNav,
				pages: props.pages
			};
		}

		return null;
	}

	private createNavigationLinks(pages: Array<IPage>, depth: number = 0): Array<ReactNode> {
		return pages.map((page: IPage, i: number) => {
			if (
				page.display === false ||
				(page.showIcon === false && page.showText === false)
			) {
				return null;
			}
			const subMenu: Array<ReactNode> | null =
				page.showSubmenue !== false && page.subPages != null
					? this.createNavigationLinks(page.subPages, depth + 1)
					: null;
			return (
				<ShadowBox
					active={page.active}
					selected={page.active === true || page.subPageActive === true}
					depth={depth}
					key={i}>
					<NavBox
						active={page.active}
						selected={page.active === true || page.subPageActive === true}
						depth={depth}>
						<div>
							<NavbarLink
								depth={depth}
								page={page}
								key={page.text}
								first={i === 0}
								last={i - 1 === pages.length}
								smallNav={this.state.smallNav}
							/>
						</div>
						{subMenu}
					</NavBox>
				</ShadowBox>
			);
		});
	}

	private onChangeClient(itemValue: any): void {
		switch (itemValue) {
			case 'default':
			case 'onefor':
			default:
				Actions.setTheme('default');
				break;
			case 'other':
				Actions.setTheme('other');
				break;
		}
	}

	private _onCloseDeveloper(): void {
		Actions.disableDeveloperOptions();
		OverlayHandler.closeSpecific(Overlays.developer);
		this.setState({
			showSmallDeveloperMenu: false,
		});
	}

	private _onShowDeveloperMenu(): void {
		OverlayHandler.showOverlay(Overlays.developer, {
			_onMinimize: this.showSmallDeveloperMenu,
			_onClose: this._onCloseDeveloper,
		});
		this.setState({
			showSmallDeveloperMenu: false,
		});
	}

	private _onDrop(event: React.DragEvent<HTMLDivElement>): void {
		event.stopPropagation();
		event.preventDefault();
		const image = event.dataTransfer.getData('image/*');
		const svg = event.dataTransfer.getData('image/svg');
		if ((image != null || svg != null) && DeveloperStore.isDraggingUserImage() === true) {
			Actions.enableDeveloperOptions();
			this._onShowDeveloperMenu();
		}
	}

	private showSmallDeveloperMenu(): void {
		OverlayHandler.closeSpecific(Overlays.developer);
		this.setState({
			showSmallDeveloperMenu: true,
		});
	}

	render() {
		return (
			<StyledNav smallNav={this.state.smallNav} onDrop={this._onDrop}>
				<StyledView smallNav={this.state.smallNav} onClick={() => history.push('/')}>
					<IconPicker
						onValueChange={this.onChangeClient}
						selectedValue={this.state.theme}
						defaultValue="ndpay">
						{process.env.REACT_APP_CLIENT != null &&
						process.env.REACT_APP_CLIENT === 'onefor' ? (
							<IconPickerItem
								value="onefor"
								pickerStyle={{ width: '49px', height: '37px' }}>
								{Images.navLogo('49px')}
							</IconPickerItem>
						) : (
							<IconPickerItem value="ndpay">{Icons.ndPayLogo()}</IconPickerItem>
						)}
						{/*<IconPickerItem value="other">
                            {Icons.construction(49, 49)}
        				</IconPickerItem>*/}
					</IconPicker>
				</StyledView>
				{this.createNavigationLinks(this.state.pages.getPages(this.state.currentPath))}
				<div
					style={{
						width: '100%',
						flexGrow: 1,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end',
						alignItems: 'center',
					}}
					onDrop={this._onDrop}
					onDragOver={(e) => e.preventDefault()}>
					{this.state.showSmallDeveloperMenu !== true ? null : (
						<SmallDeveloperMenu smallNav={this.state.smallNav === true}>
							{this.state.smallNav === true ? null : (
								<span style={{ flexGrow: 1 }}>Developer</span>
							)}
							<span
								onClick={() => {
									this._onShowDeveloperMenu();
								}}>
								{Icons.maximize()}
							</span>
							<span onClick={() => this._onCloseDeveloper()}>
								{Icons.close()}
							</span>
						</SmallDeveloperMenu>
					)}
				</div>
			</StyledNav>
		);
	}
}

const SmallDeveloperMenu = styled.div<{ smallNav: boolean }>`
	width: 80%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	background-color: white;
	border: 1px solid black;
	border-radius: 4px;
	margin-bottom: 12px;
	padding: 4px 0px 4px 4px;
	box-sizing: border-box;
`;

const StyledNav = styled.nav<{ smallNav?: boolean }>`
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: flex-start;

	width: ${(props) => (props.smallNav === true ? '64px' : '241px')};
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: #ffffff;
	border: solid #e8ecef;
	border-width: 0px 1px 0px 0px;
	-webkit-overflow-scrolling: touch;
	cursor: default;
`;

const StyledView = styled.div<{ smallNav?: boolean }>`
	height: 64px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const ShadowBox = styled.div<{ active: boolean; selected: boolean; depth: number }>`
	background-color: ${(props) => (props.selected === true ? '#f9f9f9' : 'none')};
	width: 100%;
	border-radius: 5px;
`;

const NavBox = styled.div<{ active: boolean; selected: boolean; depth: number }>`

	box-sizing: border-box;
	margin: 0 4px 4px 0;
`;

const StatsBox = styled.div<{ smallNav?: boolean }>`
	display: ${(props) => (props.smallNav === true ? 'none' : 'flex' )};
	flex-direction: column;
	margin: 16px;
	height: 100%;
	justify-content: flex-end;
	margin-left: 10%;	
	margin-bottom: 32px;
`;

const StatsElement = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-bottom: 32px;
	border-bottom: 3px;
	border-color: ${(props) => props.theme.Global.primaryColor};
	border-style: solid;
	border-width: 0px 0px 2px 0px;
    color: ${(props) => props.theme.Global.darkFontColor};
`;

const TextBox = styled.div`
	width: 70%;
	font-weight: 400;
	font-size: 10
`;

export default NavbarComponent;
