// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AccountDetailTxLimits
 */
export interface AccountDetailTxLimits {
    /**
     * unique id for each limit type
     * @type {number}
     * @memberof AccountDetailTxLimits
     */
    limit_type_id: number;
    /**
     * textual description of the limit
     * @type {string}
     * @memberof AccountDetailTxLimits
     */
    description: string;
    /**
     * validity period for that limit * \'D\'ay * calendar \'W\'eek * calendar \'M\'onth * calendar \'Y\'ear * \'T\'ransaction * \'B\'alance
     * @type {string}
     * @memberof AccountDetailTxLimits
     */
    validity: AccountDetailTxLimitsValidityEnum;
    /**
     * if the limit was used at least once in the current period, the start of the period
     * @type {Date}
     * @memberof AccountDetailTxLimits
     */
    valid_from?: Date;
    /**
     * if the limit was used at least once in the current period, the date the currente period becomes invalid
     * @type {Date}
     * @memberof AccountDetailTxLimits
     */
    invalid_from?: Date;
    /**
     * the limits value in account currency
     * @type {number}
     * @memberof AccountDetailTxLimits
     */
    limit_amount: number;
    /**
     * the amount used in the period indicated by the interval [valid_froim,invalid_from)
     * @type {number}
     * @memberof AccountDetailTxLimits
     */
    used_amount: number;
    /**
     * the minimum configurable limit_amount value
     * @type {number}
     * @memberof AccountDetailTxLimits
     */
    min_amount: number;
    /**
     * the maximum configurable limit_amount value
     * @type {number}
     * @memberof AccountDetailTxLimits
     */
    max_amount: number;

    amount_type: 'Q' | 'M';
}

/**
 * @export
 * @enum {string}
 */
export enum AccountDetailTxLimitsValidityEnum {
    D = 'D',
    W = 'W',
    M = 'M',
    Y = 'Y',
    T = 'T',
    B = 'B'
}

