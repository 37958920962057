// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface IssuedCard
 */
export interface IssuedCard {
    /**
     * @type {string}
     * @memberof IssuedCard
     */
    account_number?: string;
    /**
     * @type {string}
     * @memberof IssuedCard
     */
    name?: string;
    /**
     * @type {string}
     * @memberof IssuedCard
     */
    given_name?: string;
    /**
     * @type {Date}
     * @memberof IssuedCard
     */
    issuing_date?: Date;
    /**
     * @type {string}
     * @memberof IssuedCard
     */
    virtual_or_physical?: IssuedCardVirtualOrPhysicalEnum;
    /**
     * @type {string}
     * @memberof IssuedCard
     */
    bpc_card_id?: string;
    /**
     * @type {number}
     * @memberof IssuedCard
     */
    card_status?: number;
    /**
     * @type {Date}
     * @memberof IssuedCard
     */
    last_update?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum IssuedCardVirtualOrPhysicalEnum {
    V = 'V',
    P = 'P'
}

