// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Iso4217CurrencyOUT,
    PostingJournalRelatedTransactions,
} from './';

/**
 * @export
 * @interface DispoActivityDispoStatements
 */
export interface DispoActivityDispoStatements {
    /**
     * first part of transaction_/statement_identifier
     * @type {number}
     * @memberof DispoActivityDispoStatements
     */
    ta_id: number;
    /**
     * second part of dispo_statement_identifier
     * @type {number}
     * @memberof DispoActivityDispoStatements
     */
    dispo_statement_id: number;
    /**
     * second part of transaction_identifier
     * @type {number}
     * @memberof DispoActivityDispoStatements
     */
    transaction_id: number;
    /**
     * the date part of booking timestamp, for legacy reasons
     * @type {Date}
     * @memberof DispoActivityDispoStatements
     */
    booking_date: Date;
    /**
     * official booking timestamp
     * @type {Date}
     * @memberof DispoActivityDispoStatements
     */
    booking_datetime: Date;
    /**
     * timestamp of technical booking
     * @type {Date}
     * @memberof DispoActivityDispoStatements
     */
    sysdate: Date;
    /**
     * value date
     * @type {Date}
     * @memberof DispoActivityDispoStatements
     */
    value_date: Date;
    /**
     * timestamp when the transaction actually happend
     * @type {Date}
     * @memberof DispoActivityDispoStatements
     */
    transaction_datetime: Date;
    /**
     * @type {string}
     * @memberof DispoActivityDispoStatements
     */
    subbalance?: string;
    /**
     * debit credit indicator
     * @type {string}
     * @memberof DispoActivityDispoStatements
     */
    dc: DispoActivityDispoStatementsDcEnum;
    /**
     * amount in currency
     * @type {number}
     * @memberof DispoActivityDispoStatements
     */
    amount: number;
    /**
     * @type {Iso4217CurrencyOUT}
     * @memberof DispoActivityDispoStatements
     */
    currency: Iso4217CurrencyOUT;
    /**
     * a user or system generated free text
     * @type {string}
     * @memberof DispoActivityDispoStatements
     */
    statement_text: string;
    /**
     * transaction key to describe the kind of transaction
     * @type {number}
     * @memberof DispoActivityDispoStatements
     */
    txkey_id: number;
    /**
     * the decoded txkey_id
     * @type {string}
     * @memberof DispoActivityDispoStatements
     */
    txkey_description: string;
    /**
     * information provided by card processor, spec (SVE_CBS-Specification.doc):  The name and location of the card acceptor. This field is required in authorization requests, advice messages and related reversal requests for all card-read transactions. It contains the information necessary for printing on customer account statements and on credit card billing statements.  Subfield 1: ans38  * Merchant number of bank name;  * Separator ‘>’  * Merchant city or location of the terminal device   * Trailing spaces  Subfield 2: ans 2  * ISO code of the country  
     * @type {string}
     * @memberof DispoActivityDispoStatements
     */
    card_acceptor_name_and_location?: string;
    /**
     * phone number of the other customer
     * @type {string}
     * @memberof DispoActivityDispoStatements
     */
    counterparty_phone_number?: string;
    /**
     * indicates if activity line has been closed by contrary transactions
     * @type {boolean}
     * @memberof DispoActivityDispoStatements
     */
    is_closed?: boolean;
    /**
     * @type {string}
     * @memberof DispoActivityDispoStatements
     */
    source?: string;
    /**
     * @type {number}
     * @memberof DispoActivityDispoStatements
     */
    source_id?: number;
    /**
     * @type {Array<PostingJournalRelatedTransactions>}
     * @memberof DispoActivityDispoStatements
     */
    related_transactions?: Array<PostingJournalRelatedTransactions>;
}

/**
 * @export
 * @enum {string}
 */
export enum DispoActivityDispoStatementsDcEnum {
    D = 'D',
    C = 'C'
}

