// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    TransactionTemplate,
} from '../models';

export interface ReportTemplatesAdminGetRequest {
    language?: ReportTemplatesAdminGetLanguageEnum;
}

export interface ReportTemplatesBoGetRequest {
    language?: ReportTemplatesBoGetLanguageEnum;
}

export interface ReportTemplatesCustomerGetRequest {
    language?: ReportTemplatesCustomerGetLanguageEnum;
}

export interface ReportTemplatesGetRequest {
    report_class: ReportTemplatesGetReportClassEnum;
    language?: ReportTemplatesGetLanguageEnum;
}

/**
 * no description
 */
export class TemplatesApi extends BaseAPI implements IOperationIdMap {

    /**
     * get all admin report templates
     */
    reportTemplatesAdminGet = (requestParameters: ReportTemplatesAdminGetRequest): Observable<Array<TransactionTemplate>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<TransactionTemplate>>({
            path: '/report_templates/admin',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get all bo report templates
     */
    reportTemplatesBoGet = (requestParameters: ReportTemplatesBoGetRequest): Observable<Array<TransactionTemplate>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<TransactionTemplate>>({
            path: '/report_templates/bo',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get all customer report templates
     */
    reportTemplatesCustomerGet = (requestParameters: ReportTemplatesCustomerGetRequest): Observable<Array<TransactionTemplate>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<TransactionTemplate>>({
            path: '/report_templates/customer',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * to be removed as it was split into report-admin/bo/customer
     * get all report templates
     */
    reportTemplatesGet = (requestParameters: ReportTemplatesGetRequest): Observable<Array<TransactionTemplate>> => {
        throwIfRequired(requestParameters, 'report_class', 'reportTemplatesGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.report_class && { 'report_class': requestParameters.report_class }),
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<TransactionTemplate>>({
            path: '/report_templates',
            method: 'GET',
            headers,
            query,
        });
    };


    operationToOperationId = {
	    "reportTemplatesAdminGet": "report_templates_admin_get" ,
	    "reportTemplatesBoGet": "report_templates_bo_get" ,
	    "reportTemplatesCustomerGet": "report_templates_customer_get" ,
	    "reportTemplatesGet": "report_templates_get"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum ReportTemplatesAdminGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum ReportTemplatesBoGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum ReportTemplatesCustomerGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum ReportTemplatesGetReportClassEnum {
    Bo = 'report-bo',
    Admin = 'report-admin',
    Customer = 'report-customer'
}
/**
 * @export
 * @enum {string}
 */
export enum ReportTemplatesGetLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
