// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CountryCodeAlpha2WithNone,
} from './';

/**
 * @export
 * @interface CustomerBalance
 */
export interface CustomerBalance {
    /**
     * @type {string}
     * @memberof CustomerBalance
     */
    account_number?: string;
    /**
     * type of the account
     * @type {string}
     * @memberof CustomerBalance
     */
    account_type?: string;
    /**
     * type of customer
     * @type {string}
     * @memberof CustomerBalance
     */
    customer_type?: CustomerBalanceCustomerTypeEnum;
    /**
     * name of the account owner
     * @type {string}
     * @memberof CustomerBalance
     */
    account_holder_name?: string;
    /**
     * @type {CountryCodeAlpha2WithNone}
     * @memberof CustomerBalance
     */
    residence_country?: CountryCodeAlpha2WithNone;
    /**
     * @type {string}
     * @memberof CustomerBalance
     */
    currency?: string;
    /**
     * the accounts balance
     * @type {number}
     * @memberof CustomerBalance
     */
    balance?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum CustomerBalanceCustomerTypeEnum {
    Person = 'Person',
    Merchant = 'Merchant'
}

