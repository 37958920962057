import React from 'react';
import TransfersComponent from '../transfersComponent';
import { IItfAccount } from '../../../../logic/types';
import { INotifyAbleBackofficeData, BackofficeStore } from '../../../../logic/flux';

interface IProps {
	redirectCallback?: (path: string) => void;
}

interface IState {
	selectedAccount?: IItfAccount;
}

class BackOfficeDetails extends React.Component<IProps, IState>
	implements INotifyAbleBackofficeData {
	constructor(props: IProps) {
		super(props);

		this.state = {
			selectedAccount: BackofficeStore.getAccount(),
		};

		this._onChangeBackofficeData = this._onChangeBackofficeData.bind(this);
	}

	componentDidMount(): void {
		BackofficeStore.addChangeListener(this._onChangeBackofficeData);
		this._onChangeBackofficeData();
	}

	componentWillUnmount(): void {
		BackofficeStore.removeChangeListener(this._onChangeBackofficeData);
	}

	_onChangeBackofficeData(): void {
		this.setState({
			selectedAccount: BackofficeStore.getAccount(),
		});
	}

	render() {
		return (
			<div>
				<TransfersComponent redirectCallback={this.props.redirectCallback}/>
			</div>
		);
	}
}

export default BackOfficeDetails;
