// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Iso4217Currency,
} from './';

/**
 * @export
 * @interface DebitExternalAccountRequest
 */
export interface DebitExternalAccountRequest {
    /**
     * @type {string}
     * @memberof DebitExternalAccountRequest
     */
    account: string;
    /**
     * @type {Iso4217Currency}
     * @memberof DebitExternalAccountRequest
     */
    currency: Iso4217Currency;
    /**
     * The amount given with fractional digits, where fractions must be compliant to the currency definition. Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.  **Example:** Valid representations for EUR with up to two decimals are:    * 1056   * 5768.2   * -1.50   * 5877.78 
     * @type {string}
     * @memberof DebitExternalAccountRequest
     */
    amount: string;
    /**
     * @type {number}
     * @memberof DebitExternalAccountRequest
     */
    external_account_id?: number;
    /**
     * @type {string}
     * @memberof DebitExternalAccountRequest
     */
    payment_method?: DebitExternalAccountRequestPaymentMethodEnum;
    /**
     * if set and payment is successfull store payment information for future use
     * @type {boolean}
     * @memberof DebitExternalAccountRequest
     */
    save_external_account?: DebitExternalAccountRequestSaveExternalAccountEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum DebitExternalAccountRequestPaymentMethodEnum {
    Adyen = 'adyen',
    Stripe = 'stripe'
}
/**
 * @export
 * @enum {string}
 */
export enum DebitExternalAccountRequestSaveExternalAccountEnum {
    False = 'false'
}

