// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CardPinGetResponseType
 */
export interface CardPinGetResponseType {
    /**
     * the customers account
     * @type {string}
     * @memberof CardPinGetResponseType
     */
    account_number?: string;
    /**
     * id of the card
     * @type {number}
     * @memberof CardPinGetResponseType
     */
    card_id?: number;
    /**
     * Indicates if card pin has been set or not
     * @type {string}
     * @memberof CardPinGetResponseType
     */
    pin_status: CardPinGetResponseTypePinStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CardPinGetResponseTypePinStatusEnum {
    NotSet = 'not set',
    Set = 'set'
}

