// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * channel to be used to send a tan
 * @export
 * @interface TanRequest
 */
export interface TanRequest {
    /**
     * * P - Phone, primary phone number
     * @type {string}
     * @memberof TanRequest
     */
    channel: TanRequestChannelEnum;
    /**
     * @type {number}
     * @memberof TanRequest
     */
    release_request_id: number;
}

/**
 * @export
 * @enum {string}
 */
export enum TanRequestChannelEnum {
    P = 'P'
}

