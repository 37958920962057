//@ts-nocheck
import styled from 'styled-components';
import { FlexBox } from '../../../auth/auth.css';
import { Select } from '../../../../components/atomiccompoents/form';

export const StyledLine = styled(FlexBox)`
	margin-top: 16px;
	margin-bottom: 16px;
	border-bottom-style: solid;
	border-bottom-color: #f2f4f6;
	border-bottom-width: 1px;
	width: 100%;
	height: 1px;
	display: block;
`;

export const StyledDivHeader = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledSpanHeaderText = styled.div`
	margin-left: 16px;
	font-weight: 500;
	text-transform: uppercase;
	color: ${(props) => props.theme.Global.lightFontColor};
`;


export const StyledSelect = styled(Select)`
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: ${props => props.theme.Global.darkFontColor};
    width: 100%;
	select {
		height: 42px;
		width: 100%;
		min-width: 320px;
		max-width: 1700px;
		margin-top: 14px;
		margin-bottom: 12px;
		padding-left: 10px;

		background: ${(props) => props.theme.Select.background};
		border: ${(props) => props.theme.Select.border};
		box-shadow: ${(props) => props.theme.Select.boxShadow};
		border-radius: ${(props) => props.theme.Select.borderRadius};
	}
`;