// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * the notification process
 * @export
 * @enum {string}
 */
export enum NotificationProcess {
    SendMoney = 'send_money',
    ReceiveMoney = 'receive_money',
    RequestMoney = 'request_money',
    TakeOver = 'take_over',
    SplitMoney = 'split_money',
    Checkin = 'checkin',
    Checkout = 'checkout',
    Order = 'order'
}

