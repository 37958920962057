//@ts-nocheck
import React, { SyntheticEvent, ReactElement, ReactNode } from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import PathMap from '../pathMap';
import AuthScreen, { IAuthScreenProps, IAuthScreenState } from '../authScreen';
import { Form } from './styledComponents';
import { IAuthConfig } from './IAuthConfig';
import { VoidResponse, apis, ApiError, Ident, api } from '../../../logic/api';
import { MessageHandler } from '../../../logic/handler/messagehandler/messageHandler';
import {
	Reporter,
	IMessageConfig,
	IStatusState,
	getFormComponents,
} from '../../../logic/handler/messagehandler/messageHandlerConfig';
import { StyledAuthComponent } from './auth.css';
import { translate } from '../../../common/language/translate';
import { Input } from '../../../components/atomiccompoents/form/input';
import { BigInput } from '../../../components/atomiccompoents/form/inputs.css';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';
import { FlexBox } from '../auth.css';
import { evaluateErrorMessage } from '../../../logic/helper/Common';

const queryString = require('query-string');

interface IProps extends IAuthScreenProps {
	email?: string;
}

interface IState extends IAuthScreenState, IStatusState {
	redirect?: ReactElement;
	email: string;
	token: string;
	styled: ReactNode;
	keyForLoadingSpinner?: number;
}

class VerifyComponentClass extends AuthScreen<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		const parsedUri = queryString.parse(window.location.search);
		const typeState = props.location.state as any | {};
		this.state = {
			redirect: undefined,
			email: parsedUri.email,
			token: parsedUri.token,
			styled:
				props.location != null &&
				props.location.state != null &&
				'styled' in typeState &&
				typeState['styled']  != null ? (
					typeState['styled'] 
				) : (
					<FlexBox />
				),
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
		};
		this.cancel = this.cancel.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		if (this.state.email != null && this.state.token != null) {
			this.onSubmit();
		}
	}

	onSubmit(event?: SyntheticEvent | React.KeyboardEvent<HTMLFormElement>) {
		if (event != null) {
			event.preventDefault();
		}

		const emailValid: boolean = Input.validateEmail(this.state.email);

		if (!emailValid && (this.state.token == null || this.state.token === '')) {
			const conf: IMessageConfig = MessageHandler.onError(
				Reporter['web.auth.verify.post.missing_params']
			);
			this.setState({
				errorMessage: translate('auth.verify.input.error'),
				showInlineError: conf.errorMethods.inline === true,
				key: conf.translationKey != null ? conf.translationKey + '.error' : '',
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			});
		} else if (!emailValid) {
			const conf: IMessageConfig = MessageHandler.onError(
				Reporter['web.auth.verify.post.missing_params']
			);
			this.setState({
				errorMessage: translate('auth.verify.input.email.error'),
				showInlineError: conf.errorMethods.inline === true,
				key: conf.translationKey != null ? conf.translationKey + '.error' : '',
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			});
		} else if (this.state.token == null || this.state.token.trim() === '') {
			const conf: IMessageConfig = MessageHandler.onError(
				Reporter['web.auth.verify.post.missing_params']
			);
			this.setState({
				errorMessage: translate('auth.verify.input.code.error'),
				showInlineError: conf.errorMethods.inline === true,
				key: conf.translationKey != null ? conf.translationKey + '.error' : '',
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			});
		} else {
			const request: Ident.OauthVerifyPostRequest = {
				AuthRequestWithVerificationCode: {
					idp_state: this.idpState,
					username: this.state.email,
					verification_code: this.state.token,
				},
			};
			api.asyncRequest<VoidResponse | Ident.AuthParams>(
				request,
				apis.OpenIDConnectApi,
				'oauthVerifyPost'
			)
				.then((response) => {
					this.setState({
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
					const conf = MessageHandler.onSuccess(Reporter['web.auth.verify.post']);
					if (!(response instanceof VoidResponse)) {
						this.idpState = response.idp_state;
						const component: Ident.AuthConnection = PathMap.getPath(
							response.connection[0]
						);
						const currentAuthConfig:
							| IAuthConfig
							| undefined = this.getAuthConfigForConnection(component);
						if (currentAuthConfig != null) {
							this.setState({
								redirect: (
									<Redirect
										to={{
											pathname: currentAuthConfig.uri,
											state: { idpState: this.idpState },
										}}
									/>
								),
								showInlineSuccess:
									conf.successMethods != null && conf.successMethods.inline,
								successMessage:
									conf.successMessage != null ? conf.successMessage : '',
								key:
									conf.translationKey != null
										? conf.translationKey + '.success'
										: '',
								keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
							});
						}
					} else {
						const conf: IMessageConfig = MessageHandler.onError(
							Reporter['general.error.message']
						);
						this.setState({
							errorMessage: conf.errorMessage != null ? conf.errorMessage : '',
							showInlineError: conf.errorMethods.inline === true,
							key:
								conf.translationKey != null
									? conf.translationKey + '.error'
									: '',
							keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
						});
					}
				})
				.catch((error: ApiError) => {
					const conf: IMessageConfig = MessageHandler.onError(
                        Reporter['web.auth.verify.post'],
                        evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
					);
					if (error.response != null && error.response.idp_state != null) {
						this.idpState = error.response.idp_state;
					}
					this.setState({
						errorMessage:
							conf.errorMessage != null ? conf.errorMessage : error.statusText,
						showInlineError: conf.errorMethods.inline === true,
						key: conf.translationKey != null ? conf.translationKey + '.error' : '',
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
				});
		}
	}

	cancel(event: SyntheticEvent) {
		event.preventDefault();
		this.setState({ redirect: <Redirect to="/" /> });
	}

	tokenChanged(token: string) {
		this.setState({ token: token });
	}

	emailChanged(email: string) {
		this.setState({ email: email });
	}

	onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
		// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			this.onSubmit(event);
		}
	};

	defineContent(): ReactNode {
		return null;
	}

	reviveState(): void {}

	render() {
		if (this.state.redirect != null) {
			return this.state.redirect;
		}

		return (
			<StyledAuthComponent>
				<div>{translate(this.getTitle())}</div>
				<div>{translate(this.getSubtitle() as string)}</div>
				<Form onSubmit={this.onSubmit} onKeyDown={this.onKeyDown}>
					<BigInput
						label={translate('auth.verify.input.email.label')}
						placeHolder={translate('auth.verify.input.email.placeholder')}
						id="email"
						onChange={(email: string) => this.emailChanged(email)}
						onSubmit={this.onSubmit}
					/>
					<BigInput
						label={translate('auth.verify.input.code.label')}
						placeHolder={translate('auth.verify.input.code.placeholder')}
						id="token"
						onChange={(token: string) => this.tokenChanged(token)}
						onSubmit={this.onSubmit}
					/>
					{getFormComponents(
						this.state.showInlineError,
						this.state.showInlineSuccess,
						this.state.errorMessage,
						this.state.successMessage,
						this.state.key
					)}
					<ButtonOk
						key={this.state.keyForLoadingSpinner}
						id="verifyComponentButtonOk"
						style={{ width: '100%' }}
						onClick={this.onSubmit}>
						{translate('button.next')}
					</ButtonOk>
					<FlexBox>
						<Link to="/" id="cancel">
							{translate('button.cancel')}
						</Link>
					</FlexBox>
				</Form>
			</StyledAuthComponent>
		);
	}
}

export const VerifyComponent = withRouter(VerifyComponentClass);
