import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { api, apis, Account, Ident } from '../../../../logic/api';
import {
	IInitProps,
	IInitState,
	InitialDataComponent,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import {
	IStatusState,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import {
    KeyField,
    TitleCell,
    ValueField,
} from './basicStyledComponents/customerDetails.css';
import { IItfAccount } from '../../../../logic/types';
import AccountSelector from '../../../../components/accountSelector';
import { Icons } from '../../../../images';
import { CardAgreementCard } from '../../../../logic/api/account';
import { Log, Logs } from '../../../../logic/log';
import { withPersonAndAccount } from '../../../../components/hocs/withPersonAndAccount';


interface IProps extends IInitProps {
	person?: Ident.Person;
    currentAccount?: IItfAccount;
}
interface IState extends IStatusState, IInitState {
    cardData?: Array<Account.CardAgreement>,
    openedFields: {[key: number]: boolean},
    additionalStates: {[key: number]: Account.CardState}
}

const IconWrapper = styled.div`
    width: 26px;
    height: 24px;
    svg {
        width: 36px;
        height: 24px;
    }
`;
const DoubleKeyValueBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 50%;
    border-bottom: 1px solid #DEDEDE;
    padding: ${(props) => props.theme.Table.TBody.Td.padding};
    margin: 8px;
    padding: 8px;
`;
const MultipleKeyValueBlock = styled(DoubleKeyValueBlock)`
    width: 25%;
`;
const InnerKeyValueBlock = styled(MultipleKeyValueBlock)`
    border-bottom: none;
`;
const StyledViewMain = styled(FlexBox)`
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
    height: 100%;
    width: 100%;
`;
const StyledContentBox = styled(FlexBox)`
	flex-direction: column;
    padding: 0px 32px;
    align-items: center;
`;

const InnerBlock = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #EEEEEE;
    width: 100%;
    margin-bottom: 16px;
`;
class CustomerDetailsCardBox extends InitialDataComponent<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
            openedFields: {},
            additionalStates: {}
		};
        this.getCardsData();
	}
    
    componentDidUpdate(prevProps: IProps) {
		if (prevProps.currentAccount?.account_number !== this.props.currentAccount?.account_number ) {
		  this.getCardsData();
		}	  
	  }
    
    getCardsData() {
        if(this.props.currentAccount != null) {
            const req: Account.AccountAccountNumberCardAgreementGetRequest = {
                account_number: this.props.currentAccount.account_number
            }

            api.asyncRequest<Array<Account.CardAgreement>>(
                req,
                apis.DefaultApi,
                'accountAccountNumberCardAgreementGet')
                .then((response: Array<Account.CardAgreement>) => {
                    const cardState: {[key: number]: Account.CardState} = {};
                    for(const o of response) {
                       /* if(this.props.currentAccount != null && o.card != null && false) {
                            for (const c of o.card) {
                                this.getCardsState(this.props.currentAccount.account_number, c.card_id ).then((state: Account.CardState) => {
                                    cardState[c.card_id] = state;
                                })
                            }
                        }*/
                    }
                    this.setState({
                        cardData: response != null && response.length > 0 ? response : [],
                        additionalStates: cardState
                    })
    
                }).catch( (error) => {
                    Log.error(Logs.API, error.status_text, error);
                    
                })
        }
     }

     getCardsState(acc_nr: string, cardID: number) {
         const req: Account.AccountAccountNumberCardAgreementCardCardIdStateGetRequest = {
             account_number: acc_nr,
             card_id: cardID
         };
         return api.asyncRequest<Account.CardState>(
             req,
             apis.DefaultApi,
             'accountAccountNumberCardAgreementCardCardIdStateGet'
         )
     }

     displayCardData(card: Account.CardAgreement, idx: number) {
        return( 
            <div key={card.card_agreement_id} style={{display: 'flex', flexDirection: 'column', width: '100%', padding: '32px', alignItems:'center'}}>
                <TitleCell style={{width: '100%'}}>
                    {translate('customers.cardTab.customerCard')  + " " +  idx }
                </TitleCell>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}} >
                    <div onClick={() => {
                        const fields = this.state.openedFields;
                        if(fields[card.card_agreement_id] !== true) {
                            fields[card.card_agreement_id] = true;
                        } else {
                            delete fields[card.card_agreement_id];
                        }
                        this.setState({
                            openedFields: fields,
                        })
                }}>{this.state.openedFields[card.card_agreement_id] !== true ?  Icons.arrowFacingDown() : Icons.arrowFacingUp() }</div>
                    <MultipleKeyValueBlock>                  
                        <KeyField>{translate('customers.cardTab.provider', 'Provider') } </KeyField>
                        <ValueField>
                            <IconWrapper>
                                {Icons.creditCardType(card.card_provider)}
                            </IconWrapper>
                        </ValueField>
                    </MultipleKeyValueBlock>
                    <MultipleKeyValueBlock>                  
                        <KeyField>{translate('customers.cardTab.id', 'ID') } </KeyField>
                        <ValueField>{card.card_agreement_id}</ValueField>
                    </MultipleKeyValueBlock>
                    <MultipleKeyValueBlock>                  
                        <KeyField>{translate('customers.cardTab.state', 'State') } </KeyField>
                        <ValueField>{translate('customers.cardTab.states.' + card.state)}</ValueField>
                    </MultipleKeyValueBlock>
                    <MultipleKeyValueBlock>                  
                        <KeyField>{translate('customers.cardTab.type', 'Type') } </KeyField>
                        <ValueField>{translate('customers.cardTab.types.' + card.card_type)}</ValueField>
                    </MultipleKeyValueBlock>
                </div>
                {this.state.openedFields[card.card_agreement_id] === true  && card.card != null ? 
                <React.Fragment>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginLeft: '31px', width: '100%'}}>
                            <DoubleKeyValueBlock style={{marginBottom: '16px'}}>                  
                                <KeyField>{translate('customers.cardTab.custNumBpc', 'BPC-Customer Number') } </KeyField>
                                <ValueField>{card.bpc_customer_number}</ValueField>
                            </DoubleKeyValueBlock>
                            <DoubleKeyValueBlock style={{marginBottom: '16px'}}>                   
                                <KeyField>{translate('customers.cardTab.accNumBpc', 'BPC-Account Number') } </KeyField>
                                <ValueField>{card.bpc_account_number}</ValueField>
                            </DoubleKeyValueBlock>
                    </div>
                    <div style={{marginLeft: '62px', width: '100%'}}> { this.renderCardView(card.card) } </div>
                </React.Fragment>
                : null }
            </div>

        )
     }

     renderCardView(cards: Array<CardAgreementCard>) {
         const out: Array<ReactElement> = [];
         for(const i in cards) {
             out.push(
                 <InnerBlock>
                    <TitleCell style={{width: '100%', fontSize: '14px', display: 'flex', flexDirection: 'row', lineHeight: '24px'}}>
                        <IconWrapper style={{marginRight: '8px'}}> {Icons.card() } </IconWrapper>{translate('customers.cardTab.card') + " " + (parseInt(i) + 1) }
                    </TitleCell> 
                    <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'center'}}>
                        <InnerKeyValueBlock>                  
                            <KeyField>{translate('customers.cardTab.cardId', 'Card ID') } </KeyField>
                            <ValueField>{cards[i].card_id}</ValueField>
                        </InnerKeyValueBlock>
                        <InnerKeyValueBlock>                  
                            <KeyField>{translate('customers.cardTab.color', 'Color') } </KeyField>
                            <ValueField>{cards[i].card_color}</ValueField>
                        </InnerKeyValueBlock>
                        <InnerKeyValueBlock>                  
                            <KeyField>{translate('customers.cardTab.expiry', 'Expiry') } </KeyField>
                            <ValueField>{cards[i].expiry}</ValueField>
                        </InnerKeyValueBlock>
                        <InnerKeyValueBlock>                  
                            <KeyField>{translate('customers.cardTab.name', 'Name') } </KeyField>
                            <ValueField>{cards[i].name}</ValueField>
                        </InnerKeyValueBlock>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'center'}}>
                        <InnerKeyValueBlock>                  
                            <KeyField>{translate('customers.cardTab.state', 'State ID') } </KeyField>
                            <ValueField>{translate('customers.cardTab.states.' + cards[i].state)}</ValueField>
                        </InnerKeyValueBlock>
                        <InnerKeyValueBlock>                  
                            <KeyField>{translate('customers.cardTab.bpsState', 'State BPC-State') } </KeyField>
                            <ValueField>{this.state.additionalStates[cards[i].card_id]}</ValueField>
                        </InnerKeyValueBlock>
                        <InnerKeyValueBlock>                  
                            <KeyField>{translate('customers.cardTab.barCode', 'Bar Code') } </KeyField>
                            <ValueField>{cards[i].bar_code}</ValueField>
                        </InnerKeyValueBlock>
                        <InnerKeyValueBlock>                  
                            <KeyField>{translate('customers.cardTab.bpcCardId', 'BPC Card ID') } </KeyField>
                            <ValueField>{cards[i].bpc_card_id}</ValueField>
                        </InnerKeyValueBlock>
                        <InnerKeyValueBlock>                  
                            <KeyField>{translate('customers.cardTab.virtOrPhys', 'Virtual/Physical') } </KeyField>
                            <ValueField>{translate('customers.cardTab.virtOrPhyType.' + cards[i].virtual_or_physical)}</ValueField>
                        </InnerKeyValueBlock>
                    </div>
                </InnerBlock>
             )}
        return out;
     }


	reviveState(newState: { [key: string]: any }): void {
	}


    generateData() {
        const out: Array<ReactElement> = [];
        if(this.state.cardData != null) {
            for (const i in this.state.cardData) {
                out.push(this.displayCardData(this.state.cardData[i], parseInt(i) + 1))
            }
        }
        return out;
    }

	render() {
		return (
			<StyledContentBox>
                <AccountSelector />
				<StyledViewMain>
                    {this.state.cardData != null && this.state.cardData.length > 0 ? this.generateData() : 'Nothing to show'}
				</StyledViewMain>
			</StyledContentBox>
		);
	}
}

export default withPersonAndAccount(CustomerDetailsCardBox);
