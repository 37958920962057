// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface DebitCardApplication
 */
export interface DebitCardApplication {
    /**
     * @type {string}
     * @memberof DebitCardApplication
     */
    account_number?: string;
    /**
     * values > * A - Active * L - Locked * C - Closed
     * @type {string}
     * @memberof DebitCardApplication
     */
    account_state?: DebitCardApplicationAccountStateEnum;
    /**
     * name of the external account owner
     * @type {string}
     * @memberof DebitCardApplication
     */
    name?: string;
    /**
     * given name of the external account owner
     * @type {string}
     * @memberof DebitCardApplication
     */
    given_name?: string;
    /**
     * The card is of type Debit card
     * @type {string}
     * @memberof DebitCardApplication
     */
    card_type?: DebitCardApplicationCardTypeEnum;
    /**
     * Provider of the debit card
     * @type {string}
     * @memberof DebitCardApplication
     */
    card_provider?: DebitCardApplicationCardProviderEnum;
    /**
     * @type {string}
     * @memberof DebitCardApplication
     */
    bpc_card_id?: string;
    /**
     * date at which card was applied for
     * @type {Date}
     * @memberof DebitCardApplication
     */
    application_date?: Date;
    /**
     * values > * P - Pending * A - Active * D - Deleted
     * @type {string}
     * @memberof DebitCardApplication
     */
    card_state?: DebitCardApplicationCardStateEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum DebitCardApplicationAccountStateEnum {
    A = 'A',
    L = 'L',
    C = 'C'
}
/**
 * @export
 * @enum {string}
 */
export enum DebitCardApplicationCardTypeEnum {
    DebitCard = 'debit card',
    CreditCard = 'credit card'
}
/**
 * @export
 * @enum {string}
 */
export enum DebitCardApplicationCardProviderEnum {
    Visa = 'Visa',
    Mastercard = 'Mastercard',
    CurrentInstitution = 'Current Institution'
}
/**
 * @export
 * @enum {string}
 */
export enum DebitCardApplicationCardStateEnum {
    P = 'P',
    A = 'A',
    D = 'D'
}

