import React, { ReactElement } from 'react';
import styled from 'styled-components';
import {
	IStatusState,
	Reporter,
} from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import {
    api, 
    apis, 
    ApiError, 
    Account,
    VoidResponse
} from '../../../../../logic/api';
import {translate} from '../../../../../common/language/translate';
import {
	IStateOver,
	IPropsOver,
	AOverlay,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import {
	IInitProps,
	IInitState,
} from '../../../../../logic/handler/initialdatahandler/initialDataComponent';
import Title from '../../../../../components/compositcomponents/title';
import { Log, Logs } from '../../../../../logic/log';
import { KeyField, KeyValueBlock, ValueField } from '../basicStyledComponents/customerDetails.css';




const StyledOverlay = styled.div`
	margin: auto;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 50px;
	padding-top: 20;
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 550px;
	align-items: center;
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	background-color: white;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	overflow-y: auto;
`;



interface IProps extends IInitProps, IPropsOver {
    accountNr: string;
}
interface IState extends IStatusState, IInitState, IStateOver {
    data?: Array<Account.ProductType>
}
/*const DummyData : Array<Account.ProductType> = [
    {
        producer: 'Bosch',
        description: 'A123123',
        product_type_id: 1
    },
    {
        producer: 'Semmelsau',
        description: 'B1231',
        product_type_id: 2
    },
    {
        producer: 'HUUURE',
        description: 'A123ASDASDA123',
        product_type_id: 5
    },
];*/

export default class ProductTypeOverlay extends AOverlay<IProps, IState> {
    
    constructor(props: IProps) {
        super(props);
       
    	this.state = {
            
          
        };
        this.getData();
       
    }
    getData() {
        const req: Account.MerchantProductTypeGetRequest = {
            merchant_account_number: this.props.accountNr
        }
        api.asyncRequest<Array<Account.ProductType>>(
            req,
            apis.MerchantApi,
            'merchantProductTypeGet'
        ).then( (response: Array<Account.ProductType>) => {
            this.setState({
                data: response
            })
        }).catch((error: ApiError) => {
            Log.error(Logs.API, error);
        })
    }

    generateList(): Array<ReactElement> {
        const out: Array<ReactElement> = [];
        if(this.state.data == null || this.state.data.length == null || this.state.data.length === 0) {
            out.push(
                <ListEntry>
                    Nothing found
                </ListEntry>
            )
            return out;
        }
        for(const o of this.state.data) {
            out.push(
                <ListEntry>
                    <KeyValueBlock style={{alignItems: 'center', justifyContent: 'center'}}>
                        <ValueField>
                            {o.product_type_id}
                        </ValueField>
                    </KeyValueBlock>
                    <KeyValueBlock>
                        <KeyField>
                            {translate('customers.products.producer')}
                        </KeyField>
                        <ValueField>
                            {o.producer}
                        </ValueField>
                    </KeyValueBlock>
                    <KeyValueBlock>
                        <KeyField>
                            {translate('customers.products.signature')}
                        </KeyField>
                        <ValueField>
                            {o.description}
                        </ValueField>
                    </KeyValueBlock>
                </ListEntry>
            )
        }
        return out;
    }
    reviveState() {

    }
    defineContent() {
    	return (
    		<StyledOverlay onClick={event => event.stopPropagation()}>
                <Title title={translate('customers.products.types')} />
                <ProductsBox>
                    { this.generateList()}
                </ProductsBox>
    		</StyledOverlay>
    	);
    }
}
const ProductsBox = styled.div`
    width: 95%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`;
const ListEntry = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;
