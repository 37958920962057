//@ts-nocheck
import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Icons } from '../../../images';
import { IRelativeProps, IRelativeState, RelativeOverlay } from '../../../logic/handler/overlayhandler/globaloverlays/aRelativeOverlay';
import { format } from '../../../logic/helper/format';
import { Account } from '../../../logic/api';

export interface IPopMenuData {
    display: string | ReactElement,
    passValue: any,
    identifier?: any,
    specialCase?: boolean
}

interface IProps extends IRelativeProps {
	callback: (id: T) => void;
	width?: string;
	exceptions?: Array<string> | Array<number>;
    data: Array<IPopMenuData>;
}
//ToDo Use this as ImageChooseroverlay, they are very similar
interface IState extends IRelativeState {}

export default class PopupMenuComponent extends RelativeOverlay<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {};
	}

	reviveState() {}

	private selectElement(event: SyntheticEvent, id: any): void {
		event.preventDefault();
		event.stopPropagation();

		if (this.props.callback != null && typeof this.props.callback === 'function') {
			this.props.callback(id);
		}

		if (this.props.selfClose != null && typeof this.props.selfClose === 'function') {
			this.props.selfClose();
		}
    }
    
    displayOptions() {
        const out = [];
        for (const i in this.props.data) {
            if(this.props.data[i].passValue != null && this.props.data[i].display != null ) {
                if(this.props.exceptions != null && this.props.exceptions.indexOf(this.props.data[i].identifier) > -1) {
                    continue;
                }
            out.push(
                <FileSelector  width={this.props.width} special={this.props.data[i].specialCase } onClick={(event) => this.selectElement(event, this.props.data[i].passValue)}>
                    <div style={{marginTop: '5px' }}>{this.props.data[i].display}</div>
                </FileSelector>
                )
            }
        }
        return out;
    }

	defineContent(): ReactNode {
		return (
			<Container x={this.props.posX} y={this.props.posY}>
                {this.displayOptions()}
			</Container>
		);
	}
}

const Container = styled.div<{ x: string; y: string }>`
	position: absolute;
	top: ${(props) =>  props.y + 'px'};
	left: ${(props) => props.x + 'px'};
	background-color: rgba(0,0,0,0);
	display: flex;
	z-index: 5000;
	flex-direction: column;
	max-height: calc(100vh - 200px);
	overflow-y: auto;
`;

const FileSelector = styled.div<{ width?: string; special?: boolean}>`
	width:  ${(props) => props.width != null ? props.width : '100%'};
	text-align: center;
	line-height: 40px;
	background-color: ${(props) => props.special === true ? '#EEEEEE' : '#FFFFFF' };
	border-radius: 5px;
	border: 1px solid  ${(props) => (props.theme.Button.backgroundColor)};
	height:40px;
	display: flex;
    justify-content: center;
    font-size: 14px;
	margin: 2px;
	svg {
		fill: ${(props) => (props.theme.Global.lightFontColor)};
	}
	:hover {
		background-color: rgb(189, 190, 192);
		cursor: pointer;
	}
	-webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 0.5s; /* Firefox < 16 */
	-ms-animation: fadein 0.5s; /* Internet Explorer */
	-o-animation: fadein 0.5s; /* Opera < 12.1 */
	animation: fadein 0.5s;
	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	
	/* Firefox < 16 */
	@-moz-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	
	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	
	/* Internet Explorer */
	@-ms-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	
	/* Opera < 12.1 */
	@-o-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
`;

