// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    AccountFeePackageList,
    AccountOpeningWithIBANList,
    AccountOpeningsList,
    AccountUpgradeList,
    ActivatedFeePaackageList,
    BusinessAccountDetails,
    CardAuthorizationList,
    CheckOutList,
    CountryCodeAlpha2WithNone,
    CustomerBalanceList,
    DebitCardApplicationList,
    ExternalAccountAndCardList,
    InternalAccountList,
    IssuedCardList,
    MastercardClearingTransactionList,
    MerchantDailyMonthlyList,
    MerchantList,
    PositiveCustomerList,
    ProductOrderStornoList,
    RegisteredCustomerList,
    ReportResponseType,
    SegmentTransactionSumsList,
    SystemTransactionList,
} from '../models';

export interface Rpt001BalanceListRequest {
    as_of_date: Date;
    balance_type: Rpt001BalanceListBalanceTypeEnum;
    response_type?: ReportResponseType;
}

export interface Rpt002AccountListRequest {
    date_from: Date;
    date_to: Date;
    response_type?: ReportResponseType;
}

export interface Rpt004005AccountOpeningListNdpayRequest {
    partner: Rpt004005AccountOpeningListNdpayPartnerEnum;
    response_type?: ReportResponseType;
}

export interface Rpt004005AccountOpeningListOneforRequest {
    partner: Rpt004005AccountOpeningListOneforPartnerEnum;
    response_type?: ReportResponseType;
}

export interface Rpt006ExternalAccountsRequest {
    date_from: Date;
    date_to: Date;
    response_type?: ReportResponseType;
}

export interface Rpt007DebitCardApplicationsRequest {
    date_from: Date;
    date_to: Date;
    response_type?: ReportResponseType;
}

export interface Rpt009CustomerBalanceListRequest {
    as_of_date: Date;
    account_type?: Rpt009CustomerBalanceListAccountTypeEnum;
    customer_type?: Rpt009CustomerBalanceListCustomerTypeEnum;
    residence_country?: CountryCodeAlpha2WithNone;
    response_type?: ReportResponseType;
}

export interface Rpt011MerchantListRequest {
    date_from: Date;
    date_to: Date;
    response_type?: ReportResponseType;
}

export interface Rpt012RegisteredCustomerListRequest {
    date_from: Date;
    date_to: Date;
    response_type?: ReportResponseType;
}

export interface Rpt014CheckoutListRequest {
    date_from: Date;
    date_to: Date;
    response_type?: ReportResponseType;
}

export interface Rpt015AccountUpgradeListRequest {
    date_from: Date;
    date_to: Date;
    segment: Rpt015AccountUpgradeListSegmentEnum;
    response_type?: ReportResponseType;
}

export interface Rpt016SystemTransactionListRequest {
    date_from: Date;
    date_to: Date;
    country?: CountryCodeAlpha2WithNone;
    amount?: number;
    response_type?: ReportResponseType;
}

export interface Rpt019CustomerOverviewPositiveListRequest {
    date_from: Date;
    date_to: Date;
    segment: Rpt019CustomerOverviewPositiveListSegmentEnum;
    response_type?: ReportResponseType;
}

export interface Rpt021ProductOrderStornoListRequest {
    date_from: Date;
    date_to: Date;
    response_type?: ReportResponseType;
}

export interface Rpt022SegmentTransactionSumsListRequest {
    date_from: Date;
    date_to: Date;
    segment: Rpt022SegmentTransactionSumsListSegmentEnum;
    filter_by: Rpt022SegmentTransactionSumsListFilterByEnum;
    response_type?: ReportResponseType;
}

export interface Rpt023MastercardClearingListRequest {
    date_from: Date;
    date_to: Date;
    response_type?: ReportResponseType;
}

export interface Rpt026InternalAccountListRequest {
    response_type?: ReportResponseType;
}

export interface Rpt027IssuedCardListRequest {
    date_from: Date;
    date_to: Date;
    response_type?: ReportResponseType;
}

export interface Rpt030AccountFeePackageListRequest {
    date_from: Date;
    date_to: Date;
    response_type?: ReportResponseType;
}

export interface Rpt031AccountOpeningListRequest {
    date_from: Date;
    date_to: Date;
    filter_by: Rpt031AccountOpeningListFilterByEnum;
    response_type?: ReportResponseType;
}

export interface Rpt032CardAuthorizationListRequest {
    date_from: Date;
    date_to: Date;
    amount?: string;
    region?: Rpt032CardAuthorizationListRegionEnum;
    response_type?: ReportResponseType;
}

export interface Rpt033FeePackageListRequest {
    date_from: Date;
    date_to: Date;
    response_type?: ReportResponseType;
}

export interface Rpt034MerchantDailyMonthlyListRequest {
    date_from: Date;
    date_to: Date;
    save_history?: boolean;
    response_type?: ReportResponseType;
}

export interface Rpt035BusinessAccountDetailsRequest {
    segment: Rpt035BusinessAccountDetailsSegmentEnum;
    response_type?: ReportResponseType;
}

/**
 * no description
 */
export class ReportBoApi extends BaseAPI implements IOperationIdMap {

    /**
     * list all balances for all accounts
     */
    rpt001BalanceList = (requestParameters: Rpt001BalanceListRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'as_of_date', 'rpt001BalanceList');
        throwIfRequired(requestParameters, 'balance_type', 'rpt001BalanceList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.as_of_date && { 'as_of_date': (requestParameters.as_of_date as any).toISOString().substring(0, 10) }),
            ...(requestParameters.balance_type && { 'balance_type': requestParameters.balance_type }),
        };

        return this.request<void>({
            path: '/report/bo/001_balance_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list all accounts
     */
    rpt002AccountList = (requestParameters: Rpt002AccountListRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt002AccountList');
        throwIfRequired(requestParameters, 'date_to', 'rpt002AccountList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<void>({
            path: '/report/bo/002_account_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * rpt004 (30 day survey) and rpt005 (annual survey)
     */
    rpt004005AccountOpeningListNdpay = (requestParameters: Rpt004005AccountOpeningListNdpayRequest): Observable<AccountOpeningsList> => {
        throwIfRequired(requestParameters, 'partner', 'rpt004005AccountOpeningListNdpay');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.partner && { 'partner': requestParameters.partner }),
        };

        return this.request<AccountOpeningsList>({
            path: '/report/bo/004_005_account_opening_list_ndpay',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * rpt004 (30 day survey) and rpt005 (annual survey)
     */
    rpt004005AccountOpeningListOnefor = (requestParameters: Rpt004005AccountOpeningListOneforRequest): Observable<AccountOpeningsList> => {
        throwIfRequired(requestParameters, 'partner', 'rpt004005AccountOpeningListOnefor');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.partner && { 'partner': requestParameters.partner }),
        };

        return this.request<AccountOpeningsList>({
            path: '/report/bo/004_005_account_opening_list_onefor',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list all external accounts
     */
    rpt006ExternalAccounts = (requestParameters: Rpt006ExternalAccountsRequest): Observable<ExternalAccountAndCardList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt006ExternalAccounts');
        throwIfRequired(requestParameters, 'date_to', 'rpt006ExternalAccounts');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<ExternalAccountAndCardList>({
            path: '/report/bo/006_external_accounts',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list all debit card applications
     */
    rpt007DebitCardApplications = (requestParameters: Rpt007DebitCardApplicationsRequest): Observable<DebitCardApplicationList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt007DebitCardApplications');
        throwIfRequired(requestParameters, 'date_to', 'rpt007DebitCardApplications');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<DebitCardApplicationList>({
            path: '/report/bo/007_debit_card_applications',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list of all customer balances
     */
    rpt009CustomerBalanceList = (requestParameters: Rpt009CustomerBalanceListRequest): Observable<CustomerBalanceList> => {
        throwIfRequired(requestParameters, 'as_of_date', 'rpt009CustomerBalanceList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.as_of_date && { 'as_of_date': (requestParameters.as_of_date as any).toISOString().substring(0, 10) }),
            ...(requestParameters.account_type && { 'account_type': requestParameters.account_type }),
            ...(requestParameters.customer_type && { 'customer_type': requestParameters.customer_type }),
            ...(requestParameters.residence_country && { 'residence_country': requestParameters.residence_country }),
        };

        return this.request<CustomerBalanceList>({
            path: '/report/bo/009_customer_balance_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list all merchants
     */
    rpt011MerchantList = (requestParameters: Rpt011MerchantListRequest): Observable<MerchantList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt011MerchantList');
        throwIfRequired(requestParameters, 'date_to', 'rpt011MerchantList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<MerchantList>({
            path: '/report/bo/011_merchant_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list all registered customers
     */
    rpt012RegisteredCustomerList = (requestParameters: Rpt012RegisteredCustomerListRequest): Observable<RegisteredCustomerList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt012RegisteredCustomerList');
        throwIfRequired(requestParameters, 'date_to', 'rpt012RegisteredCustomerList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<RegisteredCustomerList>({
            path: '/report/bo/012_registered_customer_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * List of check-outs performed by registered cutomer vs. other check-outs
     */
    rpt014CheckoutList = (requestParameters: Rpt014CheckoutListRequest): Observable<CheckOutList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt014CheckoutList');
        throwIfRequired(requestParameters, 'date_to', 'rpt014CheckoutList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<CheckOutList>({
            path: '/report/bo/014_checkout_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * List of opened accounts
     */
    rpt015AccountUpgradeList = (requestParameters: Rpt015AccountUpgradeListRequest): Observable<AccountUpgradeList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt015AccountUpgradeList');
        throwIfRequired(requestParameters, 'date_to', 'rpt015AccountUpgradeList');
        throwIfRequired(requestParameters, 'segment', 'rpt015AccountUpgradeList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
            ...(requestParameters.segment && { 'segment': requestParameters.segment }),
        };

        return this.request<AccountUpgradeList>({
            path: '/report/bo/015_account_upgrade_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * All transactions in the system between two dates
     */
    rpt016SystemTransactionList = (requestParameters: Rpt016SystemTransactionListRequest): Observable<SystemTransactionList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt016SystemTransactionList');
        throwIfRequired(requestParameters, 'date_to', 'rpt016SystemTransactionList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
            ...(requestParameters.country && { 'country': requestParameters.country }),
            ...(requestParameters.amount && { 'amount': requestParameters.amount }),
        };

        return this.request<SystemTransactionList>({
            path: '/report/bo/016_system_transaction_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * All booked appointments with positive test result
     */
    rpt019CustomerOverviewPositiveList = (requestParameters: Rpt019CustomerOverviewPositiveListRequest): Observable<PositiveCustomerList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt019CustomerOverviewPositiveList');
        throwIfRequired(requestParameters, 'date_to', 'rpt019CustomerOverviewPositiveList');
        throwIfRequired(requestParameters, 'segment', 'rpt019CustomerOverviewPositiveList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
            ...(requestParameters.segment && { 'segment': requestParameters.segment }),
        };

        return this.request<PositiveCustomerList>({
            path: '/report/bo/019_customer_overview_positive_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * All cancelled product orders
     */
    rpt021ProductOrderStornoList = (requestParameters: Rpt021ProductOrderStornoListRequest): Observable<ProductOrderStornoList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt021ProductOrderStornoList');
        throwIfRequired(requestParameters, 'date_to', 'rpt021ProductOrderStornoList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<ProductOrderStornoList>({
            path: '/report/bo/021_product_order_storno_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Sums of product orders per merchant
     */
    rpt022SegmentTransactionSumsList = (requestParameters: Rpt022SegmentTransactionSumsListRequest): Observable<SegmentTransactionSumsList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt022SegmentTransactionSumsList');
        throwIfRequired(requestParameters, 'date_to', 'rpt022SegmentTransactionSumsList');
        throwIfRequired(requestParameters, 'segment', 'rpt022SegmentTransactionSumsList');
        throwIfRequired(requestParameters, 'filter_by', 'rpt022SegmentTransactionSumsList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
            ...(requestParameters.segment && { 'segment': requestParameters.segment }),
            ...(requestParameters.filter_by && { 'filter_by': requestParameters.filter_by }),
        };

        return this.request<SegmentTransactionSumsList>({
            path: '/report/bo/022_segment_transaction_sums_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * mastercard clearing transactions
     */
    rpt023MastercardClearingList = (requestParameters: Rpt023MastercardClearingListRequest): Observable<MastercardClearingTransactionList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt023MastercardClearingList');
        throwIfRequired(requestParameters, 'date_to', 'rpt023MastercardClearingList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<MastercardClearingTransactionList>({
            path: '/report/bo/023_mastercard_clearing_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list all internal accounts
     */
    rpt026InternalAccountList = (requestParameters: Rpt026InternalAccountListRequest): Observable<InternalAccountList> => {

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<InternalAccountList>({
            path: '/report/bo/026_internal_account_list',
            method: 'GET',
            headers,
        });
    };

    /**
     * list all issued cards
     */
    rpt027IssuedCardList = (requestParameters: Rpt027IssuedCardListRequest): Observable<IssuedCardList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt027IssuedCardList');
        throwIfRequired(requestParameters, 'date_to', 'rpt027IssuedCardList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<IssuedCardList>({
            path: '/report/bo/027_issued_card_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list all fee packages for accounts
     */
    rpt030AccountFeePackageList = (requestParameters: Rpt030AccountFeePackageListRequest): Observable<AccountFeePackageList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt030AccountFeePackageList');
        throwIfRequired(requestParameters, 'date_to', 'rpt030AccountFeePackageList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<AccountFeePackageList>({
            path: '/report/bo/030_account_fee_package_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list all accounts opened in the selected period
     */
    rpt031AccountOpeningList = (requestParameters: Rpt031AccountOpeningListRequest): Observable<AccountOpeningWithIBANList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt031AccountOpeningList');
        throwIfRequired(requestParameters, 'date_to', 'rpt031AccountOpeningList');
        throwIfRequired(requestParameters, 'filter_by', 'rpt031AccountOpeningList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
            ...(requestParameters.filter_by && { 'filter_by': requestParameters.filter_by }),
        };

        return this.request<AccountOpeningWithIBANList>({
            path: '/report/bo/031_account_opening_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list all pending card authorizations
     */
    rpt032CardAuthorizationList = (requestParameters: Rpt032CardAuthorizationListRequest): Observable<CardAuthorizationList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt032CardAuthorizationList');
        throwIfRequired(requestParameters, 'date_to', 'rpt032CardAuthorizationList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
            ...(requestParameters.amount && { 'amount': requestParameters.amount }),
            ...(requestParameters.region && { 'region': requestParameters.region }),
        };

        return this.request<CardAuthorizationList>({
            path: '/report/bo/032_card_authorization_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list all activated fee packages
     */
    rpt033FeePackageList = (requestParameters: Rpt033FeePackageListRequest): Observable<ActivatedFeePaackageList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt033FeePackageList');
        throwIfRequired(requestParameters, 'date_to', 'rpt033FeePackageList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<ActivatedFeePaackageList>({
            path: '/report/bo/033_fee_package_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list merchant daily closing data
     */
    rpt034MerchantDailyMonthlyList = (requestParameters: Rpt034MerchantDailyMonthlyListRequest): Observable<MerchantDailyMonthlyList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt034MerchantDailyMonthlyList');
        throwIfRequired(requestParameters, 'date_to', 'rpt034MerchantDailyMonthlyList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString() }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString() }),
            ...(requestParameters.save_history && { 'save_history': requestParameters.save_history }),
        };

        return this.request<MerchantDailyMonthlyList>({
            path: '/report/bo/034_merchant_daily_monthly_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list business accounts details
     */
    rpt035BusinessAccountDetails = (requestParameters: Rpt035BusinessAccountDetailsRequest): Observable<BusinessAccountDetails> => {
        throwIfRequired(requestParameters, 'segment', 'rpt035BusinessAccountDetails');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.segment && { 'segment': requestParameters.segment }),
        };

        return this.request<BusinessAccountDetails>({
            path: '/report/bo/035_business_account_details',
            method: 'GET',
            headers,
            query,
        });
    };


    operationToOperationId = {
	    "rpt001BalanceList": "rpt001_balance_list" ,
	    "rpt002AccountList": "rpt002_account_list" ,
	    "rpt004005AccountOpeningListNdpay": "rpt004_005_account_opening_list_ndpay" ,
	    "rpt004005AccountOpeningListOnefor": "rpt004_005_account_opening_list_onefor" ,
	    "rpt006ExternalAccounts": "rpt006_external_accounts" ,
	    "rpt007DebitCardApplications": "rpt007_debit_card_applications" ,
	    "rpt009CustomerBalanceList": "rpt009_customer_balance_list" ,
	    "rpt011MerchantList": "rpt011_merchant_list" ,
	    "rpt012RegisteredCustomerList": "rpt012_registered_customer_list" ,
	    "rpt014CheckoutList": "rpt014_checkout_list" ,
	    "rpt015AccountUpgradeList": "rpt015_account_upgrade_list" ,
	    "rpt016SystemTransactionList": "rpt016_system_transaction_list" ,
	    "rpt019CustomerOverviewPositiveList": "rpt019_customer_overview_positive_list" ,
	    "rpt021ProductOrderStornoList": "rpt021_product_order_storno_list" ,
	    "rpt022SegmentTransactionSumsList": "rpt022_segment_transaction_sums_list" ,
	    "rpt023MastercardClearingList": "rpt023_mastercard_clearing_list" ,
	    "rpt026InternalAccountList": "rpt026_internal_account_list" ,
	    "rpt027IssuedCardList": "rpt027_issued_card_list" ,
	    "rpt030AccountFeePackageList": "rpt030_account_fee_package_list" ,
	    "rpt031AccountOpeningList": "rpt031_account_opening_list" ,
	    "rpt032CardAuthorizationList": "rpt032_card_authorization_list" ,
	    "rpt033FeePackageList": "rpt033_fee_package_list" ,
	    "rpt034MerchantDailyMonthlyList": "rpt034_merchant_daily_monthly_list" ,
	    "rpt035BusinessAccountDetails": "rpt035_business_account_details"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum Rpt001BalanceListBalanceTypeEnum {
    B = 'B',
    V = 'V',
    T = 'T'
}
/**
 * @export
 * @enum {string}
 */
export enum Rpt004005AccountOpeningListNdpayPartnerEnum {
    All = 'all',
    NDIDENT = 'NDIDENT',
    NDPAY = 'NDPAY'
}
/**
 * @export
 * @enum {string}
 */
export enum Rpt004005AccountOpeningListOneforPartnerEnum {
    ONEFOR = 'ONEFOR'
}
/**
 * @export
 * @enum {string}
 */
export enum Rpt009CustomerBalanceListAccountTypeEnum {
    All = 'all',
    CashAccount = 'Cash Account',
    VoucherAccount = 'Voucher Account',
    MerchantAccount = 'Merchant Account'
}
/**
 * @export
 * @enum {string}
 */
export enum Rpt009CustomerBalanceListCustomerTypeEnum {
    All = 'all',
    Person = 'Person',
    Merchant = 'Merchant'
}
/**
 * @export
 * @enum {string}
 */
export enum Rpt015AccountUpgradeListSegmentEnum {
    Restaurant = 'Restaurant',
    Club = 'Club',
    EcoCare = 'EcoCare',
    Military = 'Military',
    Canteen = 'Canteen',
    Noweda = 'Noweda',
    PharmaDirekt = 'PharmaDirekt',
    All = 'All',
    Kaufland = 'Kaufland',
    Lidl = 'Lidl',
    Airport = 'Airport',
    CityTestcenter = 'City-Testcenter',
    LifeStyle = 'LifeStyle'
}
/**
 * @export
 * @enum {string}
 */
export enum Rpt019CustomerOverviewPositiveListSegmentEnum {
    Restaurant = 'Restaurant',
    Club = 'Club',
    EcoCare = 'EcoCare',
    Military = 'Military',
    Canteen = 'Canteen',
    All = 'All',
    Noweda = 'Noweda',
    PharmaDirekt = 'PharmaDirekt',
    Kaufland = 'Kaufland',
    Lidl = 'Lidl',
    Airport = 'Airport',
    CityTestcenter = 'City-Testcenter',
    LifeStyle = 'LifeStyle'
}
/**
 * @export
 * @enum {string}
 */
export enum Rpt022SegmentTransactionSumsListSegmentEnum {
    Restaurant = 'Restaurant',
    Club = 'Club',
    EcoCare = 'EcoCare',
    Military = 'Military',
    Canteen = 'Canteen',
    All = 'All',
    Noweda = 'Noweda',
    PharmaDirekt = 'PharmaDirekt',
    Kaufland = 'Kaufland',
    Lidl = 'Lidl',
    Airport = 'Airport',
    CityTestcenter = 'City-Testcenter',
    LifeStyle = 'LifeStyle'
}
/**
 * @export
 * @enum {string}
 */
export enum Rpt022SegmentTransactionSumsListFilterByEnum {
    BookingDate = 'booking_date',
    TransactionDatetime = 'transaction_datetime',
    CheckinDatetime = 'checkin_datetime'
}
/**
 * @export
 * @enum {string}
 */
export enum Rpt031AccountOpeningListFilterByEnum {
    AccountCreation = 'date_of_account_creation',
    Identification = 'date_of_identification'
}
/**
 * @export
 * @enum {string}
 */
export enum Rpt032CardAuthorizationListRegionEnum {
    ChEea = 'ch-eea',
    Uk = 'uk'
}
/**
 * @export
 * @enum {string}
 */
export enum Rpt035BusinessAccountDetailsSegmentEnum {
    Restaurant = 'Restaurant',
    Club = 'Club',
    EcoCare = 'EcoCare',
    Military = 'Military',
    Canteen = 'Canteen',
    All = 'All',
    Noweda = 'Noweda',
    PharmaDirekt = 'PharmaDirekt',
    Kaufland = 'Kaufland',
    Lidl = 'Lidl',
    Airport = 'Airport',
    CityTestcenter = 'City-Testcenter',
    LifeStyle = 'LifeStyle'
}
