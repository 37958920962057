// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Iso4217Currency,
    ProductAttributeConfig,
    ProductCalendar,
    
} from './';

/**
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * @type {number}
     * @memberof Product
     */
    product_id: number;
    /**
     * amount in cents to be compatible with stripe api
     * @type {number}
     * @memberof Product
     */
    price?: number;
    /**
     * @type {Iso4217Currency}
     * @memberof Product
     */
    currency?: Iso4217Currency;
    /**
     * @type {string}
     * @memberof Product
     */
    name?: string;
    /**
     * @type {string}
     * @memberof Product
     */
    claim?: string;
    /**
     * @type {string}
     * @memberof Product
     */
    long_description?: string;
    /**
     * a link to the product image
     * @type {string}
     * @memberof Product
     */
    images?: Array<{merchant_product_image_id: number, url: string}>;
    /**
     * @type {string}
     * @memberof Product
     */
    forward_receive_test_to?: string;
    /**
     * @type {boolean}
     * @memberof Product
     */
    is_fast_track?: boolean;
    /**
     * @type {boolean}
     * @memberof Product
     */
    is_noweda_standard?: boolean;
    /**
     * @type {number}
     * @memberof Product
     */
    merchant_provision?: number;
    /**
     * @type {String | object}
     * @memberof Product
     */
    type_of_test?: String | object;
    /**
     * @type {number}
     * @memberof Product
     */
    vat: number;
    /**
     * @type {Array<Array<string>>}
     * @memberof Product
     */
    languages?: Array<Array<ProductLanguagesEnum>>;
    /**
     * product package name assigned to the product
     * @type {string}
     * @memberof Product
     */
    product_package?: string;
    /**
     * products with identical groups will be checked together, across all merchants and all products
     * @type {string}
     * @memberof Product
     */
    unique_group?: string;
    /**
     * * O - online  * P - physical
     * @type {string}
     * @memberof Product
     */
    test_method?: ProductTestMethodEnum;
    /**
     * @type {ProductAttributeConfig}
     * @memberof Product
     */
    need_igm_igg?: ProductAttributeConfig;
    /**
     * @type {ProductAttributeConfig}
     * @memberof Product
     */
    need_product_type_id?: ProductAttributeConfig;
    /**
     * * B - antiBody  * G - antiGen  * P - PCR  * V - Vaccine 
     * @type {string}
     * @memberof Product
     */
    product_group?: ProductProductGroupEnum;
    /**
     * for some merchants and some products the end time when tests can be done latest is restricted.  please note that this value is in merchants local time zone  deprecated (check product_calendar) 
     * @type {string}
     * @memberof Product
     */
    end_time?: string;
    /**
     * amount of voucher the customer gets in case of positive testresult   
     * @type {number}
     * @memberof Product
     */
    voucher_amount?: number;
    /**
     * @type {ProductAttributeConfig}
     * @memberof Product
     */
    need_id_check?: ProductAttributeConfig;
    /**
     * @type {ProductAttributeConfig}
     * @memberof Product
     */
    need_flight?: ProductAttributeConfig;
    /**
     * @type {Array<ProductCalendar>}
     * @memberof Product
     */
    product_calendar?: Array<ProductCalendar>;

    payment_methods?: Array<{
                    payment_method_id: number;
                    name: string;
                    }>;
    product_appointment_types?: Array<ProductAppointmentType>;
    product_delivery_types?: Array<ProductDeliveryType>;

}
export interface ProductAppointmentType {
    product_appointment_type_id: number;
    product_appointment_type_description: string;
};
export interface ProductDeliveryType {
    product_delivery_type_id: number;
    product_delivery_type_description: string;
};
/**
 * @export
 * @enum {string}
 */
export enum ProductLanguagesEnum {
    De = 'de',
    El = 'el',
    En = 'en',
    Fr = 'fr',
    Nl = 'nl',
    Ru = 'ru',
    Tr = 'tr',
    Zh = 'zh'
}
/**
 * @export
 * @enum {string}
 */
export enum ProductTestMethodEnum {
    O = 'O',
    P = 'P'
}
/**
 * @export
 * @enum {string}
 */
export enum ProductProductGroupEnum {
    B = 'B',
    G = 'G',
    P = 'P',
    V = 'V',
    D = 'D',
    C = 'C'
}

