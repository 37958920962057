// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ErrorWithMissingData
 */
export interface ErrorWithMissingData {
    /**
     * @type {number}
     * @memberof ErrorWithMissingData
     */
    error_code: number;
    /**
     * @type {string}
     * @memberof ErrorWithMissingData
     */
    error_text: string;
    /**
     * @type {Array<string>}
     * @memberof ErrorWithMissingData
     */
    missing?: Array<ErrorWithMissingDataMissingEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum ErrorWithMissingDataMissingEnum {
    Face = 'face',
    Passport = 'passport',
    Address = 'address'
}

