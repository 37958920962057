import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { translate } from '../../common/language/translate';
import { KeyField, NoMarginSelect, ValueField } from '../../content/dashboard/content/customers/basicStyledComponents/customerDetails.css';
import { DisplayMenuComponent } from '../compositcomponents/displayMenuComponent';
import { IOption } from './form';

interface IProps {
    translationkey: string;
    options: Array<IOption>;
    selected?: any;
    id: string;
    disabled?: boolean;
    onChange: (key: string | number | undefined, value: string) => void;
    wrapperStyle?: React.CSSProperties
}

interface IState {
}

export default class KeySelectComponent extends PureComponent<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
        };
    }

	render() {
		return (
			<React.Fragment>
                <LargeKeyValueBlock style={this.props.wrapperStyle}>
                   <KeyField >
                        {translate(
                            this.props.translationkey, this.props.translationkey
                        ) + (this.props.disabled === true ? '' :  ' *')}: 
                    </KeyField>	
                    <ValueField > 
                         <DisplayMenuComponent
                                elementKey={this.props.id}
                                copyValue={this.props.selected != null ? this.props.selected.toString() : '' }
                                methods={{
                                }}
                                >
                            <NoMarginSelect
                                id={this.props.id}
                                options={this.props.options}
                                current={ this.props.selected }
                                notification={(message: any ) => this.props.onChange(message[this.props.id], this.props.id)}
                                disabled={this.props.disabled}
                            />
                        </DisplayMenuComponent>
                    </ValueField> 
                </LargeKeyValueBlock> 
            </React.Fragment>
		);
	}
}

const LargeKeyValueBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 450px;
    height: 62px;
    border-bottom: 1px solid #DEDEDE;
    padding: ${(props) => props.theme.Table.TBody.Td.padding};
    margin: 8px;
    padding: 8px;
`;