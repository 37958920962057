import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Account, api, apis } from '../../../../logic/api';
import {
	IStatusState,
	Reporter,
	IMessageConfig,
	getFormComponents,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { Log, Logs } from '../../../../logic/log';
import { format } from '../../../../logic/helper/format';
import { Icons } from '../../../../images';
import { translate } from '../../../../common/language/translate';
import {
	IPropsCenter,
	IStateCenter,
	ACenteredOverlay,
} from '../../../../logic/handler/overlayhandler/globaloverlays/aCenteredOverlay';
import { FlexBox } from '../../../auth/auth.css';
import { Table, CustomRow, CustomCell, TitleCell, ContentCell } from '../customers/basicStyledComponents/customerDetails.css';
import moment, { MomentInput } from 'moment';
import { TransitionBox } from '../basicStyledComponents/contentsComponent.css';
import { flattenJsonObject } from '../../../../logic/helper/Common';

const StyledOverlay = styled(FlexBox)`
	margin: auto;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	width: 890px;
	max-height: 100vh;
	border-radius: 5px;

	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	background-color: white;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	overflow-y: auto;
	cursor: default;
`;

const StyledViewMain = styled(FlexBox)`
	padding-left: 30px;
	padding-right: 30px;
`;

const StyledOpacityIcon = styled(FlexBox)`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding-top: 8px;
	padding-right: 8px;
`;

const StyledTextFirstLine = styled.div`
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 23px;
	letter-spacing: 0.2px;
	color: ${props => props.theme.Global.darkFontColor};
	margin-bottom: 45px;
`;
type IMAP = { [index: string]: string }

const translationMap: IMAP = {
	"transaction_source": 'transfer.details.transactionSource',
	"account_from": 'transfer.details.accountFrom',
	"datetime_create": 'transfer.details.datetimeCreate',
	"email_address_id_from": 'transfer.details.emailAddressIdFrom',
	"email_address_id_to": 'transfer.details.emailAddressIdTo',
	"in_out": 'transfer.details.inOut',
	"local_datetime": 'transfer.details.localDatetime',
	"location": 'transfer.details.location',
	"person_id_from": 'transfer.details.personIdFrom',
	"purpose": 'transfer.details.purpose',
	"ref_money_transfer_id": 'transfer.details.refMoneyTransferId',
	"ref_ta_id": 'transfer.details.refMoneyTransferId',
	"reference_id": 'transfer.details.referenceId',
	"text": 'transfer.details.text',
	"attachemets": 'transfer.details.attachments',
	"instructed_amount_amount":  'transfer.details.instructedAmount',
	"instructed_amount_currency": 'transfer.details.instructedAmountCurreny',
	"currency": 'transfer.details.instructedAmountCurreny',
	"amount": 'transfer.details.instructedAmount',
}

const translationMapTechData: IMAP = {

}


const translationMapStatementData: IMAP = {
	"transaction_datetime": 'transfer.details.tradeDate',
}


const ignoreValues = [
	'attachments',
	'related_transactions'
];

export interface ITransactionDetailsOverlayProps extends IPropsCenter {
	accountNumber?: string;
	taId?: number;
	transactionId?: number;
	attachments?: Array<Account.Attachment>;
	statemenData?: any
}


interface IData extends Account.ActivityDetail {
	data: { [key: string]: any };
}

interface IState extends IStatusState, IStateCenter {
	data: null | IData;
	showDetails?: boolean;
	showTechDetails?: boolean;
	showStatementDetails?: boolean;
	showMoorwandDetails?: boolean;
}

class TransactionDetailsOverlay extends ACenteredOverlay<
	ITransactionDetailsOverlayProps,
	IState
> {
	constructor(props: ITransactionDetailsOverlayProps) {
		super(props);
		this.fetchTransactionDetails();

		this.state = {
			data: null,
			showTechDetails: false,
			showDetails: true,
			showStatementDetails: false,
			showMoorwandDetails: false,
		};

		this.onCancel = this.onCancel.bind(this);
		this.createTableContent = this.createTableContent.bind(this);
	}

	onCancel(): void {
		this.props.selfClose();
	}

	fetchTransactionDetails() {
		if (
			this.props.accountNumber == null ||
			this.props.taId == null ||
			this.props.transactionId == null
		) {
			const config: IMessageConfig = MessageHandler.onError(
				Reporter['transactions.activity.get.request.noparameters']
			);
			this.setState({
				showInlineError: config.errorMethods.inline,
				errorMessage: config.errorMessage != null ? config.errorMessage : '',
				key: config.translationKey != null ? config.translationKey + '.error' : '',
			});
			Log.debug(
				Logs.API,
				'Missing parameters to fetch accountAccountNumberActivityDetailGet'
			);
			return;
		}
		const accountNumberActivityRequest: Account.AccountAccountNumberActivityDetailGetRequest = {
			account_number: this.props.accountNumber,
			ta_id: this.props.taId,
			transaction_id: this.props.transactionId,
		};
		api.request(
			accountNumberActivityRequest,
			apis.DefaultApi,
			'accountAccountNumberActivityDetailGet',
			(error, response: Account.ActivityDetail) => {
				if (response != null) {
					this.setState({
						data: response,
					});
				} else {
					const config: IMessageConfig = MessageHandler.onError(
						Reporter['transactions.activity.detail.get.request.error'],
						error.statusText
					);
					this.setState({
						showInlineError: config.errorMethods.inline,
						errorMessage:
							config.errorMessage != null
								? config.errorMessage
								: error.statusText,
						key:
							config.translationKey != null
								? config.translationKey + '.error'
								: '',
					});
				}
			}
		);
	}

	createTableContent() {
		const out = [];
		if(this.state.data != null && this.state.data.data != null) {
			if(this.state.data.transaction_source != null) {
				out.push(
					(
					<CustomRow>
						<CustomCell style={{borderBottom: 'none'}}>
							<TitleCell style={{marginLeft: '32px'}}>
								{translate(translationMap.transaction_source) }
							</TitleCell>
						</CustomCell>
						<CustomCell style={{borderBottom: 'none'}}>
							<ContentCell >
								{this.state.data.transaction_source}
							</ContentCell>
						</CustomCell>
					</CustomRow>)
					);
			}
			if(this.props.transactionId != null && this.props.taId != null) {
				out.push(
					(
					<CustomRow>
						<CustomCell style={{borderBottom: 'none'}}>
							<TitleCell style={{marginLeft: '32px'}}>
								{ translate('transfer.details.transactionId') }
							</TitleCell>
						</CustomCell>
						<CustomCell style={{borderBottom: 'none'}}>
							<ContentCell >
								{ this.props.taId + "/" + this.props.transactionId}
							</ContentCell>
						</CustomCell>
					</CustomRow>)
					);
			}
			for(const key in this.state.data.data) {
				
				if(translationMap[key] != null) {
					out.push(
						(
						<CustomRow>
							<CustomCell style={{borderBottom: 'none'}}>
								<TitleCell style={{marginLeft: '32px'}}>
									{translationMap[key] != null ? translate(translationMap[key]) : key }
								</TitleCell>
							</CustomCell>
							<CustomCell style={{borderBottom: 'none'}}>
								<ContentCell >
									{   key === 'datetime_create' ? format.datetime(moment(this.state.data.data[key]).toDate()) : 
										key.includes('amount') ? format.number(this.state.data.data[key], 2) : this.state.data.data[key]}
								</ContentCell>
							</CustomCell>
						</CustomRow>)
						);
				}
			}
		}
		if(this.props.attachments != null) {
			out.push(
				<CustomRow>
					<CustomCell style={{borderBottom: 'none'}}>
						<TitleCell style={{marginLeft: '32px'}}>
							{translate(translationMap.attachemets)}
						</TitleCell>
					</CustomCell>
					<CustomCell style={{borderBottom: 'none'}}>
					{ this.props.attachments.map(
						(attachment: Account.Attachment) => {
							return (
								<ContentCell >
									<StyledAttachmentLink
										href={attachment.link}
										target={attachment.name}>
										{attachment.name}
									</StyledAttachmentLink>
								</ContentCell >
							);
						}
					)}
				</CustomCell>
			</CustomRow> );
		}
		return out;
	}

	parseObjectfield(field: object): Array<ReactElement> {
		let out: Array<ReactElement> = [];
		for(const key in field) {
			if(typeof field[key as keyof object ]  === 'object') {
				out = out.concat(this.parseObjectfield(field[key as keyof object]));
			} 
			else {
				out.push(
					(
					<CustomRow >
						<CustomCell style={{borderBottom: 'none',  maxWidth: '420px'}}>
							<TitleCell style={{maxWidth: '213px', marginLeft: '32px', color: '#252631'}}>
								{translationMapTechData[key] != null ? translate(translationMapTechData[key]) : key }
							</TitleCell>
						</CustomCell>
						<CustomCell style={{borderBottom: 'none'}}>
							<ContentCell style={{maxWidth: '213px'}}>
								 { field[key as keyof object ] }
							</ContentCell>
						</CustomCell>
					</CustomRow>)
					);
			}
		}
		return out;
	}

	getTechContent() {
		const out = [];
		
		if(this.state.data != null && this.state.data.data != null) {
			const newData = flattenJsonObject(this.state.data.data) as { [key: string]: any };
			for(const key in newData) {
				if(translationMap[key] == null && !key.includes('moorwand')) {
					out.push(
						(
						<CustomRow >
							<CustomCell style={{borderBottom: 'none', maxWidth: '420px' }}>
								<TitleCell title={translate(key, key)} style={{marginLeft: '32px', maxWidth: '213', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
									{translate(key, key) }
								</TitleCell>
							</CustomCell>
							<CustomCell style={{borderBottom: 'none'}}>
								<ContentCell title={key === 'datetime_create' ? format.datetime(moment(newData[key]).toDate()) : newData[key]} 
											 style={{maxWidth: '213px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>		
						{  
							key === 'datetime_create' ? format.datetime(moment(newData[key]).toDate()) : newData[key]}
								</ContentCell>
							</CustomCell>
						</CustomRow>)
						);
				}
			}
		}
		return out;
	}

	getStatementDetails( ) {
		const out = [];
	    if(this.props.statemenData != null) {
			for(const key in this.props.statemenData) {
				if(translationMap[key] == null && ignoreValues.indexOf(key) === -1  )  {
					out.push(
						(
						<CustomRow >
							<CustomCell style={{borderBottom: 'none',  maxWidth: '420px'}}>
								<TitleCell style={{marginLeft: '32px'}}>
									{translationMapStatementData[key] != null ? translate(translationMapStatementData[key]) : key }
								</TitleCell>
							</CustomCell>
							<CustomCell style={{borderBottom: 'none'}}>
								<ContentCell >
						{ //@ts-ignore
							key.includes('datetime') ? format.datetime(moment(this.props.statemenData[key] as MomentInput).toDate()) :
							//@ts-ignore
							key.includes('date')     ? format.date(moment(this.props.statemenData[key] as MomentInput).toDate()) : 
							//@ts-ignore
							this.props.statemenData[key]}
								</ContentCell>
							</CustomCell>
						</CustomRow>)
						);
				}
			}
		}
		return out;
	}

	getMoorwandDetails( ) {
		const out = [];
		
		if(this.state.data != null && this.state.data.data != null && this.state.data.data.moorwand != null) {
			const data = flattenJsonObject(this.state.data.data.moorwand) as { [key: string]: any };
			for(const rawKey in data) {
				const key = rawKey as keyof Account.ActivityStatements;
				if(translationMap[key] == null && ignoreValues.indexOf(rawKey) === -1  )  {
					out.push(
						(
						<CustomRow >
							<CustomCell style={{borderBottom: 'none',  maxWidth: '420px'}}>
								<TitleCell 
									title={translate(key,key) }
									style={{marginLeft: '32px',maxWidth: '213px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
									{translate(key,key) }
								</TitleCell>
							</CustomCell>
							<CustomCell style={{borderBottom: 'none'}}>
								<ContentCell
									title={data[key]} 
									style={{maxWidth: '213px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
						{ //@ts-ignore
							key.includes('datetime') ? format.datetime(moment(data[key] as MomentInput).toDate()) :
							key.includes('date')     ? format.date(moment(data[key] as MomentInput).toDate()) : 
							data[key]}
								</ContentCell>
							</CustomCell>
						</CustomRow>)
						);
				}
			}
		}
		return out;
	}

	reviveState(): void {}

	defineContent() {
		return (
			<StyledOverlay onClick={(event) => event.stopPropagation()}>
				<FlexBox>
					<StyledOpacityIcon onClick={this.onCancel}>
						{Icons.closingCross()}
					</StyledOpacityIcon>
					<StyledViewMain>
						<StyledTextFirstLine>
							{translate('transfer.details.title')}
						</StyledTextFirstLine>
						<ScrollView>
							<Table>
							<thead>
								<CustomRow style={{	borderCollapse: 'collapse'}}>
									<TableHeader onClick={() => 
												{this.setState({showDetails: !this.state.showDetails})} } >
												{ this.state.showDetails
													? Icons.arrowFacingUp()
													: Icons.arrowFacingDown()
												}
												 <div style={{width: '80px', marginLeft: '16px'}} >Details</div> 
									</TableHeader>
								</CustomRow>
							</thead>
								</Table>
								<TransitionBox detailsOpen={this.state.showDetails === true} maxHeight='850px'>
									<Table>
									<tbody>
										{ this.createTableContent() }
									</tbody>
									</Table>
								</TransitionBox>
							<TechScrollView detailsOpen={this.state.showDetails}>
								<Table style={{marginTop: '0',}}>
									<thead>
										<CustomRow>
											<TableHeader onClick={() => 
														{this.setState({showTechDetails: !this.state.showTechDetails})} } >
														{ this.state.showTechDetails
															? Icons.arrowFacingUp()
															: Icons.arrowFacingDown()
														}
													<div style={{width: '80px', marginLeft: '16px'}}>Advanced</div> 
													
											</TableHeader>
										</CustomRow>
									</thead>
									</Table>
									<TransitionBox detailsOpen={this.state.showTechDetails === true} maxHeight='15000px'>
										<Table style={{maxWidth: '600px', width: '600px'}}>
											<tbody>
												{ this.getTechContent()  }
											</tbody>
										</Table>
									</TransitionBox>
							</TechScrollView>
							<TechScrollView detailsOpen={this.state.showStatementDetails}>
								<Table style={{marginTop: '0',}}>
									<thead>
										<CustomRow>
											<TableHeader onClick={() => 
														{this.setState({showStatementDetails: !this.state.showStatementDetails})} } >
														{ this.state.showStatementDetails
															? Icons.arrowFacingUp()
															: Icons.arrowFacingDown()
														}
													<div style={{width: '80px', marginLeft: '16px'}}>Statement</div> 
													
											</TableHeader>
										</CustomRow>
									</thead>
									</Table>
									<TransitionBox detailsOpen={this.state.showStatementDetails === true} maxHeight='2800px'>
										<Table>
											<tbody>
												{ this.getStatementDetails()  }
											</tbody>
										</Table>
									</TransitionBox>
							</TechScrollView>
							{this.state.data != null && this.state.data.data != null && this.state.data.data.moorwand != null ? 
							<TechScrollView detailsOpen={this.state.showMoorwandDetails}>
								<Table style={{marginTop: '0',}}>
									<thead>
										<CustomRow>
											<TableHeader onClick={() => 
														{this.setState({showMoorwandDetails: !this.state.showMoorwandDetails})} } >
														{ this.state.showMoorwandDetails
															? Icons.arrowFacingUp()
															: Icons.arrowFacingDown()
														}
													<div style={{width: '80px', marginLeft: '16px'}}>Moorwand</div> 
													
											</TableHeader>
										</CustomRow>
									</thead>
									</Table>
									<TransitionBox detailsOpen={this.state.showMoorwandDetails === true} maxHeight='1000px'>
										<Table>
											<tbody>
											{ this.getMoorwandDetails()  }
											</tbody>
										</Table>
									</TransitionBox>
							</TechScrollView> : null }
						</ScrollView>
						{getFormComponents(
							this.state.showInlineError,
							this.state.showInlineSuccess,
							this.state.errorMessage,
							this.state.successMessage,
							this.state.key
						)}
					</StyledViewMain>
				</FlexBox>
			</StyledOverlay>
		);
	}
}

const StyledAttachmentLink = styled.a`
	display: block;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.2px;
	text-decoration-line: underline;

	color: ${props => props.theme.Global.darkFontColor};
`;
const ScrollView = styled(FlexBox)`
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: 20px;
`;
const TechScrollView = styled(FlexBox)<{detailsOpen?: boolean}>`
	margin-top: ${(props) => (props.detailsOpen === true ? '32px' : '0px')};

`;

const TableHeader = styled.th`
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	text-decoration: none;
	text-align: left;
	vertical-align: middle;
	padding: 0 16px;
	color: ${(props) => (props.theme.Global.lightFontColor)};
	font-weight: 600;
	align-items: center;
	position: sticky;
	top: 0px;
	background-color: white;
	border-bottom: 1px solid #DEDEDE;
	width: 200%;
	display: flex;
	flex-direction: row;
	z-index: 200;
	height: 30px;
	border-radius: ${(props) => (props.theme.Box.borderRadius)};
	:hover {
		opacity: 0.6;
	}
`;
export default TransactionDetailsOverlay;
