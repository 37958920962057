// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CustomerBalanceListParams
 */
export interface CustomerBalanceListParams {
    /**
     * @type {Date}
     * @memberof CustomerBalanceListParams
     */
    datetime_create: Date;
    /**
     * @type {Date}
     * @memberof CustomerBalanceListParams
     */
    as_of_date: Date;
    /**
     * @type {string}
     * @memberof CustomerBalanceListParams
     */
    account_type?: CustomerBalanceListParamsAccountTypeEnum;
    /**
     * @type {string}
     * @memberof CustomerBalanceListParams
     */
    customer_type?: CustomerBalanceListParamsCustomerTypeEnum;
    /**
     * @type {string}
     * @memberof CustomerBalanceListParams
     */
    residence_country?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CustomerBalanceListParamsAccountTypeEnum {
    All = 'all',
    CashAccount = 'Cash Account',
    VoucherAccount = 'Voucher Account',
    MerchantAccount = 'Merchant Account'
}
/**
 * @export
 * @enum {string}
 */
export enum CustomerBalanceListParamsCustomerTypeEnum {
    All = 'all',
    Person = 'Person',
    Merchant = 'Merchant'
}

