import React from 'react';
import GenericTemplateComponent, {
	GenericTemplateType,
	TemplateCategory,
} from '../../../genericUI/genericTemplateComponent';
import styled from 'styled-components';
import {
	IStatusState,
	getFormComponents,
	Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { Account, api, Ident } from '../../../../logic/api';
import { OverlayHandler, Overlays } from '../../../../logic/handler/overlayhandler/overlayHandler';
import { FlexBox } from '../../../auth/auth.css';
import { translate } from '../../../../common/language/translate';
import { FileType } from '../../../../components/compositcomponents/popup/fileTypeChooserOverlay';
import { Log, Logs } from '../../../../logic/log';
import { downloadFile } from '../../../../logic/helper/Common';
import { format } from '../../../../logic/helper/format';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';

interface IProps {
}

interface IState extends IStatusState {
	showReport: boolean;
	responseData?: any;
	selectedPostingTemplate?: Account.TransactionTemplate;
}
// TODO: Generate this with api.. Somehow
type ReportAcceptTypes =
	| 'application/pdf'
	| 'text/comma-separated-values'
	| 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	| 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export default class AdminReportsComponent extends React.Component<IProps, IState> {
	private ref = React.createRef<GenericTemplateComponent>();
	constructor(props: IProps) {
		super(props);

		this.state = {
			showReport: false,
		};

		this.showReportPage = this.showReportPage.bind(this);
		this.reset = this.reset.bind(this);
	}

	reset() {
		this.setState({
			showReport: false,
		});
	}
	private getAcceptHeader(report: string, filetype: FileType): ReportAcceptTypes {
		if (filetype === FileType.csv) return 'text/comma-separated-values';
		else if (filetype === FileType.pdf) return 'application/pdf';
		else if (filetype === FileType.docx)
			return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		else return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	}

	showReportPage(
		showReport: boolean,
		response: any,
		selectedPostingTemplate: Account.TransactionTemplate
	) {
		if (showReport === true) {
			this.setState({
				showReport: showReport,
				responseData: response,
				selectedPostingTemplate: selectedPostingTemplate,
			});
		}
	}

	render() {
		return (
			<FlexBox>
				<Wrapper onClick={() => OverlayHandler.closeOverlaysOnClick()}>
					<GenericTemplateComponent
						templateType={GenericTemplateType.report_template}
						templateCategory={TemplateCategory.admin}
						heading={translate('generic.report')}
						subHeading={translate('generic.chooseReport')}
						notification={this.showReportPage}
						isReport={true}
						resetCallback={this.reset}
						ref={this.ref}
					/>
					{getFormComponents(
						this.state.showInlineError,
						this.state.showInlineSuccess,
						this.state.errorMessage,
						this.state.successMessage,
						this.state.key
					)}
				</Wrapper>
			</FlexBox>
		);
	}
}

const Wrapper = styled(FlexBox)`
	margin-bottom: 24px;
`;
