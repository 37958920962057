import React, { ReactNode, SyntheticEvent } from 'react';
import styled, { CSSProperties, keyframes } from 'styled-components';

interface IProps {
	children?: ReactNode;
	containerStyle?: CSSProperties;
	onClick?: (event: SyntheticEvent, domRect: DOMRect | null) => void;
	id?: string;
	onMouseLeave?: () => void;
	onMouseEnter?: () => void;
}

interface IState {
	animate?: 0 | 1;
}

export default class TouchableOpacity extends React.Component<IProps, IState> {
	private divRef: React.RefObject<HTMLDivElement> | null = null;

	constructor(props: IProps) {
		super(props);

		this.state = {};

		this.divRef = React.createRef<HTMLDivElement>();

		this._onClick = this._onClick.bind(this);
	}

	private _onClick(event: SyntheticEvent): void {
		event.stopPropagation();
		event.preventDefault();

		let domRect: DOMRect | null = null;
		if (
			this.divRef != null &&
			this.divRef.current != null &&
			this.divRef.current.getBoundingClientRect() != null
		) {
			domRect = this.divRef.current.getBoundingClientRect();
		}

		this.setState(
			{
				animate: 1,
			},
			() => {
				if (this.props.onClick != null && typeof this.props.onClick === 'function') {
					this.props.onClick(event, domRect);
				}
			}
		);
	}

	render() {
		return (
			<Fade
				style={this.props.containerStyle as any}
				onClick={this._onClick}
				onAnimationEnd={() => this.setState({ animate: 0 })}
				animate={this.state.animate}
				id={this.props.id}
				ref={this.divRef}
				onMouseLeave={() =>
					this.props.onMouseLeave != null ? this.props.onMouseLeave() : null
				}
				onMouseEnter={() =>
					this.props.onMouseEnter != null ? this.props.onMouseEnter() : null
				}>
				{this.props.children}
			</Fade>
		);
	}
}

const fadeInOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const Fade = styled.div<{ animate?: number }>`
	display: inline;
  	&:hover {
		  cursor: pointer;
	  }
	animation: ${(props) => (props.animate === 1 ? fadeInOut : null)} 100ms linear;
`;
