//@ts-nocheck
import React from 'react';
import AGenericElement, {
	IGenericElementProps,
	IGenericElementState,
	showErrorModeEnum,
} from './AGenericElement';
import styled from 'styled-components';
import { Select, IOption } from '../../components/atomiccompoents/form/select';
import { translate } from '../../common/language/translate';

interface IProps extends IGenericElementProps {
	values: Array<any> | undefined;
	helpText?: string;
}

interface IState extends IGenericElementState {}

const StyledView = styled.div``;

const StyledSelect = styled(Select)`
	select {
		height: 42px;
		width: 100%;
		min-width: 320px;
		margin-top: 10px;
		margin-bottom: 10px;
		padding-left: 10px;

		background: ${(props) => props.theme.Select.background};
		border: ${(props) => props.theme.Select.border};
		box-shadow: ${(props) => props.theme.Select.boxShadow};
		border-radius: ${(props) => props.theme.Select.borderRadius};
	}
`;

export default class GenericSelectInput extends AGenericElement<IProps, IState> {
	private SELECT_FORM_ID = 'selected' + this.props.title;
	private initval = '';
	constructor(props: IProps) {
		super(props);

		this.state = {
			showErrorMode: showErrorModeEnum.whileTyping,
		};

		this.onSelect = this.onSelect.bind(this);
	}

	componentDidMount() {
		this.props.notification(
			this.props.requestBodyUuid,
			this.initval,
			this.props.index,
			false
		);
	}

	hasError(value: string): boolean {
		let error: string | undefined = undefined;

		if (value == null || value === '') {
			if (this.props.isRequired) {
				error = this.props.title + ' ' + translate('generic.isRequired');
			}
		}

		this.setState({
			error: error,
		});
		return error != null;
	}

	onSelect(elem: any) {
		const selectedValue = elem[this.SELECT_FORM_ID];
		this.props.notification(
			this.props.requestBodyUuid,
			selectedValue,
			this.props.index,
			false
		);
	}

	render() {
		if (this.props.isRequired === false && this.state.error != null) {
			this.setState({ error: undefined });
		}

		const options: Array<IOption> = [];
		if (this.props.values != null) {
			let key = 1;
			
			for (const option of this.props.values) {
				if (key === 1) {
					this.initval = option;
				}
				options.push({
					key: this.SELECT_FORM_ID + 'Option' + key++,
					name: option,
					value: option,
				});
			}
		}

		return (
			<StyledView>
				<StyledSelect
					key={this.props.requestBodyUuid}
					helptext={
						this.props.helpText != null
							? this.props.helpText
							: 'Select ' +
							  this.props.title +
							  (this.props.isRequired === true ? ' *' : '')
					}
					id={this.SELECT_FORM_ID}
					options={options}
					notification={this.onSelect}
					maxWidthForHelper={1240}
				/>
			</StyledView>
		);
	}
}
