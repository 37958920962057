//@ts-nocheck
import React, { PureComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { translate } from '../../../../common/language/translate';
import AccountSelector from '../../../../components/accountSelector';
import Switch from '../../../../components/atomiccompoents/switch';
import { DisplayMenuComponent } from '../../../../components/compositcomponents/displayMenuComponent';
import { Progressbar } from '../../../../components/compositcomponents/progressbar';
import { withPersonAndAccount } from '../../../../components/hocs/withPersonAndAccount';
import { Icons } from '../../../../images';
import { Accounts } from '../../../../logic/accounts';
import { Ident } from '../../../../logic/api';
import { AccountDetailTxLimits } from '../../../../logic/api/account';
import { Actions, CustomerDataStore } from '../../../../logic/flux';
import { compareObject } from '../../../../logic/helper/Common';
import { format } from '../../../../logic/helper/format';
import { IItfAccount } from '../../../../logic/types';
import { HeaderCell, KeyField, KeyValueBlock, ValueField } from './basicStyledComponents/customerDetails.css';

interface IProps {
    person?: Ident.Person;
    currentAccount?: IItfAccount;
}

interface IState {
    data: Array<AccountDetailTxLimits>
    currency: string;
    displayTypes: { trans: boolean, transOut: boolean, withdrawal: boolean, upload: boolean, checkout: boolean}
    in?: boolean;
}

const InnerKeyValueBlock = styled(KeyValueBlock)`
    border-bottom: 1px solid #EEEEEE;
    margin-left: 26px;
    height: 124px;
    max-width: 520px;
`;

const OuterKeyvValueBlock = styled(KeyValueBlock)`
    max-width: 750px;

`;

const InnerValueField = styled(ValueField)`
`;

const IgnoreValuesIn: Array<string> = [];
const IgnoreValuesOut = ['amount month in', 'amount week in', 'amount day in', 'amount year in'];
class AccountDetailsLimitBox extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		const n: IState = {
           data: this.props.currentAccount?.tx_limits ?? [],
           currency: this.props.currentAccount?.currency ?? "",
           displayTypes: { trans: true, withdrawal: true, upload: true, transOut: true, checkout: true},
           in: false

		};

        this.state = n;
        this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
    }

    _onChangeCustomerData() {
 			this.setState({
                data: this.props.currentAccount?.tx_limits ?? [],
                currency: this.props.currentAccount?.currency ?? "",
            });
    }

    componentDidUpdate(prevProps: IProps) {
		if (prevProps.person?.person_id !== this.props.person?.person_id || !compareObject(prevProps.currentAccount, this.props.currentAccount) ) {
		  this._onChangeCustomerData();
		}	  
	  }

    getProgressBarColor(ratio: number): string {
        if(ratio > 0.8) {
            return '#BE2318';
        }
        else if(ratio > 0.6) {
            return '#FF9966';
        }
        else if (ratio > 0.4) {
            return '#EED202';
        }
        else if(ratio > 0.2) {
            return '#99cc33';
        }
        else {
            return '#339900';
        }
    }
    
    setUpContent() {
        const outTrans: Array<ReactElement> = [];
        const outTransOut: Array<ReactElement> = [];
        const outWithDraw: Array<ReactElement> = [];
        const outUpload:  Array<ReactElement> = [];
        const outCheckout:  Array<ReactElement> = [];
        if(this.state.data != null) {
            for( const key in this.state.data) {
                if((IgnoreValuesIn.indexOf(this.state.data[key].description) >= 0 && this.state.in !== true) ||
                   (IgnoreValuesOut.indexOf(this.state.data[key].description) >= 0 && this.state.in === true) ) {
                    continue;
                }

                const out = this.state.data[key].description.includes('withdraw') ? outWithDraw  :
                            this.state.data[key].description.includes('upload')   ? outUpload    : 
                            this.state.data[key].description.includes('checkout') ? outCheckout  : 
                            this.state.data[key].description.includes('out')      ? outTransOut  : outTrans;
                const type =  this.state.data[key].description.includes('withdraw') ? this.state.displayTypes.withdrawal  :
                              this.state.data[key].description.includes('upload')   ? this.state.displayTypes.upload    :  
                              this.state.data[key].description.includes('checkout') ? this.state.displayTypes.checkout  :  
                              this.state.data[key].description.includes('out')      ? this.state.displayTypes.transOut  :this.state.displayTypes.trans;           
                const testRatio = this.state.data[key].limit_amount === 0 ? this.state.data[key].limit_amount : this.state.data[key].used_amount/this.state.data[key].limit_amount;
                out.push(
                    <InnerKeyValueBlock style={{marginLeft: '8px'}}>									
                            <KeyField>
                                {translate('customers.limits.types.'  + this.state.data[key].limit_type_id, this.state.data[key].description)}:
                            </KeyField>															
                                <DisplayMenuComponent
                                    elementKey={this.state.data[key].description}
                                    copyValue={format.number(this.state.data[key].limit_amount) + " " + this.state.currency}
                                    methods={{}}>
                                    <InnerValueField>{ this.state.data[key].amount_type === 'Q' ? this.state.data[key].limit_amount : 
                                                       format.number(this.state.data[key].limit_amount) + " " + this.state.currency}</InnerValueField>
                                </DisplayMenuComponent>		
                            <KeyField style={{marginTop: '16px'}}>
                                {translate('customers.limits.usage', 'Usage') + ((type === true) ? " in % " : (this.state.data[key].amount_type === 'Q' ? '" in Quantities' : " in " + this.state.currency) )}:
                            </KeyField>															
                                <DisplayMenuComponent
                                    elementKey={this.state.data[key].description}
                                    copyValue={format.number(this.state.data[key].used_amount)}
                                    methods={{}}>
                                    <InnerValueField>
                                        <Progressbar 
                                            absVal={this.state.data[key].amount_type === 'Q' ? this.state.data[key].limit_amount : format.number(this.state.data[key].used_amount) + " " + this.state.currency}
                                            displayPercent={type}
                                            ratio={testRatio}
                                            color={this.getProgressBarColor(testRatio)}
                                        />
                                    </InnerValueField>
                                </DisplayMenuComponent>			    						
                    </InnerKeyValueBlock>
                    )
                }
            }
            return {
            trans: outTrans,
            transOut: outTransOut,
            widthDraw: outWithDraw,
            upload: outUpload,
            checkout: outCheckout
        };
    }

    updateData() {
        if(this.props.person != null) {
            Accounts.getAccountsByPersonId(this.props.person.person_id).then((response: IItfAccount[]) => {
                if (response.length > 0) {
                   let currAcc;
                   if(this.props.currentAccount != null) {                       
                       for(const acc of response) {
                           if (acc.account_number === this.props.currentAccount.account_number) {
                            currAcc = acc;
                           }
                       }
                   } else {
                       currAcc = response[0]
                   }
                   Actions.setCustomerCurrentAccount(currAcc);
                   Actions.setCustomerAccounts(response);
                }
            });
        }
    }

	render() {
        const data = this.setUpContent();
        return (
        <ColumNWrapper>
            <AccountSelector />
            <SwitchBlock >            
                <IconWrapper title={translate('customers.limits.swap')}  style={{marginRight: '4px'}}>
                   { Icons.numberic() }
                </IconWrapper>
                <Switch 
                    id = "typeswitch"
                    defaultValue={this.state.displayTypes.trans}
                    onChange={(value: boolean) =>  {
                        const val = this.state.displayTypes;
                        val.upload = value;
                        val.trans = value;
                        val.withdrawal = value;
                        val.transOut = value;
                        val.checkout = value;
                        this.setState({
                            displayTypes: val
                            })
                        }}/>
                <IconWrapper title={translate('customers.limits.swap')}>
                   { Icons.perCent() }
                </IconWrapper>
                <IconHoverWrapper 
                    onClick={() => this.updateData()}>
                        {Icons.refresh()}
                </IconHoverWrapper>
             {/*   <div style={{marginLeft: '64px', marginRight: '6px'}}>in</div>
                <Switch 
                    id = "inOutSwitch"
                    defaultValue={this.state.in}
                    onChange={(value: boolean) =>  {
                        this.setState({
                            in: value
                            })
                        }}/>
                    <div style={{marginLeft: '6px', marginRight: '16px'}}>out</div>   */}     
            </SwitchBlock>
            <Main>           
                <ColumnBox>
                    <OuterKeyvValueBlock maxWidth={'400px'}>
                        <HeaderCell title={translate('customers.limits.transDescription')} style={{whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '45px', marginLeft:'0px'}}>
                            {translate('customers.limits.transactionLimit')}
                        </HeaderCell>
                    </OuterKeyvValueBlock>
                    { data.trans }
                </ColumnBox>
                <ColumnBox>
                    <OuterKeyvValueBlock maxWidth={'400px'}>
                        <HeaderCell title={translate('customers.limits.transDescription')} style={{whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '45px', marginLeft:'0px'}}>
                            {translate('customers.limits.transactionLimitOut')}
                        </HeaderCell>
                    </OuterKeyvValueBlock>
                    { data.transOut }
                </ColumnBox>
                <ColumnBox>
                    <OuterKeyvValueBlock maxWidth={'400px'}>
                        <HeaderCell title={translate('customers.limits.widthdrawDescription')} style={{whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '45px', marginLeft:'0px'}}>
                                {translate('customers.limits.widthdrawLimit')}
                        </HeaderCell >
                    </OuterKeyvValueBlock>
                    { data.widthDraw }
                </ColumnBox>
                <ColumnBox>
                    <OuterKeyvValueBlock maxWidth={'400px'}>
                        <HeaderCell title={translate('customers.limits.uploadDesciption')} style={{whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '45px', marginLeft:'0px'}}>
                                {translate('customers.limits.uploadLimit')}
                        </HeaderCell >
                    </OuterKeyvValueBlock>
                    { data.upload }
                </ColumnBox>
                <ColumnBox>
                    <OuterKeyvValueBlock maxWidth={'400px'}>
                        <HeaderCell title={translate('customers.limits.uploadDesciption')} style={{whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '45px', marginLeft:'0px'}}>
                                {'Checkout'}
                        </HeaderCell >
                    </OuterKeyvValueBlock>
                    { data.checkout }
                </ColumnBox>
            </Main>
        </ColumNWrapper> )
    }
}

const Main = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-y: auto;
    flex-wrap: nowrap;
    width: 100%;
    max-height: calc(100vh - 150px);
    max-width: 100%;
`;

const ColumnBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 17%;
    padding: 16px;
    padding-top: 0;
`;
const ColumNWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const IconWrapper = styled.div`
    width: 24px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    svg {
        width: 20px;
        height: 20px;
        fill: ${(props) => props.theme.Global.keyFontColor};
    }
`;

const SwitchBlock = styled.div`
    display: flex;
    flex-direction: row;
    width: 90%;
    padding: 16px;
`;

const IconHoverWrapper = styled(IconWrapper) `
    margin-left: 64px;
    :hover {
        cursor: pointer;
    }
`;

export default withPersonAndAccount(AccountDetailsLimitBox);
