// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface PendingRequest
 */
export interface PendingRequest {
    /**
     * @type {number}
     * @memberof PendingRequest
     */
    client_pending_requests_id?: number;
    /**
     * @type {number}
     * @memberof PendingRequest
     */
    ta_id?: number;
    /**
     * @type {number}
     * @memberof PendingRequest
     */
    transaction_id?: number;
    /**
     * @type {string}
     * @memberof PendingRequest
     */
    type?: PendingRequestTypeEnum;
    /**
     * @type {number}
     * @memberof PendingRequest
     */
    ref_id?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum PendingRequestTypeEnum {
    RevertSepa = 'revert_sepa'
}

