// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Attachment,
    CountryCodeAlpha2,
    NewAddress,
    Sex,
} from './';

/**
 * @export
 * @interface MerchantLocation
 */
export interface MerchantLocation {
    /**
     * @type {number}
     * @memberof MerchantLocation
     */
    ta_id: number;
    /**
     * @type {number}
     * @memberof MerchantLocation
     */
    location_id: number;
    /**
     * the location
     * @type {string}
     * @memberof MerchantLocation
     */
    location?: string;
    /**
     * an optional table number
     * @type {string}
     * @memberof MerchantLocation
     */
    table_no?: string;
    /**
     * date and time of checkin
     * @type {string}
     * @memberof MerchantLocation
     */
    checkin_datetime: string;
    /**
     * date and time of checkout
     * @type {Date}
     * @memberof MerchantLocation
     */
    checkout_datetime?: Date;
    /**
     * if available the customers account number
     * @type {string}
     * @memberof MerchantLocation
     */
    account_number?: string;
    /**
     * @type {Sex}
     * @memberof MerchantLocation
     */
    sex?: Sex;
    /**
     * the persons given name
     * @type {string}
     * @memberof MerchantLocation
     */
    given_name: string;
    /**
     * the persons name
     * @type {string}
     * @memberof MerchantLocation
     */
    name: string;
    /**
     * the persons birth date
     * @type {Date}
     * @memberof MerchantLocation
     */
    birth_date?: Date;
    /**
     * the email address
     * @type {string}
     * @memberof MerchantLocation
     */
    email_address: string;
    /**
     * the persons phone number
     * @type {string}
     * @memberof MerchantLocation
     */
    phone?: string;
    /**
     * @type {NewAddress}
     * @memberof MerchantLocation
     */
    address: NewAddress;
    /**
     * @type {number}
     * @memberof MerchantLocation
     */
    ref_ta_id?: number;
    /**
     * @type {number}
     * @memberof MerchantLocation
     */
    ref_transaction_id?: number;
    /**
     * the product assgined to the checin
     * @type {number}
     * @memberof MerchantLocation
     */
    product_id?: number;
    /**
     * the test assigned to the checkin
     * @type {string}
     * @memberof MerchantLocation
     */
    test_id?: string;
    /**
     * @type {Array<Attachment>}
     * @memberof MerchantLocation
     */
    attachments?: Array<Attachment>;
    /**
     * indicates if checkout is possible
     * @type {boolean}
     * @memberof MerchantLocation
     */
    checkout_possible?: boolean;
    /**
     * the persons passport number stored at the order
     * @type {string}
     * @memberof MerchantLocation
     */
    passport_number?: string;
    /**
     * @type {CountryCodeAlpha2}
     * @memberof MerchantLocation
     */
    nationality?: CountryCodeAlpha2;
    /**
     * @type {Array<string>}
     * @memberof MerchantLocation
     */
    languages?: Array<MerchantLocationLanguagesEnum>;
    /**
     * DEPRECATED, please switch to test_reason_id
     * @type {string}
     * @memberof MerchantLocation
     */
    test_reason?: string;
    /**
     * ID of the test reason for this order
     * @type {number}
     * @memberof MerchantLocation
     */
    test_reason_id?: number;
    /**
     * ID of the test method taken for this order
     * @type {number}
     * @memberof MerchantLocation
     */
    method_taken_id?: number;
    /**
     * which payment method was choosen for booked test
     * @type {string}
     * @memberof MerchantLocation
     */
    payment_method?: string;
    /**
     * product amount
     * @type {number}
     * @memberof MerchantLocation
     */
    amount?: number;
    /**
     * product amount payable
     * @type {number}
     * @memberof MerchantLocation
     */
    amount_payable?: number;
    /**
     * voucher amount
     * @type {number}
     * @memberof MerchantLocation
     */
    voucher_amount?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum MerchantLocationLanguagesEnum {
    De = 'de',
    El = 'el',
    En = 'en',
    Fr = 'fr',
    Nl = 'nl',
    Ru = 'ru',
    Tr = 'tr',
    Zh = 'zh'
}

