//@ts-nocheck
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Ident, api, VoidResponse, apis, ApiError, Account } from '../../../../logic/api';
import {
	IStatusState,
	Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import {
	IInitProps,
	IInitState,
	InitialDataComponent,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import { CustomerDataStore, Actions, PermissionStore } from '../../../../logic/flux';
import { InitComponentHandler } from '../../../../logic/handler/initialdatahandler/InitComponentHandler';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import { FlexBox } from '../../../auth/auth.css';
import { IItfAccount } from '../../../../logic/types';
import Switch from '../../../../components/atomiccompoents/switch';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import {
	PersonMerchantDocumentListRequest,
	PersonMerchantDocumentGetRequest,
	PersonFaceGetRequest
} from '../../../../logic/api/ident';
import { Log, Logs } from '../../../../logic/log';
import { Icons } from '../../../../images';
import { format } from '../../../../logic/helper/format';
import { ProductType } from '../../../../logic/api/account';
import { translate } from '../../../../common/language/translate';
import {
	OverlayHandler,
	Overlays,
} from '../../../../logic/handler/overlayhandler/overlayHandler';
import ImageSelectComponent from '../../../../components/compositcomponents/imageSelectCompoent/imageSelectComponent';
import { AddWrapper, ContentCell, DocumentsWrapper, IconWrapperPadding, KeyField, LargeKeyValueBlock, MainBox, MainField, NameField, StyledSelect, ValueField, RawRowBox, RawColumnBox } from './basicStyledComponents/customerDetails.css';
import EditableComponent from '../../../../components/atomiccompoents/editableComponent/editableComponent';
import { CheckBox, IOption } from '../../../../components/atomiccompoents/form';
import { deepCopy, evaluateErrorMessage, floatingMenuButton, updatePerson } from '../../../../logic/helper/Common';
import { IImageUploads, IImageData } from './customerDetailsInterfaces';
import { EditableCalendar } from '../../../../components/compositcomponents/editableCalendar/editableCalendar';
import Expandable from '../../../../components/compositcomponents/expandable/expandable';
import { AccessKeyComponent } from './merchantSubComponents/accessKeyComponent';
import { PaymentMethodComponent } from './merchantSubComponents/paymentMethodComponent';
import { ProductsTypeComponent } from './merchantSubComponents/productsTypeComponent';
import { SignatureComponent } from './merchantSubComponents/signatureComponent';
import { FiskalyComponent } from './merchantSubComponents/fiskalyComponent';
import { catchError } from 'rxjs/operators';
import { EditableProductCalendar } from '../../../../components/compositcomponents/editableCalendar/editableProductCalendar';



interface IProps extends IInitProps {
	showPreview?: boolean;
}

interface IState extends IStatusState, IInitState {
	user?: Ident.Person;
	currentAccount?: IItfAccount;
	toggleValue?: boolean;
	merchant: boolean;
	showUpload: boolean;
	keyForLoadingSpinner: number;
	dataList: IPreviewData[];
	toUpload?: IImageUploads;
	documents: Array<IImageData>;
	documentBodys: Array<IImageBody>;
	sliderIndex: number;
	companyName: string;
	merchant_description?: string;
	companyNameSet?: string;
	profilePictures: Array<IImageData>;
	profilePictureBodys: Array<IImageBody>;
	availProducts?: Array<Account.Product>;
	usedProducts?: Array<Account.Product>;
	openingCalendar?: Account.ProductCalendar;
	openedProducts?: {};
	calendar?: boolean;
	segment?: Ident.MerchantUpgradeSegmentEnum,
	address?: Ident.Address,
	addressChanged?: boolean,
	is_public?: boolean;
	is_healthpoint?: boolean;
	index?: number,
	error?: {},
	changedProducts: {},
	timezone?: Account.ConfigTimezoneEnum,
	showPaymentMethods?: boolean;
	availableProductTypes: Array<ProductType>;
}

interface IPreviewData {
	src: string;
	data: any;
	name: string;
	type: DocType;
	id: string;
}

interface IImageBody {
	blob: Blob,
	type: DocType
}

enum DocType {
	PDF = 'pdf',
	IMG = 'img',
}



export default class CustomerMerchants extends InitialDataComponent<IProps, IState> {
	private InpStyle = {
		textAlign: 'left',
		marginBottom: '0px',
		marginTop: '2px',
	};
	constructor(props: IProps) {
		super(props);

		this.state = {
			toggleValue: false,
			merchant: false,
			showUpload: true,
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			documents: [],
			documentBodys: [],
			sliderIndex: 0,
			dataList: [],
			companyName: '',
			profilePictureBodys: [],
			profilePictures: [],
			openedProducts: {},
			index: -1,
			changedProducts: {},
		};
		this.onSubmit = this.onSubmit.bind(this);
		this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
		this.getUserDocuments = this.getUserDocuments.bind(this);
		this.loadAndShowImage = this.loadAndShowImage.bind(this);
		this.loadAndShowProfile = this.loadAndShowProfile.bind(this);
		this.addProduct = this.addProduct.bind(this);
		this.setSegment = this.setSegment.bind(this);
		this.loadProducts = this.loadProducts.bind(this);
		this.loadUsedProducts = this.loadUsedProducts.bind(this);
		this.bookProductCallback = this.bookProductCallback.bind(this);
		this.setTimezone = this.setTimezone.bind(this);
		this.setOpenCalendarData = this.setOpenCalendarData.bind(this);
	}
	reviveState() {}
	/**
	 * converts raw response data into structured data for the access-key-table
	 * @param line current entry of table-data
	 * @param index current index of table-data
	 * @param fields keys for identifying field
	 */

	_onChangeCustomerData() {
		const account = CustomerDataStore.getCurrentAccount();
		const selectedUser = CustomerDataStore.getUser();
		if (window.sessionStorage.getItem(this.constructor.name) != null) {
			this.fill(this.constructor.name);
		} else {
			this.setState({
				currentAccount: account,
				user: selectedUser,
				segment: selectedUser != null ? selectedUser.segment : undefined,
				merchant_description: selectedUser?.merchant_description,
				merchant: selectedUser != null ? selectedUser.is_merchant : false,
				showUpload: selectedUser != null ? !(selectedUser.is_merchant === true) : true,
				companyNameSet: selectedUser != null ? selectedUser.company_name : '',
				is_public: selectedUser?.is_public,
				is_healthpoint: selectedUser?.is_healthpoint,
				companyName:
					selectedUser != null &&
					selectedUser.company_name != null &&
					selectedUser.company_name !== ''
						? selectedUser.company_name
						: '',
			});
		}
		if (selectedUser != null) {
			this.getUserDocuments(selectedUser.person_id);
			this.getProfilePicture(selectedUser.person_id);
			this.getAddress(selectedUser.person_id);
			this.getTimeZone(selectedUser.person_id);
			this.getOpenCalendarData(selectedUser.person_id);
			this.loadProducts();
			if(account != null) {
				this.loadUsedProducts(account.account_number);
			}
		}
	}

	// React-Methods

	componentDidMount() {
		CustomerDataStore.addChangeListener(this._onChangeCustomerData);
		this._onChangeCustomerData();
		document.addEventListener('mousedown', function (event) {
			if (event.detail > 1) {
			  event.preventDefault();
			}
		  }, false);
	}

	componentWillUnmount() {
		InitComponentHandler.cleanUp(this.constructor.name);
		CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
	}

	// Api Calls


	getOpenCalendarData(person_id: number) {
		const req: Account.MerchantTestCenterOpenCalendarGetRequest = {
			person_id: person_id		
		};

		api.asyncRequest<Array<Account.CalendarItem>>(req, apis.MerchantApi, 'merchantTestCenterOpenPersonIdCalendarGet')
			.then((response: Array<Account.CalendarItem> ) => {
                const calendar: Account.ProductCalendar = {
					product_id: -1,
					date_range_days: -1,
					product_calendar_items: response != VoidResponse ? response :  []
				};
                
 				this.setState({
                    openingCalendar: calendar
				})
			}).catch((error: ApiError) => {
				Log.error(Logs.API, error);
			});
	}

	setOpenCalendarData(calendar: Account.ProductCalendar) {
		if(this.state.user ==  null) {
			return;
		}
		const req: Account.MerchantTestCenterOpenCalendarPutRequest = {
			person_id: this.state.user.person_id	,
			OpenCalendar: {
				calendar_items: calendar.product_calendar_items
			}		
		};

		api.asyncRequest(req, apis.MerchantApi, 'merchantTestCenterOpenPersonIdCalendarPut')
			.then(() => {
 				this.getOpenCalendarData(this.state.user.person_id);
				MessageHandler.onSuccess(Reporter['person.merchant.put.calendar']);
			}).catch((error: ApiError) => {
				Log.error(Logs.API, error);
			});
	}

	getAddress(personID: number) {
		const addressParams: Ident.PersonAddressGetRequest = {
			person_id: personID,
		};
		api.request(
			addressParams,
			apis.MaintenanceApi,
			'personAddressGet',
			(error: ApiError, response: Array<Ident.Address>) => {
				if (response != null) {
					if (response.length > 0) {
						for (const key in response) {
							const currentAddress = response[key];
							if (currentAddress.address_type === Account.AddressType.L) {
								this.setState({
									address: currentAddress
								})
							} 
						}
					}
				} else {
					MessageHandler.onError(
						Reporter['customer.details.person.get.request.error'],
						error.statusText
					);
					Log.error(Logs.API, error);
				}
			}
		);
	}

	/**
	 * get Documents user has already uploaded
	 * @param personId personId
	 */
	getUserDocuments(personId: number) {
		const req: PersonMerchantDocumentListRequest = {
			person_id: personId,
		};

		api.asyncRequest<VoidResponse>(req, apis.MaintenanceApi, 'personMerchantDocumentList')
			.then((response: any) => {
				if (response != null) {
					for (const i in response) {
						const id = response[i].person_document_id;

						const docRequest: PersonMerchantDocumentGetRequest = {
							person_id: personId,
							// eslint-disable-next-line
							person_document_id: id,
						};
						if (id == null) {
							break;
						}
						api.asyncRequest<VoidResponse>(
							docRequest,
							apis.MaintenanceApi,
							'personMerchantDocumentGet'
						)
							.then((responseImg: any) => {
								if (responseImg != null) {
									const imgs: Array<IImageData> = this.state.documents != null ? this.state.documents : [];
									const bodys: Array<IImageBody> = this.state.documentBodys != null ? this.state.documentBodys : [];
									let type = DocType.IMG;
									if (this.isPdf(response[i].filename)) {
										type = DocType.PDF;
									}
									imgs.push({
										name: response[i].filename,
										id: parseInt(i),
									});
									bodys.push({
										blob: responseImg,
										type: type
									});
									this.setState({
										documentBodys: bodys,
										documents: imgs
									});
								}
							})
							.catch((error: ApiError) => {
								Log.debug(Logs.API, error);
								MessageHandler.onError(Reporter['person.merchant.put.error'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
								this.setState({
									keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
								});
							});
					}
				}
			})
			.catch((error: ApiError) => {
				Log.debug(Logs.API, error);
				MessageHandler.onError(Reporter['person.merchant.put.error'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
				this.setState({
					keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
				});
			});
	}

	getProfilePicture(personId: number) {
		const req: Ident.PersonFaceListRequest = {
			person_id: personId,
		};
		api.asyncRequest<Array<Ident.Face>>(req, apis.MaintenanceApi, 'personFaceList')
				.then((response) => {
					if (response != null) {
						for (const i in response) {
							const id = response[i].face_id;
	
							const docRequest: PersonFaceGetRequest = {
								person_id: personId,
								// eslint-disable-next-line
								face_id: id,
							};
							if (id == null) {
								break;
							}
							api.asyncRequest<VoidResponse>(
								docRequest,
								apis.MaintenanceApi,
								'personFaceGet'
							)
								.then((responseImg: any) => {
									if (responseImg != null) {
										const imgs: Array<IImageData> = this.state.profilePictures != null ? this.state.profilePictures : [];
										const bodys: Array<IImageBody> = this.state.profilePictureBodys != null ? this.state.profilePictureBodys : [];
										const type = DocType.IMG;
										imgs.push({
											name: response[i].face_id.toString(),
											id: parseInt(i),
										});
										bodys.push({
											blob: responseImg,
											type: type
										});
										this.setState({
											profilePictureBodys: bodys,
											profilePictures: imgs
										});
									}
								})
								.catch((error: ApiError) => {
									Log.debug(Logs.API, error);
								});
						}
					}
				})
				.catch((error: ApiError) => {
					Log.debug(Logs.API, error);
				});
	}
	/**
	 * upload selected files to backend and make person merchant
	 * @param event
	 */
	onSubmit(event: SyntheticEvent)  {
		event.preventDefault();
		//@ts-ignore
		if(this.state.companyName == null || this.state.companyName === "" || this.state.timezone == null) {
			this.setState({
				error: {merchant: "Companyname and timezone musst be set"},
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			})
			return;
		}
		const merch = JSON.stringify({
			company_name: this.state.companyName,
			segment: this.state.segment,
			timezone: this.state.timezone,
			create_calendar: this.state.calendar != null ? this.state.calendar : false,
			merchant_description: this.state.merchant_description,
			is_public: this.state.is_public,
			is_healthpoint: this.state.is_healthpoint
		});
		if (
			this.state.user != null &&
			this.state.merchant != null &&
			this.state.companyName != null &&
			this.state.companyName !== ''
		) {
			const req: Ident.PersonMerchantPutRequest = {
				person_id: this.state.user.person_id,
				//@ts-ignore
				merchant_upgrade: merch,
			};
			if(this.state.toUpload != null) {
				for(const i in this.state.toUpload.files) {
					if(i === "0") {
						//@ts-ignore
						req['document'] = this.state.toUpload.files[i]
					} else {
						
						const idx:number = parseInt(i) + 1;
						//@ts-ignore
						req['document_' + idx.toString()] = this.state.toUpload.files[i]
					}
				}
			}

			api.asyncRequest<VoidResponse>(req, apis.MaintenanceApi, 'personMerchantPut')
				.then((response) => {
					if (response != null) {
						this.setState({
							merchant: true,
							keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
							companyNameSet: this.state.companyName,
							profilePictureBodys: [],
							profilePictures: [],
						});
						const pers = this.state.user;
						if (pers != null) {
							updatePerson(pers.person_id);
						}
						MessageHandler.onSuccess(Reporter['person.merchant.put.success']);
						if(this.state.address != null && this.state.addressChanged === true && this.state.user != null)  {
							const addr = deepCopy(this.state.address) as Ident.Address;
							delete addr.address_id;
							delete addr.datetime_update;
							const addrChange: Ident.PersonAddressPutRequest = {
								person_id:  this.state.user.person_id,
								address_id: this.state.address.address_id,
								NewAddress: addr
							}
							api.asyncRequest<VoidResponse>(addrChange, apis.MaintenanceApi, 'personAddressPut')
							.then((response) => {
								if (response != null) {
									if(this.state.user != null) {
										this.getAddress(this.state.user.person_id);
									}
									MessageHandler.onSuccess(Reporter['person.address.put']);
								}
							}).catch((error: ApiError ) => {
								MessageHandler.onError(Reporter['person.address.put'],evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
							});
						}
					}
				})
				.catch((error: ApiError) => {
					Log.debug(Logs.API, error);
					MessageHandler.onError(Reporter['person.merchant.put.error'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
					this.setState({
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
				});
		} else {
			this.setState({
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			});
			MessageHandler.onError(Reporter['person.merchant.put.error']);
		}
		this.setState({
			toUpload: undefined,
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			dataList: [],
			sliderIndex: 0,
		});
	}

	loadProducts() {
		const req = {};
		api.asyncRequest<Array<Account.Product>>(req, apis.ProductApi, 'productsGet')
				.then((response) => {
					if(this.state.user != null) {
						this.setState({
							availProducts: response
						})
					}
				})
				.catch((error: ApiError) => {
					Log.debug(Logs.API, error);
				});
	}


	loadUsedProducts(accNr?: string) {
		if(this.state.currentAccount == null && accNr == null) {
			return;
		}
		const account_nr = accNr != null ? accNr : this.state.currentAccount.account_number;
 		const req: Account.MerchantProductsListRequest = {
			account_number: account_nr
		};
		api.asyncRequest<Array<Account.Product>>(req, apis.MerchantApi, 'merchantProductsListBo')
				.then((response) => {
					if(this.state.user != null) {
						this.setState({
							usedProducts: response
						});
				}})
				.catch((error: ApiError) => {
					Log.debug(Logs.API, error);
				});
	}

	addProduct(productID: number) {
		if(this.state.currentAccount == null) {
			return;
		}
 		const req: Account.MerchantProductPutRequest = {
			account_number: this.state.currentAccount.account_number,
			product_id: productID,
			MerchantProduct: {
				merchant_provision: undefined
			}
		};
		api.asyncRequest<Array<Account.Product>>(req, apis.MerchantApi, 'merchantProductPut')
				.then(() => {
					if(this.state.user != null) {
						this.loadUsedProducts();
					}
					MessageHandler.onSuccess(Reporter['person.merchant.put.product']);
				})
				.catch((error: ApiError) => {
					MessageHandler.onError(Reporter['person.merchant.put.product'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
				});
	}


	deleteProduct(productID: number) {
		if(this.state.currentAccount == null) {
			return;
		}
 		const req: Account.MerchantProductDeleteRequest = {
			account_number: this.state.currentAccount.account_number,
			product_id: productID,
		};
		api.asyncRequest<Array<Account.Product>>(req, apis.MerchantApi, 'merchantProductDelete')
				.then(() => {
					if(this.state.user != null) {
						this.loadUsedProducts();
					}
					MessageHandler.onSuccess(Reporter['person.merchant.delete.product']);
				})
				.catch((error: ApiError) => {
					MessageHandler.onError(Reporter['person.merchant.delete.product'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
				});
	}

	getTimeZone(personId: number) {
		const req: Ident.PersonSettingsGetRequest = {
			person_id: personId
		}
		api.asyncRequest<Ident.Settings>(req, apis.MaintenanceApi, 'personSettingsGet')
			.then((response: Ident.Settings) => {
				this.setState({
					timezone: response.timezone
				})
			}).catch((error: ApiError) => {
				Log.error(Logs.API, error);
			});
	} 

	uploadProfilePicture(pic: Blob) {
		if(this.state.user ==  null) {
			return;
		}
		const req: Ident.PersonFacePostRequest = {
			person_id: this.state.user.person_id,
			file: pic
		};
		api.asyncRequest<VoidResponse>(req, apis.MaintenanceApi, 'personFacePost')
				.then(() => {
					if(this.state.user != null) {
						this.getProfilePicture(this.state.user.person_id)
					}
				})
				.catch((error: ApiError) => {
					Log.debug(Logs.API, error);
					MessageHandler.onError(Reporter['person.merchant.put.error'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
					this.setState({
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
				});

	}

    

	// Checker Methods
    
	inProducts(id: number) {
		if(this.state.usedProducts == null) {
			return;
		}
		for(const i in this.state.usedProducts) {
			if(this.state.usedProducts[i].product_id === id) {
				return true;
			}
		}
		return false;
	}

	/**
	 * check if file from api is a pdf
	 * @param name filename
	 */
	isPdf(name: string) {
		return name.substring(name.length - 3, name.length) === 'pdf';
	}

	inProductsProduct(id: number) : Account.Product {
		if(this.state.usedProducts == null) {
			return;
		}
		for(const i in this.state.usedProducts) {
			if(this.state.usedProducts[i].product_id === id) {
				return this.state.usedProducts[i];
			}
		}
		return null;
	}
    
	 //Image Overlays

	
	loadAndShowImage(imageId: number) {
		const body = this.state.documentBodys[imageId];
		if(body.type === DocType.PDF) {
			OverlayHandler.showOverlay(Overlays.PDFViewer, { 
				file: body.blob
			});

		}
		else {
			OverlayHandler.showOverlay(Overlays.imagePreview, {
				image: body.blob,
				alt: 'Image',
			});
		}
	}

	loadAndShowProfile(imageId: number) {
		const body = this.state.profilePictureBodys[imageId];
		OverlayHandler.showOverlay(Overlays.imagePreview, {
			image: body.blob,
			alt: 'Image',
		});
	}

	// UI	
	
	getExceptions() {
		const out = [];
		if(this.state.usedProducts == null) {
			return [];
		}
		for(const i in this.state.usedProducts) {
			out.push(this.state.usedProducts[i].product_id);
		}
		return out;
	}


	filterProducts() {
		const out = [];
		if(this.state.availProducts == null) {
			return out;
		}
		for (const i of this.state.availProducts) {
				const dataItem: IPopupMenuData<number> = {
					display: i.name + "( " + format.number(i.price / 100) + " " + (i.currency) + (i.product_package != null ? ", "  + i.product_package + " )" : ")" ) ,
					passValue: i.product_id,
					identifier: i.product_id,
					specialCase: i.is_noweda_standard || i.product_package != null
				}
				out.push(dataItem);
		}
		return out;
	}


	generateProductFields() {
		const out = [];
		if(this.state.usedProducts == null) {
			return out;
		}
		for(const o in this.state.usedProducts) {
			out.push(
                <MainBox key={"productFieldUsed" + o}>
                    <MainField style={{height: '46px', marginTop: '16px'}}>
                        <IconWrapperPadding onClick= { (event: SyntheticEvent) => {
							event.stopPropagation();
							event.preventDefault();
							OverlayHandler.showOverlay(Overlays.productOverlay, {person_id: this.state.user != null ? this.state.user.person_id : null,
																				 product: this.state.usedProducts[o], 
																				 updateCallback: this.loadUsedProducts,
																				 accountNr: this.state.currentAccount != null ? this.state.currentAccount.account_number : undefined,
																				})
                        }}>   
                        {//@ts-ignore 
                            Icons.unfold()
                        }
                        </IconWrapperPadding>
						<NameField style={{fontSize: '18px', width: '25%'}}>{//@ts-ignore 
									this.state.usedProducts[o].name}</NameField>
						<NameField style={{fontSize: '18x', margin: '8px', width: '25%'}}>{//@ts-ignore
									format.number(this.state.usedProducts[o].price / 100) + " " + (this.state.usedProducts[o].currency)} </NameField>
						<NameField style={{fontSize: '18x', margin: '8px', width: '10%'}}>{//@ts-ignore
									(this.state.usedProducts[o].product_package)} </NameField>			
						<DelWrapper style={{width: '15%'}}>
							<div style={{margin: 'auto', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
								<CheckBox
									id="bockProductCheckbox" 
									value={this.inProducts(this.state.usedProducts[o].product_id)}
									onChange={(value) => {
										if(value && !this.inProducts(this.state.usedProducts[o].product_id)) {
											this.addProduct(this.state.usedProducts[o].product_id);
										} else if(!value && this.inProducts(this.state.usedProducts[o].product_id)) {
											this.deleteProduct(this.state.usedProducts[o].product_id)
										}
										}}/>
								</div>
						</DelWrapper>
                    </MainField>
            </MainBox>
            )
		}
		if(out.length > 0) {
			out.push(<StyledSpacer />);
		}
		return out;
	}

	getTimeZoneOptions() : Array<IOption>  {
		const options: Array<IOption> = [ ];
		options.push({
			key: 'defaultOptionKey none',
			name: translate('customers.details.merchant.noneTimezone'),
			value: undefined
		});
		for (const i in Account.ConfigTimezoneEnum) {
			options.push({
				key: 'defaultOptionKey ' + i,
				name: Account.ConfigTimezoneEnum[i],
				value: Account.ConfigTimezoneEnum[i]
			});
		}
		return options;
	}


	getSegmentOptions():  Array<IOption> {
		const options: Array<IOption> = [ ];
		options.push({
			key: 'defaultOptionKey none',
			name: translate('customers.details.merchant.segments.none'),
			value: undefined
		});
		for (const i in Ident.MerchantUpgradeSegmentEnum) {
			options.push({
				key: 'defaultOptionKey ' + i,
				name: translate('customers.details.merchant.segments.' + Ident.MerchantUpgradeSegmentEnum[i], Ident.MerchantUpgradeSegmentEnum[i]),
				value: Ident.MerchantUpgradeSegmentEnum[i]
			});
		}
		return options;
	}




	setSegment(message: any) {
		const val = message.segmentSelect;
		this.setState({
			segment: val
		})
	}

	setTimezone(message: any) {
		const val = message.timezoneSelect;
		this.setState({
			timezone: val
		})
	}

	bookProductCallback(id: number) {
		for(const o of this.state.availProducts) {
			if(o.product_id === id) {
			OverlayHandler.showOverlay(Overlays.productOverlay, {product: o, 
				updateCallback: this.loadUsedProducts,
				accountNr: this.state.currentAccount != null ? this.state.currentAccount.account_number : undefined,
				person_id: this.state.user != null ? this.state.user.person_id : -1
			   })
			}
		}
	}

	render() {
		const productsForMenu = this.filterProducts();
		const exceptions = this.getExceptions();
		return (
			<WrapperOuter>
				<RawColumnBox>
					<RawRowBox>
						<RawColumnBox>
							<Expandable 
								open={true}
								children={
									<React.Fragment>
									<MerchantBlock>
										<Merchant>
											{translate('customers.details.merchant.merchantStatus')}
										</Merchant>{' '}
										<Switch
										id={"merchantStatusSwitch"}
											disabled={false}
											defaultValue={this.state.merchant}
											onChange={this.handleMerchantChange}
										/>
									</MerchantBlock>
									{this.state.companyNameSet !== '' ? (
										<Company>{this.state.companyNameSet}</Company>
									) : null}
									<SelectComponent style={{width: '45%'}}>
										<LargeKeyValueBlock style={{maxWidth: '500px'}}>
													<KeyField>
														{translate('customers.details.merchant.current')}
													</KeyField>
													<ValueField>
														<DocumentsWrapper>
															<ImageSelectComponent
																images={this.state.documents}
																callback={this.loadAndShowImage}
															/>
															<AddWrapper
																onClick={(event) => {
																	event.stopPropagation();
																	OverlayHandler.showOverlay(
																		Overlays.multipleFileChooser,
																		{
																			callback: (data: IImageUploads) => {
																				this.setState({
																					toUpload: data
																				})
																			},
																			title: translate('documents.selectDocuments') + ' (10 max)',
																			buttonText: translate('documents.uploadDocuments'),
																			limit: 10
																		}
																	);
																}}>
																{this.state.toUpload != null ? "( " + this.state.toUpload.files.length + " selected ) " : '' }{Icons.addFoto()}
															</AddWrapper>
														</DocumentsWrapper>
													</ValueField>
												</LargeKeyValueBlock>
												{this.state.user != null && this.state.user?.is_merchant ?
												<LargeKeyValueBlock style={{maxWidth: '500px'}}>
												<KeyField>{translate(
													'customers.details.merchant.profile'
												)}:</KeyField>
												<ValueField>
												<DocumentsWrapper>
															<ImageSelectComponent
																images={this.state.profilePictures}
																callback={this.loadAndShowProfile}
															/>
															<AddWrapper
																onClick={(event) => {
																	event.stopPropagation();
																	OverlayHandler.showOverlay(
																		Overlays.fileChooserOverlay,
																		{
																			callback: (data: any) => {
																				this.uploadProfilePicture(data);
																			},
																			title: translate('documents.selectDocuments'),
																		}
																	);
																}}>
																{Icons.addFoto()}
															</AddWrapper>
														</DocumentsWrapper>
												</ValueField>
											</LargeKeyValueBlock>
												: null }
												<div style={{maxWidth: '500px'}}>
													<div style={{display: 'flex'}}>
														<div style={{display: 'flex', width: '50%', minWidth: '160px', flexDirection: 'column', marginRight: '8px'}}>
															<EditableComponent
																translationkey="customers.newCustomer.input.companyname.label"
																id="company"
																initText={this.state.companyName != null ? this.state.companyName : ''
																}
																inputStyle={this.InpStyle}
																enterCallback={(value: string) => {
																	this.setState({
																		companyName: value,
																		index: -1,
																		error: value !== "" ? {} : {merchant: "Companyname must be set"} 
																	})
																}}
																editMode={this.state.index === 1 }
																changeCallback={() => {
																
																}}
																permission={Ident.OperationId.PersonMerchantPut}
																error={this.state.error != null && this.state.error.merchant != null ?this.state.error.merchant : undefined}
															/>
														</div>
															<div style={{display: 'flex', width: '50%', minWidth: '160px', flexDirection: 'column', marginRight: '8px'}}>
																<EditableComponent
																	translationkey="customers.details.merchant.calendar"
																	id="calendar"
																	initText={""}
																	inputStyle={this.InpStyle}
																	enterCallback={(value: string) => {
																		
																	}}
																	editMode={this.state.index === 2 }
																	disabled={this.state.address == null}
																	changeCallback={() => {

																	}}
																	permission={Ident.OperationId.PersonAddressPut}
																/>
														</div>
													</div>
												</div>
												<div style={{maxWidth: '500px'}}>
													<div style={{display: 'flex'}}>
														<div style={{display: 'flex', width: '100%', minWidth: '320px', flexDirection: 'column', marginRight: '8px'}}>
															<EditableComponent
																translationkey="customers.newCustomer.input.merchantDescription.label"
																id="company"
																initText={this.state.merchant_description != null ? this.state.merchant_description : ''
																}
																inputStyle={this.InpStyle}
																enterCallback={(value: string) => {
																	this.setState({
																		merchant_description: value,
																		index: -1,
																	})
																}}
																multineLine={true}
																viewStyle={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'noWrap'}}
																editMode={this.state.index === 3 }
																changeCallback={() => {
																
																}}
																permission={Ident.OperationId.PersonMerchantPut}
																error={this.state.error != null && this.state.error.merchant != null ?this.state.error.merchant : undefined}
															/>
														</div>
													</div>
												</div>
												<div style={{maxWidth: '500px'}}>
													<div style={{display: 'flex'}}>
													<LargeKeyValueBlock style={{display: 'flex', width: '50%', minWidth: '160px', flexDirection: 'column'}}>
														<KeyField style={{display: 'flex'}}>
															Public
														</KeyField>	
														<ValueField>
															<Switch
																id={"merchantPublicSwitch"}
																	disabled={false}
																	defaultValue={this.state.is_public !== false }
																	onChange={(value: boolean) => {
																		this.setState({
																			is_public: value
																		})
																	}}
																/>
														</ValueField>
													</LargeKeyValueBlock>
													</div>
												</div>
												<div style={{maxWidth: '500px'}}>
													<div style={{display: 'flex'}}>
													<LargeKeyValueBlock style={{display: 'flex', width: '50%', minWidth: '160px', flexDirection: 'column'}}>
														<KeyField style={{display: 'flex'}}>
															Healthpoint
														</KeyField>	
														<ValueField>
															<Switch
																id={"merchantHealthpointSwitch"}
																	disabled={false}
																	defaultValue={this.state.is_healthpoint === true }
																	onChange={(value: boolean) => {
																		this.setState({
																			is_healthpoint: value
																		})
																	}}
																/>
														</ValueField>
													</LargeKeyValueBlock>
													</div>
												</div>
												<div style={{maxWidth: '500px'}}>
												<div style={{display: 'flex'}}>
														<div style={{display: 'flex', width: '50%', minWidth: '160px', flexDirection: 'column', marginRight: '8px'}}>
																<EditableComponent
																	translationkey="customers.newCustomer.input.latitude.label"
																	id="latitudre"
																	initText={this.state.address != null && this.state.address.latitude != null? this.state.address.latitude.toString() : ''
																	}
																	inputStyle={this.InpStyle}
																	enterCallback={(value: string) => {
																		if(this.state.address == null ) {
																			return;
																		}
																		if(value === "") {
																			const addr = this.state.address;
																			addr.latitude = undefined;
																			this.setState({
																				address: addr,
																				addressChanged: true,
																				index: -1
																			})
																			return;
																		}
																		if(value.charAt(value.length - 1) === "," || value.charAt(value.length - 1) === ".") {
																			return;
																		}
																		value = value.replace("," , ".");
																		const addr = this.state.address;
																		addr.latitude = parseFloat(value);
																		this.setState({
																			address: addr,
																			addressChanged: true,
																			index: -1
																		})
																	}}
																	error={this.state.address == null ? 'No address available' : undefined}
																	editMode={this.state.index === 4 }
																	disabled={this.state.address == null}
																	changeCallback={() => {

																	}}
																	permission={Ident.OperationId.PersonAddressPut}
																/>
														</div>
														<div style={{display: 'flex', width: '50%', minWidth: '160px', flexDirection: 'column'}}>
															<EditableComponent
																	translationkey="customers.newCustomer.input.longitude.label"
																	id="longitude"
																	initText={this.state.address != null && this.state.address.longitude != null? this.state.address.longitude.toString() : ''
																	}
																	inputStyle={this.InpStyle}
																	enterCallback={(value: string) => {
																		if(this.state.address == null) {
																			return;
																		}
																		if(value === "") {
																			const addr = this.state.address;
																			addr.longitude = undefined;
																			this.setState({
																				address: addr,
																				addressChanged: true,
																				index: -1
																			})
																			return;
																		}
																		if(value.charAt(value.length - 1) === "," || value.charAt(value.length - 1) === ".") {
																			return;
																		}
																		value = value.replace("," , ".");
																		const addr = this.state.address;
																		addr.longitude = parseFloat(value);
																		this.setState({
																			address: addr,
																			addressChanged: true,
																			index: -1
																		})
																	}}
																	error={this.state.address == null ? 'No address available' : undefined}
																	editMode={this.state.index === 5 }
																	disabled={this.state.address == null}
																	changeCallback={() => {

																	}}
																	permission={Ident.OperationId.PersonAddressPut}
																/>
														</div>
													</div>			
												</div>				
											<div style={{maxWidth: '500px'}}> 
											<div style={{display: 'flex'}}>
													<LargeKeyValueBlock style={{display: 'flex', width: '50%', minWidth: '160px', flexDirection: 'column'}}>
														<KeyField style={{display: 'flex'}}>
															{translate(
															'customers.details.merchant.segment'
															)}: 
														</KeyField>	
														<ValueField>
															<ContentCell style={{ color: '#4a4a4a' }}>
																	<NoMarginSelect
																		id="segmentSelect"
																		options={this.getSegmentOptions()}
																		current={this.state.segment != null ? this.state.segment : undefined}
																		notification={this.setSegment}
																		disabled={!PermissionStore.hasPermission(
																			Ident.OperationId.PersonMerchantPut
																		)}
																	/>
															</ContentCell>
														</ValueField>
													</LargeKeyValueBlock>
												<div style={{display: 'flex', width: '50%', minWidth: '160px', flexDirection: 'column'}}>
														<EditableComponent
																translationkey="customers.details.merchant.webaddress"
																id="webaddress"
																initText={this.state.address != null && this.state.address.webaddress != null? this.state.address.webaddress : ''
																}
																inputStyle={{
																	textAlign: 'left',
																	marginBottom: '0px',
																	marginTop: '2px',
																}}
																enterCallback={(value: string) => {
																	if(this.state.address == null || value === '') {
																		return;
																	}
																	const addr = this.state.address;
																	addr.webaddress = value;
																	this.setState({
																		address: addr,
																		addressChanged: true,
																		index: -1
																	})
																}}
																error={this.state.address == null ? 'No address available' : undefined}
																editMode={this.state.index === 6 }
																disabled={this.state.address == null}
																changeCallback={() => {

																}}
																permission={Ident.OperationId.PersonAddressPut}
																/>
													</div>
												</div>
											</div>
											<div style={{maxWidth: '500px'}}> 
											<div style={{display: 'flex'}}>
													<LargeKeyValueBlock style={{display: 'flex', width: '100%', minWidth: '160px', flexDirection: 'column'}}>
														<KeyField style={{display: 'flex'}}>
															{translate(
															'customers.details.merchant.timezone'
															)}: 
														</KeyField>	
														<ValueField>
															<ContentCell style={{ color: '#4a4a4a' }}>
																	<NoMarginSelect
																		id="timezoneSelect"
																		options={this.getTimeZoneOptions()}
																		current={this.state.timezone != null ? this.state.timezone : undefined}
																		notification={this.setTimezone}
																		disabled={!PermissionStore.hasPermission(
																			Ident.OperationId.PersonMerchantPut
																		)}
																	/>
															</ContentCell>
														</ValueField>
													</LargeKeyValueBlock>
													</div>
												</div>
											<ButtonOk
												onClick={this.onSubmit}
												disabled={this.state.timezone == null}
												key={this.state.keyForLoadingSpinner}
												id="btnUploadMerchDoc">
					{translate('customers.details.merchant.upload')}
				</ButtonOk>
								</SelectComponent>
							</React.Fragment> }
						title={translate('customers.details.merchant.uploadDocument')}
						/>
					
					</RawColumnBox >
					<div style={{display: 'flex', flexDirection: 'column', width: '55%', minWidth: '700px'}}>
							<ExpandableWrapper>
								<Expandable
									open={true}
									title={translate('customers.details.merchant.products')}
									children={<SelectComponent style={{ overflow: 'auto', flexWrap: 'nowrap', marginBottom: '16px'}}>
										<ProductFields style={{width: '100%', minWidth: '500px'}}>
												{ this.generateProductFields() }
											</ProductFields>
										</SelectComponent>}
								/>
						</ExpandableWrapper>
						<ExpandableWrapper>															
							<Expandable
								title={translate('customers.details.merchant.calendarData')}>
								<SelectComponent style={{ overflow: 'auto', flexWrap: 'nowrap', marginBottom: '16px'}}>
										<ProductFields style={{width: '100%', minWidth: '500px'}}>
												{this.state.user != null ? <EditableCalendar
																				person_id={this.state.user.person_id} /> : null }
										</ProductFields>
								</SelectComponent>
							</Expandable>
						</ExpandableWrapper>
						<ExpandableWrapper>															
							<Expandable
								title={translate('customers.details.merchant.openCalendarData')}>
								<SelectComponent style={{ overflow: 'auto', flexWrap: 'nowrap', marginBottom: '16px'}}>
										<ProductFields style={{width: '100%', minWidth: '500px'}}>
												{this.state.user != null && this.state.openingCalendar != null ? 
																			<EditableProductCalendar
																				person_id={this.state.user.person_id}
																				calendarData={this.state.openingCalendar}
																				saveCallback={this.setOpenCalendarData} /> : null }
										</ProductFields>
								</SelectComponent>
							</Expandable>
						</ExpandableWrapper>
						<ExpandableWrapper>
							<Expandable
								title={translate('customers.products.types')}>
								<SelectComponent style={{ overflow: 'auto', flexWrap: 'nowrap', marginBottom: '16px'}}>
										<ProductFields style={{width: '100%', minWidth: '500px'}}>
												{ <ProductsTypeComponent currentAccount={this.state.currentAccount?.account_number}   />}
										</ProductFields>
								</SelectComponent>
							</Expandable>
						</ExpandableWrapper>
						<ExpandableWrapper>
							<Expandable
								title={translate('customers.products.signatureStamp')}>
								<SelectComponent style={{ overflow: 'auto', flexWrap: 'nowrap',   marginBottom: '16px'}}>
										<ProductFields style={{width: '100%', minWidth: '500px'}}>
												{<SignatureComponent user={this.state.user} />}
										</ProductFields>
								</SelectComponent>
							</Expandable>
						</ExpandableWrapper>
						<ExpandableWrapper>
							<Expandable
								title={translate('customers.products.paymentMethods')}>
								<SelectComponent style={{ overflow: 'auto', flexWrap: 'nowrap'}}>
										<ProductFields style={{width: '100%', minWidth: '500px'}}>
												<PaymentMethodComponent currentAccount={this.state.currentAccount?.account_number} updateCallback={this.loadUsedProducts} />
										</ProductFields>
								</SelectComponent>
							</Expandable>
						</ExpandableWrapper>
						<ExpandableWrapper>
							<Expandable
								title={"Fiskaly"}>
								<SelectComponent style={{ overflow: 'auto', flexWrap: 'nowrap'}}>
										<ProductFields style={{width: '100%', minWidth: '500px'}}>
												<FiskalyComponent person={this.state.user} />
										</ProductFields>
								</SelectComponent>
							</Expandable>
						</ExpandableWrapper>
				</div>
				</RawRowBox>
					<ExpandableWrapper>
						<Expandable 
							children={<AccessKeyComponent user={this.state.user} currentAccount={this.state.currentAccount?.account_number} />}
							title="eCOS"
						/>
					</ExpandableWrapper>
				</RawColumnBox>
				{
					floatingMenuButton(this.bookProductCallback, 250, ((productsForMenu.length - exceptions.length) * 45),  productsForMenu, exceptions, {} )
				}
			</WrapperOuter>
		);
	}
} 

const Merchant = styled.div`
	margin-right: 84px;
`;

const ExpandableWrapper = styled.div`
	margin-top: 16px;
`;
const Company = styled(Merchant)`
	margin-bottom: 24px;
	font-weight: 600;
	color: rgb(107, 108, 109);
`;

const DelWrapper = styled.div`
	:hover {
		cursor: pointer;
	}
`;

const MerchantBlock = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 40px;
	align-items: center;
	font-weight: 400;
`;

const SelectComponent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-left: 24px;
	max-height: calc(0.65*100vh);`;

const StyledSpacer = styled.div`
	min-height: 32px;
`;


const NoMarginSelect = styled(StyledSelect)`
	margin-top: 0px;
	margin-bottom: 0px;
	select {
		margin-top: 0px;
		margin-bottom: 0px;
	}
`;

const WrapperOuter = styled(FlexBox)`
	flex-direction: column;
	min-height: 400px;
	padding: 32px;
`;

const ProductFields = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;