// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    VerificationAcceptance,
    VerificationSubject,
} from './';

/**
 * @export
 * @interface Verification
 */
export interface Verification {
    /**
     * then Verification ID
     * @type {string}
     * @memberof Verification
     */
    id: string;
    /**
     * @type {string}
     * @memberof Verification
     */
    tenantId?: string;
    /**
     * the video id that was verified
     * @type {string}
     * @memberof Verification
     */
    videoId: string;
    /**
     * the verification request id
     * @type {string}
     * @memberof Verification
     */
    verificationRequestId: string;
    /**
     * the person that did the verification
     * @type {string}
     * @memberof Verification
     */
    verifierId: string;
    /**
     * the Registration Authority this verification was done by
     * @type {string}
     * @memberof Verification
     */
    rauthorityId: string;
    /**
     * unix timestamp in ms, when the verification was done
     * @type {number}
     * @memberof Verification
     */
    verificationDate: number;
    /**
     * @type {number}
     * @memberof Verification
     */
    duration?: number;
    /**
     * @type {string}
     * @memberof Verification
     */
    status: VerificationStatusEnum;
    /**
     * @type {VerificationSubject}
     * @memberof Verification
     */
    subject?: VerificationSubject;
    /**
     * @type {VerificationAcceptance}
     * @memberof Verification
     */
    acceptance?: VerificationAcceptance;
    /**
     * @type {VerificationAcceptance}
     * @memberof Verification
     */
    rejection?: VerificationAcceptance;
}

/**
 * @export
 * @enum {string}
 */
export enum VerificationStatusEnum {
    Accepted = 'Accepted',
    Rejected = 'Rejected'
}

