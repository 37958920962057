// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Sex,
} from './';

/**
 * @export
 * @interface ReplPerson
 */
export interface ReplPerson {
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    name?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    given_name?: string;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    nationality_id?: number;
    /**
     * @type {Date}
     * @memberof ReplPerson
     */
    birth_date?: Date;
    /**
     * @type {Sex}
     * @memberof ReplPerson
     */
    sex?: Sex;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    state: ReplPersonStateEnum;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    timezone?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    language: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    password: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    pin?: string;
    /**
     * @type {Date}
     * @memberof ReplPerson
     */
    valid_from: Date;
    /**
     * @type {boolean}
     * @memberof ReplPerson
     */
    is_merchant: boolean;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    company_name?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    client_profile?: ReplPersonClientProfileEnum;
    /**
     * @type {boolean}
     * @memberof ReplPerson
     */
    is_eu_member?: boolean;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    person_id_primary?: number;
    /**
     * @type {object}
     * @memberof ReplPerson
     */
    enails?: object;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    l_address_id?: number;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    l_country_id?: number;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    l_street?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    l_building_identifier?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    l_suite_identifier?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    l_floor_identifier?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    l_district_name?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    l_post_box?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    l_post_code?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    l_city?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    l_country_subentity?: string;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    l_person_id_create?: number;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    l_longitude?: number;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    l_latitude?: number;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    d_address_id?: number;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    d_country_id?: number;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    d_street?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    d_building_identifier?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    d_suite_identifier?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    d_floor_identifier?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    d_district_name?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    d_post_box?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    d_post_code?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    d_city?: string;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    d_country_subentity?: string;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    d_person_id_create?: number;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    d_longitude?: number;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    d_latitude?: number;
    /**
     * @type {Array<string>}
     * @memberof ReplPerson
     */
    external_systems?: Array<string>;
    /**
     * @type {Array<string>}
     * @memberof ReplPerson
     */
    external_identifiers?: Array<string>;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    website?: string;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    external_service_id?: number;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    kyc_state?: string;
    /**
     * @type {boolean}
     * @memberof ReplPerson
     */
    residient?: boolean;
    /**
     * @type {boolean}
     * @memberof ReplPerson
     */
    pep?: boolean;
    /**
     * @type {boolean}
     * @memberof ReplPerson
     */
    advanced_kyc?: boolean;
    /**
     * @type {number}
     * @memberof ReplPerson
     */
    risk_score?: number;
    /**
     * @type {string}
     * @memberof ReplPerson
     */
    hubspot_id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ReplPersonStateEnum {
    C = 'C',
    V = 'V',
    R = 'R',
    I = 'I',
    A = 'A'
}
/**
 * @export
 * @enum {string}
 */
export enum ReplPersonClientProfileEnum {
    Primary = 'primary',
    Companion = 'companion',
    Joint = 'joint'
}

