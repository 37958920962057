// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    BookingOverview,
} from '../models';

export interface BookingOverviewGetRequest {
    date_from: Date;
    date_to: Date;
}

/**
 * no description
 */
export class BookingApi extends BaseAPI implements IOperationIdMap {

    /**
     */
    bookingOverviewGet = (requestParameters: BookingOverviewGetRequest): Observable<Array<BookingOverview>> => {
        throwIfRequired(requestParameters, 'date_from', 'bookingOverviewGet');
        throwIfRequired(requestParameters, 'date_to', 'bookingOverviewGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<Array<BookingOverview>>({
            path: '/booking/booking_overview',
            method: 'GET',
            headers,
            query,
        });
    };


    operationToOperationId = {
	    "bookingOverviewGet": "booking_overview_get"
    }
}
