
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import {  api, apis, ApiError, Account, VoidResponse, Ident } from '../../../../../logic/api';
import { Log, Logs } from '../../../../../logic/log';
import { translate } from '../../../../../common/language/translate';
import { ListEntry, MainField, NameField } from '../basicStyledComponents/customerDetails.css';


interface IProps {
	user: Ident.Person;
 };

export const SignatureComponent: FunctionComponent<IProps> = (props: IProps) => {

    const [stamp, setStamp]    = useState<Blob>();
	const [signature, setSignature] = useState<Blob>();

	function getSignatureAndStamp() {
        let req: Ident.ServicePersonDocumentGetRequest = {
            person_id: props.user.person_id,
			token: 'Kq26*k8%QX%T',
			document_type: Ident.ServicePersonDocumentGetDocumentTypeEnum.P
        };
        api.asyncRequest<Blob>(
            req,
            apis.ServiceApi,
            'servicePersonDocumentGet'
        ).then( (response:Blob) => {
            setStamp(response);

        }).catch((error: ApiError) => {
            Log.error(Logs.API, error);
        })
		req = {
            person_id: props.user.person_id,
			token: 'Kq26*k8%QX%T',
			document_type: Ident.ServicePersonDocumentGetDocumentTypeEnum.S
        };
        api.asyncRequest<Blob>(
            req,
            apis.ServiceApi,
            'servicePersonDocumentGet'
        ).then( (response:Blob) => {
            setSignature(response);

        }).catch((error: ApiError) => {
            Log.error(Logs.API, error);
        })
    }

	function generateSignature(): Array<ReactElement> {
		const out = [];
		if(signature != null) {
			const url = URL.createObjectURL(signature)
			out.push(<MainField>
                        <NameField>
							{translate('customers.products.signature')}
                        </NameField>
						<div style={{maxWidth: '128px' }}>
							<img src={url }/>
						</div>
					</MainField>);		
		}
		if(stamp != null) {
			const url = URL.createObjectURL(stamp)
			out.push(<MainField>
                        <NameField>
							{translate('customers.products.stamp')}
                        </NameField>
						<div style={{maxWidth: '128px' }}>
							<img src={url}/>
						</div>
					</MainField>);			
		}	
		if(stamp == null && signature == null) {
			out.push(
                <ListEntry>
                    Nothing found
                </ListEntry>
            )
		}	
        return out;
	}
  
    useEffect(() => {
        getSignatureAndStamp();
    }, [])

    return (
        <React.Fragment>
            {generateSignature()}
        </React.Fragment>
    );
}

