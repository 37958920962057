// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    NewAddress,
} from './';

/**
 * @export
 * @interface CoronaTestResult
 */
export interface CoronaTestResult {
    /**
     * @type {number}
     * @memberof CoronaTestResult
     */
    ta_id: number;
    /**
     * @type {number}
     * @memberof CoronaTestResult
     */
    transaction_id: number;
    /**
     * @type {string}
     * @memberof CoronaTestResult
     */
    test_result: CoronaTestResultTestResultEnum;
    /**
     * @type {string}
     * @memberof CoronaTestResult
     */
    doctor: string;
    /**
     * @type {string}
     * @memberof CoronaTestResult
     */
    remark?: string;
    /**
     * @type {string}
     * @memberof CoronaTestResult
     */
    ct_value?: string;
    /**
     * the laboratory that analysed the test
     * @type {string}
     * @memberof CoronaTestResult
     */
    laboratory_name: string;
    /**
     * @type {NewAddress}
     * @memberof CoronaTestResult
     */
    laboratory_address: NewAddress;
    /**
     * @type {number}
     * @memberof CoronaTestResult
     */
    laboratory_id?: number;
    /**
     * @type {number}
     * @memberof CoronaTestResult
     */
    gw_id?: number;
    /**
     * @type {string}
     * @memberof CoronaTestResult
     */
    gw_hash?: string;
    /**
     * @type {string}
     * @memberof CoronaTestResult
     */
    gw_url?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CoronaTestResultTestResultEnum {
    Positive = 'positive',
    Negative = 'negative',
    Void = 'void',
    NoResult = 'no_result'
}

