// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * I - ntern, if account is known * E - xtern, relevant for iban and email * F - faild, relevant for account, iban, email and phone 
 * @export
 * @enum {string}
 */
export enum ClassifyResponse {
    I = 'I',
    E = 'E',
    F = 'F'
}

