import React, { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import {
	IStatusState,
	Reporter,
} from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import {
    api, 
    apis, 
    ApiError, 
    Account,
    VoidResponse
} from '../../../../../logic/api';
import {MessageHandler} from '../../../../../logic/handler/messagehandler/messageHandler';
import {translate} from '../../../../../common/language/translate';
import {
	IStateOver,
	IPropsOver,
	AOverlay,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import {
	IInitProps,
	IInitState,
} from '../../../../../logic/handler/initialdatahandler/initialDataComponent';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import ButtonCancel from '../../../../../components/atomiccompoents/buttons/buttonCancel';
import Title from '../../../../../components/compositcomponents/title';
import { 
    OverlayHandler, 
    Overlays } from '../../../../../logic/handler/overlayhandler/overlayHandler';
import EditableComponent from '../../../../../components/atomiccompoents/editableComponent/editableComponent';
import { KeyField, KeyValueBlock, LargeKeyValueBlock, StyledSelect, StyledViewButtons, ValueField } from '../basicStyledComponents/customerDetails.css';
import { CheckBox } from '../../../../../components/atomiccompoents/form/checkbox';
import { IOption } from '../../../../../components/atomiccompoents/form';
import { deepCopy, evaluateErrorMessage } from '../../../../../logic/helper/Common';
import { format } from '../../../../../logic/helper/format';
import { Log, Logs } from '../../../../../logic/log';
import Expandable from '../../../../../components/compositcomponents/expandable/expandable';
import { EditableProductCalendar } from '../../../../../components/compositcomponents/editableCalendar/editableProductCalendar';
import KeyCheckBoxComponent from '../../../../../components/atomiccompoents/keyCheckboxComponent';


const StyledOverlay = styled.div`
	margin: auto;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 50px;
	padding-top: 20;
	display: flex;
	flex-direction: column;
	width: 800px;
	height: 90vh;
    max-height: 1150px;
	align-items: center;
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	background-color: white;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	overflow-y: auto;
`;

interface IEditableProduct extends Account.Product {
    stringPrice?: string;
    stringVat: string;
    stringProv: string;
}

interface IProductPaymentMethod {
    payment_method_id: number;
    name: string;
}

interface IProps extends IInitProps, IPropsOver {
    product: Account.Product;
    updateCallback: () => void;
    commissionOnlyCallback: (updatedProduct: {product_id: Account.Product}) => void;
    accountNr?: string;
    person_id: number;
}
interface IState extends IStatusState, IInitState, IStateOver {
    product: IEditableProduct;
    keyForLoadingSpinner: number;
    disabled: boolean;
    commissionChanged?: boolean;
    differing_comission? : string;
    time?: Date | undefined;
    voucherAmount?: string;
    product_calendar?: Account.ProductCalendar;
    merchantPaymentMethods?: Array<Account.PaymentMethod>;
    currentProductPaymentMethods?: Array<IProductPaymentMethod>;
    product_images?: Array<{url: string, id: number}>
}

export default class ProductOverlay extends AOverlay<IProps, IState> {
    private changeTracker: Array<string> = [];
    private exRef = React.createRef<Expandable>();
    constructor(props: IProps) {
        super(props);
        const prod = deepCopy(props.product) as IEditableProduct;
        prod.price = prod.price != null ? prod.price/100 : prod.price;
        prod.stringPrice = prod.price != null ? format.number(prod.price, 2) : "";
        prod.stringVat = format.number(prod.vat, 2);
        prod.stringProv = prod.merchant_provision != null ? format.number(prod.merchant_provision,2) : "" ; 
        const voucherAmount = prod.voucher_amount != null ? format.number(prod.voucher_amount, 2 ) : undefined;
        let date = undefined;
        if (props.product.end_time != null) {
            const parsed = props.product.end_time.split(":");
            date = new Date;
            date.setHours(parseInt(parsed[0]));
            date.setMinutes(parseInt(parsed[1]));
        }
       
    	this.state = {
            product: prod,
            disabled: true,
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
            time: date,
            voucherAmount: voucherAmount,
            currentProductPaymentMethods: deepCopy(prod.payment_methods ?? []) as Array<IProductPaymentMethod>
        };
        this.handleCurrencySelect = this.handleCurrencySelect.bind(this);
        this.setBooleanData = this.setBooleanData.bind(this);
        this.imageCallback = this.imageCallback.bind(this);
        this.getCalendarData();
        this.loadPaymentmethods();
        
    }
    reviveState() {

    }
    stopPropagation(event: SyntheticEvent) {
    	event.stopPropagation();
    }
    onCancel() {
        OverlayHandler.closeSpecific(Overlays.productOverlay);
    }

    loadPaymentmethods() {
		const account_nr = this.props.accountNr ;
 		const req: Account.MerchantPaymentMethodsGetRequest = {
			merchant_account_number: account_nr
		};
		api.asyncRequest<Array<Account.PaymentMethod>>(req, apis.MerchantApi, 'merchantPaymentMethodsGet')
				.then((response: Array<Account.PaymentMethod> ) => {
					const value = response.length === 0 ? [] : response;
                    this.setState({
                        merchantPaymentMethods: value
                    })
				})
				.catch((error: ApiError) => {
					Log.debug(Logs.API, error);
				});
	}

    paymentMethodChanged() : boolean {
        const a = this.state.currentProductPaymentMethods;
        const b = this.state.product.payment_methods;

        if(b == null) {
            return false;
        }
        for(const o of a) {
            if(b.find((value: IProductPaymentMethod) => {
                return value.name === o.name
            }) == null) {
                return true;
            }
        }
        return false;
    }

    getCalendarData() {
		const req: Account.MerchantPersonIdProductCalendarGetRequest = {
			person_id: this.props.person_id			
		};

		api.asyncRequest<Array<Account.ProductCalendar>>(req, apis.MerchantApi, 'merchantPersonIdProductCalendarGet')
			.then((response: Array<Account.ProductCalendar> ) => {
                let calendar: Account.ProductCalendar | undefined = undefined;
                if(response.length != 0) {
                    for(const o of response) {
                        if(o.product_id === this.props.product.product_id) {
                            calendar = o;
                        }
                    }
                }
                if(calendar == null) {
                    calendar = {
                        product_id: this.props.product.product_id,
                        product_calendar_items: [],
                        date_range_days: 15
                    }
                }
                
 				this.setState({
                    product_calendar: calendar
				})
			}).catch((error: ApiError) => {
				Log.error(Logs.API, error);
			});
	}

    onSave() {
        if(this.props.accountNr == null || this.props.product.product_id == null)  {
            this.props.selfClose()
            return;
        }
        const req: Account.MerchantProductPutRequest = {
			account_number: this.props.accountNr,
			product_id: this.props.product.product_id,
         
			MerchantProduct: {
                merchant_provision: this.state.product.stringProv != null ? parseFloat(this.state.product.stringProv.replace(",", ".")) : this.state.product.merchant_provision,
                claim: this.state.product.claim,
                currency: this.state.product.currency,
                name: this.state.product.name,
                product_image_ids: this.state.product.images != null ?  this.state.product.images.map((value: {merchant_product_image_id: number, url: string}) => { return value.merchant_product_image_id } ) : undefined,
                long_description: this.state.product.long_description,
                price: this.state.product.stringPrice != null ?  Math.round(parseFloat(this.state.product.stringPrice.replace(",", "."))*100) : this.state.product.price,
                vat: parseFloat(this.state.product.stringVat.replace(",", ".")),
                payment_methods: this.paymentMethodChanged() ? this.state.product.payment_methods.map((value: IProductPaymentMethod) =>  { return value.name as Account.PaymentMethod }) : undefined
			}
		};
        if (this.state.time != null && req.MerchantProduct != null) {
            const timestr = this.state.time.getHours().toString() + ":" + this.state.time.getMinutes().toString() + ":" + this.state.time.getSeconds().toString();
            req.MerchantProduct.end_time = timestr;
        }
        if (this.state.voucherAmount != null && this.state.voucherAmount !== '' && req.MerchantProduct != null) {
            const amount = parseFloat(this.state.voucherAmount);
            if (!isNaN(amount) && amount < 10000) {
                req.MerchantProduct.voucher_amount = amount;
            } else {
                MessageHandler.onError(Reporter['product.voucher_amount']);
                this.setState({
                    keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
                });
                return;
            }
        }

		api.asyncRequest<Array<Account.Product>>(req, apis.MerchantApi, 'merchantProductPut')
				.then(() => {
                    MessageHandler.onSuccess(Reporter['product.put']);
                    const calendar = this.state.product_calendar;
                    if(calendar != null) {
                    
                        const req: Account.MerchantPersonIdProductCalendarPutRequest = {
                            ProductCalendar: calendar		,
                            person_id: this.props.person_id
                        };
                                
                        api.asyncRequest<VoidResponse>(req, apis.MerchantApi, 'merchantPersonIdProductCalendarPut')
                                .then(() => {					
                                    MessageHandler.onSuccess(Reporter['person.merchant.put.calendar']);
                                    this.getCalendarData()
                                })
                                .catch((error: ApiError) => {
                                    MessageHandler.onError(Reporter['person.merchant.put.calendar'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
                                });
                    }
                    this.setState({
                        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
                    })
                    this.props.updateCallback();
                    this.props.selfClose();					
				})
				.catch((error: ApiError) => {
					MessageHandler.onError(Reporter['product.put'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
                    this.setState({
                        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
                    })
				});
	}

    onCreateKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    	// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    	if (event.key === 'Enter') {
    		event.preventDefault();
    		event.stopPropagation();
    	}
    };

    setData(key: string, value: string) {
        const prd = this.state.product;
        //@ts-ignore
        prd[key as keyof IEditableProduct] = value;
        this.setState({
            product: prd,
            disabled: false
        })
    }

    setBooleanData(key: string, value: boolean) {
        const prd = this.state.product;
        //@ts-ignore
        prd[key as keyof Account.Product] = value;
        this.setState({
            product: prd,
            disabled: false
        })
    }

	getCurrencyOptions(): Array<IOption> {
        const options: Array<IOption> = [];
        options.push({
            key: 'defaultOptionKey',
            name: "Select currency",
            value: ""
        });
        for(const o in Account.Iso4217Currency) {
            options.push({
				key: 'defaultOptionKey' + o,
				name: o,
				value: o
			});
        }
 		return options;
    }

    handleCurrencySelect(message: any) {
        const prd = this.state.product;
        //@ts-ignore
        prd.currency= message.currency;
		this.setState({
			product: prd 
		});
	}

    imageCallback(imageUrls: Array<{ merchant_product_image_id: number, url: string}>) {
        const prd = this.state.product;
        prd.images = imageUrls;
        this.setState({
            product: prd
        })
    }

    generateProductView(): Array<ReactElement> {
        const out = [];
        
        out.push(
            <React.Fragment>
                <TwoField>
                    <EditableComponent 
                        initText={this.state.product.product_id != null ? this.state.product.product_id.toString() : ''}
                        translationkey='customers.products.productid'
                        disabled={true}
                        changeCallback={() => {}}
                        enterCallback={() => {}}
                        id='product_id'
                    />
                </TwoField>
                <TwoField>
                    <EditableComponent 
                        initText={this.state.product.name != null ? this.state.product.name : ''}
                        translationkey='customers.products.name'
                        disabled={false}
                        changeCallback={(value: string) => {
                            this.setData('name', value);
                        }}
                        enterCallback={() => {}}
                        id='name'
                    />
                </TwoField>
            </React.Fragment>
        );
        out.push(
            <OneField>
                 <EditableComponent 
                    initText={this.state.product.long_description != null ? this.state.product.long_description : ''}
                    translationkey='customers.products.description'
                    disabled={false}
                    changeCallback={(value: string) => {
                        this.setData('long_description', value);
                    }}
                    multineLine={true}
                    enterCallback={() => {}}
                    id='description'
                    wrapperStyle={{height: '124px', maxWidth: '100%'}}
                    viewStyle={{height: '72px'}}
                />
            </OneField>
        );
        out.push(
            <OneField>
                    <EditableComponent 
                        initText={this.state.product.claim != null ? this.state.product.claim : ''}
                        translationkey='customers.products.claim'
                        disabled={false}
                        wrapperStyle={{maxWidth: '100%'}}
                        changeCallback={(value: string) => {
                            this.setData('claim', value);
                        }}
                        enterCallback={() => {}}
                        id='claim'
                    />
                </OneField>
        )
        out.push(
            <React.Fragment>
                 <TwoField>
                    <EditableComponent 
                        initText={this.state.product.stringProv != null ? this.state.product.stringProv : ''}
                        translationkey='customers.products.default_merchant_provision'
                        disabled={false}
                        wrapperStyle={{maxWidth: '100%'}}
                        changeCallback={(value: string) => {
                            this.setData('stringProv', value);
                        }}
                        enterCallback={() => {}}
                        id='merchant_provision'
                    />
                </TwoField>
                <TwoField>
                    <EditableComponent 
                        initText={this.state.product.stringVat != null ? this.state.product.stringVat : ''}
                        translationkey='customers.products.vat'
                        disabled={false}
                        wrapperStyle={{maxWidth: '100%'}}
                        changeCallback={(value: string) => {
                            this.setData('stringVat', value);
                        }}
                        enterCallback={() => {}}
                        id='vat'
                    />
                </TwoField>
            </React.Fragment>
        );
        out.push(
            <OneField>
                <KeyValueBlock style={{maxWidth: '100%'}}>
                    <KeyField>
                        {translate('customers.products.image')}
                    </KeyField>
                    <HoverField style={{display: 'flex', justifyContent: 'center'}} 
                                onClick= {() => {
                                    OverlayHandler.showOverlay(Overlays.selectProductImageOverlay, { callback: this.imageCallback, images: this.state.product?.images ?? []})
                                }}> 
                      <div style={{display: 'flex', maxWidth: '700px', whiteSpace: 'nowrap', overflowX: 'auto'}}>
                        {this.state.product.images != null  
                            ? this.state.product.images.map((value: {merchant_product_image_id: number, url: string}) => {
                                return <img style={{maxHeight: '60px',  marginLeft: '16px'}} alt="no image to show" src={value.url != null ? value.url : ''}  /> 
                            })
                            : "-" }
                     </div>
                      </HoverField>
                </KeyValueBlock>
            </OneField>
        );
        out.push(
            <React.Fragment>
                <TwoField>
                    <EditableComponent 
                        initText={this.state.product.product_package != null ? (this.state.product.product_package) : ''}
                        translationkey='customers.products.package'
                        disabled={true}
                        changeCallback={(value: string) => {
                            
                        }}
                        enterCallback={() => {}}
                        id='package'
                    />
                </TwoField>
                <TwoField>
                    <EditableComponent 
                        initText={this.state.product.unique_group != null ? (this.state.product.unique_group) : ''}
                        translationkey='customers.products.group'
                        disabled={true}
                        changeCallback={(value: string) => {
                            
                        }}
                        enterCallback={() => {}}
                        id='group'
                    />
                </TwoField>
            </React.Fragment>
        );
        out.push(
            <React.Fragment>
                <TwoField>
                    <EditableComponent 
                        initText={this.state.product.stringPrice != null ? (this.state.product.stringPrice) : ''}
                        translationkey='customers.products.price'
                        disabled={false}
                        changeCallback={(value: string) => {
                            this.setData('stringPrice', value);
                        }}
                        enterCallback={() => {}}
                        id='price'
                    />
                </TwoField>
                <TwoField>
                    <LargeKeyValueBlock style={{height: '62px'}}>
                        <KeyField>{translate('customers.currency')}:</KeyField>
                        <ValueField>
                            <SmallSelect
                                id="currency"
                                options={this.getCurrencyOptions()}
                                current={this.state.product.currency != null ? this.state.product.currency : ''}
                                notification={this.handleCurrencySelect}
                            />
                        </ValueField>
                    </LargeKeyValueBlock>
                </TwoField>
            </React.Fragment>
        );
        out.push(
            <React.Fragment>
                <TwoField>
                    <EditableComponent 
                        initText={this.state.product.type_of_test != null ? (this.state.product.type_of_test.toString()) : ''}
                        translationkey='customers.products.typeOfTest'
                        disabled={true}
                        changeCallback={(value: string) => {
                           
                        }}
                        enterCallback={() => {}}
                        id='typeOfTest'
                    />
                </TwoField>
                <TwoField>
                    <LargeKeyValueBlock style={{height: '62px'}}>
                        <KeyField>{translate('customers.products.noweda')}:</KeyField>
                        <ValueField>
                            <CheckBox 
                                id="noweda"
                                defaultValue={this.state.product.is_noweda_standard === true}
                                disabled={true}
                            />
                        </ValueField>
                    </LargeKeyValueBlock>
                </TwoField>
            </React.Fragment>
        );

        out.push(
            <React.Fragment>
                <OneField>
                   <EditableComponent 
                        initText={this.state.voucherAmount != null ? (this.state.voucherAmount) : ''}
                        translationkey='customers.products.voucherAmount'
                        disabled={false}
                        changeCallback={(value: string) => {
                           this.setState({
                               voucherAmount: value
                           })
                        }}
                        wrapperStyle={{height: '92px'}}
                        enterCallback={() => {}}
                        id='voucherAmount'
                    />
                </OneField>
            </React.Fragment>
        );

        out.push(
            <React.Fragment>
                <Expandable title={translate('customers.details.merchant.calendarData')} ref={this.exRef}>
                    {this.state.product_calendar != null ? 
                    <EditableProductCalendar 
                        person_id={this.props.person_id}
                        calendarData={this.state.product_calendar}   
                        saveCallback= { (calendar: Account.ProductCalendar) => {
                            this.setState({
                                product_calendar: calendar
                            })
                            if(this.exRef.current != null) {
                                this.exRef.current.setOpenFromExtern(false);
                            }
                        }}               
                    /> : <React.Fragment/> }
                </Expandable>
            </React.Fragment>
        )               
        out.push(
            <OneField style={{marginTop: '16px'}}>
                <Expandable title={translate('customers.products.paymentMethods')} ref={this.exRef}>
                    {this.state.merchantPaymentMethods != null ? 
                        this.state.merchantPaymentMethods.map((elem: Account.PaymentMethod) => {
                            return <KeyCheckBoxComponent 
                                id={elem}
                                required={false}
                                translationkey={elem}
                                value={ this.state.product.payment_methods == null ? false :
                                        this.state.product.payment_methods.find((value: {payment_method_id: number, name: string}) => {
                                    return value.name === elem;
                                }) == null ? false : true}
                                onChange={
                                    (value: boolean) => {                                       
                                        const prd = this.state.product;
                                        if(prd.payment_methods == null) {
                                            prd.payment_methods = [];
                                        }
                                        if(value) {
                                            prd.payment_methods.push({name: elem, payment_method_id: -1})
                                        } else {
                                            for(const i in prd.payment_methods) {
                                                if(prd.payment_methods[i].name === elem) {
                                                    prd.payment_methods.splice(parseInt(i), 1);
                                                }
                                            }
                                        }
                                        this.setState({
                                            product: prd
                                        })
                                    }
                                }
                            />
                        })
                    : <React.Fragment/> }
                </Expandable>
            </OneField>
        )
        
        return out;
    }


    defineContent() {
    	return (
    		<StyledOverlay onClick={event => event.stopPropagation()}>
                <Title title={translate('customers.products.title')} />
                <ProductsBox>
                    { this.generateProductView()}
                </ProductsBox>
                <StyledViewButtons>
                    <ButtonCancel
                        id="btnEditCustomerEmailCancel"
                        onClick={this.onCancel} >                                
                        {translate('button.cancel')}
                    </ButtonCancel>  
                    <ButtonOk 
                        key={this.state.keyForLoadingSpinner}
                        disabled={this.state.product == null || this.state.product.vat == null}
                        onClick={(event: SyntheticEvent) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.onSave()
                            }
                        }
                    > {translate('button.ok')}</ButtonOk>
                </StyledViewButtons>
    		</StyledOverlay>
    	);
    }
}
const ProductsBox = styled.div`
    width: 95%;
    padding: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;
const OneField = styled.div`
    width: 100%;
`;
const TwoField = styled.div`
    width: 50%;
`;
const ThreeField = styled.div`
    width: 33%;
`;
const SmallSelect = styled(StyledSelect)`
    max-height: 14px;
    margin-top: 2px;
    
`;
const HoverField = styled(ValueField)`
    :hover {
        cursor: pointer
    }
`;