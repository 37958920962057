// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CountryCodeAlpha2,
    Iso4217CurrencyOUT,
} from './';

/**
 * @export
 * @interface AccountFeePackage
 */
export interface AccountFeePackage {
    /**
     * @type {string}
     * @memberof AccountFeePackage
     */
    account_number?: string;
    /**
     * @type {string}
     * @memberof AccountFeePackage
     */
    name?: string;
    /**
     * @type {string}
     * @memberof AccountFeePackage
     */
    given_name?: string;
    /**
     * @type {CountryCodeAlpha2}
     * @memberof AccountFeePackage
     */
    country?: CountryCodeAlpha2;
    /**
     * @type {string}
     * @memberof AccountFeePackage
     */
    fee_package?: string;
    /**
     * @type {Date}
     * @memberof AccountFeePackage
     */
    datetime_create?: Date;
    /**
     * @type {Date}
     * @memberof AccountFeePackage
     */
    datetime_delete?: Date;
    /**
     * @type {string}
     * @memberof AccountFeePackage
     */
    validity?: AccountFeePackageValidityEnum;
    /**
     * @type {number}
     * @memberof AccountFeePackage
     */
    package_price?: number;
    /**
     * @type {Iso4217CurrencyOUT}
     * @memberof AccountFeePackage
     */
    package_currency?: Iso4217CurrencyOUT;
    /**
     * @type {string}
     * @memberof AccountFeePackage
     */
    state?: AccountFeePackageStateEnum;
    /**
     * @type {string}
     * @memberof AccountFeePackage
     */
    person_create?: string;
    /**
     * @type {string}
     * @memberof AccountFeePackage
     */
    person_delete?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AccountFeePackageValidityEnum {
    D = 'D',
    W = 'W',
    M = 'M',
    Q = 'Q',
    Y = 'Y',
    I = 'I'
}
/**
 * @export
 * @enum {string}
 */
export enum AccountFeePackageStateEnum {
    A = 'A',
    D = 'D'
}

