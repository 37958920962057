import styled from 'styled-components';
import { FlexBox } from '../../../auth/auth.css';

export const StyledLine = styled.hr`
	margin-top: 16px;
	margin-bottom: 16px;
	border-bottom-color: #f2f4f6;
	border-bottom-width: 1;
`;

export const StyledDivHeader = styled(FlexBox)`
	display: flex;
	align-items: center;
`;

export const StyledSpanHeaderText = styled.div`
	margin-left: 16px;
	font-weight: 500;
	text-transform: uppercase;
	color: ${(props) => props.theme.Global.lightFontColor};
`;
export const TransitionBox = styled(FlexBox)<{detailsOpen: boolean, maxHeight: string}>`
	max-height:  ${(props) => (props.detailsOpen !== true ? '0' : props.maxHeight)};
	overflow: hidden;
	transition-property: max-height;
	transition: all 1s ease-in-out;	
`;