import { Account, Ident } from '../../../logic/api';
import { Log, Logs } from '../../../logic/log';

export default class JsonFilter {
	data: any = {};
	dataIdent: any ={};

	constructor() {
		this.setupData();
	}

	loadData(url: string, callback: (response: any) => void): any {
		fetch(url)
			.then((response) => response.text())
			.then((text) => callback(text))
			.catch((error: any) => {
				Log.error(Logs.PARSER, error);
				callback(undefined);
			});
	}

	getRequestBody(path: string, method: string) {
		return this.data.paths[path][method].requestBody;
	}

	replaceRefs(content: Account.TransactionTemplate | Ident.TransactionTemplate, ident?: boolean) : any {
		let textContent = JSON.stringify(content);
		const regexp: RegExp = /\{[\s\n\r]*"\$ref"\s*:\s*"(.*?)"[\s\n\r]*\}/gm;
		let reg = regexp.exec(textContent);
		while (reg != null && reg.length > 1) {
			//let path = matches[match].slice(7, matches[match].length-1);
			const path = reg[1];
			Log.debug(Logs.PARSER, 'Replacing: ' + path);
			const pathKey = path.split('/');
			let replaceVal = ident === true ? this.dataIdent : this.data;
			try {
				for (let i = 1; i < pathKey.length; i++) {
					if (replaceVal == null) {
						return null;
					}
					replaceVal = replaceVal[pathKey[i]];
				}
				const replaceString = JSON.stringify(replaceVal);
				textContent = textContent.replace(reg[0], replaceString);
				regexp.lastIndex = 0;
				reg = regexp.exec(textContent);
			} catch(e) {
					console.log(e, replaceVal, pathKey, textContent, content, ident === true ? this.dataIdent : this.data );
			}
		}
		return JSON.parse(textContent);
	}

	setupData() {
		this.data = this.loadData('/core/api-docs/openapi.json', (response: any) => {
			try {
				this.data = JSON.parse(response);
			} catch (e) {
				this.data = JSON.parse('{}');
				console.log(JSON, 'error');
			}
		});
		this.dataIdent = this.loadData('/ident/api-docs/openapi.json', (response: any) => {
			try {
				this.dataIdent = JSON.parse(response);
			} catch (e) {
				this.dataIdent = JSON.parse('{}');
				console.log(JSON, 'error');
			}
		});
	}

	getSchemaName(content: Account.TransactionTemplate): string {
		const textContent = JSON.stringify(content);
		const regexp: RegExp = /\{[\s\n\r]*"\$ref"\s*:\s*"(.*?)"[\s\n\r]*\}/gm;
		const reg = regexp.exec(textContent);

		if (reg != null) {
			const path = reg[1];
			const pathKey = path.split('/');
			const schemaName = pathKey[pathKey.length - 1];
			Log.info(Logs.PARSER, 'schemaName: ' + schemaName);

			return schemaName;
		} else {
			Log.debug(Logs.PARSER, 'Error getting schemaName');
		}
		return '';
	}
}
