// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CustomerProfile
 */
export interface CustomerProfile {
    /**
     * the email address
     * @type {string}
     * @memberof CustomerProfile
     */
    email_address?: string;
    /**
     * name of the external account owner
     * @type {string}
     * @memberof CustomerProfile
     */
    name?: string;
    /**
     * given name of the external account owner
     * @type {string}
     * @memberof CustomerProfile
     */
    given_name?: string;
    /**
     * @type {string}
     * @memberof CustomerProfile
     */
    sex?: CustomerProfileSexEnum;
    /**
     * date of birth
     * @type {Date}
     * @memberof CustomerProfile
     */
    birth_date?: Date;
    /**
     * place of birth
     * @type {string}
     * @memberof CustomerProfile
     */
    birth_city?: string;
    /**
     * @type {string}
     * @memberof CustomerProfile
     */
    nationality?: string;
    /**
     * @type {string}
     * @memberof CustomerProfile
     */
    phone_number?: string;
    /**
     * street as part of address
     * @type {string}
     * @memberof CustomerProfile
     */
    street?: string;
    /**
     * building as part of address
     * @type {string}
     * @memberof CustomerProfile
     */
    building_identifier?: string;
    /**
     * city as part of address
     * @type {string}
     * @memberof CustomerProfile
     */
    city?: string;
    /**
     * Postal code/ZIP code
     * @type {string}
     * @memberof CustomerProfile
     */
    post_code?: string;
    /**
     * @type {string}
     * @memberof CustomerProfile
     */
    country?: string;
    /**
     * @type {string}
     * @memberof CustomerProfile
     */
    image?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CustomerProfileSexEnum {
    M = 'M',
    F = 'F',
    U = 'U'
}

