// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface SegmentTransactionSums
 */
export interface SegmentTransactionSums {
    /**
     * @type {string}
     * @memberof SegmentTransactionSums
     */
    account_number?: string;
    /**
     * @type {string}
     * @memberof SegmentTransactionSums
     */
    segment?: SegmentTransactionSumsSegmentEnum;
    /**
     * @type {string}
     * @memberof SegmentTransactionSums
     */
    external_id?: string;
    /**
     * @type {string}
     * @memberof SegmentTransactionSums
     */
    merchant_name?: string;
    /**
     * @type {string}
     * @memberof SegmentTransactionSums
     */
    product?: string;
    /**
     * @type {string}
     * @memberof SegmentTransactionSums
     */
    _package?: string;
    /**
     * @type {string}
     * @memberof SegmentTransactionSums
     */
    booking_datetime?: string;
    /**
     * @type {number}
     * @memberof SegmentTransactionSums
     */
    sum_performed?: number;
    /**
     * @type {number}
     * @memberof SegmentTransactionSums
     */
    sum_cancelled?: number;
    /**
     * @type {number}
     * @memberof SegmentTransactionSums
     */
    sum_pending?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum SegmentTransactionSumsSegmentEnum {
    Restaurant = 'Restaurant',
    Club = 'Club',
    EcoCare = 'EcoCare',
    Military = 'Military',
    Canteen = 'Canteen',
    Noweda = 'Noweda',
    All = 'All',
    PharmaDirekt = 'PharmaDirekt',
    Testcenter = 'Testcenter',
    Kaufland = 'Kaufland',
    Lidl = 'Lidl',
    Airport = 'Airport',
    CityTestcenter = 'City-Testcenter',
    LifeStyle = 'LifeStyle'
}

