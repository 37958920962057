// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface MoorwandCreateIban
 */
export interface MoorwandCreateIban {
    /**
     * @type {number}
     * @memberof MoorwandCreateIban
     */
    iban_request_id: number;
    /**
     * - 1 - confirmed - 2 - pending - 3 - declined by admin - 4 - failed - 5 - done - 6 - Request sent and waiting for result
     * @type {number}
     * @memberof MoorwandCreateIban
     */
    iban_request_status: MoorwandCreateIbanIbanRequestStatusEnum;
    /**
     * @type {number}
     * @memberof MoorwandCreateIban
     */
    account_id: number;
    /**
     * @type {string}
     * @memberof MoorwandCreateIban
     */
    currency_code?: string;
    /**
     * @type {number}
     * @memberof MoorwandCreateIban
     */
    iban_id?: number;
    /**
     * Bank account address - IBAN number
     * @type {object}
     * @memberof MoorwandCreateIban
     */
    iban: object;
    /**
     * Bank account address - account name
     * @type {string}
     * @memberof MoorwandCreateIban
     */
    account_name?: string;
    /**
     * Bank account address - account number
     * @type {string}
     * @memberof MoorwandCreateIban
     */
    account_number: string;
    /**
     * Bank account address - sort code. needs to be nullable
     * @type {object}
     * @memberof MoorwandCreateIban
     */
    sort_code?: object;
    /**
     * Bank account address - BIC code.
     * @type {string}
     * @memberof MoorwandCreateIban
     */
    bic?: string;
    /**
     * Bank account address inbound status
     * @type {number}
     * @memberof MoorwandCreateIban
     */
    inbound_enabled?: number;
    /**
     * Bank account address outbound status
     * @type {number}
     * @memberof MoorwandCreateIban
     */
    outbound_enabled?: number;
    /**
     * See IBAN bank providers
     * @type {number}
     * @memberof MoorwandCreateIban
     */
    iban_bank_provider_id?: number;
    /**
     * Request reference that has been provided in IBAN create call. needs to be nullable
     * @type {object}
     * @memberof MoorwandCreateIban
     */
    request_reference?: object;
    /**
     * Merchant API Key – provided by Tribe Payments.
     * @type {string}
     * @memberof MoorwandCreateIban
     */
    key?: string;
    /**
     * Request timestamp.
     * @type {number}
     * @memberof MoorwandCreateIban
     */
    ts?: number;
    /**
     * See Sign generation.
     * @type {string}
     * @memberof MoorwandCreateIban
     */
    sign?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MoorwandCreateIbanIbanRequestStatusEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6
}

