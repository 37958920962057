// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    NewAddress,
    SourceOfIncome,
} from './';

/**
 * @export
 * @interface AdvancedKYCData
 */
export interface AdvancedKYCData {
    /**
     * @type {SourceOfIncome}
     * @memberof AdvancedKYCData
     */
    source_of_incomme?: SourceOfIncome;
    /**
     * @type {string}
     * @memberof AdvancedKYCData
     */
    average_monthly_income?: AdvancedKYCDataAverageMonthlyIncomeEnum;
    /**
     * @type {string}
     * @memberof AdvancedKYCData
     */
    employer_name?: string;
    /**
     * @type {NewAddress}
     * @memberof AdvancedKYCData
     */
    employer_address?: NewAddress;
}

/**
 * @export
 * @enum {string}
 */
export enum AdvancedKYCDataAverageMonthlyIncomeEnum {
    Below1000 = 'below_1000',
    Between10002000 = 'between_1000_2000',
    Between20005000 = 'between_2000_5000',
    Between500010000 = 'between_5000_10000',
    Above10000 = 'above_10000'
}

