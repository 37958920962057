// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Language,
    NewAddress,
    Sex,
    UserClass,
} from './';

/**
 * @export
 * @interface NewEmoneyPersonWithUsertype
 */
export interface NewEmoneyPersonWithUsertype {
    /**
     * the persons name
     * @type {string}
     * @memberof NewEmoneyPersonWithUsertype
     */
    name: string;
    /**
     * the persons given name
     * @type {string}
     * @memberof NewEmoneyPersonWithUsertype
     */
    given_name: string;
    /**
     * the email address
     * @type {string}
     * @memberof NewEmoneyPersonWithUsertype
     */
    email_address: string;
    /**
     * the persons birth date
     * @type {Date}
     * @memberof NewEmoneyPersonWithUsertype
     */
    birth_date?: Date;
    /**
     * City or municipality
     * @type {string}
     * @memberof NewEmoneyPersonWithUsertype
     */
    birth_city?: string;
    /**
     * @type {UserClass}
     * @memberof NewEmoneyPersonWithUsertype
     */
    user_class: UserClass;
    /**
     * @type {string}
     * @memberof NewEmoneyPersonWithUsertype
     */
    family_relation?: NewEmoneyPersonWithUsertypeFamilyRelationEnum;
    /**
     * the product the user should be created with. depending on user class. valid values can be fetched from /lookup/product_user_class
     * @type {string}
     * @memberof NewEmoneyPersonWithUsertype
     */
    product?: string;
    /**
     * @type {NewAddress}
     * @memberof NewEmoneyPersonWithUsertype
     */
    address?: NewAddress;
    /**
     * @type {Sex}
     * @memberof NewEmoneyPersonWithUsertype
     */
    sex?: Sex;
    /**
     * @type {Language}
     * @memberof NewEmoneyPersonWithUsertype
     */
    language?: Language;
}

/**
 * @export
 * @enum {string}
 */
export enum NewEmoneyPersonWithUsertypeFamilyRelationEnum {
    Junior = 'junior',
    FamilyMember = 'family_member'
}

