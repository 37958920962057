import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { StyledLine, StyledSelect } from '../../basicStyledComponents/customersComponent.css';
import { translate } from '../../../../../common/language/translate';
import { FlexBox } from '../../../../auth/auth.css';
import Title from '../../../../../components/compositcomponents/title';
import { Account } from '../../../../../logic/api';
import { IOption } from '../../../../../components/atomiccompoents/form';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import EditableComponent from '../../../../../components/atomiccompoents/editableComponent/editableComponent';
import { AOverlay, IPropsOver, IStateOver } from '../../../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import { Icons } from '../../../../../images';

const StyledDivMain = styled(FlexBox)`
	margin: auto;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 50px;
	padding-top: 20;
	display: flex;
	flex-direction: column;
	width: 480px;
	height: 240px;
	align-items: center;
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	background-color: white;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	overflow-y: auto;
`;

const SmallSelect = styled(StyledSelect)`
  select{
	  min-width: 40px;
	  max-width: 120px;
	  width: 100px;
  }
`;
interface IProps extends IPropsOver {
	callback: (item: Account.CalendarItem) => void;
}
interface IState extends IStateOver{
	item: Account.CalendarItem;
	keyForLoadingSpinner: number;
	errors: {[key: string]: string}
}



export default class addCalendarItemOverlay extends AOverlay<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			item: {
				end_time: '18:00:00',
				start_time: '10:00:00',
				weekday_id: 0,
			},
			errors: {},
			keyForLoadingSpinner:  Math.floor(Math.random() * 10000000)
		};
		this.handleWeekdaySelect = this.handleWeekdaySelect.bind(this);
	}

	getWeekdayOptions() {
		const enumValues: Array<IOption> = [];
		for (let i = 0; i < 7; i++) {
			enumValues.push({
				key: 'defaultOptionKey value' + i,
				name: translate('customers.details.merchant.calendarItems.' + i),
				value: i,
			});
		}
		return enumValues;
	}

	reviveState() {

	}
	
	handleWeekdaySelect(message: any) {
		const item = this.state.item;
		item.weekday_id = parseInt(message.weekday);
		this.setState({
			item: item
		});
	}

	defineContent() {
		return (
			<StyledDivMain onClick={(event: SyntheticEvent) => event.stopPropagation()}>
				<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
						<div onClick={() => this.props.selfClose()}>
							{Icons.closingCross()}
						</div>
				</div>
				<div style={{display: 'flex', flexDirection: 'column'}}>
					<Title title={translate('customers.details.merchant.add')} />
					<div style={{display: 'flex', flexDirection: 'row', height: '52px', marginBottom: '16px'}}>
						<SmallSelect
							id="weekday"
							options={this.getWeekdayOptions()}
							notification={this.handleWeekdaySelect}
							current={this.state.item.weekday_id}
							wrapperStyle={{}}
							helptext={""}
						/>
						<EditableComponent
						    id="itemtStartmtime"
							initText={this.state.item.start_time}
							changeCallback={
								(value: string) => {
									const item = this.state.item;
									item.start_time = value;
									this.setState({
										item: item
									})
								}
							}
							leaveCallback={() =>  {
								const value = this.state.item.start_time;
								const regexp: RegExp = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]:[0-5][\d]$/;
								if(!regexp.test(value)) {
									 //MessageHandler.onError(Reporter['customer.merchants.time.invalid']);
									 const errors = this.state.errors;
									 errors["s"] = 'Invalid Time format';
									 this.setState({
										 errors: errors
									 })
								} else {
									if(this.state.errors["s"] != null) {
										const errors = this.state.errors;
										delete errors["s"];
										this.setState({
											errors: errors
										})
									}
								}
							}}
							error={this.state.errors["s"]}
							wrapperStyle={{border: 'none', width: '40%', alignItems: 'center'}}
							enterCallback={() =>  {}}
							viewStyle={{fontSize: '18px'}}
							inputStyle={{fontSize: '18px', width: '100%', minWidth: '100px'}}
						/>
						<div style={{display: 'flex', alignItems: 'center' }}>{" - "}</div>
						<EditableComponent
						    id="itemEndTime"
							initText={this.state.item.end_time}
							changeCallback={
								(value: string) => {
									const item = this.state.item;
									item.end_time = value;
									this.setState({
										item: item
									})
								}
							}
							leaveCallback={() =>  {
								const value = this.state.item.end_time;
								const regexp: RegExp = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]:[0-5][\d]$/;
								if(!regexp.test(value)) {
									 //MessageHandler.onError(Reporter['customer.merchants.time.invalid']);
									 const errors = this.state.errors;
									 errors["e"] = 'Invalid Time format';
									 this.setState({
										 errors: errors
									 })
								} else {
									if(this.state.errors["e"] != null) {
										const errors = this.state.errors;
										delete errors["e"];
										this.setState({
											errors: errors
										})
									}
								}
							}}
							error={this.state.errors["e"]}
							wrapperStyle={{border: 'none', width: '40%', alignItems: 'center'}}
							enterCallback={() =>  {}}
							viewStyle={{fontSize: '18px'}}
							inputStyle={{fontSize: '18px', width: '100%', minWidth: '100px'}}
						/>
					</div>
				</div>
				<ButtonOk
					key={this.state.keyForLoadingSpinner}
					id="btnAddItem"
					onClick={() => {
						this.props.callback(this.state.item);
						this.setState({
							keyForLoadingSpinner:  Math.floor(Math.random() * 10000000)
						})
					}}
					disabled={Object.keys(this.state.errors).length > 0}>
					{translate('button.create')}
				</ButtonOk>
			</StyledDivMain>
		);
	}
}
