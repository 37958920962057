//@ts-nocheck
import React from "react";
import { Ident } from "../../logic/api";
import { CustomerDataStore } from '../../logic/flux';

export interface IWithPersonProps {
    
  }
  
export function withPerson<P extends IWithPersonProps, C>(
    // this type allows us to infer P, but grab the type of WrappedComponent separately without it interfering with the inference of P
    WrappedComponent: React.JSXElementConstructor<P> & C,
    // selectData is a functor for T
    // props is Readonly because it's readonly inside of the class
  ) {
    // the magic is here: JSX.LibraryManagedAttributes will take the type of WrapedComponent and resolve its default props
    // against the props of WithData, which is just the original P type with 'data' removed from its requirements
    type Props = JSX.LibraryManagedAttributes<C, Omit<P, "person">>;
    type State = {
      person?: Ident.Person;
    };
    return class WithData extends React.Component<Props, State> {
      constructor(props: Props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            person: CustomerDataStore.getUser()
        };
      }
  
      componentDidMount = () =>  {
          CustomerDataStore.addChangeListener(this.handleChange);
      }
      componentWillUnmount = () => {
          CustomerDataStore.removeChangeListener(this.handleChange);
      }
  
      handleChange = () => {
        const person = CustomerDataStore.getUser();
        this.setState({
          person: person
        });
    }
  
      render() {
        // the typing for spreading this.props is... very complex. best way right now is to just type it as any
        // data will still be typechecked
        return (
          <WrappedComponent person={this.state.person} {...(this.props as any)} />
        );
      }
    };
    // return WithData;
  }