import { IDispatcher } from '../dispatcher';
import { Constants, CHANGE_EVENT } from '../constants';
import EventEmitter from 'events';
import { IPermissions } from '../../types';
import { OperationIds, Ident, Account } from '../../api';

class IPermissionsStore extends EventEmitter {
	private permissions: Array<OperationIds> = [];
	private identPermissions: Array<Ident.OperationId> = [];
	private accountPermissions: Array<Account.OperationId> = [];

	private static instance: IPermissionsStore | null;

	private PERMISSIONS_STORAGE = 'permissions';

	constructor() {
		super();
		IDispatcher.register(this.registerActions.bind(this));
	}
	static getInstance(): IPermissionsStore {
		if (IPermissionsStore.instance == null) {
			IPermissionsStore.instance = new IPermissionsStore();
		}

		return IPermissionsStore.instance;
	}

	registerActions(action: any) {
		switch (action.actionType) {
			default:
				break;
			case Constants.CLEAR_ALL:
				this.permissions = [];
				this.identPermissions = [];
				this.accountPermissions = [];
				this.emit(CHANGE_EVENT);
				break;
			case Constants.PERMISSIONS_CHANGED:
				if ('identPermissions' in action) {
					this.setIdentPermissions(action.identPermissions);
				} else if ('accountPermissions' in action) {
					this.setAccountPermissions(action.accountPermissions);
				}
				this.emit(CHANGE_EVENT);
				break;
		}
		return true;
	}

	private combinePermissions(): void {
		this.permissions = [];
		this.identPermissions.forEach((el: Ident.OperationId) => {
			this.permissions.push(el);
		});
		this.accountPermissions.forEach((el: Account.OperationId) => {
			this.permissions.push(el);
		});

		window.sessionStorage.setItem(
			this.PERMISSIONS_STORAGE,
			JSON.stringify(this.permissions)
		);
	}

	setPermissions(permissions: Array<OperationIds>) {
		this.permissions = permissions;

		this.combinePermissions();
	}

	setAccountPermissions(permissions: Array<Account.OperationId>): void {
		this.accountPermissions = permissions;
		this.combinePermissions();
	}

	setIdentPermissions(permissions: Array<Ident.OperationId>): void {
		this.identPermissions = permissions;
		this.combinePermissions();
	}

	getPermissions(): Array<OperationIds> {
		if (this.permissions == null || this.permissions.length === 0) {
			const permissionsString = window.sessionStorage.getItem(this.PERMISSIONS_STORAGE);
			if (permissionsString != null) {
				this.permissions = JSON.parse(permissionsString);
				return this.permissions;
			} else {
				return this.permissions;
			}
		} else {
			return this.permissions;
		}
	}

	addChangeListener(callback: any) {
		this.on(CHANGE_EVENT, callback);
	}

	removeChangeListener(callback: any) {
		this.removeListener(CHANGE_EVENT, callback);
	}

	hasPermission(
		permission: OperationIds | string,
		userPermissions?: Array<OperationIds>
	): boolean {
		const perm: Array<OperationIds> | string =
			userPermissions == null ? this.getPermissions() : userPermissions;

		let result: number = -1;
		if (typeof permission === 'string') {
			result =
				Object.entries(perm).find(([, value]: [string, string]) => {
					return value === permission;
				}) != null
					? 1
					: -1;
		} else {
			result = this.permissions.indexOf(permission);
		}
		return result >= 0;
	}

	hasAllPermissionsOf(
		permissions: Array<OperationIds>,
		userPermissions?: Array<OperationIds>
	): boolean {
		let result: boolean = true;

		for (let i = 0; i < permissions.length; i++) {
			if (!this.hasPermission(permissions[i], userPermissions)) {
				result = false;
				break;
			}
		}

		return result;
	}

	hasOnePermissionOf(
		permissions: Array<OperationIds>,
		userPermissions?: Array<OperationIds>
	): boolean {
		let result: boolean = false;

		for (let i = 0; i < permissions.length; i++) {
			if (this.hasPermission(permissions[i], userPermissions)) {
				result = true;
				break;
			}
		}

		return result;
	}

	checkPermissionConfig(
		permissions: IPermissions,
		userPermissions?: Array<OperationIds>
	): boolean {
		if (permissions.allOf == null && permissions.oneOf == null) {
			return false;
		}
		if (
			permissions.allOf != null &&
			this.hasAllPermissionsOf(permissions.allOf, userPermissions)
		) {
			return true;
		}
		if (
			permissions.oneOf != null &&
			this.hasOnePermissionOf(permissions.oneOf, userPermissions)
		) {
			return true;
		}

		return false;
	}
}

export const PermissionStore = IPermissionsStore.getInstance();
