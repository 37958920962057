import React from 'react';
import styled from 'styled-components';
import GenericTemplateComponent, {
	GenericTemplateType,
	TemplateCategory,
} from '../../../genericUI/genericTemplateComponent';
import AccountSelector from '../../../../components/accountSelector';
import {
	IStatusState,
	getFormComponents,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { IItfAccount } from '../../../../logic/types';
import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import { OverlayHandler } from '../../../../logic/handler/overlayhandler/overlayHandler';

interface IProps {}

interface IState extends IStatusState {
	accounts?: Array<IItfAccount>;
	selectedAccount?: number;
}

export default class PostingTemplateComponent extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<FlexBox>
				<Wrapper>
					<AccountSelector />
				</Wrapper>
				{getFormComponents(
					this.state.showInlineError,
					this.state.showInlineSuccess,
					this.state.errorMessage,
					this.state.successMessage,
					this.state.key
				)}
				<Wrapper onClick={() => OverlayHandler.closeOverlaysOnClick()}>
					<GenericTemplateComponent
						templateType={GenericTemplateType.posting_template}
						templateCategory={TemplateCategory.customer}
						heading={translate('generic.createPosting')}
						subHeading={translate('generic.postingTemplate')}
					/>
				</Wrapper>
			</FlexBox>
		);
	}
}

const Wrapper = styled(FlexBox)`
	margin-bottom: 24px;
`;
