//@ts-nocheck
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import {CountryCodeAlpha2, PersonAddressPutRequest, PersonAddressPostRequest} from '../../../../../logic/api/ident';
import {
	IStatusState,
	Reporter,
} from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import {
    api, 
    apis, 
    ApiError, 
    Ident
} from '../../../../../logic/api';
import {MessageHandler} from '../../../../../logic/handler/messagehandler/messageHandler';
import {translate} from '../../../../../common/language/translate';
import {Icons} from '../../../../../images';
import {
	IStateOver,
	IPropsOver,
	AOverlay,
} from '../../../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import {
	IInitProps,
	IInitState,
} from '../../../../../logic/handler/initialdatahandler/initialDataComponent';
import ButtonOk from '../../../../../components/atomiccompoents/buttons/buttonOk';
import ButtonCancel from '../../../../../components/atomiccompoents/buttons/buttonCancel';
import Title from '../../../../../components/compositcomponents/title';
import { 
    OverlayHandler, 
    Overlays } from '../../../../../logic/handler/overlayhandler/overlayHandler';
import { KeyField, StyledSelect as RawStyledSelect, StyledInputView, StyledSmallInput, ColumnBox, KeyValueBlock, ValueField } from '../basicStyledComponents/customerDetails.css';
import EditableComponent from '../../../../../components/atomiccompoents/editableComponent/editableComponent';
import { IOption } from '../../../../../components/atomiccompoents/form';
import { DisplayMenuComponent } from '../../../../../components/compositcomponents/displayMenuComponent';
import { PermissionStore } from '../../../../../logic/flux';
import { StyledSelect } from '../../basicStyledComponents/customersComponent.css';
import { getCountryOptions } from '../../../../../logic/helper/Common';

const StyledOverlay = styled.div`
	margin: auto;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 50px;
	padding-top: 20;
	display: flex;
	flex-direction: column;
	width: 690px;
	height: 850px;
	align-items: center;
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	background-color: white;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	overflow-y: auto;
`;

interface IProps extends IInitProps, IPropsOver {
    addresses: Array<Ident.Address>;
    personId: number;
    state?: Ident.PersonStateEnum
}
interface IState extends IStatusState, IInitState, IStateOver {
    addresses: Array<Ident.Address>,
    currentIndex: number;
    currentNewAddress?: Ident.NewAddress;
    newAddress?: boolean;
    keyForLoadingSpinner: number;
}

export default class AddressEditOverlay extends AOverlay<IProps, IState> {
    private ButtonStyle = {

    };
    constructor(props: IProps) {
    	super(props);
    	this.state = {
            addresses: this.props.addresses,
            currentIndex: 0,
            newAddress: false,
            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
    	};
        this.setEditValue = this.setEditValue.bind(this);
        this.setCurrentCountry = this.setCurrentCountry.bind(this);
        this.setAddressType = this.setAddressType.bind(this);
        this.onSave = this.onSave.bind(this);
        this.addressPut = this.addressPut.bind(this);
        this.addressPost = this.addressPost.bind(this);
    }
    reviveState() {

    }
    stopPropagation(event: SyntheticEvent) {
    	event.stopPropagation();
    }

    getAddressTypeOptions() {
        const enumValues: Array<IOption>= [];
        for(const value in Ident.AddressType) {
                enumValues.push({
                    key: 'defaultOptionKey '+ value,
                    name: value,
                    value: value,
                });
        }
        return enumValues
    }

    setCurrentCountry(message: any) {
        this.setEditValue(message.country, "country");
    }
    setAddressType(message: any) {
        this.setEditValue(message.address_type, "address_type");
    }
    setEditValue(value: string, key?: string) {
        const isNew = this.state.newAddress === true && this.state.currentNewAddress != null;
        const addresses = this.state.addresses;
        const current = isNew ? 
                        this.state.currentNewAddress : 
                        this.state.addresses[this.state.currentIndex];
        if(current != null && key != null) {
            switch(key) {
                case 'street': 
                    current.street = value;
                    if(!isNew) {
                        addresses[this.state.currentIndex] = current as Ident.Address;
                    }
                    this.setState({
                        addresses: isNew ? this.state.addresses : addresses,
                        currentNewAddress: isNew ? current : this.state.currentNewAddress
                    })
                    break;
                case 'building_identifier': 
                    current.building_identifier = value;
                    if(!isNew) {
                        addresses[this.state.currentIndex] = current as Ident.Address;
                    }
                    this.setState({
                        addresses: isNew ? this.state.addresses : addresses,
                        currentNewAddress: isNew ? current : this.state.currentNewAddress
                    })
                    break;
                case 'city': 
                    current.city = value;
                    if(!isNew) {
                        addresses[this.state.currentIndex] = current as Ident.Address;
                    }
                    this.setState({
                        addresses: isNew ? this.state.addresses : addresses,
                        currentNewAddress: isNew ? current : this.state.currentNewAddress
                    })
                    break;
                case 'post_code': 
                    current.post_code = value;
                    if(!isNew) {
                        addresses[this.state.currentIndex] = current as Ident.Address;
                    }
                    this.setState({
                        addresses: isNew ? this.state.addresses : addresses,
                        currentNewAddress: isNew ? current : this.state.currentNewAddress
                    })
                    break;
                case 'post_box': 
                    current.post_box = value;
                    if(!isNew) {
                        addresses[this.state.currentIndex] = current as Ident.Address;
                    }
                    this.setState({
                        addresses: isNew ? this.state.addresses : addresses,
                        currentNewAddress: isNew ? current : this.state.currentNewAddress
                    })
                    break;    
                case 'country': 
                    current.country = value as CountryCodeAlpha2;
                    if(!isNew) {
                        addresses[this.state.currentIndex] = current as Ident.Address;
                    }
                    this.setState({
                        addresses: isNew ? this.state.addresses : addresses,
                        currentNewAddress: isNew ? current : this.state.currentNewAddress
                    })
                    break;
                case 'country_subentity': 
                    current.country_subentity = value;
                    if(!isNew) {
                        addresses[this.state.currentIndex] = current as Ident.Address;
                    }
                    this.setState({
                        addresses: isNew ? this.state.addresses : addresses,
                        currentNewAddress: isNew ? current : this.state.currentNewAddress
                    })
                    break;
                case 'floor_identifier': 
                    current.floor_identifier = value;
                    if(!isNew) {
                        addresses[this.state.currentIndex] = current as Ident.Address;
                    }
                    this.setState({
                        addresses: isNew ? this.state.addresses : addresses,
                        currentNewAddress: isNew ? current : this.state.currentNewAddress
                    })
                    break;     
                case 'suite_identifier': 
                    current.suite_identifier = value;
                    if(!isNew) {
                        addresses[this.state.currentIndex] = current as Ident.Address;
                    }
                    this.setState({
                        addresses: isNew ? this.state.addresses : addresses,
                        currentNewAddress: isNew ? current : this.state.currentNewAddress
                    })
                    break;
                case 'district_name': 
                    current.district_name = value;
                    if(!isNew) {
                        addresses[this.state.currentIndex] = current as Ident.Address;
                    }
                    this.setState({
                        addresses: isNew ? this.state.addresses : addresses,
                        currentNewAddress: isNew ? current : this.state.currentNewAddress
                    })
                    break;                                   
                case 'address_type': 
                    current.address_type = value as Ident.AddressType;
                    this.setState({
                        currentNewAddress: current
                    })
                    break;
                case 'longitude':
                    current.longitude = parseFloat(value);
                    this.setState({
                        currentNewAddress: current
                    })
                    break;
                case 'latitude':
                    current.latitude = parseFloat(value);
                    this.setState({
                        currentNewAddress: current
                    })
                    break;
                default:
                    break;
                       
            }
        }
    }
    onCancel() {
        OverlayHandler.closeSpecific(Overlays.addressOverlay);
    }

    onSave() {
        if(this.state.newAddress === true) {
            this.addressPost();
        }
        else {
            this.addressPut();
        }
    }

    addressPut() {
        const address = this.state.addresses[this.state.currentIndex];
        const addressId = address.address_id;
        delete address.address_id;
        delete address.datetime_update;
        const params: PersonAddressPutRequest = {
            person_id: this.props.personId,
            address_id: addressId,
            NewAddress: address
        };
        api.request(
			params,
			apis.MaintenanceApi,
			'personAddressPut',
			(error: ApiError, response: Array<Ident.Address>) => {
				if (response != null) {
                     MessageHandler.onSuccess(Reporter['customer.details.adress.put.request.error']);
                     this.setState({
                        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
                     })
				} else { 
                    MessageHandler.onError(Reporter['customer.details.adress.put.request.error'])
                }
            });
    }

    addressPost() {
        const address = this.state.currentNewAddress;
        if (address != null) {
            const params: PersonAddressPostRequest = {
                person_id: this.props.personId,
                NewAddress:  address
            }
            api.request(
                params,
                apis.MaintenanceApi,
                'personAddressPost',
                (error: ApiError, response: any) => {
                    if (response != null) {
                        const newAddress: Ident.Address = {
                            address_id: response.address_id,
                            datetime_update: new Date(),
                            ...address,
                            address_type: address.address_type != null ? address.address_type : Ident.AddressType.D
                        }
                        const addresses = this.state.addresses;
                        addresses.push(newAddress);
                        this.setState({
                            newAddress: false,
                            currentNewAddress: {city: '', country: CountryCodeAlpha2.DE},
                            addresses: addresses,
                            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
                        })
                        MessageHandler.onSuccess(Reporter['customer.details.adress.post.request.error'])
                    } 
                    else {
                        this.setState({
                            keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
                        })
                        MessageHandler.onError(Reporter['customer.details.adress.post.request.error'])
                    }
                }).catch(() => {
                    this.setState({
                        keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
                    })
                    MessageHandler.onError(Reporter['customer.details.adress.post.request.error'])
                });
        }
    }
    onCreateKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    	// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    	if (event.key === 'Enter') {
    		event.preventDefault();
    		event.stopPropagation();
    	}
    };
    getAddressField() {
        if(this.state.newAddress === false) {
            const street = this.state.addresses[this.state.currentIndex] != null && this.state.addresses[this.state.currentIndex].street != null ? this.state.addresses[this.state.currentIndex].street : '';
            const buildIdent = this.state.addresses[this.state.currentIndex] != null && this.state.addresses[this.state.currentIndex].building_identifier != null ? this.state.addresses[this.state.currentIndex].building_identifier : '';
            const city = this.state.addresses[this.state.currentIndex] != null && this.state.addresses[this.state.currentIndex].city != null ? this.state.addresses[this.state.currentIndex].city : '';
            const postCode = this.state.addresses[this.state.currentIndex] != null && this.state.addresses[this.state.currentIndex].post_code != null ? this.state.addresses[this.state.currentIndex].post_code : '';
            const country = this.state.addresses[this.state.currentIndex] != null && this.state.addresses[this.state.currentIndex].country != null ? this.state.addresses[this.state.currentIndex].country : '';
            const countrySubentity = this.state.addresses[this.state.currentIndex] != null && this.state.addresses[this.state.currentIndex].country_subentity != null ? this.state.addresses[this.state.currentIndex].country_subentity : '';
            const floorIdentifier = this.state.addresses[this.state.currentIndex] != null && this.state.addresses[this.state.currentIndex].floor_identifier != null ? this.state.addresses[this.state.currentIndex].floor_identifier : '';
            const districtName = this.state.addresses[this.state.currentIndex] != null && this.state.addresses[this.state.currentIndex].district_name != null ? this.state.addresses[this.state.currentIndex].district_name : '';
            const suiteIdentifier = this.state.addresses[this.state.currentIndex] != null && this.state.addresses[this.state.currentIndex].suite_identifier != null ? this.state.addresses[this.state.currentIndex].suite_identifier : '';
            const postBox = this.state.addresses[this.state.currentIndex] != null && this.state.addresses[this.state.currentIndex].post_box != null ? this.state.addresses[this.state.currentIndex].post_box : '';
            const latitude = this.state.addresses[this.state.currentIndex] != null && this.state.addresses[this.state.currentIndex].latitude != null ? this.state.addresses[this.state.currentIndex].latitude : '';
            const longitude = this.state.addresses[this.state.currentIndex] != null && this.state.addresses[this.state.currentIndex].longitude != null ? this.state.addresses[this.state.currentIndex].longitude : '';
            
            if(this.state.addresses.length < 1) {
                return <div>No secondary address found</div>;
            }
            const out = (
                <React.Fragment>
                    <ColumnBox style={{maxHeight: 'calc(100vh - 500px)', minWidth: '500px', minHeight: '500px'}}>
                            <SmallerKeyValueDiv>
                                    <EditableComponent 
                                        translationkey='customers.street'
                                        id="street"
                                        initText={street != null ? street : ''}
                                        changeCallback={() =>  {}}
                                        inputStyle={{textAlign: 'left', marginBottom: '0px', marginTop: '2px'}}
                                        leaveCallback= {() => {}}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
                                        index={0}
                                    />
                            </SmallerKeyValueDiv>
                            <SmallerKeyValueDiv>
                                    <EditableComponent 
                                        translationkey='customers.buildingIdentifier'
                                        id="building_identifier"
                                        initText={buildIdent != null ? buildIdent : ''}
                                        changeCallback={() =>  {}}
                                        inputStyle={{textAlign: 'left', marginBottom: '0px', marginTop: '2px'}}
                                        leaveCallback= {() => {}}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
                                        index={1}
                                    />
                            </SmallerKeyValueDiv>
                            <SmallerKeyValueDiv>
                                    <EditableComponent 
                                        translationkey='customers.floor_identifier'
                                        id="floor_identifier"
                                        initText={floorIdentifier != null ? floorIdentifier : ''}
                                        changeCallback={() =>  {}}
                                        inputStyle={{textAlign: 'left', marginBottom: '0px', marginTop: '2px'}}
                                        leaveCallback= {() => {}}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
                                        index={1}
                                    />
                            </SmallerKeyValueDiv>
                            <SmallerKeyValueDiv>
                                    <EditableComponent 
                                        translationkey='customers.suite_identifier'
                                        id="suite_identifier"
                                        initText={suiteIdentifier != null ? suiteIdentifier : ''}
                                        changeCallback={() =>  {}}
                                        inputStyle={{textAlign: 'left', marginBottom: '0px', marginTop: '2px'}}
                                        leaveCallback= {() => {}}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
                                        index={1}
                                    />
                            </SmallerKeyValueDiv>
                            <SmallerKeyValueDiv>
                                    <EditableComponent 
                                        translationkey='customers.city'
                                        id="city"
                                        initText={city != null ? city : ''}
                                        changeCallback={() =>  {}}
                                        inputStyle={{textAlign: 'left', marginBottom: '0px', marginTop: '2px'}}
                                        leaveCallback= {() => {}}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
                                        index={1}
                                    />
                            </SmallerKeyValueDiv>
                            <SmallerKeyValueDiv>
                                    <EditableComponent 
                                        translationkey='customers.district_name'
                                        id="district_name"
                                        initText={districtName != null ? districtName : ''}
                                        changeCallback={() =>  {}}
                                        inputStyle={{textAlign: 'left', marginBottom: '0px', marginTop: '2px'}}
                                        leaveCallback= {() => {}}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
                                        index={1}
                                    />
                            </SmallerKeyValueDiv>
                            <SmallerKeyValueDiv>
                                    <EditableComponent 
                                        translationkey='customers.zip'
                                        id="post_code"
                                        initText={postCode != null ? postCode : ''}
                                        changeCallback={() =>  {}}
                                        leaveCallback= {() => {}}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
                                        inputStyle={{textAlign: 'left', marginBottom: '0px', marginTop: '2px'}}
                                        index={1}
                                    />
                            </SmallerKeyValueDiv>
                            <SmallerKeyValueDiv>
                                    <EditableComponent 
                                        translationkey='customers.post_boxl'
                                        id="post_box"
                                        initText={postBox != null ? postBox : ''}
                                        changeCallback={() =>  {}}
                                        leaveCallback= {() => {}}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
                                        inputStyle={{textAlign: 'left', marginBottom: '0px', marginTop: '2px'}}
                                        index={1}
                                    />
                            </SmallerKeyValueDiv>
                            <SmallerKeyValueBlock>
                                <KeyField>
                                    {translate('customers.newCustomer.input.country.label')}:
                                </KeyField>
                                <ValueField >
                                    <DisplayMenuComponent
                                        elementKey="sex"
                                        copyValue={country}
                                        methods={{
                                            openCallback: () => {}
                                        }}
                                        >  
                                        <CountrySelect
                                            id="selectCountry"
                                            options={getCountryOptions()}
                                            current={country}
                                            notification={this.setCurrentCountry}
                                            disabled={ !PermissionStore.hasPermission(Ident.OperationId.PersonAddressPut) }
                                        />
                                        </DisplayMenuComponent>
                                </ValueField>
                            </SmallerKeyValueBlock>
                            <SmallerKeyValueDiv>
                                    <EditableComponent 
                                        translationkey='customers.country_subentity'
                                        id="country_subentity"
                                        initText={countrySubentity != null ? countrySubentity : ''}
                                        changeCallback={() =>  {}}
                                        leaveCallback= {() => {}}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
                                        inputStyle={{textAlign: 'left', marginBottom: '0px', marginTop: '2px'}}
                                        index={1}
                                    />
                            </SmallerKeyValueDiv>
                            <SmallerKeyValueDiv>
                                    <EditableComponent 
                                        translationkey='customers.newCustomer.input.latitude.label'
                                        id="latitude"
                                        initText={latitude != null ? latitude.toString() : ''}
                                        changeCallback={() =>  {}}
                                        leaveCallback= {() => {}}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
                                        inputStyle={{textAlign: 'left', marginBottom: '0px', marginTop: '2px'}}
                                        index={1}
                                    />
                            </SmallerKeyValueDiv>
                            <SmallerKeyValueDiv>
                                    <EditableComponent 
                                        translationkey='customers.newCustomer.input.longitude.label'
                                        id="longitude"
                                        initText={longitude != null ? longitude.toString() : ''}
                                        changeCallback={() =>  {}}
                                        leaveCallback= {() => {}}
                                        enterCallback={this.setEditValue}
                                        permission={Ident.OperationId.PersonAddressPut}
                                        inputStyle={{textAlign: 'left', marginBottom: '0px', marginTop: '2px'}}
                                        index={1}
                                    />
                            </SmallerKeyValueDiv>
                            </ColumnBox>
                            <StyledViewButtons>
                                    <ButtonCancel
                                        id="btnEditCustomerAddressdatasCancel"
                                        onClick={this.onCancel} >                                
                                        {translate('button.cancel')}
                                    </ButtonCancel>                                   
                                    <ButtonOk
                                        key={this.state.keyForLoadingSpinner}
                                        disabled={this.props.state === Ident.PersonStateEnum.C}
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            this.addressPut()
                                        }}
                                        id="btnEditCustomerAddressDatasOk">
                                        {translate('button.save')}
                                    </ButtonOk>                                   
                            </StyledViewButtons>
                </React.Fragment>
            )
            return out;
        } else {
            return (
                <React.Fragment>
                    <StyledSelect 
                            helptext={translate('customers.newCustomer.input.type.placeholder')}
                            id="address_type"
                            options={this.getAddressTypeOptions()}
                            notification={this.setAddressType}
                            current={this.state.currentNewAddress != null ? this.state.currentNewAddress.address_type : Ident.AddressType.D}                               
                        />
                    <StyledInputView>
                        <StyledSmallInput
                            label={translate('customers.newCustomer.input.address.label')}
                            placeHolder={translate(
                                'customers.newCustomer.input.address.placeholder'
                            )}
                            type="street-address"
                            id="street"
                            onSubmit={() => {return}}
                            valueFromState={true}                                
                            value={this.state.currentNewAddress != null ? this.state.currentNewAddress.street : ''}
                            notification={n => {
                                if (Object.keys(n).length === 1) {
                                    const key = Object.keys(n)[0] as keyof any;
                                    this.setEditValue(n[key].value, key.toString());
                                }
                            }}
                        />
                        <div style={{width: '16px'}} />
                        <StyledSmallInput
                            placeHolder={translate(
                                'customers.newCustomer.input.number.placeholder'
                            )}
                            label={translate('customers.newCustomer.input.number.label')}
                            type="street-address"
                            id="building_identifier"
                            onSubmit={() => {return}}
                            valueFromState={true}                                
                            value={this.state.currentNewAddress != null ? this.state.currentNewAddress.building_identifier : ''}
                            notification={n => {
                                if (Object.keys(n).length === 1) {
                                    const key = Object.keys(n)[0] as keyof any;
                                    this.setEditValue(n[key].value, key.toString());
                                }
                            }}
                        />
                    </StyledInputView>
                    <StyledInputView>
                        <StyledSmallInput
                            label={translate('customers.newCustomer.input.floor_identifier.label')}
                            placeHolder={translate(
                                'customers.newCustomer.input.floor_identifier.placeholder'
                            )}
                            type="street-address"
                            id="floor_identifier"
                            onSubmit={() => {return}}
                            valueFromState={true}                                
                            value={this.state.currentNewAddress != null ? this.state.currentNewAddress.floor_identifier : ''}
                            notification={n => {
                                if (Object.keys(n).length === 1) {
                                    const key = Object.keys(n)[0] as keyof any;
                                    this.setEditValue(n[key].value, key.toString());
                                }
                            }}
                        />
                        <div style={{width: '16px'}} />
                        <StyledSmallInput
                            placeHolder={translate(
                                'customers.newCustomer.input.suite_identifier.placeholder'
                            )}
                            label={translate('customers.newCustomer.input.suite_identifier.label')}
                            type="street-address"
                            id="suite_identifier"
                            onSubmit={() => {return}}
                            valueFromState={true}                                
                            value={this.state.currentNewAddress != null ? this.state.currentNewAddress.suite_identifier : ''}
                            notification={n => {
                                if (Object.keys(n).length === 1) {
                                    const key = Object.keys(n)[0] as keyof any;
                                    this.setEditValue(n[key].value, key.toString());
                                }
                            }}
                        />
                    </StyledInputView>
                    <StyledInputView>
                        <StyledSmallInput
                            placeHolder={translate(
                                'customers.newCustomer.input.city.placeholder'
                            )}
                            label={translate('customers.newCustomer.input.city.label')}
                            type="street-address"
                            id="city"
                            onSubmit={() => {return}}
                            valueFromState={true}                                
                            value={this.state.currentNewAddress != null ? this.state.currentNewAddress.city : ''}
                            notification={n => {
                                if (Object.keys(n).length === 1) {
                                    const key = Object.keys(n)[0] as keyof any;
                                    this.setEditValue(n[key].value, key.toString());
                                }
                            }}
                        />
                        <div style={{width: '16px'}} />
                        <StyledSmallInput
                            placeHolder={translate(
                                'customers.newCustomer.input.postCode.placeholder'
                            )}
                            label={translate('customers.newCustomer.input.postCode.label')}
                            type="postal-code"
                            id="post_code"
                            onSubmit={() => {return}}
                            valueFromState={true}                                
                            value={this.state.currentNewAddress != null ? this.state.currentNewAddress.post_code : ''}
                            notification={n => {
                                if (Object.keys(n).length === 1) {
                                    const key = Object.keys(n)[0] as keyof any;
                                    this.setEditValue(n[key].value, key.toString());
                                }
                            }}
                        />
                    </StyledInputView>
                    <StyledInputView>
                        <StyledSmallInput
                            placeHolder={translate(
                                'customers.newCustomer.input.post_box.placeholder'
                            )}
                            label={translate('customers.newCustomer.input.post_box.label')}
                            type="street-address"
                            id="post_box"
                            onSubmit={() => {return}}
                            valueFromState={true}                                
                            value={this.state.currentNewAddress != null ? this.state.currentNewAddress.post_box : ''}
                            notification={n => {
                                if (Object.keys(n).length === 1) {
                                    const key = Object.keys(n)[0] as keyof any;
                                    this.setEditValue(n[key].value, key.toString());
                                }
                            }}
                        />
                        <div style={{width: '16px'}} />
                        <StyledSmallInput
                            placeHolder={translate(
                                'customers.newCustomer.input.district_name.placeholder'
                            )}
                            label={translate('customers.newCustomer.input.district_name.label')}
                            type="postal-code"
                            id="district_name"
                            onSubmit={() => {return}}
                            valueFromState={true}                                
                            value={this.state.currentNewAddress != null ? this.state.currentNewAddress.district_name : ''}
                            notification={n => {
                                if (Object.keys(n).length === 1) {
                                    const key = Object.keys(n)[0] as keyof any;
                                    this.setEditValue(n[key].value, key.toString());
                                }
                            }}
                        />
                    </StyledInputView>
                    <StyledInputView>
                        <StyledSelectNoMinWidth 
                            helptext={translate('customers.newCustomer.input.country.placeholder')}
                            id="country"
                            options={getCountryOptions()}
                            notification={this.setCurrentCountry}
                            current={this.state.currentNewAddress != null ? this.state.currentNewAddress.country : ''}                               
                        />
                        <div style={{width: '16px'}} />
                        <StyledSmallInput
                                placeHolder={translate(
                                    'customers.newCustomer.input.country_subentity.placeholder'
                                )}
                                label={translate('customers.newCustomer.input.country_subentity.label')}
                                type="street-address"
                                id="country_subentity"
                                onSubmit={() => {return}}
                                valueFromState={true}                                
                                value={this.state.currentNewAddress != null ? this.state.currentNewAddress.country_subentity : ''}
                                notification={n => {
                                    if (Object.keys(n).length === 1) {
                                        const key = Object.keys(n)[0] as keyof any;
                                        this.setEditValue(n[key].value, key.toString());
                                    }
                                }}
                            />
                    </StyledInputView>
                    <StyledInputView>
                         <StyledSmallInput
                            placeHolder={translate(
                                'customers.newCustomer.input.latitude.placeholder'
                            )}
                            label={translate('customers.newCustomer.input.latitude.label')}
                            type="text"
                            id="latitude"
                            onSubmit={() => {return}}
                            valueFromState={true}                                
                            value={this.state.currentNewAddress != null && this.state.currentNewAddress.latitude != null ? this.state.currentNewAddress.latitude.toString() : ''}
                            notification={n => {
                                if (Object.keys(n).length === 1) {
                                    const key = Object.keys(n)[0] as keyof any;
                                    this.setEditValue(n[key].value, key.toString());
                                }
                            }}
                        />
                        <div style={{width: '16px'}} />
                        <StyledSmallInput
                            placeHolder={translate(
                                'customers.newCustomer.input.longitude.placeholder'
                            )}
                            label={translate('customers.newCustomer.input.longitude.label')}
                            type="text"
                            id="longitude"
                            onSubmit={() => {return}}
                            valueFromState={true}                                
                            value={this.state.currentNewAddress != null && this.state.currentNewAddress.longitude != null ? this.state.currentNewAddress.longitude.toString() : ''}
                            notification={n => {
                                if (Object.keys(n).length === 1) {
                                    const key = Object.keys(n)[0] as keyof any;
                                    this.setEditValue(n[key].value, key.toString());
                                }
                            }}
                        />
                    </StyledInputView>

                    <StyledViewButtons>
                            <ButtonCancel
                                id="btnAddCustomerAddressCancel"
                                style={{marginRight: '48px'}}
                                onClick={() => {
                                    this.setState({
                                        newAddress: false,
                                        currentNewAddress: undefined,
                                    })
                                }}>
                                {translate('button.cancel')}
                            </ButtonCancel>
                            <ButtonOk
                                key={this.state.keyForLoadingSpinner}
                                disabled={this.props.state === Ident.PersonStateEnum.C}
                                id="btnAddCustomerAddressOk"
                                onClick={(event: any) => {
                                                event.preventDefault();
                                                this.addressPost()
                                        }}>
                                {translate('button.create')}
                            </ButtonOk>
                        </StyledViewButtons>
                </React.Fragment>
            )
        }
    }

    defineContent() {
        const displayIndex = this.state.addresses.length > 0 ? this.state.currentIndex + 1 : 0;
    	return (
    		<StyledOverlay onClick={event => event.stopPropagation()}>
                <HeaderBlock>
                    <Title title={translate('customers.customersAddress')} />
                    <IconWrapper
                        style={{marginLeft: '16px', marginTop: '5px'}} 
                        onClick={() => this.setState({
                        newAddress: true,
                        currentNewAddress: {city: '', country: CountryCodeAlpha2.DE, address_type: Ident.AddressType.D}
                        })}>
                        {Icons.addressAdd()}
                    </IconWrapper>
                </HeaderBlock>
                <AddressChangeBlock>
                    <div onClick={ ()  => {
                        this.setState({
                            currentIndex: this.state.currentIndex === 0 ? this.state.addresses.length - 1 : this.state.currentIndex -1,
                        })
                        }}>
                            {Icons.pagingArrowLeft()}
                    </div>
                    <div>
                        {this.state.newAddress === true ? translate('customers.newAddress') : displayIndex + " von " + this.state.addresses.length}
                    </div>
                    <div onClick={ () => {
                        this.setState({
                            currentIndex: (this.state.currentIndex + 1)%this.state.addresses.length,
                        })
                        }}>
                            {Icons.pagingArrowRight()}
                    </div>
                </AddressChangeBlock>
                <AddressBlock>
                        {this.getAddressField()}
                </AddressBlock>
    		</StyledOverlay>
    	);
    }
}

const AddressChangeBlock = styled.div`
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const AddressBlock = styled.div`
    display: flex;
    flex-direction: column;
`;
const HeaderBlock = styled.div`
    display: flex;
    flex-direction: row;
`;
const StyledViewButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 32px;
    margin-top: 40px;
    margin-bottom: 24px;
`;
const IconWrapper = styled.div`
`;

const SmallerKeyValueBlock = styled(KeyValueBlock)`
    max-width: 230px;
`;
const SmallerKeyValueDiv = styled.div`
    width: 230px;
`;
const CountrySelect = styled(RawStyledSelect)`
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
    font-weight: 400;
    color: ${(props) => props.theme.Global.darkFontColor};
    line-height: 20px;
    font-size: 14px;
`;
const StyledSelectNoMinWidth = styled(StyledSelect)`
    margin-top: -4px;
    select {
        min-width: 100px;
    }
`;