import React from 'react';
import { StyledSimpleMDE } from './easyMde.css';

interface IProps {
	label?: string;
	onChange?: (text: string) => void;
	options?: EasyMDE.Options;
	defaultValue?: string;
}

interface IState {
	label?: string;
	options?: EasyMDE.Options;
	text?: string;
}

export class Editor extends React.Component<IProps, IState> {
	private static defaultOptions: EasyMDE.Options = {};

	constructor(props: IProps) {
		super(props);

		this.state = {
			label: props.label,
			options: props.options == null ? Editor.defaultOptions : props.options,
			text: props.defaultValue == null ? '' : props.defaultValue,
		};

		this.onChangeInternal = this.onChangeInternal.bind(this);
	}

	componentWillReceiveProps(props: IProps): void {
		if (props == null) {
			return;
		}

		if (props.label !== this.state.label) {
			this.setState({ label: props.label });
		}

		if (props.options !== this.state.options) {
			this.setState({ options: props.options });
		}
	}

	private onChangeInternal(event: any): void {
		this.setState({ text: event });
		if (this.props.onChange != null) {
			this.props.onChange(event);
		}
	}

	render() {
		return (
			<label>
				{this.state.label}
				<StyledSimpleMDE
					value={this.state.text}
					options={this.state.options}
					onChange={(event: any) => this.onChangeInternal(event)}
				/>
			</label>
		);
	}
}
