// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Attachment,
    Iso4217Currency,
} from './';

/**
 * @export
 * @interface GiftPayment
 */
export interface GiftPayment {
    /**
     * @type {number}
     * @memberof GiftPayment
     */
    ta_id: number;
    /**
     * @type {number}
     * @memberof GiftPayment
     */
    transaction_id: number;
    /**
     * @type {string}
     * @memberof GiftPayment
     */
    receiver_account_number: string;
    /**
     * @type {string}
     * @memberof GiftPayment
     */
    sender_account_number: string;
    /**
     * @type {Date}
     * @memberof GiftPayment
     */
    datetime_create: Date;
    /**
     * @type {string}
     * @memberof GiftPayment
     */
    type: GiftPaymentTypeEnum;
    /**
     * external reference as provided by the initiaing application
     * @type {string}
     * @memberof GiftPayment
     */
    external_reference?: string;
    /**
     * @type {number}
     * @memberof GiftPayment
     */
    sender_person_id?: number;
    /**
     * @type {string}
     * @memberof GiftPayment
     */
    sender_name?: string;
    /**
     * @type {string}
     * @memberof GiftPayment
     */
    sender_given_name?: string;
    /**
     * @type {string}
     * @memberof GiftPayment
     */
    sender_phone_number?: string;
    /**
     * @type {number}
     * @memberof GiftPayment
     */
    amount: number;
    /**
     * @type {Iso4217Currency}
     * @memberof GiftPayment
     */
    currency: Iso4217Currency;
    /**
     * @type {string}
     * @memberof GiftPayment
     */
    statement_text?: string;
    /**
     * @type {Array<Attachment>}
     * @memberof GiftPayment
     */
    attachments?: Array<Attachment>;
}

/**
 * @export
 * @enum {string}
 */
export enum GiftPaymentTypeEnum {
    C = 'C',
    G = 'G'
}

