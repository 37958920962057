import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FlexBox } from '../../../content/auth/auth.css';

const StyledLine = styled.hr`
	border: 1px solid #f2f4f6;
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

const StyledTextEntryKey = styled.div`
	font-weight: normal;
	font-size: 14px;
	line-height: 30px;
	color: #98a9bc;
	margin-bottom: 2px;
`;

const StyledTextEntryValue = styled.div`
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.2px;
	color: ${props => props.theme.Global.darkFontColor};
	opacity: 0.5;
	margin-bottom: 6px;
`;

const StyledTextEntryKeyInline = styled.div`
	font-weight: normal;
	font-size: 14px;
	line-height: 30px;
	color: #98a9bc;
	margin-bottom: 6px;
	float: left;
	width: fit-content;
`;

const StyledTextEntryValueInline = styled.div`
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	letter-spacing: 0.2px;
	color: ${props => props.theme.Global.darkFontColor};
	margin-bottom: 6px;
	float: right;
	width: fit-content;
`;

const StyledView = styled(FlexBox)`
	min-height: 38px;
	width: 100%;
	border-bottom: 1px solid #DEDEDE;
`;

interface IProps {
	keyLabel: string;
	valueLabel: string | number | Date | ReactElement | null | Array<ReactElement>;
	forceShow?: boolean;
	valueInline?: boolean;
	skipUnderLine?: boolean;
}

export class KeyValueDisplayComponent extends React.Component<IProps> {
	render() {
		const val = this.props.valueLabel == null ? '' : this.props.valueLabel;
		if (this.props.valueLabel != null || this.props.forceShow) {
			if (this.props.valueInline) {
				// render for value on the rigth
				return (
					<StyledView>
						<StyledTextEntryKeyInline>
							{this.props.keyLabel}
						</StyledTextEntryKeyInline>
						<StyledTextEntryValueInline>{val}</StyledTextEntryValueInline>
						{this.props.skipUnderLine ? null : <StyledLine />}
					</StyledView>
				);
			} else {
				// render for value below key
				return (
					<StyledView>
						<StyledTextEntryKey>{this.props.keyLabel}</StyledTextEntryKey>
						<StyledTextEntryValue>{val}</StyledTextEntryValue>
						{this.props.skipUnderLine ? null : <StyledLine />}
					</StyledView>
				);
			}
		} else {
			return null;
		}
	}
}
