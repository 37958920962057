//@ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { translate } from '../../../../../common/language/translate';
import { StyledInput } from '../../../../../components/atomiccompoents/form/inputs.css';
import { Table } from '../../../../../components/compositcomponents/table/table';
import { RowType } from '../../../../../components/compositcomponents/table/tableTypes';
import { PagingComponent } from '../../../../../components/paging';
import { Icons } from '../../../../../images';
import { Account, api, apis, ApiError } from '../../../../../logic/api';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import { evaluateErrorMessage, compareDate } from '../../../../../logic/helper/Common';
import { format } from '../../../../../logic/helper/format';
import { Log, Logs } from '../../../../../logic/log';
import { IconWrapper } from '../../customers/basicStyledComponents/customerDetails.css';
import { Container, ContainerText } from '../../transfer/transfersComponent.css';
import VideoVerification from './videoVerification';




const Main = styled.div`
    display: flex,
    flex-direction: column;
    align-items: center;
` ;
const PagingWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
const IconWrapperInTable = styled.div`
	display: flex;
	justify-content: center;
	:hover {
		cursor: pointer;
	}
`;

const SplitView = styled.div`
	width: 95%;
	padding: 16px;
	height: calc(100vh - 250px);
	display: flex;
	flex-direction: column;
`;

 interface IProps {
	releaseTypeDocument?: Account.ReleaseType;
 }

 interface IAccountWithSource extends Account.Release {
	 source: string;
 }

interface IState {
  approvalDataAccount?: Array<IAccountWithSource>,
  selectedPersonId?: number,
  selectedQueue?: { type: Account.ReleaseType;  release_request_id: number }
  releaseTypeDocument: Account.ReleaseType;
  searchValue?: string;
}


class VideoApprovalQueue extends React.Component<IProps, IState> {

	private tableRef: Table<IAccountWithSource> | null = null;
	converterAccount(
		line: IAccountWithSource ,
		index: number,
		fields: Array<string>
	): RowType {
		const row: RowType = { cells: [], ref: line };
		if (row.cells == null) {
			return row;
		}

		const keys: Array<string> = Object.keys(line);
		const releaseId: number = line.release_requests_id == null ? -1 : line.release_requests_id;
        const methods = { startQueuecallback: () => { 
                        this.setState({
                            selectedQueue: {
                                release_request_id: releaseId,
                                type: line.type
                            }
                        });
                    }
                };

		for (let i = 0; i < fields.length; i++) {
			const field: string = fields[i];
			switch (field) {
                case 'status': {
                    const status = translate('backoffice.approvals.status.' + line.status, line.status);
					row.cells.push({
						value: status,
						display: (
							<Container onClick={() => { this.setState({
								
							})}}>
								<ContainerText title={status}>{status}</ContainerText>
							</Container>
						),
						copyVal: status,
                        methods: methods
					});
					break;
				}
				case 'requested_by_person_given_name': {
					const value = line.requested_by_person_given_name + " " + line.requested_by_person_name;
					row.cells.push({
						value: value,
						display: (
							<Container onClick={() => { this.setState({
								
							})}}>
								<ContainerText title={value}>{value}</ContainerText>
							</Container>
						),
						copyVal: value,
                        methods: methods
					});
					break;
                }
                case 'datetime_create':
				case 'last_modified': {
					const valueDate: string | undefined = format.datetime(line[field]);
					row.cells.push({
						value: valueDate == null ? '-' : valueDate,
						display: (
							<Container onClick={() => { this.setState({
								
							})}}>
								<ContainerText title={valueDate}>
									{valueDate == null ? '-' : valueDate}
								</ContainerText>
							</Container>
						),
						copyVal: valueDate != null ? valueDate.toString() : '-',
                        methods: methods
					});
					break;
				}
				case 'source': {
					row.cells.push({
						value: '',
						display: (
							<Container onClick={() => { 
								this.setState({
									selectedQueue: {
										release_request_id: releaseId,
										type: line.type
									}
							})}}>
								<ContainerText title={'start'}>
									<IconWrapperInTable>
										{Icons.pagingArrowRight()}
									</IconWrapperInTable>
								</ContainerText>
							</Container>
						),
						copyVal: '',
                        methods: methods
					});
					break;
				}
				default: {
					if (Object.prototype.hasOwnProperty.call(line, field)) {
						row.cells.push({
							value: Object.values(line)[keys.indexOf(field)],
							display: (
								<Container onClick={() => { this.setState({
								})}}>
									<ContainerText
										title={Object.values(line)[keys.indexOf(field)]}>
										{Object.values(line)[keys.indexOf(field)]}
									</ContainerText>
								</Container>
							),
							copyVal: Object.values(line)[keys.indexOf(field)],
							methods: methods
						});
					} else {
						row.cells.push({ value: '' });
					}
					break;
				}
			}
		}

		return row;
	};

	private resetIndex = false;

	constructor(props: IProps) {
		super(props);
		this.state = {
			releaseTypeDocument:  	props.releaseTypeDocument == null ? Account.ReleaseType.Eyeson : props.releaseTypeDocument
		};
		this.converterAccount = this.converterAccount.bind(this);
		this.setTableRef = this.setTableRef.bind(this);
	}

	componentDidMount() {	
        	this.fetchAccountReleases();
    }
    
    
	fetchAccountReleases() {
		const req: Account.ReleasesGetRequest = {
			release_status: [Account.ReleaseStatus.W, Account.ReleaseStatus.N],
			release_type: this.state.releaseTypeDocument
		}
		api.asyncRequest<any>(
			req,
			apis.ReleaseApi,
			'releasesGet'
		).then((response: Array<Account.Release>) => {
			if (response != null) {
				const res = [];
				for(const i in response) {
					res.push({
						source: 'i',
						...response[i]
					})
				}
				this.setState({
					approvalDataAccount: res,
				}, () => {
					this.resetIndex = false;
					if(this.tableRef != null) {
						this.tableRef.resetPaging(0);
					}

				})
			}
		})
		.catch((error: ApiError) => {
			Log.error(Logs.API, error);
			MessageHandler.onError(Reporter['backoffice.relaeses.fetch'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
		});
	}
	
	compareData(a: IAccountWithSource , b: IAccountWithSource ): number {
		return -compareDate(a.datetime_create, b.datetime_create) 
	}

	private setTableRef(element: Table<IAccountWithSource>): void {
		this.tableRef = element;
	}

	render() {
		const accountData: Array<IAccountWithSource>  = this.state.approvalDataAccount != null ? this.state.approvalDataAccount : [];
		const srcData = accountData.sort(this.compareData);
        if(this.state.selectedQueue != null && this.state.selectedQueue.type === Account.ReleaseType.Eyeson) {
            return (
                <VideoVerification 	release_request_id={this.state.selectedQueue.release_request_id} 
									callback={() => {
										this.setState({ selectedQueue: undefined},
										() => this.fetchAccountReleases())
									} }/>
            )
        }
		return (
			<Main>
				<SplitView>	
					<div style={{display: 'flex'}}>
							<IconWrapper
								style={{alignSelf: 'flex-start'}} 
								onClick={() => this.fetchAccountReleases()}>
									{Icons.refresh()}
							</IconWrapper>
					<PagingWrapper>
						<StyledInput 
								id="search"
								placeHolder={translate('transactions.search.placeholder')}
								iconRight={Icons.search()}
								containerStyle={{
									height: '32px',
									marginTop: '8px',
									width: '100%',
									boxShadow: 'none',
									marginRight: '32px',
								}}
								input={{
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									border: '1px solid #E8ECEF',
									boxShadow: 'none',
									minWidth: '100px',
									maxWidth: '280px',
									height: '32px',
									paddingRight: '5px',
								}}
								inline={true}
								valueFromState={true}
								value={this.state.searchValue}
								notification={ (message: any) => {
									this.setState({
										searchValue: message?.search?.value
									})
								}
							}
						/>
						<PagingComponent
							numberOfEntries={srcData.length }
							rowsPerPage={5}
							resetIndex={this.resetIndex}
						/>
					</PagingWrapper>
					</div>
					<div style={{maxHeight: 'calc(100vh - 400px', overflowY: 'auto'}}>
						{srcData == null ?  null : 
							<Table<IAccountWithSource>
									header={[
										'ID',
										translate('backoffice.approvals.header.created'),
										'Person ID',
										translate('backoffice.approvals.header.name'),
										translate('backoffice.approvals.header.type'),
										translate('contextMenu.startQueue')
									]}
									sourceData={
										srcData
									}
									fields={[
										'release_requests_id',
										'datetime_create',
										'requested_by_person_id',
										'requested_by_person_given_name',
										'type',
										'source'
									]}
									stickyHeader={true}
									dataConverter={this.converterAccount}									
									dynamicPaging={true}
									externalPaging={true}   
									ref={this.setTableRef}    
									searchValue={this.state.searchValue}                     
								/>
						}
						</div>
					</SplitView>
			</Main>
		);
	}
}

export default VideoApprovalQueue;
