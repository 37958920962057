// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CardLimit
 */
export interface CardLimit {
    /**
     * @type {string}
     * @memberof CardLimit
     */
    limit_name: CardLimitLimitNameEnum;
    /**
     * @type {number}
     * @memberof CardLimit
     */
    limit_amount: number;
}

/**
 * @export
 * @enum {string}
 */
export enum CardLimitLimitNameEnum {
    ATMCashWithdrawalOneForCountDaily = 'ATM Cash Withdrawal OneFor (count Daily)',
    ATMCashWithdrawalOneForAmountDaily = 'ATM Cash Withdrawal OneFor (amount Daily)',
    ATMCashWithdrawalOneForCountMonthly = 'ATM Cash Withdrawal OneFor (count Monthly)',
    ATMCashWithdrawalOneForAmountMonthly = 'ATM Cash Withdrawal OneFor (amount Monthly)',
    ATMCashWithdrawalOneForCountYearly = 'ATM Cash Withdrawal OneFor (count Yearly)',
    ATMCashWithdrawalOneForAmountYearly = 'ATM Cash Withdrawal OneFor (amount Yearly)',
    POSPurchaseOneForCountDaily = 'POS Purchase OneFor (count Daily)',
    POSPurchaseOneForAmountDaily = 'POS Purchase OneFor (amount Daily)',
    POSPurchaseOneForCountMonthly = 'POS Purchase OneFor (count Monthly)',
    POSPurchaseOneForAmountMonthly = 'POS Purchase OneFor (amount Monthly)',
    POSPurchaseOneForCountYearly = 'POS Purchase OneFor (count Yearly)',
    POSPurchaseOneForAmountYearly = 'POS Purchase OneFor (amount Yearly)',
    TotalSpendingAccountLimitOneForCountDaily = 'Total spending account limit OneFor (count Daily)',
    TotalSpendingAccountLimitOneForAmountDaily = 'Total spending account limit OneFor (amount Daily)',
    TotalSpendingAccountLimitOneForAmountMonthly = 'Total spending account limit OneFor (amount Monthly)',
    TotalSpendingAccountLimitOneForAmountYearly = 'Total spending account limit OneFor (amount Yearly)'
}

