//@ts-nocheck
import React, { SyntheticEvent, ReactElement, ReactNode } from 'react';
import { Form } from './styledComponents';
import queryString from 'query-string';
import AuthScreen, { IAuthScreenProps, IAuthScreenState } from '../authScreen';
import { IAuthConfig } from './IAuthConfig';
import { withRouter } from 'react-router';
import { ApiError, apis, VoidResponse, Ident, api } from '../../../logic/api';
import { MessageHandler } from '../../../logic/handler/messagehandler/messageHandler';
import { translate } from '../../../common/language/translate';
import {
	Reporter,
	IMessageConfig,
	IStatusState,
	getFormComponents,
} from '../../../logic/handler/messagehandler/messageHandlerConfig';
import { StyledAuthComponent } from './auth.css';
import { Log, Logs } from '../../../logic/log';
import { ConfirmationComponent } from './confirmationComponent';
import { BigInput } from '../../../components/atomiccompoents/form/inputs.css';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';
import { CheckBox } from '../../../components/atomiccompoents/form/checkbox';
import { FlexBox } from '../auth.css';
import { evaluateErrorMessage } from '../../../logic/helper/Common';

interface IProps extends IAuthScreenProps {}

interface IState extends IAuthScreenState, IStatusState {
	buttonDisabled: boolean;
	checked: boolean;
	firstNameValid: boolean;
	lastNameValid: boolean;
	emailValid: boolean;
	showComponent?: ReactElement;
	errorEmail?: string;
	firstnameError?: string;
	lastnameError?: string;
	checkedError?: string;
}

interface INotification {
	email?: {
		valid: boolean;
		error?: string;
		value: string;
	};
	lastname?: {
		valid: boolean;
		error?: string;
		value: string;
	};
	firstname?: {
		valid: boolean;
		error?: string;
		value: string;
	};
	agree: {
		checked: boolean;
	};
}

class SignupComponentClass extends AuthScreen<IProps, IState> {
	private email: string | undefined = undefined;
	private firstname: string | undefined = undefined;
	private lastname: string | undefined = undefined;
	private agreed: boolean = false;

	constructor(props: IProps) {
		super(props);

		const values = queryString.parse(props.location == null ? '' : props.location.search);
		this.email =
			values.email == null
				? undefined
				: values.email instanceof Array
				? values.email[0]
				: values.email;

		this.state = {
			buttonDisabled: true,
			showComponent: undefined,
			checked: false,
			emailValid: false,
			firstNameValid: false,
			lastNameValid: false,
		};

		this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(event: SyntheticEvent | React.KeyboardEvent<HTMLFormElement>) {
		event.preventDefault();
		Log.debug(Logs.AUTH, 'onsubmit');

		if (
			this.state.firstNameValid &&
			this.lastname != null &&
			this.state.lastNameValid &&
			this.firstname != null &&
			this.state.emailValid &&
			this.email != null &&
			this.state.checked
		) {
			const request: Ident.OauthSignupPostRequest = {
				AuthSignupRequest: {
					idp_state: this.idpState,
					name: this.lastname,
					given_name: this.firstname,
					email_address: this.email,
				},
			};
			api.asyncRequest<VoidResponse>(request, apis.OpenIDConnectApi, 'oauthSignupPost')
				.then(() => {
					const currentAuthConfig: IAuthConfig | undefined = this.getAuthConfigById(
						'gotMail'
					);
					this.setState({
						showComponent: (
							<ConfirmationComponent currentAuthConfig={currentAuthConfig} />
						),
					});
				})
				.catch((error: ApiError) => {
					const conf: IMessageConfig = MessageHandler.onError(
                        Reporter['web.auth.signup.post'],
						evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
					);
					if (error.response != null && error.response.idp_state != null) {
						this.idpState = error.response.idp_state;
					}
					this.setState({
						errorMessage:
							conf.errorMessage != null ? conf.errorMessage : error.statusText,
						showInlineError: conf.errorMethods.inline === true,
						key: conf.translationKey != null ? conf.translationKey + '.error' : '',
					});
				});
		} else {
			let firstnameError: string | undefined = undefined;
			let lastnameError: string | undefined = undefined;
			let emailError: string | undefined = undefined;
			let checkedError: string | undefined = undefined;
			if (!this.state.firstNameValid) {
				firstnameError = translate('auth.signup.input.firstName.error');
			}

			if (!this.state.lastNameValid) {
				lastnameError = translate('auth.signup.input.lastName.error');
			}

			if (!this.state.emailValid) {
				emailError = translate('auth.signup.input.email.error');
			}

			if (!this.state.checked) {
				checkedError = translate('auth.signup.input.conditions.error');
			}

			this.setState({
				firstnameError: firstnameError,
				lastnameError: lastnameError,
				errorEmail: emailError,
				checkedError: checkedError,
			});
		}
	}

	refreshEmailError() {
		this.setState({ errorEmail: this.state.errorEmail });
	}

	notification(notification: INotification) {
		if (notification != null) {
			if (notification.email != null) {
				const email = notification.email;
				this.email = email.value.trim();
				this.setState({
					buttonDisabled:
						email.error != null ||
						!email.valid ||
						email.value == null ||
						!this.agreed ||
						!this.state.lastNameValid ||
						!this.state.firstNameValid,
					emailValid: email.valid,
					errorEmail: email.error,
				});
			}
			if (notification.firstname != null) {
				const firstname = notification.firstname;
				this.firstname = firstname.value.trim();
				this.setState({
					buttonDisabled:
						firstname.error != null ||
						!firstname.valid ||
						firstname.value == null ||
						!this.agreed ||
						!this.state.emailValid ||
						!this.state.lastNameValid,
					firstNameValid: firstname.value != null && this.lastname !== '',
					firstnameError: firstname.error,
				});
			}
			if (notification.lastname != null) {
				const lastname = notification.lastname;
				this.lastname = lastname.value.trim();
				this.setState({
					buttonDisabled:
						lastname.error != null ||
						!lastname.valid ||
						lastname.value == null ||
						!this.agreed ||
						!this.state.emailValid ||
						!this.state.firstNameValid,
					lastNameValid: lastname.value != null && this.lastname !== '',
					lastnameError: lastname.error,
				});
			}
		}
	}

	private onChangeCheckbox(value: boolean): void {
		this.agreed = value;
		this.setState({
			buttonDisabled:
				!this.agreed ||
				!this.state.emailValid ||
				!this.state.lastNameValid ||
				!this.state.firstNameValid,
			checked: value,
		});
	}

	onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
		// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			this.onSubmit(event);
		}
	};

	defineContent(): ReactNode {
		return null;
	}

	reviveState(): void {}

	render() {
		if (this.state.showComponent != null) {
			return this.state.showComponent;
		}

		return (
			<StyledAuthComponent>
				<div>{translate(this.getTitle())}</div>
				<div>{translate(this.getSubtitle() as string)}</div>

				<FlexBox>
					<Form onSubmit={this.onSubmit} onKeyDown={this.onKeyDown}>
						<FlexBox>
							<BigInput
								type="firstname"
								id="firstname"
								label={translate('auth.signup.input.firstName.label')}
								error={this.state.firstnameError}
								notification={(notification: INotification) =>
									this.notification(notification)
								}
								onSubmit={this.onSubmit}
							/>
							<BigInput
								type="lastname"
								id="lastname"
								label={translate('auth.signup.input.lastName.label')}
								error={this.state.lastnameError}
								notification={(notification: INotification) =>
									this.notification(notification)
								}
								onSubmit={this.onSubmit}
							/>
							<BigInput
								type="email"
								id="email"
								label={translate('auth.signup.input.email.label')}
								error={this.state.errorEmail}
								refresh={this.refreshEmailError}
								notification={(notification: INotification) =>
									this.notification(notification)
								}
								onSubmit={this.onSubmit}
							/>
							<FlexBox>
								{getFormComponents(
									this.state.showInlineError,
									this.state.showInlineSuccess,
									this.state.errorMessage,
									this.state.successMessage,
									this.state.key
								)}
								<ButtonOk
									disableSpinner={true}
									onClick={this.onSubmit}
									id="btnProceed"
									style={{ width: '100%' }}>
									{translate('auth.signup.signup')}
								</ButtonOk>
								<FlexBox>
									<CheckBox
										id="agree"
										value={this.state.checked}
										onChange={(value: boolean) =>
											this.onChangeCheckbox(value)
										}
										label={translate('auth.signup.input.conditions.label')}
										error={this.state.checkedError}
									/>
								</FlexBox>
							</FlexBox>
						</FlexBox>
					</Form>
				</FlexBox>
				{this.props.children}
			</StyledAuthComponent>
		);
	}
}

export const SignupComponent = withRouter(SignupComponentClass);
