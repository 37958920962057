import { IPermissionConfig } from '../../logic/types';
import { Ident, Account } from '../../logic/api';

export const permissionConfig: IPermissionConfig = {
	nav: {
		administration: {
			fees: {
				oneOf: [Ident.OperationId.PersonSearch],
			},
			permissions: {
				oneOf: [Ident.OperationId.PersonSearch],
			},
			reports: {
				oneOf: [Account.OperationId.ReportTemplatesAdminGet],
			},
			teams: {
				oneOf: [Ident.OperationId.PermissionsPersonsGet],
			},
			oneOf: [Ident.OperationId.PersonSearch],
		},
		backoffice: {
			postingTemplates: {
				oneOf: [Account.OperationId.TransactionTemplatesBoGet],
			},
			reports: {
				oneOf: [Account.OperationId.ReportTemplatesBoGet],
			},
			transactions: {
				oneOf: [Account.OperationId.TransactionGet],
			},
			oneOf: [Ident.OperationId.PersonSearch],
		},
		customers: {
			postingTemplates: {
				oneOf: [Account.OperationId.TransactionTemplatesCustomerGet],
			},
			reports: {
				oneOf: [Account.OperationId.ReportTemplatesCustomerGet],
			},
			transactions: {
				oneOf: [Account.OperationId.TransactionGet],
			},
			oneOf: [
				Ident.OperationId.PersonSearch,
				Ident.OperationId.PersonCreateThirdPersonPost,
			],
		},
		dashboard: {
			oneOf: [Ident.OperationId.PersonGet],
		},
		servicecenter: {
			oneOf: [Ident.OperationId.RegulaforensicsApiProcessGet]
		},
		product_management: {
			oneOf: [Account.OperationId.MerchantProductTypePost]
		},
		reports: {
			oneOf: [Ident.OperationId.PersonSearch],
		},
		settings: {
			oneOf: [Ident.OperationId.PersonSearch],
		},
	},
	userMenu: {
		logoutButton: {
			allOf: [Ident.OperationId.OauthLogoutGet],
		},
		changePassword: {
			allOf: [Ident.OperationId.SelfPasswordPut],
		},
		oneOf: [Ident.OperationId.OauthLogoutGet, Ident.OperationId.SelfPasswordPut],
	},
};
