// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * the position   *  `0` - Unknown   *  `1` - Right thumb   *  `2` - Right index finger   *  `3` - Right middle finger   *  `4` - Right ring finger   *  `5` - Right little finger   *  `6` - Left thumb   *  `7` - Left index finger   *  `8` - Left middle finger   *  `9` - Left ring finger   * `10` - Left little finger 
 * @export
 * @enum {string}
 */
export enum NistPosition {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10
}

