// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Iso4217Currency
} from './';

/**
 * @export
 * @interface ProductAirports
 */
export interface ProductAirports {
    /**
     * @type {number}
     * @memberof ProductAirports
     */
    product_id: number;
    /**
     * amount in cents to be compatible with stripe api
     * @type {number}
     * @memberof ProductAirports
     */
    price?: number;
    /**
     * @type {Iso4217Currency}
     * @memberof ProductAirports
     */
    currency?: Iso4217Currency;
    /**
     * @type {string}
     * @memberof ProductAirports
     */
    name?: string;
    /**
     * @type {string}
     * @memberof ProductAirports
     */
    claim?: string;
    /**
     * @type {string}
     * @memberof ProductAirports
     */
    long_description?: string;
    /**
     * a link to the product image
     * @type {string}
     * @memberof ProductAirports
     */
    image?: string;
    /**
     * @type {boolean}
     * @memberof ProductAirports
     */
    is_noweda_standard?: boolean;
    /**
     * @type {String | object}
     * @memberof ProductAirports
     */
    type_of_test?: String | object;
    /**
     * @type {number}
     * @memberof ProductAirports
     */
    vat: number;
    /**
     * @type {Array<Array<string>>}
     * @memberof ProductAirports
     */
    languages?: Array<Array<ProductAirportsLanguagesEnum>>;
    /**
     * * O - online  * P - physical
     * @type {string}
     * @memberof ProductAirports
     */
    test_method?: ProductAirportsTestMethodEnum;
    /**
     * * B - antiBody  * G - antiGen  * P - PCR  * V - Vaccine 
     * @type {string}
     * @memberof ProductAirports
     */
    product_group?: ProductAirportsProductGroupEnum;
    /**
     * amount of voucher the customer gets in case of positive testresult   
     * @type {number}
     * @memberof ProductAirports
     */
    voucher_amount?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ProductAirportsLanguagesEnum {
    De = 'de',
    El = 'el',
    En = 'en',
    Fr = 'fr',
    Nl = 'nl',
    Ru = 'ru',
    Tr = 'tr',
    Zh = 'zh'
}
/**
 * @export
 * @enum {string}
 */
export enum ProductAirportsTestMethodEnum {
    O = 'O',
    P = 'P'
}
/**
 * @export
 * @enum {string}
 */
export enum ProductAirportsProductGroupEnum {
    B = 'B',
    G = 'G',
    P = 'P',
    V = 'V',
    D = 'D',
    C = 'C'
}

