import IconPickerOverlay from "../../../components/atomiccompoents/iconPicker/iconPickerOverlay";
import { BubbleContainer } from "../../../components/bubblecontainer";
import ImageSelectOverlay from "../../../components/compositcomponents/imageSelectCompoent/imageSelectOverayl";
import { ConfirmationMessageOverlay } from "../../../components/compositcomponents/popup/confirmationMessageOverlay";
import ConfirmationRequestOverlay from "../../../components/compositcomponents/popup/confirmationRequestOverlay";
import ConfirmationRequestOverlayWithReason from "../../../components/compositcomponents/popup/confirmationRequestOverlayWithReason";
import { ContextMenuOverlay } from "../../../components/compositcomponents/popup/contextMenuOverlay";
import { ErrorMessageOverlay } from "../../../components/compositcomponents/popup/errorMessageOverlay";
import { FileChooserOverlay } from "../../../components/compositcomponents/popup/fileChooserOverlay";
import FileTypeChooserOverlay from "../../../components/compositcomponents/popup/fileTypeChooserOverlay";
import ImagePreview from "../../../components/compositcomponents/popup/imagePreview";
import { MultipleFileChooserOverlay } from "../../../components/compositcomponents/popup/multipleFileChooserOverlay";
import { PDFViewerOverlay } from "../../../components/compositcomponents/popup/pdfViewerOverlay";
import PopupMenuComponent from "../../../components/compositcomponents/popup/popUpMenuComponent";
import { ToolTipOverlay } from "../../../components/compositcomponents/popup/toolTipOverlay";
import WarningOverlay from "../../../components/compositcomponents/popup/warningOverlay";
import { DateComponent } from "../../../components/datepicker/datecomponent";
import { UserMenu, MessageMenu } from "../../../components/menu";
import BackOfficeSearchHelp from "../../../content/dashboard/content/backoffice/backofficeSearchHelp";
import addCalendarItemExceptionOverlay from "../../../content/dashboard/content/customers/CustomerOverlays/addCalendarItemExceptionOverlay";
import addCalendarItemExcludeOverlay from "../../../content/dashboard/content/customers/CustomerOverlays/addCalendarItemExcludeOverlay.";
import addCalendarItemOverlay from "../../../content/dashboard/content/customers/CustomerOverlays/addCalendarItemOverlay";
import AddressEditOverlay from "../../../content/dashboard/content/customers/CustomerOverlays/addressEditOverlay";
import MailEditOverlay from "../../../content/dashboard/content/customers/CustomerOverlays/emailEditOverlay";
import KycDataOverlay from "../../../content/dashboard/content/customers/CustomerOverlays/kycDataOverlay";
import LimitInfoOverlay from "../../../content/dashboard/content/customers/CustomerOverlays/limitInfoOverlay";
import ProductOverlay from "../../../content/dashboard/content/customers/CustomerOverlays/productsOverlay";
import productTypeOverlay from "../../../content/dashboard/content/customers/CustomerOverlays/productTypeOverlay";
import ReportsOverlay from "../../../content/dashboard/content/customers/CustomerOverlays/reportOverlay";
import { SelectProductImageOverlay } from "../../../content/dashboard/content/customers/CustomerOverlays/selectIProducImageOverlay";
import RoleEditOverlay from "../../../content/dashboard/content/administration/teamMembers/roleEditOverlay";
import TeamMemberOverlay from "../../../content/dashboard/content/administration/teamMembers/teamMemberOverlay";
import PostingJournalOverlay from "../../../content/dashboard/content/transfer/postingJournalOverlay";
import TransactionDetailsOverlay from "../../../content/dashboard/content/transfer/transactionDetailsOverlay";
import AlertMessages from "../../../content/dashboard/header/AlertMessages";
import NewPasswordComponent from "../../../content/dashboard/header/newPasswordComponent";
import Notifications from "../../../content/dashboard/header/Notifications";
import DeveloperOverlay from "../../../content/overlays/developer/developerOverlay";
import { IOverlayOptions } from "../overlayhandler/overlayHandler";
import { RequestDataOverlay } from "../../../components/compositcomponents/popup/requestDataOverlay";

export const options: IOverlayOptions = {
	alerts: {
		overlayId: 'alerts',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: AlertMessages,
		isCentered: true,
	},
	notifications: {
		overlayId: 'notifications',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: Notifications,
	},
	postingJournal: {
		overlayId: 'postingJournal',
		modal: true,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: PostingJournalOverlay,
		isCentered: true,
	},
	teamMember: {
		overlayId: 'teamMember',
		modal: true,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: TeamMemberOverlay,
	},
	userMenu: {
		overlayId: 'userMenu',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: true,
		component: UserMenu,
	},
	transactionDetail: {
		overlayId: 'transactionDetail',
		modal: true,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: TransactionDetailsOverlay,
		isCentered: true,
	},
	errorMessage: {
		overlayId: 'error',
		modal: true,
		isClosedOnClickOutside: false,
		closesOpenedOverlays: false,
		component: ErrorMessageOverlay,
		isCentered: true,
	},
	changePassword: {
		overlayId: 'changePassword',
		modal: true,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: NewPasswordComponent,
		isCentered: true,
	},
	successMessage: {
		overlayId: 'success',
		modal: true,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: ConfirmationMessageOverlay,
		isCentered: true,
	},
	confirmationOverlay: {
		overlayId: 'confirmationOverlay',
		modal: true,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: ConfirmationMessageOverlay,
		isCentered: true,
	},
	backofficeSearchHelp: {
		overlayId: 'BackOfficeSearchHelp',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: BackOfficeSearchHelp,
		isCentered: true,
	},
	ConfirmationRequestOverlay: {
		overlayId: 'ConfirmationRequestOverlay',
		modal: true,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: ConfirmationRequestOverlay,
		isCentered: true,
	},
	messageBubble: {
		overlayId: 'messageBubble',
		modal: false,
		isClosedOnClickOutside: false,
		closesOpenedOverlays: false,
		component: BubbleContainer,
	},
	roleEdit: {
		overlayId: 'roleEdit',
		modal: true,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: RoleEditOverlay,
	},
	messageMenu: {
		overlayId: 'messageMenu',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: true,
		component: MessageMenu,
	},
	datePicker: {
		overlayId: 'datePicker',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		dupilcate: true,
		component: DateComponent,
	},
	datePickerRangeElement: {
		overlayId: 'datePickerRangeElement',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		dupilcate: false,
		component: DateComponent,
	},
	iconPicker: {
		overlayId: 'iconPicker',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: IconPickerOverlay,
	},
	imagePreview: {
		overlayId: 'imagePreview',
		modal: true,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: ImagePreview,
		isCentered: true,
	},
	developer: {
		overlayId: 'developer',
		modal: true,
		isClosedOnClickOutside: false,
		closesOpenedOverlays: true,
		component: DeveloperOverlay,
		isCentered: true,
	},
	toolTip: {
		overlayId: 'toolTip',
		modal: false,
		isClosedOnClickOutside: false,
		closesOpenedOverlays: false,
		component: ToolTipOverlay,
		isCentered: false,
	},
	FileTypeChooser: {
		overlayId: 'FileTypeChooser',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: FileTypeChooserOverlay,
		isCentered: false,
	},
	PDFViewer: {
		overlayId: 'PDFViewer',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: PDFViewerOverlay,
		isCentered: false,
	},
	contextMenu: {
		overlayId: 'contextMenu',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: ContextMenuOverlay,
		isCentered: false,
	},
	warningOverlay: {
		overlayId: 'warningOverlay',
		modal: true,
		isClosedOnClickOutside: false,
		closesOpenedOverlays: false,
		component: WarningOverlay,
		isCentered: true,
    },
    addressOverlay: {
        overlayId: 'addressOverlay',
		modal: true,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: AddressEditOverlay,
		isCentered: true,
    },
    fileChooserOverlay: {
        overlayId: 'fileChooserOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: FileChooserOverlay,
		isCentered: true,
	},
    imageSelectOverlay: {
        overlayId: 'imageSelectOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
        component: ImageSelectOverlay,
        isCentered: false,
	},
	multipleFileChooser: {
        overlayId: 'multipleFileChooser',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
        component: MultipleFileChooserOverlay,
        isCentered: true,
	},
	emailOverlay: {
        overlayId: 'emailOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
        component: MailEditOverlay,
        isCentered: true,
	},
	productOverlay: {
        overlayId: 'productOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
        component: ProductOverlay,
        isCentered: true,
	},
	reportOverlay: {
        overlayId: 'reportOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
        component: ReportsOverlay,
        isCentered: true,
	},
	selectProductImageOverlay:  {
        overlayId: 'selectProductImageOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
        component: SelectProductImageOverlay,
        isCentered: true,
	},
	confirmationRequestOverlayWithReason: {
		overlayId: 'confirmationRequestOverlayWithReason',
		modal: true,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: ConfirmationRequestOverlayWithReason,
		isCentered: true,
	},
	limitInfoOverlay: {
		overlayId: 'limitInfoOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: LimitInfoOverlay,
		isCentered: false,
	},
	productTypeOverlay: {
		overlayId: 'productTypeOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: productTypeOverlay,
		isCentered: false,
	},
	kycDataOverlay: {
		overlayId: 'kycDataOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: KycDataOverlay,
		isCentered: false,
	},
	addCalendarItemOverlay: {
		overlayId: 'addCalendarItemOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: addCalendarItemOverlay,
		isCentered: true,
	},
	addCalendarItemExceptionOverlay: {
		overlayId: 'addCalendarItemExceptionOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: addCalendarItemExceptionOverlay,
		isCentered: true,
	},
	addCalendarItemExcludeOverlay: {
		overlayId: 'addCalendarItemExcludeOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: addCalendarItemExcludeOverlay,
		isCentered: true,
	},
	popupMenuComponent: {
		overlayId: 'PopupMenuOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: PopupMenuComponent,
		isCentered: false,
	},
	requestDataOverlay: {
		overlayId: 'requestDataOverlay',
		modal: false,
		isClosedOnClickOutside: true,
		closesOpenedOverlays: false,
		component: RequestDataOverlay,
		isCentered: true,
	},
}
