import React from 'react';
import { Input } from '../form/input';
import { PermissionStore } from '../../../logic/flux/stores/permissionStore';
import { Ident, Account } from '../../../logic/api';
import { DisplayMenuComponent } from '../../compositcomponents/displayMenuComponent';
import { EditableComponentIndexStore } from '../../../logic/flux/stores/editableComponentStore';
import { translate } from '../../../common/language/translate';
import { KeyField, ValueField } from '../../../content/dashboard/content/customers/basicStyledComponents/customerDetails.css';
import { InputView, TextView, LargeKeyValueBlock } from './editableComponent.css';

interface IProps {
    translationkey?: string;
    viewStyle?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    wrapperStyle?: React.CSSProperties;
    initText: string;
    changeCallback: (value: string, key?: string) => void;
    enterCallback: (value: string, key?: string) => void;
    editAddressCallback?: () => void;
    id: string;
    permission?: Array<Account.OperationId | Ident.OperationId> | Account.OperationId | Ident.OperationId;
    active?: boolean;
    indexCallback?: (index: number) => void,
    index?: number,
    leaveCallback?: () => void;
    disabled?: boolean;
    editMode?: boolean;
    error?: string;
    multineLine?: boolean;
    rows?: number;
    cols?: number;
    callEnterOnChange?: boolean;
}

interface IState {
    value: string;
    editMode: boolean;
    focused?: boolean;
    error?: string;
    hasPermission: boolean;
}

export default class EditableComponent extends React.Component<IProps, IState> {
    inputRef = React.createRef<Input>();
	constructor(props: IProps) {
		super(props);

		this.state = {
            value: props.initText,
            editMode: props.editMode != null ? props.editMode : false,
            hasPermission: this.hasPermission(),
        };
        this.swapEditMode = this.swapEditMode.bind(this);
        this.onChange = this.onChange.bind(this);
        this.paste = this.paste.bind(this);
        this.onEnterWithoutSafe = this.onEnterWithoutSafe.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.receiveActiveIndex = this.receiveActiveIndex.bind(this);
    }


    componentWillReceiveProps(props: IProps) {
        if(props.editMode != null) {
            this.setState({
                value: props.initText,
                editMode: props.editMode === true && this.state.hasPermission && this.props.disabled !== true ? true : this.state.editMode,
                error: props.error
            })
        } else {
            this.setState({
                value: props.initText,
                error: props.error
            })
        }
    }

    componentWillUnmount() {
        EditableComponentIndexStore.setActiveIndex(-1);
        EditableComponentIndexStore.removeChangeListener(this.receiveActiveIndex)
    }

    receiveActiveIndex() {        
       const idx = EditableComponentIndexStore.getActiveIndex();
       if(this.props.index != null && this.props.index === idx) {
           this.setEditModeToValue(true);
       } else {
           this.setEditModeToValue(false);
       }
    }

    hasPermission() {
        if(  this.props.permission == null || 
          (  Array.isArray(this.props.permission) && PermissionStore.hasOnePermissionOf(this.props.permission) ) || 
          ( !Array.isArray(this.props.permission) && PermissionStore.hasPermission(this.props.permission) ) ) {
            return true;
        }
        return false;
    }
    
    swapEditMode(enter?: boolean) {
        if(this.state.hasPermission && this.props.disabled !== true) {
            this.setState({
                editMode: !this.state.editMode,
                value: this.state.value,
            }, () => {
                if(enter === true) {
                    this.props.enterCallback(this.state.value, this.props.id);
                }
            })
        } else {
            this.setState({
                error: 'no permission given'
            })
        }
    }

    setEditModeToValue(value: boolean, indexCallback?: boolean) {
        if(this.props.indexCallback != null && this.props.index != null && indexCallback === true) {
            this.props.indexCallback(this.props.index);
        }
        if(this.state.hasPermission && this.props.disabled !== true ) {
            this.setState({
                editMode: value    
            })
        } else {
            this.setState({
                error: 'no permission given'
            })
        }
    }

    componentDidMount() {
        const node = document.getElementById("editableComp" + this.props.id);
        if( node != null ) {
            node.addEventListener("keyup", (event: any) => {
                if (event.key === "Enter") {
                   this.onEnter();
                }
            });
        }
        if(this.state.editMode === true && this.inputRef.current != null) {
            this.inputRef.current.handleFocus();
        }
        if(this.props.index != null) {
            EditableComponentIndexStore.addChangeListener(this.receiveActiveIndex);
            EditableComponentIndexStore.setMaxListeners(20);
        }
        
    }

    onChange(message: string) {     
        if(message != null ){
            if(this.props.changeCallback != null) {
                this.props.changeCallback(message, this.props.id);
            }
            if(this.props.callEnterOnChange !== false) {
                this.props.enterCallback(message, this.props.id);
            }
        }
    }

    onEnter() {
        if(this.state.hasPermission) {
            this.swapEditMode(true);
            if(this.props.leaveCallback != null) {
                this.props.leaveCallback();
            }
        } else {
            this.setState({
                error: 'no permission given'
            })
        }
    }

    onBlur(event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) {
        if(event.isPropagationStopped() ) {
            return;
        }
        this.onEnter();   
        if(this.props.callEnterOnChange === false) {
            this.props.enterCallback(event.target.value, this.props.id);
        }  
    }

    onEnterWithoutSafe() {
       this.setState({
           editMode: false,
           value: this.props.initText,
       })
    }

    paste(text: string) {
        this.setState({
            value: text
        })
    }

	render() {
		return (
			<React.Fragment>
                <LargeKeyValueBlock 
                            big={this.props.multineLine && this.state.editMode}
                            style={this.props.wrapperStyle}
                            onDoubleClick = {() => {
                                this.swapEditMode();
                                if(this.props.indexCallback != null && this.props.index != null) {
                                    this.props.indexCallback(this.props.index)
                            }
                        }} >
                   {this.props.translationkey != null ? 
                   <KeyField >
                            {translate(
                                this.props.translationkey, this.props.translationkey
                            ) + (this.props.disabled === true ? '' :  ' *')}: 
                    </KeyField>	
                    : null }
                    <ValueField > 
                    {this.state.editMode === true ? 
                        <DisplayMenuComponent
                            elementKey={this.props.id}
                            methods={{
                                pasteCallback: this.state.hasPermission ? this.paste  : undefined,
                            }}
                            copyValue={this.state.value}>
                            {this.props.multineLine === true ? 
                                <textarea rows={this.props.rows ?? 5}
                                          cols={this.props.cols ?? 50}
                                          onBlur={this.onBlur}
                                          autoFocus={true}
                                          value={this.state.value}
                                          onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                              this.onChange(ev.target.value)
                                          }}  
                                          style={this.props.inputStyle}></textarea>
                                :
                                <InputView 
                                    id={"editableComp" + this.props.id}
                                    value={this.state.value}
                                    valueFromState={true}
                                    type="text"
                                    input={this.props.inputStyle}
                                    onChange={this.onChange}
                                    onSubmit={() => {
                                        this.onEnter()
                                        }
                                    }
                                    onLeave={(this.onBlur)}
                                    ref={this.inputRef}
                                    focusOnRender={true}
                                    toolTip={true}
                                    error={this.state.error}
                                    inline={true}
                                /> 
                        }
                        </DisplayMenuComponent> :
                        <DisplayMenuComponent
                            elementKey={this.props.id}
                            methods={{
                                pasteCallback: this.state.hasPermission ? this.paste  : undefined,
                                editCallback: this.state.hasPermission  && this.props.disabled !== true ? () => this.setEditModeToValue(true, true)  : undefined,
                                editAddressCallback: this.state.hasPermission && this.props.editAddressCallback != null ? this.props.editAddressCallback : undefined,
                            }}
                            copyValue={this.state.value}>

                            <TextView 
                                title={this.state.error != null ? this.state.error : this.state.value}
                                style={this.props.viewStyle}
                                
                                hasPermission={(this.state.hasPermission && this.props.disabled) !== true }
                                hasError={this.state.error != null}
                                >
                                    {this.state.value}
                            </TextView> 
                        </DisplayMenuComponent>
                        }
                    </ValueField> 
                </LargeKeyValueBlock> 
            </React.Fragment>
		);
	}
}
