// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * debit_card - a debit card * credit_card - a credit_card 
 * @export
 * @enum {string}
 */
export enum CardType {
    DebitCard = 'debit_card',
    CreditCard = 'credit_card'
}

