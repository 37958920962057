import React from 'react';
import AGenericElement, {
	IGenericElementProps,
	IGenericElementState,
	showErrorModeEnum,
} from './AGenericElement';
import styled, { withTheme } from 'styled-components';
import { CustomDatePicker } from '../../components/datepicker';
import { Icons } from '../../images';
import { compareDate } from '../../logic/helper/Common';
import { GenericElementType } from './IGenericElement';

const StyledView = styled.div`
	width: 100%;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: ${props => props.theme.Global.darkFontColor};
`;

interface IProps extends IGenericElementProps {
    theme?: any;
	helpText?: string;
	active?: boolean;
	activeIndex?: number;
	activeIndexCallback?: (index: number) => void;
}

interface IState extends IGenericElementState {
	value?: Date;
}

class GenericDateInputClass extends AGenericElement<IProps, IState> {
	private DatePickerStyle: React.CSSProperties = {
		height: '52px',
		borderStyle: this.props.theme.Box.borderStyle,
		borderColor: this.props.theme.Box.borderColor,
		borderWidth: this.props.theme.Box.borderWidth,
		borderRadius: this.props.theme.Box.borderRadius,
		marginBottom: '14px',
	};

	constructor(props: IProps) {
		super(props);
		this.state = {
			showErrorMode: showErrorModeEnum.whileTyping,
		};
		this.resetDate = this.resetDate.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	resetDate() {
		this.setState({
		});
	}

	onChange(newDate: Date) {
		if(0 === compareDate(newDate, this.state.value)) {
			return;
		}
		this.setState({
			value: new Date(newDate),
		});
		this.props.notification(
			this.props.requestBodyUuid,
			new Date(newDate),
			this.props.index,
			false
		)
	}

	render() {
		const title = this.props.title != null
		? this.props.title + (this.props.isRequired === true ? ' *' : '')
		: ''
		return (
			<StyledView  onClick={() => {
				if(this.props.activeIndexCallback != null && this.props.activeIndex != null) {
					this.props.activeIndexCallback(this.props.activeIndex)
				}
			}}>
				<CustomDatePicker
					title={this.props.helpText != null ? this.props.helpText : 'Select ' + title}
					selectedValue={this.state.value}
					onChange={this.onChange}
					iconCallback={Icons.calendarExpand}
					displayDate={this.state.value != null}
					resetCallback={this.resetDate}
					focusInputOnRender={false}
					helperText={
						title
					}
					isInput={true}
					showTimePicker={this.props.type === GenericElementType.datetime}
					number={this.props.index != null ? this.props.index : Math.random() * 100}
					toggleHeightOffset={16}
					activeIndex={this.props.activeIndex}
					activeIndexCallback={this.props.activeIndexCallback}
					autofillDate={this.props.type !== GenericElementType.datetime}
					inlineTodayButton={true}
				/>
			</StyledView>
		);
	}
}

export const GenericDateInput = withTheme(GenericDateInputClass);
