// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    GlExport,
} from '../models';

export interface GlExportGetRequest {
    gl_export_header_id: number;
    file_type: GlExportGetFileTypeEnum;
}

export interface GlExportsListRequest {
    limit: number;
    offset: number;
}

/**
 * no description
 */
export class GlApi extends BaseAPI implements IOperationIdMap {

    /**
     */
    glExportGet = (requestParameters: GlExportGetRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'gl_export_header_id', 'glExportGet');
        throwIfRequired(requestParameters, 'file_type', 'glExportGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.gl_export_header_id && { 'gl_export_header_id': requestParameters.gl_export_header_id }),
            ...(requestParameters.file_type && { 'file_type': requestParameters.file_type }),
        };

        return this.request<void>({
            path: '/gl/gl_export',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    glExportsList = (requestParameters: GlExportsListRequest): Observable<Array<GlExport>> => {
        throwIfRequired(requestParameters, 'limit', 'glExportsList');
        throwIfRequired(requestParameters, 'offset', 'glExportsList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.limit && { 'limit': requestParameters.limit }),
            ...(requestParameters.offset && { 'offset': requestParameters.offset }),
        };

        return this.request<Array<GlExport>>({
            path: '/gl/gl_exports',
            method: 'GET',
            headers,
            query,
        });
    };


    operationToOperationId = {
	    "glExportGet": "gl_export_get" ,
	    "glExportsList": "gl_exports_list"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum GlExportGetFileTypeEnum {
    B = 'B',
    T = 'T'
}
