import i18next from 'i18next';

export const translate = (
    key: string | undefined,
    fallback: string = '',
    params?: {}
): string => {
    const translationMissing = function(translated: string) {
        return translated === key; // when no translation was found, translated will still have the value of key
    };

    if (key == null) {
        return fallback;
    }
    const translated: string = i18next.t(key, params);
    if (translated == null || translated === '' || translationMissing(translated)) {
        return fallback;
    }
    return translated;
};
