
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import {  api, apis, ApiError, Ident, Account, VoidResponse } from '../../../../../logic/api';
import { Log, Logs } from '../../../../../logic/log';
import { coalesce, evaluateErrorMessage } from '../../../../../logic/helper/Common';
import { translate } from '../../../../../common/language/translate';
import { MessageHandler } from '../../../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../../../logic/handler/messagehandler/messageHandlerConfig';
import { ListEntry, NameField, SimpleHover } from '../basicStyledComponents/customerDetails.css';
import { CheckBox } from '../../../../../components/atomiccompoents/form/checkbox';

interface IProps {
    currentAccount: string;
	updateCallback?:() => void;
 };

export const PaymentMethodComponent: FunctionComponent<IProps> = (props: IProps) => {

    const [methods, setMethods]    = useState<Array<Account.PaymentMethod>>([]);

    function loadPaymentmethods() {
		const account_nr = props.currentAccount ;
 		const req: Account.MerchantPaymentMethodsGetRequest = {
			merchant_account_number: account_nr
		};
		api.asyncRequest<Array<Account.PaymentMethod>>(req, apis.MerchantApi, 'merchantPaymentMethodsGet')
				.then((response: Array<Account.PaymentMethod> ) => {
					const value = response.length === 0 ? [] : response;
                    setMethods(value);
				})
				.catch((error: ApiError) => {
					Log.debug(Logs.API, error);
				});
	}

    function addPaymentmethod(method: Account.PaymentMethod) {

 		const req: Account.MerchantPaymentMethodsPutRequest = {
			merchant_account_number: props.currentAccount,
			PaymentMethodPut: {
				payment_method: method
			}
		};
		api.asyncRequest<Array<Account.Product>>(req, apis.MerchantApi, 'merchantPaymentMethodsPut')
				.then(() => {
					loadPaymentmethods();
					if(props.updateCallback != null) {
						props.updateCallback();
					}
					MessageHandler.onSuccess(Reporter['person.merchant.put.paymentmethod']);
				})
				.catch((error: ApiError) => {
					MessageHandler.onError(Reporter['person.merchant.put.paymentmethod'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
				});
	}
	
	
	function deletePaymentMethod(method: Account.PaymentMethod) {
 		const req: Account.MerchantPaymentMethodsDeleteRequest = {
			merchant_account_number: props.currentAccount,
			payment_method: method,
		};
		api.asyncRequest<Array<Account.Product>>(req, apis.MerchantApi, 'merchantPaymentMethodsDelete')
				.then(() => {
					loadPaymentmethods();
					MessageHandler.onSuccess(Reporter['person.merchant.delete.paymentmethod']);
				})
				.catch((error: ApiError) => {
					MessageHandler.onError(Reporter['person.merchant.delete.paymentmethod'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
				});
	}

    function generatePaymentMethods(): Array<ReactElement> {
		const out = [];
		for (const o in Account.PaymentMethod) {
            //@ts-ignore
            const accEl =  Account.PaymentMethod[o];
			out.push(
				<ListEntry>
					<NameField style={{fontSize: '16x', margin: '8px', width: '20%'}}>{//@ts-ignore
									translate('customers.products.' + accEl), accEl } </NameField>			
						<SimpleHover style={{width: '15%'}}>
							<div style={{margin: 'auto', marginTop: '16px' , width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
								<CheckBox
									id= {"bockPpaymentMethod" + accEl} 
									value={inPaymentMethods(accEl)}
									onChange={(value) => {
										if(value ) {
											addPaymentmethod(accEl)
										} else {
											deletePaymentMethod(accEl)
										}
									}}/>
								</div>
						</SimpleHover>
				</ListEntry>
			)
		}	
        return out;
	}

    function inPaymentMethods(value: Account.PaymentMethod) {
		return methods != null && methods.length > 0 && methods.indexOf(value) > - 1
	}	

    
    useEffect(() => {
        loadPaymentmethods()
    }, [])

    return (
        <React.Fragment>
            {generatePaymentMethods()}
        </React.Fragment>
    );
}

