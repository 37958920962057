// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Attachment,
    Iso4217CurrencyOUT,
    PostingJournalRelatedTransactions,
} from './';

/**
 * @export
 * @interface ActivityStatements
 */
export interface ActivityStatements {
    /**
     * first part of transaction_/statement_identifier
     * @type {number}
     * @memberof ActivityStatements
     */
    ta_id: number;
    /**
     * second part of statement_identifier
     * @type {number}
     * @memberof ActivityStatements
     */
    statement_id: number;
    /**
     * second part of transaction_identifier
     * @type {number}
     * @memberof ActivityStatements
     */
    transaction_id: number;
    /**
     * the date part of booking timestamp, for legacy reasons
     * @type {Date}
     * @memberof ActivityStatements
     */
    booking_date: Date;
    /**
     * official booking timestamp
     * @type {Date}
     * @memberof ActivityStatements
     */
    booking_datetime: Date;
    /**
     * timestamp of technical booking
     * @type {Date}
     * @memberof ActivityStatements
     */
    sysdate: Date;
    /**
     * value date
     * @type {Date}
     * @memberof ActivityStatements
     */
    value_date: Date;
    /**
     * timestamp when the transaction actually happend
     * @type {Date}
     * @memberof ActivityStatements
     */
    transaction_datetime: Date;
    /**
     * @type {string}
     * @memberof ActivityStatements
     */
    subbalance?: string;
    /**
     * debit credit indicator
     * @type {string}
     * @memberof ActivityStatements
     */
    dc: ActivityStatementsDcEnum;
    /**
     * amount in currency
     * @type {number}
     * @memberof ActivityStatements
     */
    amount: number;
    /**
     * @type {Iso4217CurrencyOUT}
     * @memberof ActivityStatements
     */
    currency: Iso4217CurrencyOUT;
    /**
     * a user or system generated free text
     * @type {string}
     * @memberof ActivityStatements
     */
    statement_text: string;
    /**
     * transaction key to describe the kind of transaction
     * @type {number}
     * @memberof ActivityStatements
     */
    txkey_id: number;
    /**
     * the decoded txkey_id
     * @type {string}
     * @memberof ActivityStatements
     */
    txkey_description: string;
    /**
     * the balance for the account including the current statement
     * @type {number}
     * @memberof ActivityStatements
     */
    new_balance: number;
    /**
     * the location the transaction happened
     * @type {string}
     * @memberof ActivityStatements
     */
    location?: string;
    /**
     * if applicable the person name of the counterparty
     * @type {string}
     * @memberof ActivityStatements
     */
    participant_given_name?: string;
    /**
     * if applicable the person_id of the counterparty
     * @type {number}
     * @memberof ActivityStatements
     */
    participant_person_id?: number;
    /**
     * if applicable the person name of the counterparty
     * @type {string}
     * @memberof ActivityStatements
     */
    participant_name?: string;
    /**
     * if applicable the email address of the counterparty
     * @type {string}
     * @memberof ActivityStatements
     */
    participant_email_address?: string;
    /**
     * if applicable the account identification of the counterparty, depending on the information available it is usually an iban
     * @type {string}
     * @memberof ActivityStatements
     */
    participant_account?: string;
    /**
     * @type {string}
     * @memberof ActivityStatements
     */
    participant_sort_code?: string;
    /**
     * if applicable the phone number of the counterpartyin format e164
     * @type {string}
     * @memberof ActivityStatements
     */
    participant_phone_number?: string;
    /**
     * if set > * B - Balance * C - CreditCard * R - Reference Account
     * @type {string}
     * @memberof ActivityStatements
     */
    money_origination?: ActivityStatementsMoneyOriginationEnum;
    /**
     * @type {Array<Attachment>}
     * @memberof ActivityStatements
     */
    attachments?: Array<Attachment>;
    /**
     * external reference as provided by the initiaing application
     * @type {string}
     * @memberof ActivityStatements
     */
    external_reference?: string;
    /**
     * @type {string}
     * @memberof ActivityStatements
     */
    source?: string;
    /**
     * @type {number}
     * @memberof ActivityStatements
     */
    source_id?: number;
    /**
     * if set the source of the initial transaction, then init_ta_id and init_source id provide the link to the initial request
     * @type {string}
     * @memberof ActivityStatements
     */
    init_source?: ActivityStatementsInitSourceEnum;
    /**
     * @type {number}
     * @memberof ActivityStatements
     */
    init_ta_id?: number;
    /**
     * @type {number}
     * @memberof ActivityStatements
     */
    init_source_id?: number;
    /**
     * @type {Array<PostingJournalRelatedTransactions>}
     * @memberof ActivityStatements
     */
    related_transactions?: Array<PostingJournalRelatedTransactions>;
}

/**
 * @export
 * @enum {string}
 */
export enum ActivityStatementsDcEnum {
    D = 'D',
    C = 'C'
}
/**
 * @export
 * @enum {string}
 */
export enum ActivityStatementsMoneyOriginationEnum {
    B = 'B',
    C = 'C',
    R = 'R'
}
/**
 * @export
 * @enum {string}
 */
export enum ActivityStatementsInitSourceEnum {
    RequestMoney = 'request_money',
    ScheduledTransaction = 'scheduled_transaction'
}

