// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationId {
    AccountGet = 'account_get',
    AccountPost = 'account_post',
    AccountSearch = 'account_search',
    SystemAccountGet = 'system_account_get',
    AccountAccountNumberBalanceGet = 'account_account_number_balance_get',
    AccountAccountNumberActivityGet = 'account_account_number_activity_get',
    AccountAccountNumberActivityDetailGet = 'account_account_number_activity_detail_get',
    AccountDispoActivityGet = 'account_dispo_activity_get',
    AccountAccountNumberCardTransactionDetailGet = 'account_account_number_card_transaction_detail_get',
    AccountAccountNumberExternalDebitDetailGet = 'account_account_number_external_debit_detail_get',
    AccountAccountNumberDetailGet = 'account_account_number_detail_get',
    AccountAccountNumberLimitPut = 'account_account_number_limit_put',
    AccountAccountNumberLimitDelete = 'account_account_number_limit_delete',
    AccountFeesGet = 'account_fees_get',
    AccountAccountNumberFeesGet = 'account_account_number_fees_get',
    AccountAccountNumberFeesPut = 'account_account_number_fees_put',
    AccountAccountNumberFeesDelete = 'account_account_number_fees_delete',
    AccountAccountNumberBookableFees = 'account_account_number_bookable_fees',
    AccountAccountNumberMerchantNotificationGet = 'account_account_number_merchant_notification_get',
    AccountAccountNumberNamePut = 'account_account_number_name_put',
    AccountAccountNumberMemoPut = 'account_account_number_memo_put',
    AccountAccountNumberCustomNamePut = 'account_account_number_custom_name_put',
    AccountAccountNumberCustomNameDelete = 'account_account_number_custom_name_delete',
    AccountNotificationGet = 'account_notification_get',
    AccountNotificationPut = 'account_notification_put',
    AccountAccountNumberPersonGet = 'account_account_number_person_get',
    AccountAccountNumberValidCardProviderGet = 'account_account_number_valid_card_provider_get',
    AccountAccountNumberCardAgreementGet = 'account_account_number_card_agreement_get',
    AccountAccountNumberCardAgreementPost = 'account_account_number_card_agreement_post',
    AccountAccountNumberCardAgreementDelete = 'account_account_number_card_agreement_delete',
    AccountAccountNumberCardAgreementCardAgreementIdStatePut = 'account_account_number_card_agreement_card_agreement_id_state_put',
    AccountAccountNumberCardAgreementCardAgreementIdLimitPut = 'account_account_number_card_agreement_card_agreement_id_limit_put',
    AccountAccountNumberVirtualCardOrderingFeeGet = 'account_account_number_virtual_card_ordering_fee_get',
    AccountAccountNumberCardAgreementCardPost = 'account_account_number_card_agreement_card_post',
    AccountAccountNumberCardAgreementCardGet = 'account_account_number_card_agreement_card_get',
    AccountAccountNumberCardAgreementCardCardIdPinGet = 'account_account_number_card_agreement_card_card_id_pin_get',
    AccountAccountNumberCardAgreementCardCardIdPinPut = 'account_account_number_card_agreement_card_card_id_pin_put',
    AccountAccountNumberCardAgreementCardCardIdResetPinCounterPut = 'account_account_number_card_agreement_card_card_id_reset_pin_counter_put',
    AccountAccountNumberCardAgreementCardCardIdStateGet = 'account_account_number_card_agreement_card_card_id_state_get',
    AccountAccountNumberCardAgreementCardCardIdStatePut = 'account_account_number_card_agreement_card_card_id_state_put',
    AccountAccountNumberNfcGet = 'account_account_number_nfc_get',
    AccountAccountNumberNfcPost = 'account_account_number_nfc_post',
    AccountAccountNumberNfcDelete = 'account_account_number_nfc_delete',
    AccountAccountNumberCryptoOrderUniqueIdentifierPut = 'account_account_number_crypto_order_unique_identifier_put',
    AccountAccountNumberQrGet = 'account_account_number_qr_get',
    AccountAccountNumberQrPngGet = 'account_account_number_qr_png_get',
    AccountAccountNumberBlockPut = 'account_account_number_block_put',
    AccountAccountNumberBlockDelete = 'account_account_number_block_delete',
    AccountAccessKeyPost = 'account_access_key_post',
    AccountMobileTagPut = 'account_mobile_tag_put',
    AccountProductOrderList = 'account_product_order_list',
    AccountGiftPaymentGet = 'account_gift_payment_get',
    AccountGiftPaymentPut = 'account_gift_payment_put',
    AccountScheduledTransactionGet = 'account_scheduled_transaction_get',
    AccountScheduledTransactionPut = 'account_scheduled_transaction_put',
    AccountScheduledTransactionDelete = 'account_scheduled_transaction_delete',
    CompanionCompanionCodeCardPost = 'companion_companion_code_card_post',
    PersonGet = 'person_get',
    PersonPut = 'person_put',
    PersonConfigGet = 'person_config_get',
    PersonConfigPut = 'person_config_put',
    PersonNewpasswordPost = 'person_newpassword_post',
    PersonResetpasswordPost = 'person_resetpassword_post',
    PersonResetpasswordPut = 'person_resetpassword_put',
    PersonPinPut = 'person_pin_put',
    AccountsGet = 'accounts_get',
    PersonAccountsPersonIdGet = 'person_accounts_person_id_get',
    PersonAccountsPersonIdPost = 'person_accounts_person_id_post',
    PersonAccountsAccessKeyPost = 'person_accounts_access_key_post',
    PersonApnGet = 'person_apn_get',
    PersonApnDeviceIdGet = 'person_apn_device_id_get',
    PersonApnDeviceIdPost = 'person_apn_device_id_post',
    PersonApnDeviceIdDelete = 'person_apn_device_id_delete',
    PersonFcmGet = 'person_fcm_get',
    PersonFcmTokenPut = 'person_fcm_token_put',
    PersonFcmTokenDelete = 'person_fcm_token_delete',
    PersonEmailAddressGet = 'person_email_address_get',
    PersonEmailAddressPost = 'person_email_address_post',
    PersonEmailAddressEmailAddressIdPut = 'person_email_address_email_address_id_put',
    PersonEmailAddressEmailAddressIdDelete = 'person_email_address_email_address_id_delete',
    PersonPhoneNumberGet = 'person_phone_number_get',
    PersonPhoneNumberPost = 'person_phone_number_post',
    PersonPhoneNumberPhoneNumberIdPut = 'person_phone_number_phone_number_id_put',
    PersonPhoneNumberPhoneNumberIdDelete = 'person_phone_number_phone_number_id_delete',
    PersonExternalAccountValidCardBrandsGet = 'person_external_account_valid_card_brands_get',
    PersonExternalAccountGet = 'person_external_account_get',
    PersonExternalAccountPost = 'person_external_account_post',
    PersonExternalAccountPut = 'person_external_account_put',
    PersonExternalAccountDelete = 'person_external_account_delete',
    PersonExternalAccountAmountsGet = 'person_external_account_amounts_get',
    PersonLocationGet = 'person_location_get',
    PersonLocationCheckout = 'person_location_checkout',
    PersonLocationHistoryGet = 'person_location_history_get',
    PersonFingerprintPost = 'person_fingerprint_post',
    PersonAuthorizationGet = 'person_authorization_get',
    PersonPersonIdDelete = 'person_person_id_delete',
    PersonReferralLinkPut = 'person_referral_link_put',
    PersonReferralQrCodeGet = 'person_referral_qr_code_get',
    PersonPersonIdAccountTaskPost = 'person_person_id_account_task_post',
    PendingRequestsAccountNumberGet = 'pending_requests_account_number_get',
    PendingRequestsAccountNumberPut = 'pending_requests_account_number_put',
    WipePerson = 'wipe_person',
    TransactionGet = 'transaction_get',
    TransactionPost = 'transaction_post',
    AccountTypeGet = 'account_type_get',
    CountryGet = 'country_get',
    AtmLoginPost = 'atm_login_post',
    AtmPayoutGet = 'atm_payout_get',
    AtmPayoutPost = 'atm_payout_post',
    CardCardNumberCardVersionGet = 'card_card_number_card_version_get',
    CardCardNumberCardVersionPut = 'card_card_number_card_version_put',
    CardCardNumberCardVersionPost = 'card_card_number_card_version_post',
    CardCardNumberCardVersionDelete = 'card_card_number_card_version_delete',
    NfcUidGet = 'nfc_uid_get',
    NfcUidPut = 'nfc_uid_put',
    NfcUidPost = 'nfc_uid_post',
    NfcUidDelete = 'nfc_uid_delete',
    VoucherPost = 'voucher_post',
    VoucherVoucherNumberGet = 'voucher_voucher_number_get',
    VoucherVoucherNumberPost = 'voucher_voucher_number_post',
    LoaddtestSessionPost = 'loaddtest_session_post',
    ProductVoucherPost = 'product_voucher_post',
    ProductVoucherEmailAddressGet = 'product_voucher_email_address_get',
    ProductVoucherVoucherGroupGet = 'product_voucher_voucher_group_get',
    ProductVoucherVoucherGroupPost = 'product_voucher_voucher_group_post',
    ProductVoucherVoucherGroupClientPost = 'product_voucher_voucher_group_client_post',
    ProductVoucherVoucherGroupVoucherGroupGet = 'product_voucher_voucher_group_voucher_group_get',
    BusinessAccountsPost = 'business_accounts_post',
    BusinessAccountsBusinessAccountNumberTransactionsUniqueIdentifierPut = 'business_accounts_business_account_number_transactions_unique_identifier_put',
    MerchantPost = 'merchant_post',
    MerchantList = 'merchant_list',
    MerchantMerchantAccountIdCheckoutPost = 'merchant_merchant_account_id_checkout_post',
    MerchantCheckoutDelete = 'merchant_checkout_delete',
    MerchantInvoicePut = 'merchant_invoice_put',
    MerchantTransactionPut = 'merchant_transaction_put',
    MerchantAccountImageGet = 'merchant_account_image_get',
    MerchantNotificationPost = 'merchant_notification_post',
    MerchantNotificationDelete = 'merchant_notification_delete',
    MerchantMobileTagList = 'merchant_mobile_tag_list',
    MerchantMobileTagGet = 'merchant_mobile_tag_get',
    MerchantMobileTagPost = 'merchant_mobile_tag_post',
    MerchantMobileTagDelete = 'merchant_mobile_tag_delete',
    MerchantLocationGet = 'merchant_location_get',
    MerchantLocationCheckin = 'merchant_location_checkin',
    MerchantLocationCheckout = 'merchant_location_checkout',
    MerchantLocationdataPut = 'merchant_locationdata_put',
    MerchantLocationTestGet = 'merchant_location_test_get',
    MerchantProductOrderTestIdPut = 'merchant_product_order_test_id_put',
    MerchantLocationHistoryGet = 'merchant_location_history_get',
    MerchantProductOrderPaymentPost = 'merchant_product_order_payment_post',
    MerchantFinishPayment = 'merchant_finish_payment',
    MerchantOrderProduct = 'merchant_order_product',
    MerchantProductDiagnosesGet = 'merchant_product_diagnoses_get',
    MerchantProductOrderIdCheckPut = 'merchant_product_order_id_check_put',
    MerchantAbortTestPost = 'merchant_abort_test_post',
    MerchantMerchantAccountIdSubscriptionPost = 'merchant_merchant_account_id_subscription_post',
    MerchantMerchantAccountIdSubscriptionDeletePost = 'merchant_merchant_account_id_subscription_delete_post',
    MerchantMerchantAccountIdUpdateGet = 'merchant_merchant_account_id_update_get',
    MerchantMerchantAccountIdUpdateDelete = 'merchant_merchant_account_id_update_delete',
    MerchantAntibodyTestResultGet = 'merchant_antibody_test_result_get',
    MerchantProductsList = 'merchant_products_list',
    MerchantProductsListBo = 'merchant_products_list_bo',
    MerchantProductPut = 'merchant_product_put',
    MerchantProductDelete = 'merchant_product_delete',
    MerchantProductOrderList = 'merchant_product_order_list',
    MerchantVoucherValidate = 'merchant_voucher_validate',
    MerchantCalendarGet = 'merchant_calendar_get',
    MerchantTestCenterOpenCalendarGet = 'merchant_test_center_open_calendar_get',
    MerchantPersonIdCalendarGet = 'merchant_person_id_calendar_get',
    MerchantTestCenterOpenPersonIdCalendarGet = 'merchant_test_center_open_person_id_calendar_get',
    MerchantReportHistoryList = 'merchant_report_history_list',
    MerchantPersonIdCalendarPut = 'merchant_person_id_calendar_put',
    MerchantTestCenterOpenPersonIdCalendarPut = 'merchant_test_center_open_person_id_calendar_put',
    MerchantPersonIdProductCalendarGet = 'merchant_person_id_product_calendar_get',
    MerchantPersonIdProductCalendarPut = 'merchant_person_id_product_calendar_put',
    MerchantPersonIdCreateFiskalyPost = 'merchant_person_id_create_fiskaly_post',
    MerchantPersonIdFiskalyDataGet = 'merchant_person_id_fiskaly_data_get',
    MerchantCalendarPut = 'merchant_calendar_put',
    MerchantTestCenterOpenCalendarPut = 'merchant_test_center_open_calendar_put',
    MerchantCalendarDelete = 'merchant_calendar_delete',
    MerchantTestCenterOpenCalendarDelete = 'merchant_test_center_open_calendar_delete',
    MerchantProductTypeGet = 'merchant_product_type_get',
    MerchantProductTypePost = 'merchant_product_type_post',
    MerchantProductTypePut = 'merchant_product_type_put',
    MerchantProductConfigGet = 'merchant_product_config_get',
    MerchantProductTypeDelete = 'merchant_product_type_delete',
    MerchantAvailableProductTypeGet = 'merchant_available_product_type_get',
    MerchantPaymentMethodsDelete = 'merchant_payment_methods_delete',
    MerchantPaymentMethodsPut = 'merchant_payment_methods_put',
    MerchantPaymentMethodsGet = 'merchant_payment_methods_get',
    MerchantAntibodyTestresultGet = 'merchant_antibody_testresult_get',
    CryptoTransactionUniqueIdentifierPut = 'crypto_transaction_unique_identifier_put',
    PersonLogin = 'person_login',
    PaymentsSepaCreditTransferPost = 'payments_sepa_credit_transfer_post',
    PaymentsInternalCreditTransferPost = 'payments_internal_credit_transfer_post',
    PaymentsInternalConversionPost = 'payments_internal_conversion_post',
    PaymentsCustomerCreditTransferPost = 'payments_customer_credit_transfer_post',
    PaymentsScheduledTransactionPost = 'payments_scheduled_transaction_post',
    PaymentsReimbursementPost = 'payments_reimbursement_post',
    PaymentsTanPost = 'payments_tan_post',
    PaymentsTanPut = 'payments_tan_put',
    PaymentsReversalPost = 'payments_reversal_post',
    PaymentsRandomCardTakeoverPost = 'payments_random_card_takeover_post',
    PaymentsRequestMoneyGet = 'payments_request_money_get',
    PaymentsRequestMoneyPost = 'payments_request_money_post',
    PaymentsRequestMoneyDelete = 'payments_request_money_delete',
    PaymentsRequestMoneyBoGet = 'payments_request_money_bo_get',
    PaymentsSplitTransaction = 'payments_split_transaction',
    PaymentsTakeoverPost = 'payments_takeover_post',
    PaymentsTakeOverDebitPost = 'payments_take_over_debit_post',
    PaymentsCardTakeoverPost = 'payments_card_takeover_post',
    PaymentsCardTakeoverGet = 'payments_card_takeover_get',
    PaymentsCardTakeoverPut = 'payments_card_takeover_put',
    PaymentsCardTakeoverDelete = 'payments_card_takeover_delete',
    PaymentsDebitExternalAccountPost = 'payments_debit_external_account_post',
    PaymentsClassifyPost = 'payments_classify_post',
    PaymentsOrderProduct = 'payments_order_product',
    PaymentsOrderProductStorno = 'payments_order_product_storno',
    PaymentProductOrderReceivedConfirm = 'payment_product_order_received_confirm',
    PaymentsOrderProductFlightPut = 'payments_order_product_flight_put',
    PaymentsRequestInvoicePost = 'payments_request_invoice_post',
    PaymentsPaypalResponse = 'payments_paypal_response',
    PaymentsManualDispoPost = 'payments_manual_dispo_post',
    PaymentsCwaGet = 'payments_cwa_get',
    PaymentsCwaLinkPut = 'payments_cwa_link_put',
    PaymentsCwaQrPut = 'payments_cwa_qr_put',
    PaymentsTestResultDocumentPost = 'payments_test_result_document_post',
    ProductOrderProductTypePost = 'product_order_product_type_post',
    ProductOrderProductTypeGet = 'product_order_product_type_get',
    ProductOrderGet = 'product_order_get',
    ProductsGet = 'products_get',
    ProductsBoGet = 'products_bo_get',
    ProductsPut = 'products_put',
    ProductsPassportPut = 'products_passport_put',
    ProductsLanguagesPut = 'products_languages_put',
    ProductImagesList = 'product_images_list',
    ProductImageGet = 'product_image_get',
    ProductImagePost = 'product_image_post',
    ProductTestReasonsGet = 'product_test_reasons_get',
    ProductMethodsTakenGet = 'product_methods_taken_get',
    ProductMaintenanceProductCategories = 'product_maintenance_product_categories',
    ProductMaintenanceProductCategoriesPost = 'product_maintenance_product_categories_post',
    ProductMaintenanceProductCategoriesDelete = 'product_maintenance_product_categories_delete',
    ProductMaintenanceProductTypeOfTestList = 'product_maintenance_product_type_of_test_list',
    ProductMaintenanceProductCategoryImage = 'product_maintenance_product_category_image',
    Rpt001BalanceList = 'rpt001_balance_list',
    Rpt002AccountList = 'rpt002_account_list',
    Rpt003BalanceHistory = 'rpt003_balance_history',
    Rpt004005AccountOpeningListNdpay = 'rpt004_005_account_opening_list_ndpay',
    Rpt004005AccountOpeningListOnefor = 'rpt004_005_account_opening_list_onefor',
    Rpt006ExternalAccounts = 'rpt006_external_accounts',
    Rpt007DebitCardApplications = 'rpt007_debit_card_applications',
    Rpt009CustomerBalanceList = 'rpt009_customer_balance_list',
    Rpt010CustomerTransactionList = 'rpt010_customer_transaction_list',
    Rpt011MerchantList = 'rpt011_merchant_list',
    Rpt012RegisteredCustomerList = 'rpt012_registered_customer_list',
    Rpt013CheckinCheckoutList = 'rpt013_checkin_checkout_list',
    Rpt014CheckoutList = 'rpt014_checkout_list',
    Rpt015AccountUpgradeList = 'rpt015_account_upgrade_list',
    Rpt016SystemTransactionList = 'rpt016_system_transaction_list',
    MerchantExpectedCustomers = 'merchant_expected_customers',
    Rpt018CustomerOverviewList = 'rpt018_customer_overview_list',
    Rpt019CustomerOverviewPositiveList = 'rpt019_customer_overview_positive_list',
    Rpt021ProductOrderStornoList = 'rpt021_product_order_storno_list',
    Rpt022SegmentTransactionSumsList = 'rpt022_segment_transaction_sums_list',
    Rpt023MastercardClearingList = 'rpt023_mastercard_clearing_list',
    Rpt026InternalAccountList = 'rpt026_internal_account_list',
    Rpt027IssuedCardList = 'rpt027_issued_card_list',
    Rpt030AccountFeePackageList = 'rpt030_account_fee_package_list',
    Rpt031AccountOpeningList = 'rpt031_account_opening_list',
    Rpt032CardAuthorizationList = 'rpt032_card_authorization_list',
    Rpt033FeePackageList = 'rpt033_fee_package_list',
    Rpt034MerchantDailyMonthlyList = 'rpt034_merchant_daily_monthly_list',
    Rpt035BusinessAccountDetails = 'rpt035_business_account_details',
    PersonBeneficiaryGet = 'person_beneficiary_get',
    PersonBeneficiaryPost = 'person_beneficiary_post',
    PersonBeneficiaryPut = 'person_beneficiary_put',
    PersonBeneficiaryDelete = 'person_beneficiary_delete',
    PersonFavoriteGet = 'person_favorite_get',
    PersonFavoritePost = 'person_favorite_post',
    PersonFavoritePut = 'person_favorite_put',
    PersonFavoriteDelete = 'person_favorite_delete',
    PersonEmailGet = 'person_email_get',
    ExternalLaboratoryDocumentGet = 'external_laboratory_document_get',
    NotificationsGet = 'notifications_get',
    NotificationsPut = 'notifications_put',
    NotificationsDelete = 'notifications_delete',
    ReleasesGet = 'releases_get',
    ReleasesPut = 'releases_put',
    ReleasesDetailsGet = 'releases_details_get',
    TransactionTemplatesGet = 'transaction_templates_get',
    ReportTemplatesGet = 'report_templates_get',
    TransactionTemplatesAdminGet = 'transaction_templates_admin_get',
    TransactionTemplatesBoGet = 'transaction_templates_bo_get',
    TransactionTemplatesCustomerGet = 'transaction_templates_customer_get',
    ReportTemplatesAdminGet = 'report_templates_admin_get',
    ReportTemplatesBoGet = 'report_templates_bo_get',
    ReportTemplatesCustomerGet = 'report_templates_customer_get',
    AttachmentSourceIdsGet = 'attachment_source_ids_get',
    EterminTimeslotsGet = 'etermin_timeslots_get',
    EterminAppointmentGet = 'etermin_appointment_get',
    MoorwandReportsCardholderBalanceReportFilePost = 'moorwand_reports_cardholder_balance_report_file_post',
    MoorwandReportsCardholderBalanceReportFilesList = 'moorwand_reports_cardholder_balance_report_files_list',
    MoorwandReportsCardholderBalanceReportFileGet = 'moorwand_reports_cardholder_balance_report_file_get',
    BpcClearingFilePost = 'bpc_clearing_file_post',
    BpcClearingFilesList = 'bpc_clearing_files_list',
    BpcCardStatusFilePost = 'bpc_card_status_file_post',
    BpcCardStatusFilesList = 'bpc_card_status_files_list',
    BpcDbalFilesList = 'bpc_dbal_files_list',
    BpcDbalFileGet = 'bpc_dbal_file_get',
    GlExportsList = 'gl_exports_list',
    GlExportGet = 'gl_export_get',
    BookingOverviewGet = 'booking_overview_get',
    ConfigAppGet = 'config_app_get',
    AdyenPaymentPost = 'adyen_payment_post',
    AdyenPaymentFinishedPost = 'adyen_payment_finished_post',
    AdyenPaymentNotificationPost = 'adyen_payment_notification_post',
    Rpt024TestcenterStatsGet = 'rpt024_testcenter_stats_get',
    Rpt025BookingStatsGet = 'rpt025_booking_stats_get',
    TransactionDocumentPost = 'transaction_document_post',
    TransactionDocumentPresignedGet = 'transaction_document_presigned_get',
    ScheduledTransactionDocumentPost = 'scheduled_transaction_document_post',
    ConfigurationAutomaticFeeAssignmentGet = 'configuration_automatic_fee_assignment_get',
    ConfigurationAutomaticFeeAssignmentPut = 'configuration_automatic_fee_assignment_put',
    ConfigurationAutomaticFeeAssignmentDelete = 'configuration_automatic_fee_assignment_delete',
    ConfigurationFeePackagesGet = 'configuration_fee_packages_get',
    ClientstatsRegistrationsGet = 'clientstats_registrations_get',
    MoorwandRetryTransfer = 'moorwand_retry_transfer',
    MoorwandResendIbanRequest = 'moorwand_resend_iban_request',
    MoorwandRetryBooking = 'moorwand_retry_booking',
    MoorwandReturnTransfer = 'moorwand_return_transfer',
    MonitoringThirdPartyList = 'monitoring_third_party_list',
    MoorwandIbanMarkAsDone = 'moorwand_iban_mark_as_done',
    MoorwandIbanInboundMarkAsDone = 'moorwand_iban_inbound_mark_as_done',
    MoorwandOutboundMarkAsDone = 'moorwand_outbound_mark_as_done',
    EyesonRoomsPost = 'eyeson_rooms_post',
    EyesonRoomsMerchantPost = 'eyeson_rooms_merchant_post',
    EyesonRoomsGet = 'eyeson_rooms_get',
    EyesonRoomsPut = 'eyeson_rooms_put',
    EyesonGuestPost = 'eyeson_guest_post',
    EyesonGuestRoomGet = 'eyeson_guest_room_get',
    EyesonRecordingsList = 'eyeson_recordings_list',
    EyesonRecordingsPut = 'eyeson_recordings_put',
    EyesonRecordingsDelete = 'eyeson_recordings_delete',
    EyesonRecordingGet = 'eyeson_recording_get',
    EyesonRecordingPut = 'eyeson_recording_put',
    TellMoneyConsentGet = 'tell_money_consent_get',
    TellMoneyConsentPost = 'tell_money_consent_post',
    TellMoneyConsentsGet = 'tell_money_consents_get',
    TellMoneyConsentDelete = 'tell_money_consent_delete'
}

