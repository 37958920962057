export const Constants = {
	CLEAR_ALL: 'CLEAR_ALL',
	HEADER_CHANGED: 'HEADER_CHANGED',
	USER_CHANGED: 'USER_CHANGED',
	MENU_CHANGED: 'MENU:CHANGED',
	ACCOUNTS_CHANGED: 'ACCOUNTS_CHANGED',
	CURRENT_ACCOUNT_CHANGED: 'CURRENT_ACCOUNT_CHANGED',
	TRANSACTIONS_CHANGED: 'TRANSACTIONS_CHANGED',
	CUSTOMER_CHANGED: 'CUSTOMER_CHANGED',
	CUSTOMER_ACCOUNTS_CHANGED: 'CUSTOMER_ACCOUNTS_CHANGED',
	CUSTOMER_DATA_CHANGED: 'CUSTOMER_DATA_CHANGED',
	BALANCE_CHANGED: 'BALANCE_CHANGED',
	RESET_CUSTOMER_DATA: 'RESET_CUSTOMER_DATA',
	DELETE_HISTORY: 'DELETE_HISTORY',
	ADD_HISTORY: 'ADD_HISTORY',
	BUBBLE_ADD: 'BUBBLE_ADD',
	ACTIVE_INDEX_CHANGED: 'ACTIVE_INDEX_CHANGED',
	API_WORKFLOW: {
		DATA_GET: 'DATA_GET',
		DATA_CHANGED: 'DATA_CHANGED',
	},
	PERMISSIONS_CHANGED: 'PERMISSIONS_CHANGED',
	USERIMAGE_CHANGED: 'USERIMAGE_CHANGED',
	BACKOFFICE_CHANGED: 'BACKOFFICE_CHANGED',
	RESET_BACKOFFICE_DATA: 'RESET_BACKOFFICE_DATA',
	TEAMMEMBER_ADDED: 'TEAMMEMBER_ADDED',
	TEAMMEMBER_CHANGED: 'TEAMMEMBER_CHANGED',
	UPDATE_LAST_ACTIVITY: 'UPDATE_LAST_ACTIVITY',
	LOGOUT_TIME: 'LOGOUT_TIME',
	ABS_LOGOUT_TIME: 'ABS_LOGOUT_TIME',
	THEME_CHANGED: 'THEME_CHANGED',
	ADD_NOTIFICATION: 'ADD_NOTIFICATION',
	DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
	READ_NOTIFICATION: 'READ_NOTIFICATION',
	UNREAD_NOTIFICATION: 'UNREAD_NOTIFICATION',
	SET_NOTIFICATION: 'SET_NOTIFICATION',
	DISABLE_DEVELOPER: 'DISABLE_DEVELOPER',
	ENABLE_DEVELOPER: 'ENABLE_DEVELOPER',
	DEVICE_TOKEN: 'DEVICE_TOKEN',
	DEFAULT_BACKOFFICE_ACCOUNT: 'DEFAULT_BACKOFFICE_ACCOUNT',
	DEFAULT_CUSTOMER: 'DEFAULT_CUSTOMER',
	DEVELOPER_LOGIN: 'DEVELOPER_LOGIN',
	DEFAULT_CUSTOMER_PAGE: 'DEFAULT_CUSTOMER_PAGE',
	PAGES: 'PAGES',
	BLOCK: 'BLOCK',
	NOTIFICATION_SET: 'NOTIFICATION_SET',
	CONFIG_SET_SPECIFIC_KEY: 'CONFIG_SET_SINGLE_KEY',
	CONFIG_SET: 'CONFIG_SET',
	UNDO_CUSTOMER: 'UNDO_CUSTOMER',
	UNDO_SET_LAST_USER: 'UNDO_SET_LAST_USER',
	SET_ROLES: 'SET_ROLES',
};

export const CHANGE_EVENT = 'CHANGE';
export const CHANGE_TRANSACTIONS = 'CHANGE_TRANSACTIONS';
