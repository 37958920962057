// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CardAuthorizationListParams
 */
export interface CardAuthorizationListParams {
    /**
     * @type {string}
     * @memberof CardAuthorizationListParams
     */
    datetime_create: string;
    /**
     * @type {string}
     * @memberof CardAuthorizationListParams
     */
    date_from: string;
    /**
     * @type {string}
     * @memberof CardAuthorizationListParams
     */
    date_to: string;
    /**
     * @type {number}
     * @memberof CardAuthorizationListParams
     */
    amount?: number;
    /**
     * @type {string}
     * @memberof CardAuthorizationListParams
     */
    region?: CardAuthorizationListParamsRegionEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CardAuthorizationListParamsRegionEnum {
    Uk = 'uk',
    ChEea = 'ch-eea'
}

