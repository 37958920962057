// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CountryCodeAlpha2,
    Iso4217CurrencyOUT,
    Sex,
} from './';

/**
 * @export
 * @interface ExpectedCustomer
 */
export interface ExpectedCustomer {
    /**
     * timestamp the appointment starts
     * @type {string}
     * @memberof ExpectedCustomer
     */
    appointment?: string;
    /**
     * @type {Date}
     * @memberof ExpectedCustomer
     */
    appointment_timestamp?: Date;
    /**
     * timestamp the order was booked
     * @type {string}
     * @memberof ExpectedCustomer
     */
    sys_date?: string;
    /**
     * @type {Date}
     * @memberof ExpectedCustomer
     */
    sys_date_timestamp?: Date;
    /**
     * @type {number}
     * @memberof ExpectedCustomer
     */
    ta_id?: number;
    /**
     * @type {number}
     * @memberof ExpectedCustomer
     */
    transaction_id?: number;
    /**
     * first name of the customer
     * @type {string}
     * @memberof ExpectedCustomer
     */
    given_name?: string;
    /**
     * last name of the customer
     * @type {string}
     * @memberof ExpectedCustomer
     */
    name?: string;
    /**
     * birth date of the customer
     * @type {string}
     * @memberof ExpectedCustomer
     */
    birth_date?: string;
    /**
     * @type {Sex}
     * @memberof ExpectedCustomer
     */
    sex?: Sex;
    /**
     * address of the customer
     * @type {string}
     * @memberof ExpectedCustomer
     */
    address_line_1?: string;
    /**
     * address of the customer
     * @type {string}
     * @memberof ExpectedCustomer
     */
    post_code?: string;
    /**
     * address of the customer
     * @type {string}
     * @memberof ExpectedCustomer
     */
    city?: string;
    /**
     * @type {CountryCodeAlpha2}
     * @memberof ExpectedCustomer
     */
    country?: CountryCodeAlpha2;
    /**
     * @type {string}
     * @memberof ExpectedCustomer
     */
    email_address?: string;
    /**
     * @type {string}
     * @memberof ExpectedCustomer
     */
    phone_number?: string;
    /**
     * @type {string}
     * @memberof ExpectedCustomer
     */
    passport_number?: string;
    /**
     * @type {CountryCodeAlpha2}
     * @memberof ExpectedCustomer
     */
    nationality?: CountryCodeAlpha2;
    /**
     * name of the ordered test
     * @type {string}
     * @memberof ExpectedCustomer
     */
    product?: string;
    /**
     * price of the ordered product
     * @type {number}
     * @memberof ExpectedCustomer
     */
    price?: number;
    /**
     * @type {Iso4217CurrencyOUT}
     * @memberof ExpectedCustomer
     */
    currency?: Iso4217CurrencyOUT;
    /**
     * flag for peoduct payed or not
     * @type {string}
     * @memberof ExpectedCustomer
     */
    payment_received?: ExpectedCustomerPaymentReceivedEnum;
    /**
     * timestamp the sample was received
     * @type {string}
     * @memberof ExpectedCustomer
     */
    sample_received?: string;
    /**
     * timestamp the result was eneterd
     * @type {string}
     * @memberof ExpectedCustomer
     */
    result_enetered?: string;
    /**
     * @type {string}
     * @memberof ExpectedCustomer
     */
    test_result?: ExpectedCustomerTestResultEnum;
    /**
     * name of the person executing the test
     * @type {string}
     * @memberof ExpectedCustomer
     */
    testing_doctor?: string;
    /**
     * result of the test
     * @type {string}
     * @memberof ExpectedCustomer
     */
    barcode?: string;
    /**
     * @type {string}
     * @memberof ExpectedCustomer
     */
    is_reversed?: ExpectedCustomerIsReversedEnum;
    /**
     * @type {string}
     * @memberof ExpectedCustomer
     */
    test_reason?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ExpectedCustomerPaymentReceivedEnum {
    Sumup = 'sumup',
    Cash = 'cash',
    Paypal = 'paypal',
    Stripe = 'stripe',
    NotFinished = 'not finished'
}
/**
 * @export
 * @enum {string}
 */
export enum ExpectedCustomerTestResultEnum {
    Positive = 'positive',
    Negative = 'negative',
    Void = 'void',
    NoResult = 'no_result'
}
/**
 * @export
 * @enum {string}
 */
export enum ExpectedCustomerIsReversedEnum {
    Y = 'Y',
    N = 'N'
}

