// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CardAgreementCard
 */
export interface CardAgreementCard {
    /**
     * @type {number}
     * @memberof CardAgreementCard
     */
    card_id: number;
    /**
     * @type {string}
     * @memberof CardAgreementCard
     */
    card_number?: string;
    /**
     * @type {string}
     * @memberof CardAgreementCard
     */
    card_number_masked?: string;
    /**
     * the expiry date in the format MM/YY 
     * @type {string}
     * @memberof CardAgreementCard
     */
    expiry: string;
    /**
     * card holders name as printed on the card
     * @type {string}
     * @memberof CardAgreementCard
     */
    name?: string;
    /**
     * * O - Ordered * A - Active * B - Blocked * P - Pending * D - Deleted
     * @type {string}
     * @memberof CardAgreementCard
     */
    state: CardAgreementCardStateEnum;
    /**
     * The card color as text
     * @type {string}
     * @memberof CardAgreementCard
     */
    card_color?: string;
    /**
     * Tagline on card
     * @type {string}
     * @memberof CardAgreementCard
     */
    tagline?: string;
    /**
     * @type {string}
     * @memberof CardAgreementCard
     */
    virtual_or_physical: CardAgreementCardVirtualOrPhysicalEnum;
    /**
     * @type {string}
     * @memberof CardAgreementCard
     */
    bar_code?: string;
    /**
     * @type {string}
     * @memberof CardAgreementCard
     */
    bpc_card_id?: string;
    /**
     * date and time of card order
     * @type {Date}
     * @memberof CardAgreementCard
     */
    create_datetime?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum CardAgreementCardStateEnum {
    O = 'O',
    A = 'A',
    D = 'D',
    P = 'P',
    B = 'B'
}
/**
 * @export
 * @enum {string}
 */
export enum CardAgreementCardVirtualOrPhysicalEnum {
    V = 'V',
    P = 'P'
}

