//@ts-nocheck
import React, { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import Parser, { AnyMapPar, IParsedContent } from './parser';
import { IGenericElement, GenericElementType } from './IGenericElement';
import { genericElementUtils } from './genericElementUtils';
import {
	IStatusState,
	Reporter,
	IMessageConfig,
} from '../../logic/handler/messagehandler/messageHandlerConfig';
import { IItfAccount } from '../../logic/types';
import ButtonOk from '../../components/atomiccompoents/buttons/buttonOk';
import ButtonCancel from '../../components/atomiccompoents/buttons/buttonCancel';
import { translate } from '../../common/language/translate';
import { api, apis, ApiError, Ident, Account } from '../../logic/api';
import { MessageHandler } from '../../logic/handler/messagehandler/messageHandler';
import { theme } from '../../common/theme';
import { BackofficeStore, CustomerDataStore, Actions } from '../../logic/flux';
import { OverlayHandler, Overlays } from '../../logic/handler/overlayhandler/overlayHandler';
import { Accounts } from '../../logic/accounts';
import { Box } from '../../components/atomiccompoents/boxes/box';
import { FlexBox } from '../auth/auth.css';
import i18next from 'i18next';
import {
	Table,
	CustomRow,
	CustomCell,
	TitleCell,
} from '../dashboard/content/customers/basicStyledComponents/customerDetails.css';
import { Icons } from '../../images';
import TouchableOpacity from '../../components/atomiccompoents/buttons/touchableOpacity';
import { FileType } from '../../components/compositcomponents/popup/fileTypeChooserOverlay';
import Title from '../../components/compositcomponents/title';
import ActivityIndicator from '../../components/atomiccompoents/activityIndicator';
import { Log, Logs } from '../../logic/log';
import { compareDate, downloadFile, evaluateErrorMessage } from '../../logic/helper/Common';
import { isBuffer } from 'util';
import { format } from '../../logic/helper/format';
import { request } from 'http';
import { object } from 'prop-types';
import { indexOfDefault } from '../auth/authConfig';

export enum GenericTemplateType {
	posting_template = 'posting_template',
	report_template = 'report_template',
}

export enum TemplateCategory {
	admin = 'admin',
	customer = 'customer',
	backoffice = 'backoffice',
}

interface ITemplateTypes {
	account: Array<Account.TransactionTemplate>,
	ident: Array<Ident.TransactionTemplate>
}

interface IProps {
	templateType: GenericTemplateType;
	templateCategory: TemplateCategory;
	heading?: string;
	subHeading?: string;
	notification?: (
		showReport: boolean,
		response: any,
		selectedPostingTemplate: Account.TransactionTemplate
	) => void;
	isReport?: boolean;
	isBackOffice?: boolean;
	resetCallback?: () => void;
}

interface IState extends IStatusState {
	templatesData: ITemplateTypes;
	selectedPostingTemplate: Account.TransactionTemplate | Ident.TransactionTemplate;
	selectedIsIdent?: boolean;
	dynFieldIndex?: {[key: string]: string};
	showTemplate: boolean;
	formIsValid?: boolean;
	parsedContent: IParsedContent | null;
	errors?: Array<boolean>;
	formInputs: { [index: string]: any };
	dynFormInputs: { [index: string]: any };
	delFormInputs: { [index: string]: any };
	showOptionalFields?: boolean;
	currentAccount?: IItfAccount;
	selectedUser?: Ident.Person;
	keyUsedForRerender: number;
	keyForLoadingSpinner?: number;
	lang: string;
	elem: any | null;
	clicked?: boolean;
	heightOfBox?: number;
	showReportDownload?: boolean;
	selectedLabel?: string;
	downloadLoading?: boolean;
}

const FileChooserStyle: React.CSSProperties = {
	paddingLeft: '20px',
	borderRadius: '50%',
	height: '40px',
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
};

const HalfBox = styled.div<{ heightOfBox?: number }>`
	width: 48%;
	max-height: calc(
		100vh - ${(props) => (props.heightOfBox != null ? props.heightOfBox + 'px' : '430px')}
	);
	overflow: auto;
`;
const StyledTextSubHeading = styled.div`
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: ${(props) => props.theme.Global.darkFontColor};
	margin-top: 8px;
`;

const StyledButtonView = styled.div`
	display: flex;
	flex-direction: row-reverse;

	button {
		margin-right: 10px;
	}
`;

const Helpertext = styled.div`
	font-size: 10px;
	color: ${(props) => props.theme.Button.backgroundColor};
	width: 100%;
	text-align: left;
`;

const StyledHr = styled.div`
	border-bottom: 1px solid #e8ecef;
	width: 100%;
`;

const StyledViewFields = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	& > div {
		@media (min-width: 1240px) {
			width: 100%;
			margin-left: 12px;
			margin-right: 12px;
		}

		@media (max-width: 1240px) {
			width: 100%;
		}
	}
`;
const HoverCell = styled(CustomCell)`
	display: flex;
	flex-direction: column;
	width: 100%;
	:hover {
		cursor: pointer;
		opacity: 0.6;
	}
`;
const ViewBox = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const StyledBox = styled(Box)`
	width: 100%;
	margin-bottom: 4px;
	box-shadow: 4px 0px 4px -4px rgba(0,0,0,0.25);
`;

const StyledViewOptionalFields = styled(StyledViewFields)`
	margin-bottom: 50px;
`;

const StyledHrOptionalFields = styled.hr`
	border: 2px solid #ccd5db;
	width: 100%;
	margin-top: 20px;
`;

const StyledTextToggleOptionals = styled.div`
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: ${(props) => props.theme.Global.lightFontColor};
	margin-bottom: 48px;
	text-transform: uppercase;
`;

export default class GenericTemplateComponent extends React.Component<IProps, IState> {
	private TEMPLATE_GROUP = 'Sepa Zahlungsverkehr';
	private SELECT_FORM_ID = 'selectedPostingTemplate';
	private FIELD_ID = 'PostingTemplateField';
	parser = new Parser();
	private ButtonStyle = {
		marginLeft: '16px',
		width: theme.Button.width,
		padding: '0px',
	};
	boxRef = React.createRef<HTMLDivElement>();
	private requiredElements = 0;
	private totalFields = 0;
	private activeIndex = -1;
	private defaultSelectOption: Account.TransactionTemplate = {
		label:
			this.props.templateType === GenericTemplateType.posting_template
				? translate('generic.selectPostingTemplate')
				: this.props.templateType === GenericTemplateType.report_template
				? translate('generic.selectReportTemplate')
				: '',
		operationid: '',
		template_group: '',
		short_info: '',
	};

	constructor(props: IProps) {
		super(props);
		this.state = {
			templatesData: {account: [], ident: []},
			selectedPostingTemplate: this.defaultSelectOption,
			showTemplate: false,
			parsedContent: null,
			formInputs: {}, // TODO: type
			dynFormInputs: {},
			delFormInputs: {},
			keyUsedForRerender: 1000,
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			lang: i18next.language,
			elem: null,
			clicked: false,
			dynFieldIndex: {},
			showReportDownload: this.props.isReport,
		};
		this.onSelect = this.onSelect.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.notification = this.notification.bind(this);
		this.toggleOptionalFields = this.toggleOptionalFields.bind(this);
		this.isCustomerScreen = this.isCustomerScreen.bind(this);
		this.isBackofficeScreen = this.isBackofficeScreen.bind(this);
		this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
		this._onChangeBackofficeData = this._onChangeBackofficeData.bind(this);
		this.triggerRerenderByIncreasingKey = this.triggerRerenderByIncreasingKey.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.setActiveIndex = this.setActiveIndex.bind(this);
	}

	private triggerRerenderByIncreasingKey(): void {
		const newKey = this.state.keyUsedForRerender + 1;
		this.setState({
			keyUsedForRerender: newKey,
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
		});
	}

	private isCustomerScreen(): boolean {
		return window.location.pathname.includes('customers');
	}

	private isBackofficeScreen(): boolean {
		return window.location.pathname.includes('backoffice');
	}

	_onChangeBackofficeData() {
		if (this.isBackofficeScreen()) {
			this.setState({
				currentAccount: BackofficeStore.getAccount(),
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			});
		}
	}

	_onChangeCustomerData() {
		if (this.isCustomerScreen()) {
			this.setState({
				currentAccount: CustomerDataStore.getCurrentAccount(),
				selectedUser: CustomerDataStore.getUser(),
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			});
		}
	}

	componentWillReceiveProps(props: IProps) {
		this.setState({
			showReportDownload: props.isReport,
		});
	}
	componentWillUnmount() {
		CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
		BackofficeStore.removeChangeListener(this._onChangeBackofficeData);
	}

	componentDidMount() {
		CustomerDataStore.addChangeListener(this._onChangeCustomerData);
		this._onChangeCustomerData();
		BackofficeStore.addChangeListener(this._onChangeBackofficeData);
		this._onChangeBackofficeData();
		if (this.boxRef.current != null) {
			const box = this.boxRef.current.getBoundingClientRect();
			this.setState({
				heightOfBox: box.top + 205,
			});
		}
		const requestParams = {
			language: i18next.languages[0],
		};
		let requestString = '';

		if (this.props.templateCategory != null) {
			if (this.props.templateType === GenericTemplateType.posting_template) {
				requestString = 'transaction';
			} else if (this.props.templateType === GenericTemplateType.report_template) {
				requestString = 'report';
			}

			if (this.props.templateCategory === TemplateCategory.customer) {
				requestString += 'TemplatesCustomerGet';
			} else if (this.props.templateCategory === TemplateCategory.backoffice) {
				requestString += 'TemplatesBoGet';
			} else if (this.props.templateCategory === TemplateCategory.admin) {
				requestString += 'TemplatesAdminGet';
			}
		}

		api.asyncRequest<Array<Account.TransactionTemplate>>(
			requestParams,
			apis.TemplatesApi,
			requestString
		) 
			.then((response: Array<Account.TransactionTemplate>) => {
				const newTemplatesData = response;
				newTemplatesData.unshift(this.defaultSelectOption);
				const templatesData = this.state.templatesData;
				templatesData.account = response;
				this.setState({
					templatesData: templatesData,
					keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
				});
				if (this.props.templateType === GenericTemplateType.report_template) {
					api.asyncRequest<Array<Ident.TransactionTemplate>>(
						requestParams,
						apis.TemplatesApiDient,
						requestString
						)
						.then((responseIdent: Array<Ident.TransactionTemplate>) => {
							if(responseIdent != null && responseIdent.length > 0) {
								const newTemplatesDataIdent = responseIdent;
								const templatesData = this.state.templatesData;
								templatesData.ident = newTemplatesDataIdent;
								this.setState({
									templatesData: templatesData,
									keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
								});
							}
								
						}).catch((error: ApiError) => {
							if (error != null) {
								const config: IMessageConfig = MessageHandler.onError(
									Reporter['template.data.request.error'],
									evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
								);
								this.setState({
									showInlineSuccess: false,
									showInlineError: config.errorMethods.inline,
									key:
										config.translationKey != null
											? config.translationKey + '.error'
											: '',
									errorMessage:
										config.errorMessage != null
											? config.errorMessage
											: error.statusText,
									keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
								});
							} else {
								MessageHandler.onError(Reporter['template.data.request.error']);
								this.setState({
									keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
								});
							}
					});
				}
			})
			.catch((error: ApiError) => {
				if (error != null ) {
					const config: IMessageConfig = MessageHandler.onError(
						Reporter['template.data.request.error'],
						evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
					);
					this.setState({
						showInlineSuccess: false,
						showInlineError: config.errorMethods.inline,
						key:
							config.translationKey != null
								? config.translationKey + '.error'
								: '',
						errorMessage:
							config.errorMessage != null
								? config.errorMessage
								: error.statusText,
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
				} else {
					MessageHandler.onError(Reporter['template.data.request.error']);
					this.setState({
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
				}
			});
	}

	// Maybe useful in future, do not delete for documentation purposes.
	// If safe to delete, delete variable group
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	matchesTemplateGroup(group: string): boolean {
		// return group === this.TEMPLATE_GROUP;
		return true;
	}

	initFormInputs(elem: IGenericElement) {
		const formInputs = this.state.formInputs;
		if (elem.type === GenericElementType.date) {
			formInputs[elem.uuid] = new Date();
		} else if (
			elem.type === GenericElementType.text ||
			elem.type === GenericElementType.password ||
			elem.type === GenericElementType.number ||
			elem.type === GenericElementType.enum ||
			elem.type === GenericElementType.combined 			
		) {
			formInputs[elem.uuid] = elem.required === true ? null : '';
		}
		this.setState({
			formInputs: formInputs,
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
		});
	}

	componentDidUpdate() {
		if (i18next.language !== this.state.lang && this.state.elem != null) {
			this.onSelect(this.state.elem);
		}
	}

	onSelect(elem: any, ident?: boolean) {
		this.triggerRerenderByIncreasingKey();
		// TODO: make it type like this: {[this.props.id as string]: value}
		this.setState({ showTemplate: false });
		const selectedTemplateOption = elem;
		let selectedPostingTemplate = this.defaultSelectOption;
		let parsedContent: IParsedContent | null = null;
		let errors: Array<boolean> = [];
		this.requiredElements = 0;
		const templatesData = ident === true ? this.state.templatesData.ident : this.state.templatesData.account 
		for (const template of templatesData) {
			if (template.label === selectedTemplateOption) {
				// TODO: replace with template.operationid ?; list/enum with all operation ids (?)
				selectedPostingTemplate = template;
				if (selectedPostingTemplate != null && selectedPostingTemplate.path != null) {
					parsedContent = this.parser.getParsedContent(selectedPostingTemplate, ident);
					errors = parsedContent.elements.map((elem: IGenericElement) => {
						this.initFormInputs(elem);

						return elem.required != null &&
							elem.required === true &&
							(elem.type === GenericElementType.text ||
								elem.type === GenericElementType.password ||
								elem.type === GenericElementType.date ||
								elem.type === GenericElementType.number ||
								elem.type === GenericElementType.combined)
							? true
							: false;
					});
				}
				break;
			}
		}
		this.setState({
			showTemplate:
				selectedTemplateOption !== this.defaultSelectOption.label ? true : false,
			selectedPostingTemplate: selectedPostingTemplate,
			selectedLabel: elem,
			parsedContent: parsedContent,
			errors: errors,
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			elem: elem,
			lang: i18next.language,
			showReportDownload: false,
			selectedIsIdent: ident
		}, () => {
			Actions.setEditableComponentActiveIndex(0);
			this.activeIndex = 0;
		});
		if (this.props.resetCallback != null) {
			this.props.resetCallback();
		}
		this.activeIndex = -1;
	}

	getElementForUuid(uuid: string): IGenericElement | null {
		if (this.state.parsedContent != null && this.state.parsedContent.elements != null) {
			for (const element of this.state.parsedContent.elements) {
				if (element.uuid === uuid) {
					return element;
				}
			}
		}
		return null;
	}

	getTypeForUuid(uuid: string): string {
		const element = this.getElementForUuid(uuid);
		return element != null ? element.type : '';
	}

	getTitleForUuid(uuid: string): string {
		const element = this.getElementForUuid(uuid);
		return element != null ? element.title : '';
	}

	getRegexMapForUuid(uuid: string) {
		const element = this.getElementForUuid(uuid);
		return element != null ? element.regexMap : [];
	}

	deleteEmptyFields(requestBody: string): string {
		let replaceRegex = new RegExp('"[^"{}]*":\\s"",?');
		while (requestBody.search(replaceRegex) >= 0) {
			// for example: "creditorAddress": {"street": "", ...}  will become "creditorAddress": {}
			requestBody = requestBody.replace(replaceRegex, '');
		}
		replaceRegex = new RegExp('"[^"{}]*":\\s{},?');
		while (requestBody.search(replaceRegex) >= 0) {
			// for example this cuts out: "creditorAddress": {}
			requestBody = requestBody.replace(replaceRegex, '');
		}
		return requestBody;
	}

	deleteUUids(obj: any, uuids: Array<string>): object {
		Object.entries(obj).forEach((el) => {
			if (typeof el[1] === 'object') {
				obj[el[0]] = this.deleteUUids(el[1], uuids);
			}
			const val: string = el[1] as string;
			if (uuids.indexOf(val) >= 0) {
				delete obj[el[0]];
			}
		});
		return obj;
	}

	/*base64EncodeUnicode(str: string) {
		// First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters, 
		// then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
		const utf8Bytes = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
			//@ts-ignore
				return String.fromCharCode('0x' + p1);
		});
	
		return btoa(utf8Bytes);
	}


	base64ToArrayBuffer(data: any) {
		const b64Data = this.base64EncodeUnicode(data);
		var binaryString = window.atob(b64Data);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	};*/

	onSubmit(domRect?: DOMRect | null, file?: FileType | null) {
		this.setState({
			clicked: true,
		});
		const parsedContent: IParsedContent | null = this.state.parsedContent;
		if (
			parsedContent != null
		) {
			let requestBody = this.parser.getRequestBody();
			console.log(requestBody);
			const toDelete: Array<string> = [];
			for (const entry in this.state.formInputs) {
				let value: any;
				if (
					typeof this.state.formInputs[entry] === 'object' &&
					!(this.state.formInputs[entry] instanceof Date)
				) {
					value = this.state.formInputs[entry] != null ? this.state.formInputs[entry]['value'] : '';
					const regexMap = this.getRegexMapForUuid(entry);
					const validRegex = this.state.formInputs[entry] != null ? this.state.formInputs[entry]['validRegex'] : '';

					if (regexMap != null) {
						// Code entirely correct, see here: https://github.com/eslint/eslint/issues/5044
						// eslint-disable-next-line no-loop-func
						regexMap.forEach((entry: { regex: RegExp; uuid: string }) => {
							if (validRegex === entry.regex) {
								requestBody = requestBody.replace(entry.uuid, value);
							} else {
								toDelete.push(entry.uuid);
							}
						});
					}
				} else if (this.state.formInputs[entry] instanceof Date) {
					value = this.state.formInputs[entry] != null ? this.state.formInputs[entry] as Date : new Date();
					let monthString: string | number = value.getMonth() + 1;
					if (monthString < 10) {
						monthString = '0' + monthString;
					}
					let dayString: string | number = value.getDate();
					if (dayString < 10) {
						dayString = '0' + dayString;
					}
					const key = this.getKeyForValue(requestBody, entry);
					let type = null;
					if(key != null) {
					   type = this.getTypeForKey(parsedContent.elements, key);
					}
					let dateString =
						value.getFullYear() + '-' + monthString + '-' + dayString;
					if(type === GenericElementType.datetime) {
						const hourString = value.getHours() < 10 ? "0" + value.getHours().toString() : value.getHours() ;
						const minuteString = value.getMinutes() < 10 ? "0" + value.getMinutes().toString() : value.getMinutes() ;
						const secondString = value.getSeconds() < 10 ? "0" + value.getSeconds().toString() : value.getSeconds() ;
						dateString += "T" + hourString + ":" + minuteString + ":" + secondString + "Z";
					}	
						// eslint-disable-next-line no-loop-func
						requestBody = requestBody.replace(entry, dateString);
				}
				else {
					value = this.state.formInputs[entry];
					if (this.getTypeForUuid(entry) === 'number') {
						value = value.replace(',', '.');
						requestBody = requestBody.replace('"' + entry + '"', value);
						const title = this.getTitleForUuid(entry);
						if ((value == null || value === '') && title !== '') {
							requestBody = requestBody.replace(
								new RegExp(',*?"' + title + '":'),
								''
							);
						}
					} else {
						if (i18next.language !== 'en') {
							if (!Number.isNaN(parseFloat(value))) {
								value = value.replace(',', '.');
							}
						}
						requestBody = requestBody.replace(entry, value);
					}
				}
				for(const key of Object.keys(this.state.delFormInputs)) {
					toDelete.push(key);
				}
			}
			if (parsedContent.uri == null || parsedContent.method == null) {
				const config: IMessageConfig = MessageHandler.onError(
					Reporter['generic.parsed.content.uri.null']
				);
				this.setState({
					showInlineSuccess: false,
					showInlineError: config.errorMethods.inline,
					key: config.translationKey != null ? config.translationKey + '.error' : '',
					errorMessage: config.errorMessage != null ? config.errorMessage : '',
					keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
				});
				return;
			}
			const requestParams: any = {};
			if (parsedContent.method === 'GET') {
				for (const element of parsedContent.elements) {
					const key = element.title;

					for (const input in this.state.formInputs) {
						if (input === element.uuid) {
							const value = this.state.formInputs[input];
							if (value instanceof Date) {
								let monthString: string | number = value.getMonth() + 1;
								if (monthString < 10) {
									monthString = '0' + monthString;
								}
								let dayString: string | number = value.getDate();
								if (dayString < 10) {
									dayString = '0' + dayString;
								}
								const dateString =
									value.getFullYear() + '-' + monthString + '-' + dayString;
								requestParams[key] = dateString;
							} else {
								if (value != null && value && 0 !== value.length) {
									requestParams[key] = value;
								}
							}
							break;
						}
					}
				}
			}
			requestBody = this.deleteEmptyFields(requestBody);
			if (toDelete.length > 0) {
				try {
					//TODO check method for erros when nothing is replaced
					const newjs = JSON.stringify(
						this.deleteUUids(JSON.parse(requestBody), toDelete)
					);
					requestBody = newjs;
				} catch (err) {
					Log.error(Logs.PARSER, err);
				}
			}
			let contentType = null;
			if( file != null ) {
				if(file === FileType.pdf){
					contentType = Account.ReportResponseType.ApplicationPdf
				} else if ( file === FileType.xls) {
					contentType = Account.ReportResponseType.ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet
				} else if ( file === FileType.docx) {
					contentType = Account.ReportResponseType.ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet
				} else if ( file === FileType.csv) {
					contentType = Account.ReportResponseType.TextCommaSeparatedValues
				} else {
					contentType = Account.ReportResponseType.ApplicationJson
				}		
			}
			api.genericRequest<any>(
				parsedContent.uri,
				parsedContent.method,
				{
					'Content-Type':
						parsedContent.contentType == null
							? 'application/json'
							: parsedContent.contentType,
					'response_type': contentType != null ? contentType : 'application/json'	
				},
				requestBody,
				requestParams,
				this.state.selectedIsIdent,
				file !== FileType.inApp ? file : null
				)
				.then((response) => {
					this.setState({
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
						if (
							(response.status != null &&
							(response.status < 200 || response.status >= 300) )
							|| response.error_code != null
						) {
							let respBody = response.error_code != null ? response : response.response;
							if( typeof respBody === 'string') {
								respBody = JSON.parse(respBody);
							}
							
							const config: IMessageConfig = MessageHandler.onError(
								Reporter['template.posting.request.error'],
								respBody.error_code,
								respBody.error_text
							);
							this.setState({
								showInlineSuccess: false,
								showInlineError: config.errorMethods.inline,
								key:
									config.translationKey != null
										? config.translationKey + '.error'
										: '',
								errorMessage: response.response.error_text,
								keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
							});
						} else {
							if(typeof response === 'string') {
								response = JSON.parse(response);
							}
							/*if(file != null && file != FileType.inApp) {
								const fileEnding: string =
									file === FileType.csv ? 'csv' : file === FileType.pdf ? 'pdf' : 'xlsx';
								const anchor = document.body.appendChild(document.createElement('a'));
								anchor.download = format.getDateString() + '_report.' + fileEnding;
								anchor.href = response as string;
								anchor.click();
							}
							else */if (file === FileType.inApp) {
								OverlayHandler.showOverlay(Overlays.reportOverlay, {data: response})
							}	
							
							if (this.isCustomerScreen()) {
								if (this.state.selectedUser != null) {
									Accounts.getAccountsByPersonId(
										this.state.selectedUser.person_id
									).then((response: Array<IItfAccount>) => {
										Actions.setCustomerAccounts(response);
									});
								}
							} else if (this.isBackofficeScreen()) {
								if (this.state.currentAccount != null) {
									Accounts.getAccountById(
										this.state.currentAccount.account_number
									).then((response: IItfAccount) => {
										Actions.backofficeChanged(response);
									});
								}
							}
							let config: IMessageConfig = {errorMethods: {}};
							if(response.release_request_id != null) {
								config = MessageHandler.onSuccess(
									Reporter['template.posting.request.approval'],
									response.statusText
								);
							}
							else {
								config = MessageHandler.onSuccess(
									Reporter['template.posting.request.error'],
									response.statusText
								);
							}
							this.setState({
								showInlineSuccess: config.errorMethods.inline,
								successMessage:
									config.successMessage != null ? config.successMessage : '',
								key:
									config.translationKey != null
										? config.translationKey + '.success'
										: '',
								keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
							});
							// // this will rerender the displayed fields resulting in "fresh" empty fields for a new transaction
							if (
								parsedContent.method === 'GET' &&
								this.props.notification != null
							) {
								const triggerPageChange = true;
								this.props.notification(
									triggerPageChange,
									response,
									this.state.selectedPostingTemplate
								);
							}
						}
				})
				.catch((error: ApiError) => {
					if (error != null ) {
						const config: IMessageConfig = MessageHandler.onError(
							Reporter['template.posting.request.unknown.error'],
							evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
						);
						this.setState({
							showInlineSuccess: false,
							showInlineError: config.errorMethods.inline,
							key:
								config.translationKey != null
									? config.translationKey + '.error'
									: '',
							errorMessage:
								config.errorMessage != null
									? config.errorMessage
									: error.statusText,
							keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
						});
					} else {
						MessageHandler.onError(
							Reporter['template.posting.request.unknown.error']
						);
						this.setState({
							keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
						});
					}
				}); 
		}
	}

	getKeyForValue(requestBody: string, value: string): string | null {
		try {
		  const requestJson = JSON.parse(requestBody)
          const keys = Object.keys(requestJson);
		  for(const o of keys) {
		  	  //@ts-ignore
		 	  if(requestJson[o] === value) {
			   	return o;
			  }
	     	}
	    	return null;
    	}
		catch(e) {
			return null;
		}
	}

	getTypeForKey(vals: Array<IGenericElement>, key: string) : GenericElementType |  null { 
		for(const o of vals) {
			if(o.title === key) {
				return o.type;
			}
		}
		return null;
	}

	onCancel(event: SyntheticEvent) {
		event.preventDefault();
		this.setState({
			showTemplate: false,
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
		});
	}

	toggleOptionalFields() {
		this.setState({
			showOptionalFields: !this.state.showOptionalFields,
		});
	}
	compareValues(a: string | any, b: string | any) {
		if(a instanceof Date && b instanceof Date ) {
			return compareDate(a,b) !== 0;
		}

		if (typeof a === 'string' && typeof b === 'string') {
			return a !== b;
		}
		if (typeof a === 'number' && typeof b === 'number') {
			return a !== b;
		}
		if (typeof a === 'object' && typeof b === 'object') {
			if (a != null && b != null && a.value !== b.value) {
				return true;
			}
			return false;
		}
		return false;
	}
	notification(requestBodyUuid: string, value: any, index?: number, hasError?: boolean) {
		const formInputs = this.state.formInputs;
		let valueChanged = false;
		if (requestBodyUuid != null && value != null) {
			valueChanged = this.compareValues(value, formInputs[requestBodyUuid]);
			formInputs[requestBodyUuid] = value;
		}
		const errors = this.state.errors;

		let errorChanged = false;
		if (errors != null && index != null && hasError != null) {
			errorChanged = errors[index] !== hasError;
			errors[index] = hasError;
		}
		
		if (errorChanged || valueChanged) {
			this.setState({
				errors: errors,
				formInputs: formInputs,
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
				clicked: false,
			});
		}
	}

	private areSiblings(elem1: IGenericElement, elem2: IGenericElement): boolean {
		if (
			elem1.parentKeys != null &&
			elem2.parentKeys != null &&
			elem1.parentKeys.length > 0 &&
			elem1.parentKeys.length === elem2.parentKeys.length &&
			elem1.title !== elem2.title
		) {
			for (let i = 0; i < elem1.parentKeys.length; i++) {
				if (elem1.parentKeys[i] !== elem2.parentKeys[i]) {
					return false;
				}
			}
			return true;
		} else {
			return false;
		}
	}
	setDownloadLoading(value: boolean) {
		this.setState({
			downloadLoading: value,
		});
	}
	isSiblingFieldFilledIn(currentElement: IGenericElement): boolean {
		if (this.state.parsedContent != null) {
			if (currentElement.parentKeys != null && currentElement.parentKeys.length > 0) {
				for (const element of this.state.parsedContent.elements) {
					if (
						this.areSiblings(currentElement, element) &&
						this.state.formInputs[element.uuid] !== ''
					) {
						return true;
					}
				}
			}
		}
		return false;
	}

	getTableContent() {
		let skipFirst = true;
		const preRows = [];
		const rows: Array<ReactElement> = [];
		for (const template of this.state.templatesData.account) {
			if (skipFirst) {
				skipFirst = false;
				continue;
			}
			preRows.push({template: template , type: 'a'});
		}
		for (const template of this.state.templatesData.ident) {
			if (skipFirst) {
				skipFirst = false;
				continue;
			}
			preRows.push({template: template, type: 'i'});
		}
		const sortedRows = preRows.sort((a: {template: Account.TransactionTemplate | Ident.TransactionTemplate, type: string }, 
			 							 b: {template: Account.TransactionTemplate | Ident.TransactionTemplate, type:string  }) => {
			if(a.template.label > b.template.label) {
				return 1;
			}
			else if(a.template.label === b.template.label) {
				return 0;
			}
			else {
				return -1;
			}
		}) 
		for (const template of sortedRows) {
			rows.push(
				<CustomRow key={template.template.label}>
					<HoverCell
						id={template.template.label}
						onClick={() => this.onSelect(template.template.label, template.type === 'i')}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<TitleCell>{template.template.label} </TitleCell>
						</div>
						<Helpertext>{template.template.short_info}</Helpertext>
					</HoverCell>
				</CustomRow>
			);
		}
		return rows;
	}
	handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
		if (event.nativeEvent.key === 'ArrowDown' ) {
			event.preventDefault();
			this.activeIndex =  (this.activeIndex + 1) % (this.totalFields + 1);
			Actions.setEditableComponentActiveIndex(this.activeIndex);
		} else if (event.nativeEvent.key === 'ArrowUp' ) {
			event.preventDefault();
			this.activeIndex = this.activeIndex <= 0 ? this.totalFields : (this.activeIndex - 1) % ( this.totalFields + 1 );
			Actions.setEditableComponentActiveIndex(this.activeIndex);
		} 
	}

	setActiveIndex(index: number) {
		if(index !== this.activeIndex) 
		{
			this.activeIndex = index;
			Actions.setEditableComponentActiveIndex(this.activeIndex);
		}
	}

	renderDynamicFields(elem: IGenericElement, key: string, currFieldID: number, out: Array<JSX.Element | undefined>, optionalFieldsCount: number )  {
		const dyn = this.state.dynFieldIndex ?? {};
		let currIdx = "SortCode";
		if(dyn[key] != null) {
			currIdx = dyn[key];
		}
		const dynInps = this.state.dynFormInputs ?? {};
		const delInps: { [index: string]: any } = {};
		const newDynInps: {[key: string]: any} = {};
		out.push(
			genericElementUtils.createGenericElement(
				elem,
				this.FIELD_ID + currFieldID,
				++currFieldID,
				this.notification,
				//@ts-ignore
				this.state.selectedPostingTemplate.parameter_names[key],
				typeof	this.state.formInputs[elem.uuid] === 'object' && this.state.formInputs[elem.uuid]  != null ? 
																									this.state.formInputs[elem.uuid].value : 
																									this.state.formInputs[elem.uuid],
				() => this.onSubmit(),
				undefined,
				undefined,
				(idx: any) => {
					const dyn = this.state.dynFieldIndex ?? {};
					dyn[key] = idx;
					this.setState({
						dynFieldIndex: dyn
					})
				}
			)
		);
		if(elem.anyOf == null) {
			return;
		}
		for(const o of elem.anyOf) {
			if(o.belongsTo == AnyMapPar.indexOf(currIdx) ) {
				out.push(
					genericElementUtils.createGenericElement(
						o,
						this.FIELD_ID + currFieldID,
						++currFieldID,
						this.notification,
						//@ts-ignore
						{label: o.title},
						typeof	this.state.formInputs[elem.uuid] === 'object' && this.state.formInputs[elem.uuid]  != null ? 
																											this.state.formInputs[elem.uuid].value : 
																											this.state.formInputs[elem.uuid],
						() => this.onSubmit(),
						this.requiredElements + optionalFieldsCount,
						undefined
					)
				);
				newDynInps[o.uuid] = null;
			} else {
				delInps[o.uuid] = true;
			}
		}
		for(const newKey of Object.keys(newDynInps)) {

			if(Object.keys(dynInps).indexOf(newKey) === -1) {
				for(const delKey of Object.keys(dynInps)) {
					delete this.state.formInputs[delKey];
				}
				for(const addlKey of Object.keys(newDynInps)) {
					this.state.formInputs[addlKey] = null;
				}
				//@ts-ignore
				this.state.dynFormInputs = newDynInps;
				//@ts-ignore 	
				this.state.delFormInputs = delInps;
				break;			
			}
		}
	}

	render() {
		let fieldId = 0;
		const requiredFields: Array<any> = [];
		const optionalFields: Array<any> = [];
		if (this.state.parsedContent != null) {
			let optionalFieldsCount  = 0;
			let requieredFieldsCount =  -1;
			this.state.parsedContent.elements.forEach((elem: IGenericElement) => {
				if(  elem.required && ( elem.type !== GenericElementType.anyOf && elem.type !== GenericElementType.enum )) {
					++requieredFieldsCount;
				}
				if( !elem.required && this.state.showOptionalFields === true && elem.type !== GenericElementType.enum) {
					++optionalFieldsCount;
				}
				
				const elemCopy = Object.assign({}, elem);
				let array: Array<JSX.Element | undefined> | undefined;

				if (
					elem.parentKeys != null &&
					elem.parentKeys.length > 0 &&
					elem.parentRequired !== true &&
					elem.required === true
				) {
					array = optionalFields;

					if (
						!this.isSiblingFieldFilledIn(elem) &&
						this.state.formInputs[elem.uuid] === ''
					) {
						elemCopy.required = false;
						if (
							this.state.errors != null &&
							this.state.errors[fieldId] !== false
						) {
							const errors = this.state.errors;
							errors[fieldId] = false;
							this.setState({ errors: errors });
						}
					} else {
						if (
							this.state.errors != null &&
							this.state.errors[fieldId] === false &&
							this.state.formInputs[elem.uuid] === ''
						) {
							const errors = this.state.errors;
							errors[fieldId] = true;
							this.setState({ errors: errors });
						}
					}
				} else {
					if (elem.required === true) {
						array = requiredFields;
					} else {
						array = optionalFields;
					}
				}
				//@ts-ignore
				const key = this.state.selectedPostingTemplate.parameter_names[elemCopy.title] != null ||
				            elemCopy.parentKeys == null || elemCopy.parentKeys.length === 0 ? elemCopy.title :
						    elemCopy.parentKeys[elemCopy.parentKeys.length-1];
				if(elemCopy.type === GenericElementType.anyOf) {
					const curSize = array.length;
					this.renderDynamicFields(elemCopy, key, fieldId, array, optionalFieldsCount );
					fieldId += array.length - curSize;
				}
				else {
					array.push(
						genericElementUtils.createGenericElement(
							elemCopy,
							this.FIELD_ID + fieldId,
							fieldId++,
							this.notification,
							//@ts-ignore
							this.state.selectedPostingTemplate.parameter_names[key],
							typeof	this.state.formInputs[elemCopy.uuid] === 'object' && this.state.formInputs[elemCopy.uuid]  != null ? 
																												this.state.formInputs[elemCopy.uuid].value : 
																												this.state.formInputs[elemCopy.uuid],
							() => this.onSubmit(),
							elem.required ? requieredFieldsCount : this.requiredElements + optionalFieldsCount,
							this.setActiveIndex,
						)
					);
			}
			});
			requieredFieldsCount = requieredFieldsCount < 0 ? 0 : requieredFieldsCount;
			optionalFieldsCount  = optionalFieldsCount  < 0 ? 0 : optionalFieldsCount;
			this.totalFields = requieredFieldsCount + optionalFieldsCount;
			this.requiredElements = requieredFieldsCount;
		}
		return (
			<StyledBox onKeyDown={this.handleKeyDown} ref={this.boxRef} onClick={() => OverlayHandler.closeOverlaysOnClick()}>
				<FlexBox>
					<Title title={this.props.heading != null ? this.props.heading : ''} />
					<StyledHr />
					<StyledTextSubHeading>{this.props.subHeading}</StyledTextSubHeading>
					<ViewBox>
						<HalfBox heightOfBox={this.state.heightOfBox}>
							<Table><tbody>{this.getTableContent()}</tbody></Table>
						</HalfBox>
						<HalfBox heightOfBox={this.state.heightOfBox}>
							{this.state.showTemplate === true ? (
								<FlexBox key={this.state.keyUsedForRerender}>
									<div style={{ marginBottom: '16px' }}>
										<Title
											title={
												this.state.selectedLabel != null
													? this.state.selectedLabel
													: ''
											}
										/>{' '}
									</div>

									{requiredFields.length > 0 ? (
										<StyledViewFields  >{requiredFields}</StyledViewFields>
									) : null}

									{optionalFields.length > 0 ? (
										<FlexBox>
											<StyledHrOptionalFields />
											<StyledTextToggleOptionals
												onClick={this.toggleOptionalFields}>
												{this.state.showOptionalFields === true
													? translate('generic.hideOptionalFields')
													: translate('generic.showOptionalFields')}
											</StyledTextToggleOptionals >
											{this.state.showOptionalFields === true ? (
												<StyledViewOptionalFields >
													{optionalFields}
												</StyledViewOptionalFields>
											) : null}
										</FlexBox>
									) : null}
								</FlexBox>
							) : null}

							{this.state.showTemplate === true ? (
								this.props.isReport === true ? (
									<StyledButtonView>
										<ButtonOk
											disableSpinner={true}
											style={this.ButtonStyle}
											onClick={(event: any) => {
												event.preventDefault();
											}}
											disabled={
												(this.state.errors != null &&
												this.state.errors.indexOf(true) >= 0) || this.state.clicked === true
											}>
											<TouchableOpacity
												onClick={(event, domRect) => {													
														OverlayHandler.showOverlay(Overlays.FileTypeChooser, {
															callback: (file: any) => {
																this.onSubmit(null, file);
															},
															posX: domRect == null ? 0 : domRect.x,
															posY: domRect == null ? 0 : domRect.y - 182,
															displayReport: true, 
															width: '205px',
														});											
												}}
												containerStyle={FileChooserStyle}>
												{this.state.downloadLoading === true ? (
													<div style={{ width: '100%' }}>
														<ActivityIndicator
															animating={true}
															size="small"
														/>
													</div>
												) : (
													<React.Fragment>
														<div>"Download" </div>
														<div
															style={{
																width: '100%',
																marginLeft: '10px',
																marginBottom: '0px',
																color: 'white',
															}}>
															{Icons.downloadIcon()}
														</div>
													</React.Fragment>
												)}
											</TouchableOpacity>
										</ButtonOk>
									</StyledButtonView>
								) : (
									<StyledButtonView>
										<ButtonOk
											key={this.state.keyForLoadingSpinner}
											disabled={
												this.state.errors != null &&
												this.state.errors.indexOf(true) >= 0 && false
											}
											clicked={this.state.clicked}
											id="buttonGenericTemplateOk"
											onClick={() => this.onSubmit()
												}
											style={this.ButtonStyle}>
											{this.props.templateType ===
											GenericTemplateType.report_template
												? translate('button.create')
												: translate('button.send')}
										</ButtonOk>
										<ButtonCancel
											id="buttonGenericTemplateCancel"
											onClick={(event) => this.onCancel(event)}
											style={this.ButtonStyle}
										/>
									</StyledButtonView>
								)
							) : null}
						</HalfBox>
					</ViewBox>
				</FlexBox>
			</StyledBox>
		);
	}
}
