import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { translate } from '../../../common/language/translate';
import ActivityIndicator from '../activityIndicator';
import Button from '@material-ui/core/Button';

interface IButtonProps {
	loading?: boolean;
	enableSpinner?: boolean;
	onClick?: (event: SyntheticEvent) => void;
	children?: string;
	theme?: any;
	disabled?: boolean;
	id?: string;
	style?: {};
}
interface IButtonState {
	loading?: boolean;
}

const StyledButton = styled(Button)`
    && {
        height: 46px;
        width: ${props => props.theme.Button.width};

        border: none;
        border-radius: 4px;
        background-color: ${props => props.theme.ButtonCancel.backgroundColor};

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        :disabled {
            background-color: ${props => props.theme.ButtonDisabled.backgroundColor};
        }
        &:hover {
            background-color: ${props => props.theme.ButtonCancel.hover};
        }
    }
`;

const StyledText = styled.span<{ disabled?: boolean }>`
	color: ${(props) =>
		props.disabled ? props.theme.ButtonDisabled.color : props.theme.ButtonCancel.color};
	text-transform: uppercase;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.3px;
`;

export default class ButtonCancel extends React.Component<IButtonProps, IButtonState> {
	constructor(props: IButtonProps) {
		super(props);
		this.state = {
			loading: false,
		};
		this.onClick = this.onClick.bind(this);
	}

	onClick(event: SyntheticEvent): void {
		if (this.props.disabled !== true) {
			this.setState({
				loading: true,
			});
			if (this.props.onClick != null) {
				this.props.onClick(event);
			}
		}
	}

	render() {
		return (
			<StyledButton
				style={this.props.style}
				onClick={(event: SyntheticEvent) => this.onClick(event)}
				disabled={this.props.disabled}>
				{this.state.loading === true && this.props.enableSpinner === true ? (
					<ActivityIndicator
						animating={this.state.loading}
						size="small"
						color={this.props.theme.Button.color}
					/>
				) : (
					<StyledText disabled={this.props.disabled}>
						{this.props.children == null
							? translate('button.cancel')
							: this.props.children}
					</StyledText>
				)}
			</StyledButton>
		);
	}
}
