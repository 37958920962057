// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ConsentPost
 */
export interface ConsentPost {
    /**
     * ConsentId as per the requested resource
     * @type {string}
     * @memberof ConsentPost
     */
    ConsentId: string;
    /**
     * Indicates if the account holder authentication has been successful and the consent has been accepted (“Pass”) or if either the authentication has failed, or the account holder has rejected the consent request (“Fail”)
     * @type {string}
     * @memberof ConsentPost
     */
    Outcome: ConsentPostOutcomeEnum;
    /**
     * An array of AccountIds which the account holder has approved for the consent. Mandatory if Outcome is Pass
     * @type {Array<string>}
     * @memberof ConsentPost
     */
    Accounts?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum ConsentPostOutcomeEnum {
    Pass = 'Pass',
    Fail = 'Fail'
}

