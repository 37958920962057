// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * if grant type is authorization_code, the id token will also be delivered
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * the access token
     * @type {string}
     * @memberof TokenResponse
     */
    access_token: string;
    /**
     * the refresh token
     * @type {string}
     * @memberof TokenResponse
     */
    refresh_token: string;
    /**
     * the id token
     * @type {string}
     * @memberof TokenResponse
     */
    id_token?: string;
    /**
     * the token_type
     * @type {string}
     * @memberof TokenResponse
     */
    token_type: TokenResponseTokenTypeEnum;
    /**
     * @type {number}
     * @memberof TokenResponse
     */
    expires_in: number;
}

/**
 * @export
 * @enum {string}
 */
export enum TokenResponseTokenTypeEnum {
    Bearer = 'Bearer'
}

