// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpQuery, throwIfRequired, encodeURI } from '../runtime';

export interface PersonPersonIdAccountTaskPostRequest {
    person_id: number;
    number_of_days: number;
    account_task_action: PersonPersonIdAccountTaskPostAccountTaskActionEnum;
    token: string;
}

export interface PersonPersonIdDeleteRequest {
    person_id: number;
    token: string;
    immediate_winddown: boolean;
    immediate_closure: boolean;
    synchronous: boolean;
}

/**
 * no description
 */
export class PartnerApi extends BaseAPI implements IOperationIdMap {

    /**
     * account tasks.
     * remove a person and close all it\'s accounts.
     */
    personPersonIdAccountTaskPost = (requestParameters: PersonPersonIdAccountTaskPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personPersonIdAccountTaskPost');
        throwIfRequired(requestParameters, 'number_of_days', 'personPersonIdAccountTaskPost');
        throwIfRequired(requestParameters, 'account_task_action', 'personPersonIdAccountTaskPost');
        throwIfRequired(requestParameters, 'token', 'personPersonIdAccountTaskPost');

        const query: HttpQuery = {
            ...(requestParameters.number_of_days && { 'number_of_days': requestParameters.number_of_days }),
            ...(requestParameters.account_task_action && { 'account_task_action': requestParameters.account_task_action }),
            ...(requestParameters.token && { 'token': requestParameters.token }),
        };

        return this.request<void>({
            path: '/person/partner/{person_id}/account_task'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            query,
        });
    };

    /**
     * in order to close the accounts it is neccessary to have a balance of 0.00 as from concept, it is in the responsibility of the user, to bring the balance to zero. either by transfering money out or in.
     * remove a person and close all it\'s accounts.
     */
    personPersonIdDelete = (requestParameters: PersonPersonIdDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'personPersonIdDelete');
        throwIfRequired(requestParameters, 'token', 'personPersonIdDelete');
        throwIfRequired(requestParameters, 'immediate_winddown', 'personPersonIdDelete');
        throwIfRequired(requestParameters, 'immediate_closure', 'personPersonIdDelete');
        throwIfRequired(requestParameters, 'synchronous', 'personPersonIdDelete');

        const query: HttpQuery = {
            ...(requestParameters.token && { 'token': requestParameters.token }),
            ...(requestParameters.immediate_winddown && { 'immediate_winddown': requestParameters.immediate_winddown }),
            ...(requestParameters.immediate_closure && { 'immediate_closure': requestParameters.immediate_closure }),
            ...(requestParameters.synchronous && { 'synchronous': requestParameters.synchronous }),
        };

        return this.request<void>({
            path: '/person/partner/{person_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'DELETE',
            query,
        });
    };


    operationToOperationId = {
	    "personPersonIdAccountTaskPost": "person_person_id_account_task_post" ,
	    "personPersonIdDelete": "person_person_id_delete"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum PersonPersonIdAccountTaskPostAccountTaskActionEnum {
    ACTBLO = 'ACTBLO',
    ACTDGR = 'ACTDGR',
    BPCCON = 'BPCCON',
    BPCACC = 'BPCACC',
    BPCTBC = 'BPCTBC',
    BPCPBC = 'BPCPBC'
}
