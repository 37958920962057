import { FormError } from '../../../components/atomiccompoents/form/formError';
import { FormSuccess } from '../../../components/atomiccompoents/form/formSuccess';
import React, { ReactElement } from 'react';
import { translate } from '../../../common/language/translate';
import { IStateCenter } from '../overlayhandler/globaloverlays/aCenteredOverlay';

export enum Reporter {
    'web.auth.password.invalid',
    'image.not.found',
    'person.address.requirement.post',
    'document.upload.error',
    'template.posting.request.approval',
    'perform.login.unknown.error',
    'perform.login.permission.response',
    'general.error.message',
    'account.balance.get.request',
    'account.details.accountnumber.block.delete.error',
    'account.details.accountnumber.block.put.error',
    'account.details.partner.get.request',
    'account.details.partner.transfer.delete.request',
    'account.details.partner.transfer.get.request',
    'account.fetch.data.error',
    'accounts.transactions.get.request.error',
    'backoffice.search.get.request',
    'backoffice.relaeses.fetch',
    'beneficiary.preview.getall',
    'customer.add.new',
    'customer.address.edit',
    'customer.search.post.request.noparameter.error',
    'customer.details.adress.put.request.error',
    'customer.details.adress.post.request.error',
    'customer.details.email.post.request.error',
    'customer.details.externalAccounts.invalidData',
    'person.passport.document.put',
    'customer.details.externalAccounts.submit',
    'customer.details.person.person.id.request.error',
    'customer.details.person.get.request.error',
    'customer.details.identification',
    'dateselect.range.error',
    'permissions.persons.roles.put',
    'ident.person.partner.transfer.delete.request',
    'person.create.thirdperson.post.request.error',
    'reports.account.get.request',
    'administrator.teams.add.team.member',
    'administrator.teams.update.role',
    'administrator.teams.update.data',
    'administrator.teams.add.role',
    'selfsettings.language.put.request',
    'transactions.activity.get.request.noparameters',
    'transaction.posting.journal.get.request',
    'template.posting.request.error',
    'template.posting.request.unknown.error',
    'template.data.request.error',
    'transactions.activity.detail.get.request.error',
    'transfer.between.accounts',
    'transfer.sepa',
    'web.accountselector.download.statements',
    'web.account.add',
    'web.auth.login.error',
    'web.auth.login.error.nodata',
    'web.auth.login.error.nopassword',
    'web.auth.login.error.nouser',
    'web.auth.resetpassword.error.nomatch',
    'web.auth.password.post',
    'web.auth.password.put',
    'web.auth.signup.post',
    'web.auth.verify.post',
    'web.auth.verify.post.missing_params',
    'web.beneficiary.add',
    'web.beneficiary.delete',
    'web.beneficiary.edit.missing_params',
    'web.beneficiary.edit.save',
    'web.beneficiary.merge.save',
    'web.sendmoney.beneficiary.preview.get',
    'web.sendmoney.post.request',
    'web.sendmoney.post.request.missing_data',
    'account.by.personid.get.request',
    'person.roles.get.request',
    'person.create.third.post.request',
    'web.auth.login.permission.error',
    'images.upload.decode',
    'person.create.thirdperson.invaliddata',
    'partner.notfound',
    'person.partner.transfer.delete',
    'person.partner.get',
    'account.close.selectedUser',
    'role.or.person.null',
    'person.authorize.get',
    'teammembers.permissions.persons.get.request',
    'accounts.by.person.id.get',
    'administrator.teams.save.roleid.null',
    'administrator.teams.save.personid.null',
    'generic.parsed.content.uri.null',
    'auth.is.permission.response',
    'web.auth.login.error.timerExceeded',
    'web.auth.password.timerExceeded',
    'person.merchant.put.error',
    'person.merchant.put.success',
    'person.merchant.sucesskey',
    'overlayhandler.noelement.error',
    'confirmation.nosuccess.error',
    'person.create.thirdperson.post.request.success',
    'person.merchant.document.wrongtype',
    'customer.details.external.accoounts.fetch',
    'customer.details.settings.fetch',
    'header.notifications.fetch.error',
    'header.customer.close.confirmation',
    'customer.details.save.undo',
    'customer.transactions.revert',
    'customer.transactions.cancel',
    'preferences.save',
    'customer.details.data.put.request',
    'user.profile.picture.request',
    'user.profile.picture.request.load',
    'person.create.thirdperson.post.request.error.other',
    'reports.get.file',
    'passport.post.request',
    'backoffice.relaeses.put',
    'product.image.delete',
    'teammember.approval.needed',
    'customers.email.put',
    'customers.email.post',
    'customer.details.fees.put',
    'person.merchant.put.product',
    'product.put',
    'person.merchant.delete.product',
    'person.merchant.address.put',
    'person.address.apprrove.post',
    'account.qrcode.png.get',
    'backoffice.relaeses.put.trigger',
    'backoffice.relaeses.put.new_release',
    'backoffice.relaeses.put.not_all',
    'backoffice.relaeses.put.ident',
    'person.password.reset',
    'transaction.search.primanota',
    'account.person.get',
    'account.person.get.multipleResults',
    'backoffice.relaeses.put.retriggered.B',
    'backoffice.relaeses.put.retriggered.R',
    'person.fees.delete',
    'customer.voucher.set',
    'customers.vouchers.report',
    'customers.product.image',
    'customers.vouchers.missingParams',
    'backoffice.relaeses.put.cancel',
    'customer.details.kyc.put.request',
    'customer.close.release',
    'customer.close.noRelease',
    'person.address.apprrove.release',
    'person.advanced_kyc.approve.release',
    'person.merchant.put.paymentmethod',
    'person.merchant.delete.paymentmethod',
    'admin.clearing_file.post',
    'admin.clearing_file.get',
    'customer.transactions.invoice',
    'customer.advancedKyc.post',
    'product.voucher_amount',
    'customer.merchants.time.invalid',
    'person.merchant.put.calendar',
    'person.merchant.productType.put',
    'person.merchant.productType.delete',
    'administration.feepackages.delete', 
    'administration.feepackages.put',
    'customer.close.reason.maxlength',
    'customers.person.put.approval',
    'customer.details.kyc.put.request.approval',
    'videoqueue.approve.put',
    'videoqueue.approve.result.put',
    'videoqueue.approve.video.put',
    'videoqueue.take.error',
    'fiskaly.create.post',
    'productmaintenance.change.category',
    'person.companion.connect.post',
    'product.maintenance.config.delete',
    'product.maintenance.config.put',
    'onfido.notification.put'
}

export interface IStatusState extends IStateCenter {
    showInlineError?: boolean;
    errorMessage?: string;
    showInlineSuccess?: boolean;
    successMessage?: string;
    key?: string;
}

export interface IDisplayMethods {
    inline?: boolean; // Show messages on corresponding input fields, or dialogs
    notification?: boolean; // Show message as notification in notification bar
    dialog?: boolean; // Show message in a popover
    bubble?: boolean; // Show message bubble on the bottom right, this bubble will disappear automatically after some time
}

export interface IMessageConfig {
    errorMethods: IDisplayMethods;
    errorMessage?: string | any;
    successMethods?: IDisplayMethods;
    successMessage?: string | any;
    translationKey?: string;
    warningMethods?: IDisplayMethods;
    warningMessage?: any;
}

export function getFormComponents(
    inlineError: boolean | undefined,
    inlineSuccess: boolean | undefined,
    errorMsg: string | undefined,
    succMsg: string | undefined,
    key: string | undefined
): Array<ReactElement> {
    return [
        <FormError
            key={'error_' + key}
            active={inlineError}
            error={translate(key, errorMsg)}
        />,
        <FormSuccess
            key={'success_' + key}
            active={inlineSuccess}
            success={translate(key, succMsg)}
        />,
    ];
}

export const ReporterConfig: Map<Reporter, IMessageConfig> = new Map([
    [
        Reporter['customer.search.post.request.noparameter.error'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'No Search parameter given'
        },
    ],
    [
        Reporter['customer.details.adress.put.request.error'],
        {
            errorMethods: { bubble: true },
            successMessage: 'Successfully updated address',
            errorMessage: 'error appeared while performing put request',
            successMethods: { bubble: true },
            translationKey: 'messages.putRequestError',
        },
    ],
    [
        Reporter['customer.details.adress.post.request.error'],
        {
            errorMethods: { bubble: true },
            errorMessage: 'error appeared while post request',
            successMessage: 'Successfull add address',
            successMethods: { bubble: true },
            translationKey: 'messages.postRequestError',
        },
    ],
    [
        Reporter['customer.details.email.post.request.error'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            translationKey: 'customer.details.message',
        },
    ],
    [
        Reporter['customer.details.externalAccounts.invalidData'],
        {
            errorMethods: { inline: true },
        },
    ],
    [
        Reporter['customer.details.externalAccounts.submit'],
        {
            errorMethods: { inline: true },
            successMethods: { bubble: true },
        },
    ],
    [
        Reporter['web.auth.password.put'],
        {
            errorMethods: { inline: true },
            errorMessage: 'Origin password wrong',
            successMethods: { bubble: true },
            translationKey: 'messages.passwordWrong',
        },
    ],
    [
        Reporter['web.accountselector.download.statements'],
        {
            errorMethods: { dialog: true },
            errorMessage: 'Error while downloading Statements',
            sucessMessage: 'Successfully downloaded Statements',
            successMethods: { bubble: true },
            translationKey: 'messages.downloadStatements',
        },
    ],
    [
        Reporter['web.auth.login.error'],
        {
            errorMethods: { inline: true },
            errorMessage: 'Error while logging in',
            successMessage: 'Successfully logged in',
            successMethods: { bubble: true },
            translationKey: 'messages.login',
        },
    ],
    [
        Reporter['web.auth.login.error.nodata'],
        {
            errorMethods: { inline: true },
            errorMessage: 'Error while logging in',
            successMessage: 'Successfully logged in',
            successMethods: { bubble: true },
            translationKey: 'messages.loginNoData',
        },
    ],
    [
        Reporter['template.posting.request.error'],
        {
            errorMethods: { dialog: true },
            successMessage: 'Successfully performed generic request',
            successMethods: { bubble: true },
            translationKey: 'messages.genericRequest',
        },
    ],
    [
        Reporter['dateselect.range.error'],
        {
            errorMethods: { bubble: true },
            errorMessage: 'Invalid Range Selected',
            successMethods: { bubble: true },
            translationKey: 'messages.rangeSelect',
        },
    ],
    [
        Reporter['accounts.transactions.get.request.error'],
        {
            errorMethods: { inline: true },
            successMethods: { inline: true },
            translationKey: 'transactionGet',
        },
    ],
    [
        Reporter['person.create.thirdperson.post.request.error'],
        {
            errorMethods: { dialog: true },
            successMethods: { bubble: true },
            errorMessage: 'Error',
            translationKey: 'messages.addCustomerExists',
        },
    ],
    [
        Reporter['person.create.thirdperson.post.request.success'],
        {
            errorMethods: { dialog: true },
            successMethods: { bubble: true },
            successMessage: 'The customer was successfully added to the backend system.',
            translationKey: 'messages.addCustomer',
        },
    ],
    [
        Reporter['selfsettings.language.put.request'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Sucessfully set language',
            errorMessage: 'unable to set Language',
            translationKey: 'messages.languageSet',
        },
    ],
    [
        Reporter['person.create.third.post.request'],
        {
            errorMethods: { inline: true },
            //errorMessage: 'Unable to create a team member',
            translationKey: 'messages.addTeamMember',
        },
    ],
    [
        Reporter['administrator.teams.add.team.member'],
        {
            errorMessage: 'Field required',
            errorMethods: { inline: true },
            translationKey: 'team.required',
        },
    ],
    [
        Reporter['administrator.teams.add.role'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Sucessfully added a team member and role',
            errorMessage: 'Unable to add a role',
            translationKey: 'messages.addTeamMemberRole',
        },
    ],

    [
        Reporter['administrator.teams.update.role'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Sucessfully updated the role',
            errorMessage: 'Unable to update the role',
            translationKey: 'messages.updateTeamMemberRole',
        },
    ],
    [
        Reporter['administrator.teams.update.data'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Sucessfully updated the user data',
            errorMessage: 'Unable to update user data role',
            translationKey: 'messages.updateTeamMemberData',
        },
    ],

    [
        Reporter['account.details.partner.get.request'],
        {
            errorMethods: { dialog: true },
            errorMessage: 'unable to set Language',
            translationKey: 'messages.languageError',
        },
    ],
    [
        Reporter['account.details.partner.transfer.delete.request'],
        {
            errorMethods: { dialog: true },
            errorMessage: 'You can not leave us',
            translationKey: 'messages.deleteAccount',
        },
    ],
    [
        Reporter['ident.person.partner.transfer.delete.request'],
        {
            errorMethods: { dialog: true },
            errorMessage: 'Error on Partner Transfer Request',
            translationKey: 'messages.deleteAccount',
        },
    ],
    [
        Reporter['transactions.activity.get.request.noparameters'],
        {
            errorMethods: { inline: true },
            errorMessage: 'Missing parameters to fetch accountAccountNumberActivityDetailGet',
            translationKey: 'messages.getTransactionsParams',
        },
    ],
    [
        Reporter['account.fetch.data.error'],
        {
            errorMethods: { inline: true },
        },
    ],
    [
        Reporter['partner.notfound'],
        {
            errorMethods: { dialog: true },
            errorMessage: 'Partner not found',
            translationKey: 'messages.partnerNotFound',
        },
    ],
    [
        Reporter['general.error.message'],
        {
            errorMethods: { dialog: true },
            errorMessage: 'An Error occured',
            translationKey: 'messages.general',
        },
    ],
    [
        Reporter['person.merchant.put.error'],
        {
            errorMethods: { bubble: true },
            errorMessage: 'An Error occured, mandatory fields are invalid',
            translationKey: 'merchants.error',
        },
    ],
    [
        Reporter['person.merchant.put.success'],
        {
            errorMethods: {},
            successMethods: { bubble: true },
            successMessage: 'Successfully sent Merchant data',
            translationKey: 'document.upload-success',
        },
    ],
    [
        Reporter['person.merchant.sucesskey'],
        {
            errorMethods: {},
            successMethods: { bubble: true },
            successMessage: 'Successfully generated Accesskey',
            translationKey: 'merchants.success',
        },
    ],
    [
        Reporter['overlayhandler.noelement.error'],
        {
            errorMethods: {},
            successMethods: { bubble: true },
            successMessage: '',
            errorMessage: 'No Element given',
            translationKey: 'overlays.noElements',
        },
    ],
    [
        Reporter['confirmation.nosuccess.error'],
        {
            errorMethods: {},
            successMethods: { bubble: true },
            successMessage: '',
            errorMessage: 'Confirmation requiered',
            translationKey: 'overlay.confirmation',
        },
    ],
    [
        Reporter['person.merchant.document.wrongtype'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: '',
            errorMessage: 'Wrong file Type. Only Images and PDFs allowed',
            translationKey: 'merchants.document.filetype',
        },
    ],
    [
        Reporter['customer.details.external.accoounts.fetch'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: '',
            errorMessage: 'Error while fetching accounts',
            translationKey: 'customers.details.externalAccounts.fetchError',
        },
    ],
    [
        Reporter['customer.details.settings.fetch'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: '',
            errorMessage: 'Error while fetching settings',
            translationKey: 'customers.details.settings.fetchError',
        },
    ],
    [
        Reporter['header.notifications.fetch.error'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: '',
            errorMessage: 'Error while fetching notifications',
            translationKey: 'messageMenu.fetchError',
        },
    ],
    [
        Reporter['header.customer.close.confirmation'],
        {
            errorMethods: { bubble: true },
            warningMethods: { bubble: true },
            successMessage: '',
            warningMessage: 'You closed this View, are you sure?',
         },
    ],
    [
        Reporter['customer.details.save.undo'],
        {
            errorMethods: { bubble: true },
            warningMethods: { bubble: true },
            successMessage: '',
            warningMessage: 'Data saved, are you sure?',
         },
    ],
    [
        Reporter['customer.details.fees.put'],
        {
            errorMethods: { bubble: true },
            warningMethods: { bubble: true },
            successMessage: 'Successfully booked Feepackage',
            warningMessage: 'Successfully booked Package',
            translationKey: '',
        },
    ],
    [
        Reporter['customer.transactions.revert'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully canceld Transaction',
            errorMessage: 'Error on canceling Transaction',
            translationKey: '',
        },
    ],
    [
        Reporter['customer.transactions.cancel'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Cancel of transaction has been added to Approvals',
            errorMessage: 'Error on canceling Transaction',
            translationKey: '',
        },
    ],
    [
        Reporter['preferences.save'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully saved settings',
            errorMessage: 'Error on saving settings',
            translationKey: 'messages.preferencesSave',
        },
    ],
    [
        Reporter['customer.details.data.put.request'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully saved person details',
            errorMessage: 'Error on saving details',
            translationKey: '',
        },
    ],
    [
        Reporter['user.profile.picture.request'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully changed profilepicture',
            errorMessage: 'Error while saving profilepicture',
            translationKey: '',
        },
    ],
    [
        Reporter['user.profile.picture.request.load'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully load profilepicture',
            errorMessage: 'Error while loading profilepicture',
            translationKey: '',
        },
    ],
    [
        Reporter['person.create.thirdperson.post.request.error.other'],
        {
            errorMethods: { dialog: true },
            successMethods: { dialog: true },
            successMessage: 'Successfully created Person',
            errorMessage: 'Error, Data may be invalid',
            translationKey: 'messages.addCustomer',
        }
    ],
    [
        Reporter['reports.get.file'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully created Report',
            errorMessage: 'Error while generating report',
            translationKey: 'reports.getr',
        }
    ],
    [
        Reporter['passport.post.request'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully updated Passport',
            errorMessage: 'Error while updating Passport',
            translationKey: 'passport.post',
        }
    ],
    [
        Reporter['image.not.found'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Image not found in backend',
            translationKey: 'image.not.found',
        }
    ],
    [
        Reporter['document.upload.error'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on uploading Documents',
            successMessage: 'Successfully uploaded Document',
            translationKey: 'document.upload.error',
        }
    ],
    [
        Reporter['backoffice.relaeses.fetch'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on fetching Approvals',
            translationKey: 'messageMenu.approvals.',
        }
    ],
    [
        Reporter['backoffice.relaeses.put'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Some Approvals for core went into an error',
            successMessage: 'Approvals for core where successfully set',
            translationKey: 'messageMenu.approvals.',
        }
    ],
    [
        Reporter['backoffice.relaeses.put.cancel'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Some Rejections for core went into an error',
            successMessage: 'Case Rejected',
            translationKey: 'messageMenu.approvals.',
        }
    ],
    [
        Reporter['template.posting.request.approval'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on approving ',
            successMessage: 'Transaction sent, but requieres further investigation, Your approvement has been counted',
            translationKey: 'messageMenu.approvals.',
        }
    ],
    [
        Reporter['teammember.approval.needed'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error ',
            successMessage: 'User creation needs further approvement. Your approvement has been counted',
            translationKey: 'messageMenu.approvals.',
        }
    ],
    [
        Reporter['customers.person.put.approval'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error ',
            successMessage: 'Changing senstive person data needs further approvement. Your approvement has been counted',
            translationKey: '',
        }
    ],
    [
        Reporter['customers.email.put'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on changing Mail address',
            successMessage: 'Successfully changed Mail address',
            translationKey: 'messageMenu.approvals.',
        }
    ],
    [
        Reporter['customers.email.post'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on adding Mail address',
            successMessage: 'Successfully added Mail address',
            translationKey: 'messageMenu.approvals.',
        }
    ],
    [
        Reporter['person.merchant.put.product'],
            {
                errorMethods: { bubble: true },
                successMethods: { bubble: true },
                errorMessage: 'Error on adding Product',
                successMessage: 'Successfully added Product',
                translationKey: 'merchant.products.',
            }
    ],
    [
        Reporter['person.merchant.delete.product'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on deleting Product',
            successMessage: 'Successfully deleted Product',
            translationKey: 'merchant.products.',
        }
    ],
    [
        Reporter['person.merchant.address.put'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Successfully updated Address',
            successMessage: 'Error on Updating Address',
            translationKey: '',
        }
    ],
    [
        Reporter['person.fees.delete'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully deleted Feepackage',
            errorMessage: 'Error on deleting Feepackage',
            translationKey: '',
        }
    ],
    [
        Reporter['person.address.apprrove.post'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on setting Address state',
            successMessage: 'Successfully set Address status',
            translationKey: '',
        }
    ],
    [
        Reporter['account.qrcode.png.get'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error while downloading QR-Code',
            successMessage: 'QR-Code downloaded',
            translationKey: '',
        }
    ],
    [
        Reporter['backoffice.relaeses.put.trigger'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Proccess approved but Error on inital Event',
            successMessage: 'Process succesfully triggered',
            translationKey: '',
        }
    ],
    [
        Reporter['backoffice.relaeses.put.new_release'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Proccess approved but another Approval is nessecary',
            successMessage: 'Process succesfully triggered',
            translationKey: '',
        }
    ],
    [
        Reporter['backoffice.relaeses.put.not_all'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: ' ',
            successMessage: 'Proccess approved but Not all approvals triggerred a successfull proccess',
            translationKey: '',
        }
    ],
    [
        Reporter['backoffice.relaeses.put.retriggered.R'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Some Releases needs another release ',
            successMessage: 'Proccess approved but some proccesses triggered another Approval',
            translationKey: '',
        }
    ],
    [
        Reporter['backoffice.relaeses.put.retriggered.B'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Not all were successfully rejected',
            successMessage: 'All rejected',
            translationKey: '',
        }
    ],
    [
        Reporter['backoffice.relaeses.put.ident'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Some Releases for ident went into an error ',
            successMessage: 'Releases for Ident where successfully set',
            translationKey: '',
        }
    ],
    [
        Reporter['person.password.reset'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully triggered Passwordreset',
            errorMessage: 'Error on triggering Passwordreset',
            translationKey: '',
        }
    ],
    [
        Reporter['transaction.search.primanota'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: '',
            errorMessage: 'Not a valid primanota',
            translationKey: '',
        }
    ],
    [
        Reporter['account.person.get'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: '',
            errorMessage: 'Error fetching personid',
            translationKey: '',
        }
    ],
    [
        Reporter['account.person.get.multipleResults'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: '',
            errorMessage: 'Too many Results too directly show Person',
            translationKey: '',
        }
    ],
    [
        Reporter['product.put'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully edited Product',
            errorMessage: 'Error on saving Product',
            translationKey: '',
        }
    ],
    [
        Reporter['customer.voucher.set'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully saved Voucher',
            errorMessage: 'Error on saving Voucher',
            translationKey: '',
        }
    ],
    [
        Reporter['customers.vouchers.report'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully created Report. Downloading...',
            errorMessage: 'Error on creating Report',
            translationKey: '',
        }
    ],
    [
        Reporter['customers.product.image'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully added Image',
            errorMessage: 'Error on adding Image',
            translationKey: '',
        }
    ],
    [
        Reporter['customers.vouchers.missingParams'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Mandatory Parameter are missing',
        }
    ],
    [
        Reporter['customer.details.kyc.put.request'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on requesting KYC',
            successMessage: 'Successfully requested KYC'
        }
    ],
    [
        Reporter['customer.details.kyc.put.request.approval'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on requesting KYC',
            successMessage: 'Requesting KYC requieres approval'
        }
    ],
    [
        Reporter['customer.close.release'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on closing Account',
            successMessage: 'Close Account requieres Approval'
        }
    ],
    [
        Reporter['customer.close.noRelease'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on closing Account',
            successMessage: 'Successfully started Winddown-process'
        }
    ],
    [
        Reporter['person.address.apprrove.release'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Person not waiting for address check',
            successMessage: 'Successfully approved address'
        }
    ],
    [
        Reporter['person.advanced_kyc.approve.release'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on triggering action for advanced kyc',
            successMessage: 'Successfully triggered action for advanced kyc'
        }
    ],
    [
        Reporter['person.merchant.delete.paymentmethod'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on deleting Paymentmethod',
            successMessage: 'Successfully deleted Paymentmethod'
        }
    ],
    [
        Reporter['person.merchant.put.paymentmethod'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on adding Paymentmethod',
            successMessage: 'Successfully added Paymentmethod'
        }
    ],
    [
        Reporter['admin.clearing_file.post'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on uploading File',
            successMessage: 'Successfully uploaded File'
        }
    ],
    [
        Reporter['admin.clearing_file.get'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on downloading File',
            successMessage: 'Successfully downloaded File'
        }
    ],
    [
        Reporter['customer.transactions.invoice'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on requesting Invoice',
            successMessage: 'Successfully requested Invoice'
        }
    ],
    [
        Reporter['customer.advancedKyc.post'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on requesting advanced KYC',
            successMessage: 'Successfully requested advanced KYC'
        }
    ],
    [
        Reporter['product.voucher_amount'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Invalid Voucher amount',
            successMessage: ''
        }
    ],
    [
        Reporter['customer.merchants.time.invalid'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Invalid Time Format',
            successMessage: ''
        }
    ],
    [
        Reporter['person.merchant.put.calendar'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on saving Calendar',
            successMessage: 'Successfully saved Calendar'
        }
    ],
    [
        Reporter['person.merchant.productType.put'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on saving Producttype',
            successMessage: 'Successfully saved Producttype'
        }
    ],
    [
        Reporter['person.merchant.productType.delete'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on deleting Producttype',
            successMessage: 'Successfully deleted Producttype'
        }
    ],
    [
        Reporter['administration.feepackages.put'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on adding fee assignment',
            successMessage: 'Successfully added fee assignment'
        }
    ], 
    [
        Reporter['administration.feepackages.delete'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error on deleting fee assignment',
            successMessage: 'Successfully deleted fee assignment'
        }
    ],
    [
        Reporter['customer.close.reason.maxlength'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Maximum reason length exceeded (100 character)',
            successMessage: ''
        }
    ],
    [
        Reporter['videoqueue.approve.put'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error while changing Document state',
            successMessage: 'Successfully changed Document state'
        }
    ],
    [
        Reporter['videoqueue.approve.video.put'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error while approving video',
            successMessage: 'Successfully approved video'
        }
    ], 
    [
        Reporter['videoqueue.approve.result.put'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error while setting testresult',
            successMessage: 'Successfully set testresult'
        }
    ],
    [
        Reporter['videoqueue.take.error'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            errorMessage: 'Error while fetching data',
            successMessage: ''
        }
    ],
    [
        Reporter['fiskaly.create.post'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully created Organisation and TSE',
            errorMessage: 'Error on creating Organisation'
        }
    ],
    [
        Reporter['productmaintenance.change.category'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully updated Product categories',
            errorMessage: 'Error on updating Product categories'
        }
    ],
    [
        Reporter['person.address.requirement.post'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully set address requirement',
            errorMessage: 'Error on setting address requirement'
        }
    ],
    [
        Reporter['person.passport.document.put'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully uploaded documents',
            errorMessage: 'Error on uploading documents'
        }
    ],
    [
        Reporter['person.companion.connect.post'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully connected Persons',
            errorMessage: 'Error on connecting Persons'
        }
    ],
    [
        Reporter['product.image.delete'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully deleted Image',
            errorMessage: 'Error on deleting Image'
        }
    ],
    [
        Reporter['product.maintenance.config.put'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully set product config',
            errorMessage: 'Error on setting product config'
        }
    ],
    [
        Reporter['product.maintenance.config.delete'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully deleted product config',
            errorMessage: 'Error on deleting product config'
        }
    ],
    [
        Reporter['onfido.notification.put'],
        {
            errorMethods: { bubble: true },
            successMethods: { bubble: true },
            successMessage: 'Successfully toggled status',
            errorMessage: 'Error on Toggling status'
        }
    ],  
]);

