// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * - B => Block - N => No release - R => Release - U => Unblock
 * @export
 * @enum {string}
 */
export enum ReleaseAction {
    B = 'B',
    N = 'N',
    R = 'R',
    U = 'U'
}

