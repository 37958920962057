//@ts-nocheck
import React, { SyntheticEvent, ReactElement, ReactNode } from 'react';
import queryString from 'query-string';
import { withRouter, Link } from 'react-router-dom';
import AuthScreen, { IAuthScreenProps, IAuthScreenState } from '../authScreen';
import { IAuthConfig } from './IAuthConfig';
import { apis, ApiError, VoidResponse, Ident, api } from '../../../logic/api';

import { MessageHandler } from '../../../logic/handler/messagehandler/messageHandler';
import {
	Reporter,
	IMessageConfig,
	IStatusState,
	getFormComponents,
} from '../../../logic/handler/messagehandler/messageHandlerConfig';
import { translate } from '../../../common/language/translate';
import { StyledAuthComponent } from './auth.css';
import { ConfirmationComponent } from './confirmationComponent';
import { BigInput } from '../../../components/atomiccompoents/form/inputs.css';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';
import { LogoutTimer } from '../../../components/compositcomponents/logoutTimer';
import { FlexBox } from '../auth.css';
import { evaluateErrorMessage } from '../../../logic/helper/Common';

interface IProps extends IAuthScreenProps {}

interface IState extends IAuthScreenState, IStatusState {
	buttonDisabled: boolean;
	errorEmail?: string;
	errorPassword?: string;
	redirect: boolean;
	showComponent?: ReactElement;
	timerExceeded?: boolean;
	keyForLoadingSpinner?: number;
}

interface INotification {
	email: {
		value: string | undefined;
		error: string | undefined;
		valid: boolean;
	};
}

class EmailComponentClass extends AuthScreen<IProps, IState> {
	email: string | undefined = undefined;
	token: string | undefined = undefined;

	constructor(props: IProps) {
		super(props);

		this.state = {
			buttonDisabled: true,
			errorEmail: undefined,
			redirect: false,
			showComponent: undefined,
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
		};

		if (props.location != null && props.location.search != null) {
			const values = queryString.parse(props.location.search);

			if (values.email != null && values.token != null) {
				this.email = values.email instanceof Array ? values.email[0] : values.email;
			}
		}

		this.onSubmit = this.onSubmit.bind(this);
		this.cancel = this.cancel.bind(this);
	}

	onSubmit(event: React.KeyboardEvent<HTMLFormElement> | SyntheticEvent) {
		event.preventDefault();

		if (this.email != null) {
			this.setState({ keyForLoadingSpinner: Math.floor(Math.random() * 10000000) });
			if (this.state.errorEmail != null) {
				return;
			}

			const request: Ident.OauthResetpasswordRequest = {
				AuthForgotPasswordRequest: {
					idp_state: this.idpState,
					email_address: this.email,
				},
			};
			api.asyncRequest<VoidResponse>(
				request,
				apis.OpenIDConnectApi,
				'oauthResetpassword'
			)
				.then(() => {
					const currentAuthConfig: IAuthConfig | undefined = this.getAuthConfigById(
						'gotMail'
					);
					this.setState({
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
						showComponent: (
							<ConfirmationComponent currentAuthConfig={currentAuthConfig} />
						),
					});
				})
				.catch((error: ApiError) => {
					if (error.response != null && error.response.idp_state != null) {
						this.idpState = error.response.idp_state;
					}

					const conf: IMessageConfig = MessageHandler.onError(
                        Reporter['web.auth.password.post'],
                        evaluateErrorMessage(error, true), evaluateErrorMessage(error, false)
					);
					this.setState({
						errorMessage:
							conf.errorMessage != null ? conf.errorMessage : error.statusText,
						showInlineError: conf.errorMethods.inline,
						key: conf.translationKey != null ? conf.translationKey + '.error' : '',
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
				});
		} else if (this.state.timerExceeded === true) {
			const conf: IMessageConfig = MessageHandler.onError(
				Reporter['web.auth.password.timerExceeded']
			);
			this.setState({
				errorMessage: conf.errorMessage != null ? conf.errorMessage : 'Timer exceeded',
				showInlineError: conf.errorMethods.inline,
				key: conf.translationKey != null ? conf.translationKey + '.error' : '',
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			});
		} else {
			this.setState({
				errorEmail: 'Email is required',
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			});
		}
	}

	cancel(event: SyntheticEvent) {
		event.preventDefault();
		this.setState({ redirect: true });
	}

	refreshEmailError = () => {
		this.setState({ errorEmail: this.state.errorEmail });
	};

	notification(event: INotification) {
		if (event.email != null) {
			this.setState({ showInlineError: undefined });
			const mail = event.email;
			this.email = mail.value;
			if (mail.error == null && mail.value != null && mail.valid) {
				this.setState({
					buttonDisabled: this.state.timerExceeded === true,
					errorEmail: mail.error,
				});
			} else {
				this.setState({ buttonDisabled: true, errorEmail: mail.error });
			}
		}
	}

	onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
		// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			this.onSubmit(event);
		}
	};

	defineContent(): ReactNode {
		return null;
	}

	reviveState(): void {}

	render() {
		if (this.state.showComponent != null) {
			return this.state.showComponent;
		}
		return (
			<StyledAuthComponent>
				<div>{translate(this.getTitle())}</div>
				<div>{translate(this.getSubtitle() as string)}</div>
				<form
					onSubmit={(event: SyntheticEvent) => this.onSubmit(event)}
					onKeyDown={this.onKeyDown}>
					<BigInput
						id="email"
						type="email"
						label={translate('auth.resetPassword.input.email.label')}
						placeHolder={translate('auth.resetPassword.input.email.placeholder')}
						notification={(event: any) => this.notification(event)}
						showError={true}
						error={this.state.errorEmail}
						onSubmit={this.onSubmit}
					/>

					{getFormComponents(
						this.state.showInlineError,
						this.state.showInlineSuccess,
						this.state.errorMessage,
						this.state.successMessage,
						this.state.key
					)}
					<ButtonOk
						onClick={this.onSubmit}
						key={this.state.keyForLoadingSpinner}
						id="btnSignIn"
						style={{ width: '100%' }}
						disabled={this.state.buttonDisabled}>
						{translate('button.sendInformation')}
						<LogoutTimer
							onExpire={() =>
								this.setState({
									timerExceeded: true,
									buttonDisabled: true,
								})
							}
						/>
					</ButtonOk>
					<FlexBox>
						<Link to="/" id="cancel">
							{translate('button.cancel')}
						</Link>
					</FlexBox>
				</form>
			</StyledAuthComponent>
		);
	}
}

export const EmailComponent = withRouter(EmailComponentClass);
