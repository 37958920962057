//@ts-nocheck
import styled, { StyledComponent } from 'styled-components';

import { Input } from './input';

// Not fixable due to line length and styled-components patterns
// eslint-disable-next-line no-unexpected-multiline
export const StyledInput: StyledComponent<typeof Input, any, {}, never> = styled(Input)<{
	disabled?: boolean;
	warningBorder?: boolean;
}>`
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;

	color: ${props => props.theme.Global.darkFontColor};

	& > div > input,
	& > input {
		height: ${(props) => props.theme.Input.height};
		width: 360px;
		padding-left: 14px;
		margin-top: 14px;
		margin-bottom: 14px;
		font-family: Roboto;

		background: ${(props) =>
			props.disabled ? props.theme.Input.disabled : props.theme.Input.background};
		border: ${(props) =>
			props.warningBorder === true ? '1px solid grey' : props.theme.Input.border};
		box-sizing: ${(props) => props.theme.Input.boxSizing};
		box-shadow: ${(props) => props.theme.Input.boxShadow};
		border-radius: ${(props) => props.theme.Input.borderRadius};
	}
`;

export const BigInput: StyledComponent<typeof StyledInput, any, {}, never> = styled(
	StyledInput
)`
	input {
		
	}
	div {
		width: 100%;
	}
`;

export const MediumInput: StyledComponent<typeof StyledInput, any, {}, never> = styled(
	StyledInput
)`
	input {
		width: 85%;
	}
	div {
		width: 85%;
	}
`;
export const TinyInput: StyledComponent<typeof StyledInput, any, {}, never> = styled(
	StyledInput
)`
	input {
		width: 15%;
	}
	div {
		width: 15%;
		margin-left: 20px;
	}
`;

export const MedInput: StyledComponent<typeof StyledInput, any, {}, never> = styled(
	StyledInput
)`
	input {
		height: 28px;
		width: 360px;
	}
`;
