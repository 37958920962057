import React from "react";
import { ClientConfig } from "../../../../../common/config";
import { api, ApiError, apis, Ident } from "../../../../../logic/api";
import { INotifyAbleCustomerData } from "../../../../../logic/flux";
import { CustomerDataStore } from "../../../../../logic/flux/stores/customerDataStore";
import { InitialDataComponent } from "../../../../../logic/handler/initialdatahandler/initialDataComponent";
import { MessageHandler } from "../../../../../logic/handler/messagehandler/messageHandler";
import { Reporter } from "../../../../../logic/handler/messagehandler/messageHandlerConfig";
import { evaluateErrorMessage } from "../../../../../logic/helper/Common";
import { Log, Logs } from "../../../../../logic/log";
import CustomerDetailsEidVerification from "./CustomerDetailsEidVerification";
import CustomerDetailsPassport from "./CustomerDetailsPassport";

interface IProps {}

interface IState {
	eidData?: Ident.PersonVerification;
    selectedUser?: Ident.Person;
	is18?: boolean;
}

export default class CustomerDetailsIdentifcationDistributor extends React.Component<IProps, IState> 
    implements INotifyAbleCustomerData {
	constructor(props: IProps) {
		super(props);
		const person =  CustomerDataStore.getUser();
		let is18 = true;
		if (person != null) {
			const bDays = person.birth_date;
			const nDate = new Date();
			if(bDays != null) {
				const bDate = new Date(bDays);
				const diffYears  = nDate.getFullYear() 	- bDate.getFullYear();
				const diffMonths = nDate.getMonth()	 	- bDate.getMonth();
				const diffDays   = nDate.getDate() 		- bDate.getDate();
				if(!(  diffYears > 18                       ||
					(diffYears === 18 && diffMonths > 0 ) ||
					(diffYears === 18 && diffMonths === 0 && diffDays > 0) )) {
						is18 = false;
					}
			}
		}
		this.state = {
            selectedUser: person,
			is18: is18
		};
        this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
        this.fetchEidData = this.fetchEidData.bind(this);
    }

	_onChangeCustomerData() {
		this.setState({
			selectedUser: CustomerDataStore.getUser(),
		});
	}

    componentDidMount(): void {
		CustomerDataStore.addChangeListener(this._onChangeCustomerData);
		this._onChangeCustomerData();
		if(ClientConfig.fetchEid) {
			this.fetchEidData();
		}

	}

	componentWillUnmount() {
		CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
	}


	fetchEidData(): void {
		if(this.state.selectedUser == null) {
			return;
		}
		const personId = this.state.selectedUser.person_id;
		api.asyncRequest<any>(
			{person_id: personId},
			apis.MaintenanceApi,
			'personElectronicidVerificationGet'
		).then((response) => {
			if (response != null) {
				this.setState({
					eidData: response
				})
			}
		})
		.catch((error: ApiError) => {
			Log.error(Logs.API, error);
			MessageHandler.onError(Reporter['customer.details.identification'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
		});
	}

    render() {
        if(this.state.selectedUser == null) {
            return null;
        }										
        if(this.state.eidData != null && this.state.eidData.waiting_for_approval === true && this.state.selectedUser.family_relation !== 'junior') {
            return <CustomerDetailsEidVerification 
                        person_id={this.state.selectedUser.person_id} 
                        state={this.state.selectedUser.state} 
                        eidData={this.state.eidData}
                        approveCallback={() => this.fetchEidData()}
						is18={this.state.is18} />
        } else {
            return <CustomerDetailsPassport person={this.state.selectedUser} 
                                            changeCallback={() => this.fetchEidData()} 
                                            eidData={this.state.eidData}
											is18={this.state.is18}/>
        }
    }

}