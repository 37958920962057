//@ts-nocheck
import React, { SyntheticEvent, ReactElement, ReactNode } from 'react';
import { Link, withRouter } from 'react-router-dom';
import AuthScreen, { IAuthScreenProps, IAuthScreenState } from '../authScreen';
import { IAuthConfig } from './IAuthConfig';
import { MessageHandler } from '../../../logic/handler/messagehandler/messageHandler';
import {
	Reporter,
	IMessageConfig,
	IStatusState,
	getFormComponents,
} from '../../../logic/handler/messagehandler/messageHandlerConfig';
import { StyledAuthComponent } from './auth.css';
import { translate } from '../../../common/language/translate';
import { Ident, VoidResponse, api, apis, ApiError } from '../../../logic/api';
import { ProcessCompleteComponent } from './processCompleteComponent';
import { IOverlayMessage } from '../../../components/compositcomponents/popup/overlay';
import { BigInput } from '../../../components/atomiccompoents/form/inputs.css';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';
import { FlexBox } from '../auth.css';
import { evaluateErrorMessage } from '../../../logic/helper/Common';

interface IProps extends IAuthScreenProps {
	notifyParentObject?: (message: IOverlayMessage) => void;
}

interface IState extends IAuthScreenState, IStatusState {
	redirect: boolean;
	password?: string;
	password2?: string;
	showComponent?: ReactElement;
	keyForLoadingSpinner?: number;
}

class PasswordComponentClass extends AuthScreen<IProps, IState> {
	password: string = '';

	constructor(props: IProps) {
		super(props);

		this.state = {
			...props,
			redirect: false,
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
		};

		this.cancel = this.cancel.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(event: SyntheticEvent | React.KeyboardEvent<HTMLFormElement>) {
		event.preventDefault();

		if (this.state.password == null && this.state.password2 == null) {
			const conf: IMessageConfig = MessageHandler.onError(
				Reporter['web.auth.login.error.nopassword']
			);
			this.setState({
				errorMessage:
					conf.errorMessage != null
						? conf.errorMessage
						: translate('auth.password.inValidPasswordError'),
				showInlineError: conf.errorMethods.inline,
				key: conf.translationKey != null ? conf.translationKey + '.error' : '',
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			});
		} else if (
			this.state.password == null ||
			this.state.password2 == null ||
			this.state.password !== this.state.password2
		) {
			const conf: IMessageConfig = MessageHandler.onError(
				Reporter['web.auth.resetpassword.error.nomatch']
			);
			this.setState({
				errorMessage:
					conf.errorMessage != null
						? conf.errorMessage
						: translate('auth.password.notMatchingError'),
				showInlineError: conf.errorMethods.inline,
				key: conf.translationKey != null ? conf.translationKey + '.error' : '',
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			});
		} else {
			const request: Ident.OauthSetPasswordPostRequest = {
				AuthRequestWithPassword: {
					idp_state: this.idpState,
					password: this.state.password,
				},
			};
			api.asyncRequest<VoidResponse | Ident.AuthParams>(
				request,
				apis.OpenIDConnectApi,
				'oauthSetPasswordPost'
			)
				.then((response) => {
					this.setState({
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
					if (response instanceof VoidResponse) {
						window.location.reload();
					} else {
						const conf = MessageHandler.onSuccess(
							Reporter['web.auth.password.post']
						);
						this.idpState = response.idp_state;
						const currentAuthConfig:
							| IAuthConfig
							| undefined = this.getAuthConfigById('processComplete');
						this.setState({
							showComponent: (
								<ProcessCompleteComponent
									authConfig={this.props.authConfig}
									currentAuthConfig={currentAuthConfig}
								/>
							),
							showInlineSuccess:
								conf.successMethods != null && conf.successMethods.inline,
							successMessage:
								conf.successMessage != null ? conf.successMessage : '',
							key:
								conf.translationKey != null
									? conf.translationKey + '.success'
									: '',
							keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
						});
					}
				})
				.catch((error: ApiError) => {
					const errorconf: IMessageConfig = MessageHandler.onError(
                        Reporter['web.auth.password.post'],
						evaluateErrorMessage(error, true), 
						evaluateErrorMessage(error, false)
					);
					if (error.response != null && error.response.idp_state != null) {
						this.idpState = error.response.idp_state;
					}
					this.setState({
						errorMessage: error.statusText,
						showInlineError: errorconf.errorMethods.inline === true,
						key:
							errorconf.translationKey != null
								? errorconf.translationKey + '.error'
								: '',
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
				});
		}
	}

	private handleInputChange(notification: any): void {
		if (notification != null) {
			if (notification.password != null) {
				this.setState({ password: notification.password.value });
			}
			if (notification.passwordRepeat != null) {
				this.setState({ password2: notification.passwordRepeat.value });
			}
		}
	}

	cancel(event?: SyntheticEvent) {
		if (event != null) {
			event.preventDefault();
		}

		if (this.props.notifyParentObject != null) {
			this.props.notifyParentObject({
				changePopUpComponent: null,
				newPopUpComponent: null,
				isShowing: false,
				messageForParentObject: false,
			});
		}
	}

	consume(event: SyntheticEvent) {
		event.preventDefault();
		event.stopPropagation();
	}

	onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
		// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			this.onSubmit(event);
		}
	};

	reviveState(): void {}

	render(): ReactNode {
		if (this.state.showComponent != null) {
			return this.state.showComponent;
		}

		return (
			<StyledAuthComponent>
				<div>{translate(this.getTitle())}</div>
				<div>{translate(this.getSubtitle() as string)}</div>
				<form onSubmit={this.onSubmit} onKeyDown={this.onKeyDown}>
					<BigInput
						label={translate('auth.password.input.password.label')}
						placeHolder={translate('auth.password.input.password.placeholder')}
						type="password"
						id="password"
						showError={true}
						showStrength={true}
						notification={(notification: any) =>
							this.handleInputChange(notification)
						}
						onSubmit={this.onSubmit}
					/>
					<BigInput
						label={translate('auth.password.input.password2.label')}
						placeHolder={translate('auth.password.input.password2.placeholder')}
						type="password"
						id="passwordRepeat"
						showError={true}
						notification={(notification: any) =>
							this.handleInputChange(notification)
						}
						onSubmit={this.onSubmit}
					/>
					{getFormComponents(
						this.state.showInlineError,
						this.state.showInlineSuccess,
						this.state.errorMessage,
						this.state.successMessage,
						this.state.key
					)}
					<ButtonOk
						id="passwordComponentOk"
						style={{ width: '100%' }}
						onClick={this.onSubmit}
						key={this.state.keyForLoadingSpinner}>
						{translate('button.next')}
					</ButtonOk>
					<FlexBox>
						<Link to="/" id="cancel">
							{translate('button.cancel')}
						</Link>
					</FlexBox>
				</form>
			</StyledAuthComponent>
		);
	}
}

export const PasswordComponent = withRouter(PasswordComponentClass);
