// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    CountryCodeAlpha2,
    Product,
    ProductImage,
    ProductMethodTaken,
    ProductOrderLanguage,
    ProductTestReason,
} from '../models';
import { VoidResponse } from '../..';

export interface ProductImageGetRequest {
    product_image_id: number;
    filename: string;
}

export interface ProductImagePostRequest {
    product_image: Blob;
}

export interface ProductMethodsTakenGetRequest {
    product_id?: number;
}

export interface ProductTestReasonsGetRequest {
    product_id?: number;
    merchant_account_number?: string;
}

export interface ProductsLanguagesPutRequest {
    ProductOrderLanguage?: ProductOrderLanguage;
}

export interface ProductsPassportPutRequest {
    merchant_account_number: string;
    ta_id: number;
    transaction_id: number;
    passport_number: string;
    nationality?: CountryCodeAlpha2;
}

export interface ProductsPutRequest {
    Product?: Product;
}

/**
 * no description
 */
export class ProductsApi extends BaseAPI implements IOperationIdMap {

    /**
     * get a specific product image
     * get a product image
     */
    productImageGet = (requestParameters: ProductImageGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'product_image_id', 'productImageGet');
        throwIfRequired(requestParameters, 'filename', 'productImageGet');

        return this.request<Blob>({
            path: '/products/image/{product_image_id}/{filename}'.replace('{product_image_id}', encodeURI(requestParameters.product_image_id)).replace('{filename}', encodeURI(requestParameters.filename)),
            method: 'GET',
            responseType: 'blob'
        });
    };

    productImageDelete = (requestParameters: {product_image_id}): Observable<VoidResponse> => {
        throwIfRequired(requestParameters, 'product_image_id', 'productImageDelete');
        const query: HttpQuery = {
            ...(requestParameters.product_image_id && { 'product_image_id': requestParameters.product_image_id }),
        };
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };
        return this.request<any>({
            path: '/products/image',
            method: 'DELETE',
            headers,
            query
        });
    };

    /**
     * add a new product image to be use
     * create product image
     */
    productImagePost = (requestParameters: ProductImagePostRequest): Observable<ProductImage> => {
        throwIfRequired(requestParameters, 'product_image', 'productImagePost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.product_image !== undefined) {
            formData.append('product_image', requestParameters.product_image as any);
        }

        return this.request<ProductImage>({
            path: '/products/image',
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * get a list of product images available
     * list of product images
     */
    productImagesList = (): Observable<Array<ProductImage>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ProductImage>>({
            path: '/products/images',
            method: 'GET',
            headers,
        });
    };

    /**
     * get a list of all test methods
     * list of test methods
     */
    productMethodsTakenGet = (requestParameters: ProductMethodsTakenGetRequest): Observable<Array<ProductMethodTaken>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.product_id && { 'product_id': requestParameters.product_id }),
        };

        return this.request<Array<ProductMethodTaken>>({
            path: '/products/methods_taken',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get a list of all test reasons
     * list of reasons for test
     */
    productTestReasonsGet = (requestParameters: ProductTestReasonsGetRequest): Observable<Array<ProductTestReason>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.product_id && { 'product_id': requestParameters.product_id }),
            ...(requestParameters.merchant_account_number && { 'merchant_account_number': requestParameters.merchant_account_number }),
        };

        return this.request<Array<ProductTestReason>>({
            path: '/products/test_reasons',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get a list of products available for purchase
     * list of products available
     */
    productsGet = (): Observable<Array<Product>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Product>>({
            path: '/products',
            method: 'GET',
            headers,
        });
    };

    /**
     * add or change language combination for ordererd product
     * language combination for ordererd product
     */
    productsLanguagesPut = (requestParameters: ProductsLanguagesPutRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/products/languages',
            method: 'PUT',
            headers,
            body: requestParameters.ProductOrderLanguage,
        });
    };

    /**
     * add or change pasport number for ordererd product
     * passport number for ordererd product
     */
    productsPassportPut = (requestParameters: ProductsPassportPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'merchant_account_number', 'productsPassportPut');
        throwIfRequired(requestParameters, 'ta_id', 'productsPassportPut');
        throwIfRequired(requestParameters, 'transaction_id', 'productsPassportPut');
        throwIfRequired(requestParameters, 'passport_number', 'productsPassportPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.merchant_account_number && { 'merchant_account_number': requestParameters.merchant_account_number }),
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
            ...(requestParameters.passport_number && { 'passport_number': requestParameters.passport_number }),
            ...(requestParameters.nationality && { 'nationality': requestParameters.nationality }),
        };

        return this.request<void>({
            path: '/products/passport',
            method: 'PUT',
            headers,
            query,
        });
    };

    /**
     * update a product by its id
     * update a products data
     */
    productsPut = (requestParameters: ProductsPutRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/products',
            method: 'PUT',
            headers,
            body: requestParameters.Product,
        });
    };


    operationToOperationId = {
	    "productImageGet": "product_image_get" ,
	    "productImagePost": "product_image_post" ,
	    "productImagesList": "product_images_list" ,
	    "productMethodsTakenGet": "product_methods_taken_get" ,
	    "productTestReasonsGet": "product_test_reasons_get" ,
	    "productsGet": "products_get" ,
	    "productsLanguagesPut": "products_languages_put" ,
	    "productsPassportPut": "products_passport_put" ,
	    "productsPut": "products_put"
    }
}
