// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Iso4217CurrencyOUT,
} from './';

/**
 * @export
 * @interface PostingJournalTMoneyStatements
 */
export interface PostingJournalTMoneyStatements {
    /**
     * first part of transaction identifier, transacting account
     * @type {number}
     * @memberof PostingJournalTMoneyStatements
     */
    ta_id: number;
    /**
     * unique statement_id per account
     * @type {number}
     * @memberof PostingJournalTMoneyStatements
     */
    statement_id: number;
    /**
     * the date part of booking timestamp, for legacy reasons
     * @type {Date}
     * @memberof PostingJournalTMoneyStatements
     */
    booking_date: Date;
    /**
     * official booking timestamp
     * @type {Date}
     * @memberof PostingJournalTMoneyStatements
     */
    booking_datetime: Date;
    /**
     * timestamp, the transaction was technically booked
     * @type {Date}
     * @memberof PostingJournalTMoneyStatements
     */
    sysdate: Date;
    /**
     * value date of the statement, relevant for interes calculation
     * @type {Date}
     * @memberof PostingJournalTMoneyStatements
     */
    value_date: Date;
    /**
     * timestamp the transaction acualhappened in reality
     * @type {Date}
     * @memberof PostingJournalTMoneyStatements
     */
    transaction_datetime: Date;
    /**
     * the subbalance this statement was used to calulate
     * @type {string}
     * @memberof PostingJournalTMoneyStatements
     */
    subbalance: string;
    /**
     * @type {string}
     * @memberof PostingJournalTMoneyStatements
     */
    dc: PostingJournalTMoneyStatementsDcEnum;
    /**
     * the statements amnount. please note that, in oder to get the actual movement direction a sign correction using debit/credit might be needed, dependiong on clients needs
     * @type {number}
     * @memberof PostingJournalTMoneyStatements
     */
    amount: number;
    /**
     * @type {Iso4217CurrencyOUT}
     * @memberof PostingJournalTMoneyStatements
     */
    currency: Iso4217CurrencyOUT;
    /**
     * the account this statement belongs to
     * @type {string}
     * @memberof PostingJournalTMoneyStatements
     */
    account_number: string;
    /**
     * if account has a memo, it is shown here
     * @type {string}
     * @memberof PostingJournalTMoneyStatements
     */
    memo?: string;
    /**
     * account name
     * @type {string}
     * @memberof PostingJournalTMoneyStatements
     */
    description?: string;
    /**
     * user provided text for the transaction
     * @type {string}
     * @memberof PostingJournalTMoneyStatements
     */
    statement_text?: string;
    /**
     * identifies the type of statement
     * @type {number}
     * @memberof PostingJournalTMoneyStatements
     */
    txkey_id?: number;
    /**
     * human readable description of the txkey_id
     * @type {string}
     * @memberof PostingJournalTMoneyStatements
     */
    txkey_description?: string;
    /**
     * @type {boolean}
     * @memberof PostingJournalTMoneyStatements
     */
    write_tx_limit?: boolean;
    /**
     * @type {boolean}
     * @memberof PostingJournalTMoneyStatements
     */
    check_tx_limit?: boolean;
    /**
     * @type {boolean}
     * @memberof PostingJournalTMoneyStatements
     */
    check_balance_limit?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum PostingJournalTMoneyStatementsDcEnum {
    D = 'D',
    C = 'C'
}

