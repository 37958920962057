// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    NistImpressionType,
    NistPosition,
} from './';

/**
 * a fingerprint
 * @export
 * @interface NewFingerprint
 */
export interface NewFingerprint {
    /**
     * @type {Blob}
     * @memberof NewFingerprint
     */
    data: Blob;
    /**
     * @type {string}
     * @memberof NewFingerprint
     */
    type: NewFingerprintTypeEnum;
    /**
     * @type {NistPosition}
     * @memberof NewFingerprint
     */
    nist_position: NistPosition;
    /**
     * @type {NistImpressionType}
     * @memberof NewFingerprint
     */
    nist_impression_type: NistImpressionType;
}

/**
 * @export
 * @enum {string}
 */
export enum NewFingerprintTypeEnum {
    BMP = 'BMP',
    PNG = 'PNG',
    WSQ = 'WSQ',
    JP2 = 'JP2'
}

