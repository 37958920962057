// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, COLLECTION_FORMATS } from '../runtime';

export interface EterminAppointmentGetRequest {
    id?: string;
    start?: Date;
    end?: Date;
    calendar_type?: EterminAppointmentGetCalendarTypeEnum;
}

export interface EterminTimeslotsGetRequest {
    date: Date;
    calendarid: number;
    rangesearch?: number;
    end?: Date;
    serviceid?: Array<string>;
    calendar_type?: EterminTimeslotsGetCalendarTypeEnum;
    capacity?: number;
    languageid?: string;
    duration?: number;
    cluster?: number;
}

/**
 * no description
 */
export class EterminNetApi extends BaseAPI implements IOperationIdMap {

    /**
     */
    eterminAppointmentGet = (requestParameters: EterminAppointmentGetRequest): Observable<void> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.id && { 'id': requestParameters.id }),
            ...(requestParameters.start && { 'start': (requestParameters.start as any).toISOString().substring(0, 10) }),
            ...(requestParameters.end && { 'end': (requestParameters.end as any).toISOString().substring(0, 10) }),
            ...(requestParameters.calendar_type && { 'calendar_type': requestParameters.calendar_type }),
        };

        return this.request<void>({
            path: '/etermin.net/api/appointment',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    eterminTimeslotsGet = (requestParameters: EterminTimeslotsGetRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'date', 'eterminTimeslotsGet');
        throwIfRequired(requestParameters, 'calendarid', 'eterminTimeslotsGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date && { 'date': (requestParameters.date as any).toISOString().substring(0, 10) }),
            ...(requestParameters.rangesearch && { 'rangesearch': requestParameters.rangesearch }),
            ...(requestParameters.end && { 'end': (requestParameters.end as any).toISOString().substring(0, 10) }),
            ...(requestParameters.serviceid && { 'serviceid': requestParameters.serviceid.join(COLLECTION_FORMATS['csv']) }),
            ...(requestParameters.calendarid && { 'calendarid': requestParameters.calendarid }),
            ...(requestParameters.calendar_type && { 'calendar_type': requestParameters.calendar_type }),
            ...(requestParameters.capacity && { 'capacity': requestParameters.capacity }),
            ...(requestParameters.languageid && { 'languageid': requestParameters.languageid }),
            ...(requestParameters.duration && { 'duration': requestParameters.duration }),
            ...(requestParameters.cluster && { 'cluster': requestParameters.cluster }),
        };

        return this.request<void>({
            path: '/etermin.net/api/timeslots',
            method: 'GET',
            headers,
            query,
        });
    };


    operationToOperationId = {
	    "eterminAppointmentGet": "etermin_appointment_get" ,
	    "eterminTimeslotsGet": "etermin_timeslots_get"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum EterminAppointmentGetCalendarTypeEnum {
    E = 'E',
    N = 'N'
}
/**
 * @export
 * @enum {string}
 */
export enum EterminTimeslotsGetCalendarTypeEnum {
    E = 'E',
    N = 'N'
}
