import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Icons } from '../../images';
import { Log, Logs } from '../../logic/log';

const StyledWrapper = styled.div``;

const StyledDiv = styled.div`
	display: flex;
	align-items: center;
	img,
	svg {
		height: 28px;
		width: 28px;
		fill: ${(props) => props.theme.Global.lightFontColor};
	}
`;

const StyledSpan = styled.span`
	margin-left: 16px;
	font-weight: ${(props) => props.theme.Title.fontWeight};
	text-transform: ${(props) => props.theme.Title.textTransform};
	color: ${(props) => props.theme.Title.color};
	font-style: ${(props) => props.theme.Title.fontStyle};
	font-size: ${(props) => props.theme.Title.fontSize};
	line-height: ${(props) => props.theme.Title.lineHeight};
	flex-grow: 1;
`;

const StyledHr = styled.hr`
	margin-top: ${(props) => props.theme.Title.spaceBetween};
	border: ${(props) => props.theme.Title.line};
	margin-bottom: 16px;
`;

interface IProps {
	title: string;
	icon?: ReactElement | null;
	minimize?: boolean;
	maximize?: boolean;
	close?: boolean;
	onMinimize?: () => void;
	onMaximize?: () => void;
	onClose?: () => void;
}

interface IState extends IProps {
    title: string;
}

export default class Title extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			...props,
		};
		this._onMinimize = this._onMinimize.bind(this);
		this._onMaximize = this._onMaximize.bind(this);
		this._onClose = this._onClose.bind(this);
	}
    componentWillReceiveProps(props: IProps) {
        this.setState({
            title: props.title
        })
    }
 	private _onMinimize(): void {
		Log.debug(Logs.COMPONENT, 'Title -> minimize');
		if (this.props.onMinimize != null && typeof this.props.onMinimize === 'function') {
			this.props.onMinimize();
		}
	}

	private _onMaximize(): void {
		Log.debug(Logs.COMPONENT, 'Title -> maximize');
		if (this.props.onMaximize != null && typeof this.props.onMaximize === 'function') {
			this.props.onMaximize();
		}
	}

	private _onClose(): void {
		Log.debug(Logs.COMPONENT, 'Title -> close');
		if (this.props.onClose != null && typeof this.props.onClose === 'function') {
			this.props.onClose();
		}
	}

	render() {
		return (
			<StyledWrapper>
				<StyledDiv>
					{this.state.icon == null ? '' : this.state.icon}
					<StyledSpan>{this.state.title}</StyledSpan>
					{this.props.minimize !== true ? null : Icons.minimize(this._onMinimize)}
					{this.props.maximize !== true ? null : Icons.maximize(this._onMaximize)}
					{this.props.close !== true ? null : Icons.close(this._onClose)}
				</StyledDiv>
			</StyledWrapper>
		);
	}
}
