// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CreditorAccountSelection,
} from './';

/**
 * a request card-takeover request if a stored favorite was used, put its id into favorite_id  please note the counterpartyName will be ignored and is just here for compatibility reasons
 * @export
 * @interface CardTakeoverRequest
 */
export interface CardTakeoverRequest {
    /**
     * @type {string}
     * @memberof CardTakeoverRequest
     */
    own_account: string;
    /**
     * @type {String | creditorAccountSelection}
     * @memberof CardTakeoverRequest
     */
    counterparty_account: String | CreditorAccountSelection;
    /**
     * Creditor Name
     * @type {string}
     * @memberof CardTakeoverRequest
     */
    counterparty_name?: string;
    /**
     * Unstructured remittance information 
     * @type {string}
     * @memberof CardTakeoverRequest
     */
    remittance_information_unstructured?: string;
    /**
     * external reference as provided by the initiaing application
     * @type {string}
     * @memberof CardTakeoverRequest
     */
    external_reference?: string;
    /**
     * @type {string}
     * @memberof CardTakeoverRequest
     */
    offer_or_request: CardTakeoverRequestOfferOrRequestEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CardTakeoverRequestOfferOrRequestEnum {
    R = 'R',
    O = 'O'
}

