import React from 'react';
import {
	AOverlay,
	IPropsOver,
	IStateOver,
} from '../../../logic/handler/overlayhandler/globaloverlays/aOverlay';
import styled from 'styled-components';
import { translate } from '../../../common/language/translate';

interface IProps extends IPropsOver {}

interface IState extends IStateOver {}

export default class Notifications extends AOverlay<IProps, IState> {
	reviveState(): void {}

	defineContent() {
		return (
			<Container>
				<Title>{translate('notifications.overlay.title')}</Title>
				<NotificationContainer></NotificationContainer>
			</Container>
		);
	}
}

const Container = styled.div`
	width: 500px;
	height: 764px;
	position: fixed;
	top: 0px;
	right: 260px;
	z-index: 300;

	background-color: #fafafa;
`;

const Title = styled.div`
	display: flex;
	width: 100%;
	height: 64px;
	align: center;
	justify-content: center;

	background-color: #fff;

	color: #4f5f8e;
	font-weight: 800;
	font-size: 24px;
	line-height: 28px;
	box-shadow: ${(props) => (props.theme.Box.boxShadow)};
`;

const NotificationContainer = styled.div`
	width: 100%;
	height: calc(100% - 64px);

	overflow-x: hidden;
	overflow-y: auto;
	box-sizing: border-box;
	padding: 20px;
`;
