import { InitialDataComponent } from './initialDataComponent';

class InitComponentHandlerClass {
	static instance: InitComponentHandlerClass | null;
	initComponents: Map<string, InitialDataComponent<any, any>>;
	constructor() {
		this.initComponents = new Map();
	}

	static getInstance() {
		if (this.instance == null) {
			return new InitComponentHandlerClass();
		}
		return this.instance;
	}

	register(comp: InitialDataComponent<any, any>, name: string) {
		this.initComponents.set(name, comp);
	}

	trigger() {
		this.initComponents.forEach((value: InitialDataComponent<any, any>, key: string) => {
			value.safe(value, Object.keys(value.state), key);
		});
	}

	cleanUp(key: string) {
		this.initComponents.delete(key);
	}
}

export const InitComponentHandler = InitComponentHandlerClass.getInstance();
