// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface SumupPaymentDetails
 */
export interface SumupPaymentDetails {
    /**
     * @type {string}
     * @memberof SumupPaymentDetails
     */
    affiliate_key?: string;
    /**
     * @type {string}
     * @memberof SumupPaymentDetails
     */
    payment_method?: SumupPaymentDetailsPaymentMethodEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum SumupPaymentDetailsPaymentMethodEnum {
    Sumup = 'sumup'
}

