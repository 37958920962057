// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    CheckInCheckOutList,
    CustomerTransactionList,
    ExpectedCustomerList,
    ReportResponseType,
} from '../models';

export interface Rpt003BalanceHistoryRequest {
    account_number: string;
    start_date: Date;
    end_date: Date;
    balance_type: Rpt003BalanceHistoryBalanceTypeEnum;
    response_type?: ReportResponseType;
}

export interface Rpt010CustomerTransactionListRequest {
    email_address: string;
    date_from: Date;
    date_to: Date;
    account_number?: string;
    response_type?: ReportResponseType;
}

export interface Rpt013CheckinCheckoutListRequest {
    account_number: string;
    date_from: Date;
    date_to: Date;
    response_type?: ReportResponseType;
}

export interface Rpt018CustomerOverviewListRequest {
    date_from: Date;
    date_to: Date;
    account_number: string;
    email_address?: string;
    order_by?: Rpt018CustomerOverviewListOrderByEnum;
    response_type?: ReportResponseType;
}

/**
 * no description
 */
export class ReportCustomerApi extends BaseAPI implements IOperationIdMap {

    /**
     * shows the balance for an account for each day in the requested date range
     * show balance history of one account
     */
    rpt003BalanceHistory = (requestParameters: Rpt003BalanceHistoryRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'rpt003BalanceHistory');
        throwIfRequired(requestParameters, 'start_date', 'rpt003BalanceHistory');
        throwIfRequired(requestParameters, 'end_date', 'rpt003BalanceHistory');
        throwIfRequired(requestParameters, 'balance_type', 'rpt003BalanceHistory');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.account_number && { 'account_number': requestParameters.account_number }),
            ...(requestParameters.start_date && { 'start_date': (requestParameters.start_date as any).toISOString().substring(0, 10) }),
            ...(requestParameters.end_date && { 'end_date': (requestParameters.end_date as any).toISOString().substring(0, 10) }),
            ...(requestParameters.balance_type && { 'balance_type': requestParameters.balance_type }),
        };

        return this.request<void>({
            path: '/report/customer/003_balance_history',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list all transactions of customer or account
     */
    rpt010CustomerTransactionList = (requestParameters: Rpt010CustomerTransactionListRequest): Observable<CustomerTransactionList> => {
        throwIfRequired(requestParameters, 'email_address', 'rpt010CustomerTransactionList');
        throwIfRequired(requestParameters, 'date_from', 'rpt010CustomerTransactionList');
        throwIfRequired(requestParameters, 'date_to', 'rpt010CustomerTransactionList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.email_address && { 'email_address': requestParameters.email_address }),
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
            ...(requestParameters.account_number && { 'account_number': requestParameters.account_number }),
        };

        return this.request<CustomerTransactionList>({
            path: '/report/customer/010_customer_transaction_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Check-ins and check-out for specific merchant
     */
    rpt013CheckinCheckoutList = (requestParameters: Rpt013CheckinCheckoutListRequest): Observable<CheckInCheckOutList> => {
        throwIfRequired(requestParameters, 'account_number', 'rpt013CheckinCheckoutList');
        throwIfRequired(requestParameters, 'date_from', 'rpt013CheckinCheckoutList');
        throwIfRequired(requestParameters, 'date_to', 'rpt013CheckinCheckoutList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.account_number && { 'account_number': requestParameters.account_number }),
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<CheckInCheckOutList>({
            path: '/report/bo/013_checkin_checkout_list',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * All booked appointments
     */
    rpt018CustomerOverviewList = (requestParameters: Rpt018CustomerOverviewListRequest): Observable<ExpectedCustomerList> => {
        throwIfRequired(requestParameters, 'date_from', 'rpt018CustomerOverviewList');
        throwIfRequired(requestParameters, 'date_to', 'rpt018CustomerOverviewList');
        throwIfRequired(requestParameters, 'account_number', 'rpt018CustomerOverviewList');

        const headers: HttpHeaders = {
            ...(requestParameters.response_type && { 'response_type': String(requestParameters.response_type) }),
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
            ...(requestParameters.account_number && { 'account_number': requestParameters.account_number }),
            ...(requestParameters.email_address && { 'email_address': requestParameters.email_address }),
            ...(requestParameters.order_by && { 'order_by': requestParameters.order_by }),
        };

        return this.request<ExpectedCustomerList>({
            path: '/report/bo/018_customer_overview_list',
            method: 'GET',
            headers,
            query,
        });
    };


    operationToOperationId = {
	    "rpt003BalanceHistory": "rpt003_balance_history" ,
	    "rpt010CustomerTransactionList": "rpt010_customer_transaction_list" ,
	    "rpt013CheckinCheckoutList": "rpt013_checkin_checkout_list" ,
	    "rpt018CustomerOverviewList": "rpt018_customer_overview_list"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum Rpt003BalanceHistoryBalanceTypeEnum {
    B = 'B',
    V = 'V',
    T = 'T'
}
/**
 * @export
 * @enum {string}
 */
export enum Rpt018CustomerOverviewListOrderByEnum {
    SysDateTimestamp = 'sys_date_timestamp',
    AppointmentTimestamp = 'appointment_timestamp'
}
