//@ts-nocheck
import React from 'react';
import BackOfficeSearch from './backoffice/BackOfficeSearch';
import BackOfficeDetails from './backoffice/BackOfficeDetails';
import BackofficeReportsComponent from './backoffice/BackofficeReportComponent';
import BackofficeTemplateComponent from './backoffice/BackofficeTemplateComponent';
import { IItfAccount } from '../../../logic/types';
import { INotifyAbleBackofficeData, BackofficeStore } from '../../../logic/flux';
import { Log, Logs } from '../../../logic/log';
import Tabs, { ITabvalues } from '../../../components/atomiccompoents/tabs/tabs';
import { translate } from '../../../common/language/translate';
import BackofficeApprovals from './backoffice/backofficeApprovals';
import CustomerTransactionSearch from './customers/customerTransactionSearch';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ClientConfig } from '../../../common/config';
import CustomerVouchers from './customers/CustomerVouchers';
import { Ident } from '../../../logic/api';
import CustomerOpenApprovals from './customers/CustomerOpenApprovals';
import VideoApprovalQueue from './serviceCenter/videoApprovalProcess/idApprovalQueue';

interface IProps extends RouteComponentProps{}

interface IState {
	selectedAccount?: IItfAccount;
}

class BackOfficeComponent extends React.Component<IProps, IState>
	implements INotifyAbleBackofficeData {
	constructor(props: IProps) {
		super(props);

		this.state = {
			selectedAccount: BackofficeStore.getAccount(),
		};

        this._onChangeBackofficeData = this._onChangeBackofficeData.bind(this);
        this.redirect = this.redirect.bind(this);
	}

	componentDidMount(): void {
		BackofficeStore.addChangeListener(this._onChangeBackofficeData);
		this._onChangeBackofficeData();
	}

	componentWillUnmount(): void {
		BackofficeStore.removeChangeListener(this._onChangeBackofficeData);
    }
    
    redirect(path: string) {
        this.props.history.push(path);
    }

	_onChangeBackofficeData(): void {
		this.setState(
			{
				selectedAccount: BackofficeStore.getAccount(),
			},
			() => {
				Log.debug(Logs.COMPONENT, 'BackofficeComponent -> state changed');
				Log.debug(Logs.COMPONENT, this.state.selectedAccount);
			}
		);
	}

    render() {
        const tabValuesnoAccount: Array<ITabvalues> = [
            { 
                name: 'search',
                title: translate('backoffice.search.title'),
                path: '/backoffice/search',
                callback:( props => {
                    Log.info(Logs.COMPONENT, 'Showing backoffice search');
                    return <BackOfficeSearch />;
            })},
            {
				title: translate('backoffice.search.searchTrans'),
				path: '/backoffice/transactionlookup',
				name: 'transactionlookup',
			    callback: () => { return <CustomerTransactionSearch callback={this.redirect }/> },
			},
            { 
                name: 'reports',
                title: translate('generic.report'),
                path: '/backoffice/reports',
                callback:( props => {
                    Log.info(Logs.COMPONENT, 'Showing reports');
                    return <BackofficeReportsComponent />;
            })},
            { 
                name: 'approvals',
                title: translate('backoffice.approvals.title'),
                path: '/backoffice/approvals',
                callback:( props => {
                    Log.info(Logs.COMPONENT, 'Showing reports');
                    return <BackofficeApprovals callback={this.redirect } />;
            })},
            { 
                name: 'postingTemplates',
                title: translate('nav.postingTemplates'),
                path: '/backoffice/postingTemplates',
                callback:( props => {
                    return <BackofficeTemplateComponent />;
            })},
        ];
        if(ClientConfig.showVouchers) {
			tabValuesnoAccount.push(
				{
					name: 'vouchers',
					title: translate('customers.vouchers'),
					path: '/backoffice/vouchers', 
					callback: (props) => {
						return <CustomerVouchers isBackoffice={true} />;
					},
				},
			);
		}
        if(ClientConfig.showAddressApproval) {
			tabValuesnoAccount.push({
				title: translate('customers.openapprovals.title'),
				path: '/backoffice/openAddressAprovals',
				name: 'openAddressAprovals',
			    callback: () => { return <CustomerOpenApprovals callback={this.redirect } /> },
				}
			)
			tabValuesnoAccount.push({
				title: translate('customers.opendocuments'),
				path: '/backoffice/openDocumentApprovals',
				name: 'openDocumentApprovals',
			    callback: () => { return <CustomerOpenApprovals callback={this.redirect } releaseType={Ident.ReleaseType.ApproveDocument} /> },
				}
			)
		}
        const tabValuesAccount: Array<ITabvalues> = [
            { 
                name: 'transactions',
                title: translate('nav.transactions'),
                path: '/backoffice/transactions',
                callback:( props => {
                    if (this.state.selectedAccount == null) {
                        props.history.push('/backoffice');
                        return null;
                    }
                    return <BackOfficeDetails redirectCallback={this.redirect}/>;
            })},
            { 
                name: 'postingTemplates',
                title: translate('nav.postingTemplates'),
                path: '/backoffice/postingTemplates',
                callback:( props => {
                    if (this.state.selectedAccount == null) {
                        props.history.push('/backoffice');
                        return null;
                    }
                    return <BackofficeTemplateComponent />;
            })},
            { 
                name: 'reportsA',
                title: translate('generic.report'),
                path: '/backoffice/reportsA',
                callback:( props => {
                        return <BackofficeReportsComponent />       
            })},
        ];
        return (
            this.state.selectedAccount != null ? (
                    <Tabs 
                        activeTab='transactions'
                        tabList={tabValuesAccount}
                        redicrect="/backoffice/transactions"
                        basePath='/backoffice/'
                    />         
                ) :
                (
                    <Tabs 
                        activeTab='accountList'
                        tabList={tabValuesnoAccount}
                        redicrect="/backoffice/search"
                        basePath='/backoffice/'
                    />         
                )
        );
    }
}
export default withRouter(BackOfficeComponent);
