// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CountryCodeAlpha2,
} from './';

/**
 * @export
 * @interface AccountOpeningWithIBAN
 */
export interface AccountOpeningWithIBAN {
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    account_number?: string;
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    account_currency?: string;
    /**
     * date and time of account was opened
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    account_creation?: string;
    /**
     * date and time the IBAN was created
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    iban_creation?: string;
    /**
     * name of the customer
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    name?: string;
    /**
     * given name of the customer
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    given_name?: string;
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    document_type?: string;
    /**
     * @type {Date}
     * @memberof AccountOpeningWithIBAN
     */
    document_expiry?: Date;
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    mobile_number?: string;
    /**
     * the email address of the customer
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    email_address?: string;
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    address_line?: string;
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    city?: string;
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    post_code?: string;
    /**
     * @type {CountryCodeAlpha2}
     * @memberof AccountOpeningWithIBAN
     */
    country?: CountryCodeAlpha2;
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    client_profile?: AccountOpeningWithIBANClientProfileEnum;
    /**
     * @type {string}
     * @memberof AccountOpeningWithIBAN
     */
    person_state?: AccountOpeningWithIBANPersonStateEnum;
    /**
     * @type {Date}
     * @memberof AccountOpeningWithIBAN
     */
    identification_date?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum AccountOpeningWithIBANClientProfileEnum {
    Primary = 'primary',
    Companion = 'companion'
}
/**
 * @export
 * @enum {string}
 */
export enum AccountOpeningWithIBANPersonStateEnum {
    R = 'R',
    V = 'V',
    I = 'I',
    C = 'C'
}

