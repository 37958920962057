import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Tabs, { ITabvalues } from '../../../components/atomiccompoents/tabs/tabs';
import { Ident } from '../../../logic/api';
import IDApprovalQueue from './serviceCenter/videoApprovalProcess/idApprovalQueue';
import VideoApprovalQueue from './serviceCenter/videoApprovalProcess/videoApprovalQueue';


interface IProps extends RouteComponentProps {}

export default class ServiceCenterComponent extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
        this.redirect = this.redirect.bind(this);
    }

    redirect(path: string) {
        this.props.history.push(path);
    }

	render() {
		const tabValues: Array<ITabvalues> = [
            { 
                name: 'idqueue',
                title: 'ID Queue',
                path: '/servicecenter/idqueue',
                callback:( props => {
                    return <IDApprovalQueue releaseTypeDocument={Ident.ReleaseType.RegulaApproveDocument} />;
            })},
			{ 
                name: 'videoqueue',
                title: 'Video Queue',
                path: '/servicecenter/videoqueue',
                callback:( props => {
                    return <VideoApprovalQueue />;
            })},
		];
		
		return  (
			<Tabs 
				activeTab='idqueue'
				tabList={tabValues}
				redicrect='/servicecenter/idqueue'
				basePath='/servicecenter/'
                minwidthNeeded={false}
			/>  
		);       
	}
}
