// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Amount,
    Attachment,
    RequestMoneyResponseCheckout,
    RequestMoneyState,
} from './';

/**
 * a request money request if a stored favorite was used, put its id into favorite_id
 * @export
 * @interface RequestMoneyResponse
 */
export interface RequestMoneyResponse {
    /**
     * @type {number}
     * @memberof RequestMoneyResponse
     */
    ta_id: number;
    /**
     * @type {number}
     * @memberof RequestMoneyResponse
     */
    request_money_id: number;
    /**
     * @type {string}
     * @memberof RequestMoneyResponse
     */
    ownAccount: string;
    /**
     * @type {Amount}
     * @memberof RequestMoneyResponse
     */
    instructedAmount?: Amount;
    /**
     * the creditor account identification, either  * an email address, or  * an accountnumber or  * an iban note * phone number note: we need to find out why what the swagger editor does not like the email regex from https://emailregex.com/ 
     * @type {string}
     * @memberof RequestMoneyResponse
     */
    counterpartyAccount: string;
    /**
     * Creditor Name
     * @type {string}
     * @memberof RequestMoneyResponse
     */
    counterpartyName: string;
    /**
     * Creditor Name
     * @type {string}
     * @memberof RequestMoneyResponse
     */
    counterpartyGivenName: string;
    /**
     * @type {number}
     * @memberof RequestMoneyResponse
     */
    counterpartyPersonId: number;
    /**
     * @type {string}
     * @memberof RequestMoneyResponse
     */
    counterpartyEmailAddress?: string;
    /**
     * @type {string}
     * @memberof RequestMoneyResponse
     */
    counterpartyPhoneNumber?: string;
    /**
     * Unstructured remittance information 
     * @type {string}
     * @memberof RequestMoneyResponse
     */
    remittanceInformationUnstructured?: string;
    /**
     * shows when this request was created
     * @type {Date}
     * @memberof RequestMoneyResponse
     */
    datetime_create: Date;
    /**
     * indicates if a request money was received(I) or sent (O)
     * @type {string}
     * @memberof RequestMoneyResponse
     */
    in_out: RequestMoneyResponseInOutEnum;
    /**
     * @type {RequestMoneyState}
     * @memberof RequestMoneyResponse
     */
    state: RequestMoneyState;
    /**
     * shows when this request was updated either withdrawn, denied, cancelled or accepted
     * @type {Date}
     * @memberof RequestMoneyResponse
     */
    datetime_update?: Date;
    /**
     * if the request is in state W,D,C and the one who cancelled the request, set a reason, it can be found here
     * @type {string}
     * @memberof RequestMoneyResponse
     */
    cancellation_reason?: string;
    /**
     * * R - request money * S - split transaction * T - takeover * C - cash desk * M - Merchant Checkout * D - debit card takeover request * O - debit card takeover offer
     * @type {string}
     * @memberof RequestMoneyResponse
     */
    request_money_type: RequestMoneyResponseRequestMoneyTypeEnum;
    /**
     * @type {Array<Attachment>}
     * @memberof RequestMoneyResponse
     */
    attachments?: Array<Attachment>;
    /**
     * @type {RequestMoneyResponseCheckout}
     * @memberof RequestMoneyResponse
     */
    checkout?: RequestMoneyResponseCheckout;
    /**
     * external reference as provided by the initiaing application
     * @type {string}
     * @memberof RequestMoneyResponse
     */
    external_reference?: string;
    /**
     * @type {string}
     * @memberof RequestMoneyResponse
     */
    face_image_link?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RequestMoneyResponseInOutEnum {
    I = 'I',
    O = 'O'
}
/**
 * @export
 * @enum {string}
 */
export enum RequestMoneyResponseRequestMoneyTypeEnum {
    R = 'R',
    S = 'S',
    T = 'T',
    C = 'C',
    M = 'M',
    D = 'D',
    O = 'O'
}

