import React, { PureComponent, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Helpertext } from '../../content/dashboard/content/customers/basicStyledComponents/customerDetails.css';
import { Icons } from '../../images';
import { OverlayHandler, Overlays } from '../../logic/handler/overlayhandler/overlayHandler';

export interface IImageBoxElement {
    image?: Blob;
    title: string;
    url?: string;
    selectcallback?: (image: Blob) => void;
}

interface IProps {
    images: Array<IImageBoxElement>
}

interface IState {
   
}

export default class ImageBox extends PureComponent<IProps, IState> {
    
	render() {
		return (
            <React.Fragment>
                {
                    this.props.images.map((o: IImageBoxElement) => {
                        const isPDF = o.image?.type === 'application/pdf';
                        return  <FaceBlock>
                                    <FaceImage
                                        src={isPDF ? window.location.origin + '/invrs/pdf_icon.png' : o.image != null ? window.URL.createObjectURL(o.image) : o.url}
                                        alt={isPDF ? 'PDF' : undefined  }
                                        onClick={(event: SyntheticEvent) => {
                                            event.stopPropagation();
                                            if(isPDF) {
                                                OverlayHandler.showOverlay(Overlays.PDFViewer, {
                                                    file: o.image
                                                });
                                            } else {
                                                OverlayHandler.showOverlay(Overlays.imagePreview, {
                                                    image: o.image,
                                                    alt: o.url,
                                                });
                                            }
                                        }}
                                    />
                                    <IDBox>{ o.title } 
                                        {o.selectcallback != null ?
                                            <div  onClick={(event: SyntheticEvent) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            if(o.selectcallback != null)
                                                OverlayHandler.showOverlay(Overlays.fileChooserOverlay, {callback: o.selectcallback, fileType: 'all'})
                                            }}>
                                                {Icons.upload()}
                                            </div> : null }
                                    </IDBox>
                                </FaceBlock>
                    })}
            </React.Fragment>
		);
	}
}

const FaceImage = styled.img`
    max-height: 64px;
    object-fit: cover;
    border: 1px solid #98a9bc;
`;
const FaceBlock = styled.div`
    display: flex;
    flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	height: 88px;
    margin-right: 8px;
    margin-top: 8px;
`;

const IDBox = styled(Helpertext)`
    color: ${props => props.theme.Global.darkFontColor};
    font-size: 12px;
    width: 100%;
    font-weight: 600;
	padding-left: 4px;
    max-width: 180px;
    white-space:nowrap;
	text-overflow:ellipsis;
`;