// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import {
    BusinessAccount,
    NewBusinessAccount,
    TransactionIdentifier,
    UlTransaction,
} from '../models';

export interface BusinessAccountsBusinessAccountNumberTransactionsUniqueIdentifierPutRequest {
    businessAccountNumber: string;
    uniqueIdentifier: string;
    UlTransaction: UlTransaction;
}

export interface BusinessAccountsPostRequest {
    NewBusinessAccount: NewBusinessAccount;
}

/**
 * no description
 */
export class BusinessaccountApi extends BaseAPI implements IOperationIdMap {

    /**
     * post billable events to the businessaccount. detection of duplicates is done using the id. If an already processed event is received again and the new event is identical to the previous one, no error will be raised. if these two events differ, error 409 conflict will be raised
     * post billable events to the businessaccount
     */
    businessAccountsBusinessAccountNumberTransactionsUniqueIdentifierPut = (requestParameters: BusinessAccountsBusinessAccountNumberTransactionsUniqueIdentifierPutRequest): Observable<TransactionIdentifier> => {
        throwIfRequired(requestParameters, 'businessAccountNumber', 'businessAccountsBusinessAccountNumberTransactionsUniqueIdentifierPut');
        throwIfRequired(requestParameters, 'uniqueIdentifier', 'businessAccountsBusinessAccountNumberTransactionsUniqueIdentifierPut');
        throwIfRequired(requestParameters, 'UlTransaction', 'businessAccountsBusinessAccountNumberTransactionsUniqueIdentifierPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<TransactionIdentifier>({
            path: '/business-accounts/{businessAccountNumber}/transactions/{uniqueIdentifier}'.replace('{businessAccountNumber}', encodeURI(requestParameters.businessAccountNumber)).replace('{uniqueIdentifier}', encodeURI(requestParameters.uniqueIdentifier)),
            method: 'PUT',
            headers,
            body: requestParameters.UlTransaction,
        });
    };

    /**
     * if called multiple times with the same arguments, only one account will be created.
     * create a new businessaccount
     */
    businessAccountsPost = (requestParameters: BusinessAccountsPostRequest): Observable<BusinessAccount> => {
        throwIfRequired(requestParameters, 'NewBusinessAccount', 'businessAccountsPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<BusinessAccount>({
            path: '/business-accounts',
            method: 'POST',
            headers,
            body: requestParameters.NewBusinessAccount,
        });
    };


    operationToOperationId = {
	    "businessAccountsBusinessAccountNumberTransactionsUniqueIdentifierPut": "business_accounts_business_account_number_transactions_unique_identifier_put" ,
	    "businessAccountsPost": "business_accounts_post"
    }
}
