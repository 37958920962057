// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ContentDocumentType,
} from './';

/**
 * @export
 * @interface DocumentAction
 */
export interface DocumentAction {
    /**
     * @type {ContentDocumentType}
     * @memberof DocumentAction
     */
    identifier?: ContentDocumentType;
    /**
     * @type {string}
     * @memberof DocumentAction
     */
    version?: string;
    /**
     * @type {string}
     * @memberof DocumentAction
     */
    language?: DocumentActionLanguageEnum;
    /**
     * @type {string}
     * @memberof DocumentAction
     */
    action?: DocumentActionActionEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum DocumentActionLanguageEnum {
    De = 'de',
    En = 'en',
    Sq = 'sq'
}
/**
 * @export
 * @enum {string}
 */
export enum DocumentActionActionEnum {
    A = 'A',
    R = 'R',
    J = 'J'
}

