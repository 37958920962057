// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI, COLLECTION_FORMATS } from '../runtime';
import {
    AccessKeyName,
    AccessKeyWithPassword,
    Account,
    AccountCustomName,
    AccountDetail,
    AccountIdentifier,
    AccountMemo,
    AccountName,
    AccountParams,
    AccountProductOrder,
    AccountType,
    Activity,
    ActivityDetail,
    Balance,
    Beneficiary,
    BeneficiaryId,
    BlockAccount,
    CardAgreement,
    CardAgreementResponse,
    CardApplication,
    CardLimit,
    CardPinGetResponseType,
    CardSecurity,
    CardState,
    CardTransactionDetail,
    Config,
    Country,
    DeviceToken,
    DeviceTokenItem,
    DispoActivity,
    EmailAddress,
    ExternalAccount,
    ExternalAccountAmount,
    ExternalAccountID,
    ExternalAccountValidCardBrand,
    ExternalDebitDetail,
    Favorite,
    FavoriteId,
    FcmTokenItem,
    FeeBooking,
    FeesDetails,
    FiatCryptoTransaction,
    GiftPayments,
    IBeacon,
    InternalAccount,
    Iso4217Currency,
    LimitValue,
    Location,
    MerchantNotification,
    MoneyTransfer,
    NewBeneficiary,
    NewEmailAddress,
    NewExternalAccount,
    NewFavorite,
    NewFingerprint,
    NewLoginAndAccount,
    NewPassword,
    NewPasswordResponse,
    NewPasswordToken,
    NewProductVoucher,
    NewProductVoucherGroup,
    Nfc,
    NfcData,
    NfcItem,
    Notification,
    NotificationGroup,
    PayoutData,
    PayoutRequest,
    PendingRequest,
    PendingRequestUpdate,
    Person,
    PersonAndAccount,
    Pin,
    PlainTransactionIdentifier,
    PostingJournal,
    PresignedPost,
    ProductOrderDetails,
    ProductTypeOut,
    ProductVoucher,
    ResetPassword,
    ScheduledTransaction,
    ScheduledTransactionIdentifier,
    ScheduledTransactionUpdate,
    TransactionIdentifier,
    ValidCardProvider,
    VirtualCardOrderFee,
    VoucherTransaction,
} from '../models';

export interface AccountAccessKeyPostRequest {
    account_number: string;
    AccessKeyName?: AccessKeyName;
}

export interface AccountAccountNumberActivityDetailGetRequest {
    account_number: string;
    ta_id: number;
    transaction_id: number;
}

export interface AccountAccountNumberActivityGetRequest {
    account_number: string;
    date_from?: Date;
    date_to?: Date;
    populate_face_links?: boolean;
    participant_filter?: Array<string>;
    limit?: number;
    offset?: number;
    hide_reversal?: boolean;
}

export interface AccountAccountNumberBalanceGetRequest {
    account_number: string;
}

export interface AccountAccountNumberBlockDeleteRequest {
    account_number: string;
    AccountBlockIDS?: Array<number>;
}

export interface AccountAccountNumberBlockPutRequest {
    account_number: string;
    BlockAccount: BlockAccount;
}

export interface AccountAccountNumberBookableFeesRequest {
    account_number: string;
}

export interface AccountAccountNumberCardAgreementCardAgreementIdLimitPutRequest {
    account_number: string;
    card_agreement_id: number;
    CardLimit?: CardLimit;
}

export interface AccountAccountNumberCardAgreementCardAgreementIdStatePutRequest {
    account_number: string;
    card_agreement_id: number;
    state: AccountAccountNumberCardAgreementCardAgreementIdStatePutStateEnum;
}

export interface AccountAccountNumberCardAgreementCardCardIdPinGetRequest {
    account_number: string;
    card_id: number;
}

export interface AccountAccountNumberCardAgreementCardCardIdPinPutRequest {
    account_number: string;
    card_id: number;
    pin?: string;
}

export interface AccountAccountNumberCardAgreementCardCardIdResetPinCounterPutRequest {
    account_number: string;
    card_id: number;
}

export interface AccountAccountNumberCardAgreementCardCardIdStateGetRequest {
    account_number: string;
    card_id: number;
}

export interface AccountAccountNumberCardAgreementCardCardIdStatePutRequest {
    account_number: string;
    card_id: number;
    state: AccountAccountNumberCardAgreementCardCardIdStatePutStateEnum;
}

export interface AccountAccountNumberCardAgreementCardGetRequest {
    account_number: string;
    card_id: number;
}

export interface AccountAccountNumberCardAgreementCardPostRequest {
    account_number: string;
    virtual_or_physical: AccountAccountNumberCardAgreementCardPostVirtualOrPhysicalEnum;
    card_color?: AccountAccountNumberCardAgreementCardPostCardColorEnum;
    secret_answer?: string;
}

export interface AccountAccountNumberCardAgreementDeleteRequest {
    account_number: string;
    card_agreement_id: number;
}

export interface AccountAccountNumberCardAgreementGetRequest {
    account_number: string;
}

export interface AccountAccountNumberCardAgreementPostRequest {
    account_number: string;
    CardApplication?: CardApplication;
}

export interface AccountAccountNumberCardTransactionDetailGetRequest {
    account_number: string;
    ta_id: number;
    transaction_id: number;
}

export interface AccountAccountNumberCryptoOrderUniqueIdentifierPutRequest {
    account_number: string;
    uniqueIdentifier: string;
    FiatCryptoTransaction: FiatCryptoTransaction;
}

export interface AccountAccountNumberCustomNameDeleteRequest {
    account_number: string;
}

export interface AccountAccountNumberCustomNamePutRequest {
    account_number: string;
    AccountCustomName: AccountCustomName;
}

export interface AccountAccountNumberDetailGetRequest {
    account_number: string;
}

export interface AccountAccountNumberExternalDebitDetailGetRequest {
    account_number: string;
    ta_id: number;
    transaction_id: number;
}

export interface AccountAccountNumberFeesDeleteRequest {
    account_number: string;
    account_fee_package_id: number;
}

export interface AccountAccountNumberFeesGetRequest {
    account_number: string;
    hide_used?: boolean;
}

export interface AccountAccountNumberFeesPutRequest {
    account_number: string;
    FeeBooking?: FeeBooking;
}

export interface AccountAccountNumberLimitDeleteRequest {
    account_number: string;
    limit_type_id: number;
}

export interface AccountAccountNumberLimitPutRequest {
    account_number: string;
    limit_type_id: number;
    LimitValue: LimitValue;
}

export interface AccountAccountNumberMemoPutRequest {
    account_number: string;
    AccountMemo: AccountMemo;
}

export interface AccountAccountNumberMerchantNotificationGetRequest {
    account_number: string;
}

export interface AccountAccountNumberNamePutRequest {
    account_number: string;
    AccountName: AccountName;
}

export interface AccountAccountNumberNfcDeleteRequest {
    account_number: string;
    Nfc: Nfc;
}

export interface AccountAccountNumberNfcGetRequest {
    account_number: string;
}

export interface AccountAccountNumberNfcPostRequest {
    account_number: string;
    Nfc: Nfc;
}

export interface AccountAccountNumberQrGetRequest {
    account_number: string;
    amount?: string;
    remittance_info?: string;
}

export interface AccountAccountNumberQrPngGetRequest {
    account_number: string;
    amount?: string;
    remittance_info?: string;
}

export interface AccountAccountNumberValidCardProviderGetRequest {
    account_number: string;
}

export interface AccountAccountNumberVirtualCardOrderingFeeGetRequest {
    account_number: string;
}

export interface AccountDispoActivityGetRequest {
    account_number: string;
    date_from?: Date;
    date_to?: Date;
    limit?: number;
    offset?: number;
    hide_closed?: boolean;
}

export interface AccountGiftPaymentGetRequest {
    account_number: string;
    filter_by_person_id?: number;
}

export interface AccountGiftPaymentPutRequest {
    account_number: string;
    PlainTransactionIdentifier?: PlainTransactionIdentifier;
}

export interface AccountMobileTagPutRequest {
    account_number: string;
    link: string;
}

export interface AccountNotificationGetRequest {
    account_number: string;
    person_id: number;
}

export interface AccountNotificationPutRequest {
    account_number: string;
    person_id: number;
    Notification?: Notification;
}

export interface AccountPostRequest {
    NewLoginAndAccount: NewLoginAndAccount;
}

export interface AccountProductOrderListRequest {
    account_number: string;
    date_from?: Date;
    date_to?: Date;
}

export interface AccountScheduledTransactionDeleteRequest {
    account_number: string;
    ta_id: number;
    scheduled_transaction_id: number;
}

export interface AccountScheduledTransactionGetRequest {
    account_number: string;
    show_history?: boolean;
}

export interface AccountScheduledTransactionPutRequest {
    account_number: string;
    ta_id: number;
    scheduled_transaction_id: number;
    ScheduledTransactionUpdate?: ScheduledTransactionUpdate;
}

export interface AccountSearchRequest {
    account_number?: string;
    iban?: string;
    memo?: string;
    name?: string;
    custom_name?: string;
    account_type_id?: number;
    limit?: number;
    offset?: number;
}

export interface AtmLoginPostRequest {
    IBeacon: IBeacon;
}

export interface AtmPayoutGetRequest {
    token: string;
}

export interface AtmPayoutPostRequest {
    PayoutRequest: PayoutRequest;
}

export interface CompanionCompanionCodeCardPostRequest {
    companion_code: string;
    card_color?: CompanionCompanionCodeCardPostCardColorEnum;
    secret_answer?: string;
}

export interface CountryGetRequest {
    language?: string;
}

export interface ExternalLaboratoryDocumentGetRequest {
    external_laboratory_id: number;
    document_type?: ExternalLaboratoryDocumentGetDocumentTypeEnum;
}

export interface LoaddtestSessionPostRequest {
    session: number;
    type?: string;
}

export interface NfcUidDeleteRequest {
    uid: string;
}

export interface NfcUidGetRequest {
    uid: string;
}

export interface NfcUidPostRequest {
    uid: string;
    NfcData: NfcData;
}

export interface NfcUidPutRequest {
    uid: string;
    NfcData: NfcData;
}

export interface PendingRequestsAccountNumberGetRequest {
    account_number: string;
}

export interface PendingRequestsAccountNumberPutRequest {
    account_number: string;
    PendingRequestUpdate?: PendingRequestUpdate;
}

export interface PersonAccountsAccessKeyPostRequest {
    person_id: number;
    account_number: string;
    AccessKeyName?: AccessKeyName;
}

export interface PersonAccountsPersonIdGetRequest {
    person_id: number;
}

export interface PersonAccountsPersonIdPostRequest {
    person_id: number;
    currency: Iso4217Currency;
}

export interface PersonApnDeviceIdDeleteRequest {
    device_id: string;
}

export interface PersonApnDeviceIdGetRequest {
    device_id: string;
}

export interface PersonApnDeviceIdPostRequest {
    device_id: string;
    DeviceToken: DeviceToken;
}

export interface PersonBeneficiaryDeleteRequest {
    beneficiary_id: number;
}

export interface PersonBeneficiaryGetRequest {
    debtorAccount?: string;
    creditorAccount?: string;
    creditorName?: string;
    remittanceInformationUnstructured?: string;
    limit?: number;
    offset?: number;
}

export interface PersonBeneficiaryPostRequest {
    NewBeneficiary?: NewBeneficiary;
}

export interface PersonBeneficiaryPutRequest {
    beneficiary_id: number;
    NewBeneficiary?: NewBeneficiary;
}

export interface PersonConfigPutRequest {
    Config: Config;
}

export interface PersonEmailAddressEmailAddressIdDeleteRequest {
    email_address_id: number;
}

export interface PersonEmailAddressEmailAddressIdPutRequest {
    email_address_id: number;
    NewEmailAddress: NewEmailAddress;
}

export interface PersonEmailAddressPostRequest {
    NewEmailAddress: NewEmailAddress;
}

export interface PersonEmailGetRequest {
    email_id: number;
}

export interface PersonExternalAccountAmountsGetRequest {
    external_account_id: number;
    account_number: string;
}

export interface PersonExternalAccountDeleteRequest {
    external_account_id: number;
    person_id?: number;
}

export interface PersonExternalAccountGetRequest {
    person_id?: number;
}

export interface PersonExternalAccountPostRequest {
    NewExternalAccount: NewExternalAccount;
    person_id?: number;
}

export interface PersonExternalAccountPutRequest {
    external_account_id: number;
    NewExternalAccount: NewExternalAccount;
    person_id?: number;
}

export interface PersonFavoriteDeleteRequest {
    favorite_id: number;
}

export interface PersonFavoriteGetRequest {
    ownAccount?: string;
    counterpartyAccount?: string;
    counterpartyName?: string;
    remittanceInformationUnstructured?: string;
    limit?: number;
    offset?: number;
}

export interface PersonFavoritePostRequest {
    NewFavorite?: NewFavorite;
}

export interface PersonFavoritePutRequest {
    favorite_id: number;
    NewFavorite?: NewFavorite;
}

export interface PersonFcmTokenDeleteRequest {
    token: string;
}

export interface PersonFcmTokenPutRequest {
    token: string;
}

export interface PersonFingerprintPostRequest {
    NewFingerprint: NewFingerprint;
}

export interface PersonLocationCheckoutRequest {
    ta_id: number;
    location_id: number;
}

export interface PersonNewpasswordPostRequest {
    NewPassword: NewPassword;
}

export interface PersonPinPutRequest {
    Pin: Pin;
}

export interface PersonPutRequest {
    Person: Person;
}

export interface PersonReferralLinkPutRequest {
    person_id: number;
    referral_link: string;
}

export interface PersonReferralQrCodeGetRequest {
    person_id: number;
}

export interface PersonResetpasswordPostRequest {
    ResetPassword: ResetPassword;
}

export interface PersonResetpasswordPutRequest {
    NewPasswordToken: NewPasswordToken;
}

export interface ProductOrderGetRequest {
    ta_id: number;
    transaction_id: number;
}

export interface ProductOrderProductTypeGetRequest {
    ta_id: number;
    transaction_id: number;
}

export interface ProductOrderProductTypePostRequest {
    product_type_id: number;
    ta_id: number;
    transaction_id: number;
}

export interface ProductVoucherEmailAddressGetRequest {
    email_address: string;
}

export interface ProductVoucherPostRequest {
    NewProductVoucher?: NewProductVoucher;
}

export interface ProductVoucherVoucherGroupClientPostRequest {
    NewProductVoucherGroup?: NewProductVoucherGroup;
}

export interface ProductVoucherVoucherGroupGetRequest {
    qlimit?: number;
    qoffset?: number;
}

export interface ProductVoucherVoucherGroupPostRequest {
    NewProductVoucherGroup?: NewProductVoucherGroup;
}

export interface ProductVoucherVoucherGroupVoucherGroupGetRequest {
    voucher_group: number;
}

export interface ScheduledTransactionDocumentPostRequest {
    file: Blob;
    ta_id: number;
    scheduled_transaction_id: number;
}

export interface TransactionDocumentPostRequest {
    file: Blob;
    ta_id: number;
    transaction_id: number;
}

export interface TransactionDocumentPresignedGetRequest {
    ta_id: number;
    transaction_id: number;
}

export interface TransactionGetRequest {
    ta_id?: number;
    transaction_id?: number;
}

export interface TransactionPostRequest {
    MoneyTransfer: MoneyTransfer;
}

export interface VoucherVoucherNumberGetRequest {
    voucher_number: string;
}

export interface VoucherVoucherNumberPostRequest {
    voucher_number: string;
    VoucherTransaction: VoucherTransaction;
}

/**
 * no description
 */
export class DefaultApi extends BaseAPI implements IOperationIdMap {

    /**
     * please note that the only time the only time the secret_key is shown is in this response
     * create an access key for an account
     */
    accountAccessKeyPost = (requestParameters: AccountAccessKeyPostRequest): Observable<AccessKeyWithPassword> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccessKeyPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<AccessKeyWithPassword>({
            path: '/account/{account_number}/access_key'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'POST',
            headers,
            body: requestParameters.AccessKeyName,
        });
    };

    /**
     * details about booked transactions, the amount of detail varies depending on source data
     * activity details
     */
    accountAccountNumberActivityDetailGet = (requestParameters: AccountAccountNumberActivityDetailGetRequest): Observable<ActivityDetail> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberActivityDetailGet');
        throwIfRequired(requestParameters, 'ta_id', 'accountAccountNumberActivityDetailGet');
        throwIfRequired(requestParameters, 'transaction_id', 'accountAccountNumberActivityDetailGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<ActivityDetail>({
            path: '/account/{account_number}/activity/detail'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get get the account activity
     */
    accountAccountNumberActivityGet = (requestParameters: AccountAccountNumberActivityGetRequest): Observable<Activity> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberActivityGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
            ...(requestParameters.populate_face_links && { 'populate_face_links': requestParameters.populate_face_links }),
            ...(requestParameters.participant_filter && { 'participant_filter': requestParameters.participant_filter.join(COLLECTION_FORMATS['csv']) }),
            ...(requestParameters.limit && { 'limit': requestParameters.limit }),
            ...(requestParameters.offset && { 'offset': requestParameters.offset }),
            ...(requestParameters.hide_reversal && { 'hide_reversal': requestParameters.hide_reversal }),
        };

        return this.request<Activity>({
            path: '/account/{account_number}/activity'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * get an account balance
     */
    accountAccountNumberBalanceGet = (requestParameters: AccountAccountNumberBalanceGetRequest): Observable<Balance> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberBalanceGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Balance>({
            path: '/account/{account_number}/balance'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * unblock the account, so that transactions can happen on this account again
     */
    accountAccountNumberBlockDelete = (requestParameters: AccountAccountNumberBlockDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberBlockDelete');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/block'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'POST',
            headers,
            body: requestParameters.AccountBlockIDS,
        });
    };

    /**
     * block the account, so that no transaction can happen on this account
     */
    accountAccountNumberBlockPut = (requestParameters: AccountAccountNumberBlockPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberBlockPut');
        throwIfRequired(requestParameters, 'BlockAccount', 'accountAccountNumberBlockPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/block'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'PUT',
            headers,
            body: requestParameters.BlockAccount,
        });
    };

    /**
     * details about bookable packages on source data
     * bookable fee packages
     */
    accountAccountNumberBookableFees = (requestParameters: AccountAccountNumberBookableFeesRequest): Observable<Array<FeesDetails>> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberBookableFees');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<FeesDetails>>({
            path: '/account/{account_number}/bookable_fees/'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Set the individual card limit for the customer account at BPC.
     * Set limit for BPC account.
     */
    accountAccountNumberCardAgreementCardAgreementIdLimitPut = (requestParameters: AccountAccountNumberCardAgreementCardAgreementIdLimitPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardAgreementCardAgreementIdLimitPut');
        throwIfRequired(requestParameters, 'card_agreement_id', 'accountAccountNumberCardAgreementCardAgreementIdLimitPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/card_agreement/{card_agreement_id}/limit'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{card_agreement_id}', encodeURI(requestParameters.card_agreement_id)),
            method: 'PUT',
            headers,
            body: requestParameters.CardLimit,
        });
    };

    /**
     * Set the state for the customer account at BPC.
     * Set state for BPC account.
     */
    accountAccountNumberCardAgreementCardAgreementIdStatePut = (requestParameters: AccountAccountNumberCardAgreementCardAgreementIdStatePutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardAgreementCardAgreementIdStatePut');
        throwIfRequired(requestParameters, 'card_agreement_id', 'accountAccountNumberCardAgreementCardAgreementIdStatePut');
        throwIfRequired(requestParameters, 'state', 'accountAccountNumberCardAgreementCardAgreementIdStatePut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.state && { 'state': requestParameters.state }),
        };

        return this.request<void>({
            path: '/account/{account_number}/card_agreement/{card_agreement_id}/state'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{card_agreement_id}', encodeURI(requestParameters.card_agreement_id)),
            method: 'PUT',
            headers,
            query,
        });
    };

    /**
     * get card pin status from BPC. If pin is not set you receive status \'not set\', if it is set you receive status \'set\'
     * get card pin status
     */
    accountAccountNumberCardAgreementCardCardIdPinGet = (requestParameters: AccountAccountNumberCardAgreementCardCardIdPinGetRequest): Observable<CardPinGetResponseType> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardAgreementCardCardIdPinGet');
        throwIfRequired(requestParameters, 'card_id', 'accountAccountNumberCardAgreementCardCardIdPinGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<CardPinGetResponseType>({
            path: '/account/{account_number}/card_agreement/card/{card_id}/pin'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{card_id}', encodeURI(requestParameters.card_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * generate request to change pin for BPC
     * change pin of card
     */
    accountAccountNumberCardAgreementCardCardIdPinPut = (requestParameters: AccountAccountNumberCardAgreementCardCardIdPinPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardAgreementCardCardIdPinPut');
        throwIfRequired(requestParameters, 'card_id', 'accountAccountNumberCardAgreementCardCardIdPinPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.pin && { 'pin': requestParameters.pin }),
        };

        return this.request<void>({
            path: '/account/{account_number}/card_agreement/card/{card_id}/pin'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{card_id}', encodeURI(requestParameters.card_id)),
            method: 'PUT',
            headers,
            query,
        });
    };

    /**
     * generate request to reset pin counter for BPC
     * creset pin counter of the card
     */
    accountAccountNumberCardAgreementCardCardIdResetPinCounterPut = (requestParameters: AccountAccountNumberCardAgreementCardCardIdResetPinCounterPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardAgreementCardCardIdResetPinCounterPut');
        throwIfRequired(requestParameters, 'card_id', 'accountAccountNumberCardAgreementCardCardIdResetPinCounterPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/card_agreement/card/{card_id}/reset_pin_counter'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{card_id}', encodeURI(requestParameters.card_id)),
            method: 'PUT',
            headers,
        });
    };

    /**
     * generate request to get card state from BPC
     * card state inquiry
     */
    accountAccountNumberCardAgreementCardCardIdStateGet = (requestParameters: AccountAccountNumberCardAgreementCardCardIdStateGetRequest): Observable<CardState> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardAgreementCardCardIdStateGet');
        throwIfRequired(requestParameters, 'card_id', 'accountAccountNumberCardAgreementCardCardIdStateGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<CardState>({
            path: '/account/{account_number}/card_agreement/card/{card_id}/state'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{card_id}', encodeURI(requestParameters.card_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * generate request to block or unblock card for BPC
     * block or unblock card
     */
    accountAccountNumberCardAgreementCardCardIdStatePut = (requestParameters: AccountAccountNumberCardAgreementCardCardIdStatePutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardAgreementCardCardIdStatePut');
        throwIfRequired(requestParameters, 'card_id', 'accountAccountNumberCardAgreementCardCardIdStatePut');
        throwIfRequired(requestParameters, 'state', 'accountAccountNumberCardAgreementCardCardIdStatePut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.state && { 'state': requestParameters.state }),
        };

        return this.request<void>({
            path: '/account/{account_number}/card_agreement/card/{card_id}/state'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{card_id}', encodeURI(requestParameters.card_id)),
            method: 'PUT',
            headers,
            query,
        });
    };

    /**
     * get the cards security code from BPC
     * get security code
     */
    accountAccountNumberCardAgreementCardGet = (requestParameters: AccountAccountNumberCardAgreementCardGetRequest): Observable<CardSecurity> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardAgreementCardGet');
        throwIfRequired(requestParameters, 'card_id', 'accountAccountNumberCardAgreementCardGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<CardSecurity>({
            path: '/account/{account_number}/card_agreement/card/{card_id}'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{card_id}', encodeURI(requestParameters.card_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * order an additional card for an existing and active card agreement
     * order an additional card
     */
    accountAccountNumberCardAgreementCardPost = (requestParameters: AccountAccountNumberCardAgreementCardPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardAgreementCardPost');
        throwIfRequired(requestParameters, 'virtual_or_physical', 'accountAccountNumberCardAgreementCardPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.virtual_or_physical && { 'virtual_or_physical': requestParameters.virtual_or_physical }),
            ...(requestParameters.card_color && { 'card_color': requestParameters.card_color }),
            ...(requestParameters.secret_answer && { 'secret_answer': requestParameters.secret_answer }),
        };

        return this.request<void>({
            path: '/account/{account_number}/card_agreement/card'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'POST',
            headers,
            query,
        });
    };

    /**
     * if a card agreement is ordered (and not Pending on the card providers side) or if the agreement is active, it can be canceled.
     * cancel a card agreement
     */
    accountAccountNumberCardAgreementDelete = (requestParameters: AccountAccountNumberCardAgreementDeleteRequest): Observable<CardAgreementResponse> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardAgreementDelete');
        throwIfRequired(requestParameters, 'card_agreement_id', 'accountAccountNumberCardAgreementDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.card_agreement_id && { 'card_agreement_id': requestParameters.card_agreement_id }),
        };

        return this.request<CardAgreementResponse>({
            path: '/account/{account_number}/card_agreement'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * show card agreements and their issued cards for the given account
     * show card agreements
     */
    accountAccountNumberCardAgreementGet = (requestParameters: AccountAccountNumberCardAgreementGetRequest): Observable<Array<CardAgreement>> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardAgreementGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<CardAgreement>>({
            path: '/account/{account_number}/card_agreement'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * apply for a new card agreement. the valid providers and their card types can bee looked up using `/GET /account/{account_number}/valid_card_provider`
     * apply for a new card agreement.
     */
    accountAccountNumberCardAgreementPost = (requestParameters: AccountAccountNumberCardAgreementPostRequest): Observable<CardAgreementResponse> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardAgreementPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<CardAgreementResponse>({
            path: '/account/{account_number}/card_agreement'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'POST',
            headers,
            body: requestParameters.CardApplication,
        });
    };

    /**
     * card transaction details
     * card transaction details
     */
    accountAccountNumberCardTransactionDetailGet = (requestParameters: AccountAccountNumberCardTransactionDetailGetRequest): Observable<CardTransactionDetail> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCardTransactionDetailGet');
        throwIfRequired(requestParameters, 'ta_id', 'accountAccountNumberCardTransactionDetailGet');
        throwIfRequired(requestParameters, 'transaction_id', 'accountAccountNumberCardTransactionDetailGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<CardTransactionDetail>({
            path: '/account/{account_number}/card_transaction_detail'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * post crypto buy for the account. detection of duplicates is done using the id. If an already processed event is received again and the new event is identical to the previous one, no error will be raised. if these two events differ, error 409 conflict will be raised
     * buy crypto tokens
     */
    accountAccountNumberCryptoOrderUniqueIdentifierPut = (requestParameters: AccountAccountNumberCryptoOrderUniqueIdentifierPutRequest): Observable<TransactionIdentifier> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCryptoOrderUniqueIdentifierPut');
        throwIfRequired(requestParameters, 'uniqueIdentifier', 'accountAccountNumberCryptoOrderUniqueIdentifierPut');
        throwIfRequired(requestParameters, 'FiatCryptoTransaction', 'accountAccountNumberCryptoOrderUniqueIdentifierPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<TransactionIdentifier>({
            path: '/account/{account_number}/crypto/order/{uniqueIdentifier}'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{uniqueIdentifier}', encodeURI(requestParameters.uniqueIdentifier)),
            method: 'PUT',
            headers,
            body: requestParameters.FiatCryptoTransaction,
        });
    };

    /**
     * removes the accounts user given name
     */
    accountAccountNumberCustomNameDelete = (requestParameters: AccountAccountNumberCustomNameDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCustomNameDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/custom_name'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * set the accounts user given name
     */
    accountAccountNumberCustomNamePut = (requestParameters: AccountAccountNumberCustomNamePutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberCustomNamePut');
        throwIfRequired(requestParameters, 'AccountCustomName', 'accountAccountNumberCustomNamePut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/custom_name'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'PUT',
            headers,
            body: requestParameters.AccountCustomName,
        });
    };

    /**
     * account details
     * account details
     */
    accountAccountNumberDetailGet = (requestParameters: AccountAccountNumberDetailGetRequest): Observable<AccountDetail> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberDetailGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<AccountDetail>({
            path: '/account/{account_number}/detail'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * external debit transaction details
     * external debit transaction details
     */
    accountAccountNumberExternalDebitDetailGet = (requestParameters: AccountAccountNumberExternalDebitDetailGetRequest): Observable<ExternalDebitDetail> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberExternalDebitDetailGet');
        throwIfRequired(requestParameters, 'ta_id', 'accountAccountNumberExternalDebitDetailGet');
        throwIfRequired(requestParameters, 'transaction_id', 'accountAccountNumberExternalDebitDetailGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<ExternalDebitDetail>({
            path: '/account/{account_number}/external_debit_detail'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * book a package for transactions
     * delete fee packages
     */
    accountAccountNumberFeesDelete = (requestParameters: AccountAccountNumberFeesDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberFeesDelete');
        throwIfRequired(requestParameters, 'account_fee_package_id', 'accountAccountNumberFeesDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/fees/{account_fee_package_id}'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{account_fee_package_id}', encodeURI(requestParameters.account_fee_package_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * details about booked packages on source data
     * booked fee packages
     */
    accountAccountNumberFeesGet = (requestParameters: AccountAccountNumberFeesGetRequest): Observable<Array<FeesDetails>> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberFeesGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.hide_used && { 'hide_used': requestParameters.hide_used }),
        };

        return this.request<Array<FeesDetails>>({
            path: '/account/{account_number}/fees/'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * book a package for transactions
     * book fee packages
     */
    accountAccountNumberFeesPut = (requestParameters: AccountAccountNumberFeesPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberFeesPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/fees/'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'PUT',
            headers,
            body: requestParameters.FeeBooking,
        });
    };

    /**
     * reset a user defined limit to its system default value
     */
    accountAccountNumberLimitDelete = (requestParameters: AccountAccountNumberLimitDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberLimitDelete');
        throwIfRequired(requestParameters, 'limit_type_id', 'accountAccountNumberLimitDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/tx_limit/{limit_type_id}'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{limit_type_id}', encodeURI(requestParameters.limit_type_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * set a user defined account limit
     */
    accountAccountNumberLimitPut = (requestParameters: AccountAccountNumberLimitPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberLimitPut');
        throwIfRequired(requestParameters, 'limit_type_id', 'accountAccountNumberLimitPut');
        throwIfRequired(requestParameters, 'LimitValue', 'accountAccountNumberLimitPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/tx_limit/{limit_type_id}'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{limit_type_id}', encodeURI(requestParameters.limit_type_id)),
            method: 'PUT',
            headers,
            body: requestParameters.LimitValue,
        });
    };

    /**
     * set the accounts memo
     */
    accountAccountNumberMemoPut = (requestParameters: AccountAccountNumberMemoPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberMemoPut');
        throwIfRequired(requestParameters, 'AccountMemo', 'accountAccountNumberMemoPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/memo'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'PUT',
            headers,
            body: requestParameters.AccountMemo,
        });
    };

    /**
     * if the customer checks in into en ecos restaurant, this restaurant will report each client transaction to the core using this interface the customer get get this
     * get pending merchant notifications
     */
    accountAccountNumberMerchantNotificationGet = (requestParameters: AccountAccountNumberMerchantNotificationGetRequest): Observable<Array<MerchantNotification>> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberMerchantNotificationGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<MerchantNotification>>({
            path: '/account/{account_number}/merchant_notification'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * set the accounts name
     */
    accountAccountNumberNamePut = (requestParameters: AccountAccountNumberNamePutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberNamePut');
        throwIfRequired(requestParameters, 'AccountName', 'accountAccountNumberNamePut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/name'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'PUT',
            headers,
            body: requestParameters.AccountName,
        });
    };

    /**
     * remove nfc from account
     */
    accountAccountNumberNfcDelete = (requestParameters: AccountAccountNumberNfcDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberNfcDelete');
        throwIfRequired(requestParameters, 'Nfc', 'accountAccountNumberNfcDelete');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/nfc'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'DELETE',
            headers,
            body: requestParameters.Nfc,
        });
    };

    /**
     * get an accounts nfc devices
     */
    accountAccountNumberNfcGet = (requestParameters: AccountAccountNumberNfcGetRequest): Observable<Array<NfcItem>> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberNfcGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<NfcItem>>({
            path: '/account/{account_number}/nfc'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * add nfc to account
     */
    accountAccountNumberNfcPost = (requestParameters: AccountAccountNumberNfcPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberNfcPost');
        throwIfRequired(requestParameters, 'Nfc', 'accountAccountNumberNfcPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/nfc'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'POST',
            headers,
            body: requestParameters.Nfc,
        });
    };

    /**
     * qrcode for person and account
     */
    accountAccountNumberQrGet = (requestParameters: AccountAccountNumberQrGetRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberQrGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.amount && { 'amount': requestParameters.amount }),
            ...(requestParameters.remittance_info && { 'remittance_info': requestParameters.remittance_info }),
        };

        return this.request<void>({
            path: '/account/{account_number}/qr'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * qrcode for person and account
     */
    accountAccountNumberQrPngGet = (requestParameters: AccountAccountNumberQrPngGetRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberQrPngGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.amount && { 'amount': requestParameters.amount }),
            ...(requestParameters.remittance_info && { 'remittance_info': requestParameters.remittance_info }),
        };

        return this.request<void>({
            path: '/account/{account_number}/qr.png'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list all card_providers and their card_types, that can be ordered for the current account
     * show valid card providers
     */
    accountAccountNumberValidCardProviderGet = (requestParameters: AccountAccountNumberValidCardProviderGetRequest): Observable<Array<ValidCardProvider>> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberValidCardProviderGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ValidCardProvider>>({
            path: '/account/{account_number}/valid_card_provider'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * depending on the context, the next order might be free or cost
     * get the payable fee for the next order of a virtual card
     */
    accountAccountNumberVirtualCardOrderingFeeGet = (requestParameters: AccountAccountNumberVirtualCardOrderingFeeGetRequest): Observable<VirtualCardOrderFee> => {
        throwIfRequired(requestParameters, 'account_number', 'accountAccountNumberVirtualCardOrderingFeeGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<VirtualCardOrderFee>({
            path: '/account/{account_number}/card_agreement/virtual_card_ordering_fee'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * get get the account dispo activity
     */
    accountDispoActivityGet = (requestParameters: AccountDispoActivityGetRequest): Observable<DispoActivity> => {
        throwIfRequired(requestParameters, 'account_number', 'accountDispoActivityGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
            ...(requestParameters.limit && { 'limit': requestParameters.limit }),
            ...(requestParameters.offset && { 'offset': requestParameters.offset }),
            ...(requestParameters.hide_closed && { 'hide_closed': requestParameters.hide_closed }),
        };

        return this.request<DispoActivity>({
            path: '/account/{account_number}/dispo_activity'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * account information
     */
    accountGet = (): Observable<Account> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'face': this.configuration.apiKey('face') }), // FaceAuth authentication
            ...(this.configuration.apiKey && { 'finger': this.configuration.apiKey('finger') }), // FingerAuth authentication
            ...(this.configuration.apiKey && { 'qr': this.configuration.apiKey('qr') }), // QrAuth authentication
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
        };

        return this.request<Account>({
            path: '/account',
            method: 'GET',
            headers,
        });
    };

    /**
     * list of pending gift payments
     * get pending gift payments
     */
    accountGiftPaymentGet = (requestParameters: AccountGiftPaymentGetRequest): Observable<GiftPayments> => {
        throwIfRequired(requestParameters, 'account_number', 'accountGiftPaymentGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.filter_by_person_id && { 'filter_by_person_id': requestParameters.filter_by_person_id }),
        };

        return this.request<GiftPayments>({
            path: '/account/{account_number}/gift_payment'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * accepting a gift payment completes the transaction
     * accept a gift payment
     */
    accountGiftPaymentPut = (requestParameters: AccountGiftPaymentPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountGiftPaymentPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/account/{account_number}/gift_payment'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'PUT',
            headers,
            body: requestParameters.PlainTransactionIdentifier,
        });
    };

    /**
     * used to replicate merchants transactions 
     * register client with merchant mobile tag
     */
    accountMobileTagPut = (requestParameters: AccountMobileTagPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountMobileTagPut');
        throwIfRequired(requestParameters, 'link', 'accountMobileTagPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.link && { 'link': requestParameters.link }),
        };

        return this.request<void>({
            path: '/account/{account_number}/mobile_tag'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'PUT',
            headers,
            query,
        });
    };

    /**
     * get the currently notification configuration
     */
    accountNotificationGet = (requestParameters: AccountNotificationGetRequest): Observable<Array<NotificationGroup>> => {
        throwIfRequired(requestParameters, 'account_number', 'accountNotificationGet');
        throwIfRequired(requestParameters, 'person_id', 'accountNotificationGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.account_number && { 'account_number': requestParameters.account_number }),
            ...(requestParameters.person_id && { 'person_id': requestParameters.person_id }),
        };

        return this.request<Array<NotificationGroup>>({
            path: '/account/notification',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * change notification configuration
     */
    accountNotificationPut = (requestParameters: AccountNotificationPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountNotificationPut');
        throwIfRequired(requestParameters, 'person_id', 'accountNotificationPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.account_number && { 'account_number': requestParameters.account_number }),
            ...(requestParameters.person_id && { 'person_id': requestParameters.person_id }),
        };

        return this.request<void>({
            path: '/account/notification',
            method: 'PUT',
            headers,
            query,
            body: requestParameters.Notification,
        });
    };

    /**
     * create new account
     */
    accountPost = (requestParameters: AccountPostRequest): Observable<PersonAndAccount> => {
        throwIfRequired(requestParameters, 'NewLoginAndAccount', 'accountPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<PersonAndAccount>({
            path: '/account',
            method: 'POST',
            headers,
            body: requestParameters.NewLoginAndAccount,
        });
    };

    /**
     * get a list of purchased products
     * list of products ordered
     */
    accountProductOrderList = (requestParameters: AccountProductOrderListRequest): Observable<Array<AccountProductOrder>> => {
        throwIfRequired(requestParameters, 'account_number', 'accountProductOrderList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
        };

        return this.request<Array<AccountProductOrder>>({
            path: '/account/{account_number}/product_orders'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * delete scheduled transaction
     * delete a scheduled transaction
     */
    accountScheduledTransactionDelete = (requestParameters: AccountScheduledTransactionDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'accountScheduledTransactionDelete');
        throwIfRequired(requestParameters, 'ta_id', 'accountScheduledTransactionDelete');
        throwIfRequired(requestParameters, 'scheduled_transaction_id', 'accountScheduledTransactionDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.scheduled_transaction_id && { 'scheduled_transaction_id': requestParameters.scheduled_transaction_id }),
        };

        return this.request<void>({
            path: '/account/{account_number}/scheduled_transaction'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * list of scheduled transactions for given account
     * list transaction schedule for account
     */
    accountScheduledTransactionGet = (requestParameters: AccountScheduledTransactionGetRequest): Observable<Array<ScheduledTransaction>> => {
        throwIfRequired(requestParameters, 'account_number', 'accountScheduledTransactionGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.show_history && { 'show_history': requestParameters.show_history }),
        };

        return this.request<Array<ScheduledTransaction>>({
            path: '/account/{account_number}/scheduled_transaction'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * change scheduled transaction
     * change a scheduled transaction
     */
    accountScheduledTransactionPut = (requestParameters: AccountScheduledTransactionPutRequest): Observable<ScheduledTransactionIdentifier> => {
        throwIfRequired(requestParameters, 'account_number', 'accountScheduledTransactionPut');
        throwIfRequired(requestParameters, 'ta_id', 'accountScheduledTransactionPut');
        throwIfRequired(requestParameters, 'scheduled_transaction_id', 'accountScheduledTransactionPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.scheduled_transaction_id && { 'scheduled_transaction_id': requestParameters.scheduled_transaction_id }),
        };

        return this.request<ScheduledTransactionIdentifier>({
            path: '/account/{account_number}/scheduled_transaction'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'PUT',
            headers,
            query,
            body: requestParameters.ScheduledTransactionUpdate,
        });
    };

    /**
     * search for an account, all parameters given will be considered in the search. searches for name or custom name use double metaphone to find different spellings of the same word. please note that this is not an exact search, so there might be more results than expected. also we split name and custom name by space and minus and index them separate. so if an account is named Hugo-Egon the following searches will find him, \'hugo\',\'egon\', \'hugo-egon\',\'egon hugo\',...   search logic uses the following algorithm:    1 exact search using the provided parameter of the following    * account number    * iban    * memo       2 if nothing was found we continue inexact search using allprovided  parameters    please note that the result is currently sorted by account_id not by \"match quality\", that may change in the future. 
     * search by accounts by their attributes.
     */
    accountSearch = (requestParameters: AccountSearchRequest): Observable<Array<AccountParams>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.account_number && { 'account_number': requestParameters.account_number }),
            ...(requestParameters.iban && { 'iban': requestParameters.iban }),
            ...(requestParameters.memo && { 'memo': requestParameters.memo }),
            ...(requestParameters.name && { 'name': requestParameters.name }),
            ...(requestParameters.custom_name && { 'custom_name': requestParameters.custom_name }),
            ...(requestParameters.account_type_id && { 'account_type_id': requestParameters.account_type_id }),
            ...(requestParameters.limit && { 'limit': requestParameters.limit }),
            ...(requestParameters.offset && { 'offset': requestParameters.offset }),
        };

        return this.request<Array<AccountParams>>({
            path: '/account/search',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * list of known account types
     */
    accountTypeGet = (): Observable<Array<AccountType>> => {
        return this.request<Array<AccountType>>({
            path: '/account_type',
            method: 'GET',
        });
    };

    /**
     * get all accounts of a person
     */
    accountsGet = (): Observable<Array<AccountParams>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<AccountParams>>({
            path: '/accounts',
            method: 'GET',
            headers,
        });
    };

    /**
     * register customer to atm
     */
    atmLoginPost = (requestParameters: AtmLoginPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'IBeacon', 'atmLoginPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/atm/login',
            method: 'POST',
            headers,
            body: requestParameters.IBeacon,
        });
    };

    /**
     * get details for payment token
     */
    atmPayoutGet = (requestParameters: AtmPayoutGetRequest): Observable<PayoutData> => {
        throwIfRequired(requestParameters, 'token', 'atmPayoutGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.token && { 'token': requestParameters.token }),
        };

        return this.request<PayoutData>({
            path: '/atm/payout',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * authorize payout
     */
    atmPayoutPost = (requestParameters: AtmPayoutPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'PayoutRequest', 'atmPayoutPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/atm/payout',
            method: 'POST',
            headers,
            body: requestParameters.PayoutRequest,
        });
    };

    /**
     * schedule an order of a physical card for a companion that has just been invited
     * order a physical card for a companion
     */
    companionCompanionCodeCardPost = (requestParameters: CompanionCompanionCodeCardPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'companion_code', 'companionCompanionCodeCardPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.card_color && { 'card_color': requestParameters.card_color }),
            ...(requestParameters.secret_answer && { 'secret_answer': requestParameters.secret_answer }),
        };

        return this.request<void>({
            path: '/companion/{companion_code}/card'.replace('{companion_code}', encodeURI(requestParameters.companion_code)),
            method: 'POST',
            headers,
            query,
        });
    };

    /**
     * list of known Countries
     */
    countryGet = (requestParameters: CountryGetRequest): Observable<Array<Country>> => {

        const query: HttpQuery = {
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Array<Country>>({
            path: '/country',
            method: 'GET',
            query,
        });
    };

    /**
     * get a stamp or signature image of a laboratory
     * get a document
     */
    externalLaboratoryDocumentGet = (requestParameters: ExternalLaboratoryDocumentGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'external_laboratory_id', 'externalLaboratoryDocumentGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.document_type && { 'document_type': requestParameters.document_type }),
        };

        return this.request<Blob>({
            path: '/external_laboratory/{external_laboratory_id}/document'.replace('{external_laboratory_id}', encodeURI(requestParameters.external_laboratory_id)),
            method: 'GET',
            headers,
            query,
            responseType: 'blob'
        });
    };

    /**
     * do a loadtest
     */
    loaddtestSessionPost = (requestParameters: LoaddtestSessionPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'session', 'loaddtestSessionPost');

        const query: HttpQuery = {
            ...(requestParameters.type && { 'type': requestParameters.type }),
        };

        return this.request<void>({
            path: '/loaddtest/{session}'.replace('{session}', encodeURI(requestParameters.session)),
            method: 'POST',
            query,
        });
    };

    /**
     * deactivate nfc
     */
    nfcUidDelete = (requestParameters: NfcUidDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'uid', 'nfcUidDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/nfc/{uid}'.replace('{uid}', encodeURI(requestParameters.uid)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get nfc data
     */
    nfcUidGet = (requestParameters: NfcUidGetRequest): Observable<NfcData> => {
        throwIfRequired(requestParameters, 'uid', 'nfcUidGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<NfcData>({
            path: '/nfc/{uid}'.replace('{uid}', encodeURI(requestParameters.uid)),
            method: 'GET',
            headers,
        });
    };

    /**
     * create new nfc
     */
    nfcUidPost = (requestParameters: NfcUidPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'uid', 'nfcUidPost');
        throwIfRequired(requestParameters, 'NfcData', 'nfcUidPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/nfc/{uid}'.replace('{uid}', encodeURI(requestParameters.uid)),
            method: 'POST',
            headers,
            body: requestParameters.NfcData,
        });
    };

    /**
     * update nfc data
     */
    nfcUidPut = (requestParameters: NfcUidPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'uid', 'nfcUidPut');
        throwIfRequired(requestParameters, 'NfcData', 'nfcUidPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/nfc/{uid}'.replace('{uid}', encodeURI(requestParameters.uid)),
            method: 'PUT',
            headers,
            body: requestParameters.NfcData,
        });
    };

    /**
     * get pending_requests
     */
    pendingRequestsAccountNumberGet = (requestParameters: PendingRequestsAccountNumberGetRequest): Observable<Array<PendingRequest>> => {
        throwIfRequired(requestParameters, 'account_number', 'pendingRequestsAccountNumberGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<PendingRequest>>({
            path: '/pending_requests/{account_number}'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * update pending_requests
     */
    pendingRequestsAccountNumberPut = (requestParameters: PendingRequestsAccountNumberPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'pendingRequestsAccountNumberPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/pending_requests/{account_number}'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'PUT',
            headers,
            body: requestParameters.PendingRequestUpdate,
        });
    };

    /**
     * please note that the only time the only time the secret_key is shown is in this response
     * create an access key for an account
     */
    personAccountsAccessKeyPost = (requestParameters: PersonAccountsAccessKeyPostRequest): Observable<AccessKeyWithPassword> => {
        throwIfRequired(requestParameters, 'person_id', 'personAccountsAccessKeyPost');
        throwIfRequired(requestParameters, 'account_number', 'personAccountsAccessKeyPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<AccessKeyWithPassword>({
            path: '/person/accounts/{person_id}/access_key/{account_number}'.replace('{person_id}', encodeURI(requestParameters.person_id)).replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'POST',
            headers,
            body: requestParameters.AccessKeyName,
        });
    };

    /**
     * get all accounts of a person
     */
    personAccountsPersonIdGet = (requestParameters: PersonAccountsPersonIdGetRequest): Observable<Array<AccountParams>> => {
        throwIfRequired(requestParameters, 'person_id', 'personAccountsPersonIdGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<AccountParams>>({
            path: '/person/accounts/{person_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * create an extra account for a person
     */
    personAccountsPersonIdPost = (requestParameters: PersonAccountsPersonIdPostRequest): Observable<Array<AccountIdentifier>> => {
        throwIfRequired(requestParameters, 'person_id', 'personAccountsPersonIdPost');
        throwIfRequired(requestParameters, 'currency', 'personAccountsPersonIdPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.currency && { 'currency': requestParameters.currency }),
        };

        return this.request<Array<AccountIdentifier>>({
            path: '/person/accounts/{person_id}'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            headers,
            query,
        });
    };

    /**
     * deactivates notifications for the given device
     */
    personApnDeviceIdDelete = (requestParameters: PersonApnDeviceIdDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'device_id', 'personApnDeviceIdDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/apn/{device_id}'.replace('{device_id}', encodeURI(requestParameters.device_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get device token for current device
     */
    personApnDeviceIdGet = (requestParameters: PersonApnDeviceIdGetRequest): Observable<DeviceToken> => {
        throwIfRequired(requestParameters, 'device_id', 'personApnDeviceIdGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<DeviceToken>({
            path: '/person/apn/{device_id}'.replace('{device_id}', encodeURI(requestParameters.device_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * set the latest notifikation device-token for the given device
     */
    personApnDeviceIdPost = (requestParameters: PersonApnDeviceIdPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'device_id', 'personApnDeviceIdPost');
        throwIfRequired(requestParameters, 'DeviceToken', 'personApnDeviceIdPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/apn/{device_id}'.replace('{device_id}', encodeURI(requestParameters.device_id)),
            method: 'POST',
            headers,
            body: requestParameters.DeviceToken,
        });
    };

    /**
     * get all device tokens for current person
     */
    personApnGet = (): Observable<Array<DeviceTokenItem>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<DeviceTokenItem>>({
            path: '/person/apn',
            method: 'GET',
            headers,
        });
    };

    /**
     * delete a beneficiary
     */
    personBeneficiaryDelete = (requestParameters: PersonBeneficiaryDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'beneficiary_id', 'personBeneficiaryDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/beneficiary/{beneficiary_id}'.replace('{beneficiary_id}', encodeURI(requestParameters.beneficiary_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get all beneficaiaries a person has stored
     */
    personBeneficiaryGet = (requestParameters: PersonBeneficiaryGetRequest): Observable<Array<Beneficiary>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.debtorAccount && { 'debtorAccount': requestParameters.debtorAccount }),
            ...(requestParameters.creditorAccount && { 'creditorAccount': requestParameters.creditorAccount }),
            ...(requestParameters.creditorName && { 'creditorName': requestParameters.creditorName }),
            ...(requestParameters.remittanceInformationUnstructured && { 'remittanceInformationUnstructured': requestParameters.remittanceInformationUnstructured }),
            ...(requestParameters.limit && { 'limit': requestParameters.limit }),
            ...(requestParameters.offset && { 'offset': requestParameters.offset }),
        };

        return this.request<Array<Beneficiary>>({
            path: '/person/beneficiary',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * create a beneficiary
     */
    personBeneficiaryPost = (requestParameters: PersonBeneficiaryPostRequest): Observable<BeneficiaryId> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<BeneficiaryId>({
            path: '/person/beneficiary',
            method: 'POST',
            headers,
            body: requestParameters.NewBeneficiary,
        });
    };

    /**
     * update a beneficiary
     */
    personBeneficiaryPut = (requestParameters: PersonBeneficiaryPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'beneficiary_id', 'personBeneficiaryPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/beneficiary/{beneficiary_id}'.replace('{beneficiary_id}', encodeURI(requestParameters.beneficiary_id)),
            method: 'PUT',
            headers,
            body: requestParameters.NewBeneficiary,
        });
    };

    /**
     * get the persons config
     */
    personConfigGet = (): Observable<Config> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Config>({
            path: '/person/config',
            method: 'GET',
            headers,
        });
    };

    /**
     * update the persons config
     */
    personConfigPut = (requestParameters: PersonConfigPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'Config', 'personConfigPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/config',
            method: 'PUT',
            headers,
            body: requestParameters.Config,
        });
    };

    /**
     * delete an existing email address
     */
    personEmailAddressEmailAddressIdDelete = (requestParameters: PersonEmailAddressEmailAddressIdDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'email_address_id', 'personEmailAddressEmailAddressIdDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/email_address/{email_address_id}'.replace('{email_address_id}', encodeURI(requestParameters.email_address_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * update an existing email address
     */
    personEmailAddressEmailAddressIdPut = (requestParameters: PersonEmailAddressEmailAddressIdPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'email_address_id', 'personEmailAddressEmailAddressIdPut');
        throwIfRequired(requestParameters, 'NewEmailAddress', 'personEmailAddressEmailAddressIdPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/email_address/{email_address_id}'.replace('{email_address_id}', encodeURI(requestParameters.email_address_id)),
            method: 'PUT',
            headers,
            body: requestParameters.NewEmailAddress,
        });
    };

    /**
     * get all email addresses of a person
     */
    personEmailAddressGet = (): Observable<Array<EmailAddress>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<EmailAddress>>({
            path: '/person/email_address',
            method: 'GET',
            headers,
        });
    };

    /**
     * add a new email address to a person
     */
    personEmailAddressPost = (requestParameters: PersonEmailAddressPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'NewEmailAddress', 'personEmailAddressPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/email_address',
            method: 'POST',
            headers,
            body: requestParameters.NewEmailAddress,
        });
    };

    /**
     * get a previously sent email
     */
    personEmailGet = (requestParameters: PersonEmailGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'email_id', 'personEmailGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.email_id && { 'email_id': requestParameters.email_id }),
        };

        return this.request<Blob>({
            path: '/person/email',
            method: 'GET',
            headers,
            query,
            responseType: 'blob'
        });
    };

    /**
     * list suggested amounts a user can choose from when debitting his external account
     */
    personExternalAccountAmountsGet = (requestParameters: PersonExternalAccountAmountsGetRequest): Observable<Array<ExternalAccountAmount>> => {
        throwIfRequired(requestParameters, 'external_account_id', 'personExternalAccountAmountsGet');
        throwIfRequired(requestParameters, 'account_number', 'personExternalAccountAmountsGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.external_account_id && { 'external_account_id': requestParameters.external_account_id }),
            ...(requestParameters.account_number && { 'account_number': requestParameters.account_number }),
        };

        return this.request<Array<ExternalAccountAmount>>({
            path: '/person/external_account/amounts',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * remove an external account from a person
     */
    personExternalAccountDelete = (requestParameters: PersonExternalAccountDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'external_account_id', 'personExternalAccountDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.person_id && { 'person_id': requestParameters.person_id }),
            ...(requestParameters.external_account_id && { 'external_account_id': requestParameters.external_account_id }),
        };

        return this.request<void>({
            path: '/person/external_account',
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * list all external accounts a person has stored
     */
    personExternalAccountGet = (requestParameters: PersonExternalAccountGetRequest): Observable<Array<ExternalAccount>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.person_id && { 'person_id': requestParameters.person_id }),
        };

        return this.request<Array<ExternalAccount>>({
            path: '/person/external_account',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * valid card brands can be looked up using `GET /person/external_account_valid_card_brands`
     * add an external account to a person
     */
    personExternalAccountPost = (requestParameters: PersonExternalAccountPostRequest): Observable<ExternalAccountID> => {
        throwIfRequired(requestParameters, 'NewExternalAccount', 'personExternalAccountPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.person_id && { 'person_id': requestParameters.person_id }),
        };

        return this.request<ExternalAccountID>({
            path: '/person/external_account',
            method: 'POST',
            headers,
            query,
            body: requestParameters.NewExternalAccount,
        });
    };

    /**
     * update an external account
     */
    personExternalAccountPut = (requestParameters: PersonExternalAccountPutRequest): Observable<ExternalAccountID> => {
        throwIfRequired(requestParameters, 'external_account_id', 'personExternalAccountPut');
        throwIfRequired(requestParameters, 'NewExternalAccount', 'personExternalAccountPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.person_id && { 'person_id': requestParameters.person_id }),
            ...(requestParameters.external_account_id && { 'external_account_id': requestParameters.external_account_id }),
        };

        return this.request<ExternalAccountID>({
            path: '/person/external_account',
            method: 'PUT',
            headers,
            query,
            body: requestParameters.NewExternalAccount,
        });
    };

    /**
     * list all valid card brands
     */
    personExternalAccountValidCardBrandsGet = (): Observable<Array<ExternalAccountValidCardBrand>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ExternalAccountValidCardBrand>>({
            path: '/person/external_account_valid_card_brands',
            method: 'GET',
            headers,
        });
    };

    /**
     * delete a beneficiary
     */
    personFavoriteDelete = (requestParameters: PersonFavoriteDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'favorite_id', 'personFavoriteDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/favorite/{favorite_id}'.replace('{favorite_id}', encodeURI(requestParameters.favorite_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get all favorites a person has stored
     */
    personFavoriteGet = (requestParameters: PersonFavoriteGetRequest): Observable<Array<Favorite>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ownAccount && { 'ownAccount': requestParameters.ownAccount }),
            ...(requestParameters.counterpartyAccount && { 'counterpartyAccount': requestParameters.counterpartyAccount }),
            ...(requestParameters.counterpartyName && { 'counterpartyName': requestParameters.counterpartyName }),
            ...(requestParameters.remittanceInformationUnstructured && { 'remittanceInformationUnstructured': requestParameters.remittanceInformationUnstructured }),
            ...(requestParameters.limit && { 'limit': requestParameters.limit }),
            ...(requestParameters.offset && { 'offset': requestParameters.offset }),
        };

        return this.request<Array<Favorite>>({
            path: '/person/favorite',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * create a favorite
     */
    personFavoritePost = (requestParameters: PersonFavoritePostRequest): Observable<FavoriteId> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<FavoriteId>({
            path: '/person/favorite',
            method: 'POST',
            headers,
            body: requestParameters.NewFavorite,
        });
    };

    /**
     * update a favorite
     */
    personFavoritePut = (requestParameters: PersonFavoritePutRequest): Observable<FavoriteId> => {
        throwIfRequired(requestParameters, 'favorite_id', 'personFavoritePut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<FavoriteId>({
            path: '/person/favorite/{favorite_id}'.replace('{favorite_id}', encodeURI(requestParameters.favorite_id)),
            method: 'PUT',
            headers,
            body: requestParameters.NewFavorite,
        });
    };

    /**
     * get the fcm push tokens for the current person
     */
    personFcmGet = (): Observable<Array<FcmTokenItem>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<FcmTokenItem>>({
            path: '/person/fcm',
            method: 'GET',
            headers,
        });
    };

    /**
     * remove push token from the current person
     */
    personFcmTokenDelete = (requestParameters: PersonFcmTokenDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'token', 'personFcmTokenDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/fcm/{token}'.replace('{token}', encodeURI(requestParameters.token)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * upload fcm push token for the current person, please note that duplicates are filtered on the server side
     */
    personFcmTokenPut = (requestParameters: PersonFcmTokenPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'token', 'personFcmTokenPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/fcm/{token}'.replace('{token}', encodeURI(requestParameters.token)),
            method: 'PUT',
            headers,
        });
    };

    /**
     * proxy to ndident, to add a fingerprint to a person
     * add fingerprint to person
     */
    personFingerprintPost = (requestParameters: PersonFingerprintPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'NewFingerprint', 'personFingerprintPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'face': this.configuration.apiKey('face') }), // FaceAuth authentication
            ...(this.configuration.apiKey && { 'qr': this.configuration.apiKey('qr') }), // QrAuth authentication
        };

        return this.request<void>({
            path: '/person/fingerprint',
            method: 'POST',
            headers,
            body: requestParameters.NewFingerprint,
        });
    };

    /**
     * get the person data
     */
    personGet = (): Observable<Person> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Person>({
            path: '/person',
            method: 'GET',
            headers,
        });
    };

    /**
     * leave a location
     * leave a location
     */
    personLocationCheckout = (requestParameters: PersonLocationCheckoutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'personLocationCheckout');
        throwIfRequired(requestParameters, 'location_id', 'personLocationCheckout');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.location_id && { 'location_id': requestParameters.location_id }),
        };

        return this.request<void>({
            path: '/person/location',
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * a list of locations the current person is logged in
     */
    personLocationGet = (): Observable<Array<Location>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Location>>({
            path: '/person/location',
            method: 'GET',
            headers,
        });
    };

    /**
     * a list of locations the current person was logged in
     */
    personLocationHistoryGet = (): Observable<Array<Location>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Location>>({
            path: '/person/location_history',
            method: 'GET',
            headers,
        });
    };

    /**
     * set the password to a new value
     */
    personNewpasswordPost = (requestParameters: PersonNewpasswordPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'NewPassword', 'personNewpasswordPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/newpassword',
            method: 'POST',
            headers,
            body: requestParameters.NewPassword,
        });
    };

    /**
     * updates a persons pin code to a given number
     */
    personPinPut = (requestParameters: PersonPinPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'Pin', 'personPinPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person/pin',
            method: 'PUT',
            headers,
            body: requestParameters.Pin,
        });
    };

    /**
     * update the person data
     */
    personPut = (requestParameters: PersonPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'Person', 'personPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/person',
            method: 'PUT',
            headers,
            body: requestParameters.Person,
        });
    };

    /**
     * set the referral link and generate qr code
     */
    personReferralLinkPut = (requestParameters: PersonReferralLinkPutRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'personReferralLinkPut');
        throwIfRequired(requestParameters, 'referral_link', 'personReferralLinkPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.referral_link && { 'referral_link': requestParameters.referral_link }),
        };

        return this.request<Blob>({
            path: '/person/{person_id}/referral_link'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
            query,
            responseType: 'blob'
        });
    };

    /**
     * get the qr_code for referrals
     */
    personReferralQrCodeGet = (requestParameters: PersonReferralQrCodeGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'person_id', 'personReferralQrCodeGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Blob>({
            path: '/person/{person_id}/referral_qr_code'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * requests a password reset token
     */
    personResetpasswordPost = (requestParameters: PersonResetpasswordPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ResetPassword', 'personResetpasswordPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/person/resetpassword',
            method: 'POST',
            headers,
            body: requestParameters.ResetPassword,
        });
    };

    /**
     * set new password using the token received by email
     */
    personResetpasswordPut = (requestParameters: PersonResetpasswordPutRequest): Observable<NewPasswordResponse> => {
        throwIfRequired(requestParameters, 'NewPasswordToken', 'personResetpasswordPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<NewPasswordResponse>({
            path: '/person/resetpassword',
            method: 'PUT',
            headers,
            body: requestParameters.NewPasswordToken,
        });
    };

    /**
     * get details of a product order to display in service center
     * get details of a product order
     */
    productOrderGet = (requestParameters: ProductOrderGetRequest): Observable<ProductOrderDetails> => {
        throwIfRequired(requestParameters, 'ta_id', 'productOrderGet');
        throwIfRequired(requestParameters, 'transaction_id', 'productOrderGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<ProductOrderDetails>({
            path: '/product_order',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * set the ID of the testkit used for the online test
     * set the product type (testkit)
     */
    productOrderProductTypeGet = (requestParameters: ProductOrderProductTypeGetRequest): Observable<ProductTypeOut> => {
        throwIfRequired(requestParameters, 'ta_id', 'productOrderProductTypeGet');
        throwIfRequired(requestParameters, 'transaction_id', 'productOrderProductTypeGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<ProductTypeOut>({
            path: '/product_order/product_type',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * set the ID of the testkit used for the online test
     * set the product type (testkit)
     */
    productOrderProductTypePost = (requestParameters: ProductOrderProductTypePostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'product_type_id', 'productOrderProductTypePost');
        throwIfRequired(requestParameters, 'ta_id', 'productOrderProductTypePost');
        throwIfRequired(requestParameters, 'transaction_id', 'productOrderProductTypePost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<void>({
            path: '/product_order/product_type/{product_type_id}'.replace('{product_type_id}', encodeURI(requestParameters.product_type_id)),
            method: 'POST',
            headers,
            query,
        });
    };

    /**
     */
    productVoucherEmailAddressGet = (requestParameters: ProductVoucherEmailAddressGetRequest): Observable<Array<ProductVoucher>> => {
        throwIfRequired(requestParameters, 'email_address', 'productVoucherEmailAddressGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ProductVoucher>>({
            path: '/product_voucher/{email_address}'.replace('{email_address}', encodeURI(requestParameters.email_address)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    productVoucherPost = (requestParameters: ProductVoucherPostRequest): Observable<ProductVoucher> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<ProductVoucher>({
            path: '/product_voucher',
            method: 'POST',
            headers,
            body: requestParameters.NewProductVoucher,
        });
    };

    /**
     */
    productVoucherVoucherGroupClientPost = (requestParameters: ProductVoucherVoucherGroupClientPostRequest): Observable<Array<ProductVoucher>> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ProductVoucher>>({
            path: '/product_voucher/voucher_group_client',
            method: 'POST',
            headers,
            body: requestParameters.NewProductVoucherGroup,
        });
    };

    /**
     */
    productVoucherVoucherGroupGet = (requestParameters: ProductVoucherVoucherGroupGetRequest): Observable<Array<ProductVoucher>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.qlimit && { 'qlimit': requestParameters.qlimit }),
            ...(requestParameters.qoffset && { 'qoffset': requestParameters.qoffset }),
        };

        return this.request<Array<ProductVoucher>>({
            path: '/product_voucher/voucher_group',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    productVoucherVoucherGroupPost = (requestParameters: ProductVoucherVoucherGroupPostRequest): Observable<Array<ProductVoucher>> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ProductVoucher>>({
            path: '/product_voucher/voucher_group',
            method: 'POST',
            headers,
            body: requestParameters.NewProductVoucherGroup,
        });
    };

    /**
     */
    productVoucherVoucherGroupVoucherGroupGet = (requestParameters: ProductVoucherVoucherGroupVoucherGroupGetRequest): Observable<Array<ProductVoucher>> => {
        throwIfRequired(requestParameters, 'voucher_group', 'productVoucherVoucherGroupVoucherGroupGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ProductVoucher>>({
            path: '/product_voucher/voucher_group/{voucher_group}'.replace('{voucher_group}', encodeURI(requestParameters.voucher_group)),
            method: 'GET',
            headers,
        });
    };

    /**
     * attach document to scheduled transaction
     * attach document to scheduled transaction
     */
    scheduledTransactionDocumentPost = (requestParameters: ScheduledTransactionDocumentPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'file', 'scheduledTransactionDocumentPost');
        throwIfRequired(requestParameters, 'ta_id', 'scheduledTransactionDocumentPost');
        throwIfRequired(requestParameters, 'scheduled_transaction_id', 'scheduledTransactionDocumentPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.file !== undefined) {
            formData.append('file', requestParameters.file as any);
        }

        if (requestParameters.ta_id !== undefined) {
            formData.append('ta_id', requestParameters.ta_id as any);
        }

        if (requestParameters.scheduled_transaction_id !== undefined) {
            formData.append('scheduled_transaction_id', requestParameters.scheduled_transaction_id as any);
        }

        return this.request<void>({
            path: '/scheduled_transaction/document',
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * internal accounts
     */
    systemAccountGet = (): Observable<Array<InternalAccount>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<InternalAccount>>({
            path: '/system_account',
            method: 'GET',
            headers,
        });
    };

    /**
     * attach a document to a transaction
     */
    transactionDocumentPost = (requestParameters: TransactionDocumentPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'file', 'transactionDocumentPost');
        throwIfRequired(requestParameters, 'ta_id', 'transactionDocumentPost');
        throwIfRequired(requestParameters, 'transaction_id', 'transactionDocumentPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const formData = new FormData();
        if (requestParameters.file !== undefined) {
            formData.append('file', requestParameters.file as any);
        }

        if (requestParameters.ta_id !== undefined) {
            formData.append('ta_id', requestParameters.ta_id as any);
        }

        if (requestParameters.transaction_id !== undefined) {
            formData.append('transaction_id', requestParameters.transaction_id as any);
        }

        return this.request<void>({
            path: '/transaction/document',
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * attach a document to a transaction
     */
    transactionDocumentPresignedGet = (requestParameters: TransactionDocumentPresignedGetRequest): Observable<PresignedPost> => {
        throwIfRequired(requestParameters, 'ta_id', 'transactionDocumentPresignedGet');
        throwIfRequired(requestParameters, 'transaction_id', 'transactionDocumentPresignedGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<PresignedPost>({
            path: '/transaction/document/get_presigned_post',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * postingjournal
     */
    transactionGet = (requestParameters: TransactionGetRequest): Observable<PostingJournal> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<PostingJournal>({
            path: '/transaction',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * transfer money between accounts
     */
    transactionPost = (requestParameters: TransactionPostRequest): Observable<TransactionIdentifier> => {
        throwIfRequired(requestParameters, 'MoneyTransfer', 'transactionPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<TransactionIdentifier>({
            path: '/transaction',
            method: 'POST',
            headers,
            body: requestParameters.MoneyTransfer,
        });
    };

    /**
     * create a new voucher
     */
    voucherPost = (): Observable<void> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/voucher',
            method: 'POST',
            headers,
        });
    };

    /**
     * retrieve the current balance of a voucher
     */
    voucherVoucherNumberGet = (requestParameters: VoucherVoucherNumberGetRequest): Observable<Balance> => {
        throwIfRequired(requestParameters, 'voucher_number', 'voucherVoucherNumberGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Balance>({
            path: '/voucher/{voucher_number}'.replace('{voucher_number}', encodeURI(requestParameters.voucher_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * load or use the voucher
     */
    voucherVoucherNumberPost = (requestParameters: VoucherVoucherNumberPostRequest): Observable<TransactionIdentifier> => {
        throwIfRequired(requestParameters, 'voucher_number', 'voucherVoucherNumberPost');
        throwIfRequired(requestParameters, 'VoucherTransaction', 'voucherVoucherNumberPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<TransactionIdentifier>({
            path: '/voucher/{voucher_number}'.replace('{voucher_number}', encodeURI(requestParameters.voucher_number)),
            method: 'POST',
            headers,
            body: requestParameters.VoucherTransaction,
        });
    };


    operationToOperationId = {
	    "accountAccessKeyPost": "account_access_key_post" ,
	    "accountAccountNumberActivityDetailGet": "account_account_number_activity_detail_get" ,
	    "accountAccountNumberActivityGet": "account_account_number_activity_get" ,
	    "accountAccountNumberBalanceGet": "account_account_number_balance_get" ,
	    "accountAccountNumberBlockDelete": "account_account_number_block_delete" ,
	    "accountAccountNumberBlockPut": "account_account_number_block_put" ,
	    "accountAccountNumberBookableFees": "account_account_number_bookable_fees" ,
	    "accountAccountNumberCardAgreementCardAgreementIdLimitPut": "account_account_number_card_agreement_card_agreement_id_limit_put" ,
	    "accountAccountNumberCardAgreementCardAgreementIdStatePut": "account_account_number_card_agreement_card_agreement_id_state_put" ,
	    "accountAccountNumberCardAgreementCardCardIdPinGet": "account_account_number_card_agreement_card_card_id_pin_get" ,
	    "accountAccountNumberCardAgreementCardCardIdPinPut": "account_account_number_card_agreement_card_card_id_pin_put" ,
	    "accountAccountNumberCardAgreementCardCardIdResetPinCounterPut": "account_account_number_card_agreement_card_card_id_reset_pin_counter_put" ,
	    "accountAccountNumberCardAgreementCardCardIdStateGet": "account_account_number_card_agreement_card_card_id_state_get" ,
	    "accountAccountNumberCardAgreementCardCardIdStatePut": "account_account_number_card_agreement_card_card_id_state_put" ,
	    "accountAccountNumberCardAgreementCardGet": "account_account_number_card_agreement_card_get" ,
	    "accountAccountNumberCardAgreementCardPost": "account_account_number_card_agreement_card_post" ,
	    "accountAccountNumberCardAgreementDelete": "account_account_number_card_agreement_delete" ,
	    "accountAccountNumberCardAgreementGet": "account_account_number_card_agreement_get" ,
	    "accountAccountNumberCardAgreementPost": "account_account_number_card_agreement_post" ,
	    "accountAccountNumberCardTransactionDetailGet": "account_account_number_card_transaction_detail_get" ,
	    "accountAccountNumberCryptoOrderUniqueIdentifierPut": "account_account_number_crypto_order_unique_identifier_put" ,
	    "accountAccountNumberCustomNameDelete": "account_account_number_custom_name_delete" ,
	    "accountAccountNumberCustomNamePut": "account_account_number_custom_name_put" ,
	    "accountAccountNumberDetailGet": "account_account_number_detail_get" ,
	    "accountAccountNumberExternalDebitDetailGet": "account_account_number_external_debit_detail_get" ,
	    "accountAccountNumberFeesDelete": "account_account_number_fees_delete" ,
	    "accountAccountNumberFeesGet": "account_account_number_fees_get" ,
	    "accountAccountNumberFeesPut": "account_account_number_fees_put" ,
	    "accountAccountNumberLimitDelete": "account_account_number_limit_delete" ,
	    "accountAccountNumberLimitPut": "account_account_number_limit_put" ,
	    "accountAccountNumberMemoPut": "account_account_number_memo_put" ,
	    "accountAccountNumberMerchantNotificationGet": "account_account_number_merchant_notification_get" ,
	    "accountAccountNumberNamePut": "account_account_number_name_put" ,
	    "accountAccountNumberNfcDelete": "account_account_number_nfc_delete" ,
	    "accountAccountNumberNfcGet": "account_account_number_nfc_get" ,
	    "accountAccountNumberNfcPost": "account_account_number_nfc_post" ,
	    "accountAccountNumberQrGet": "account_account_number_qr_get" ,
	    "accountAccountNumberQrPngGet": "account_account_number_qr_png_get" ,
	    "accountAccountNumberValidCardProviderGet": "account_account_number_valid_card_provider_get" ,
	    "accountAccountNumberVirtualCardOrderingFeeGet": "account_account_number_virtual_card_ordering_fee_get" ,
	    "accountDispoActivityGet": "account_dispo_activity_get" ,
	    "accountGet": "account_get" ,
	    "accountGiftPaymentGet": "account_gift_payment_get" ,
	    "accountGiftPaymentPut": "account_gift_payment_put" ,
	    "accountMobileTagPut": "account_mobile_tag_put" ,
	    "accountNotificationGet": "account_notification_get" ,
	    "accountNotificationPut": "account_notification_put" ,
	    "accountPost": "account_post" ,
	    "accountProductOrderList": "account_product_order_list" ,
	    "accountScheduledTransactionDelete": "account_scheduled_transaction_delete" ,
	    "accountScheduledTransactionGet": "account_scheduled_transaction_get" ,
	    "accountScheduledTransactionPut": "account_scheduled_transaction_put" ,
	    "accountSearch": "account_search" ,
	    "accountTypeGet": "account_type_get" ,
	    "accountsGet": "accounts_get" ,
	    "atmLoginPost": "atm_login_post" ,
	    "atmPayoutGet": "atm_payout_get" ,
	    "atmPayoutPost": "atm_payout_post" ,
	    "companionCompanionCodeCardPost": "companion_companion_code_card_post" ,
	    "countryGet": "country_get" ,
	    "externalLaboratoryDocumentGet": "external_laboratory_document_get" ,
	    "loaddtestSessionPost": "loaddtest_session_post" ,
	    "nfcUidDelete": "nfc_uid_delete" ,
	    "nfcUidGet": "nfc_uid_get" ,
	    "nfcUidPost": "nfc_uid_post" ,
	    "nfcUidPut": "nfc_uid_put" ,
	    "pendingRequestsAccountNumberGet": "pending_requests_account_number_get" ,
	    "pendingRequestsAccountNumberPut": "pending_requests_account_number_put" ,
	    "personAccountsAccessKeyPost": "person_accounts_access_key_post" ,
	    "personAccountsPersonIdGet": "person_accounts_person_id_get" ,
	    "personAccountsPersonIdPost": "person_accounts_person_id_post" ,
	    "personApnDeviceIdDelete": "person_apn_device_id_delete" ,
	    "personApnDeviceIdGet": "person_apn_device_id_get" ,
	    "personApnDeviceIdPost": "person_apn_device_id_post" ,
	    "personApnGet": "person_apn_get" ,
	    "personBeneficiaryDelete": "person_beneficiary_delete" ,
	    "personBeneficiaryGet": "person_beneficiary_get" ,
	    "personBeneficiaryPost": "person_beneficiary_post" ,
	    "personBeneficiaryPut": "person_beneficiary_put" ,
	    "personConfigGet": "person_config_get" ,
	    "personConfigPut": "person_config_put" ,
	    "personEmailAddressEmailAddressIdDelete": "person_email_address_email_address_id_delete" ,
	    "personEmailAddressEmailAddressIdPut": "person_email_address_email_address_id_put" ,
	    "personEmailAddressGet": "person_email_address_get" ,
	    "personEmailAddressPost": "person_email_address_post" ,
	    "personEmailGet": "person_email_get" ,
	    "personExternalAccountAmountsGet": "person_external_account_amounts_get" ,
	    "personExternalAccountDelete": "person_external_account_delete" ,
	    "personExternalAccountGet": "person_external_account_get" ,
	    "personExternalAccountPost": "person_external_account_post" ,
	    "personExternalAccountPut": "person_external_account_put" ,
	    "personExternalAccountValidCardBrandsGet": "person_external_account_valid_card_brands_get" ,
	    "personFavoriteDelete": "person_favorite_delete" ,
	    "personFavoriteGet": "person_favorite_get" ,
	    "personFavoritePost": "person_favorite_post" ,
	    "personFavoritePut": "person_favorite_put" ,
	    "personFcmGet": "person_fcm_get" ,
	    "personFcmTokenDelete": "person_fcm_token_delete" ,
	    "personFcmTokenPut": "person_fcm_token_put" ,
	    "personFingerprintPost": "person_fingerprint_post" ,
	    "personGet": "person_get" ,
	    "personLocationCheckout": "person_location_checkout" ,
	    "personLocationGet": "person_location_get" ,
	    "personLocationHistoryGet": "person_location_history_get" ,
	    "personNewpasswordPost": "person_newpassword_post" ,
	    "personPinPut": "person_pin_put" ,
	    "personPut": "person_put" ,
	    "personReferralLinkPut": "person_referral_link_put" ,
	    "personReferralQrCodeGet": "person_referral_qr_code_get" ,
	    "personResetpasswordPost": "person_resetpassword_post" ,
	    "personResetpasswordPut": "person_resetpassword_put" ,
	    "productOrderGet": "product_order_get" ,
	    "productOrderProductTypeGet": "product_order_product_type_get" ,
	    "productOrderProductTypePost": "product_order_product_type_post" ,
	    "productVoucherEmailAddressGet": "product_voucher_email_address_get" ,
	    "productVoucherPost": "product_voucher_post" ,
	    "productVoucherVoucherGroupClientPost": "product_voucher_voucher_group_client_post" ,
	    "productVoucherVoucherGroupGet": "product_voucher_voucher_group_get" ,
	    "productVoucherVoucherGroupPost": "product_voucher_voucher_group_post" ,
	    "productVoucherVoucherGroupVoucherGroupGet": "product_voucher_voucher_group_voucher_group_get" ,
	    "scheduledTransactionDocumentPost": "scheduled_transaction_document_post" ,
	    "systemAccountGet": "system_account_get" ,
	    "transactionDocumentPost": "transaction_document_post" ,
	    "transactionDocumentPresignedGet": "transaction_document_presigned_get" ,
	    "transactionGet": "transaction_get" ,
	    "transactionPost": "transaction_post" ,
	    "voucherPost": "voucher_post" ,
	    "voucherVoucherNumberGet": "voucher_voucher_number_get" ,
	    "voucherVoucherNumberPost": "voucher_voucher_number_post"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum AccountAccountNumberCardAgreementCardAgreementIdStatePutStateEnum {
    NoRestrictions = 'No restrictions',
    CashWithdrawalTransactionsAreProhibited = 'Cash withdrawal transactions are prohibited',
    CashWithdrawalTransactionsAndTransfersAreProhibited = 'Cash withdrawal transactions and transfers are prohibited',
    AllowedBalanceRequest = 'Allowed balance request',
    OnlyDepositAndNonFinancialServiceTransactionsAreAllowed = 'Only deposit and non-financial service transactions are allowed',
    AllowedBalanceInquiryAndCreditTransactions = 'Allowed balance inquiry and credit transactions',
    AllowedBalanceInquiryDepositAndTransfersAndNonFinancialServiceTransactions = 'Allowed balance inquiry, deposit and transfers and non-financial service transactions',
    AnyTransactionsOnTheAccountAreProhibited = 'Any transactions on the account are prohibited'
}
/**
 * @export
 * @enum {string}
 */
export enum AccountAccountNumberCardAgreementCardCardIdStatePutStateEnum {
    ValidCard = 'Valid card',
    TemporaryBlockedByClient = 'Temporary blocked by client',
    PermanentBlockedByClient = 'Permanent blocked by client'
}
/**
 * @export
 * @enum {string}
 */
export enum AccountAccountNumberCardAgreementCardPostVirtualOrPhysicalEnum {
    V = 'V',
    P = 'P'
}
/**
 * @export
 * @enum {string}
 */
export enum AccountAccountNumberCardAgreementCardPostCardColorEnum {
    DarkLilac = 'Dark Lilac',
    LightLilac = 'Light Lilac',
    White = 'White'
}
/**
 * @export
 * @enum {string}
 */
export enum CompanionCompanionCodeCardPostCardColorEnum {
    DarkLilac = 'Dark Lilac',
    LightLilac = 'Light Lilac',
    White = 'White'
}
/**
 * @export
 * @enum {string}
 */
export enum ExternalLaboratoryDocumentGetDocumentTypeEnum {
    S = 'S',
    P = 'P'
}
