import React, { ReactElement } from 'react';
import { TitleCell } from '../../../content/dashboard/content/customers/basicStyledComponents/customerDetails.css';
import { Icons } from '../../../images/icons/icons';

interface IProps {
    title: string | ReactElement;
    children: Array<ReactElement> | ReactElement;
    open?: boolean;
    onExpand?: ()=>void;
}

interface IState {
   open: boolean;
}

export default class Expandable extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			open: props.open != null ? props.open : false
		};
		

     
	}

   setOpenFromExtern(open: boolean) {
       this.setState({
           open: open
       })
   }
    
	render() {
		return (
            <React.Fragment>
                <TitleCell style={{display: 'flex', whiteSpace: 'nowrap'}}>
                    <div style={{marginRight: '8px'}}
                            onClick={()=> {
                                this.setState({
                                    open: !this.state.open
                                });
                                this.props.onExpand && this.props.onExpand();
                            }}> {this.state.open === true ? Icons.arrowFacingDown() : Icons.arrowFacingRight()} 
                        </div>
                        {this.props.title}
                </TitleCell>
                {this.state.open === true ? this.props.children : null	}
            </React.Fragment>
		);
	}
}
