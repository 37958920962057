//@ts-nocheck
import React, { ReactNode } from 'react';
import AuthScreen, { IAuthScreenState, IAuthScreenProps } from '../authScreen';
import { withRouter } from 'react-router';
import { translate } from '../../../common/language/translate';
import { StyledAuthComponent } from './auth.css';
import { Images } from '../../../images';
import ButtonOk from '../../../components/atomiccompoents/buttons/buttonOk';
import { FlexBox } from '../auth.css';

interface IState extends IAuthScreenState {}

interface IProps extends IAuthScreenProps {}

class ProcessCompleteComponentClass extends AuthScreen<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(): void {}

	private gotoDashboard(): void {
		//TODO: Set UserDataStore.user, maybe performLogin()
		window.location.pathname = process.env.REACT_APP_ROOT_PATH + '/';
	}

	defineContent(): ReactNode {
		return null;
	}

	reviveState(): void {}

	render() {
		return (
			<StyledAuthComponent>
				<div>{translate('auth.complete.title')}</div>
				<div>{translate('auth.complete.title')}</div>

				<FlexBox>{Images.passwordApproved()}</FlexBox>

				<ButtonOk
					disableSpinner={true}
					id="gotoDashboard"
					style={{ width: '100%' }}
					onClick={this.gotoDashboard}>
					{translate('button.gotoDashboard')}
				</ButtonOk>
			</StyledAuthComponent>
		);
	}
}

export const ProcessCompleteComponent = withRouter(ProcessCompleteComponentClass);
