import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { FormError } from './formError';
import { Icons } from '../../../images/icons/icons';

interface IProps {
	id: string;
	value?: boolean;
	defaultValue?: boolean;
	disabled?: boolean;
	onChange?: ((value: boolean) => void) | undefined;
	label?: string;
	showError?: boolean;
	textStyle?: React.CSSProperties;
	containerStyle?: React.CSSProperties;
	error?: string;
	bigCheckbox?: boolean;
}

interface IState {
	checked?: boolean;
	error?: string;
	isStateValue?: boolean;
}

export class CheckBox extends React.Component<IProps, IState> {
	notification: ((value: boolean) => void) | undefined = undefined;
	constructor(props: IProps) {
		super(props);

		this.state = {
			checked: props.defaultValue != null ? props.defaultValue === true : props.value,
			error: props.error,
		};
		this.notification =
			props.onChange == null ? this.notification : props.onChange.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	static getDerivedStateFromProps(props: IProps, state: IState): IState | null {
		if (
			props.error !== state.error ||
			(props.value != null &&
				props.value !== state.checked &&
				state.isStateValue !== true)
		) {
			return {
				error: props.error,
				checked: props.value === true,
				isStateValue: false,
			};
		}

		return {
			isStateValue: false,
		};
	}

	onChange(event: SyntheticEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (this.props.disabled === true) {
			return;
		}
		if (this.props.onChange != null && typeof this.props.onChange === 'function') {
			this.props.onChange(this.state.checked !== true);
		}
		this.setState({ checked: this.state.checked !== true, isStateValue: true });
	}

    render() {
		console.log(this.props.bigCheckbox)
        return (
            <CheckBoxContainer style={this.props.containerStyle}>
                <HiddenCheckbox
                    id={this.props.id}
                    onChange={this.onChange}
                    disabled={this.props.disabled}
                />
                <StyledCheckbox
                    onClick={this.onChange}
                    id={this.props.id + '_inner'}
                    value={this.state.checked === true}
					bigCheckbox={this.props.bigCheckbox}
                    disabled={this.props.disabled}>
                   		{Icons.checkBoxIcon()}
                </StyledCheckbox>
                <CheckText style={this.props.textStyle}>{this.props.label != null ? this.props.label : ''}</CheckText>
                <StyledFormError
                    active={this.props.showError !== false}
                    error={this.state.error}
                />
            </CheckBoxContainer>
        );
    }
}

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${(props: IProps) => (props.bigCheckbox === true ? '24px' : '16px' )};
  height: ${(props: IProps) => (props.bigCheckbox === true ? '24px' : '16px' )};
  background: ${(props: IProps) => (props.disabled === true ? '#E1E2E1' : '#FFFFFF')}
  border: 1px solid #A0A0A0;
  margin: ${(props: IProps) => (props.bigCheckbox === true ? '8px' : '2px' )};
  transition: all 150ms;
	svg {
		visibility: ${(props: IProps) => (props.value ? 'visible' : 'hidden')}
		fill: ${(props => props.theme.Button.backgroundColor)};
		width: ${(props: IProps) => (props.bigCheckbox === true ? '36px' : '24px')};
		height: ${(props: IProps) => (props.bigCheckbox === true ? '36px' : '24px')};
    }
`;
const CheckBoxContainer = styled.label`
	max-width: 16px;
	max-height: 16px;
`;

const CheckText = styled.span`
	display: table-cell;
	vertical-align: middle;
	padding-left: 15px;
	margin-left: 10px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 380;
	font-size: 14px;
	line-height: 16px;
	color: ${props => props.theme.Global.darkFontColor};
	white-space: nowrap;
	padding-bottom: 15px;
`;

const StyledFormError = styled(FormError)`
	display: table-caption;
	caption-side: bottom;
	(Text)margin-top: 10px;
`;
