// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * a phone number
 * @export
 * @interface Phone
 */
export interface Phone {
    /**
     * @type {number}
     * @memberof Phone
     */
    phone_number_id: number;
    /**
     * indicate a primary phone number
     * @type {boolean}
     * @memberof Phone
     */
    is_primary: boolean;
    /**
     * date and time this entry was last updated
     * @type {Date}
     * @memberof Phone
     */
    datetime_update: Date;
    /**
     * @type {string}
     * @memberof Phone
     */
    phone_number: string;
    /**
     * Phone Number Type:   * \'M\' - Mobile    * \'F\' - Fixed Network 
     * @type {string}
     * @memberof Phone
     */
    phone_number_type: PhonePhoneNumberTypeEnum;
    /**
     * indicates if a phone number has been validated via sms code
     * @type {boolean}
     * @memberof Phone
     */
    is_validated: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum PhonePhoneNumberTypeEnum {
    M = 'M',
    F = 'F'
}

