export default class RequestBodyParser {
	private requestBody: string = '';

	addJsonBegin() {
		this.requestBody = '{';
	}

	addElement(key: string, uuid: string) {
		this.requestBody += '"' + key + '": "' + uuid + '",';
	}

	openNewObject(key: string) {
		this.requestBody += '"' + key + '": {';
	}

	closeObject() {
		this.requestBody = this.requestBody.substring(0, this.requestBody.length - 1) + '},';
	}

	finish() {
		this.requestBody = this.requestBody.substring(0, this.requestBody.length - 1);
		this.requestBody += '}';
	}

	getRequestBody() {
		return this.requestBody;
	}

	getRequestBodyObject(): object | null {
		try {
			return JSON.parse(this.requestBody);
		} catch (e) {
			return null;
		}
	}
}
