// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    AntibodyRange,
    Calendar,
    CheckoutAuthNeededResponse,
    CheckoutRequest,
    CheckoutRequestLineItemWithIdAndCurrency,
    CheckoutResponse,
    CustomerLocation,
    FiskalyData,
    FiskalyOrgaData,
    JointPerson,
    Language,
    Locationdata,
    MerchantAccountResponse,
    MerchantAddress,
    MerchantId,
    MerchantInvoice,
    MerchantLocation,
    MerchantName,
    MerchantOnsideProductOrder,
    MerchantProduct,
    MerchantProductOrder,
    MerchantReportHistory,
    MerchantTransaction,
    MerchantUpdate,
    MobileTag,
    MobileTagRequest,
    NewProductType,
    OpenCalendar,
    OrderTransactionIdentifier,
    PaymentData,
    PaymentMethod,
    PaymentMethodPut,
    Product,
    ProductCalendar,
    ProductDiagnose,
    ProductType,
    ProductTypeIdentifier,
    Voucher,
} from '../models';

export interface MerchantAbortTestPostRequest {
    ta_id: number;
    transaction_id: number;
    comment?: string;
}

export interface MerchantAccountImageGetRequest {
    customer_account: string;
}

export interface MerchantAntibodyTestResultGetRequest {
    account_number: string;
}

export interface MerchantAvailableProductTypeGetRequest {
    merchant_account_number: string;
}

export interface MerchantCalendarPutRequest {
    Calendar?: Calendar;
}

export interface MerchantCheckoutDeleteRequest {
    ta_id: number;
    merchant_checkout_id: number;
}

export interface MerchantExpectedCustomersRequest {
    date_from: Date;
    date_to: Date;
    account_number?: string;
    order_by?: MerchantExpectedCustomersOrderByEnum;
}

export interface MerchantFinishPaymentRequest {
    ta_id: number;
    transaction_id: number;
    PaymentData?: PaymentData;
}

export interface MerchantInvoicePutRequest {
    MerchantInvoice?: MerchantInvoice;
}

export interface MerchantListRequest {
    longitude?: number;
    latitude?: number;
    radius_km?: number;
    pharmacy_id?: string;
    theme?: string;
    merchant_name?: string;
    identifier?: string;
}

export interface MerchantLocationCheckinRequest {
    merchant_account_number: string;
    CustomerLocation: CustomerLocation;
}

export interface MerchantLocationCheckoutRequest {
    ta_id: number;
    location_id: number;
}

export interface MerchantLocationHistoryGetRequest {
    datetime_from?: Date;
    datetime_to?: Date;
}

export interface MerchantLocationTestGetRequest {
    test_id: string;
}

export interface MerchantLocationdataPutRequest {
    Locationdata: Locationdata;
}

export interface MerchantMerchantAccountIdCheckoutPostRequest {
    CheckoutRequest: CheckoutRequest;
}

export interface MerchantMerchantAccountIdSubscriptionDeletePostRequest {
    merchant_account_id: number;
    request_body: Array<number>;
}

export interface MerchantMerchantAccountIdSubscriptionPostRequest {
    merchant_account_id: number;
    request_body: Array<number>;
}

export interface MerchantMerchantAccountIdUpdateDeleteRequest {
    merchant_account_id: number;
    merchant_update_id: number;
}

export interface MerchantMerchantAccountIdUpdateGetRequest {
    merchant_account_id: number;
    merchant_update_id?: number;
}

export interface MerchantMobileTagDeleteRequest {
    account_number: string;
    mobile_tag_id: number;
}

export interface MerchantMobileTagGetRequest {
    account_number: string;
    mobile_tag_id: number;
    language?: Language;
}

export interface MerchantMobileTagListRequest {
    account_number: string;
}

export interface MerchantMobileTagPostRequest {
    MobileTagRequest: MobileTagRequest;
}

export interface MerchantNotificationDeleteRequest {
    customer_account: string;
    line_item_id?: number;
}

export interface MerchantNotificationPostRequest {
    customer_account: string;
    CheckoutRequestLineItemWithIdAndCurrency: CheckoutRequestLineItemWithIdAndCurrency;
}

export interface MerchantOrderProductRequest {
    account_number: string;
    MerchantOnsideProductOrder?: MerchantOnsideProductOrder;
}

export interface MerchantPaymentMethodsDeleteRequest {
    merchant_account_number: string;
    payment_method: PaymentMethod;
}

export interface MerchantPaymentMethodsGetRequest {
    merchant_account_number: string;
}

export interface MerchantPaymentMethodsPutRequest {
    merchant_account_number: string;
    PaymentMethodPut?: PaymentMethodPut;
}

export interface MerchantPersonIdCalendarGetRequest {
    person_id: number;
}

export interface MerchantPersonIdCalendarPutRequest {
    person_id: number;
    Calendar?: Calendar;
}

export interface MerchantPersonIdCreateFiskalyPostRequest {
    person_id: number;
    FiskalyData?: FiskalyData;
}

export interface MerchantPersonIdFiskalyDataGetRequest {
    person_id: number;
}

export interface MerchantPersonIdProductCalendarGetRequest {
    person_id: number;
}

export interface MerchantPersonIdProductCalendarPutRequest {
    person_id: number;
    ProductCalendar?: ProductCalendar;
}

export interface MerchantPostRequest {
    MerchantName: MerchantName;
}

export interface MerchantProductConfigGetRequest {
    merchant_account_number: string;
    product_id: number;
}

export interface MerchantProductDeleteRequest {
    account_number: string;
    product_id: number;
}

export interface MerchantProductDiagnosesGetRequest {
    product_id: number;
}

export interface MerchantProductOrderIdCheckPutRequest {
    ta_id: number;
    transaction_id: number;
}

export interface MerchantProductOrderListRequest {
    account_number: string;
    participant_filter: string;
}

export interface MerchantProductOrderPaymentPostRequest {
    ta_id: number;
    transaction_id: number;
    local_payment_method: MerchantProductOrderPaymentPostLocalPaymentMethodEnum;
}

export interface MerchantProductOrderTestIdPutRequest {
    ta_id: number;
    transaction_id: number;
    test_id: string;
}

export interface MerchantProductPutRequest {
    account_number: string;
    product_id: number;
    MerchantProduct?: MerchantProduct;
}

export interface MerchantProductTypeDeleteRequest {
    merchant_account_number: string;
    product_type_id: number;
}

export interface MerchantProductTypeGetRequest {
    merchant_account_number: string;
}

export interface MerchantProductTypePostRequest {
    merchant_account_number: string;
    NewProductType?: NewProductType;
}

export interface MerchantProductTypePutRequest {
    merchant_account_number: string;
    product_type_id: number;
}

export interface MerchantProductsListRequest {
    account_number: string;
}

export interface MerchantProductsListBoRequest {
    account_number: string;
}

export interface MerchantTestCenterOpenCalendarPutRequest {
    OpenCalendar?: OpenCalendar;
}

export interface MerchantTestCenterOpenPersonIdCalendarGetRequest {
    person_id: number;
}

export interface MerchantTestCenterOpenPersonIdCalendarPutRequest {
    person_id: number;
    OpenCalendar?: OpenCalendar;
}

export interface MerchantTransactionPutRequest {
    MerchantTransaction?: MerchantTransaction;
}

export interface MerchantVoucherValidateRequest {
    account_number: string;
    voucher_code: string;
    product_id?: number;
}

/**
 * no description
 */
export class MerchantApi extends BaseAPI implements IOperationIdMap {

    /**
     * abort an already started test
     */
    merchantAbortTestPost = (requestParameters: MerchantAbortTestPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'merchantAbortTestPost');
        throwIfRequired(requestParameters, 'transaction_id', 'merchantAbortTestPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
            ...(requestParameters.comment && { 'comment': requestParameters.comment }),
        };

        return this.request<void>({
            path: '/merchant/abort_test',
            method: 'POST',
            headers,
            query,
        });
    };

    /**
     * used to retrieve customer images 
     * get an customer image at a merchants site
     */
    merchantAccountImageGet = (requestParameters: MerchantAccountImageGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'customer_account', 'merchantAccountImageGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<Blob>({
            path: '/merchant/account/image/{customer_account}'.replace('{customer_account}', encodeURI(requestParameters.customer_account)),
            method: 'GET',
            headers,
            responseType: 'blob'
        });
    };

    /**
     * lower and upper ranges for IgM and IgG values
     * ranges for interpretation of test result
     */
    merchantAntibodyTestResultGet = (requestParameters: MerchantAntibodyTestResultGetRequest): Observable<Array<AntibodyRange>> => {
        throwIfRequired(requestParameters, 'account_number', 'merchantAntibodyTestResultGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<AntibodyRange>>({
            path: '/merchant/{account_number}/antibody_test_result'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * list of product types a merchant can configure
     */
    merchantAvailableProductTypeGet = (requestParameters: MerchantAvailableProductTypeGetRequest): Observable<Array<ProductType>> => {
        throwIfRequired(requestParameters, 'merchant_account_number', 'merchantAvailableProductTypeGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ProductType>>({
            path: '/merchant/{merchant_account_number}/available_product_type'.replace('{merchant_account_number}', encodeURI(requestParameters.merchant_account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    merchantCalendarDelete = (): Observable<void> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/calendar',
            method: 'DELETE',
            headers,
        });
    };

    /**
     */
    merchantCalendarGet = (): Observable<Calendar> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Calendar>({
            path: '/merchant/calendar',
            method: 'GET',
            headers,
        });
    };

    /**
     */
    merchantCalendarPut = (requestParameters: MerchantCalendarPutRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/calendar',
            method: 'PUT',
            headers,
            body: requestParameters.Calendar,
        });
    };

    /**
     * cancel a pending checkout 
     * cancel checkout request
     */
    merchantCheckoutDelete = (requestParameters: MerchantCheckoutDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'merchantCheckoutDelete');
        throwIfRequired(requestParameters, 'merchant_checkout_id', 'merchantCheckoutDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<void>({
            path: '/merchant/checkout/{ta_id}/{merchant_checkout_id}'.replace('{ta_id}', encodeURI(requestParameters.ta_id)).replace('{merchant_checkout_id}', encodeURI(requestParameters.merchant_checkout_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get testing report
     */
    merchantExpectedCustomers = (requestParameters: MerchantExpectedCustomersRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'date_from', 'merchantExpectedCustomers');
        throwIfRequired(requestParameters, 'date_to', 'merchantExpectedCustomers');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.date_from && { 'date_from': (requestParameters.date_from as any).toISOString().substring(0, 10) }),
            ...(requestParameters.date_to && { 'date_to': (requestParameters.date_to as any).toISOString().substring(0, 10) }),
            ...(requestParameters.account_number && { 'account_number': requestParameters.account_number }),
            ...(requestParameters.order_by && { 'order_by': requestParameters.order_by }),
        };

        return this.request<Blob>({
            path: '/merchant/expected_customer',
            method: 'GET',
            headers,
            query,
            responseType: 'blob'
        });
    };

    /**
     * abort an already started test
     */
    merchantFinishPayment = (requestParameters: MerchantFinishPaymentRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'merchantFinishPayment');
        throwIfRequired(requestParameters, 'transaction_id', 'merchantFinishPayment');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<void>({
            path: '/merchant/finish_payment',
            method: 'POST',
            headers,
            query,
            body: requestParameters.PaymentData,
        });
    };

    /**
     * used to replicate merchants transactions 
     * replicate all merchant transactions
     */
    merchantInvoicePut = (requestParameters: MerchantInvoicePutRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<void>({
            path: '/merchant/invoice',
            method: 'PUT',
            headers,
            body: requestParameters.MerchantInvoice,
        });
    };

    /**
     * query:    1. without  parameter, return all merchants    2. with the tripel (longitude, latitude , radius_km ) set, return all mechants in a given radius   filter:    1. parameter pharmacy_id is set, only this pharamcy will be returned, can be combined with q1 and q2   2. parameter theme is set, return mathcing merchants, known values are ECOCARE and PHARMACY. for other values this filter is ignored   2. parameter merchant_name is set, return merchants with a name matching the query. matching is based on double metaphone. it is not an exact match 
     * list merchants having a testcenter
     */
    merchantList = (requestParameters: MerchantListRequest): Observable<Array<MerchantAddress>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.longitude && { 'longitude': requestParameters.longitude }),
            ...(requestParameters.latitude && { 'latitude': requestParameters.latitude }),
            ...(requestParameters.radius_km && { 'radius_km': requestParameters.radius_km }),
            ...(requestParameters.pharmacy_id && { 'pharmacy_id': requestParameters.pharmacy_id }),
            ...(requestParameters.theme && { 'theme': requestParameters.theme }),
            ...(requestParameters.merchant_name && { 'merchant_name': requestParameters.merchant_name }),
            ...(requestParameters.identifier && { 'identifier': requestParameters.identifier }),
        };

        return this.request<Array<MerchantAddress>>({
            path: '/merchant',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * register a customer at location
     */
    merchantLocationCheckin = (requestParameters: MerchantLocationCheckinRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'merchant_account_number', 'merchantLocationCheckin');
        throwIfRequired(requestParameters, 'CustomerLocation', 'merchantLocationCheckin');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.merchant_account_number && { 'merchant_account_number': requestParameters.merchant_account_number }),
        };

        return this.request<void>({
            path: '/merchant/location',
            method: 'PUT',
            headers,
            query,
            body: requestParameters.CustomerLocation,
        });
    };

    /**
     * checkout a customer
     * checkout a customer
     */
    merchantLocationCheckout = (requestParameters: MerchantLocationCheckoutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'merchantLocationCheckout');
        throwIfRequired(requestParameters, 'location_id', 'merchantLocationCheckout');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.location_id && { 'location_id': requestParameters.location_id }),
        };

        return this.request<void>({
            path: '/merchant/location',
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * a list of current customers
     */
    merchantLocationGet = (): Observable<Array<MerchantLocation>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<MerchantLocation>>({
            path: '/merchant/location',
            method: 'GET',
            headers,
        });
    };

    /**
     * a list of current and historical customers
     */
    merchantLocationHistoryGet = (requestParameters: MerchantLocationHistoryGetRequest): Observable<Array<MerchantLocation>> => {

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.datetime_from && { 'datetime_from': (requestParameters.datetime_from as any).toISOString() }),
            ...(requestParameters.datetime_to && { 'datetime_to': (requestParameters.datetime_to as any).toISOString() }),
        };

        return this.request<Array<MerchantLocation>>({
            path: '/merchant/location_history',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    merchantLocationTestGet = (requestParameters: MerchantLocationTestGetRequest): Observable<Array<MerchantLocation>> => {
        throwIfRequired(requestParameters, 'test_id', 'merchantLocationTestGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<MerchantLocation>>({
            path: '/merchant/location/test/{test_id}'.replace('{test_id}', encodeURI(requestParameters.test_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * update locationdata
     */
    merchantLocationdataPut = (requestParameters: MerchantLocationdataPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'Locationdata', 'merchantLocationdataPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/locationdata',
            method: 'PUT',
            headers,
            body: requestParameters.Locationdata,
        });
    };

    /**
     * post checkout request
     * post a merchant checkout
     */
    merchantMerchantAccountIdCheckoutPost = (requestParameters: MerchantMerchantAccountIdCheckoutPostRequest): Observable<CheckoutResponse> => {
        throwIfRequired(requestParameters, 'CheckoutRequest', 'merchantMerchantAccountIdCheckoutPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<CheckoutResponse>({
            path: '/merchant/checkout',
            method: 'POST',
            headers,
            body: requestParameters.CheckoutRequest,
        });
    };

    /**
     * remove accounts for given merchant
     */
    merchantMerchantAccountIdSubscriptionDeletePost = (requestParameters: MerchantMerchantAccountIdSubscriptionDeletePostRequest): Observable<Array<MerchantAccountResponse>> => {
        throwIfRequired(requestParameters, 'merchant_account_id', 'merchantMerchantAccountIdSubscriptionDeletePost');
        throwIfRequired(requestParameters, 'request_body', 'merchantMerchantAccountIdSubscriptionDeletePost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<MerchantAccountResponse>>({
            path: '/merchant/{merchant_account_id}/subscription/delete'.replace('{merchant_account_id}', encodeURI(requestParameters.merchant_account_id)),
            method: 'POST',
            headers,
            body: requestParameters.request_body,
        });
    };

    /**
     * register accounts for given merchant
     */
    merchantMerchantAccountIdSubscriptionPost = (requestParameters: MerchantMerchantAccountIdSubscriptionPostRequest): Observable<Array<MerchantAccountResponse>> => {
        throwIfRequired(requestParameters, 'merchant_account_id', 'merchantMerchantAccountIdSubscriptionPost');
        throwIfRequired(requestParameters, 'request_body', 'merchantMerchantAccountIdSubscriptionPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<MerchantAccountResponse>>({
            path: '/merchant/{merchant_account_id}/subscription'.replace('{merchant_account_id}', encodeURI(requestParameters.merchant_account_id)),
            method: 'POST',
            headers,
            body: requestParameters.request_body,
        });
    };

    /**
     * acknowledge a received update
     */
    merchantMerchantAccountIdUpdateDelete = (requestParameters: MerchantMerchantAccountIdUpdateDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'merchant_account_id', 'merchantMerchantAccountIdUpdateDelete');
        throwIfRequired(requestParameters, 'merchant_update_id', 'merchantMerchantAccountIdUpdateDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.merchant_update_id && { 'merchant_update_id': requestParameters.merchant_update_id }),
        };

        return this.request<void>({
            path: '/merchant/{merchant_account_id}/update'.replace('{merchant_account_id}', encodeURI(requestParameters.merchant_account_id)),
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * receive updates for registered accounts for given merchant
     */
    merchantMerchantAccountIdUpdateGet = (requestParameters: MerchantMerchantAccountIdUpdateGetRequest): Observable<MerchantUpdate> => {
        throwIfRequired(requestParameters, 'merchant_account_id', 'merchantMerchantAccountIdUpdateGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.merchant_update_id && { 'merchant_update_id': requestParameters.merchant_update_id }),
        };

        return this.request<MerchantUpdate>({
            path: '/merchant/{merchant_account_id}/update'.replace('{merchant_account_id}', encodeURI(requestParameters.merchant_account_id)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * deactivate a mobile tag
     * deactivate a mobile tag
     */
    merchantMobileTagDelete = (requestParameters: MerchantMobileTagDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'merchantMobileTagDelete');
        throwIfRequired(requestParameters, 'mobile_tag_id', 'merchantMobileTagDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<void>({
            path: '/merchant/mobile_tag/{account_number}/{mobile_tag_id}'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{mobile_tag_id}', encodeURI(requestParameters.mobile_tag_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * get a created mobile tag pdf again
     * get a created mobile tag pdf again
     */
    merchantMobileTagGet = (requestParameters: MerchantMobileTagGetRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'account_number', 'merchantMobileTagGet');
        throwIfRequired(requestParameters, 'mobile_tag_id', 'merchantMobileTagGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        const query: HttpQuery = {
            ...(requestParameters.language && { 'language': requestParameters.language }),
        };

        return this.request<Blob>({
            path: '/merchant/mobile_tag/{account_number}/{mobile_tag_id}'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{mobile_tag_id}', encodeURI(requestParameters.mobile_tag_id)),
            method: 'GET',
            headers,
            query,
            responseType: 'blob'
        });
    };

    /**
     * get the created mobile tags
     * get the active mobile tags
     */
    merchantMobileTagList = (requestParameters: MerchantMobileTagListRequest): Observable<Array<MobileTag>> => {
        throwIfRequired(requestParameters, 'account_number', 'merchantMobileTagList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        const query: HttpQuery = {
            ...(requestParameters.account_number && { 'account_number': requestParameters.account_number }),
        };

        return this.request<Array<MobileTag>>({
            path: '/merchant/mobile_tag',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * generate a document that describes the process to the customer and includes a qrcode
     * create a mobile tag
     */
    merchantMobileTagPost = (requestParameters: MerchantMobileTagPostRequest): Observable<Blob> => {
        throwIfRequired(requestParameters, 'MobileTagRequest', 'merchantMobileTagPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<Blob>({
            path: '/merchant/mobile_tag',
            method: 'POST',
            headers,
            body: requestParameters.MobileTagRequest,
            responseType: 'blob'
        });
    };

    /**
     * accounts that have already been identified, receive notifications about newly added lineitems via this interface
     * remove lineitem
     */
    merchantNotificationDelete = (requestParameters: MerchantNotificationDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'customer_account', 'merchantNotificationDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        const query: HttpQuery = {
            ...(requestParameters.line_item_id && { 'line_item_id': requestParameters.line_item_id }),
        };

        return this.request<void>({
            path: '/merchant/notification/{customer_account}'.replace('{customer_account}', encodeURI(requestParameters.customer_account)),
            method: 'DELETE',
            headers,
            query,
        });
    };

    /**
     * accounts that have already been identified, receive notifications about newly added lineitems via this interface
     * add lineitem
     */
    merchantNotificationPost = (requestParameters: MerchantNotificationPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'customer_account', 'merchantNotificationPost');
        throwIfRequired(requestParameters, 'CheckoutRequestLineItemWithIdAndCurrency', 'merchantNotificationPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<void>({
            path: '/merchant/notification/{customer_account}'.replace('{customer_account}', encodeURI(requestParameters.customer_account)),
            method: 'POST',
            headers,
            body: requestParameters.CheckoutRequestLineItemWithIdAndCurrency,
        });
    };

    /**
     * buy a product at a location
     * buy a product at a location
     */
    merchantOrderProduct = (requestParameters: MerchantOrderProductRequest): Observable<OrderTransactionIdentifier> => {
        throwIfRequired(requestParameters, 'account_number', 'merchantOrderProduct');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<OrderTransactionIdentifier>({
            path: '/merchant/{account_number}/order_product'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'POST',
            headers,
            body: requestParameters.MerchantOnsideProductOrder,
        });
    };

    /**
     */
    merchantPaymentMethodsDelete = (requestParameters: MerchantPaymentMethodsDeleteRequest): Observable<Array<PaymentMethod>> => {
        throwIfRequired(requestParameters, 'merchant_account_number', 'merchantPaymentMethodsDelete');
        throwIfRequired(requestParameters, 'payment_method', 'merchantPaymentMethodsDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<PaymentMethod>>({
            path: '/merchant/{merchant_account_number}/payment_method/{payment_method}'.replace('{merchant_account_number}', encodeURI(requestParameters.merchant_account_number)).replace('{payment_method}', encodeURI(requestParameters.payment_method)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     */
    merchantPaymentMethodsGet = (requestParameters: MerchantPaymentMethodsGetRequest): Observable<Array<PaymentMethod>> => {
        throwIfRequired(requestParameters, 'merchant_account_number', 'merchantPaymentMethodsGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<PaymentMethod>>({
            path: '/merchant/{merchant_account_number}/payment_method'.replace('{merchant_account_number}', encodeURI(requestParameters.merchant_account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    merchantPaymentMethodsPut = (requestParameters: MerchantPaymentMethodsPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'merchant_account_number', 'merchantPaymentMethodsPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/{merchant_account_number}/payment_method'.replace('{merchant_account_number}', encodeURI(requestParameters.merchant_account_number)),
            method: 'PUT',
            headers,
            body: requestParameters.PaymentMethodPut,
        });
    };

    /**
     */
    merchantPersonIdCalendarGet = (requestParameters: MerchantPersonIdCalendarGetRequest): Observable<Calendar> => {
        throwIfRequired(requestParameters, 'person_id', 'merchantPersonIdCalendarGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Calendar>({
            path: '/merchant/{person_id}/calendar'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    merchantPersonIdCalendarPut = (requestParameters: MerchantPersonIdCalendarPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'merchantPersonIdCalendarPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/{person_id}/calendar'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
            body: requestParameters.Calendar,
        });
    };

    /**
     */
    merchantPersonIdCreateFiskalyPost = (requestParameters: MerchantPersonIdCreateFiskalyPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'merchantPersonIdCreateFiskalyPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/{person_id}/create_fiskaly'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'POST',
            headers,
            body: requestParameters.FiskalyData,
        });
    };

    /**
     */
    merchantPersonIdFiskalyDataGet = (requestParameters: MerchantPersonIdFiskalyDataGetRequest): Observable<FiskalyOrgaData> => {
        throwIfRequired(requestParameters, 'person_id', 'merchantPersonIdFiskalyDataGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<FiskalyOrgaData>({
            path: '/merchant/{person_id}/fiskaly_data'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    merchantPersonIdProductCalendarGet = (requestParameters: MerchantPersonIdProductCalendarGetRequest): Observable<Array<ProductCalendar>> => {
        throwIfRequired(requestParameters, 'person_id', 'merchantPersonIdProductCalendarGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ProductCalendar>>({
            path: '/merchant/{person_id}/product_calendar'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    merchantPersonIdProductCalendarPut = (requestParameters: MerchantPersonIdProductCalendarPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'merchantPersonIdProductCalendarPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/{person_id}/product_calendar'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
            body: requestParameters.ProductCalendar,
        });
    };

    /**
     * create a new merchant
     */
    merchantPost = (requestParameters: MerchantPostRequest): Observable<MerchantId> => {
        throwIfRequired(requestParameters, 'MerchantName', 'merchantPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<MerchantId>({
            path: '/merchant',
            method: 'POST',
            headers,
            body: requestParameters.MerchantName,
        });
    };

    /**
     */
    merchantProductConfigGet = (requestParameters: MerchantProductConfigGetRequest): Observable<Product> => {
        throwIfRequired(requestParameters, 'merchant_account_number', 'merchantProductConfigGet');
        throwIfRequired(requestParameters, 'product_id', 'merchantProductConfigGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Product>({
            path: '/merchant/{merchant_account_number}/product_config/{product_id}'.replace('{merchant_account_number}', encodeURI(requestParameters.merchant_account_number)).replace('{product_id}', encodeURI(requestParameters.product_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * remove product to list of available products
     * remove product to list of available products
     */
    merchantProductDelete = (requestParameters: MerchantProductDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'merchantProductDelete');
        throwIfRequired(requestParameters, 'product_id', 'merchantProductDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/{account_number}/product/{product_id}'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{product_id}', encodeURI(requestParameters.product_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     */
    merchantProductDiagnosesGet = (requestParameters: MerchantProductDiagnosesGetRequest): Observable<ProductDiagnose> => {
        throwIfRequired(requestParameters, 'product_id', 'merchantProductDiagnosesGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<ProductDiagnose>({
            path: '/merchant/merchant_product_diagnoses/{product_id}'.replace('{product_id}', encodeURI(requestParameters.product_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * confirm that the customer is identical to the one who does the test
     */
    merchantProductOrderIdCheckPut = (requestParameters: MerchantProductOrderIdCheckPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'merchantProductOrderIdCheckPut');
        throwIfRequired(requestParameters, 'transaction_id', 'merchantProductOrderIdCheckPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
        };

        return this.request<void>({
            path: '/merchant/product_order/id_check',
            method: 'PUT',
            headers,
            query,
        });
    };

    /**
     * get a list of purchased products at a specific merchant
     * list of products ordered
     */
    merchantProductOrderList = (requestParameters: MerchantProductOrderListRequest): Observable<Array<MerchantProductOrder>> => {
        throwIfRequired(requestParameters, 'account_number', 'merchantProductOrderList');
        throwIfRequired(requestParameters, 'participant_filter', 'merchantProductOrderList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.participant_filter && { 'participant_filter': requestParameters.participant_filter }),
        };

        return this.request<Array<MerchantProductOrder>>({
            path: '/merchant/{account_number}/product_orders'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * pay for a product order at the merchant
     */
    merchantProductOrderPaymentPost = (requestParameters: MerchantProductOrderPaymentPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'merchantProductOrderPaymentPost');
        throwIfRequired(requestParameters, 'transaction_id', 'merchantProductOrderPaymentPost');
        throwIfRequired(requestParameters, 'local_payment_method', 'merchantProductOrderPaymentPost');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
            ...(requestParameters.local_payment_method && { 'local_payment_method': requestParameters.local_payment_method }),
        };

        return this.request<void>({
            path: '/merchant/product_order_payment',
            method: 'POST',
            headers,
            query,
        });
    };

    /**
     * pay for a product order at the merchant
     */
    merchantProductOrderTestIdPut = (requestParameters: MerchantProductOrderTestIdPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'ta_id', 'merchantProductOrderTestIdPut');
        throwIfRequired(requestParameters, 'transaction_id', 'merchantProductOrderTestIdPut');
        throwIfRequired(requestParameters, 'test_id', 'merchantProductOrderTestIdPut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.ta_id && { 'ta_id': requestParameters.ta_id }),
            ...(requestParameters.transaction_id && { 'transaction_id': requestParameters.transaction_id }),
            ...(requestParameters.test_id && { 'test_id': requestParameters.test_id }),
        };

        return this.request<void>({
            path: '/merchant/product_order/test_id',
            method: 'PUT',
            headers,
            query,
        });
    };

    /**
     * add product to list of available products
     * add product to list of available products
     */
    merchantProductPut = (requestParameters: MerchantProductPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'account_number', 'merchantProductPut');
        throwIfRequired(requestParameters, 'product_id', 'merchantProductPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/{account_number}/product/{product_id}'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{product_id}', encodeURI(requestParameters.product_id)),
            method: 'PUT',
            headers,
            body: requestParameters.MerchantProduct,
        });
    };

    /**
     */
    merchantProductTypeDelete = (requestParameters: MerchantProductTypeDeleteRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'merchant_account_number', 'merchantProductTypeDelete');
        throwIfRequired(requestParameters, 'product_type_id', 'merchantProductTypeDelete');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/{merchant_account_number}/product_type/{product_type_id}'.replace('{merchant_account_number}', encodeURI(requestParameters.merchant_account_number)).replace('{product_type_id}', encodeURI(requestParameters.product_type_id)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     */
    merchantProductTypeGet = (requestParameters: MerchantProductTypeGetRequest): Observable<Array<ProductType>> => {
        throwIfRequired(requestParameters, 'merchant_account_number', 'merchantProductTypeGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<ProductType>>({
            path: '/merchant/{merchant_account_number}/product_type'.replace('{merchant_account_number}', encodeURI(requestParameters.merchant_account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * deprecated interface use merchant_product_type_put instead
     */
    merchantProductTypePost = (requestParameters: MerchantProductTypePostRequest): Observable<ProductTypeIdentifier> => {
        throwIfRequired(requestParameters, 'merchant_account_number', 'merchantProductTypePost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<ProductTypeIdentifier>({
            path: '/merchant/{merchant_account_number}/product_type'.replace('{merchant_account_number}', encodeURI(requestParameters.merchant_account_number)),
            method: 'POST',
            headers,
            body: requestParameters.NewProductType,
        });
    };

    /**
     */
    merchantProductTypePut = (requestParameters: MerchantProductTypePutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'merchant_account_number', 'merchantProductTypePut');
        throwIfRequired(requestParameters, 'product_type_id', 'merchantProductTypePut');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/{merchant_account_number}/product_type/{product_type_id}'.replace('{merchant_account_number}', encodeURI(requestParameters.merchant_account_number)).replace('{product_type_id}', encodeURI(requestParameters.product_type_id)),
            method: 'PUT',
            headers,
        });
    };

    /**
     * get a list of products available for purchase, at a specific merchant
     * list of products available at merchant
     */
    merchantProductsList = (requestParameters: MerchantProductsListRequest): Observable<Array<Product>> => {
        throwIfRequired(requestParameters, 'account_number', 'merchantProductsList');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Product>>({
            path: '/merchant/{account_number}/products'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     * get a list of products available for purchase, at a specific merchant
     * list of products available at merchant, with provision
     */
    merchantProductsListBo = (requestParameters: MerchantProductsListBoRequest): Observable<Array<Product>> => {
        throwIfRequired(requestParameters, 'account_number', 'merchantProductsListBo');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<Product>>({
            path: '/merchant/{account_number}/products_bo'.replace('{account_number}', encodeURI(requestParameters.account_number)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    merchantReportHistoryList = (): Observable<Array<MerchantReportHistory>> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<Array<MerchantReportHistory>>({
            path: '/merchant/report_history_list',
            method: 'GET',
            headers,
        });
    };

    /**
     */
    merchantTestCenterOpenCalendarDelete = (): Observable<void> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/opencalendar',
            method: 'DELETE',
            headers,
        });
    };

    /**
     */
    merchantTestCenterOpenCalendarGet = (): Observable<OpenCalendar> => {
        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<OpenCalendar>({
            path: '/merchant/opencalendar',
            method: 'GET',
            headers,
        });
    };

    /**
     */
    merchantTestCenterOpenCalendarPut = (requestParameters: MerchantTestCenterOpenCalendarPutRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/opencalendar',
            method: 'PUT',
            headers,
            body: requestParameters.OpenCalendar,
        });
    };

    /**
     */
    merchantTestCenterOpenPersonIdCalendarGet = (requestParameters: MerchantTestCenterOpenPersonIdCalendarGetRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'merchantTestCenterOpenPersonIdCalendarGet');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/{person_id}/opencalendar'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    merchantTestCenterOpenPersonIdCalendarPut = (requestParameters: MerchantTestCenterOpenPersonIdCalendarPutRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'person_id', 'merchantTestCenterOpenPersonIdCalendarPut');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/merchant/{person_id}/opencalendar'.replace('{person_id}', encodeURI(requestParameters.person_id)),
            method: 'PUT',
            headers,
            body: requestParameters.OpenCalendar,
        });
    };

    /**
     * used to replicate merchants transactions 
     * replicate all merchant transactions
     */
    merchantTransactionPut = (requestParameters: MerchantTransactionPutRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
        };

        return this.request<void>({
            path: '/merchant/transaction',
            method: 'PUT',
            headers,
            body: requestParameters.MerchantTransaction,
        });
    };

    /**
     * validate a voucher code to use at the selected merchant
     */
    merchantVoucherValidate = (requestParameters: MerchantVoucherValidateRequest): Observable<Voucher> => {
        throwIfRequired(requestParameters, 'account_number', 'merchantVoucherValidate');
        throwIfRequired(requestParameters, 'voucher_code', 'merchantVoucherValidate');

        const headers: HttpHeaders = {
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        const query: HttpQuery = {
            ...(requestParameters.product_id && { 'product_id': requestParameters.product_id }),
        };

        return this.request<Voucher>({
            path: '/merchant/{account_number}/voucher/{voucher_code}'.replace('{account_number}', encodeURI(requestParameters.account_number)).replace('{voucher_code}', encodeURI(requestParameters.voucher_code)),
            method: 'GET',
            headers,
            query,
        });
    };


    operationToOperationId = {
	    "merchantAbortTestPost": "merchant_abort_test_post" ,
	    "merchantAccountImageGet": "merchant_account_image_get" ,
	    "merchantAntibodyTestResultGet": "merchant_antibody_test_result_get" ,
	    "merchantAvailableProductTypeGet": "merchant_available_product_type_get" ,
	    "merchantCalendarDelete": "merchant_calendar_delete" ,
	    "merchantCalendarGet": "merchant_calendar_get" ,
	    "merchantCalendarPut": "merchant_calendar_put" ,
	    "merchantCheckoutDelete": "merchant_checkout_delete" ,
	    "merchantExpectedCustomers": "merchant_expected_customers" ,
	    "merchantFinishPayment": "merchant_finish_payment" ,
	    "merchantInvoicePut": "merchant_invoice_put" ,
	    "merchantList": "merchant_list" ,
	    "merchantLocationCheckin": "merchant_location_checkin" ,
	    "merchantLocationCheckout": "merchant_location_checkout" ,
	    "merchantLocationGet": "merchant_location_get" ,
	    "merchantLocationHistoryGet": "merchant_location_history_get" ,
	    "merchantLocationTestGet": "merchant_location_test_get" ,
	    "merchantLocationdataPut": "merchant_locationdata_put" ,
	    "merchantMerchantAccountIdCheckoutPost": "merchant_merchant_account_id_checkout_post" ,
	    "merchantMerchantAccountIdSubscriptionDeletePost": "merchant_merchant_account_id_subscription_delete_post" ,
	    "merchantMerchantAccountIdSubscriptionPost": "merchant_merchant_account_id_subscription_post" ,
	    "merchantMerchantAccountIdUpdateDelete": "merchant_merchant_account_id_update_delete" ,
	    "merchantMerchantAccountIdUpdateGet": "merchant_merchant_account_id_update_get" ,
	    "merchantMobileTagDelete": "merchant_mobile_tag_delete" ,
	    "merchantMobileTagGet": "merchant_mobile_tag_get" ,
	    "merchantMobileTagList": "merchant_mobile_tag_list" ,
	    "merchantMobileTagPost": "merchant_mobile_tag_post" ,
	    "merchantNotificationDelete": "merchant_notification_delete" ,
	    "merchantNotificationPost": "merchant_notification_post" ,
	    "merchantOrderProduct": "merchant_order_product" ,
	    "merchantPaymentMethodsDelete": "merchant_payment_methods_delete" ,
	    "merchantPaymentMethodsGet": "merchant_payment_methods_get" ,
	    "merchantPaymentMethodsPut": "merchant_payment_methods_put" ,
	    "merchantPersonIdCalendarGet": "merchant_person_id_calendar_get" ,
	    "merchantPersonIdCalendarPut": "merchant_person_id_calendar_put" ,
	    "merchantPersonIdCreateFiskalyPost": "merchant_person_id_create_fiskaly_post" ,
	    "merchantPersonIdFiskalyDataGet": "merchant_person_id_fiskaly_data_get" ,
	    "merchantPersonIdProductCalendarGet": "merchant_person_id_product_calendar_get" ,
	    "merchantPersonIdProductCalendarPut": "merchant_person_id_product_calendar_put" ,
	    "merchantPost": "merchant_post" ,
	    "merchantProductConfigGet": "merchant_product_config_get" ,
	    "merchantProductDelete": "merchant_product_delete" ,
	    "merchantProductDiagnosesGet": "merchant_product_diagnoses_get" ,
	    "merchantProductOrderIdCheckPut": "merchant_product_order_id_check_put" ,
	    "merchantProductOrderList": "merchant_product_order_list" ,
	    "merchantProductOrderPaymentPost": "merchant_product_order_payment_post" ,
	    "merchantProductOrderTestIdPut": "merchant_product_order_test_id_put" ,
	    "merchantProductPut": "merchant_product_put" ,
	    "merchantProductTypeDelete": "merchant_product_type_delete" ,
	    "merchantProductTypeGet": "merchant_product_type_get" ,
	    "merchantProductTypePost": "merchant_product_type_post" ,
	    "merchantProductTypePut": "merchant_product_type_put" ,
	    "merchantProductsList": "merchant_products_list" ,
	    "merchantProductsListBo": "merchant_products_list_bo" ,
	    "merchantReportHistoryList": "merchant_report_history_list" ,
	    "merchantTestCenterOpenCalendarDelete": "merchant_test_center_open_calendar_delete" ,
	    "merchantTestCenterOpenCalendarGet": "merchant_test_center_open_calendar_get" ,
	    "merchantTestCenterOpenCalendarPut": "merchant_test_center_open_calendar_put" ,
	    "merchantTestCenterOpenPersonIdCalendarGet": "merchant_test_center_open_person_id_calendar_get" ,
	    "merchantTestCenterOpenPersonIdCalendarPut": "merchant_test_center_open_person_id_calendar_put" ,
	    "merchantTransactionPut": "merchant_transaction_put" ,
	    "merchantVoucherValidate": "merchant_voucher_validate"
    }
}

/**
 * @export
 * @enum {string}
 */
export enum MerchantExpectedCustomersOrderByEnum {
    SysDateTimestamp = 'sys_date_timestamp',
    AppointmentTimestamp = 'appointment_timestamp'
}
/**
 * @export
 * @enum {string}
 */
export enum MerchantProductOrderPaymentPostLocalPaymentMethodEnum {
    Cash = 'cash',
    CreditCard = 'credit_card',
    DebitCard = 'debit_card'
}
