//@ts-nocheck
import React, { SyntheticEvent } from 'react';
import {
	StyledViewWrapper,
	StyledViewSearchBox,
	StyledViewResultBox,
	StyledBox,
	StyledLine,
} from './basicStyledComponents/styled';
import BackOfficeResultBox from './BackOfficeResultBox';
import styled from 'styled-components';
import i18next from 'i18next';
import {
	IStatusState,
	getFormComponents,
	IMessageConfig,
	Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { translate } from '../../../../common/language/translate';
import { ApiError, apis, api, Account } from '../../../../logic/api';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import {
	OverlayHandler,
	Overlays,
} from '../../../../logic/handler/overlayhandler/overlayHandler';
import { theme } from '../../../../common/theme';
import {
	IInitProps,
	InitialDataComponent,
	IInitState,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import { InitComponentHandler } from '../../../../logic/handler/initialdatahandler/InitComponentHandler';
import { BigInput } from '../../../../components/atomiccompoents/form/inputs.css';
import ButtonOk from '../../../../components/atomiccompoents/buttons/buttonOk';
import { DeveloperStore } from '../../../../logic/flux/stores/DeveloperStore';
import { Accounts } from '../../../../logic/accounts';
import { Actions } from '../../../../logic/flux';
import { Log, Logs } from '../../../../logic/log';
import { FlexBox } from '../../../auth/auth.css';
import { IOption } from '../../../../components/atomiccompoents/form/select';
import { Icons } from '../../../../images';
import { StyledDivHeader, StyledSelect } from '../basicStyledComponents/customersComponent.css';
import Title from '../../../../components/compositcomponents/title';
import { ResetWrapper } from '../customers/basicStyledComponents/customerDetails.css';
import { evaluateErrorMessage } from '../../../../logic/helper/Common';

interface IProps extends IInitProps {
	accountTypes?: Array<string>;
}

interface IState extends IStatusState, IInitState {
	searchValue?: string;
	accountType?: Account.AccountType;
	selectedAccountType?: number;
	accountTypes: Array<Account.AccountType>;
	data?: Array<Account.AccountParams> | null;
	searchData: Account.AccountSearchRequest;
	keyForLoadingSpinner?: number;
	clicked?: boolean;
}

class BackOfficeSearch extends InitialDataComponent<IProps, IState> {
	private ButtonStyle = {
		width: theme.Button.width,
	};

	constructor(props: IProps) {
		super(props);
		const initState: IState = {
			searchValue: '',
			accountType: {
				account_type_id: -1,
				name: '',
			},
			accountTypes: [],
			data: null,
			selectedAccountType: -1,
			searchData: {
				account_number: DeveloperStore.getDefaultBackofficeAccount() || '',
				iban: '',
				memo: '',
				name: '',
				custom_name: '',
				account_type_id: undefined,
				limit: 20,
				offset: 0,
			},
			clicked: false,
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
		};
		this.state = initState;

		this.onChange = this.onChange.bind(this);
		this._onLanguageChanged = this._onLanguageChanged.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.createAccountOptions = this.createAccountOptions.bind(this);
		this.resetState = this.resetState.bind(this);
		InitComponentHandler.register(this, this.constructor.name);

		if (
			initState.searchData.account_number != null &&
			initState.searchData.account_number !== ''
		) {
			this.onSubmit(undefined, true);
		}
	}

	_onLanguageChanged(): void {
		this.setState(this.state);
	}

	componentDidMount() {
		i18next.on('languageChanged', this._onLanguageChanged);

		this.fetchAccountTypes().then((response) => {
			this.setState({ accountTypes: response });
		});
		this.fill(this.constructor.name);
	}

	reviveState(newState: IState): void {
		this.setState(newState);
	}

	componentWillUnmount() {
		i18next.off('languageChanged', this._onLanguageChanged);
		InitComponentHandler.cleanUp(this.constructor.name);
	}

	createAccountOptions() {
		const options: Array<IOption> = [];
		options.push({
			key: "-1",
			value: "-1",
			name: translate(
				'backoffice.search.input.accountTypeAll'
			)
		});
		if(this.state.accountTypes != null) {
			this.state.accountTypes.forEach((element: any) => {
				options.push({
					key: element.account_type_id,
					name: element.name,
					value: element.account_type_id
				})
			}); 
		}
		return options;
	}

	resetState() {
		this.setState({
			searchData: {
				account_number: DeveloperStore.getDefaultBackofficeAccount() || '',
				iban: '',
				memo: '',
				name: '',
				custom_name: '',
				account_type_id: undefined,
				limit: 20,
				offset: 0,
			},
		});
    }
    unsetAutofillForChrome(event: any) {
        if(event.target.autocomplete) {
            event.target.autocomplete = "whatever";
        }
    }

	onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
		// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			this.onSubmit(event);
		}
	};

	render() { 
		return (
			<StyledViewWrapper>
				<StyledViewSearchBox>
					<StyledBox>
						<FlexBox>
							<StyledDivHeader>
									{Icons.searchBackoffice()}
									<Title title= {translate('backoffice.search.title')} />
								<ResetIcon onClick={() => this.resetState() } title="reset searchvalues">
                                        {Icons.resetSearch()}
                                    </ResetIcon>
							</StyledDivHeader>
							<StyledLine />
							<form
								onSubmit={(event: SyntheticEvent) => this.onSubmit(event)}
								onKeyDown={this.onKeyDown}>
								<BigInput
									type="text"
									label={translate(
										'backoffice.search.input.account_number.label'
									)}
									placeHolder={translate(
										'backoffice.search.input.account_number.placeholder'
									)}
									id="account_number"
									value={this.state.searchData.account_number}
									notification={(n) => this.onInputChange(n)}
									valueFromState={true}
                                    onSubmit={this.onSubmit}
                                    onEnter={this.unsetAutofillForChrome}
								/>
								<StyledSelect
									options={this.createAccountOptions()}
									id="accountselect"
									helptext={translate('backoffice.search.input.accountType')}
									notification={this.onChange}	
									maxWidthForHelper={1000}	
									wrapperStyle={{maxWidth: '1700px'}}							
								/>
								<BigInput
									type="text"
									label={translate(
										'backoffice.search.input.iban.label'
									)}
									placeHolder={translate(
										'backoffice.search.input.iban.placeholder'
									)}
									id="iban"
									value={this.state.searchData.iban}
									notification={(n) => this.onInputChange(n)}
									valueFromState={true}
                                    onSubmit={this.onSubmit}
                                    onEnter={this.unsetAutofillForChrome}
								/>
			
								<BigInput
									type="text"
									label={translate(
										'backoffice.search.input.memo.label'
									)}
									placeHolder={translate(
										'backoffice.search.input.memo.placeholder'
									)}
									id="memo"
									value={this.state.searchData.memo}
									notification={(n) => this.onInputChange(n)}
									valueFromState={true}
                                    onSubmit={this.onSubmit}
                                    onEnter={this.unsetAutofillForChrome}
								/>
								<BigInput
									type="text"
									label={translate(
										'backoffice.search.input.name.label'
									)}
									placeHolder={translate(
										'backoffice.search.input.name.placeholder'
									)}
									id="name"
									value={this.state.searchData.name}
									notification={(n: any) =>
										this.onInputChange(n)
									}
									valueFromState={true}
                                    onSubmit={this.onSubmit}
                                    onEnter={this.unsetAutofillForChrome}
								/>		
								<BigInput
									type="text"
									label={translate(
										'backoffice.search.input.custom_name.label'
									)}
									placeHolder={translate(
										'backoffice.search.input.custom_name.placeholder'
									)}
									id="custom_name"
									value={this.state.searchData.custom_name}
									notification={(n: any) =>
										this.onInputChange(n)
									}
									valueFromState={true}
                                    onSubmit={this.onSubmit}
                                    onEnter={this.unsetAutofillForChrome}
								/>	
								{getFormComponents(
									this.state.showInlineError,
									this.state.showInlineSuccess,
									this.state.errorMessage,
									this.state.successMessage,
									this.state.key
								)}
								<StyledButtonView>
									<ButtonOk
										key={this.state.keyForLoadingSpinner}
										onClick={this.onSubmit}
										id="btnBackofficeSearch"
										clicked={this.state.clicked}
										style={ButtonStyle}>
										{translate('button.search')}
									</ButtonOk>
								</StyledButtonView>
							</form>
						</FlexBox>
					</StyledBox>
				</StyledViewSearchBox>
				<StyledViewResultBox>
					<BackOfficeResultBox
						data={this.state.data != null ? this.state.data : []}
						title={translate('customers.results')}
					/>
				</StyledViewResultBox>
			</StyledViewWrapper>
		);
	}

	onInputChange(n: any): void {
		if (n != null) {
			const key = Object.keys(n)[0] as keyof Account.AccountSearchRequest;
			const value = n[key].value;

			const searchData = this.state.searchData;
			//@ts-ignore
			searchData[key] = value;

			this.setState({
				searchData: searchData,
			});
		}
	}

	onChange(message: any) {
		const searchData = this.state.searchData;
		searchData.account_type_id = message.value;

		this.setState({ searchData: searchData });
	}

	onSubmit(event?: SyntheticEvent, autoSelectOnSingleResult?: boolean): void {
		if (event != null) {
			event.preventDefault();
		}
		this.setState({
			clicked: true,
		})
		if (this.state.searchData != null) {
			this.searchAccount(autoSelectOnSingleResult)
				.then((results) => {
					this.setState({
						data: results,
						showInlineError: undefined,
						errorMessage: undefined,
						key: undefined,
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
				})
				.catch((error: ApiError) => {
					const config: IMessageConfig = MessageHandler.onError(
                        Reporter['backoffice.search.get.request'],
						evaluateErrorMessage(error, true), 
						evaluateErrorMessage(error, false)
					);
					this.setState({
						showInlineError: config.errorMethods.inline,
						errorMessage:
							config.errorMessage != null
								? config.errorMessage
								: error.statusText,
						key:
							config.translationKey != null
								? config.translationKey + '.error'
								: '',
						keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
					});
				});
		} else {
			this.setState({
				keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			});
		}
	}

	showHelpOverlay(): void {
		OverlayHandler.showOverlay(Overlays.backofficeSearchHelp);
	}

	fetchAccountTypes(): Promise<Array<Account.AccountType>> {
		return new Promise((resolve: any, reject: any) => {
			api.asyncRequest<Array<Account.AccountType>>(
				null,
				apis.DefaultApi,
				'accountTypeGet'
			)
				.then((response) => resolve(response))
				.catch((error: ApiError) => reject(error));
		});
	}

	prepareSearchValues(searchValue: string): Account.AccountSearchRequest {
		if (searchValue.trim() === '') {
			return {};
		}

		const searchValues = searchValue.split(/\s*,\s*/);
		const result: Account.AccountSearchRequest = {};
		searchValues.forEach((el: string): void => {
			const keyValue = el.split(/\s*:\s*/, 2);
			const key: string = keyValue.length !== 2 ? '' : keyValue[0].toLowerCase();
			const value: string =
				keyValue.length !== 2 ? keyValue[0] : keyValue[1].toLowerCase();
			switch (key) {
				case 'account_number':
					result.account_number = value;
					break;
				case 'iban':
					result.iban = value;
					break;
				default: // <-- Simply move this to select another default field
				case 'memo':
					result.memo = value;
					break;
				case 'name':
					result.name = value;
					break;
				case 'custom_name':
					result.custom_name = value;
					break;
			}
		});

		return result;
	}

	searchAccount(autoSelectOnSingleResult?: boolean): Promise<Array<Account.AccountParams>> {
		return new Promise((resolve: any, reject: any) => {
			const params = this.state.searchData;
			api.asyncRequest<Array<Account.AccountParams>>(
				params,
				apis.DefaultApi,
				'accountSearch'
			)
				.then((response) => {
					if (autoSelectOnSingleResult === true && response.length === 1) {
						// Auto submit when option is set and the result contains only one entry

						OverlayHandler.updateApp();

						Accounts.getAccountById(response[0].account_number)
							.then((response) => {
								Log.debug(Logs.API, 'Found account ' + response.name);
								Actions.backofficeChanged(response);
								// BackofficeStore.setAccount(response);
							})
							.catch((error: ApiError) => {
								Log.error(Logs.API, error);
							});
						return;
					}
					resolve(response);
				})
				.catch((error: ApiError) => reject(error));
		});
	}
}

const StyledButtonView = styled(FlexBox)`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 25px;
`;

export default BackOfficeSearch;

const ResetIcon = styled(ResetWrapper)`
`;
const ButtonStyle: React.CSSProperties = {
	marginLeft: '12px',
	marginTop: '25px',
	marginBottom: '6px',
	float: 'left',
}