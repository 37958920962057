// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Amount,
    CreditorAccountSelection
} from './';

/**
 * a request money request if a stored favorite was used, put its id into favorite_id  please note the counterpartyName will be ignored and is just here for compatibility reasons
 * @export
 * @interface RequestMoneyRequest
 */
export interface RequestMoneyRequest {
    /**
     * @type {string}
     * @memberof RequestMoneyRequest
     */
    ownAccount: string;
    /**
     * @type {Amount}
     * @memberof RequestMoneyRequest
     */
    instructedAmount: Amount;
    /**
     * @type {String | CreditorAccountSelection}
     * @memberof RequestMoneyRequest
     */
    counterpartyAccount: String | CreditorAccountSelection;
    /**
     * Creditor Name
     * @type {string}
     * @memberof RequestMoneyRequest
     */
    counterpartyName?: string;
    /**
     * Unstructured remittance information 
     * @type {string}
     * @memberof RequestMoneyRequest
     */
    remittanceInformationUnstructured?: string;
    /**
     * @type {number}
     * @memberof RequestMoneyRequest
     */
    favorite_id?: number;
    /**
     * save current request data as new favorite if * the current request is accepted and * the favorite_id is not set deduplication does only occur if and only if all transaction data is identical to a previous stored favorite if parameter favorite_name is set, its value will be used
     * @type {boolean}
     * @memberof RequestMoneyRequest
     */
    save_new_favorite?: boolean;
    /**
     * user given name for the favorite, of nothing was set, it will be created by the system
     * @type {string}
     * @memberof RequestMoneyRequest
     */
    favorite_name?: string;
    /**
     * * C - cash desk 
     * @type {string}
     * @memberof RequestMoneyRequest
     */
    request_money_type?: RequestMoneyRequestRequestMoneyTypeEnum;
    /**
     * external reference as provided by the initiaing application
     * @type {string}
     * @memberof RequestMoneyRequest
     */
    external_reference?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RequestMoneyRequestRequestMoneyTypeEnum {
    C = 'C'
}

