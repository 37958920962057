// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Iso4217Currency,
} from './';

/**
 * @export
 * @interface NewProductVoucherGroup
 */
export interface NewProductVoucherGroup {
    /**
     * the merchants account_number
     * @type {string}
     * @memberof NewProductVoucherGroup
     */
    merchant_account_number?: string;
    /**
     * The amount given with fractional digits, where fractions must be compliant to the currency definition. Up to 14 significant figures. Negative amounts are signed by minus. The decimal separator is a dot.  **Example:** Valid representations for EUR with up to two decimals are:    * 1056   * 5768.2   * -1.50   * 5877.78 
     * @type {string}
     * @memberof NewProductVoucherGroup
     */
    amount: string;
    /**
     * @type {Iso4217Currency}
     * @memberof NewProductVoucherGroup
     */
    currency: Iso4217Currency;
    /**
     * @type {Date}
     * @memberof NewProductVoucherGroup
     */
    valid_until: Date;
    /**
     * @type {number}
     * @memberof NewProductVoucherGroup
     */
    quantity?: number;
    /**
     * just for information purpose, but we are not sending out emails
     * @type {string}
     * @memberof NewProductVoucherGroup
     */
    receiver?: string;
    /**
     * @type {number}
     * @memberof NewProductVoucherGroup
     */
    product_id?: number;
    /**
     * @type {string}
     * @memberof NewProductVoucherGroup
     */
    voucher_type?: NewProductVoucherGroupVoucherTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum NewProductVoucherGroupVoucherTypeEnum {
    OnceOnly = 'onceOnly',
    Unlimited = 'unlimited'
}

