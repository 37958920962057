// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Attachment,
} from './';

/**
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * @type {number}
     * @memberof Location
     */
    ta_id: number;
    /**
     * @type {number}
     * @memberof Location
     */
    location_id: number;
    /**
     * the location
     * @type {string}
     * @memberof Location
     */
    location: string;
    /**
     * an optional table number
     * @type {string}
     * @memberof Location
     */
    table_no?: string;
    /**
     * date and time of checkin
     * @type {string}
     * @memberof Location
     */
    checkin_datetime: string;
    /**
     * date and time of checkout
     * @type {Date}
     * @memberof Location
     */
    checkout_datetime?: Date;
    /**
     * date and time when the record will not be available for the merchant
     * @type {Date}
     * @memberof Location
     */
    merchant_delete_timestamp?: Date;
    /**
     * date and time when the record has been deleted at the merchant
     * @type {Date}
     * @memberof Location
     */
    merchant_deleted_timestamp?: Date;
    /**
     * @type {string}
     * @memberof Location
     */
    merchant_account_number?: string;
    /**
     * @type {number}
     * @memberof Location
     */
    ref_ta_id?: number;
    /**
     * @type {number}
     * @memberof Location
     */
    ref_transaction_id?: number;
    /**
     * @type {number}
     * @memberof Location
     */
    product_id?: number;
    /**
     * @type {Array<Attachment>}
     * @memberof Location
     */
    attachments?: Array<Attachment>;
    /**
     * indicates if checkout is possible
     * @type {boolean}
     * @memberof Location
     */
    checkout_possible?: boolean;
    /**
     * indicates if product order can be submitted to cwa
     * @type {boolean}
     * @memberof Location
     */
    cwa?: boolean;
    /**
     * confirm/deny that test is received * C - Confirmed * D - Denied * P - Pending --> ask customer 
     * @type {string}
     * @memberof Location
     */
    ask_for_confirmation?: LocationAskForConfirmationEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum LocationAskForConfirmationEnum {
    C = 'C',
    D = 'D',
    P = 'P'
}

