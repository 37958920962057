// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Iso4217CurrencyOUT,
} from './';

/**
 * @export
 * @interface CardTransactionDetail
 */
export interface CardTransactionDetail {
    /**
     * @type {number}
     * @memberof CardTransactionDetail
     */
    ta_id: number;
    /**
     * @type {number}
     * @memberof CardTransactionDetail
     */
    transaction_id: number;
    /**
     * @type {string}
     * @memberof CardTransactionDetail
     */
    transaction_source?: string;
    /**
     * @type {number}
     * @memberof CardTransactionDetail
     */
    txkey_id?: number;
    /**
     * @type {string}
     * @memberof CardTransactionDetail
     */
    debit_credit?: CardTransactionDetailDebitCreditEnum;
    /**
     * @type {number}
     * @memberof CardTransactionDetail
     */
    amount?: number;
    /**
     * @type {Iso4217CurrencyOUT}
     * @memberof CardTransactionDetail
     */
    currency?: Iso4217CurrencyOUT;
    /**
     * @type {string}
     * @memberof CardTransactionDetail
     */
    reference_number?: string;
    /**
     * the merchant
     * @type {string}
     * @memberof CardTransactionDetail
     */
    card_acceptor_name_and_location?: string;
    /**
     * the merchants name
     * @type {string}
     * @memberof CardTransactionDetail
     */
    merchant_name?: string;
    /**
     * the merchants address street
     * @type {string}
     * @memberof CardTransactionDetail
     */
    merchant_street?: string;
    /**
     * the merchants city
     * @type {string}
     * @memberof CardTransactionDetail
     */
    merchant_city?: string;
    /**
     * the merchants region
     * @type {string}
     * @memberof CardTransactionDetail
     */
    merchant_region?: string;
    /**
     * @type {string}
     * @memberof CardTransactionDetail
     */
    merchant_country?: string;
    /**
     * the merchants postcode
     * @type {string}
     * @memberof CardTransactionDetail
     */
    merchant_postcode?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CardTransactionDetailDebitCreditEnum {
    C = 'C',
    D = 'D'
}

