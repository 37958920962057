import React from 'react';

export interface IInitProps {}

export interface IInitState {}

export abstract class InitialDataComponent<
	P extends IInitProps,
	S extends IInitState
> extends React.Component<P, S> {
	abstract reviveState(newState: { [key: string]: any }): void;

	fill(storageKey: string) {
		const safedState = window.sessionStorage.getItem(storageKey);
		if (safedState != null) {
			this.reviveState(JSON.parse(safedState));
			window.sessionStorage.removeItem(storageKey);
		}
	}

	safe(comp: React.Component, keys: Array<string>, storageKey: string) {
		window.sessionStorage.setItem(storageKey, JSON.stringify(comp.state));
	}
}
