import React, { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { translate } from '../../../common/language/translate';
import { MainField, NameField } from '../../../content/dashboard/content/customers/basicStyledComponents/customerDetails.css';
import { Icons } from '../../../images';
import { Account, api, ApiError, apis, VoidResponse } from '../../../logic/api';
import { MessageHandler } from '../../../logic/handler/messagehandler/messageHandler';
import { Reporter } from '../../../logic/handler/messagehandler/messageHandlerConfig';
import { OverlayHandler, Overlays } from '../../../logic/handler/overlayhandler/overlayHandler';
import { compareDate, evaluateErrorMessage, TimeRangeOverlap } from '../../../logic/helper/Common';
import { format } from '../../../logic/helper/format';
import { Log, Logs } from '../../../logic/log';
import ButtonOk from '../../atomiccompoents/buttons/buttonOk';
import EditableComponent from '../../atomiccompoents/editableComponent/editableComponent';
import { CustomDatePicker } from '../../datepicker';

interface IProps {
	person_id: number,
	calendarData: Account.ProductCalendar,
	saveCallback?: (calendar: Account.ProductCalendar) => void
}

interface IState{
	calendarData?: Account.ProductCalendar,
	orderedCalendar?: {[key: number] : any},
	openCalendarData?: Account.OpenCalendar;
	calendarErrors?: {[key:string] : string};
	keyForLoadingSpinner: number
}

export class EditableProductCalendar extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			calendarData: props.calendarData,
			orderedCalendar: undefined,
			keyForLoadingSpinner: Math.floor(Math.random() * 10000000),
			calendarErrors: {},
		};
		this.saveCalendar = this.saveCalendar.bind(this);
		
	}

	buildCalendarData(data: any): Array<Account.CalendarItem> {
		const keys = Object.keys(data);
		const out: Array<Account.CalendarItem> = [];
		for (const o of keys) { 
			for(const i of data[o]) {
				out.push({
					end_time: i.endTime,
					start_time: i.startTime,
					weekday_id: parseInt(o)
				})
			}
		}
		return out;
	}

	componentDidMount() {
		this.rebuildCalendarData(this.props.calendarData.product_calendar_items);
	}

	rebuildCalendarData(data: Array<Account.CalendarItem>) {
		const out = {};
		for(const o of data) {
			if(out[o.weekday_id] == null) {
				out[o.weekday_id] = [{
					startTime: o.start_time,
					endTime: o.end_time
				}]
			} else {
				out[o.weekday_id].push(
					{
						startTime: o.start_time,
						endTime: o.end_time
					}
				)
			}
		}

		this.setState({
			orderedCalendar: out
		})
	}

	checkOverlaps(): string | undefined {
		const times = this.state.orderedCalendar;
		const keys = Object.keys(times);
		let outPut: string | undefined = undefined;
		if(times == null) {
			return "no data";
		}
		for(const o of keys) { //check overalps in timeslots
			for(let i = 0; i < times[o].length;  i++) {
				if(i === times[o].length -1) {
					break;
				}
				for(let a = i +1; a < times[o].length; a++) {
					if(TimeRangeOverlap(times[o][i].startTime, times[o][i].endTime, times[o][a].startTime, times[o][a].endTime)) {
						if(outPut == null) {
							outPut = ""
						}
						outPut = outPut + " Overlap at " + times[o][i].startTime + " - " + times[o][i].endTime + ", "  + times[o][a].startTime + " - " + times[o][a].endTime; 
					}
				}
			}
		}

		const data = this.state.calendarData;
		if(data == null) {
			return outPut;
		}
		if (data.excludes != null ) {
			for(let i = 0; i < data.excludes.length; i++) { //check overlaps in exclude dates
				if( i === data.excludes.length -1) {
					break;
				}
				for(let a = i + 1; a < data.excludes.length; a++) {
					if(compareDate(data.excludes[i], data.excludes[a] ) === 0 )  {
						if(outPut == null) {
							outPut = ""
						}
						outPut = outPut + " Overlap at exclude dates: " + data.excludes[a];
					}
				}
			}
		}
		if (data.exceptional_openings != null) {
			for(let i = 0; i < data.exceptional_openings.length; i++) { // check overlaps in exceptional openings
				if( i === data.exceptional_openings.length -1) {
					break;
				}
				for(let a = i + 1; a < data.exceptional_openings.length; a++) {
					if(compareDate(data.exceptional_openings[i].opening_date, data.exceptional_openings[a].opening_date) === 0 ) {
						if(TimeRangeOverlap(data.exceptional_openings[i].start_time, data.exceptional_openings[i].end_time, data.exceptional_openings[a].start_time, data.exceptional_openings[a].end_time )) {
							if(outPut == null) {
								outPut = ""
							}
							outPut = outPut + " Overlap at " + data.exceptional_openings[i].start_time + " - " + data.exceptional_openings[i].end_time + ", "  + data.exceptional_openings[a].start_time + " - " + data.exceptional_openings[a].end_time;
						}
					}
				}
			}
			if(data.excludes != null) {
				for(let i = 0; i < data.exceptional_openings.length; i++) { // check overlaps between exceptionals and excludes2
					for(let a = 0; a < data.excludes.length; a++) {
						if(compareDate(data.exceptional_openings[i].opening_date, data.excludes[a] ) === 0) {
							if(outPut == null) {
								outPut = ""
							}
							outPut = outPut + " Overlap at exclude and exceptional dates: " + data.excludes[a];
						}
					}
				}
			}
		}
		return outPut;
	}

	saveCalendar() {
		if(this.state.calendarData == null) {
			return;
		}
		const overlap = this.checkOverlaps();
		if (overlap != null) {
			MessageHandler.onError(Reporter['person.merchant.put.calendar'], null, overlap);
			this.setState({
				keyForLoadingSpinner:  Math.floor(Math.random() * 10000000),
			})
			return;
		}
		const calendar = this.state.calendarData;
		calendar.product_calendar_items = this.buildCalendarData(this.state.orderedCalendar);
 		this.props.saveCallback(calendar);
		 this.setState({
			keyForLoadingSpinner:  Math.floor(Math.random() * 10000000),
		})
	}

	generateCalendarFields(): Array<ReactElement> {
		if(this.state.calendarData == null) {
			return []
		};
		//customers.details.merchant.calendarItems
		const out = [];
		const data = this.state.calendarData;
		if(data.product_id > 0) {
			out.push(
				<MainField style={{margin: '16px', justifyContent: 'flex-start'}}>
					<div style={{width: '60%', display: 'flex'}}>
						<NameField style={{width: '50%', display: 'flex', alignItems: 'center'}}>{translate('customers.details.merchant.calendarItems.dateRangeDays') + ": "}</NameField>
						<NameField style={{width: '50%'}}	
							><EditableComponent
								id="range"
								initText={data.date_range_days != null ? data.date_range_days.toString() : ''}
								changeCallback={
									(value: string) => {
										const dat = this.state.calendarData;
										const val = parseInt(value);
										if(!isNaN(val)) {
											dat.date_range_days = val
										}
										this.setState({
											calendarData: dat
										})
									}
								}
								wrapperStyle={{border: 'none', width: '40%', alignItems: 'center', height: '40px'}}
								enterCallback={() =>  {}}
								viewStyle={{fontSize: '18px'}}
								inputStyle={{fontSize: '18px', minWidth: '100px'}}
						/>
						</NameField>
					</div>
					<div style={{width: '10%'}} >
						<Plus onClick={ (event: SyntheticEvent) => {
							event.preventDefault();
							event.stopPropagation();
							OverlayHandler.showOverlay(Overlays.addCalendarItemOverlay, {callback: (item: Account.CalendarItem) => {
								const dats = this.state.orderedCalendar;
								if(dats[item.weekday_id] == null) {
									dats[item.weekday_id] = [
										{
											startTime: item.start_time,
											endTime: item.end_time
										}
									]
								} else {
									dats[item.weekday_id].push(
										{
											startTime: item.start_time,
											endTime: item.end_time
										}
									)
								}
								this.setState({
									orderedCalendar: dats
								})
							}})
						}}> + </Plus>
					</div>
				</MainField>
			)
		} else {
			out.push(
				<MainField>
					<div style={{width: '10%'}} >
						<Plus onClick={ (event: SyntheticEvent) => {
							event.preventDefault();
							event.stopPropagation();
							OverlayHandler.showOverlay(Overlays.addCalendarItemOverlay, {callback: (item: Account.CalendarItem) => {
								const dats = this.state.orderedCalendar;
								if(dats[item.weekday_id] == null) {
									dats[item.weekday_id] = [
										{
											startTime: item.start_time,
											endTime: item.end_time
										}
									]
								} else {
									dats[item.weekday_id].push(
										{
											startTime: item.start_time,
											endTime: item.end_time
										}
									)
								}
								this.setState({
									orderedCalendar: dats
								})
							}})
						}}> + </Plus>
					</div>
				</MainField>
			)
		}
		const items = this.state.orderedCalendar;

		if(items == null) {
			return out
		}
		const keys = Object.keys(items);

		for (const o of keys) {
				out.push(
					<MainField>
						<NameField style={{ width: '30%'}}>
							{translate('customers.details.merchant.calendarItems.' + o) + ": "}
						</NameField>
						<div style={{display: 'flex', flexDirection: 'column', width: '70%'}}>
							{items[o].map( (item: any, index: any) => {
								return <NameField style={{width: '100%', display: 'flex', alignItems: 'center'}}>
									<EditableComponent
									   id={"startime"+index+o}
									   initText={item.startTime}
									   changeCallback={
											(value: string) => {
												const dat = this.state.orderedCalendar;
												dat[o][index].startTime = value;
												this.setState({
													orderedCalendar: dat
												})
											}
									   }
									   error={this.state.calendarErrors[o + "."+ index + "s"]}
									   enterCallback={() =>  {}}
									   wrapperStyle={{border: 'none', width: '40%', alignItems: 'center', height: '40px'}}
									   leaveCallback={() =>  {
										   const value = this.state.orderedCalendar[o][index].startTime;
										   const regexp: RegExp = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]:[0-5][\d]$/;
										   if(!regexp.test(value)) {
												//MessageHandler.onError(Reporter['customer.merchants.time.invalid']);
												const errors = this.state.calendarErrors;
												errors[o + "." + index + "s"] = 'Invalid Time format';
												this.setState({
													calendarErrors: errors
												})
									  		 } else {
												if(this.state.calendarErrors[o + "." + index + "s"] != null) {
													const errors = this.state.calendarErrors;
													delete errors[o + "." + index + "s"];
													this.setState({
														calendarErrors: errors
													})
												}
									   }
									   }}
									   viewStyle={{fontSize: '18px'}}
									   inputStyle={{fontSize: '18px'}}
									/> <div style={{width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{ " - " } </div>  
									<EditableComponent
									   id={"endtime"+index+o}
									   initText={item.endTime}
									   changeCallback={
											(value: string) => {
												const dat = this.state.orderedCalendar;
												dat[o][index].endTime = value;
												this.setState({
													orderedCalendar: dat
												})
											}
											
									 	}
										viewStyle={{fontSize: '18px'}}
										error={this.state.calendarErrors[o + "."+ index + "e"]}
									    inputStyle={{fontSize: '18px'}}
										wrapperStyle={{border: 'none', width: '40%', alignItems: 'center', height: '40px'}}
										leaveCallback={() =>  {
											const value = this.state.orderedCalendar[o][index].endTime;
											const regexp: RegExp = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]:[0-5][\d]$/;
											if(!regexp.test(value)) {
												 //MessageHandler.onError(Reporter['customer.merchants.time.invalid']);
												 const errors = this.state.calendarErrors;
												 errors[o + "." + index + "e"] = 'Invalid Time format';
												 this.setState({
													 calendarErrors: errors
												 })
											} else {
												if(this.state.calendarErrors[o + "." + index + "e"] != null) {
													const errors = this.state.calendarErrors;
													delete errors[o + "." + index + "e"];
													this.setState({
														calendarErrors: errors
													})
												}
											}
										}}
									 	enterCallback={() =>  {}}/>
										 <DelWrapper style={{width: '10%', display: 'flex'}}
										      onClick={()=> {
												const dat = this.state.orderedCalendar;
												const Arr: Array<any> = dat[o];
												const newArr = [];
												for(const j in Arr) {
													if(j.toString() !== index.toString() ) {
														newArr.push(Arr[j]);
													}
												}
												if(newArr.length > 0) {
													dat[o] = newArr;
												} else {
													delete dat[o];
												}
												this.setState({
													orderedCalendar: dat
												});
											 }}>
											 {Icons.del()}
										 </DelWrapper>
								</NameField>
							})}
						</div>
					</MainField>
				)			
		}
		if(data.product_id > 0) {
			out.push(
				<MainField>
					<NameField style={{ width: '30%'}}>
						{translate('customers.details.merchant.calendarItems.excluded') + ": "}
						<div style={{width: '10%', margin: '16px'}} >
							<Plus onClick={ (event: SyntheticEvent) => {
								event.preventDefault();
								event.stopPropagation();
								OverlayHandler.showOverlay(Overlays.addCalendarItemExcludeOverlay, {callback: (item: Date) => {
									const dats = this.state.calendarData;
									if(this.state.calendarData == null) {
										return;
									}
									if(dats.excludes == null) {
										dats.excludes = [
											item
										]
									} else {
										dats.excludes.push(
											item
										)
									}
									this.setState({
										calendarData: dats
									})
								}})
							}}> + </Plus>
						</div>
					</NameField>
						<div style={{display: 'flex', flexDirection: 'row', width: '50%'}}>
							{data.excludes != null ? 
							data.excludes.map( (item: Date, index: number) => {
								return <NameField style={{marginRight: '8px', width: '100px', display: 'flex'}}>
									<CustomDatePicker
										selectedValue={new Date(item)}
										onChange={(date: Date) => {
											const item = this.state.calendarData;
											if(item == null || item.excludes == null) {
												return;
											}
											item.excludes[index] = date;
											this.setState({
												calendarData: item
											})
											}
										}
										displayDate={true}
										resetCallback={() => {}}
										boxStyle={{
											boxShadow: 'none',
											borderRadius: '0px',
											height: '24px',
											border: 'none',
											margin: 'auto',
											justifyContent: 'flex-start',
										}}
										textStyle={{
											color: '#4a4a4a',
											textAlign: 'left',
											margin: '0px',
											marginRight: '8px',
											fontWeight: 500,
											fontSize: '18px',
										}}
										smallText={true}
									/>
									 <DelWrapper style={{width: '10%', display: 'flex', margin: '8px'}}
										onClick={()=> {
										const dat = this.state.calendarData;
										if(dat == null) {
											return;
										}
										const Arr: Array<Date> | undefined = dat.excludes;
										const newArr = [];
										for(const j in Arr) {
											if(j.toString() !== index.toString() ) {
												newArr.push(Arr[j]);
											}
										}
										dat.excludes = newArr;
										this.setState({
											calendarData: dat
										});
										}}>
										{Icons.del()}
									</DelWrapper>
								</NameField>
							}) : null }
						</div>
					</MainField>
			)
			
			out.push(
				<MainField>
					<NameField style={{ width: '30%'}}>
						{translate('customers.details.merchant.calendarItems.exceptions') + ": "}
						<div style={{width: '10%', margin: '16px'}} >
							<Plus onClick={ (event: SyntheticEvent) => {
								event.preventDefault();
								event.stopPropagation();
								OverlayHandler.showOverlay(Overlays.addCalendarItemExceptionOverlay, {callback: (item: {opening_date: Date, start_time: string, end_time: string}) => {
									const dats = this.state.calendarData;
									if(this.state.calendarData == null) {
										return;
									}
									if(dats.exceptional_openings == null) {
										dats.exceptional_openings = [
											item
										]
									} else {
										dats.exceptional_openings.push(
											item
										)
									}
									this.setState({
										calendarData: dats
									})
								}})
							}}> + </Plus>
				</div>
					</NameField>
						<div style={{display: 'flex', flexDirection: 'column', width: '70%'}}>
							{data.exceptional_openings != null ?
							 data.exceptional_openings.map( (item: any, index: number) => {
								return <NameField style={{marginRight: '8px', width: '100%', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems:'center'}}>
									{format.date(item.opening_date) + ":  " }
									<EditableComponent
									   id={"exceoptionsStart" + index}
									   initText={item.start_time}
									   changeCallback={
											(value: string) => {
												const dat = this.state.calendarData;
												if(dat.exceptional_openings != null) {
													dat.exceptional_openings[index].start_time = value;
													this.setState({
														calendarData: dat
													})
												}
											}
									   }
									   error={this.state.calendarErrors["except." + index + "s"]}
									   enterCallback={() =>  {}}
									   wrapperStyle={{border: 'none', width: '40%', alignItems: 'center', height: '40px'}}
									   leaveCallback={() =>  {
										   const value = this.state.calendarData.exceptional_openings[index].start_time;
										   const regexp: RegExp = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]:[0-5][\d]$/;
										   if(!regexp.test(value)) {
												//MessageHandler.onError(Reporter['customer.merchants.time.invalid']);
												const errors = this.state.calendarErrors;
												errors["except." + index + "s"] = 'Invalid Time format';
												this.setState({
													calendarErrors: errors
												})
									  		 } else {
												if(this.state.calendarErrors["except." + index + "s"] != null) {
													const errors = this.state.calendarErrors;
													delete errors["except." + index + "s"];
													this.setState({
														calendarErrors: errors
													})
												}
									   }
									   }}
									   viewStyle={{fontSize: '18px'}}
									   inputStyle={{fontSize: '18px'}}
									/> <div style={{width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{ " - " } </div>  
									<EditableComponent
									   id={"exceoptionsEnd" + index}
									   initText={item.end_time}
									   changeCallback={
											(value: string) => {
												const dat = this.state.calendarData;
												if(dat.exceptional_openings != null) {
													dat.exceptional_openings[index].end_time = value;
													this.setState({
														calendarData: dat
													})
												}
											}
									   }
									   error={this.state.calendarErrors["except." + index + "e"]}
									   enterCallback={() =>  {}}
									   wrapperStyle={{border: 'none', width: '40%', alignItems: 'center', height: '40px'}}
									   leaveCallback={() =>  {
											const value = this.state.calendarData.exceptional_openings[index].end_time;
											const regexp: RegExp = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]:[0-5][\d]$/;
										   if(!regexp.test(value)) {
												//MessageHandler.onError(Reporter['customer.merchants.time.invalid']);
												const errors = this.state.calendarErrors;
												errors["except." + index + "e"] = 'Invalid Time format';
												this.setState({
													calendarErrors: errors
												})
									  		 } else {
												if(this.state.calendarErrors["except." + index + "e"] != null) {
													const errors = this.state.calendarErrors;
													delete errors["except." + index + "e"];
													this.setState({
														calendarErrors: errors
													})
												}
									   }
									   }}
									   viewStyle={{fontSize: '18px'}}
									   inputStyle={{fontSize: '18px'}}/>
										 <DelWrapper style={{width: '10%', display: 'flex'}}
										      onClick={()=> {
												const dat = this.state.calendarData;
												if(dat == null) {
													return;
												}
												const Arr: Array<any> = dat.exceptional_openings;
												const newArr = [];
												for(const j in Arr) {
													if(j.toString() !== index.toString() ) {
														newArr.push(Arr[j]);
													}
												}
												dat.exceptional_openings = newArr;
												this.setState({
													calendarData: dat
												});
											 }}>
											 {Icons.del()}
										 </DelWrapper>
								</NameField>
							}) : null }
						</div>
					</MainField>
			)
		}
			out.push(
				<div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '16px'}}>
					<ButtonOk
						onClick={() => { this.saveCalendar() } }
						disabled={this.state.calendarData == null  || ( this.state.calendarErrors != null &&  Object.keys(this.state.calendarErrors).length  > 0 ) }
						key={this.state.keyForLoadingSpinner}
						id="btnUploadCalendar">
						{translate('customers.details.merchant.upload')}
					</ButtonOk>
				</div>
			)
		
		return out;
	}
	


	reviveState() {}

	render() {
		return (
			<React.Fragment>
              { this.generateCalendarFields() }
			</React.Fragment>
		);
	}
}

const Plus = styled.div`
	border-radius: 50%;
	background-color: ${props => props.theme.Button.backgroundColor};
	width: 26px;
	height: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: white;
	font-weight: 500;
	font-size: 27px;
	margin-left: 20px;
	:hover {
		cursor: pointer;
	}
`;

const DelWrapper = styled.div`
	:hover {
		cursor: pointer;
	}
`;

