// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    EyesonBaseWebhook,
    EyesonRecordingWebhookAllOf,
    EyesonRecordingWebhookAllOf1,
    NewEyesonRecording,
} from './';

/**
 * @export
 * @interface EyesonRecordingWebhook
 */
export interface EyesonRecordingWebhook {
    /**
     * @type {number}
     * @memberof EyesonRecordingWebhook
     */
    timestamp: number;
    /**
     * @type {string}
     * @memberof EyesonRecordingWebhook
     */
    type: EyesonRecordingWebhookTypeEnum;
    /**
     * @type {NewEyesonRecording}
     * @memberof EyesonRecordingWebhook
     */
    recording: NewEyesonRecording;
}

/**
 * @export
 * @enum {string}
 */
export enum EyesonRecordingWebhookTypeEnum {
    RecordingUpdate = 'recording_update'
}

