// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    NewAddress,
    PaymentMethod,
} from './';

/**
 * @export
 * @interface MerchantAddress
 */
export interface MerchantAddress {
    /**
     * the merchants name
     * @type {string}
     * @memberof MerchantAddress
     */
    name: string;
    /**
     * the merchants account number
     * @type {string}
     * @memberof MerchantAddress
     */
    account_number: string;
    /**
     * the etermin calendar id
     * @type {number}
     * @memberof MerchantAddress
     */
    calendar_id?: number;
    /**
     * etermin or ndpay
     * @type {string}
     * @memberof MerchantAddress
     */
    calendar_type?: MerchantAddressCalendarTypeEnum;
    /**
     * time zone name see https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     * @type {string}
     * @memberof MerchantAddress
     */
    timezone?: string;
    /**
     * a link to the merchants image
     * @type {string}
     * @memberof MerchantAddress
     */
    image?: string;
    /**
     * @type {NewAddress}
     * @memberof MerchantAddress
     */
    address?: NewAddress;
    /**
     * tthe distance in km in case the request provided lat/long
     * @type {number}
     * @memberof MerchantAddress
     */
    distance_km?: number;
    /**
     * the locations website, if any
     * @type {string}
     * @memberof MerchantAddress
     */
    website?: string;
    /**
     * the merchant identifier
     * @type {string}
     * @memberof MerchantAddress
     */
    identifier?: string;
    /**
     * accepted payment methods
     * @type {Array<PaymentMethod>}
     * @memberof MerchantAddress
     */
    payment_methods?: Array<PaymentMethod>;
    /**
     * pharmacy or testcenter
     * @type {string}
     * @memberof MerchantAddress
     */
    merchant_type?: MerchantAddressMerchantTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum MerchantAddressCalendarTypeEnum {
    E = 'E',
    N = 'N'
}
/**
 * @export
 * @enum {string}
 */
export enum MerchantAddressMerchantTypeEnum {
    P = 'P',
    T = 'T'
}

