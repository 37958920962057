// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * a faceimage
 * @export
 * @interface Face
 */
export interface Face {
    /**
     * @type {number}
     * @memberof Face
     */
    face_id: number;
    /**
     * date and time this entry was last updated
     * @type {Date}
     * @memberof Face
     */
    datetime_update: Date;
    /**
     * @type {string}
     * @memberof Face
     */
    type: FaceTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum FaceTypeEnum {
    BMP = 'BMP',
    PNG = 'PNG',
    JPG = 'JPG'
}

