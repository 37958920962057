// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface MerchantProductOrder
 */
export interface MerchantProductOrder {
    /**
     * @type {number}
     * @memberof MerchantProductOrder
     */
    ta_id: number;
    /**
     * @type {number}
     * @memberof MerchantProductOrder
     */
    transaction_id: number;
    /**
     * @type {Date}
     * @memberof MerchantProductOrder
     */
    sysdate: Date;
    /**
     * @type {Date}
     * @memberof MerchantProductOrder
     */
    start?: Date;
    /**
     * @type {Date}
     * @memberof MerchantProductOrder
     */
    end?: Date;
    /**
     * @type {string}
     * @memberof MerchantProductOrder
     */
    statement_text: string;
    /**
     * @type {number}
     * @memberof MerchantProductOrder
     */
    product_id: number;
    /**
     * @type {string}
     * @memberof MerchantProductOrder
     */
    record_state: MerchantProductOrderRecordStateEnum;
    /**
     * @type {string}
     * @memberof MerchantProductOrder
     */
    flight_number?: string;
    /**
     * @type {boolean}
     * @memberof MerchantProductOrder
     */
    payed: boolean;
    /**
     * indicates if the customers id was checked
     * @type {boolean}
     * @memberof MerchantProductOrder
     */
    id_checked?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum MerchantProductOrderRecordStateEnum {
    A = 'A',
    T = 'T',
    R = 'R'
}

