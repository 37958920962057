import { IItfAccount } from '../types';
import { Activity, Balance } from '../api/account';
import { OperationIds, Account, apis, Ident } from '../api';
import { ReactElement } from 'react';
import { IPermissionPerson } from './stores/teamMemberStore';
import { IDispatcher } from './dispatcher';
import { Constants } from './constants';
import { DefaultLoginType, CustomerPageType } from './stores/DeveloperStore';
import { IUserConfig } from './stores/configStore';

export interface IAPIData {
	endpoint: string;
	params: any;
	apitype: apis;
}

class IActions {
	private dispatchQueue: Array<() => void> = [];

	constructor() {
		window.setInterval(() => {
			if (this.dispatchQueue.length > 0) {
				const currentDispatch: (() => void) | undefined = this.dispatchQueue.shift();
				if (currentDispatch != null) {
					currentDispatch();
				}
			}
		}, 30);
	}

	headerChanged(header: string) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.HEADER_CHANGED,
				header: header,
			});
		});
	}
	userChanged(user: Ident.Person | undefined) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.USER_CHANGED,
				user: user,
			});
		});
	}
	customerChanged(user: Ident.Person) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.CUSTOMER_CHANGED,
				user: user,
			});
		});
	}
	customerBalanceChanged(balance: Balance | undefined) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.BALANCE_CHANGED,
				balance: balance,
			});
		});
	}
	menuChanged(component: number) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.MENU_CHANGED,
				component: component,
			});
		});
	}
	accountsChanged(accounts: {
		accounts: IItfAccount[];
		currentAccount: IItfAccount | undefined;
	}) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.ACCOUNTS_CHANGED,
				accounts: accounts,
			});
		});
	}

	resetCustomerData() {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.RESET_CUSTOMER_DATA,
			});
		});
	}

	resetBackoffice() {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.RESET_BACKOFFICE_DATA,
			});
		});
	}
	currentAccountChanged(account: IItfAccount | undefined) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.CURRENT_ACCOUNT_CHANGED,
				currentAccounts: account,
			});
		});
	}
	setCustomerAccounts(accounts: Array<IItfAccount>) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.CUSTOMER_ACCOUNTS_CHANGED,
				accounts: accounts,
			});
		});
	}
	setCustomerCurrentAccount(account: IItfAccount | undefined) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.CURRENT_ACCOUNT_CHANGED,
				account: account,
			});
		});
	}
	setCustomerData(data: {
		accounts: Array<IItfAccount>;
		currentAccount?: IItfAccount;
		selectedUser?: Ident.Person;
	}) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.CUSTOMER_DATA_CHANGED,
				data: data,
			});
		});
	}
	transactionsChanged(transactions: Activity) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.TRANSACTIONS_CHANGED,
				transactions: transactions,
			});
		});
	}
	permissionsChanged(permissions: Array<OperationIds>): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.PERMISSIONS_CHANGED,
				permissions: permissions,
			});
		});
	}
	userImageChanged(userImage: Blob): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.USERIMAGE_CHANGED,
				userImage: userImage,
			});
		});
	}
	backofficeChanged(account?: IItfAccount): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.BACKOFFICE_CHANGED,
				account: account,
			});
		});
	}
	bubbleChanged(bubble?: ReactElement): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.BUBBLE_ADD,
				bubble: bubble,
			});
		});
	}
	teamMemberAdded(teamMember: IPermissionPerson): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.TEAMMEMBER_ADDED,
				teamMember: teamMember,
			});
		});
	}
	teamMembersChanged(teamMembers?: Array<IPermissionPerson>): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.TEAMMEMBER_CHANGED,
				teamMembers: teamMembers,
			});
		});
	}
	clearAll(): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.CLEAR_ALL,
			});
		});
	}

	setEditableComponentActiveIndex(index: number): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.ACTIVE_INDEX_CHANGED,
				index: index,
			});
		});
	}
	accountPermissionsChanged(permissions: Array<Account.OperationId>): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.PERMISSIONS_CHANGED,
				accountPermissions: permissions,
			});
		});
	}
	identPermissionsChanged(permissions: Array<Ident.OperationId>): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.PERMISSIONS_CHANGED,
				identPermissions: permissions,
			});
		});
	}
	updateLastActivity(): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.UPDATE_LAST_ACTIVITY,
			});
		});
	}
	setLogoutTime(logoutTime?: number): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.LOGOUT_TIME,
				logoutTime: logoutTime,
			});
		});
	}
	setLogoutAbsTime(absLogoutTime?: number): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.ABS_LOGOUT_TIME,
				absLogoutTime: absLogoutTime,
			});
		});
	}
	setTheme(theme?: string): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.THEME_CHANGED,
				theme: theme,
			});
		});
	}
	addNotification(notification?: Account.NotificationLine): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.ADD_NOTIFICATION,
				notification: notification,
			});
		});
	}
	deleteNotification(notification?: Account.NotificationLine): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.DELETE_NOTIFICATION,
				notification: notification,
			});
		});
	}
	readNotification(notification?: Account.NotificationLine): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.READ_NOTIFICATION,
				notification: notification,
			});
		});
	}
	unreadNotification(notification?: Account.NotificationLine): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.UNREAD_NOTIFICATION,
				notification: notification,
			});
		});
	}
	setNotifications(notificationList?: Account.NotificationList): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.SET_NOTIFICATION,
				notificationList: notificationList,
			});
		});
	}
	disableDeveloperOptions(): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.DISABLE_DEVELOPER,
			});
		});
	}
	enableDeveloperOptions(): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.ENABLE_DEVELOPER,
			});
		});
	}
	deviceToken(deviceToken?: string): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.DEVICE_TOKEN,
				deviceToken: deviceToken,
			});
		});
	}
	setDefaultBackofficeAccount(accountNumber?: string): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.DEFAULT_BACKOFFICE_ACCOUNT,
				accountNumber: accountNumber,
			});
		});
	}
	setDefaultCustomer(personId?: number): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.DEFAULT_CUSTOMER,
				personId: personId,
			});
		});
	}
	setDefaultCustomerPage(page?: CustomerPageType): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.DEFAULT_CUSTOMER_PAGE,
				page: page,
			});
		});
	}
	setDeveloperLogin(developerLogin?: DefaultLoginType): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.DEVELOPER_LOGIN,
				defaultLogin: developerLogin,
			});
		});
	}
	setPaging(paging: { rowsPP: number; first: number; last: number; amount?: number }, id?: string): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.PAGES,
				pages: paging,
				id: id
			});
		});
	}
	setBlocked(blocked: boolean): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.BLOCK,
				blocked: blocked,
			});
		});
	}
	setNotification(notifiation: any): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.NOTIFICATION_SET,
				notification: notifiation,
			});
		});
	}
	setUserConfig(config: IUserConfig): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.CONFIG_SET,
				config: config,
			});
		});
	}
	setSingleConfigValue(values: [{ key: string; value: any }]): void {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.CONFIG_SET_SPECIFIC_KEY,
				values: values,
			});
		});
	}
	setUndoUser(person: Ident.Person, account: IItfAccount | null) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.UNDO_SET_LAST_USER,
				person: person,
				account: account,
			});
		});
	}
	setRoles(roles: Array<string>) {
		this.dispatchQueue.push(() => {
			IDispatcher.dispatch({
				actionType: Constants.SET_ROLES,
				roles: roles
			});
		});
	}
}

class SActions {
	dataGet(APIData: IAPIData) {
		IDispatcher.dispatch({
			actionType: Constants.API_WORKFLOW.DATA_GET,
			apidata: APIData,
		});
	}
	dataChanged(data: any) {
		IDispatcher.dispatch({
			actionType: Constants.API_WORKFLOW.DATA_CHANGED,
			data: data,
		});
	}
}

export const Actions = new IActions();
export const ServerActions = new SActions();
