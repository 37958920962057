// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AccountProductOrderEyesonRoom,
    Attachment,
    Iso4217CurrencyOUT,
    NewAddress
} from './';

/**
 * @export
 * @interface AccountProductOrder
 */
export interface AccountProductOrder {
    /**
     * @type {number}
     * @memberof AccountProductOrder
     */
    ta_id: number;
    /**
     * @type {number}
     * @memberof AccountProductOrder
     */
    transaction_id: number;
    /**
     * amount in currency
     * @type {number}
     * @memberof AccountProductOrder
     */
    amount?: number;
    /**
     * @type {Iso4217CurrencyOUT}
     * @memberof AccountProductOrder
     */
    currency?: Iso4217CurrencyOUT;
    /**
     * @type {Date}
     * @memberof AccountProductOrder
     */
    start?: Date;
    /**
     * @type {Date}
     * @memberof AccountProductOrder
     */
    end?: Date;
    /**
     * @type {string}
     * @memberof AccountProductOrder
     */
    statement_text?: string;
    /**
     * @type {string}
     * @memberof AccountProductOrder
     */
    merchant_name?: string;
    /**
     * @type {string}
     * @memberof AccountProductOrder
     */
    merchant_account_number?: string;
    /**
     * @type {number}
     * @memberof AccountProductOrder
     */
    product_id: number;
    /**
     * product in currency
     * @type {number}
     * @memberof AccountProductOrder
     */
    product_amount?: number;
    /**
     * @type {string}
     * @memberof AccountProductOrder
     */
    product_name?: string;
    /**
     * indicates if product order can be submitted to cwa
     * @type {boolean}
     * @memberof AccountProductOrder
     */
    cwa?: boolean;
    /**
     * external reference as provided by the initiaing application
     * @type {string}
     * @memberof AccountProductOrder
     */
    external_reference?: string;
    /**
     * @type {Array<Attachment>}
     * @memberof AccountProductOrder
     */
    attachments?: Array<Attachment>;
    /**
     * @type {string}
     * @memberof AccountProductOrder
     */
    record_state: AccountProductOrderRecordStateEnum;
    /**
     * @type {string}
     * @memberof AccountProductOrder
     */
    flight_number?: string;
    /**
     * @type {string}
     * @memberof AccountProductOrder
     */
    delivery_note?: string;
    /**
     * @type {NewAddress}
     * @memberof AccountProductOrder
     */
    merchant_address?: NewAddress;
    /**
     * indicates if an invoice can be created for that product order
     * @type {boolean}
     * @memberof AccountProductOrder
     */
    invoice?: boolean;
    /**
     * confirm/deny that test is received * C - Confirmed * D - Denied * P - Pending --> ask customer 
     * @type {string}
     * @memberof AccountProductOrder
     */
    ask_for_confirmation?: AccountProductOrderAskForConfirmationEnum;
    /**
     * @type {AccountProductOrderEyesonRoom}
     * @memberof AccountProductOrder
     */
    eyeson_room?: AccountProductOrderEyesonRoom;
    /**
     * @type {String | object}
     * @memberof AccountProductOrder
     */
    type_of_test?: String | object;
}

/**
 * @export
 * @enum {string}
 */
export enum AccountProductOrderRecordStateEnum {
    A = 'A',
    T = 'T',
    R = 'R'
}
/**
 * @export
 * @enum {string}
 */
export enum AccountProductOrderAskForConfirmationEnum {
    C = 'C',
    D = 'D',
    P = 'P'
}

