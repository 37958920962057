// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Sex:   * \'M\' - Male   * \'F\' - Female   * \'X\' - Unspecified 
 * @export
 * @enum {string}
 */
export enum Sex {
    M = 'M',
    F = 'F',
    X = 'X'
}

