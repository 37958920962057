// tslint:disable
/**
 * nd-ident
 * Interface to the ndBIT Ident Plattform
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CompanionRelationship,
    Sex,
} from './';

/**
 * @export
 * @interface JointPersonCreated
 */
export interface JointPersonCreated {
    /**
     * @type {number}
     * @memberof JointPersonCreated
     */
    person_id: number;
    /**
     * @type {Sex}
     * @memberof JointPersonCreated
     */
    sex: Sex;
    /**
     * the persons given name
     * @type {string}
     * @memberof JointPersonCreated
     */
    given_name: string;
    /**
     * the persons name
     * @type {string}
     * @memberof JointPersonCreated
     */
    name: string;
    /**
     * the persons birth date to change
     * @type {Date}
     * @memberof JointPersonCreated
     */
    birth_date: Date;
    /**
     * City or municipality
     * @type {string}
     * @memberof JointPersonCreated
     */
    birth_city?: string;
    /**
     * @type {CompanionRelationship}
     * @memberof JointPersonCreated
     */
    relationship?: CompanionRelationship;
    /**
     * * C person is companion * P person is primary 
     * @type {string}
     * @memberof JointPersonCreated
     */
    relation_type: JointPersonCreatedRelationTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum JointPersonCreatedRelationTypeEnum {
    C = 'C',
    P = 'P'
}

