// tslint:disable
/**
 * EBaas Accounting Engine
 * Interface to the EbaaS Accounting Engine
 *
 * The version of the OpenAPI document: 0.0.33
 * Contact: apiteam@ndbit.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { IOperationIdMap } from '../../IOperationMap';
import { BaseAPI, HttpHeaders } from '../runtime';
import {
    InternalConversion,
    InternalCreditTransfer,
    ManualDispo,
    PlainTransactionIdentifier,
    PlainTransactionIdentifierString,
    RandomCardTakeover,
    ReleaseNotice,
    Reversal,
    TransactionIdentifier,
} from '../models';

export interface PaymentsInternalConversionPostRequest {
    InternalConversion?: InternalConversion;
}

export interface PaymentsInternalCreditTransferPostRequest {
    InternalCreditTransfer?: InternalCreditTransfer;
}

export interface PaymentsManualDispoPostRequest {
    ManualDispo?: ManualDispo;
}

export interface PaymentsRandomCardTakeoverPostRequest {
    RandomCardTakeover?: RandomCardTakeover;
}

export interface PaymentsReimbursementPostRequest {
    InternalCreditTransfer?: InternalCreditTransfer;
}

export interface PaymentsReversalPostRequest {
    Reversal?: Reversal;
}

export interface PaymentsTestResultDocumentPostRequest {
    PlainTransactionIdentifierString?: PlainTransactionIdentifierString;
}

/**
 * no description
 */
export class TransactionBoApi extends BaseAPI implements IOperationIdMap {

    /**
     * create a internal conversion transaction
     */
    paymentsInternalConversionPost = (requestParameters: PaymentsInternalConversionPostRequest): Observable<TransactionIdentifier> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<TransactionIdentifier>({
            path: '/payments/internal_conversion',
            method: 'POST',
            headers,
            body: requestParameters.InternalConversion,
        });
    };

    /**
     * create an internal payment 
     */
    paymentsInternalCreditTransferPost = (requestParameters: PaymentsInternalCreditTransferPostRequest): Observable<TransactionIdentifier> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<TransactionIdentifier>({
            path: '/payments/internal_credit_transfers',
            method: 'POST',
            headers,
            body: requestParameters.InternalCreditTransfer,
        });
    };

    /**
     * block or unblock an amount 
     */
    paymentsManualDispoPost = (requestParameters: PaymentsManualDispoPostRequest): Observable<PlainTransactionIdentifier> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<PlainTransactionIdentifier>({
            path: '/payments/manual_dispo',
            method: 'POST',
            headers,
            body: requestParameters.ManualDispo,
        });
    };

    /**
     * create a random card take-over
     */
    paymentsRandomCardTakeoverPost = (requestParameters: PaymentsRandomCardTakeoverPostRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/payments/random_card_takeover',
            method: 'POST',
            headers,
            body: requestParameters.RandomCardTakeover,
        });
    };

    /**
     * create an internal payment 
     */
    paymentsReimbursementPost = (requestParameters: PaymentsReimbursementPostRequest): Observable<TransactionIdentifier> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<TransactionIdentifier>({
            path: '/payments/reimbursement',
            method: 'POST',
            headers,
            body: requestParameters.InternalCreditTransfer,
        });
    };

    /**
     * create a reverse transaction
     */
    paymentsReversalPost = (requestParameters: PaymentsReversalPostRequest): Observable<TransactionIdentifier> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<TransactionIdentifier>({
            path: '/payments/reversal',
            method: 'POST',
            headers,
            body: requestParameters.Reversal,
        });
    };

    /**
     * generte document with test result for product order
     * generate test result pdf
     */
    paymentsTestResultDocumentPost = (requestParameters: PaymentsTestResultDocumentPostRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username && this.configuration.password && { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` }),
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // biometryAuth authentication
            ...(this.configuration.apiKey && { 'x-amzn-oidc-data': this.configuration.apiKey('x-amzn-oidc-data') }), // oidc_header authentication
        };

        return this.request<void>({
            path: '/payments/test_result_document',
            method: 'POST',
            headers,
            body: requestParameters.PlainTransactionIdentifierString,
        });
    };


    operationToOperationId = {
	    "paymentsInternalConversionPost": "payments_internal_conversion_post" ,
	    "paymentsInternalCreditTransferPost": "payments_internal_credit_transfer_post" ,
	    "paymentsManualDispoPost": "payments_manual_dispo_post" ,
	    "paymentsRandomCardTakeoverPost": "payments_random_card_takeover_post" ,
	    "paymentsReimbursementPost": "payments_reimbursement_post" ,
	    "paymentsReversalPost": "payments_reversal_post" ,
	    "paymentsTestResultDocumentPost": "payments_test_result_document_post"
    }
}
