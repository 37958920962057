//@ts-nocheck
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { api, apis, ApiError, Account, VoidResponse, Ident } from '../../../../logic/api';
import {
	IInitProps,
	IInitState,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import {
	IStatusState, Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { INotifyAbleCustomerData } from '../../../../logic/flux';
import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { format } from '../../../../logic/helper/format';
import AccountSelector from '../../../../components/accountSelector';
import { Icons } from '../../../../images';
import { Log, Logs } from '../../../../logic/log';
import { IItfAccount } from '../../../../logic/types';
import { evaluateErrorMessage, floatingMenuButton } from '../../../../logic/helper/Common';
import { IPopMenuData } from '../../../../components/compositcomponents/popup/popUpMenuComponent';
import { KeyValueRowBlock, KeyBlock, ValueBlock, MainBox, MainField, IconWrapperPadding as IconWrapper, NameField, DateField, DataBlock } from './basicStyledComponents/customerDetails.css';
import { ClientConfig } from '../../../../common/config';
import { withPersonAndAccount } from '../../../../components/hocs/withPersonAndAccount';


interface IProps extends IInitProps {
	currentAccount?: IItfAccount;
    person?: Ident.Person
}
interface IState extends IStatusState, IInitState {
    openedBoxes: {};
    data: Array<Account.FeesDetails>;
    feePackages: Array<Account.FeePackage>;
    exceptions: Array<string>;
}

const BookingExceptions = ['All inclusive', 'Default'];

const StyledViewMain = styled(FlexBox)`
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;
`;
const StyledContentBox = styled(FlexBox)`
	flex-direction: column;
	padding: 0px 32px;
`;

class CustomerFeesBox extends React.Component<IProps, IState>
	implements INotifyAbleCustomerData {
	constructor(props: IProps) {
		super(props);

		this.state = {
            openedBoxes: { },
            data: [],
            feePackages: [],
            exceptions: []
		};

        this.bookPackage = this.bookPackage.bind(this);
        this._onChangeCustomerData();
	}

	_onChangeCustomerData() {
        this.getFeeData(this.props.currentAccount?.account_number);
        this.getFeePackages(this.props.currentAccount?.account_number);
    }
    
    componentDidUpdate(prevProps: IProps) {
		if (prevProps.currentAccount?.account_number !== this.props.currentAccount?.account_number ) {
		  this._onChangeCustomerData();
		}	  
    }

    deletePackage(index: any) {
        if(this.props.currentAccount == null || this.state.data[index] == null || this.state.data[index].account_fee_package_id == null) {
            return;
        }
        const req: Account.AccountAccountNumberFeesDeleteRequest = {
            account_number: this.props.currentAccount.account_number,
            account_fee_package_id: this.state.data[index].account_fee_package_id 
        }
        api.asyncRequest<Array<VoidResponse>>
        (
			req,
			apis.DefaultApi,
            'accountAccountNumberFeesDelete'
          ).then(() => {
              MessageHandler.onSuccess(Reporter['person.fees.delete']);
                if(this.props.currentAccount != null) {
                    this.getFeeData(this.props.currentAccount.account_number);
                }
            })
            .catch((error: ApiError) => {
                MessageHandler.onError(Reporter['person.fees.delete'],evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
                Log.error(
                    Logs.API,
                    'Unable to delete Fees: ' +
                    error.statusText
                );
            });
    }

    setFeeData() {
		const out: Array<IPopMenuData> = [];
		if(this.state.feePackages == null || this.props.currentAccount == null) {
			return out;
		}
		for (const i of this.state.feePackages) {
				const dataItem: IPopupMenuData = {
					display: i.description + "( "  + format.currency(i.price , this.props.currentAccount.currency)  + " )" ,
					passValue: i.memo,
					identifier: i.description,
					specialCase: false
				}
				out.push(dataItem);
		}
		return out;
	}


	getFeeData(accountNumber: string) {
		const params: Account.AccountAccountNumberFeesGetRequest = {
			account_number: accountNumber,
        };
        api.asyncRequest<Array<Account.FeesDetails>>
        (
			params,
			apis.DefaultApi,
            'accountAccountNumberFeesGet'
          ).then((response: Array<Account.FeesDetails>) => {
            const exceptions = [];
                for (const o of response) {
                    if(o.name == null) {
                        continue;
                    }
                    if(BookingExceptions.indexOf( o.name) > -1 && exceptions.indexOf(o.name) < 0) {
                        exceptions.push(o.name);
                    }
                }
                this.setState({
                    data: response,
                    exceptions: exceptions
                })
            })
            .catch((error: ApiError) => {
                Log.error(
                    Logs.API,
                    'Unable to load Fees: ' +
                    error.statusText
                );
                this.setState({
                    data: []
                })
            });
    }

    getFeePackages(accountNr: string) { 
        const req: Accout.AccountAccountNumberBookableFeesRequest = {
            account_number: accountNr
        }
        api.asyncRequest<Array<Account.FeePackage>>
        (
			req,
			apis.DefaultApi,
            'accountAccountNumberBookableFees'
          ).then((response: Array<Account.FeePackage>) => {
                this.setState({
                    feePackages: response
                })
            })
            .catch((error: ApiError) => {
                Log.error(
                    Logs.API,
                    'Unable to load Feepackages: ' +
                    error.statusText
                );
            });
    }
    
    renderDataFields()  {
        const out = [];
        if(this.state.data == null) {
            return;
        }
        for(const i in this.state.data) {
            const innerOut = [];
            if(this.state.data[i].transactions != null) {
                innerOut.push(
                    <KeyValueRowBlock >
                        <KeyBlock>{translate('customers.feesTab.name')}</KeyBlock>
                        <KeyBlock>{translate('customers.feesTab.price')}</KeyBlock>
                        <KeyBlock>{translate('customers.feesTab.used')}</KeyBlock>
                    </KeyValueRowBlock>
                )
                for(const k in this.state.data[i].transactions) {
                    innerOut.push(
                    <KeyValueRowBlock>                      
                        <ValueBlock>{this.state.data[i].transactions[k].name}</ValueBlock>
                        <ValueBlock>{this.state.data[i].transactions[k].price + " €"}</ValueBlock>
                        <ValueBlock isMax={this.state.data[i].transactions[k].used === this.state.data[i].transactions[k].max_used }>                            
                            {this.state.data[i].transactions[k].used + (
                            this.state.data[i].transactions[k].max_used != null ? 
                            "/" + this.state.data[i].transactions[k].max_used : "" 
                        )}</ValueBlock>
                    </KeyValueRowBlock>
                    )
                }
            }
            out.push(
                <MainBox>
                    <MainField>
                        <IconWrapper onClick= { () => {
                            if(this.state.data[i].name == null ) {
                                return;
                            }
                            const boxes = this.state.openedBoxes;
                            //@ts-ignore
                            if(boxes[this.state.data[i].name] != null) {
                                //@ts-ignore
                                delete boxes[this.state.data[i].name];
                            }
                            else {
                                //@ts-ignore
                                boxes[this.state.data[i].name] = true;
                            }

                            this.setState({
                                openedBoxes: boxes
                            })
                        }}>   
                        {//@ts-ignore 
                            this.state.openedBoxes[this.state.data[i].name] != null ? Icons.arrowFacingUp() : Icons.arrowFacingDown()
                        }
                        </IconWrapper>
                    <NameField >{this.state.data[i].name}</NameField>
                    <DateField >{format.date(this.state.data[i].valid_from ) + " - " +
                                ( this.state.data[i].valid_to != null ? format.date(this.state.data[i].valid_to)  : 'No Limit' ) }</DateField>
                    { this.state.data[i].name !== 'Default' && ClientConfig.bookFee === true ?
                        <HoverField onClick={ () => {
                            this.deletePackage(i)
                            }
                        }>  { Icons.del() }  
                        </HoverField>   : <HoverField /> }          
                    </MainField>
                    { //@ts-ignore 
                    this.state.data[i].name != null && this.state.openedBoxes[this.state.data[i].name] != null ? 
                        <DataBlock>
                            {innerOut}
                        </DataBlock>  
                        : null 
                    }          
            </MainBox>
            )
        }
        if(out.length > 0) {
            return out;
        }
        else {
            return <NameField>
                {translate('customers.feesTab.empty')}
            </NameField>
        }

    }

    bookPackage(packageType: string) {
        if(this.props.currentAccount == null){
            return;
        }
        const req: Account.AccountAccountNumberFeesPutRequest = {
            account_number: this.props.currentAccount.account_number,
            FeeBooking: {
                memo: packageType
            }
        };
        api.asyncRequest<Array<Account.FeesDetails>>
        (
			req,
			apis.DefaultApi,
            'accountAccountNumberFeesPut'
          ).then(() => {
                MessageHandler.onSuccess( Reporter['customer.details.fees.put']);
                this.getFeeData(this.props.currentAccount != null ? this.props.currentAccount.account_number : "");
            })
            .catch((error: ApiError) => {
                MessageHandler.onError( Reporter['customer.details.fees.put'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
            });
    }

	render() {
        const fee = this.setFeeData();
        const exceptions = this.state.exceptions != null ? this.state.exceptions : [];

		return (
			<StyledContentBox>
				<StyledViewMain>
                    <AccountSelector />
                    {
                        ClientConfig.bookFee === true && 
                        this.state.feePackages != null ? floatingMenuButton(this.bookPackage, 250, ((fee.length - exceptions.length) * 45),  fee, exceptions, {} ) : null 
                    }
                    {this.renderDataFields()}
				</StyledViewMain>
			</StyledContentBox>
		);
	}
}



const HoverField = styled(NameField)`
    display: flex;
    justify-content: flex-end;
    :hover {
        cursor: pointer;
    }
`;
export default withPersonAndAccount(CustomerFeesBox);